import React from 'react'
import styled from 'styled-components'
import { mediaPcForLiveTalk } from '../../../lib/MediaQuery'
import { Button } from '../../atoms'

type Props = {
  className?: string
  onClickFinish: () => void
}

const StyledFinishLiveTalkDialog = styled.div`
  width: 100%;
  padding: 14px 0;
  background-color: rgba(34, 34, 34, 0.44);
  border-radius: 4px;
  text-align: center;

  > p {
    color: ${(props): string => props.theme.textColors.white};
    line-height: 16px;
    letter-spacing: 0.1em;
  }

  .title {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 700;
  }

  .body {
    margin-bottom: 8px;
    font-size: 10px;
    font-weight: 400;
  }

  .finish-button {
    width: 120px;
    line-height: 27px;
  }

  ${mediaPcForLiveTalk`
  padding: 19px 0;

  .title {
    margin-bottom: 9px;
    font-size: 18px;
  }

  .body {
    margin-bottom: 10px;
    font-weight: 700;
  }
`}
`

export const FinishLiveTalkDialog: React.FC<Props> = ({ className = '', onClickFinish }) => {
  return (
    <StyledFinishLiveTalkDialog className={className}>
      <p className="title">ライブトーク終了の時間になりました。</p>
      <p className="body">
        ボタンを押してライブトークを終了できます。
        <br />
        押さない場合は30秒後にそのまま自動終了します。
      </p>
      <Button buttonType="black" onClick={onClickFinish} className="finish-button">
        終了する
      </Button>
    </StyledFinishLiveTalkDialog>
  )
}
