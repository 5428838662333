const BASE_WIDTH = 3.75

export const getPxNumber = (pxString: string): number => {
  return Number(pxString.replace('px', ''))
}

// digit: 小数点以下何桁まで残すか
export const round = (data: number, digit: number): number => {
  return Math.round(data * Math.pow(10, digit)) / Math.pow(10, digit)
}

// px: '数字+px'で指定
// digit: 小数点以下何桁まで残すか
export const convertToVw = (size: string, digit = 3): string => {
  // autoや%で指定した時はそのままの値を返却
  if (!size.includes('px')) return size

  const pxNumber = getPxNumber(size)
  return `${round(pxNumber / BASE_WIDTH, digit)}vw`
}

// margin, paddingの値を1つ〜4つまとめて指定した時用
export const convertMultipleSizesToVw = (size: string, digit = 3): string => {
  const sizeArray = size.split(' ')
  let sizesAfterConvert = ''
  sizeArray.forEach((size) => (sizesAfterConvert += ` ${convertToVw(size, digit)}`))
  return sizesAfterConvert.trim()
}

// height, fontSize共にpxで受け取る
export const getLineHeight = (height: string, fontSize: string): number => {
  return getPxNumber(height) / getPxNumber(fontSize)
}
