import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'
import copyLink from '../../assets/images/icon/copy_link.png'

type Props = {
  className?: string
  children?: React.ReactNode
  onClick?: () => void
}

const StyledCopyLink = styled.p`
  padding-left: 1.8em;
  font-size: 3.2vw;
  color: ${(props): string => props.theme.textColors.primary};
  line-height: 1.65;
  letter-spacing: 0.05em;
  background: url(${copyLink}) no-repeat center left;
  background-size: 16px 13px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
  ${mediaPc`
    font-size: 12px;
  `}
`

export const CopyLink: React.FC<Props> = ({ className = '', children, onClick }) => {
  return (
    <StyledCopyLink className={className} onClick={onClick}>
      {children}
    </StyledCopyLink>
  )
}
