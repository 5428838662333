import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorMypageEditCompleteTpl, CreatorTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'

import { useCurrentCreatorUpdateAccountMutation } from '../../../types/graphql'
import { FlashMessageType } from '../../../types/myTypes'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import { CreatorProfileContext } from '../../../context'

export const CreatorMypageEditComplete: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const { creator } = useContext(CreatorProfileContext)

  const location = useLocation<{ email: string }>()

  const [currentCreatorUpdateAccountMutation, { loading }] = useCurrentCreatorUpdateAccountMutation({
    onCompleted: () => {
      setFlashMessage({ type: 'success', message: '確認メールを再送しました' })
    },
    onError: (e) => {
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })
  const onClickResendConfirmMail = async (): Promise<void> => {
    if (creator) {
      currentCreatorUpdateAccountMutation({
        variables: {
          creator_id: creator.id,
          input: {
            name: creator.name,
            email: email,
            profile: creator.profile,
            twitter_username: creator.twitter_username,
            instagram_username: creator.instagram_username,
            youtube_channel_url: creator.youtube_channel_url,
            kindle_author_url: creator.kindle_author_url,
          },
        },
      })
    }
  }
  useValidateCreatorToken()
  useSetCreatorProfile()
  useEffect(() => {
    setEmail(location.state.email)
  }, [location.state.email])

  return (
    <>
      <Meta title="メールアドレス更新用メール送信" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="creatorInfo" isVisibleCreatorEditButton={false}>
        <CreatorMypageEditCompleteTpl email={email} onClickResendConfirmMail={onClickResendConfirmMail} />
      </CreatorTwoColumnLayout>
    </>
  )
}
