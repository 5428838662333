import React from 'react'
import styled from 'styled-components'
import { Modal } from '../..'
import { ModalInputItem } from '../../../molecules'
import { ModalTitle, TextInput, Button } from '../../../atoms'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  newAffiliationName: string
  onChangeNewAffiliationName: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClickAffiliationRegistration: () => void
  closeModal: () => void
}

const ModalContents = styled.div`
  padding: 32px 0;
  .inner {
    width: 86%;
    margin: 0 auto;
    .modal-title {
      padding-bottom: 8vw;
    }
    .input-text {
      padding-bottom: 3.7vw;
    }
    .button {
      width: 53vw;
      height: 8vw;
      font-size: 3.2vw;
      line-height: 8vw;
      display: block;
      margin: 2.6vw auto 3.2vw;
    }
  }
  ${mediaPc`
    .inner {
      width: 100%;
      max-width: 375px;
      .modal-title {
        padding-bottom: 45px;
      }
      .input-text {
        padding-bottom: 30px;
      }
    .button {
        width: 200px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
        display: block;
        margin: 0 auto 28px;
      }
    }
  `}
`

export const AffiliationRegistrationModalTpl: React.FC<Props> = ({
  newAffiliationName,
  onChangeNewAffiliationName,
  onClickAffiliationRegistration,
  closeModal,
}) => {
  const isDisable = (): boolean => {
    if (!newAffiliationName) {
      return true
    }
    return false
  }
  return (
    <Modal closeModal={closeModal}>
      <ModalContents>
        <div className="inner">
          <ModalTitle className="modal-title" title="所属リスト新規追加" />
          <ModalInputItem className="input-text" label="所属" inputType="text">
            <TextInput
              placeholder="所属名を入力してください"
              type="text"
              value={newAffiliationName}
              onChange={onChangeNewAffiliationName}
            />
          </ModalInputItem>
          <Button
            buttonType="primary"
            className="button"
            onClick={() => {
              onClickAffiliationRegistration()
              closeModal()
            }}
            disabled={isDisable()}
          >
            登録
          </Button>
        </div>
      </ModalContents>
    </Modal>
  )
}
