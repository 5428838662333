import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessCreators } from '../../../components/organisms'
import { BusinessTwoColumnLayout, CreatorRegistrationModalTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  Affiliation,
  usePagedCreatorsInBusinessLazyQuery,
  useRegisterNewCreatorMutation,
  useAffiliationsInBusinessLazyQuery,
} from '../../../types/graphql'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'

type Props = {} & RouteComponentProps

export const BusinessCreator: React.FC<Props> = (props) => {
  const [creators, setCreators] = useState<Creator[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [affiliations, setAffiliations] = useState<Affiliation[]>([])
  const [newCreatorEmail, setNewCreatorEmail] = useState<string>('')
  const [newCreatorAffiliation, setNewCreatorAffiliation] = useState<string>('')
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const location = useLocation<{ deleteCreator?: boolean; creatorRegistrationComplete?: boolean }>()
  const history = useHistory()

  const [pagedCreatorsInBusinessLazyQuery] = usePagedCreatorsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setCreators(data.PagedCreatorsInBusiness?.data ?? [])
        setLastPage(data.PagedCreatorsInBusiness?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [affiliationsInBusinessLazyQuery] = useAffiliationsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAffiliations(data.AffiliationsInBusiness)
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [registerNewCreatorMutation] = useRegisterNewCreatorMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        setNewCreatorEmail('')
        setNewCreatorAffiliation('')

        setFlashMessage({ type: 'success', message: '登録が完了しました' })

        pagedCreatorsInBusinessLazyQuery({
          variables: {
            first: 20,
            page: currentPage,
          },
        })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const onChangeNewCreatorEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewCreatorEmail(event.target.value)
  }
  const onChangeNewCreatorAffiliation = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setNewCreatorAffiliation(event.target.value)
  }

  const onClickCreatorRegistration = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await registerNewCreatorMutation({
      variables: {
        input: {
          email: newCreatorEmail,
          affiliation_id: newCreatorAffiliation,
        },
      },
    })
  }

  const openCreatorRegistrationModal = (): void => {
    setModalVisible(true)
  }

  const closeCreatorRegistrationModal = (): void => {
    setModalVisible(false)
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    setLoading(true)
    pagedCreatorsInBusinessLazyQuery({
      variables: {
        first: 20,
        page: Number(pageParam),
      },
    })
    affiliationsInBusinessLazyQuery()
  }, [pagedCreatorsInBusinessLazyQuery, affiliationsInBusinessLazyQuery, props.location.search])

  useEffect(() => {
    if (location.state) {
      if (location.state.deleteCreator === true) {
        // クリエイターアカウント削除からの画面遷移
        setFlashMessage({ message: 'アカウント削除が完了しました', type: 'success' })
      } else if (location.state.creatorRegistrationComplete === true) {
        // 所属詳細でのクリエイター仮登録からの画面遷移
        setFlashMessage({ message: '仮登録が完了しました', type: 'success' })
      }
    }
  }, [location.state])

  // location.state.deleteCreator = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (location.state) {
      history.replace({ ...location, state: undefined })
    }
  }, [history, location])

  return (
    <>
      <Meta title="クリエイター一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <BusinessCreators
          creators={creators}
          lastPage={lastPage}
          currentPage={currentPage}
          openCreatorRegistrationModal={openCreatorRegistrationModal}
        />
      </BusinessTwoColumnLayout>
      {modalVisible && (
        <CreatorRegistrationModalTpl
          affiliations={affiliations}
          newCreatorEmail={newCreatorEmail}
          newCreatorAffiliation={newCreatorAffiliation}
          onChangeNewCreatorEmail={onChangeNewCreatorEmail}
          onChangeNewCreatorAffiliation={onChangeNewCreatorAffiliation}
          onClickCreatorRegistration={onClickCreatorRegistration}
          closeModal={closeCreatorRegistrationModal}
        />
      )}
    </>
  )
}
