import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Icon } from '../../atoms'

import { mediaPc } from '../../../lib/MediaQuery'
import modalCloseIcon from '../../../assets/images/icon/modal_close.png'

type Props = {
  children?: React.ReactNode
  closeModal: () => void
}

const Wrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  .overlay-for-close {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;
  }
  .close-button {
    position: absolute;
    width: 8vw;
    max-width: 30px;
    height: 8vw;
    max-height: 30px;
    top: 20px;
    right: 20px;
    z-index: 20;
    cursor: pointer;
  }
  .contents {
    width: 90vw;
    max-width: 550px;
    max-height: 83vh;
    position: relative;
    z-index: 21;
    overflow: scroll;
    background: ${(props): string => props.theme.backgroundColors.gray};
  }

  ${mediaPc`
    .close-button {
      width: 60px;
      height: 60px;
      max-width: initial;
      max-height: initial;
      top: 44px;
      right: 44px;
    }
     .contents {
        width: 100%;
        max-width: 780px;
        max-height: 90vh;
     }
  `}
`

export const Modal: React.FC<Props> = ({ children, closeModal }) => {
  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'

    return () => {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  })

  return (
    <Wrapper>
      <div className="overlay-for-close" onClick={closeModal} />
      <div className="close-button" onClick={closeModal}>
        <Icon src={modalCloseIcon} />
      </div>
      <div className="contents">{children}</div>
    </Wrapper>
  )
}
