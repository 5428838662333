import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Icon, SnsIcon } from '../../atoms'
import { HASHTAG } from '../../../const'

import { Creator } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

import defaultIcon from '../../../assets/images/icon/man_icon_default.png'
import twitterBlue from '../../../assets/images/icon/twitter_blue.png'
import favoritePink from '../../../assets/images/icon/favorite_pink.png'
import favoriteRed from '../../../assets/images/icon/favorite_red.png'
import paperAirplane from '../../../assets/images/icon/paper_airplane.png'

import { config } from '../../../config'

type Props = {
  className?: string
  creator: Creator
  isUserValid?: boolean
  isCreatorTopPage?: boolean
  displayRequestButton?: boolean
  onClickFavorite?: (creatorId: string) => void
  onClickUnfavorite?: (creatorId: string) => void
  onClickProductRequestButton?: () => void
}

const Wrapper = styled.div`
  padding: 5.3vw;
  background-color: ${(props): string => props.theme.backgroundColors.primary};
  .inner {
    .info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .icon {
        border-radius: 50%;
        width: 26.6vw;
        height: 26.6vw;
        object-fit: cover;

        &.clickable {
          cursor: pointer;
        }
      }
      .profile-column {
        color: ${(props): string => props.theme.textColors.white};
        width: 55.6vw;
        margin-bottom: 4vw;
        letter-spacing: 0.05em;
        .name {
          margin-bottom: 2vw;
          font-size: 3.735vw;
          font-weight: bold;
        }
        .profile {
          width: 100%;
          font-size: 3.2vw;
        }
      }
      ul.sns {
        display: flex;
        width: 100%;
        margin-bottom: 4.8vw;
        padding-right: 6.1vw;
        margin-left: 30vw;
        justify-content: space-between;
        li {
          width: 9.6vw;
          margin-right: 3.5vw;
        }
      }
    }
    .button-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .favorite-button {
        display: block;
        width: 42.9vw;
        height: 9.6vw;
        padding-left: 5vw;
        margin-right: auto;
        line-height: 9.6vw;
        text-align: center;
        background-repeat: no-repeat;
        background-position: 3vw;
        background-size: 4.8vw;
        border-radius: 18.6vw;
        font-size: 3.2vw;
        font-weight: bold;
        background-color: ${(props): string => props.theme.backgroundColors.gray};

        &.add {
          background-image: url(${favoritePink});
          color: ${(props): string => props.theme.textColors.black};
        }

        &.remove {
          background-image: url(${favoriteRed});
          color: ${(props): string => props.theme.textColors.red};
        }
      }

      .twitter-button {
        display: block;
        width: 42.9vw;
        height: 9.6vw;
        padding-left: 5vw;
        line-height: 9.6vw;
        text-align: center;
        background: url(${twitterBlue}) 3vw center / 5vw auto no-repeat ${(props): string => props.theme.backgroundColors.gray};
        color: ${(props): string => props.theme.textColors.black};
        border-radius: 18.6vw;
        font-size: 3.2vw;
        font-weight: bold;
      }
    }
  }
  ${mediaPc`
    width: 240px;
    display: flex;
    padding: 0;
    margin-bottom: 0;
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
    .inner {
      color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
      .info {
        border-radius: 4px;
        margin: 0 0 20px;
        padding: 0 15px 17px;
        background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
        justify-content: center;
        .icon {
          width: 120px;
          height: 120px;
          margin: -30px auto 0;
        }
        .profile-column {
          color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
          width: 100%;
          margin-bottom: 0;
          letter-spacing: 0.05em;
          .name {
            text-align:center;
            font-size: 14px;
            font-weight: bold;
            padding: 15px 0 ;
            margin: 0 auto 0;
          }
          .profile {
            width: 100%;
            margin: 0 auto 24px;
            font-size: 13px;
            line-height: 24px;
            padding: 0 0 17px;
            }
          }
          ul.sns {
            padding: 0 5px;
            margin: 0 auto 19px;
            li {
              width: auto;
              margin:0;
            }
          }
        }
        .button-wrapper {
          display: block;
          .favorite-button {
            width: 100%;
            height:36px;
            margin: 0 auto 10px;
            padding:0;
            border-radius: 18px;
            line-height:36px;
            font-size:12px;
            background-size: 18px;
            background-position: 10px;
          }
          .twitter-button {
            width: 100%;
            height:36px;
            padding:0;
            border-radius: 18px;
            line-height:36px;
            font-size:12px;
            background-size: 18px;
            background-position: 10px;
          }
        }
      }
      .request-button {
        width: 100%;
        height: 46px;
        padding: 0 0 0 25px;
        border-radius: 30px;
        background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.buttonColors.red};
        background-image: url(${paperAirplane});
        background-repeat: no-repeat;
        background-position: 18px;
        background-size: 24px;
        color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.white};
        font-weight: bold;
      }
  `}
`

export const CreatorInfoSideColumn: React.FC<Props> = ({
  className = '',
  creator,
  isUserValid,
  isCreatorTopPage = false,
  displayRequestButton = false,
  onClickFavorite = () => {},
  onClickUnfavorite = () => {},
  onClickProductRequestButton,
}) => {
  const history = useHistory()

  const onClickIcon = (): void => {
    if (userType() === 'business') {
      history.push(`/business/creator/${creator.id}/user`)
    }
    if (userType() === 'creator') {
      history.push(`/creator/${creator.id}/user`)
    }
    if (userType() === 'user') {
      history.push(`/creators/${creator.id}`)
    }
  }

  return (
    <Wrapper className={className}>
      <div className="inner">
        <div className="info">
          <Icon
            className={isCreatorTopPage ? 'icon' : 'clickable icon'}
            src={creator.icon_url !== '' ? creator.icon_url : defaultIcon}
            alt={creator.name}
            onClick={isCreatorTopPage ? undefined : onClickIcon}
          />
          <div className="profile-column">
            <p className="name">{creator.name}</p>
            <p className="profile">{creator.profile}</p>
          </div>
          <ul className="sns">
            <li>
              {creator.twitter_username && (
                <a href={`https://twitter.com/${creator.twitter_username}`}>
                  <SnsIcon className="pc" sns="twitter" />
                  <SnsIcon className="sp" sns="twitter" type="white" />
                </a>
              )}
            </li>
            <li>
              {creator.instagram_username && (
                <a href={`https://www.instagram.com/${creator.instagram_username}`}>
                  <SnsIcon className="pc" sns="instagram" />
                  <SnsIcon className="sp" sns="instagram" type="white" />
                </a>
              )}
            </li>
            <li>
              {creator.youtube_channel_url && (
                <a href={`${creator.youtube_channel_url}`}>
                  <SnsIcon className="pc" sns="youtube" />
                  <SnsIcon className="sp" sns="youtube" type="white" />
                </a>
              )}
            </li>
            <li>
              {creator.kindle_author_url && (
                <a href={`${creator.kindle_author_url}`}>
                  <SnsIcon className="pc" sns="kindle" />
                  <SnsIcon className="sp" sns="kindle" type="white" />
                </a>
              )}
            </li>
          </ul>
          <div className="button-wrapper">
            {userType() === 'user' &&
              isUserValid &&
              (creator.is_favorite ? (
                <button
                  className="favorite-button remove"
                  onClick={() => {
                    onClickUnfavorite(creator.id)
                  }}
                >
                  お気に入り登録済み
                </button>
              ) : (
                <button
                  className="favorite-button add"
                  onClick={() => {
                    onClickFavorite(creator.id)
                  }}
                >
                  お気に入り登録
                </button>
              ))}
            <a
              href={`http://twitter.com/share?url=${config.shareEndpoint}/creator/${creator.id}&hashtags=${HASHTAG}`}
              className="twitter-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              シェアする
            </a>
          </div>
        </div>
        {userType() === 'user' && displayRequestButton && (
          <button className="request-button pc" onClick={onClickProductRequestButton}>
            販売リクエストを送る
          </button>
        )}
      </div>
    </Wrapper>
  )
}
