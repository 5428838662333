import React from 'react'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'
import { OrderedProductStatus, OrderedProductLiveTalkStatus } from '../../types/graphql'

type Props = {
  className?: string
  orderedProductStatus: OrderedProductStatus
  orderedProductLiveTalkStatus?: OrderedProductLiveTalkStatus | null
  displayLong?: boolean
  onClick?: () => void
}

const getBackgroundColor = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  if (props.orderedProductLiveTalkStatus) {
    switch (props.orderedProductLiveTalkStatus) {
      case OrderedProductLiveTalkStatus.UnTalked:
      case OrderedProductLiveTalkStatus.Waiting:
        return `
          background: ${props.theme.backgroundColors.red};
        `
      case OrderedProductLiveTalkStatus.Talking:
        if (props.orderedProductStatus === OrderedProductStatus.Undelivered)
          return `
      background: ${props.theme.backgroundColors.red};
      `
        if (props.orderedProductStatus === OrderedProductStatus.Delivered)
          return `
      background: ${props.theme.backgroundColors.blue};
      `
        return ''

      case OrderedProductLiveTalkStatus.Talked:
        return `
          background: ${props.theme.backgroundColors.blue};
        `
      default:
        return `
          background: ${props.theme.backgroundColors.red};
        `
    }
  } else {
    switch (props.orderedProductStatus) {
      case OrderedProductStatus.Undelivered:
        return `
          background: ${props.theme.backgroundColors.red};
        `
      case OrderedProductStatus.Processing:
        return `
          background: ${props.theme.backgroundColors.statusGray};
        `
      case OrderedProductStatus.Delivered:
        return `
          background: ${props.theme.backgroundColors.blue};
        `
      default:
        return `
          background: ${props.theme.backgroundColors.red};
        `
    }
  }
}

const getButtonStyle = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  if (props.onClick !== undefined) {
    return `
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        opacity: 0.8;
      }
    `
  } else {
    return ''
  }
}

const StyledOrderedProductStatus = styled.div<Props>`
  width: 100%;
  height: 7.4vw;
  font-size: 3.2vw;
  line-height: 7.4vw;
  color: ${(props): string => props.theme.textColors.white};
  text-align: center;
  border-radius: 4px;
  display: inline-block;

  ${(props) => getBackgroundColor(props)}
  ${(props) => getButtonStyle(props)}

  ${mediaPc`
    height: 28px;
    font-size: 12px;
    line-height: 28px;
  `}
`

export const OrderStatus: React.FC<Props> = ({
  className = '',
  orderedProductStatus,
  orderedProductLiveTalkStatus,
  displayLong = false,
  onClick,
}) => {
  const text = (): string => {
    if (orderedProductLiveTalkStatus) {
      switch (orderedProductLiveTalkStatus) {
        case OrderedProductLiveTalkStatus.UnTalked:
          return 'トーク前'
        case OrderedProductLiveTalkStatus.Waiting:
          return '待機中'
        case OrderedProductLiveTalkStatus.Talking:
          return 'トーク中'
        case OrderedProductLiveTalkStatus.Talked:
          return 'トーク終了'
        default:
          return ''
      }
    }
    if (!orderedProductLiveTalkStatus && displayLong) {
      switch (orderedProductStatus) {
        case OrderedProductStatus.Undelivered:
          return '未納品状態'
        case OrderedProductStatus.Processing:
          return '処理中'
        case OrderedProductStatus.Delivered:
          return '納品済み'
        default:
          return ''
      }
    }
    if (!orderedProductLiveTalkStatus && !displayLong) {
      switch (orderedProductStatus) {
        case OrderedProductStatus.Undelivered:
          return '未納品'
        case OrderedProductStatus.Processing:
          return '処理中'
        case OrderedProductStatus.Delivered:
          return '納品済'
        default:
          return ''
      }
    }
    return ''
  }

  return (
    <StyledOrderedProductStatus
      className={className}
      orderedProductStatus={orderedProductStatus}
      orderedProductLiveTalkStatus={orderedProductLiveTalkStatus}
      onClick={onClick}
    >
      {text()}
    </StyledOrderedProductStatus>
  )
}
