import React from 'react'
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  term?: string
  children?: React.ReactNode
  type?: 'red' | 'blue' | 'primary'
}

const getTextColor = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  switch (props.type) {
    case 'red':
      return `${props.theme.textColors.red}`
    case 'blue':
      return `${props.theme.textColors.blue}`
    case 'primary':
      return `${props.theme.textColors.primary}`
    default:
      return `${props.theme.buttonColors.black}`
  }
}

const StyledOrderItem = styled.dl<Props>`
  dt {
    margin: 0 0 1vw 0;
    font-size: 2.67vw;
    color: ${(props): string => props.theme.textColors.gray};
    letter-spacing: 0.05em;
  }
  dd {
    font-size: 3.2vw;
    font-weight: bold;
    color: ${(props): string => getTextColor(props)};
  }

  ${mediaPc`
    display: flex;
    align-items: center;

    dt {
      width: 92px;
      margin: 0 13px 0 0;
      font-size: 14px;
    }

    dd {
      font-size: 14px;
    }
  `}
`

export const OrderItem: React.FC<Props> = ({ className = '', term = '', children, type }) => {
  return (
    <StyledOrderItem className={className} type={type}>
      <dt>{term}</dt>
      <dd>{children}</dd>
    </StyledOrderItem>
  )
}
