import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../../atoms'
import { CONTACT_URL, CREATOR_AND_BUSINESS_FAQ_URL } from '../../../../const'
import { mediaPc } from '../../../../lib/MediaQuery'
import topBgPc from '../../../../assets/images/lp/top_bg_pc.png'
import topBgSp from '../../../../assets/images/lp/top_bg_sp.png'
import liveTalkText from '../../../../assets/images/lp/live_talk_text.png'
import topImage from '../../../../assets/images/lp/top_image.png'
import speechBalloon from '../../../../assets/images/lp/speech_balloon.png'
import liveTalkTheme01 from '../../../../assets/images/lp/live_talk_theme01.png'
import liveTalkTheme02 from '../../../../assets/images/lp/live_talk_theme02.png'
import liveTalkTheme03 from '../../../../assets/images/lp/live_talk_theme03.png'

const Wrapper = styled.div`
  .contents-title {
    font-weight: bold;
    position: relative;
    text-align: center;
    font-size: 4.8vw;
    margin: 0 0 13vw;
    &::after {
      content: '';
      width: 177px;
      height: 6px;
      margin: auto;
      position: absolute;
      bottom: -22px;
      right: 0;
      left: 0;
      border-radius: 3px;
      background-color: ${(props): string => props.theme.backgroundColors.primary};
    }
  }
  .top {
    padding: 18.6vw 0 0;
    background-image: url(${topBgSp});
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 184vw;
    .inner {
      color: ${(props): string => props.theme.textColors.white};
      padding: 0 4.35vw;
      .lead-text {
        width: 84.8vw;
        height: 16.8vw;
        margin: 0 auto;
        padding: 2.4vw 0;
        background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
        color: ${(props): string => props.theme.textColors.primary};
        font-size: 4.2vw;
        font-weight: bold;
        text-align: center;
        border-radius: 15px 15px 15px 0;
      }
      .top-text {
        .text {
          margin: 0 auto 7.2vw;
          font-size: 3.7vw;
          font-weight: bold;
          letter-spacing: 0.1em;
          line-height: 1.5;
          text-align: center;
        }
        img {
          width: 82.4vw;
          margin: 0 auto 9.6vw;
        }
      }
      .top-image {
        width: 100%;
        margin: 0 0 5.6vw;
      }
    }
  }
  .about {
    width: 81vw;
    margin: 0 auto;
    padding: 21vw 0;
    text-align: center;
    p {
      font-weight: bold;
      font-size: 3.2vw;
      line-height: 170%;
      letter-spacing: 0.1em;
    }
  }
  .feature {
    padding: 21vw 0 15.6vw;
    background-color: ${(props): string => props.theme.backgroundColors.primary};
    .inner {
      width: 88.9vw;
      margin: 0 auto;
      .title {
        width: 74.1vw;
        height: 24.4vw;
        margin: 0 auto 8.3vw;
        padding: 7vw 0 0;
        font-size: 4.6vw;
        font-weight: bold;
        text-align: center;
        background-image: url(${speechBalloon});
        background-size: cover;
        background-repeat: no-repeat;
      }
      .block {
        background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
        margin: 0 0 10.4vw;
        padding: 7.8vw 8.26vw 7.54vw;
        border-radius: 10px;
        box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, 0.1);
        div {
          color: ${(props): string => props.theme.textColors.primary};
          text-align: center;
          padding: 0 0 2.34vw;
          letter-spacing: 0.1em;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-size: 4.8vw;
            font-weight: bold;
            line-height: 5.6vw;
            text-align: left;
            &.num {
              font-size: 10.2vw;
              letter-spacing: 0;
              font-weight: bold;
              padding: 0 3.38vw 0 0;
            }
          }
        }
        p {
          font-size: 3.12vw;
          text-align: left;
          letter-spacing: 0.15em;
          line-height: 170%;
        }
      }
    }
  }
  .theme {
    padding: 21vw 0 15.6vw;
    .explanatory-text {
      padding: 0 0 18.2vw;
      font-size: 3.12vw;
      text-align: center;
      font-weight: bold;
    }
    .row {
      width: 87.1vw;
      margin: 0 auto 18.2vw;
      display: flex;
      position: relative;
      .text {
        width: 66.3vw;
        height: 66.3vw;
        right: 0;
        border-radius: 50%;
        background-color: ${(props): string => props.theme.backgroundColors.lightGreen};
        position: absolute;
        display: flex;
        flex-flow: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        p {
          letter-spacing: 0.1em;
          font-size: 3.12vw;
          width: 48.36vw;
          &.catchy-text {
            padding: 0 0 3.12vw;
            font-weight: bold;
            font-size: 4.16vw;
            line-height: 170%;
          }
        }
      }
      .image {
        position: relative;
        margin: 10.6vw 0 0;
        z-index: 2;
        img {
          width: 25.46vw;
        }
        .speech-ballon {
          width: max-content;
          padding: 2.6vw 3.64vw;
          position: absolute;
          left: 2.6vw;
          bottom: 3.9vw;
          background-color: ${(props): string => props.theme.backgroundColors.primary};
          color: ${(props): string => props.theme.textColors.white};
          border-radius: 0px 15px 15px 15px;
          font-weight: bold;
          font-size: 3.12vw;
          letter-spacing: 0.1em;
        }
      }
      &.reverse {
        display: flex;
        justify-content: flex-end;
        .image {
          .speech-ballon {
            left: auto;
            right: 2.6vw;
            bottom: -3.38vw;
            border-radius: 15px 0px 15px 15px;
          }
        }
        .text {
          left: 0;
          right: auto;
          bottom: 0;
        }
      }
    }
  }
  .to-register-form {
    padding: 4.6vw 0;
    background-color: ${(props): string => props.theme.backgroundColors.gray};
    .inner {
      width: 87.1vw;
      margin: 0 auto;
      padding: 7.8vw 5.98vw 9.62vw;
      border-radius: 10px;
      background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
      .title {
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.1em;
        font-size: 5.4vw;
      }
      .button {
        display: block;
        margin: 6.76vw auto 7.54vw;
        font-weight: bold;
      }
      .about-register {
        p {
          font-size: 3.12vw;
          font-weight: bold;
          line-height: 170%;
          letter-spacing: 0.1em;
          &:first-child {
            font-size: 3.64vw;
            padding: 0 0 4.68vw;
          }
          &:last-child {
            font-weight: normal;
            padding: 5.2vw 0 0;
          }
          a {
            color: ${(props): string => props.theme.textColors.primary};
          }
        }
      }
    }
  }
  ${mediaPc`
    .contents-title {
      position: relative;
      font-size: 24px;
      margin: 0 0 73px;
      &::after {
        content: '';
        position: absolute;
        width: 183px;
      }
    }
    .top {
      padding: 74px 0 0;
      background-image:url(${topBgPc});
      background-size:cover;
      height:565px;
      .inner {
        position: relative;
        width:1040px;
        margin:0 auto;
        .lead-text {
          width: 624px;
          height: 67px;
          padding:0;
          border-radius: 15px 15px 15px 0;
          font-size: 24px;
          line-height: 67px;
          letter-spacing: 0.1em;
        }
        .top-text {
          width: 429px;
          padding: 97px 0 0;
          .text {
            margin: 0 0 23px;
            font-size: 18px;
          }
          img {
            width: 429px;
          }
        }
        .top-image {
          width: 41vw;
          min-width:470px;
          max-width: 590px;
          margin: 0;
          position: absolute;
          left: 520px;
          top: 50px;
        }
      }
    }
    .about {
      width: auto;
      padding: 149px 0 110px;
      p {
        font-size: 18px;
        width: 694px;
        margin: 0 auto;
      }
    }
    .feature {
      padding: 80px 0 60px;
      .inner {
        .title {
          width: 316px;
          height: 105px;
          margin: 0 auto 39px;
          padding: 24px 0 0;
          font-size: 24px;
        }
        .block {
          width: 750px;
          margin:0 auto 60px;
          padding:31px 0 53px;
          div {
            padding:0 0 15px;
            p {
              font-size: 24px;
              &.num {
                font-size: 42px;
                padding: 0 10px 0 0;
              }
            }
          }
          p {
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
    .theme {
      padding: 110px 0 110px;
      .explanatory-text {
        font-size:16px;
        padding: 0 0 54px;
      }
      .row {
        width: 660px;
        margin: 0 auto 80px;
        .text {
          width: 355px;
          height: 355px;
          right: 23px;
          p {
            font-size: 14px;
            width: 263px;
            &.catchy-text {
              font-size: 24px;
              padding: 0 0 10px;
            }
          }
        }
        .image {
          margin: 42px 0 0 23px;
          img {
            width: 178px;
          }
          .speech-ballon {
            padding: 19px 16px;
            font-size: 14px;
            left: 65px;
            bottom: 60px;
          }
        }
        &.reverse {
          .image {
            margin: 0;
            img {
              width: 178px;
            }
            .speech-ballon {
              left: auto;
              right: 23px;
              bottom: 28px;
            }
          }
        }
      }
    }
    .to-register-form {
      padding: 41px 0 91px;
      .inner {
        max-width: 705px;
        padding: 43px 70px 55px;
        .title {
          font-size:24px;
        }
        .button {
          width: 310px;
          height 50px;
          margin: 19px auto 30px;
        }
        .about-register {
          p {
            font-size: 14px;
            &:first-child {
              font-size: 16px;
              padding: 0 0 13px;
            }
            &:last-child {
              padding: 23px 0 0;
            }
          }
        }
      }
    }
  `}
`
export const CreatorLiveTalkLpTpl: React.FC = () => {
  const history = useHistory()
  const goToRegisterPage = (): void => {
    history.push({ pathname: `/creator/product/register`, state: { fromLiveTalkLp: true } })
  }
  return (
    <Wrapper>
      <div className="top">
        <div className="inner">
          <div className="lead-text pc">2人だけの時間を過ごせるオンラインコンテンツ</div>
          <div className="top-text">
            <p className="text">会えない時でもファンとの繋がりを</p>
            <img src={liveTalkText} alt="liveTalk" />
          </div>
          <img className="top-image" src={topImage} alt="liveTalk" />
          <div className="lead-text sp">
            2人だけの時間を過ごせる
            <br />
            オンラインコンテンツ
          </div>
        </div>
      </div>
      <div className="about">
        <div className="contents-title">ライブトーク商品とは？</div>
        <p>
          ファンの方と1対１でビデオチャットができるサービスです。
          <br className="pc" />
          アプリ等のダウンロードなしにスマホやタブレット、パソコンのブラウザからそのまま通話をお楽しみいただけます。
        </p>
      </div>
      <div className="feature">
        <div className="inner">
          <div className="title">ライブトークの魅力</div>
          <div className="block">
            <div>
              <p className="num">01</p>
              <p>
                会えなくてもできる
                <br className="sp" />
                コミュニケーション
              </p>
            </div>
            <p>
              直接会うことのできない時もファンの方と顔を合わせてお話することができます。
              <br className="pc" />
              周りを気にせず二人で顔を見て話すことで信頼関係を深めることができます。
            </p>
          </div>
          <div className="block">
            <div>
              <p className="num">02</p>
              <p>
                自分の好きな場所、
                <br className="sp" />
                好きな時間で
              </p>
            </div>
            <p>
              オンラインで行われるため、自宅など好きな場所でファンの方と
              <br className="pc" />
              お話しすることができます。
              <br className="pc" />
              また、ライブトーク商品登録する際に開始時間を設定できるので、
              <br className="pc" />
              自分の空いた時間、好きな時間にご利用いただけます。
            </p>
          </div>
          <div className="block">
            <div>
              <p className="num">03</p>
              <p>
                安心して話せる
                <br className="sp" />
                コンテンツ
              </p>
            </div>
            <p>
              安全のためライブトークは全て録画されています。
              <br className="pc" />
              お相手からの不快な言動には厳正に対処しますので安心してご利用いただけます。
            </p>
          </div>
        </div>
      </div>
      <div className="theme">
        <div className="contents-title">テーマを決めてさらに楽しい時間に</div>
        <p className="explanatory-text">
          テーマを決めて販売することで、2人で盛り上がったり、
          <br className="sp" />
          いつもとは違う一面を見せることができます
        </p>
        <div className="row">
          <div className="image">
            <img className="top-image" src={liveTalkTheme01} alt="普段と違う服装で" />
            <div className="speech-ballon">コスプレしてお話します！</div>
          </div>
          <div className="text">
            <p className="catchy-text">普段と違う服装で</p>
            <p>
              部屋着や水着、浴衣など
              <br />
              ファンが喜ぶ、いつもと違う服装で！
            </p>
          </div>
        </div>
        <div className="row reverse">
          <div className="text">
            <p className="catchy-text">プライベートな一面も</p>
            <p>寝起き、半身浴中や料理してる姿など2人だけのライブトークだから見せれるテーマで特別な時間に！</p>
          </div>
          <div className="image">
            <img className="top-image" src={liveTalkTheme02} alt="プライベートな一面も" />
            <div className="speech-ballon">
              ベットに横になったまま
              <br />
              一緒におはようの挨拶しませんか？
            </div>
          </div>
        </div>
        <div className="row">
          <div className="image">
            <img className="top-image" src={liveTalkTheme03} alt="2人きりの撮影会" />
            <div className="speech-ballon">好きなポーズ教えてください♪</div>
          </div>
          <div className="text">
            <p className="catchy-text">2人きりの撮影会</p>
            <p>
              ライブトークだからできる
              <br />
              2人きりのオンライン撮影会
            </p>
          </div>
        </div>
      </div>
      <div className="to-register-form">
        <div className="inner">
          <p className="title">
            ライブトーク商品を
            <br className="sp" />
            販売してみよう！
          </p>
          <Button buttonType="secondary" className="button" onClick={goToRegisterPage}>
            ライブトーク商品を販売する
          </Button>
          <div className="about-register">
            <p>商品登録について</p>
            <p>
              ・平均単価は1分あたり1000〜1500円程度です。
              <br />
              ・1分~10分のライブトーク商品の販売ができます。
              <br />
              ・時間を指定して販売するものなので、前後のご予定を考えて確実にお話ができる時間で販売の設定をしてください。
              <br />
              ・端末に通信制限がかかっていると、ライブトークの映像や音声がうまくつながらない可能性があります。ライブトーク前に通信制限がかかっていないか確認をお願い致します。
              <br />
            </p>
            <p>
              その他よくある質問は
              <a href={CREATOR_AND_BUSINESS_FAQ_URL} target="_blank" rel="noopener noreferrer">
                こちら
              </a>
              <br />
              お問い合わせは
              <a href={CONTACT_URL} target="_blank" rel="noopener noreferrer">
                こちら
              </a>
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
