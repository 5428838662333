import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { LoadingCircle, FlashMessage } from '../../../components/atoms'
import { BusinessTwoColumnLayout, BusinessCreatorSalesPerOrderTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import {
  SalesPerMonth,
  SalesPerOrder,
  Creator,
  useAffiliationInBusinessLazyQuery,
  usePagedSalesPerOrderLazyQuery,
  useActiveCreatorsInAffiliationLazyQuery,
  useActiveCreatorsInBusinessLazyQuery,
} from '../../../types/graphql'
import { FlashMessageType } from '../../../types/myTypes'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'

type Props = {} & RouteComponentProps<{ id: string; month: string }>

export const BusinessCreatorSalesPerOrder: React.FC<Props> = (props) => {
  const [affiliationName, setAffiliationName] = useState<string | undefined>('')
  const [month, setMonth] = useState<SalesPerMonth['month']>('')
  const [sales, setSales] = useState<SalesPerOrder[]>([])
  const [creators, setCreators] = useState<Creator[]>([])
  const [selectedCreator, setSelectedCreator] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()

  // パラメータ類
  const searchParams = new URLSearchParams(props.location.search)
  const defaultAffiliationId = searchParams.get('affiliationId')
  const defaultPage = Number(searchParams.get('page'))
  const defaultCreatorId = props.match.params.id
  const defaultMonth = props.match.params.month

  const [affiliationInBusinessLazyQuery] = useAffiliationInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAffiliationName(data.AffiliationInBusiness.name)
    },
    onError: (e) => {
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [activeCreatorsInAffiliationLazyQuery] = useActiveCreatorsInAffiliationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCreators(data.ActiveCreatorsInAffiliation ?? [])
    },
    onError: (e) => {
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [activeCreatorsInBusinessLazyQuery] = useActiveCreatorsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCreators(data.ActiveCreatorsInBusiness ?? [])
    },
    onError: (e) => {
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [pagedSalesPerOrderLazyQuery] = usePagedSalesPerOrderLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSales(data.PagedSalesPerOrder?.data ?? [])
      setLastPage(data.PagedSalesPerOrder?.paginatorInfo.lastPage ?? 1)
      setLoading(false)
    },
    onError: (e) => {
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const onCreatorChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setLoading(true)
    const selectedCreatorId: string = e.target.value
    setSelectedCreator(selectedCreatorId)
    history.push(
      `/business/sales/affiliation/creator/${selectedCreatorId}${selectedCreatorId === '' ? '' : '/'}${month}${
        defaultAffiliationId ? `?affiliationId=${defaultAffiliationId}` : ''
      }`,
    )
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    setCurrentPage(defaultPage || 1)
    setSelectedCreator(defaultCreatorId ?? '')
    setMonth(defaultMonth)

    if (!defaultAffiliationId) {
      // マイページTOP or 全所属の売上一覧 or クリエイター売上一覧からの遷移
      setAffiliationName('全所属')
      activeCreatorsInBusinessLazyQuery()
    } else {
      // 所属を選択した売上一覧からの遷移
      // 「所属なし」の場合は所属ID: 0
      if (defaultAffiliationId !== '0') {
        affiliationInBusinessLazyQuery({
          variables: {
            affiliation_id: defaultAffiliationId,
          },
        })
      }
      activeCreatorsInAffiliationLazyQuery({
        variables: {
          affiliation_id: defaultAffiliationId === '0' ? null : defaultAffiliationId,
        },
      })
    }

    pagedSalesPerOrderLazyQuery({
      variables: {
        has_all_affiliation: !defaultAffiliationId && !defaultCreatorId,
        affiliation_id: defaultAffiliationId === '0' ? null : defaultAffiliationId,
        creator_id: defaultCreatorId ?? '',
        input: {
          year_month: defaultMonth,
        },
        first: 40,
        page: defaultPage,
      },
    })
  }, [
    activeCreatorsInAffiliationLazyQuery,
    activeCreatorsInBusinessLazyQuery,
    affiliationInBusinessLazyQuery,
    defaultAffiliationId,
    defaultCreatorId,
    defaultMonth,
    defaultPage,
    pagedSalesPerOrderLazyQuery,
    props.location.search,
    props.match.params.id,
    props.match.params.month,
  ])

  return (
    <>
      <Meta title="売上内訳" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="sales">
        <BusinessCreatorSalesPerOrderTpl
          affiliationName={affiliationName}
          month={month}
          sales={sales}
          creators={creators}
          selectedCreator={selectedCreator}
          lastPage={lastPage}
          currentPage={currentPage}
          onCreatorChange={onCreatorChange}
        />
      </BusinessTwoColumnLayout>
    </>
  )
}
