import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { ErrorMessagesBox } from '../../../molecules'
import { PageSubtitle, TextInput, Button } from '../../../atoms'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  businessName?: string
  businessEmail?: string
  nameError: string | null
  emailError: string | null
  onChangeBusinessName: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeBusinessEmail: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClickUpdateBusinessUserAccount: () => void
}

const Wrapper = styled.div`
  .form-inner {
    padding: 7.4vw 0;
    background-color: ${(props): string => props.theme.backgroundColors.gray};
    .input-item {
      margin: 0 auto 4vw;
      .item-name {
        color: ${(props): string => props.theme.textColors.gray};
        font-size: 2.6vw;
        padding: 0 0 2.1vw;
      }
      .text-input {
        width: 100%;
        height: 10.6vw;
        margin: 0 auto;
        padding: 0 4vw;
        font-size: 3.2vw;
        &.error {
          border: 1px solid color: ${(props): string => props.theme.textColors.red};
        }
      }
    }
    .button {
      display: block;
      margin: 5.6vw auto 0;
      font-size: 3.2vw;
      font-weight: bold;
    }
    .error-messages {
      margin-top: 5.5vw;
      text-align: left;
    }

  }
  ${mediaPc`
  background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
  .form-inner {
    margin: 15px 0;
    padding:28px 0;
    .input-item {
      max-width:320px;
      margin-bottom: 15px;
      .item-name {
        font-size: 10px;
        padding: 0 0 10px;
      }
      .text-input {
        width: 320px;
        height: 40px;
        margin: 0 auto;
        padding: 0 15px;
        font-size: 12px;
      }
    }
    .button {
      width: 200px;
      height: 30px;
      line-height: 30px;
      margin: 30px auto 0;
      padding: 0 15px;
      font-size: 12px;
    }
    .error-messages {
      width: 320px;
      margin: 25px auto 0px;
      padding: 10px 15px;
      text-align: left;
      p {
        font-size: 12px;
      }
    }
  }
 `}
`

export const BusinessMypageEditTpl: React.FC<Props> = ({
  businessName,
  businessEmail,
  nameError,
  emailError,
  onChangeBusinessName,
  onChangeBusinessEmail,
  onClickUpdateBusinessUserAccount,
}) => {
  const errors: string[] = []
  if (nameError) {
    errors.push(nameError)
  }
  if (emailError) {
    errors.push(emailError)
  }

  const isDisable = (): boolean => {
    if (errors.length > 0 || !businessName || !businessEmail) {
      return true
    }
    return false
  }

  return (
    <Wrapper>
      <PageSubtitle subtitle="アカウント情報編集" />
      <div className="form-inner">
        <div className="input-item">
          <p className="item-name">ビジネスアカウント</p>
          <TextInput
            className="text-input"
            placeholder="ビジネスアカウントを入力してください"
            type="text"
            value={businessName}
            error={!!nameError}
            onChange={onChangeBusinessName}
          />
        </div>
        <div className="input-item">
          <p className="item-name">メールアドレス</p>
          <TextInput
            className="text-input"
            placeholder="メールアドレスを入力してください"
            type="email"
            value={businessEmail}
            error={!!emailError}
            onChange={onChangeBusinessEmail}
          />
        </div>
        {errors.length > 0 && <ErrorMessagesBox className="error-messages" errors={errors} />}
        <Button className="button" onClick={onClickUpdateBusinessUserAccount} buttonType="primary" disabled={isDisable()}>
          更新
        </Button>
      </div>
    </Wrapper>
  )
}
