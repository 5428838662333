import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  src?: string
  alt?: string
}

const StyledProductImage = styled.img`
  width: 100%;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`

export const ProductImage: React.FC<Props> = ({ className = '', src = '', alt = '' }) => {
  return <StyledProductImage className={className} src={src} alt={alt} />
}
