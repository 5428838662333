import React from 'react'
import { NotFoundTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'

type Props = {}

export const NotFound: React.FC<Props> = () => {
  return (
    <>
      <Meta title="ページが見つかりませんでした" />
      <NotFoundTpl />
    </>
  )
}
