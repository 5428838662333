import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { SubtitleWithButton, SubtitleWithLink, CreatorList } from '../../../molecules'
import { Creator } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  className?: string
  creators: Creator[]
  openCreatorRegistrationModal: () => void
}

const Wrapper = styled.div`
  .creator-subtitle-sp {
    margin-bottom: 5vw;
  }

  .creator-subtitle-pc {
    display: none;
  }

  ${mediaPc`
    .creator-subtitle-sp {
      display: none;
    }

    .creator-subtitle-pc {
      display: flex;
      height: 34px;
      margin-bottom: 10px;
      border-bottom: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.black};

      .subtitle {
        font-size: 14px;
        font-weight: normal;
      }
    }
    
  `}
`

export const AffiliationCreators: React.FC<Props> = ({ className = '', creators = [], openCreatorRegistrationModal }) => {
  return (
    <Wrapper className={className}>
      <SubtitleWithButton
        subtitle={`所属クリエイター（${creators ? creators.length : 0}）`}
        buttonText="+ クリエイター新規追加"
        buttonType="red"
        onClick={openCreatorRegistrationModal}
        className="creator-subtitle-sp"
      />
      <SubtitleWithLink
        subtitle={`所属クリエイター（${creators ? creators.length : 0}）`}
        linkText="+ クリエイター新規追加"
        textType="red"
        onClick={openCreatorRegistrationModal}
        className="creator-subtitle-pc"
      />
      <CreatorList creators={creators} />
    </Wrapper>
  )
}
