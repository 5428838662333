import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PageSubtitle, Button, TextButton } from '../../atoms'
import { SubtitleWithLink, ProfileItem } from '../../molecules'

import { mediaPc } from '../../../lib/MediaQuery'
import { useUserLogout } from '../../../lib/Logout'

import { UserProfileContext } from '../../../context'

type Props = {
  isVisibleEditButton?: boolean
}

const Wrapper = styled.div`
  margin-bottom: 8.5vw;
  .title {
    margin-bottom: 2vw;
  }
  .data {
    padding: 5vw 5vw 7vw;
    background: ${(props): string => props.theme.backgroundColors.bodyBg};
    border-radius: 4px;
    dd {
      margin-bottom: 3vw;
    }
    .twitter > dd::before {
      margin-right: 5px;
      content: '@';
      line-height: 2.4em;
      font-size: 3.733vw;
      color: ${(props): string => props.theme.textColors.placeholder};
    }
    .edit {
      line-height: 2.4;
    }
  }
  ${mediaPc`
    width: 240px;
    margin-bottom: 0;
    padding: 0;
    .title {
      margin-bottom: 20px;
      .subtitle-text {
        font-size: 14px;
      }
    }
    .data {
      padding: 25px 20px;
      dd {
        margin-bottom: 15px;
        font-size: 14px;
        border: none;
        font-weight: bold;
      }
      .twitter > dd::before {
        font-size: 14px;
      }
      .edit {
        margin-bottom: 22px;
        font-size: 12px;
        line-height: 30px;
      }
      .logout {
        width: 50%;
        margin: 0 auto;
        font-weight: bold;
      }
    }
  `}
`

export const UserProfile: React.FC<Props> = ({ isVisibleEditButton = true }) => {
  const history = useHistory()
  const userProfile = useContext(UserProfileContext)

  const goToEdit = (): void => {
    history.push('/mypage/edit')
  }

  return (
    <Wrapper>
      <div className="sp title">
        <SubtitleWithLink subtitle="アカウント情報" linkText="ログアウト" onClick={useUserLogout()} />
      </div>
      <div className="pc title">
        <PageSubtitle subtitle="アカウント情報" className="subtitle-text" />
      </div>

      <div className="data">
        <dl>
          <ProfileItem className="user-profile" label="ユーザー名">
            {userProfile.user.name}
          </ProfileItem>
          <ProfileItem className="user-profile" label="メールアドレス">
            {userProfile.user.email}
          </ProfileItem>
          <ProfileItem className="user-profile twitter" label="twitterユーザー名">
            {userProfile.user.twitter_username || '未登録'}
          </ProfileItem>
        </dl>
        {isVisibleEditButton && (
          <Button buttonType="white" onClick={goToEdit} className="edit">
            編集
          </Button>
        )}
        <TextButton onClick={useUserLogout()} className="pc logout">
          ログアウト
        </TextButton>
      </div>
    </Wrapper>
  )
}
