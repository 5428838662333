import React from 'react'
import styled from 'styled-components'
import { ProductImage, MovieDuration, OrderStatus } from '../../atoms'
import { OrderItem } from '../../molecules'
import { mediaPc } from '../../../lib/MediaQuery'
import { OrderedProductStatus, OrderedProduct } from '../../../types/graphql'

import moment from 'moment'

type Props = {
  className?: string
  orderedProduct: OrderedProduct
}

const Wrapper = styled.div`
  padding: 3vw 2vw;
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  display: flex;
  justify-content: flex-start;
  position: relative;

  .ordered-product-image {
    width: 21.6vw;
    min-width: unset;
    margin-right: 5vw;
  }

  .order-info-items {
    .order-item {
      margin-bottom: 3.2vw;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.live-talk-schedule {
        dd {
          font-weight: normal;

          > p {
            display: inline-block;

            &:first-child {
              margin-right: 1.3vw;
            }
          }
        }
      }
    }
  }

  .ordered-product-status {
    width: 24.5vw;
    position: absolute;
    right: 2vw;
    bottom: 3vw;
  }
  .canceled-text {
    color: ${(props): string => props.theme.textColors.red};
    font-weight: bold;
    margin-top: 3.2vw;
  }

  ${mediaPc`
    padding: 0;

    .ordered-product-image {
      width: 81px;
      margin-right: 21px;
    }

    .order-info-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .order-item {
        margin-bottom: 0;

        &.live-talk-schedule {
          dd {
            >p {
              &:first-child {
                margin-right: 12px;
              }
            }
          }
        }
      }
    }

    .ordered-product-status {
      width: 88px;
      padding: 0;
      text-align: center;
      position: static;
    }
    .canceled-text {
      margin-top:0px;
    }
  `}
`

export const OrderInfo: React.FC<Props> = ({ className = '', orderedProduct }) => {
  const isLiveTalk = orderedProduct.product.is_live_talk

  return (
    <Wrapper className={className}>
      <ProductImage
        src={orderedProduct.product.thumbnail_image}
        alt={orderedProduct.product.name || ''}
        className="ordered-product-image"
      />
      <div className="order-info-items">
        <OrderItem term="商品タイトル" className="order-item">
          {orderedProduct.product.name || ''}
        </OrderItem>
        {isLiveTalk && (
          <OrderItem term="日付" className="order-item live-talk-schedule">
            <p>{moment(orderedProduct.product.live_talk?.started_at).format('YYYY/MM/DD')}</p>
            <p>
              {moment(orderedProduct.product.live_talk?.started_at).format('HH時mm分')}~
              {moment(orderedProduct.product.live_talk?.started_at)
                .add(orderedProduct.product.live_talk?.talk_minutes, 'minutes')
                .format('HH時mm分')}
            </p>
          </OrderItem>
        )}
        {!isLiveTalk && orderedProduct.status === OrderedProductStatus.Delivered && (
          <OrderItem term="納品期限" type="blue" className="order-item">
            {`${moment(orderedProduct.latest_delivered_product?.delivered_at).format('M/D H:mm')}に納品済み`}
          </OrderItem>
        )}
        {!isLiveTalk && orderedProduct.status !== OrderedProductStatus.Delivered && (
          <OrderItem term="納品期限" type="red" className="order-item">
            {`${moment(orderedProduct.delivery_limit_date).format('M/D H:mm')}までに納品してください`}
          </OrderItem>
        )}
        {orderedProduct.product.is_photo && (
          <OrderItem term="納品形式" type="primary" className="order-item">
            {orderedProduct.product.product_type_name}
          </OrderItem>
        )}
        {orderedProduct.product.is_movie && (
          <OrderItem term="納品形式" className="order-item">
            <MovieDuration duration={orderedProduct.product.movie_seconds} className="movie-duration" />
          </OrderItem>
        )}
        {isLiveTalk && (
          <OrderItem term="納品形式" type="primary" className="order-item">
            ライブトーク
            <br className="sp" />({orderedProduct.product.live_talk?.talk_minutes}分間)
          </OrderItem>
        )}
        {orderedProduct.status !== OrderedProductStatus.Canceled && (
          <OrderStatus
            orderedProductStatus={orderedProduct.status}
            orderedProductLiveTalkStatus={orderedProduct.live_talk_status}
            displayLong
            className="ordered-product-status"
          />
        )}
        {isLiveTalk && orderedProduct.status === OrderedProductStatus.Canceled && (
          <div className="canceled-text">不参加によりキャンセルされました</div>
        )}
      </div>
    </Wrapper>
  )
}
