import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageTitle } from '../../../atoms'
import { CreatorRegistrationForm } from '../../../organisms'

import { CreatorRegisterAccountInput, CreatorRegisterErrors } from '../../../../types/myTypes'
import { mediaPc } from '../../../../lib/MediaQuery'
import mypageIcon from '../../../../assets/images/icon/mypage_green.png'

type Props = {
  creator: CreatorRegisterAccountInput
  iconUrl: string
  errorItems: CreatorRegisterErrors
  onChangeForm: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
  onChangeIcon: (event: React.ChangeEvent<HTMLInputElement>) => void
  registerCreator: () => void
}

const Wrapper = styled.div`
  width: 100%;
  .title-wrapper {
    width: 100%;
    padding: 5vw 7.2vw;
    text-align: center;
    background: ${(props): string => props.theme.backgroundColors.gray};

    .page-title {
      width: auto;
      padding-left: 7vw;
      display: inline-block;
      letter-spacing: 0.1em;
      background: url('${mypageIcon}') left center no-repeat;
      background-size: 5vw;
    }
  }

  .content {
    width: 100vw;
    background: ${(props): string => props.theme.backgroundColors.gray};

    & > .inner {
      width: 100%;
      padding: 2vw 7.2vw 20vw;
    }

    .right-column {
      width: 100%;

      .tab {
        margin-bottom: 7.5vw;
      }
    }
  }

  ${mediaPc`
    .title-wrapper {
      padding: 80px 20px 45px;

      .page-title {
        padding-left: 50px;
        letter-spacing: 0.3em;
        background-size: 32px;
      }
    }

    .content {
      width: 100%;
      min-width: 1040px;

      & > .inner {
        width: 1040px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: space-between;
      }

      .right-column {
        width: 720px;

        .tab {
          margin-bottom: 16px;
        }

        .right-column-content {
          border-radius: 4px;
          min-height: 317px;
          padding: 25px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
        }
      }
    }
  `}
`

export const CreatorRegistrationTpl: React.FC<Props> = ({ creator, iconUrl, errorItems, onChangeForm, onChangeIcon, registerCreator }) => {
  return (
    <Wrapper>
      <div className="title-wrapper">
        <PageTitle title="アカウント情報登録" className="page-title" />
      </div>

      <div className="content">
        <div className="inner">
          <CreatorRegistrationForm
            creator={creator}
            iconUrl={iconUrl}
            errorItems={errorItems}
            onChangeForm={onChangeForm}
            onChangeIcon={onChangeIcon}
            registerCreator={registerCreator}
          />
        </div>
      </div>
    </Wrapper>
  )
}
