import React, { useState } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SliderProgressBar } from '../../molecules'

import 'swiper/swiper.scss'
import 'swiper/components/effect-fade/effect-fade.scss'
import 'swiper/components/pagination/pagination.scss'

import topSlideBg01 from '../../../assets/images/top/top_slide_bg01.png'
import topSlideBg02 from '../../../assets/images/top/top_slide_bg02.png'
import topSlideBgSp01 from '../../../assets/images/top/top_slide_bg_sp01.png'
import topSlideBgSp02 from '../../../assets/images/top/top_slide_bg_sp02.png'
import photo01 from '../../../assets/images/top/slider01.png'
import photo02 from '../../../assets/images/top/slider02.png'
import liveTalk from '../../../assets/images/lp/live_talk_theme01.png'
import liveTalkText from '../../../assets/images/lp/live_talk_text.png'
import liveTalkIcon from '../../../assets/images/icon/live_talk.png'
import mountain from '../../../assets/images/icon/mountain.png'
import heart from '../../../assets/images/icon/heart.png'
import present from '../../../assets/images/icon/present.png'

type Props = {}

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
  .lead-text {
    text-align: center;
    font-size: 5.72vw;
    font-weight: bold;
    line-height: 8.32vw;
    letter-spacing: 0.1em;
    position: absolute;
    top: 7.7vw;
    right: 0;
    left: 0;
    color: ${(props): string => props.theme.textColors.white};
    z-index: 7;
  }

  .slider-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition-duration: 1s;
    opacity: 0;
    &::before {
      width: 100%;
      height: 100%;
      background-color: rgba(202, 129, 148, 0.6);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
    }
    &.photo-bg {
      background-image: url(${topSlideBgSp01});
      opacity: 1;
    }
    &.live-talk-bg {
      background-image: url(${topSlideBgSp02});
      opacity: 1;
    }
  }
  .swiper-container {
    justify-content: center;
    .slider-item {
      height: 174vw;
      display: flex;
      .inner {
        position: relative;
        padding: 22vw 5.8vw 12.2vw;
        .text {
          color: ${(props): string => props.theme.textColors.white};
        }
        .image {
          display: flex;
          align-items: flex-start;
          justify-content: center;
        }
      }
      &.slider-item-photo {
        .inner {
          .text {
            padding: 0 0 11.9vw;
            p {
              padding: 0 0 9.36vw;
              text-align: center;
              font-weight: bold;
              font-size: 7.28vw;
              line-height: 8.32vw;
              letter-spacing: 0.1em;
              span {
                font-size: 3.64vw;
                line-height: 4.26vw;
                padding: 0 0 2vw;
                font-weight: bold;
                display: block;
              }
            }
            li {
              width: 100%;
              height: 10.6vw;
              margin-bottom: 3.7vw;
              padding-left: 13.6vw;
              font-size: 3.455vw;
              color: ${(props): string => props.theme.textColors.white};
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              position: relative;
              font-weight: bold;
              letter-spacing: 0.1em;
              &::before {
                width: 10.6vw;
                height: 10.6vw;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 4px;
                background: center center/5.6vw no-repeat
                  ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
              }
              &:nth-child(1)::before {
                background-size: 7.2vw;
                background-image: url(${mountain});
              }
              &:nth-child(2)::before {
                background-size: 6.6vw;
                background-image: url(${heart});
              }
              &:nth-child(3)::before {
                background-size: 5vw;
                background-image: url(${present});
              }
              &:last-child {
                margin-bottom: 0;
              }
              p {
                width: 100%;
                letter-spacing: 0.05em;
                font-weight: bold;
              }
              span {
                font-size: 2.145vw;
                letter-spacing: 0.1em;
                font-weight: bold;
              }
            }
          }
          .image {
            text-align: center;
            img {
              width: 35vw;
              &:nth-child(2) {
                margin: 7.8vw 0 0 6.5vw;
              }
            }
          }
        }
      }
      &.slider-item-live-talk {
        .inner {
          padding: 22vw 5.8vw 17.68vw;
          .text {
            .top-text {
              p {
                text-align: center;
                font-weight: bold;
                font-size: 3.64vw;
                padding: 0 0 3.38vw;
              }
              .row {
                display: flex;
                justify-content: center;
                img {
                  &:nth-child(1) {
                    width: 15.6vw;
                    margin: 0 1.28vw 0 0;
                  }
                  &:nth-child(2) {
                    width: 60.32vw;
                  }
                }
              }
            }
            p {
              padding: 9.36vw 4vw 11.7vw;
              font-size: 3.38vw;
              text-align: center;
              line-height: 5.74vw;
              font-weight: bold;
              letter-spacing: 0.1em;
            }
          }
          .image {
            img {
              width: 38.47vw;
            }
          }
        }
      }
    }
  }
  .progress-bar {
    width: 198px;
    margin: 0 auto;
    position: absolute;
    bottom: 5.2vw;
    right: 0;
    left: 0;
    z-index: 21;
  }
  ${mediaPc`
    .lead-text {
      font-size: 32px;
      line-height: 32px;
      top: 37px;
    }
    .slider-bg {
      &.photo-bg {
        background-image: url(${topSlideBg01});
      }
      &.live-talk-bg {
        background-image: url(${topSlideBg02});
      }
    }
    .swiper-container {
      height: 555px;
      .slider-item {
        height: 555px;
        .inner {
          padding: 98px 0 0;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          .image {
            img{
              &:nth-child(1){
                width: 233px;
              }
              &:nth-child(2){
                width: 176px;
                margin: 132px 0 0 12px;
              }
            }
          }
        }
        &.slider-item-photo {
          .inner {
            width: 940px;
            padding: 98px 0 0;
            .text {
              padding: 36px 0 0;
              p {
                font-size: 38px;
                line-height: 44px;
                padding: 0 0 23px;
                text-align: left;
                span {
                  font-size: 24px;
                  line-height: 32px;
                  padding: 0 0 7px;
                }
              }
              li {
                width: 100%;
                height: auto;
                min-height: 40px;
                margin-bottom: 27px;
                padding-left: 52px;
                font-size: 16px;
                line-height: 21px;
                &::before {
                  width: 40px;
                  height: 40px;
                  content: '';
                }
                &:nth-child(1)::before {
                  background-size: 27px;
                }
                &:nth-child(2)::before {
                  background-size: 25px;
                }
                &:nth-child(3)::before {
                  background-size: 19px;
                }
                &:last-child {
                  margin-bottom: 0;
                }
                p {
                  width: 100%;
                }
                span {
                  font-size: 8px;
                }
              }
            }
            .image {
              img{
                &:nth-child(1){
                  width: 233px;
                }
                &:nth-child(2){
                  width: 176px;
                  margin: 132px 0 0 12px;
                }
              }
            }
          }
        }
        &.slider-item-live-talk {
          .inner {
            width: 778px;
            padding: 98px 0 0;
            .text {
              padding: 68px 0 0;
              .top-text {
                padding: 0 0 37px;
                p {
                  text-align: center;
                  font-size: 24px;
                  line-height: 32px;
                  padding: 0 0 14px;
                }
                .row {
                  img {
                    &:nth-child(1) {
                      width: 70px;
                      margin: 0 12px 0 0;
                    }
                    &:nth-child(2) {
                      width: 312px;
                    }
                  }
                }
              }
              p {
                font-size: 16px;
                line-height: 27.2px;
                padding: 0 0 0 19px;
              }
            }
            .image {
              img {
                width: 191px;
              }
            }
          }
        }
      }
    }
    .progress-bar {
      bottom: 25px;
    }
  `}
`

export const CreatorHomeSlider: React.FC<Props> = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(1)
  SwiperCore.use([Autoplay, EffectFade])

  return (
    <Wrapper>
      <p className="lead-text">PINTO 4 YOUでできること</p>
      <div className={currentSlideIndex % 2 === 0 ? `slider-bg live-talk-bg` : 'slider-bg photo-bg'} />
      <Swiper
        className="swiper-container"
        autoplay={{
          delay: 5000,
        }}
        speed={700}
        effect={'slide'}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        autoHeight={true}
        onSlideChange={(swiper) => setCurrentSlideIndex(swiper.activeIndex)}
      >
        <SwiperSlide>
          <div className="slider-item slider-item-photo">
            <div className="inner">
              <div className="text">
                <p className="pc">
                  <span>あなたのファンへ</span>
                  写真や動画でメッセージを
                </p>
                <p className="sp">
                  <span>あなたのファンへ</span>
                  写真や動画で
                  <br />
                  特別なメッセージを
                </p>
                <ul>
                  <li>
                    オリジナルメッセージ付きの写真や動画を
                    <br />
                    販売(先着4名まで購入可能)
                  </li>
                  <li>空いた時間で気軽に作成</li>
                  <li>マイページからのアップロードで簡単にお届け</li>
                </ul>
              </div>
              <div className="image">
                <img src={photo01} alt="P4U" />
                <img src={photo02} alt="P4U" />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-item slider-item-live-talk">
            <div className="inner">
              <div className="text">
                <div className="top-text">
                  <p>2人だけで話せる</p>
                  <div className="row">
                    <img src={liveTalkIcon} alt="liveTalk" />
                    <img src={liveTalkText} alt="liveTalk" />
                  </div>
                </div>
                <p>
                  会えない時でもあなたのファンと
                  <br />
                  ビデオチャットでお話ができるサービスです。
                </p>
              </div>
              <div className="image">
                <img src={liveTalk} alt="P4U" />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <SliderProgressBar className="progress-bar" maxLength={2} currentSlideIndex={currentSlideIndex} />
    </Wrapper>
  )
}
