import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  title?: string
}

const StyledModalTitle = styled.h2`
  width: 100%;
  font-size: 3.2vw;
  color: ${(props): string => props.theme.textColors.gray};
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: center;

  ${mediaPc`
    font-size: 16px;
  `}
`

export const ModalTitle: React.FC<Props> = ({ className = '', title = '' }) => {
  return <StyledModalTitle className={className}>{title}</StyledModalTitle>
}
