import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorDisabledProductsTpl, BusinessTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  Product,
  useCreatorInBusinessLazyQuery,
  usePagedBusinessDisabledProductsLazyQuery,
  useDisplayProductMutation,
} from '../../../types/graphql'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { useSetMyProfile } from '../../../lib/SetMyProfile'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessCreatorDisabledProducts: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [disabledProducts, setDisabledProducts] = useState<Product[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [creatorInBusinessLazyQuery] = useCreatorInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setCreator(data.CreatorInBusiness ?? creator)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedBusinessDisabledProductsLazyQuery] = usePagedBusinessDisabledProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setDisabledProducts(data.PagedBusinessDisabledProducts?.data ?? [])
        setLastPage(data.PagedBusinessDisabledProducts?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [displayProductMutation] = useDisplayProductMutation({
    onCompleted: () => {
      pagedBusinessDisabledProductsLazyQuery({
        variables: {
          creator_id: props.match.params.id,
          first: 20,
          page: currentPage,
        },
      })
      setFlashMessage({ type: 'success', message: '選択した商品を再表示しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickToDisplay = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    displayProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    creatorInBusinessLazyQuery({
      variables: {
        id: props.match.params.id,
      },
    })
    pagedBusinessDisabledProductsLazyQuery({
      variables: {
        creator_id: props.match.params.id,
        first: 20,
        page: Number(pageParam),
      },
    })
  }, [creatorInBusinessLazyQuery, pagedBusinessDisabledProductsLazyQuery, props.location.search, props.match.params.id])

  return (
    <>
      <Meta title={creator ? `${creator.name}さん 非表示の商品一覧` : ''} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <>
          {creator && (
            <CreatorDisabledProductsTpl
              creator={creator}
              currentPage={currentPage}
              lastPage={lastPage}
              disabledProducts={disabledProducts}
              onClickToDisplay={onClickToDisplay}
            />
          )}
        </>
      </BusinessTwoColumnLayout>
    </>
  )
}
