import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import moment from 'moment'
import { Link } from '../../atoms'
import { OrderedUser } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

type Props = {
  className?: string
  creatorId: string
  orderedUsers: OrderedUser[]
  noDataText: string
}

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid ${(props): string => props.theme.borderColors.black};
  }
  tr {
    border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
    &:last-child {
      border-bottom: none;
    }
  }
  th {
    height: auto;
    padding: 1.5vw;
    font-size: 3.2vw;
    font-weight: bold;
    text-align: center;
    color: ${(props): string => props.theme.textColors.black};
    line-height: 1.4;
    background: ${(props): string => props.theme.backgroundColors.bodyBg};
    border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
    border-right: 1px solid ${(props): string => props.theme.borderColors.black};
    &:last-child {
      border-right: none;
    }
  }

  td {
    height: 12.2vw;
    padding: 1.5vw 2vw;
    font-size: 3.2vw;
    color: ${(props): string => props.theme.textColors.black};
    line-height: 1.4;
    background: ${(props): string => props.theme.backgroundColors.bodyBg};
    vertical-align: middle;
    border-right: 1px solid ${(props): string => props.theme.borderColors.black};
    &.number-of-purchases,
    &.last-paid-at {
      text-align: center;
    }
    &:last-child {
      border: none;
    }
    p {
      width: 100%;
      line-height: 1.4em;
      text-align: center;
      span {
        font-size: 2.67vw;
        line-height: 0.6;
      }
    }
  }
  .no-data {
    width: 100%;
    height: auto;
    padding-left: 4vw;
    font-size: 3.2vw;
    font-weight: 500;
    line-height: 2;
    letter-spacing: 0.05em;
    color: ${(props): string => props.theme.textColors.gray};
    white-space: pre-wrap;
  }

  ${mediaPc`
    width: 100%;
    overflow-x: visible;
    
    table {
      width: 668px;
      margin-bottom: 20px;
    }
    th {
      padding: 5px;
      font-size: 12px;
      background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
      &.user-name {
        width: 400px;
      }
    }
    td {
      height: auto;
      min-height: 46px;
      padding: 6px;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.4;

    }
    .no-data {
      min-height: auto;
      padding: 10px;
      font-size: 14px;
    }
  `}
`

export const OrderedUserTable: React.FC<Props> = ({ className = '', creatorId, orderedUsers, noDataText }) => {
  const orderedUserUrl = (userId: string): string => {
    if (userType() === 'business') {
      return `/business/creator/${creatorId}/ordered-user/${userId}`
    } else if (userType() === 'creator') {
      return `/creator/ordered-user/${userId}`
    }
    return ''
  }

  return (
    <Wrapper className={className}>
      <table>
        <thead>
          <tr>
            <th className="user-name">購入者</th>
            <th className="last-paid-at">最終購入日</th>
            <th className="number-of-purchases">購入回数</th>
          </tr>
        </thead>
        <tbody>
          {orderedUsers.map((orderedUser, index) => (
            <tr key={index}>
              <td className="user-name">
                <Link url={orderedUserUrl(orderedUser.user_id)} text={orderedUser.user_name} />
              </td>
              <td className="last-paid-at">{moment(orderedUser.last_paid_at).format('Y/M/D')}</td>
              <td className="number-of-purchases">{orderedUser.number_of_purchases}</td>
            </tr>
          ))}

          {!orderedUsers.length && (
            <tr>
              <td className="no-data" colSpan={8}>
                {noDataText}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  )
}
