import React, { useContext } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PageTitle } from '../../../atoms'
import { TwoColumnLayoutTab } from '../../../molecules'
import { UserProfile } from '../../../organisms'

import { mediaPc } from '../../../../lib/MediaQuery'
import { UserProfileContext } from '../../../../context'

import mypageIcon from '../../../../assets/images/icon/mypage_green.png'

type Props = {
  children?: React.ReactNode
  currentTab?: 'history' | 'favoriteList'
  isVisibleUserEditButton?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  .title-wrapper {
    width: 100%;
    padding: 5vw 7.2vw;
    text-align: center;
    background: ${(props): string => props.theme.backgroundColors.gray};

    .page-title {
      width: auto;
      padding-left: 7vw;
      display: inline-block;
      letter-spacing: 0.1em;
      background: url('${mypageIcon}') left center no-repeat;
      background-size: 5vw;
    }
  }

  .content {
    width: 100vw;
    background: ${(props): string => props.theme.backgroundColors.gray};
    & > .inner {
      width: 100%;
      padding: 5vw 7.2vw 15vw;
    }

    .right-column {
      width: 100%;

      .tab {
        margin-bottom: 7.5vw;
      }
    }
  }

  ${mediaPc`
    .title-wrapper {
      width: 1040px;
      margin: 0 auto;
      padding: 80px 20px 45px;
      background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

      .page-title {
        padding-left: 50px;
        letter-spacing: 0.3em;
        background-size: 32px;
      }
    }

    .content {
      width: 100%;
      min-width: 1040px;

      & > .inner {
        width: 1040px;
        margin: 0 auto;
        padding: 34px 20px 50px;
        display: flex;
        justify-content: space-between;
      }

      .right-column {
        width: 720px;

        .tab {
          margin-bottom: 16px;
        }

        .right-column-content {
          border-radius: 4px;
          min-height: 325px;
          padding: 25px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
        }
      }
    }
  `}
`

export const UserTwoColumnLayout: React.FC<Props> = ({ children, currentTab, isVisibleUserEditButton }) => {
  const history = useHistory()
  const { favoriteCreatorsNumber } = useContext(UserProfileContext)
  // 一桁なら0を前に補う
  const twoDigitFavoriteCreatorsNumber = `0${favoriteCreatorsNumber}`.slice(
    favoriteCreatorsNumber < 10 ? -2 : -`${favoriteCreatorsNumber}`.length,
  )

  const tabs: { label: string; selected: boolean; onClick: () => void }[] = [
    {
      label: '購入履歴',
      selected: currentTab === 'history',
      onClick: () => {
        history.push('/mypage')
      },
    },
    {
      label: `お気に入りクリエイター(${twoDigitFavoriteCreatorsNumber})`,
      selected: currentTab === 'favoriteList',
      onClick: () => {
        history.push('/favorite/creators')
      },
    },
  ]

  return (
    <Wrapper>
      <div className="title-wrapper">
        <PageTitle title="マイページ" className="page-title" />
      </div>

      <div className="content">
        <div className="inner">
          <UserProfile isVisibleEditButton={isVisibleUserEditButton} />
          <div className="right-column">
            <TwoColumnLayoutTab tabs={tabs} className="tab" />
            <div className="right-column-content">{children}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
