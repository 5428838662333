import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { convertToVw } from '../../lib/ConvertSize'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  children?: React.ReactNode
  name?: string
  accept?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const StyledLabel = styled.label`
  width: 100%;
  height: auto;
  font-size: ${convertToVw('12px')};
  line-height: ${convertToVw('30px')};
  border-radius: 4px;
  text-align: center;
  transition: 0.3s all;
  color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
  border: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.black};
  cursor: pointer;
  display: block;

  &:hover {
    opacity: 0.8;
  }

  ${mediaPc`
    width: 100px;
    height: auto;
    display: block;
    font-size: 12px;
    line-height: 30px;
  `}
`

const StyledInput = styled.input<Props>`
  display: none;
`

export const FileInput: React.FC<Props> = ({ className = '', children, name = '', accept, onChange }) => {
  return (
    <StyledLabel className={className}>
      {children}
      <StyledInput
        type="file"
        name={name}
        onChange={onChange}
        onClick={(event) => {
          event.currentTarget.value = ''
        }}
        accept={accept}
      />
    </StyledLabel>
  )
}
