import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Label, Textarea } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  required?: boolean
  className?: string
  label?: string
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  value?: string
  maxLength?: number
  textCountVisible?: boolean
  placeholder?: string
  error?: boolean
}

const Wrapper = styled.div`
  width: 100%;
`
const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  .label {
    margin-bottom: 2.1vw;

    .required {
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.red};
    }
  }
  p {
    font-size: 2.6vw;
    transition: 0.3s all;
    color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.lightGray};
    &.max {
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.red};
    }
  }

  ${mediaPc`
  .label {
      margin-bottom: 5px;
      white-space: pre-wrap;
    }
    p {
      font-size: 14px;
    }
  `}
`

export const TextareaWithLabel: React.FC<Props> = ({
  required = false,
  className = '',
  label = '',
  name = '',
  value = '',
  maxLength,
  textCountVisible = true,
  placeholder = '',
  onChange = () => {},
  error,
}) => {
  const textCount = value.length

  return (
    <Wrapper className={className}>
      {label && (
        <TitleArea>
          <Label className="label">
            {label}
            {required && <span className="required">【必須】</span>}
          </Label>
          {maxLength && textCountVisible && (
            <p className={textCount === maxLength ? 'max' : ''}>
              {textCount}&nbsp;/&nbsp;{maxLength}
            </p>
          )}
        </TitleArea>
      )}
      <Textarea name={name} value={value} onChange={onChange} placeholder={placeholder} maxLength={maxLength} error={error} />
    </Wrapper>
  )
}
