import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { ProductPurchase } from '../../../organisms'
import { CreatorInfoSideColumn } from '../../../molecules'

import { Creator, PublishedProduct, ProductType } from '../../../../types/graphql'
import { paymentType } from '../../../../types/myTypes'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  creator: Creator
  product: PublishedProduct
  payment: '' | paymentType
  onChangePayment: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onClickPurchase: () => void
  onClickFavorite: (creatorId: string) => void
  onClickUnfavorite: (creatorId: string) => void
}

const Wrapper = styled.div`
  width: 100%;

  .content {
    width: 100vw;
    background: ${(props): string => props.theme.backgroundColors.gray};

    & > .inner {
      width: 100%;
    }

    .right-content {
      background: ${(props): string => props.theme.backgroundColors.bodyBg};

      .attention {
        margin: 0 auto;
        padding: 4vw 0 4vw 6.6vw;
        color: ${(props): string => props.theme.textColors.red};
        font-size: 2.933vw;
        font-weight: 700;
        line-height: 1;
      }

      .right-column {
        width: 100%;
        .right-column-content-product {
          .content-title {
            width: 100%;
            font-size: 3.735vw;
            line-height: 3.735vw;
            margin-bottom: 4vw;
            font-weight: bold;
            letter-spacing: 0.05em;
            color: ${(props): string => props.theme.textColors.black};
            span {
              line-height: 4vw;
              font-size: 2.935vw;
              letter-spacing: 0.05em;
              padding: 0 0 0 10vw;
              color: ${(props): string => props.theme.textColors.red};
            }
          }
        }
      }
    }
  }

  ${mediaPc`
    .content {
      width: 100%;
      min-width: 1040px;
      & > .inner {
        width: 1040px;
        margin: 0 auto;
        padding: 55px 20px 50px;
        display: flex;
        justify-content: space-between;
        align-items: start;
      }

      .creator-info {
        margin-top: 23px;
      }

      .right-content {
        background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};

        .attention {
          width: 720px;
          margin: 0 0 12px 0;
          padding: 0;
          font-size: 11px;
        }
  
        .right-column {
          width: 720px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
  
          .right-column-content-product {
            .content-title {
               padding: 0;
               margin:0;
              font-size:14px;
              span {
                  font-size:11px;
                  padding: 0 0 0 30px;
              }
            }
          }
        }
      }
    }
  `}
`

export const ProductPurchaseTpl: React.FC<Props> = ({
  creator,
  product,
  payment,
  onChangePayment,
  onClickPurchase,
  onClickFavorite,
  onClickUnfavorite,
}) => {
  return (
    <Wrapper>
      <div className="content">
        <div className="inner">
          <CreatorInfoSideColumn
            creator={creator}
            onClickFavorite={onClickFavorite}
            onClickUnfavorite={onClickUnfavorite}
            isUserValid={true}
            className="creator-info"
          />
          <div className="right-content">
            <p className="attention">※こちらの商品は、先着{product.product_type === ProductType.LiveTalk ? 1 : 4}名さま限定です</p>
            <div className="right-column">
              <ProductPurchase
                product={product}
                payment={payment}
                onChangePayment={onChangePayment}
                onClickPurchase={onClickPurchase}
                className="right-column-content-product"
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
