import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Button } from '../../../atoms'
import { SubtitleWithLink, ContentTitleWithLink, OrderTable, DeliveryAttention } from '../../../molecules'
import { CreatorDetail, CreatorSaleThisMonth, ProductListForManagement } from '../../../organisms'
import { Creator, SalesPerMonth, Product, OrderedProduct, CreatorAccountStatus } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  creator: Creator
  sales: SalesPerMonth[]
  publishedProducts: Product[]
  reservedProducts: Product[]
  disabledProducts: Product[]
  orders: OrderedProduct[]
  onClickResendRegistrationMail?: () => void
  onClickDeleteCreator?: () => void
  onClickToDisabled?: (productId: string) => void
  onClickToEnd?: (productId: string) => void
  onClickToDisplay?: (productId: string) => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }

  .table-title {
    margin-bottom: 3vw;
  }

  .head-line {
    margin-bottom: 4.3vw;
  }

  .creator-detail {
    margin-bottom: 7vw;
  }
  .creator-sale-this-month {
    margin-bottom: 7vw;
  }
  .go-to-registration-product {
    width: 100%;
  }
  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title {
      margin-bottom: 35px;
    }

    .table-title {
      margin-bottom: 10px;
    }

    .head-line {
      margin-bottom: 15px;
    }
    .creator-detail {
      margin-bottom: 60px;
    }
    .creator-sale-this-month {
      margin-bottom: 60px;
    }
    .go-to-registration-product {
      width: 320px;
      margin: 0 auto 45px;
      display: block;
    }
  `}
`

const OrderList = styled.div`
  margin-bottom: 7vw;

  ${mediaPc`
    margin-bottom: 60px;
  `}
`

const PublishedProducts = styled.div`
  margin-bottom: 7vw;

  ${mediaPc`
    margin-bottom: 60px;
  `}
`

const ReserveProducts = styled.div`
  margin-bottom: 7vw;

  ${mediaPc`
    margin-bottom: 60px;
  `}
`

const DisabledProducts = styled.div`
  margin-bottom: 7vw;

  ${mediaPc`
    margin-bottom: 60px;
  `}
`

const NoTargetProduct = styled.div`
  width: 100%;
  padding-bottom: 15px;
  text-align: center;
  color: ${(props): string => props.theme.textColors.gray};
`

export const CreatorDetailTpl: React.FC<Props> = ({
  creator,
  sales,
  publishedProducts,
  reservedProducts,
  disabledProducts,
  orders,
  onClickResendRegistrationMail,
  onClickDeleteCreator,
  onClickToDisabled,
  onClickToEnd,
  onClickToDisplay,
}) => {
  const history = useHistory()

  const goToProfileEdit = (): void => {
    history.push(`/business/creator/${creator.id}/edit`)
  }

  const goToOrders = (creatorId: string): void => {
    history.push(`/business/creator/${creatorId}/orders`)
  }

  const goToPublishedProductList = (creatorId: string): void => {
    history.push(`/business/creator/${creatorId}/products`)
  }

  const goToReserveProductList = (creatorId: string): void => {
    history.push(`/business/creator/${creatorId}/products/reserved`)
  }

  const goToDisabledProductList = (creatorId: string): void => {
    history.push(`/business/creator/${creatorId}/products/disabled`)
  }

  const goToRegistrationProduct = (creatorId: string): void => {
    history.push(`/business/creator/${creatorId}/product/register`)
  }

  const isCreatorActive = creator.status === CreatorAccountStatus.Active

  return (
    <Wrapper>
      <SubtitleWithLink
        className="title"
        subtitle="クリエイター情報詳細"
        textType={isCreatorActive ? undefined : 'red'}
        linkText={isCreatorActive ? 'プロフィール編集' : 'アカウントを削除'}
        onClick={isCreatorActive ? goToProfileEdit : onClickDeleteCreator}
      />
      <CreatorDetail creator={creator} onClickResendRegistrationMail={onClickResendRegistrationMail} className="creator-detail" />

      {isCreatorActive && (
        <div>
          <OrderList>
            <ContentTitleWithLink className="table-title" title="注文状況" linkText="一覧を見る" onClick={() => goToOrders(creator.id)} />
            <OrderTable
              orderType="undelivered"
              orders={orders}
              noDataText={`商品が購入されるとこちらに表示されます。${'\n'}納品を済ませることで完了となります。`}
            />
            <DeliveryAttention />
          </OrderList>

          <CreatorSaleThisMonth className="creator-sale-this-month" creator={creator} sales={sales} />

          <PublishedProducts>
            <ContentTitleWithLink
              className="head-line"
              title="公開中の商品"
              linkText="一覧を見る"
              onClick={() => goToPublishedProductList(creator.id)}
            />
            {publishedProducts.length > 0 ? (
              <ProductListForManagement
                productListType="published"
                products={publishedProducts}
                oneLineItemsNum={4}
                onClickToDisabled={onClickToDisabled}
                onClickToEnd={onClickToEnd}
              />
            ) : (
              <NoTargetProduct>販売中の商品はありません</NoTargetProduct>
            )}
          </PublishedProducts>
          <ReserveProducts>
            <ContentTitleWithLink
              className="head-line"
              title="公開日時指定商品"
              linkText="一覧を見る"
              onClick={() => goToReserveProductList(creator.id)}
            />
            {reservedProducts.length > 0 ? (
              <ProductListForManagement
                productListType="reserved"
                products={reservedProducts}
                onClickToDisabled={onClickToDisabled}
                oneLineItemsNum={4}
              />
            ) : (
              <NoTargetProduct>公開日時指定中の商品はありません</NoTargetProduct>
            )}
          </ReserveProducts>
          <DisabledProducts>
            <ContentTitleWithLink
              className="head-line"
              title="非表示の商品"
              linkText="一覧を見る"
              onClick={() => goToDisabledProductList(creator.id)}
            />
            {disabledProducts.length > 0 ? (
              <ProductListForManagement
                productListType="disabled"
                products={disabledProducts}
                onClickToDisplay={onClickToDisplay}
                oneLineItemsNum={4}
              />
            ) : (
              <NoTargetProduct>非表示の商品はありません</NoTargetProduct>
            )}
          </DisabledProducts>
          <Button className="go-to-registration-product" buttonType="red" onClick={() => goToRegistrationProduct(creator.id)}>
            新しい商品を販売する
          </Button>
        </div>
      )}
    </Wrapper>
  )
}
