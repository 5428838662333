import React, { useState, useEffect, ChangeEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { OrderedUser, OrderedUsersSort, usePagedCreatorOrderedUsersLazyQuery } from '../../../types/graphql'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import { CreatorOrderedUsersTpl } from '../../../components/templates/Creator/Orders/OrderedUsers'
import { orderedUsersSortItems } from '../../../lib/UsersOrderSortItems'

type Props = {} & RouteComponentProps

export const CreatorOrderedUsers: React.FC<Props> = (props) => {
  const [orderedUsers, setOrderedUsers] = useState<OrderedUser[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [selectedValue, setSelectedValue] = useState<string>(OrderedUsersSort.LastPaidAtDesc)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [pagedCreatorOrderedUsersLazyQuery] = usePagedCreatorOrderedUsersLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setOrderedUsers(data.PagedCreatorOrderedUsers?.data ?? [])
        setLastPage(data.PagedCreatorOrderedUsers?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  useValidateCreatorToken()
  useSetCreatorProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    pagedCreatorOrderedUsersLazyQuery({
      variables: {
        first: 30,
        page: Number(pageParam),
        sort: OrderedUsersSort['LastPaidAtDesc'],
      },
    })
  }, [pagedCreatorOrderedUsersLazyQuery, props.location.search])

  const onChangeDropdown = (e: ChangeEvent<HTMLSelectElement>): void => {
    pagedCreatorOrderedUsersLazyQuery({
      variables: {
        first: 30,
        page: 1,
        sort: e.target.value,
      },
    })
    setSelectedValue(e.target.value)
  }

  return (
    <>
      <Meta title="購入者一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="users">
        <CreatorOrderedUsersTpl
          orderedUsers={orderedUsers}
          currentPage={currentPage}
          lastPage={lastPage}
          orderedUsersSortItems={orderedUsersSortItems}
          onChangeDropdown={onChangeDropdown}
          selectedValue={selectedValue}
        />
      </CreatorTwoColumnLayout>
    </>
  )
}
