import React from 'react'
import styled from 'styled-components'
import { Button, FileInput, Icon, Label } from '../../../atoms'
import { TextInputWithLabel, TextareaWithLabel, ErrorMessagesBox } from '../../../molecules'

import { CreatorRegisterErrors, CreatorRegisterAccountInput } from '../../../../types/myTypes'

import { mediaPc } from '../../../../lib/MediaQuery'

import defaultIcon from '../../../../assets/images/icon/man_icon_default_black.png'

type Props = {
  creator: CreatorRegisterAccountInput
  iconUrl: string
  errorItems: CreatorRegisterErrors
  onChangeForm: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
  onChangeIcon: (event: React.ChangeEvent<HTMLInputElement>) => void
  registerCreator: () => void
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 4.5vw;

  .edit-item {
    margin-bottom: 6vw;

    input[type='text'],
    input[type='password'] {
      &::placeholder {
        font-size: 3.2vw;
        line-height: 1.875;
      }
    }

    &.at-sign-input {
      position: relative;
      input {
        padding: 0 15px 0 12%;
      }
      &::before {
        margin-right: 5px;
        content: '@';
        line-height: 12vw;
        color: ${(props): string => props.theme.textColors.placeholder};
        display: block;
        font-size: 3.733vw;
        position: absolute;
        top: 35%;
        right: 90%;
      }
    }

    &.icon {
      .label {
        margin-bottom: 2.1vw;
        .required {
          color: ${(props): string => props.theme.textColors.red};
        }
      }
      .photo {
        object-fit: cover;
        border-radius: 50%;
        &.default {
          border-radius: 0;
        }
      }
      .file-select {
        width: 35.6%;
        height: 35.6%;
        margin: 0 auto;
        border: none;
      }
    }
  }

  .fixed-item {
    margin-bottom: 6vw;

    input[type='text'] {
      height: auto;
      padding: 0;
      background: ${(props): string => props.theme.backgroundColors.gray};
      border: transparent;
      font-size: 3.733vw;
      font-weight: 700;
      line-height: 1;
    }
  }

  ${mediaPc`
  width: 800px;
  margin: 0 auto 25px;
  padding: 0;
  
  .edit-item {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;

      label {
        width: calc(100% - 560px);
        margin: 0;
        line-height: 40px;
        text-align: right;
      }
      
      input[type='text'],
      input[type='password'],
      textarea {
        width: 540px;
        margin: 0 0 0 20px;
        font-size: 12px;
      }

      input[type='text'],
      input[type='password'] {
        line-height: 40px;

        &::placeholder {
          font-size: 12px;
          line-height: 40px;
        } 
      }

      &.two-line-label {
        align-items: center;

        label {
          line-height: 1.4;
        }
      }

      &.profile {
        align-items: start;

        div {
          width: calc(100% - 560px);

          label {
            width: 100%;
          }
        }
      }

      &.at-sign-input {
        input {
          padding-left: 30px;
        }
        &::before {
          margin-right: 5px;
          line-height: 40px;
          font-size: 14px;
          top: 0;
          right: 510px;
        }
      }

      &.icon {
        .label {
          line-height: 100px;
        }
        .photo {
          width: 100px;
          margin: 0 auto 10px;
        }
        .file-select-wrapper {
          width: 540px;
          margin: 0 0 0 20px;
        }
        .file-select {
          width: 100px;
          margin: 0;
        }
      }
    }

    .fixed-item {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;

      label {
        width: calc(100% - 560px);
        margin: 0;
        line-height: 40px;
        text-align: right;
      }

      input[type='text'] {
        width: 540px;
        margin: 0 0 0 20px;
        font-size: 14px;
        line-height: 40px;
      }

    }

    .error-messages {
      padding: 17px 0 17px 130px;
    }

    .register {
      width: 320px;
      height: 40px;
      margin: 0 auto;
      display: block;
      line-height: 40px;
    }
  `}
`

export const CreatorRegistrationForm: React.FC<Props> = ({ creator, iconUrl, errorItems, onChangeForm, onChangeIcon, registerCreator }) => {
  const errors: string[] = Object.values(errorItems).filter((error) => error !== null) as string[]

  const isDisable = (): boolean => {
    const isRequiredItemsFilled = iconUrl && creator.name && creator.password && creator.password_confirm
    if (!isRequiredItemsFilled) return true
    if (errors.length > 0) return true
    return false
  }

  return (
    <Wrapper>
      <div className="edit-item icon">
        <Label className="label">
          アイコン<span className="required">【必須】</span>
        </Label>
        <div className="file-select-wrapper">
          <FileInput className="file-select" name="icon_url" onChange={onChangeIcon}>
            <Icon className={iconUrl !== '' ? 'photo' : 'photo default'} src={iconUrl !== '' ? iconUrl : defaultIcon} alt={creator.name} />
          </FileInput>
        </div>
      </div>
      <TextInputWithLabel
        className="edit-item"
        required
        label="クリエイター名"
        name="name"
        value={creator.name}
        placeholder="クリエイター名(登録後に変更が可能です)"
        onChange={onChangeForm}
        error={errorItems.name !== null}
      />
      <TextInputWithLabel className="fixed-item" label="メールアドレス" name="mail" value={creator.email} readOnly />
      <TextareaWithLabel
        className="edit-item two-line-label profile"
        label={`プロフィールテキスト${'\n'}(200文字まで)【任意】`}
        name="profile"
        value={creator.profile ?? ''}
        placeholder="プロフィールを入力してください(200文字)"
        onChange={onChangeForm}
        error={errorItems.profile !== null}
      />
      <TextInputWithLabel className="fixed-item" label="所属" name="affiliation" value={creator.affiliation?.name || '所属なし'} readOnly />
      <TextInputWithLabel
        className="edit-item"
        required
        label="パスワード"
        name="password"
        type="password"
        value={creator.password}
        placeholder="パスワード"
        onChange={onChangeForm}
        error={errorItems.password !== null || errorItems.password_match !== null}
      />
      <TextInputWithLabel
        className="edit-item"
        label=" "
        name="password_confirm"
        type="password"
        value={creator.password_confirm}
        placeholder="確認用パスワード"
        onChange={onChangeForm}
        error={errorItems.password_confirm !== null || errorItems.password_match !== null}
      />
      <TextInputWithLabel
        className="edit-item two-line-label at-sign-input"
        label={`twitter ユーザー名${'\n'}【任意】`}
        name="twitter_username"
        value={creator.twitter_username ?? ''}
        placeholder="@マーク以降を入力してください"
        onChange={onChangeForm}
        error={errorItems.twitter_username !== null}
      />
      <TextInputWithLabel
        className="edit-item two-line-label at-sign-input"
        label={`instagram ユーザーネーム${'\n'}【任意】`}
        name="instagram_username"
        value={creator.instagram_username ?? ''}
        placeholder="@マーク以降を入力してください"
        onChange={onChangeForm}
        error={errorItems.instagram_username !== null}
      />
      <TextInputWithLabel
        className="edit-item"
        label="YouTube チャンネル URL【任意】"
        name="youtube_channel_url"
        value={creator.youtube_channel_url ?? ''}
        placeholder="URLを入力してください"
        onChange={onChangeForm}
        error={errorItems.youtube_channel_url !== null}
      />
      <TextInputWithLabel
        className="edit-item"
        label="Kindle販売ページ URL【任意】"
        name="kindle_author_url"
        value={creator.kindle_author_url ?? ''}
        placeholder="URLを入力してください"
        onChange={onChangeForm}
        error={errorItems.kindle_author_url !== null}
      />
      {errors.length > 0 && <ErrorMessagesBox className="error-messages" errors={errors} />}

      <Button disabled={isDisable()} className="register" buttonType="primary" onClick={registerCreator}>
        登録
      </Button>
    </Wrapper>
  )
}
