import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, FileInput, LiveTalkBrowseAttention } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'
import { validateImage } from '../../../lib/Validate'
import { OrderedProductStatus, OrderedProduct, OrderedProductLiveTalkStatus } from '../../../types/graphql'

import selectPhoto from '../../../assets/images/icon/select_delivery_photo.png'
import selectMovie from '../../../assets/images/icon/select_delivery_movie.png'
import liveTalkIcon from '../../../assets/images/icon/live_talk.png'

type Props = {
  className?: string
  order: OrderedProduct
  uploadedProductUrl: string
  uploadProduct: (file: File, url: string, type: 'photo' | 'movie') => void
  showDeliveryDialog: () => void
  goToLiveTalk: () => void
}

const Wrapper = styled.div`
  background: ${(props): string => props.theme.backgroundColors.gray};

  .inner {
    margin: 0 auto;

    .title {
      margin-bottom: 3.7vw;
      font-size: 3.2vw;
      text-align: center;
      font-weight: bold;
    }

    .live-talk-attention {
      margin-bottom: 3.2vw;
      padding: 3.2vw 6.8vw;
      background: ${(props): string => props.theme.backgroundColors.bodyBg};
      border: 1px solid ${(props): string => props.theme.borderColors.red};
      border-radius: 4px;

      > p {
        font-size: 2.667vw;
        text-align: center;
        line-height: 1.7;
        letter-spacing: 0.05em;

        &.attention-title {
          margin-bottom: 1.3vw;
          font-size: 3.2vw;
          font-weight: bold;
          color: ${(props): string => props.theme.textColors.red};
        }
      }
    }

    .purchaser {
      width: 100%;
      height: auto;
      margin-bottom: 10vw;
      padding: 3.4vw 5vw;
      text-align: center;
      border-radius: 4px;
      background: ${(props): string => props.theme.backgroundColors.bodyBg};

      .purchaser-name {
        font-size: 4.8vw;
        font-weight: bold;
        letter-spacing: 0.05em;
      }

      .twitter-purchaser-name {
        color: ${(props): string => props.theme.textColors.lightGray};
        font-size: 3.2vw;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.05em;
      }
    }

    .product-input-wrapper {
      position: relative;

      .uploaded-product,
      .live-talk-product {
        width: 64vw;
        margin: 0 auto 10.6vw;

        &.processing {
          position: relative;

          &:after {
            width: 100%;
            height: 5.603vw;
            text-align: center;
            font-size: 5.603vw;
            font-weight: bold;
            color: ${(props): string => props.theme.textColors.primary};
            content: '変換中です';
            position: absolute;
            top: 44%;
            left: 0;
          }
          img {
            width: 100%;
            height: auto;
          }
        }

        &.can-click {
          cursor: pointer;
        }

        img {
          border-radius: 4px;
          margin-bottom: 5.8vw;
        }

        video {
          width: 100%;
          margin-bottom: 5.8vw;
        }
      }

      .live-talk-product {
        &:after {
          width: 32.3vw;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background: url(${liveTalkIcon}) center no-repeat;
          background-size: 32.3vw;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .upload-another {
        margin-bottom: 5.8vw;
        font-size: 3.735vw;
        font-weight: bold;
        color: ${(props): string => props.theme.textColors.gray};
        border: 0;

        &.delivered {
          color: ${(props): string => props.theme.textColors.red};
        }
      }

      .product-input {
        width: 64vw;
        margin: 0 auto 10.6vw;
        border: 0;
      }

      .error-message {
        width: 64vw;
        color: ${(props): string => props.theme.textColors.red};
        animation-duration: 0.3s;
        animation-name: viewOpacity;
        font-size: 2.4vw;
        position: absolute;
        left: 0;
        bottom: -5vw;
      }
    }
  }

  ${mediaPc`
    padding:25px 0 46px;

    .inner {
      .title {
        margin-bottom: 12px;
        font-size: 14px;
      }

      .live-talk-attention {
        width: 552px;
        margin: 0 auto 12px;
        padding: 12px 51px;
        
        >p {
          font-size: 12px;
          
          &.attention-title {
            margin-bottom: 12px;
            font-size: 12px;
          }
        }
      }

      .purchaser {
        width: 320px;
        margin: 0 auto 24px;
        padding: 13px 20px;

        .purchaser-name {
          font-size: 18px;
        }

        .twitter-purchaser-name {
          font-size: 12px;
        }
      }

      .product-input-wrapper {
        .uploaded-product,
        .live-talk-product {
          width: 200px;
          margin: 0 auto 82px;

          &.processing {
            &:after {
              height: auto;
              font-size: 21px;
            }
          }

          img,
          video {
            margin-bottom: 22px;
          }
        }

        .live-talk-product {
          &:after {
            width: 100px;
            background-size: 100px;
          }
  
          img {
            width: 100%;
            height: auto;
          }
          
          &.can-click {
            margin-bottom: 29px;
          }
        }

        .upload-another {
          width: 100%;
          margin-bottom: 34px;
          font-size: 14px;
        }

        .product-input {
          width: 200px;
          height: 360px;
          margin: 0 auto 82px;
          padding: 0 0 32px;
          display: block;
        }

        .error-message {
          width: 100%;
          bottom: -50px;
          font-size: 12px;
        }
      }

      .delivery-button {
        width: 320px;
        display:block;
        margin: 0 auto;
      }

      .browse-attention {
        width: 608px;
        margin: 0 auto;
      }
    }
  `}
`

export const OrderDelivery: React.FC<Props> = ({
  className = '',
  order,
  uploadedProductUrl,
  uploadProduct,
  showDeliveryDialog,
  goToLiveTalk,
}) => {
  const [productImageError, setProductImageError] = useState<string | null>(null)

  const onChangeProductImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null && event.target.files[0] !== undefined) {
      const file = event.target.files[0]
      const errorText: string | null = validateImage(file)
      setProductImageError(null)
      if (errorText) {
        setProductImageError(errorText)
      } else {
        const objectUrl = URL.createObjectURL(file)
        uploadProduct(file, objectUrl, 'photo')
      }
    }
  }

  const onChangeProductMovie = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null && event.target.files[0] !== undefined) {
      const file = event.target.files[0]
      const objectUrl = URL.createObjectURL(file)
      uploadProduct(file, objectUrl, 'movie')
    }
  }

  const isPhoto = order.product.is_photo
  const isMovie = order.product.is_movie
  const isLiveTalk = order.product.is_live_talk
  const isUndelivered = order.status === OrderedProductStatus.Undelivered
  const isDelivered = order.status === OrderedProductStatus.Delivered
  const isProcessing = order.status === OrderedProductStatus.Processing
  const isLiveTalkFinished = order.live_talk_status === OrderedProductLiveTalkStatus.Talked
  const isLiveTalkCanceled = order.live_talk_status === OrderedProductLiveTalkStatus.Canceled

  return (
    <Wrapper className={className}>
      <div className="inner">
        {isLiveTalk && !isLiveTalkFinished && !isLiveTalkCanceled && (
          <>
            <p className="title">
              開始時間の5分前になりましたら、
              <br />
              商品の画像をタップしトーク開始までお待ちください！
            </p>
            <div className="live-talk-attention">
              <p className="attention-title">ライブトークの前に必ずお読みください</p>
              <p>
                安全と防犯のため、
                <br className="sp" />
                ビデオ通話の内容は全て録画されます。
                <br />
                <br />
                ユーザー様から、不快になるような不適切な言動や、利用規約第5条に違反する言動があった場合、詳細をお問い合わせフォームよりお知らせください。
              </p>
            </div>
          </>
        )}
        {!isLiveTalk && <p className="title">このアカウントに向けた{order.product.product_type_name}を送りましょう！</p>}
        <div className="purchaser">
          <p className="purchaser-name">{order.order.user.name || ''}</p>
          {order.order.user.twitter_username && (
            <a
              className="twitter-purchaser-name"
              href={`https://twitter.com/${order.order.user.twitter_username}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`twitter @${order.order.user.twitter_username}`}</a>
          )}
        </div>
        <div className="product-input-wrapper">
          {/* ライブトーク */}
          {isLiveTalk && (
            <div
              onClick={isLiveTalkFinished || isLiveTalkCanceled ? undefined : goToLiveTalk}
              className={isLiveTalkFinished || isLiveTalkCanceled ? 'live-talk-product' : 'live-talk-product can-click'}
            >
              <img src={order.order.product.thumbnail_image} alt={order.order.product.name} />
            </div>
          )}
          {/* 写真or動画・未納品 */}
          {isUndelivered && isPhoto && uploadedProductUrl && (
            <div className="uploaded-product">
              <img src={uploadedProductUrl} alt={order.order.product.name} />
              <FileInput name="product" onChange={onChangeProductImage} accept="image/*" className="upload-another">
                別の写真を選択
              </FileInput>
            </div>
          )}
          {isUndelivered && isMovie && uploadedProductUrl && (
            <div className="uploaded-product">
              <video controls src={uploadedProductUrl}></video>
              <FileInput name="product" onChange={onChangeProductMovie} accept="video/*" className="upload-another">
                別の動画を選択
              </FileInput>
            </div>
          )}
          {isUndelivered && !uploadedProductUrl && (isPhoto || isMovie) && (
            <FileInput
              className="product-input"
              name="product"
              onChange={isPhoto ? onChangeProductImage : onChangeProductMovie}
              accept={isPhoto ? 'image/*' : 'video/*'}
            >
              <img src={isPhoto ? selectPhoto : selectMovie} alt="P4U" />
            </FileInput>
          )}
          {/* 写真or動画・納品済み */}
          {isDelivered && isPhoto && (
            <div className="uploaded-product">
              <img
                src={uploadedProductUrl ? uploadedProductUrl : order.latest_delivered_product?.delivered_product_url || ''}
                alt={order.order.product.name}
              />
              <FileInput name="product" onChange={onChangeProductImage} accept="image/*" className="upload-another delivered">
                別の写真を選択して再納品する
              </FileInput>
            </div>
          )}
          {isDelivered && isMovie && (
            <div className="uploaded-product">
              <video
                controls
                src={uploadedProductUrl ? uploadedProductUrl : order.latest_delivered_product?.delivered_product_url || ''}
              ></video>
              <FileInput name="product" onChange={onChangeProductMovie} accept="video/*" className="upload-another delivered">
                別の動画を選択して再納品する
              </FileInput>
            </div>
          )}
          {/* 処理中 */}
          {isProcessing && !isLiveTalk && (
            <div className="uploaded-product processing">
              <img src={order.product.thumbnail_image} alt={order.order.product.name} />
            </div>
          )}
          {productImageError !== null && <p className="error-message">{productImageError}</p>}
        </div>

        {!isProcessing && !isLiveTalk && (
          <Button buttonType="primary" disabled={!uploadedProductUrl} onClick={showDeliveryDialog} className="delivery-button">
            {isPhoto ? '納品する' : 'アップロードする（納品する）'}
          </Button>
        )}
        {isLiveTalk && !isLiveTalkFinished && !isLiveTalkCanceled && <LiveTalkBrowseAttention className="browse-attention" />}
      </div>
    </Wrapper>
  )
}
