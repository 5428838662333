import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CreatorSearchTpl } from '../../../components/templates'
import { userType } from '../../../lib/UserType'
import { Meta } from '../../../Meta'

export const CreatorSearch: React.FC = () => {
  const [searchWord, setSearchWord] = useState('')

  const history = useHistory()

  const onChangeSearchForm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchWord(event.target.value)
  }
  const onClickSearchButton = (): void => {
    if (userType() === 'business') {
      history.push(`/business/creator/search/result?keyword=${searchWord}`)
    } else if (userType() === 'creator') {
      history.push(`/creator/search/result?keyword=${searchWord}`)
    } else if (userType() === 'user') {
      history.push(`/creators/search/result?keyword=${searchWord}`)
    }
  }

  return (
    <>
      <Meta title="クリエイター検索" />
      <CreatorSearchTpl searchWord={searchWord} onChangeSearchForm={onChangeSearchForm} onClickSearchButton={onClickSearchButton} />
    </>
  )
}
