import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../../atoms'
import { TextInputWithLabel, ErrorMessagesBox } from '../../../molecules'
import { User } from '../../../../types/graphql'
import { UserEditErrors } from '../../../../types/myTypes'
import { mediaPc } from '../../../../lib/MediaQuery'
import { validateEmail, validateTwitterUserName } from '../../../../lib/Validate'

type Props = {
  user: User
  onClickUpdateUserAccount: () => void
  onChangeState: (name: string, value: string) => void
}

const Wrapper = styled.div`
  .inner {
    padding: 7.4vw 0;
    background-color: ${(props): string => props.theme.backgroundColors.gray};
    .input-item {
      margin: 0 auto 4vw;
    }
    .button {
      display: block;
      margin: 5.6vw auto 0;
      font-size: 3.2vw;
      font-weight: bold;
    }
    .error-messages {
      margin-top: 5.5vw;
      text-align: left;
    }
  }
  ${mediaPc`
  padding: 15px 0;
  .inner {
    border-radius: 4px;
    padding:28px 0;
    .input-item {
      max-width:320px;
      margin-bottom: 15px;
      > label {
        font-size: 10px;
      }
      > input {
        width: 320px;
        height: 40px;
        margin: 0 auto;
        padding: 0 15px;
        font-size: 12px;
      }
    }
    .button {
      width: 200px;
      height: 30px;
      line-height: 30px;
      margin: 30px auto 0;
      padding: 0 15px;
      font-size: 12px;
    }
    .error-messages {
      width: 320px;
      margin: 25px auto 0px;
      padding: 10px 15px;
      text-align: left;
      p {
        font-size: 12px;
      }
    }
  }
  `}
`
export const UserMypageEditForm: React.FC<Props> = ({ user, onClickUpdateUserAccount, onChangeState }) => {
  const [errors, setErrors] = useState<UserEditErrors>({
    name: null,
    email: null,
    twitter_username: null,
  })
  // ErrorMessagesBoxに渡すために配列作成
  let errorsArray: string[] = []
  errorsArray = Object.values(errors).filter((v) => v) as string[]

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const name = event.target.name
    onChangeState(name, value)
  }

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setErrors({ ...errors, name: null })
    if (!event.target.value) {
      const errorText = 'ユーザー名を入力してください'
      setErrors({ ...errors, name: errorText })
    }
    onChangeValue(event)
  }

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorText: string | null = validateEmail(event.target.value)
    setErrors({ ...errors, email: null })
    if (errorText) {
      setErrors({ ...errors, email: errorText })
    }
    onChangeValue(event)
  }

  const onChangeTwitterUserName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorText: string | null = validateTwitterUserName(event.target.value)
    setErrors({ ...errors, twitter_username: null })
    if (errorText) {
      setErrors({ ...errors, twitter_username: errorText })
    }
    onChangeValue(event)
  }

  const isDisable = (): boolean => {
    if (!user.name || !user.email || errorsArray.length > 0) {
      return true
    }
    return false
  }
  return (
    <Wrapper>
      <div className="inner">
        <TextInputWithLabel
          className="input-item"
          label="ユーザー名"
          name="name"
          value={user.name}
          placeholder="ユーザー名を入力してください"
          onChange={onChangeName}
          error={!!errors.name}
        />
        <TextInputWithLabel
          className="input-item"
          label="メールアドレス"
          name="email"
          type="email"
          value={user.email}
          placeholder="メールアドレスを入力してください"
          onChange={onChangeEmail}
          error={!!errors.email}
        />
        <TextInputWithLabel
          className="input-item"
          label="twitter ユーザー名【任意】"
          name="twitter_username"
          value={user.twitter_username ?? undefined}
          placeholder="@マーク以降を入力してください"
          onChange={onChangeTwitterUserName}
          error={!!errors.twitter_username}
        />
        {errorsArray.length > 0 && <ErrorMessagesBox className="error-messages" errors={errorsArray} />}
        <Button className="button" onClick={onClickUpdateUserAccount} buttonType="primary" disabled={isDisable()}>
          更新
        </Button>
      </div>
    </Wrapper>
  )
}
