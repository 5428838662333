import React from 'react'
import styled from 'styled-components'
import { PageSubtitle } from '../../../atoms'
import { CreatorUserRanking } from '../../../organisms'
import { CreatorPurchaseUserRanking } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  ranking: CreatorPurchaseUserRanking[]
}

const Wrapper = styled.div`
  .title {
    width: 100%;
    margin-bottom: 4.8vw;
  }

  ${mediaPc`
    .title {
      font-size: 16px;
      margin-bottom: 17px;
    }
  `}
`

export const CreatorUserRankingTpl: React.FC<Props> = ({ ranking }) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle="ユーザー購入ランキング" />
      <CreatorUserRanking ranking={ranking} />
    </Wrapper>
  )
}
