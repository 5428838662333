import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import moment from 'moment'
import { OrderStatus } from '../../atoms'
import { OrderedProduct, OrderedProductStatus, ProductType } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

type Props = {
  className?: string
  orders: OrderedProduct[]
  noDataText: string
  orderType: 'delivered' | 'undelivered'
}

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid ${(props): string => props.theme.borderColors.black};
  }
  tr {
    border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
    &.undelivered {
      td {
        background: ${(props): string => props.theme.backgroundColors.lightRed};
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
  th {
    height: auto;
    padding: 1.5vw;
    font-size: 3.2vw;
    font-weight: bold;
    color: ${(props): string => props.theme.textColors.black};
    line-height: 1.4;
    background: ${(props): string => props.theme.backgroundColors.bodyBg};
    border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
    border-right: 1px solid ${(props): string => props.theme.borderColors.black};
    &:last-child {
      border-right: none;
    }
    &.status {
      width: 21vw;
      min-width: 21vw;
      text-align: center;
    }
    &.delivery-date {
      width: 14.4vw;
      min-width: 14.4vw;
    }
    &.purchase-date {
      width: 14.4vw;
      min-width: 14.4vw;
    }
    &.purchaser {
      width: 20vw;
      min-width: 20vw;
      dt {
        text-align: left;
      }
      p {
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    &.product-name {
      width: 60vw;
      min-width: 60vw;
      dt {
        text-align: left;
      }
      p {
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    &.product-type {
      width: 16.1vw;
      min-width: 16.1vw;
    }
    &.product-time {
      width: 15vw;
      min-width: 15vw;
    }
    &.start-at {
      width: 16.1vw;
      min-width: 16.1vw;
    }
    &.order-id {
      width: 17vw;
      min-width: 17vw;
    }
  }

  td {
    height: 12.2vw;
    padding: 1.5vw 2vw;
    font-size: 3.2vw;
    color: ${(props): string => props.theme.textColors.black};
    line-height: 1.4;
    background: ${(props): string => props.theme.backgroundColors.bodyBg};
    vertical-align: middle;
    border-right: 1px solid ${(props): string => props.theme.borderColors.black};
    &:last-child {
      border: none;
    }

    &.purchaser,
    &.product-name {
      word-break: break-all;
    }

    &.product-type,
    &.product-time,
    &.order-id {
      text-align: center;
    }

    &.start-at {
      padding: 1.5vw 3.3vw;
    }

    p {
      width: 100%;
      line-height: 1.4em;
      text-align: center;
      span {
        font-size: 2.67vw;
        line-height: 0.6;
      }
    }
  }
  .no-data {
    width: 100%;
    height: auto;
    padding-left: 4vw;
    font-size: 3.2vw;
    font-weight: 500;
    line-height: 2;
    letter-spacing: 0.05em;
    color: ${(props): string => props.theme.textColors.gray};
    white-space: pre-wrap;
  }

  ${mediaPc`
    width: 100%;
    overflow-x: visible;
    
    table {
      width: 668px;
      margin-bottom: 20px;
    }
    th {
      padding: 5px;
      font-size: 12px;
      background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};

      &.status {
        width: 80px;
        min-width: 80px;
      }

      &.delivery-date {
        width: 50px;
        min-width: 50px;
        &.wide {
          width: 55px;
          min-width: 55px;
        }
      }
      &.purchase-date {
        width: 50px;
        min-width: 50px;
        &.wide {
          width: 55px;
          min-width: 55px;
        }
      }
      &.purchaser {
        width: 70px;
        min-width: 70px;
      }
      &.product-name {
        width: 250px;
        min-width: 250px;
      }
      &.product-type {
        width: 60px;
        min-width: 60px;
      }
      &.product-time {
        width: 50px;
        min-width: 50px;
      }
      &.start-at {
        width: 60px;
        min-width: 60px;
      }
      &.order-id {
        width: 50px;
        min-width: 50px;
      }
    }
    td {
      height: auto;
      min-height: 46px;
      padding: 6px;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.4;

      &.start-at {
        padding: 6px 12px;
      }
    }
    .no-data {
      min-height: auto;
      padding: 10px;
      font-size: 14px;
    }
  `}
`

export const OrderTable: React.FC<Props> = ({ className = '', orderType, orders, noDataText }) => {
  const history = useHistory()

  const goToDeliveryPage = (orderId: string): void => {
    if (userType() === 'business') {
      history.push(`/business/product/delivery/${orderId}`)
    } else if (userType() === 'creator') {
      history.push(`/creator/product/delivery/${orderId}`)
    }
  }

  return (
    <Wrapper className={className}>
      <table>
        <thead>
          <tr>
            <th className="status">ステータス</th>
            {orderType === 'delivered' && <th className={orderType === 'delivered' ? 'delivery-date wide' : 'delivery-date'}>納品日</th>}
            <th className={orderType === 'delivered' ? 'purchase-date wide' : 'purchase-date'}>購入日</th>
            <th className="purchaser">購入者</th>
            <th className="product-name">購入商品</th>
            <th className="product-type">納品形式</th>
            <th className="product-time">時間</th>
            {orderType === 'undelivered' && <th className="start-at">開始時刻</th>}
            <th className="order-id">注文ID</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index} className={order.status !== OrderedProductStatus.Delivered ? 'undelivered' : ''}>
              <td>
                <OrderStatus
                  orderedProductStatus={order.status}
                  orderedProductLiveTalkStatus={order.live_talk_status}
                  onClick={(): void => goToDeliveryPage(order.id)}
                />
              </td>
              {orderType === 'delivered' && (
                <td>
                  {moment(order.delivered_at).format('M/D')}
                  <br />
                  <span>{moment(order.delivered_at).format('HH:mm')}</span>
                </td>
              )}
              <td>
                {moment(order.order.paid_at).format('M/D')}
                <br />
                <span>{moment(order.order.paid_at).format('HH:mm')}</span>
              </td>
              <td className="purchaser">{order.order.user.name}</td>
              <td className="product-name">{order.order.product.name}</td>
              <td className="product-type">{order.order.product.product_type_name}</td>
              <td className="product-time">
                {order.order.product.product_type === ProductType.LiveTalk
                  ? `${order.order.product.live_talk?.talk_minutes}分`
                  : order.order.product.product_type === ProductType.Movie
                  ? `${order.order.product.movie_seconds}秒`
                  : 'ー'}
              </td>
              {orderType === 'undelivered' && (
                <td className="start-at">
                  {order.order.product.product_type === ProductType.LiveTalk ? (
                    <>
                      {moment(order.order.product.live_talk?.started_at).format('M/D')}
                      <br />
                      <span>{moment(order.order.product.live_talk?.started_at).format('HH:mm')}</span>
                    </>
                  ) : (
                    'ー'
                  )}
                </td>
              )}
              <td className="order-id">{order.order_id}</td>
            </tr>
          ))}

          {!orders.length && (
            <tr>
              <td className="no-data" colSpan={8}>
                {noDataText}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  )
}
