import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  maxLength: number
  currentSlideIndex: number
}

const Wrapper = styled.div`
  ul {
    display: flex;
    li {
      width: 90px;
      height: 5px;
      margin: 0 7px;
      border-radius: 3px;
      position: relative;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.4);
      @keyframes sliderProgressBar {
        0% {
          left: -90px;
        }
        100% {
          left: 0px;
        }
      }
      &.active {
        &::before {
          opacity: 1;
          animation: sliderProgressBar 5s forwards;
          position: absolute;
          width: 90px;
          height: 5px;
          content: '';
          background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
          z-index: 100;
        }
      }
      &.completed {
        background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
      }
    }
  }
  ${mediaPc`
  `}
`

export const SliderProgressBar: React.FC<Props> = ({ className = '', maxLength, currentSlideIndex }) => {
  const progressBar = (): React.ReactElement => {
    const bars = []

    for (let i = 1; i <= maxLength; i++) {
      let className = ''
      if (currentSlideIndex > i) {
        className = 'completed'
      } else if (i === currentSlideIndex) {
        className = 'active'
      }

      // swiperの仕様上、currentSlideIndexがスライド総数+1で渡ってくるのでその場合の対応
      if (currentSlideIndex > maxLength) {
        if (i === 1) {
          className = 'active'
        } else {
          className = ''
        }
      }
      bars.push(<li className={className} key={i} />)
    }
    return <ul>{bars}</ul>
  }

  return <Wrapper className={className}>{progressBar()}</Wrapper>
}
