import React from 'react'
import styled, { DefaultTheme, ThemeProps } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import { PageTitle, Icon } from '../../atoms'
import { CreatorRankingDurationTab } from '../../molecules'
import { RankedCreators } from '../../organisms'
import { CreatorRanking } from '../../../types/graphql'
import Ranking from '../../../assets/images/icon/ranking.png'

type Props = {
  creatorRanking: CreatorRanking[]
  selectedDuration: 'weekly' | 'monthly'
  isUserLoggedIn?: boolean
  onChangeDuration: (durationType: 'weekly' | 'monthly') => void
  onClickFavorite?: (creatorId: string, isFavorite: boolean) => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};

  .title-wrapper {
    padding: 5vw 7.2vw;

    & > .inner {
      .title {
        margin-bottom: 2vw;
        display: flex;
        justify-content: center;
        align-items: center;

        .ranking-icon {
          width: 5vw;
          margin-right: 2vw;
        }

        .page-title {
          letter-spacing: 0.3em;
        }
      }

      .subtitle {
        font-size: 2.1vw;
        text-align: center;
      }
    }
  }

  .ranking-content {
    & > .inner {
      width: 100%;
      padding: 0 4.8vw 6vw;
    }

    .creator-ranking-tab-wrapper {
      .creator-ranking-tab {
        margin: 0 auto 8vw;
      }
    }
  }

  ${mediaPc`
  .title-wrapper {
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 80px 20px 27px;

    & > .inner {
      width: 1040px;
      margin: 0 auto;

      .title {
        margin-bottom: 7px;

        .ranking-icon {
          width: 32px;
          margin-right: 18px;
        }
      }

      .subtitle {
        font-size: 12px;
      }
    }    
  }
  
  .ranking-content {
      width: 100%;
      min-width: 1040px;
      
      & > .inner {
        width: 1040px;
        margin: 0 auto;
        padding: 50px 20px 74px;
      }
  
      .creator-ranking-tab-wrapper {
        margin-bottom: 55px;
        display: flex;
        justify-content: flex-end;
  
        .creator-ranking-tab {
          margin: 0;
        }
      }
    }
  `}
`

export const CreatorRankingTpl: React.FC<Props> = ({
  creatorRanking,
  selectedDuration,
  isUserLoggedIn,
  onChangeDuration,
  onClickFavorite,
}) => {
  return (
    <Wrapper>
      <div className="title-wrapper">
        <div className="inner">
          <div className="title">
            <Icon src={Ranking} alt="ランキング" className="ranking-icon" />
            <PageTitle title="CREATOR RANKING" className="page-title" />
          </div>
          <p className="subtitle">人気クリエイター販売数ランキング</p>
        </div>
      </div>
      <div className="ranking-content">
        <div className="inner">
          <div className="creator-ranking-tab-wrapper">
            <CreatorRankingDurationTab
              selectedDuration={selectedDuration}
              onChangeDuration={onChangeDuration}
              className="creator-ranking-tab"
            />
          </div>
          <RankedCreators creatorRanking={creatorRanking} isUserLoggedIn={isUserLoggedIn} onClickFavorite={onClickFavorite} />
        </div>
      </div>
    </Wrapper>
  )
}
