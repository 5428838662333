import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SelectboxWithLabel, SalesTable } from '../../../molecules'
import { mediaPc } from '../../../../lib/MediaQuery'
import { Affiliation, SalesPerMonth } from '../../../../types/graphql'
import moment from 'moment'

type Props = {
  className?: string
  affiliationId?: string
  selectedAffiliation: string
  affiliations: Affiliation[]
  sales: SalesPerMonth[]
  onAffiliationChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  .affiliation-select {
    margin-bottom: 6.4vw;
    select {
      color: ${(props): string => props.theme.textColors.black};
    }
  }

  ${mediaPc`
    .affiliation-select {
      margin-bottom: 15px;
      width: 200px;
    }
  `}
`

export const AffiliationSalesPerMonth: React.FC<Props> = ({
  className = '',
  affiliationId,
  selectedAffiliation,
  affiliations,
  sales,
  onAffiliationChange,
}) => {
  const history = useHistory()

  const goToCreatorMonthlySales = (month: string): void => {
    history.push(
      `/business/sales/affiliation/creator/${moment(month).format('YYYYMM')}${affiliationId ? `?affiliationId=${affiliationId}` : ''}`,
    )
  }

  const affiliationOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  affiliations.forEach((affiliation) => {
    affiliationOptions.push({ value: affiliation.id, label: affiliation.name })
  })
  affiliationOptions.push({ value: '0', label: '所属なし' })

  return (
    <Wrapper className={className}>
      <SelectboxWithLabel
        label="所属選択"
        options={affiliationOptions}
        value={selectedAffiliation}
        onChange={onAffiliationChange}
        className="affiliation-select"
      />
      <SalesTable sales={sales} noDataText="月別の売上が一覧で表示されます" onClick={(month) => goToCreatorMonthlySales(month)} />
    </Wrapper>
  )
}
