import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button } from '../../../atoms'
import { mediaPc } from '../../../../lib/MediaQuery'

const Wrapper = styled.div`
  .title {
    margin-bottom: 4.8vw;

    h2 {
      width: 100%;
      font-size: 3.2vw;
      font-weight: bold;
      color: #222;
      letter-spacing: 0.05em;
    }
  }
  p {
    width: 100%;
    margin-bottom: 4.8vw;
    font-size: 3.2vw;
    text-align: center;
    color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    letter-spacing: 0.05em;
  }
  ${mediaPc`
    .title {
      margin-bottom: 50px;
      h2 {
        font-size: 16px;
      }
    }
    p {
      margin-bottom: 50px;
      font-size: 16px;
    }
  `}
`

export const UserLoginRegister: React.FC = () => {
  const history = useHistory()

  const goToRegister = (): void => {
    history.push('/registration')
  }

  return (
    <Wrapper>
      <div className="title">
        <h2>ユーザー新規登録（購入者）</h2>
      </div>
      <p>
        アカウントをお持ちでない場合は
        <br />
        こちらから作成をお願い致します
      </p>
      <Button buttonType="black" onClick={(): void => goToRegister()}>
        新規登録はこちら
      </Button>
    </Wrapper>
  )
}
