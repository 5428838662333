import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Pagination, CreatorSearchInput } from '../../molecules'
import { CreatorSearchResult } from '../../organisms'
import { Creator } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  searchWord?: string
  creators: Creator[]
  totalCreatorNumber: number
  creatorFrom: number
  creatorTo: number
  lastPage: number
  currentPage: number
  isUserLoggedIn?: boolean
  onChangeSearchForm?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClickSearchButton?: () => void
  onClickFavorite?: (creatorId: string, isFavorite: boolean) => void
}

const Wrapper = styled.div`
  width: 100%;
  padding: 5vw 7.2vw;
  background: ${(props): string => props.theme.backgroundColors.gray};

  .creator-search-input {
    margin-bottom: 11.5vw;
  }

  .pagination {
    margin-bottom: 13vw;
  }

  .no-creator {
    font-size: 3.735vw;
    text-align: center;
    font-weight: 500;
    color: ${(props): string => props.theme.textColors.gray};
    line-height: 1.14;
    letter-spacing: 0.05em;
  }

  ${mediaPc`
    width: 1040px;
    margin: 0 auto;
    padding: 74px 20px;
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

    .creator-number {
      margin-bottom: 27px;
      font-size: 14px;
      letter-spacing: 0.1em;
      line-height: 2.3;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.gray};
    }

    .no-creator {
      font-size: 14px;
    }  
  `}
`

export const CreatorSearchResultTpl: React.FC<Props> = ({
  searchWord,
  creators,
  totalCreatorNumber,
  creatorFrom,
  creatorTo,
  currentPage,
  lastPage,
  isUserLoggedIn,
  onChangeSearchForm,
  onClickSearchButton,
  onClickFavorite,
}) => {
  return (
    <Wrapper>
      <div className="sp">
        <CreatorSearchInput
          searchWord={searchWord}
          onChange={onChangeSearchForm}
          onClick={onClickSearchButton}
          className="creator-search-input"
        />
      </div>
      {creators.length !== 0 && (
        <>
          <p className="pc creator-number">
            {totalCreatorNumber}件中{creatorFrom}件〜{creatorTo}件を表示
          </p>
          <CreatorSearchResult
            creators={creators}
            isUserLoggedIn={isUserLoggedIn}
            onClickFavorite={onClickFavorite}
            className="creator-search-result"
          />
          <Pagination currentPage={currentPage} lastPage={lastPage} className="pagination" />
        </>
      )}
      {creators.length === 0 && <p className="no-creator">該当するクリエイターが見つかりませんでした</p>}
    </Wrapper>
  )
}
