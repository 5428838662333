import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Button } from '../../atoms'
import { ContentTitleWithLink } from '../../molecules'
import { ProductListForManagement } from '../../organisms'
import { Product, PublishedProduct } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  publishedProducts: PublishedProduct[]
  reservedProducts: Product[]
  disabledProducts: Product[]
  onClickToDisabled?: (productId: string) => void
  onClickToEnd?: (productId: string) => void
  onClickToDisplay?: (productId: string) => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};

  .go-to-registration-product {
    margin-bottom: 7vw;
  }

  .products {
    margin-bottom: 7vw;
  }

  .head-line {
    margin-bottom: 4.3vw;
  }

  .no-product {
    width: 100%;
    padding-bottom: 15px;
    text-align: center;
    color: ${(props): string => props.theme.textColors.gray};
  }

  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

    .products {
      margin-bottom: 60px;
    }

    .head-line {
      margin-bottom: 15px;
    }
  `}
`

export const CreatorProductsTpl: React.FC<Props> = ({
  publishedProducts,
  reservedProducts,
  disabledProducts,
  onClickToDisabled,
  onClickToEnd,
  onClickToDisplay,
}) => {
  const history = useHistory()

  const goToPublishedProductList = (): void => {
    history.push(`/creator/products/published`)
  }

  const goToReserveProductList = (): void => {
    history.push(`/creator/products/reserved`)
  }

  const goToDisabledProductList = (): void => {
    history.push(`/creator/products/disabled`)
  }

  const goToRegisterProduct = (): void => {
    history.push(`/creator/product/register`)
  }
  return (
    <Wrapper>
      <Button className="go-to-registration-product sp" buttonType="red" onClick={goToRegisterProduct}>
        新しい商品を販売する
      </Button>
      <div className="products">
        <ContentTitleWithLink className="head-line" title="公開中の商品" linkText="一覧を見る" onClick={goToPublishedProductList} />
        {publishedProducts.length > 0 ? (
          <ProductListForManagement
            productListType="published"
            products={publishedProducts}
            oneLineItemsNum={4}
            onClickToDisabled={onClickToDisabled}
            onClickToEnd={onClickToEnd}
          />
        ) : (
          <div className="no-product">販売中の商品はありません</div>
        )}
      </div>
      <div className="products">
        <ContentTitleWithLink className="head-line" title="公開日時指定商品" linkText="一覧を見る" onClick={goToReserveProductList} />
        {reservedProducts.length > 0 ? (
          <ProductListForManagement
            productListType="reserved"
            products={reservedProducts}
            oneLineItemsNum={4}
            onClickToDisabled={onClickToDisabled}
          />
        ) : (
          <div className="no-product">公開日時指定中の商品はありません</div>
        )}
      </div>
      <div className="products">
        <ContentTitleWithLink className="head-line" title="非表示の商品" linkText="一覧を見る" onClick={goToDisabledProductList} />
        {disabledProducts.length > 0 ? (
          <ProductListForManagement
            productListType="disabled"
            products={disabledProducts}
            onClickToDisplay={onClickToDisplay}
            oneLineItemsNum={4}
          />
        ) : (
          <div className="no-product">非表示の商品はありません</div>
        )}
      </div>
    </Wrapper>
  )
}
