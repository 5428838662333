import React from 'react'
import styled from 'styled-components'

import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  text?: string
  progress: number
}

const StyledProgressBar = styled.div`
  display: flex;
  flex-direction: column;

  .progress-bar-text {
    margin: 0 auto 2vw;
    padding: 5px 0;
    font-size: 4.267vw;
    font-weight: 700;
    color: ${(props): string => props.theme.textColors.white};
  }

  .progress-bar-background {
    width: 50vw;
    margin: 0 auto;
    background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
    height: 2vw;
    border-radius: 10px;

    .progress-bar-active {
      background-color: ${(props): string => props.theme.backgroundColors.primary};
      width: 0%;
      height: 2vw;
      border-radius: 10px;
    }
  }

  ${mediaPc`
    .progress-bar-text {
      margin: 0 auto 14px;
      font-size: 16px;
    }

    .progress-bar-background {
      width: 200px;
      height: 8px;
      border-radius: 4px;
  
      .progress-bar-active {
        width: 0%;
        height: 8px;
        border-radius: 4px;
      }
    }
  `}
`

export const ProgressBar: React.FC<Props> = ({ className = '', text = '', progress }) => {
  return (
    <StyledProgressBar className={className}>
      <p className="progress-bar-text">{text}</p>
      <div className="progress-bar-background">
        <div className="progress-bar-active" style={{ width: `${progress}%` }}></div>
      </div>
    </StyledProgressBar>
  )
}
