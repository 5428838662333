import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
}

const StyledLoadingCircle = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  svg {
    path {
      &.base-circle {
        fill: ${(props): string => props.theme.backgroundColors.lightGray};
      }
      &.spin-circle {
        fill: ${(props): string => props.theme.backgroundColors.primary};
      }
    }
  }
  svg path,
  svg rect {
    fill: #000000;
  }
`

export const LoadingCircle: React.FC<Props> = ({ className = '' }) => {
  return (
    <StyledLoadingCircle className={className}>
      <svg version="1.1" x="0px" y="0px" width="88px" height="88px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40">
        <path
          className="base-circle"
          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
        />
        <path
          className="spin-circle"
          d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
C22.32,8.481,24.301,9.057,26.013,10.047z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="1.0s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </StyledLoadingCircle>
  )
}
