import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessCreatorOrdersTpl, BusinessTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  OrderedProduct,
  useCreatorInBusinessLazyQuery,
  useBusinessUndeliveredOrderedProductsLazyQuery,
  usePagedBusinessDeliveredOrderedProductsLazyQuery,
} from '../../../types/graphql'

import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { useSetMyProfile } from '../../../lib/SetMyProfile'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessCreatorOrders: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [undeliveredOrders, setUndeliveredOrders] = useState<OrderedProduct[]>([])
  const [deliveredOrders, setDeliveredOrders] = useState<OrderedProduct[]>([])

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)

  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const history = useHistory()

  const [creatorInBusinessLazyQuery] = useCreatorInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setCreator(data.CreatorInBusiness)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [businessUndeliveredOrderedProductsLazyQuery] = useBusinessUndeliveredOrderedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setUndeliveredOrders(data.BusinessUndeliveredOrderedProducts ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedBusinessDeliveredOrderedProductsLazyQuery] = usePagedBusinessDeliveredOrderedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setDeliveredOrders(data.PagedBusinessDeliveredOrderedProducts?.data ?? [])
        setLastPage(data.PagedBusinessDeliveredOrderedProducts?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const onClickOrderedUsers = (): void => {
    if (creator) {
      history.push(`/business/creator/${creator.id}/ordered-users`)
    }
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    creatorInBusinessLazyQuery({
      variables: {
        id: props.match.params.id,
      },
    })
    businessUndeliveredOrderedProductsLazyQuery({
      variables: {
        creator_id: props.match.params.id,
      },
    })
    pagedBusinessDeliveredOrderedProductsLazyQuery({
      variables: {
        creator_id: props.match.params.id,
        first: 30,
        page: Number(pageParam),
      },
    })
  }, [
    creatorInBusinessLazyQuery,
    props.match.params.id,
    props.location.search,
    businessUndeliveredOrderedProductsLazyQuery,
    pagedBusinessDeliveredOrderedProductsLazyQuery,
  ])

  return (
    <>
      <Meta title={creator ? `${creator.name}さん 注文状況一覧` : ''} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <>
          {creator && (
            <BusinessCreatorOrdersTpl
              creator={creator}
              undeliveredOrders={undeliveredOrders}
              deliveredOrders={deliveredOrders}
              currentPage={currentPage}
              lastPage={lastPage}
              onClickOrderedUsers={onClickOrderedUsers}
            />
          )}
        </>
      </BusinessTwoColumnLayout>
    </>
  )
}
