import React from 'react'
import { UserMypageEditForm } from '../../../organisms'
import { PageSubtitle } from '../../../atoms'
import { User } from '../../../../types/graphql'

type Props = {
  user: User
  onClickUpdateUserAccount: () => void
  onChangeState: (name: string, value: string) => void
}

export const UserMypageEditTpl: React.FC<Props> = ({ user, onClickUpdateUserAccount, onChangeState }) => {
  return (
    <>
      <PageSubtitle subtitle="アカウント情報編集" />
      <UserMypageEditForm user={user} onClickUpdateUserAccount={onClickUpdateUserAccount} onChangeState={onChangeState} />
    </>
  )
}
