import React from 'react'
import styled from 'styled-components'
import { PurchasedProductListItem } from '../../../molecules'
import { PublishedOrderedProduct, ProductType } from '../../../../types/graphql'

import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  className?: string
  purchasedProducts: PublishedOrderedProduct[]
  onClickPurchasedProduct: (productId: string, productType: ProductType) => void
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mediaPc`
      justify-content: start;
      padding: 0;
    `}
`

export const PurchasedProductList: React.FC<Props> = ({ className, purchasedProducts, onClickPurchasedProduct }) => {
  return (
    <Wrapper className={className}>
      {purchasedProducts.map((purchasedProduct, index) => (
        <PurchasedProductListItem key={index} purchasedProduct={purchasedProduct} onClickPurchasedProduct={onClickPurchasedProduct} />
      ))}
    </Wrapper>
  )
}
