import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessCreatorSalesPerMonth as BusinessCreatorSalesPerMonthOrganism } from '../../../components/organisms'
import { BusinessTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { Creator, SalesPerMonth, useCreatorInBusinessLazyQuery, useCreatorSalesPerMonthLazyQuery } from '../../../types/graphql'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessCreatorSalesPerMonth: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [sales, setSales] = useState<SalesPerMonth[]>([])
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [creatorInBusinessLazyQuery] = useCreatorInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setCreator(data.CreatorInBusiness)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [creatorSalesPerMonthLazyQuery] = useCreatorSalesPerMonthLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setSales(data.CreatorSalesPerMonth.sales_per_month ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    creatorInBusinessLazyQuery({
      variables: {
        id: props.match.params.id,
      },
    })
    creatorSalesPerMonthLazyQuery({
      variables: {
        creator_id: props.match.params.id,
      },
    })
  }, [creatorInBusinessLazyQuery, creatorSalesPerMonthLazyQuery, props.match.params.id])

  return (
    <>
      <Meta title={creator ? `${creator.name}さん 売上一覧` : ''} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {creator && (
        <BusinessTwoColumnLayout currentTab="creator">
          <BusinessCreatorSalesPerMonthOrganism creator={creator} sales={sales} />
        </BusinessTwoColumnLayout>
      )}
    </>
  )
}
