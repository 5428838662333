import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'

import { PageTitle } from '../../../atoms'
import { LoginForm, UserLoginRegister } from '../../../organisms'
import { mediaPc } from '../../../../lib/MediaQuery'

import loginIcon from '../../../../assets/images/icon/login.png'

type Props = {
  email: string
  password: string
  onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void
  login: () => void
  emailError: string | null
  passwordError: string | null
}

const Wrapper = styled.div`
  width: 100%;

  .title-wrapper {
    width: 100%;
    padding: 5vw 7.2vw;
    text-align: center;
    background: ${(props): string => props.theme.backgroundColors.gray};

    .page-title {
      width: auto;
      padding-left: 7vw;
      display: inline-block;
      letter-spacing: 0.1em;
      background: url('${loginIcon}') left center no-repeat;
      background-size: 5vw;
    }
  }

  ${mediaPc`
    .title-wrapper {
      width: 1040px;
      margin: 0 auto;
      padding: 80px 20px 45px;
      background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

      .page-title {
        padding-left: 50px;
        letter-spacing: 0.3em;
        background-size: 32px;
      }
    }
    `}
`

const Content = styled.div`
  width: 100vw;
  background: ${(props): string => props.theme.backgroundColors.gray};

  .inner {
    width: 100%;
    padding: 5vw 7.2vw 15vw;
    & > .login {
      margin-bottom: 10vw;
    }

    & > .right-column {
      width: 100%;
      margin: 0 auto;
    }
  }
  ${mediaPc`
    width: 100%;
    min-width: 1040px;
    .inner {
      width: 900px;
      margin: 0 auto;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;

      & > .login {
        margin: 0;
        padding: 60px 115px 55px 0;
        border-right: 1px solid #fff;
      }
      & > .right-column {
        margin: 0;
        padding: 60px 0 0 100px;
        position: relative;
      }
    }
  `}
`
export const UserLoginTpl: React.FC<Props> = ({ email, password, onChangeEmail, onChangePassword, login, emailError, passwordError }) => {
  return (
    <Wrapper>
      <div className="title-wrapper">
        <PageTitle title="ユーザーログイン / 新規登録" className="page-title" />
      </div>

      <Content>
        <div className="inner">
          <div className="login">
            <LoginForm
              email={email}
              password={password}
              onChangeEmail={onChangeEmail}
              onChangePassword={onChangePassword}
              login={login}
              emailError={emailError}
              passwordError={passwordError}
            />
          </div>
          <div className="right-column">
            <UserLoginRegister />
          </div>
        </div>
      </Content>
    </Wrapper>
  )
}
