import React from 'react'
import styled from 'styled-components'
import { PageTitle, Icon } from '../../atoms'
import heart from '../../../assets/images/icon/heart.png'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: self-start;

  .icon {
    width: 8.5vw;
    margin-right: 2vw;
    padding: 0.5vw 0;
  }

  .page-title {
    margin-bottom: 1.5vw;
    line-height: 1;
    letter-spacing: 0.3em;
  }

  .page-subtitle {
    font-size: 2.4vw;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  ${mediaPc`
    .icon {
      width: 60px;
      margin-right: 13px;
    }

    .page-title {
      width: 100%;
      margin-bottom: 5px;
    }

    .page-subtitle {
      font-size: 12px;
      line-height: 1.2;
    }
  `}
`

export const FavoriteNewTitle: React.FC<Props> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Icon src={heart} alt="お気に入り新着" className="icon" />
      <div className="title">
        <PageTitle title="お気に入り新着" className="page-title" />
        <p className="page-subtitle">お気に入りしたクリエイターの新着商品</p>
      </div>
    </Wrapper>
  )
}
