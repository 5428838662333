import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Label, TextInput } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  required?: boolean
  className?: string
  type?: string
  label?: string
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  placeholder?: string
  error?: boolean
  readOnly?: boolean
}

const Wrapper = styled.div`
  .label {
    margin-bottom: 2.1vw;
    .required {
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.red};
    }
  }
  ${mediaPc`
  .label {
      margin-bottom: 5px;
      white-space: pre-wrap;
    }
  `}
`

export const TextInputWithLabel: React.FC<Props> = ({
  required = false,
  className = '',
  type = 'text',
  label = '',
  name = '',
  value = '',
  placeholder = '',
  onChange = () => {},
  error,
  readOnly,
}) => {
  return (
    <Wrapper className={className}>
      {label && (
        <Label className="label">
          {label}
          {required && <span className="required">【必須】</span>}
        </Label>
      )}
      <TextInput type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} error={error} readOnly={readOnly} />
    </Wrapper>
  )
}
