import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorSearchResultTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { Creator, usePagedActiveCreatorsLazyQuery, useFavoriteCreatorMutation, useUnfavoriteCreatorMutation } from '../../../types/graphql'
import { userType } from '../../../lib/UserType'

type Props = { isUserLoggedIn?: boolean } & RouteComponentProps

export const CreatorSearchResult: React.FC<Props> = (props) => {
  const [creators, setCreators] = useState<Creator[]>([])
  const [keyword, setKeyWord] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [totalCreatorNumber, setTotalCreatorNumber] = useState<number>(0)
  const [creatorFrom, setCreatorFrom] = useState<number>(0)
  const [creatorTo, setCreatorTo] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()

  const [pagedActiveCreatorsLazyQuery] = usePagedActiveCreatorsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data.PagedActiveCreators !== null && data.PagedActiveCreators !== undefined) {
        setCreators(data.PagedActiveCreators.data)
        setLastPage(data.PagedActiveCreators.paginatorInfo.lastPage)
        setTotalCreatorNumber(data.PagedActiveCreators.paginatorInfo.total)
        setCreatorFrom(Number(data.PagedActiveCreators.paginatorInfo.firstItem))
        setCreatorTo(Number(data.PagedActiveCreators.paginatorInfo.lastItem))
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [favoriteCreatorMutation] = useFavoriteCreatorMutation({
    onCompleted: () => {
      pagedActiveCreatorsLazyQuery({
        variables: {
          input: {
            keyword: keyword,
          },
          first: 20,
          page: currentPage,
        },
      })
      setFlashMessage({ type: 'success', message: 'お気に入り登録しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り登録できませんでした' })
      }
    },
  })
  const [unfavoriteCreatorMutation] = useUnfavoriteCreatorMutation({
    onCompleted: () => {
      pagedActiveCreatorsLazyQuery({
        variables: {
          input: {
            keyword: keyword,
          },
          first: 20,
          page: currentPage,
        },
      })
      setFlashMessage({ type: 'success', message: 'お気に入り解除しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り解除できませんでした' })
      }
    },
  })

  const onChangeSearchForm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setKeyWord(event.target.value)
  }

  const onClickSearchButton = (): void => {
    if (userType() === 'business') {
      history.push(`/business/creator/search/result?keyword=${keyword}`)
    } else if (userType() === 'creator') {
      history.push(`/creator/search/result?keyword=${keyword}`)
    } else if (userType() === 'user') {
      history.push(`/creators/search/result?keyword=${keyword}`)
    }
  }

  const onClickFavorite = (creatorId: string, isFavorite: boolean): void => {
    setLoading(true)
    setFlashMessage(null)
    if (isFavorite) {
      unfavoriteCreatorMutation({
        variables: {
          creator_id: creatorId,
        },
      })
    } else {
      favoriteCreatorMutation({
        variables: {
          creator_id: creatorId,
        },
      })
    }
  }

  useEffect(() => {
    const paramsKeyword = new URLSearchParams(props.location.search).get('keyword')
    const paramsCurrentPage = new URLSearchParams(props.location.search).get('page')

    setKeyWord(paramsKeyword || '')
    setCurrentPage(Number(paramsCurrentPage) || 1)

    // メモ：クエリはkeywordが'' or nullで空配列が返ってくるように実装されている
    pagedActiveCreatorsLazyQuery({
      variables: {
        input: {
          keyword: String(paramsKeyword),
        },
        first: 20,
        page: Number(paramsCurrentPage),
      },
    })
  }, [pagedActiveCreatorsLazyQuery, props.location.search])

  return (
    <>
      <Meta title="クリエイター検索結果一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorSearchResultTpl
        searchWord={keyword}
        onChangeSearchForm={onChangeSearchForm}
        onClickSearchButton={onClickSearchButton}
        creators={creators}
        totalCreatorNumber={totalCreatorNumber}
        creatorFrom={creatorFrom}
        creatorTo={creatorTo}
        currentPage={currentPage}
        lastPage={lastPage}
        onClickFavorite={onClickFavorite}
        isUserLoggedIn={props.isUserLoggedIn}
      />
    </>
  )
}
