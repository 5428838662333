import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { ProductDetailTpl, RequestProductModalTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType, RequestProduct } from '../../../types/myTypes'
import {
  Creator,
  PublishedProduct,
  useValidateUserTokenLazyQuery,
  usePagedPublishedProductsLazyQuery,
  usePublishedProductLazyQuery,
  useRecommendedCreatorsLazyQuery,
  useFavoriteCreatorMutation,
  useUnfavoriteCreatorMutation,
  useUserRequestPhotoMutation,
  useUserRequestMovieMutation,
  useUserRequestLiveTalkMutation,
  ProductType,
} from '../../../types/graphql'
import { userType } from '../../../lib/UserType'

type Props = { isUserLoggedIn?: boolean } & RouteComponentProps<{ id: string }>

export const ProductDetail: React.FC<Props> = (props) => {
  const requestProductInitialState: RequestProduct = {
    product_type: ProductType.Photo,
    request_contents: [],
    other_text: '',
    requested_dates: '',
  }

  const [creator, setCreator] = useState<Creator>()
  const [product, setProduct] = useState<PublishedProduct>()
  const [otherProducts, setOtherProducts] = useState<PublishedProduct[]>()
  const [recommendCreators, setRecommendCreators] = useState<Creator[]>([])
  const [isUserValid, setIsUserValid] = useState<boolean>(false)
  const [productRequest, setProductRequest] = useState<RequestProduct>(requestProductInitialState)
  const [requestModalVisible, setRequestModalVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()
  const { pathname } = useLocation()

  const [recommendedCreatorsLazyQuery] = useRecommendedCreatorsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setRecommendCreators(data.RecommendedCreators)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedPublishedProductsLazyQuery] = usePagedPublishedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setOtherProducts(data.PagedPublishedProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [publishedProductLazyQuery] = usePublishedProductLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setProduct(data.PublishedProduct)
        setCreator(data.PublishedProduct?.creator || undefined)
        if (data.PublishedProduct) {
          pagedPublishedProductsLazyQuery({
            variables: {
              creator_id: data.PublishedProduct.creator_id,
              exclude_product_id: props.match.params.id,
              first: 8,
              page: 1,
            },
          })
          recommendedCreatorsLazyQuery({
            variables: {
              input: {
                creator_id: data.PublishedProduct.creator_id,
              },
            },
          })
        }
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [favoriteCreatorMutation] = useFavoriteCreatorMutation({
    onCompleted: () => {
      publishedProductLazyQuery({
        variables: {
          product_id: props.match.params.id,
        },
      })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り登録できませんでした' })
      }
    },
  })
  const [unfavoriteCreatorMutation] = useUnfavoriteCreatorMutation({
    onCompleted: () => {
      publishedProductLazyQuery({
        variables: {
          product_id: props.match.params.id,
        },
      })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り解除できませんでした' })
      }
    },
  })

  const [userRequestPhotoMutation] = useUserRequestPhotoMutation({
    onCompleted: () => {
      setLoading(false)
      setFlashMessage({ type: 'success', message: 'リクエスト送信しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'リクエスト送信できませんでした' })
      }
    },
  })

  const [userRequestMovieMutation] = useUserRequestMovieMutation({
    onCompleted: () => {
      setLoading(false)
      setFlashMessage({ type: 'success', message: 'リクエスト送信しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'リクエスト送信できませんでした' })
      }
    },
  })

  const [userRequestLiveTalkMutation] = useUserRequestLiveTalkMutation({
    onCompleted: () => {
      setLoading(false)
      setFlashMessage({ type: 'success', message: 'リクエスト送信しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'リクエスト送信できませんでした' })
      }
    },
  })

  const [validateUserTokenLazyQuery] = useValidateUserTokenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.ValidateUserToken === true && props.isUserLoggedIn === true) {
        setIsUserValid(true)
      }
    },
    onError: () => {
      setLoading(false)
    },
  })

  const onClickFavorite = (creatorId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    favoriteCreatorMutation({
      variables: {
        creator_id: creatorId,
      },
    })
  }

  const onClickUnfavorite = (creatorId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    unfavoriteCreatorMutation({
      variables: {
        creator_id: creatorId,
      },
    })
  }

  const onChangeProductRequestState = (name: string, value: string | string[] | ProductType): void => {
    if (name === 'product_type') {
      setProductRequest({ ...productRequest, [name]: value as ProductType, request_contents: [] })
    } else {
      setProductRequest({ ...productRequest, [name]: value })
    }
  }

  const userRequestPhoto = (): void => {
    if (creator) {
      userRequestPhotoMutation({
        variables: {
          input: {
            creator_id: creator.id,
            is_newly_taken: productRequest.request_contents.includes('is_newly_taken'),
            is_casual_clothes: productRequest.request_contents.includes('is_casual_clothes'),
            is_pajamas: productRequest.request_contents.includes('is_pajamas'),
            is_other: productRequest.request_contents.includes('is_other'),
            other_text: productRequest.other_text,
          },
        },
      })
    }
  }

  const userRequestMovie = (): void => {
    if (creator) {
      userRequestMovieMutation({
        variables: {
          input: {
            creator_id: creator.id,
            is_casual_clothes: productRequest.request_contents.includes('is_casual_clothes'),
            is_pajamas: productRequest.request_contents.includes('is_pajamas'),
            is_good_morning_message: productRequest.request_contents.includes('is_good_morning_message'),
            is_good_night_message: productRequest.request_contents.includes('is_good_night_message'),
            is_say_you_like_me: productRequest.request_contents.includes('is_say_you_like_me'),
            is_other: productRequest.request_contents.includes('is_other'),
            other_text: productRequest.other_text,
          },
        },
      })
    }
  }

  const userRequestLiveTalk = (): void => {
    if (creator) {
      userRequestLiveTalkMutation({
        variables: {
          input: {
            creator_id: creator.id,
            is_casual_clothes: productRequest.request_contents.includes('is_casual_clothes'),
            is_pajamas: productRequest.request_contents.includes('is_pajamas'),
            is_other: productRequest.request_contents.includes('is_other'),
            other_text: productRequest.other_text,
            requested_dates: productRequest.requested_dates,
          },
        },
      })
    }
  }

  const onClickSendProductRequest = (): void => {
    setLoading(true)
    setFlashMessage(null)
    switch (productRequest.product_type) {
      case ProductType.Photo:
        userRequestPhoto()
        break
      case ProductType.Movie:
        userRequestMovie()
        break
      case ProductType.LiveTalk:
        userRequestLiveTalk()
        break
      default:
        break
    }
    setProductRequest(requestProductInitialState)
    setRequestModalVisible(false)
  }

  const onClickProductRequestButton = (): void => {
    if (!isUserValid) {
      history.push({ pathname: '/login', state: { redirect: true, from: 'productRequest', redirectFrom: pathname } })
    }
    setRequestModalVisible(true)
  }

  const closeProductRequestModal = (): void => {
    setRequestModalVisible(false)
  }

  useEffect(() => {
    if (userType() === 'user') {
      validateUserTokenLazyQuery()
    }

    publishedProductLazyQuery({
      variables: {
        product_id: props.match.params.id,
      },
    })
  }, [publishedProductLazyQuery, pagedPublishedProductsLazyQuery, props.match.params.id, validateUserTokenLazyQuery])

  return (
    <>
      <Meta title={product?.creator ? `${product.creator.name}さん 商品詳細` : ''} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {product && creator && (
        <ProductDetailTpl
          creator={creator}
          product={product}
          otherProducts={otherProducts}
          recommendCreators={recommendCreators}
          isUserValid={isUserValid}
          onClickFavorite={onClickFavorite}
          onClickUnfavorite={onClickUnfavorite}
          onClickProductRequestButton={onClickProductRequestButton}
        />
      )}
      {requestModalVisible && (
        <RequestProductModalTpl
          productRequest={productRequest}
          onChangeState={onChangeProductRequestState}
          closeModal={closeProductRequestModal}
          onClickSendProductRequest={onClickSendProductRequest}
        />
      )}
    </>
  )
}
