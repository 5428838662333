import { OrderedUsersSort } from '../types/graphql'

export const orderedUsersSortItems = [
  {
    label: '最終購入日が新しい順',
    value: OrderedUsersSort.LastPaidAtDesc,
  },
  {
    label: '最終購入日が古い順',
    value: OrderedUsersSort.LastPaidAtAsc,
  },
  {
    label: '購入回数が多い順',
    value: OrderedUsersSort.NumberOfPurchasesDesc,
  },
  {
    label: '購入回数が少ない順',
    value: OrderedUsersSort.NumberOfPurchasesAsc,
  },
]
