import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessCreatorEditTpl, BusinessTwoColumnLayout, CreatorIconTrimmingModalTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  Affiliation,
  useUpdateCreatorAccountMutation,
  useCreatorInBusinessLazyQuery,
  useAffiliationsInBusinessLazyQuery,
} from '../../../types/graphql'

import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessCreatorEdit: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [affiliations, setAffiliations] = useState<Affiliation[]>([])
  const [iconFileName, setIconFileName] = useState<string>('')
  const [iconUrl, setIconUrl] = useState<string>('')
  const [iconBeforeTrimming, setIconBeforeTrimming] = useState<string>('')
  const [iconFileAfterTrimming, setIconFileAfterTrimming] = useState<File | null>(null)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()

  const [updateCreatorAccountMutation] = useUpdateCreatorAccountMutation({
    onCompleted: (data) => {
      if (data.UpdateCreatorAccount?.is_change_request_email === true) {
        history.push({
          pathname: `/business/creator/${props.match.params.id}/edit/complete`,
          state: { email: data.UpdateCreatorAccount?.change_request_email },
        })
      } else {
        history.push({ pathname: `/business/creator/${props.match.params.id}`, state: { editComplete: true } })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })
  const [creatorInBusinessLazyQuery] = useCreatorInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setCreator(data.CreatorInBusiness)
      // トリミングのため取得したcreatorのicon_urlを別途セットする
      setIconUrl(data.CreatorInBusiness.icon_url)
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [affiliationsInBusinessLazyQuery] = useAffiliationsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setAffiliations(data.AffiliationsInBusiness ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const changeState = (name: string, value: string): void => {
    setCreator(creator ? { ...creator, [name]: value } : creator)
  }

  const onClickUpdateButton = (): void => {
    setLoading(true)
    setFlashMessage(null)
    updateCreatorAccountMutation({
      variables: {
        creator_id: props.match.params.id,
        input: {
          name: creator?.name ?? '',
          email: creator?.email ?? '',
          profile: creator?.profile ?? '',
          affiliation_id: creator?.affiliation_id,
          twitter_username: creator?.twitter_username,
          instagram_username: creator?.instagram_username,
          youtube_channel_url: creator?.youtube_channel_url,
          kindle_author_url: creator?.kindle_author_url,
          icon: iconFileAfterTrimming,
        },
      },
    })
  }

  const onIconLoad = (result: string, file: File): void => {
    setIconBeforeTrimming(result)
    setIconFileName(file.name)
    setModalVisible(true)
  }

  const closeModal = (): void => {
    setModalVisible(false)
    setIconBeforeTrimming('')
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    creatorInBusinessLazyQuery({
      variables: {
        id: props.match.params.id,
      },
    })
    affiliationsInBusinessLazyQuery()
  }, [creatorInBusinessLazyQuery, affiliationsInBusinessLazyQuery, props.match.params.id])

  return (
    <>
      <Meta title={creator ? `${creator.name}さん 情報編集` : ''} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <>
          {creator && (
            <BusinessCreatorEditTpl
              creator={creator}
              iconUrl={iconUrl}
              affiliations={affiliations}
              onClickUpdateButton={onClickUpdateButton}
              onChangeState={changeState}
              onIconLoad={onIconLoad}
            />
          )}
          {modalVisible && (
            <CreatorIconTrimmingModalTpl
              src={iconBeforeTrimming}
              iconFileName={iconFileName}
              closeModal={closeModal}
              setIconUrlAfterTrimming={(url: string) => {
                setIconUrl(url)
              }}
              setIconFileAfterTrimming={(file: File) => {
                setIconFileAfterTrimming(file)
              }}
            />
          )}
        </>
      </BusinessTwoColumnLayout>
    </>
  )
}
