import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PasswordRenewForm } from '../../molecules'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  password?: string
  passwordConfirm?: string
  passwordError: string | null
  passwordConfirmError: string | null
  passwordMatchError: string | null
  onClick: () => void
  onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangePasswordConfirm: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props): string => props.theme.backgroundColors.gray};
  padding: 7.7vw 7.2vw 51vw;
  ${mediaPc`
    background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 72px 0px 190px;
  `}
`

export const PasswordRenew: React.FC<Props> = ({
  password,
  passwordConfirm,
  passwordError,
  passwordConfirmError,
  passwordMatchError,
  onClick,
  onChangePassword,
  onChangePasswordConfirm,
}) => {
  return (
    <Wrapper>
      <PasswordRenewForm
        password={password}
        passwordConfirm={passwordConfirm}
        passwordError={passwordError}
        passwordConfirmError={passwordConfirmError}
        passwordMatchError={passwordMatchError}
        onClick={onClick}
        onChangePassword={onChangePassword}
        onChangePasswordConfirm={onChangePasswordConfirm}
      />
    </Wrapper>
  )
}
