import React, { useState, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorTwoColumnLayout, CreatorUserRankingTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { CreatorPurchaseUserRanking, useCreatorPurchaseUserRankingLazyQuery } from '../../../types/graphql'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'

export const CreatorPurchaseUserRankingPage: React.FC = () => {
  const [ranking, setRanking] = useState<CreatorPurchaseUserRanking[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const [creatorPurchaseUserRankingLazyQuery] = useCreatorPurchaseUserRankingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setRanking(data.CreatorPurchaseUserRanking)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  useValidateCreatorToken()
  useSetCreatorProfile()

  useEffect(() => {
    creatorPurchaseUserRankingLazyQuery()
  }, [creatorPurchaseUserRankingLazyQuery])

  return (
    <>
      <Meta title="ユーザー購入ランキング" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="ranking">
        <CreatorUserRankingTpl ranking={ranking} />
      </CreatorTwoColumnLayout>
    </>
  )
}
