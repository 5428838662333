import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { TextInput, PageSubtitle } from '../../../atoms'
import { SubtitleWithLink } from '../../../molecules'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  className?: string
  affiliationName: string
  openAffiliationEditModal: () => void
}

const Wrapper = styled.div`
  .affiliation-name {
    height: auto;
    margin-top: 2.7vw;
    padding-left: 4.5vw;
    font-size: 3.2vw;
    font-weight: bold;
    line-height: 3.9;

    &:focus {
      border: 1px solid transparent;
    }
  }

  .affiliation-name-pc {
    display: none;
  }

  ${mediaPc`
    .affiliation-subtitle-sp {
      display: none;
    }

    .affiliation-subtitle-pc {
      margin-bottom: 20px;
    }

    .affiliation-name-pc {
      display: flex;
      line-height: 34px;
      border-bottom: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.black};

      .subtitle {
        color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
        font-size: 14px;
        font-weight: normal;
      }
    }
  `}
`

export const AffiliationDetail: React.FC<Props> = ({ className = '', affiliationName, openAffiliationEditModal }) => {
  return (
    <Wrapper className={className}>
      <SubtitleWithLink subtitle="所属情報" linkText="編集" onClick={openAffiliationEditModal} className="affiliation-subtitle-sp" />
      <PageSubtitle subtitle="所属情報" className="affiliation-subtitle-pc pc" />
      <TextInput value={affiliationName} readOnly className="affiliation-name sp" />
      <SubtitleWithLink subtitle={affiliationName} linkText="編集" onClick={openAffiliationEditModal} className="affiliation-name-pc" />
    </Wrapper>
  )
}
