import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { TermsTitle, TermsBlock } from '../../molecules'
import { APP_NAME } from '../../../const'
import { mediaPc } from '../../../lib/MediaQuery'
import termsOfUse from '../../../assets/images/icon/terms_of_use.png'

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props): string => props.theme.backgroundColors.gray};
  padding: 7.7vw 7.2vw;

  ${mediaPc`
  width: 100%;
    background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 72px 20px 190px;
  `}
`

const Inner = styled.div`
  .terms-title {
    margin-bottom: 5.6vw;
  }

  ${mediaPc`
    max-width: 1000px;
    margin: 0 auto;

    .terms-title {
      margin-bottom: 72px;
    }
  `}
`

export const TermsOfUseTpl: React.FC = () => {
  return (
    <Wrapper>
      <Inner>
        <TermsTitle src={termsOfUse} alt="利用規約" title="利用規約" className="terms-title" />
        <TermsBlock withoutSubtitle>
          本サービスのご利用を希望される方もしくは本サービスを既に利用している方（以下「{APP_NAME}
          会員」と表示します）は、下記サービス利用規約を必ずお読みください。
        </TermsBlock>
        <TermsBlock subtitle="第1条(規約の内容)">
          {APP_NAME} サービス利用規約(以下「本規約」と表示します)は、株式会社ROLO(以下「当社」と表示します)が、{APP_NAME}
          会員に対して提供するコンテンツサービス(以下「本サービス」と表示します)の利用条件、その他の注意事項を定めたものです。
        </TermsBlock>
        <TermsBlock subtitle="第2条(本サービスの内容)">
          1.本サービスは、「{APP_NAME}
          」ウェブサイト（以下「本サイト」と表示します）にて、クリエイターのメッセージ付き画像や動画、グッズなど（以下「メッセージ付き画像等」と表示します）を販売するサービスです。本サービスのコンテンツは有料であり、
          {APP_NAME}会員は別途当社が提示する方法により、予め提示した金額をお支払い頂く必要があります。
          <br />
          2.{APP_NAME}会員の使用する通信端末が本サービスに対応していない場合や、ブラウザによる操作、{APP_NAME}
          会員のスマートフォンなどへの着信や通知、バッテリー不足、設定状況及び通信環境が原因による本サービスの不具合についての返金は行いませんのでご了承ください。
        </TermsBlock>
        <TermsBlock subtitle={`第3条(${APP_NAME}会員)`}>
          1.{APP_NAME}会員になろうとする方は、本規約を承認の上、当社の定める手続きにより当社に入会を申し込むものとします。
          <br />
          2.{APP_NAME}会員の申し込むことができるのは、本人に限るものとし、本人以外が行う登録は一切認められないものとします。
          <br />
          3.{APP_NAME}会員になろうとする方が未成年である場合は、本サービスを利用することについて、法定代理人の同意を得るものとします。
          <br />
          4.{APP_NAME}会員は、本規約に基づき本サービスを利用するものとします。
          <br />
          5.{APP_NAME}会員は、{APP_NAME}会員資格を第三者に利用させ、または貸与、譲渡、売買、質入等をすることはできないものとします。
        </TermsBlock>
        <TermsBlock subtitle="第4条(メールアドレス、パスワード)">
          1.{APP_NAME}
          会員は、メッセージ付き画像等を保存している通信端末や、アカウント情報に登録されているメールアドレス、パスワードの管理責任を負うものとします。
          <br />
          2.{APP_NAME}
          会員は、会員資格を有する間、メールアドレス及びパスワードを第三者に利用させ、または、貸与、譲渡、売買、質入等をすることはできないものとします。また、
          {APP_NAME}
          会員は、メッセージ付き画像等を保存している通信端末を他者に貸与、譲渡、売買、質入等する場合、メッセージ付き画像等が他者に利用等されないよう適切な措置を施すものとします。
          <br />
          3.メッセージ付き画像等を保存している通信端末、メールアドレス及びパスワードの管理不十分、使用上の不注意、第三者の使用等による損害の責任は
          {APP_NAME}会員が負うものとし、当社は一切責任を負いません。
          <br />
          4.{APP_NAME}
          会員は、メールアドレス及びパスワードを第三者に知られた場合、通信端末を第三者に使用されるおそれのある場合には、直ちに当社にその旨連絡するとともに、当社の指示がある場合にはこれに従うものとします。
        </TermsBlock>
        <TermsBlock subtitle={`第5条(${APP_NAME}における送信等)`}>
          1.{APP_NAME}
          会員は、本サービス内におけるアカウント設定その他いかなる場面においても、以下の情報を送信、登録及び表示（以下、これらの行為を併せて「送信等」といいます。）することはできません。
          <br />
          ①相手や他人（法人含む）の名誉または信用を傷つけるもの
          <br />
          ②わいせつな表現またはヌード画像を含むもの
          <br />
          ③詐欺的、虚偽的、欺瞞的である、もしくは誤解を招くもの
          <br />
          ④個人または団体に対して差別、偏見、人種差別、憎悪、嫌がらせまたは侵害を助長するもの
          <br />
          ⑤暴力的もしくは脅迫的である、または他者に対して暴力的もしくは脅迫的な行為を助長するもの
          <br />
          ⑥わいせつ物、ポルノ、児童ポルノ、アダルトグッズ、ヌード写真、アダルトビデオ、アダルトゲーム、ブルセラに関するもの
          <br />
          ⑦売春、児童売春に関するもの
          <br />
          ⑧無限連鎖講、マルチ商法に関するもの
          <br />
          ⑨窃盗、強盗、詐欺、恐喝、横領、背任その他の犯罪により実施されるもの
          <br />
          ⑩特許権、実用新案権、意匠権、商標権、著作権、肖像権その他の他人の権利を侵害するもの
          <br />
          ⑪コンピューターウィルスを含むもの
          <br />
          ⑫異性交際を求めるもの
          <br />
          ⑬異性交際の求めに応じるもの
          <br />
          ⑭異性交際に関する情報を媒介するもの
          <br />
          ⑮公序良俗に反するもの
          <br />
          ⑯個人情報、営業秘密その他一般に公開されていない情報
          <br />
          ⑰犯罪その他の法令違反行為
          <br />
          ⑱その他法令に違反する内容または違反する行為を助長するもの
          <br />
          ⑲上記に該当する恐れのあるもの
          <br />
          ⑳当社が別途定めるルール等に違反するもの
          <br />
          ㉑その他当社が不適当と判断したもの
          <br />
          2.当社は、{APP_NAME}
          会員による送信等に係る情報（以下、「会員送信情報」といいます。）が本規約に違反する場合、その他の当社が不適当と判断した場合には、会員送信情報を削除できるものとします。
          <br />
          3.当社は、会員送信情報を、本サービスの提供及び利用促進に必要な範囲において、当該{APP_NAME}
          会員の事前の承諾なく、無償で、当社及び当社から権利を承継しまたは許諾された者のサーバーへの複製、{APP_NAME}
          会員その他の者に対する公衆送信その他の方法により利用することができるものとします。ただし、会員送信情報は、クリエイター及び法令に定められた場合を除き、当社、第三者（
          {APP_NAME}会員が閲覧を許可した第三者を除きます。）が閲覧することはありません。
          <br />
          4.会員送信情報の著作権は、送信等がなされた場合においても、当社に譲渡されるものではありません。
          <br />
          5.{APP_NAME}
          会員は、会員送信情報に関して著作権が発生する場合であっても、当社及び当社から権利を承継しまたは許諾された者に対して、著作者人格権を行使しないものとします。
        </TermsBlock>
        <TermsBlock subtitle={`第6条(${APP_NAME} サービス利用規約の違反等について)`}>
          1.{APP_NAME}会員が以下の各号に該当した場合、当社は、当社の定める期間、本サービスの一部もしくは全部の利用を認めないこと、又は、
          {APP_NAME}
          会員の会員資格を取り消すことができるものとします。ただし、当社が既に受領した料金及びその他の金銭を返還しないものとします。
          <br />
          ①会員登録申込みの際の個人情報登録、及び{APP_NAME}
          会員となった後の個人情報変更において、その内容に虚偽や不正があった場合、または重複した会員登録があった場合
          <br />
          ②クリエイターおよび他の{APP_NAME}会員に不当に迷惑をかけたと当社が判断した場合
          <br />
          ③本サービスの対価を支払わない場合
          <br />
          ④反社会的勢力と不適切な関係にあると当社が判断した場合
          <br />
          ⑤本規約に違反した場合
          <br />
          ⑥クリエイターから通報があった場合
          <br />
          ⑦第5条1項または第11条の規定に違反した場合
          <br />
          ⑧その他、{APP_NAME}会員として不適切であると当社が判断した場合
          <br />
          2.当社が会員資格を取り消した{APP_NAME}会員は再入会することはできません。
          <br />
          3.第1項に基づく当社の措置により、{APP_NAME}会員に損害が生じた場合でも、当該損害につき、当社は、一切責任を負いません。
        </TermsBlock>
        <TermsBlock subtitle="第7条(本サービスの中断、停止)">
          1.当社は、通信システムの保守、点検、移設、増設、交換等の必要性に応じ、本サービスを中断することができるものとします。
          <br />
          2.当社は、火災、停電、その他事故、天変地異、戦争、疫病、電力会社の事故による電力供給不能等の不可抗力により、本サービスを提供することができない場合、本サービスを中断することができるものとします。
          <br />
          3.前二項に基づき本サービスの中断をしたことによって{APP_NAME}
          会員に損害が発生した場合でも、当社は、一切責任を負いません。また、これらの事柄に関連する{APP_NAME}
          会員への通知は、本サービスのお知らせ欄で行いますので、お客様は必ずご確認ください。
        </TermsBlock>
        <TermsBlock subtitle="第8条(お支払い)">
          1.{APP_NAME}
          会員は当社の有料コンテンツを購入する場合、当社が定めた金額を、当社の定める方法により当社の定める時期までに支払うものとします。また、当社は理由の如何にかかわらず、既に支払われた料金を一切返還しません。
          <br />
          2.{APP_NAME}
          会員は、決済代行会社の定めた契約或いは約款に基づき、決済代行会社が当社に代わって情報料を回収することを承諾するものとします。万が一、
          {APP_NAME}会員が支払うべき情報料の支払いを怠った場合や、またはその可能性があると当社が判断した場合は、事前の通知なく{APP_NAME}
          会員に対するサービスの停止、および、契約の解除を行うことができるものとします。
        </TermsBlock>
        <TermsBlock subtitle="第9条(個人情報の保護)">
          当社は、当社が直接入手したお客様の個人情報を当社のプライバシーポリシーにしたがって厳重に管理し、紛失、破壊、改ざん、及び漏洩等に対する防止策を講じるものとします。
        </TermsBlock>
        <TermsBlock subtitle="第10条(当社の責任)">
          1.当社は、本サービスの内容、ならびに{APP_NAME}
          会員が本サービスを通じて入手したコンテンツ及び情報等について、その完全性、正確性、確実性、有用性等につき、いかなる責任も負わないものとします。
          <br />
          2.当社は、{APP_NAME}
          会員が使用するコンピューター、回線、ソフトウェア等の環境等に基づき生じた損害について、一切責任を負わないものとします。
          <br />
          3.{APP_NAME}
          会員は、自らの責任に基づいて本サービスを利用するものとし、当社は、本サービスにおいてクリエイターが行う一切の行為について、何らの責任を負いません。
          <br />
          4.当社は、クリエイターが販売するメッセージ付き画像等の内容、品質又は性能その他当該画像等の内容に関して、一切責任を負いません。
          <br />
          5.{APP_NAME}会員は法律の範囲内で本サービスをご利用ください。本サービスの利用に関連して{APP_NAME}
          会員及びクリエイターが日本及び外国の法律に触れた場合でも、当社は一切責任を負いません。
          <br />
          6.当社は、当社の故意または重大な過失により{APP_NAME}会員に損害を与えた場合に限り、その損害を賠償します。ただし、賠償する範囲は、
          {APP_NAME}会員に発生した直接かつ通常の損害に限り、特別な事情から発生した損害については、責任を負わないものとします。
          <br />
          9.当社は、本サービスに関して、{APP_NAME}
          会員、クリエイター及び第三者のそれぞれの間で発生した一切のトラブルについて関知しません。したがって、これらのトラブルについては、当事者間で話し合い、訴訟などにより解決するものとします。
        </TermsBlock>
        <TermsBlock subtitle="第11条(禁止事項)">
          1.{APP_NAME}会員は、本サービスのご利用に際して、以下の行為を禁止します。
          <br />
          ①本サービスで提供されたコンテンツや情報を当社の許可なしにインターネット上または第三者へ公開する行為
          <br />
          ②当社または第三者を誹謗中傷する行為
          <br />
          ③当社または第三者の名誉、プライバシー、または社会的信用を侵害する行為
          <br />
          ④当社または第三者の知的財産権(特許権、意匠権、商標権、及び著作権等)を侵害する行為
          <br />
          ⑤クリエイターの個人情報を、違法、不適切に収集、開示その他利用する行為
          <br />
          ⑥他の個人または団体になりすまし、または他の個人または団体と関係があるように不当に見せかける行為
          <br />
          ⑦本サービスで提供されたクリエイターのメッセージ付き画像を、当社の許可なしにインターネット上または第三者へ公開する行為
          <br />
          ⑧本サービスを提供するサーバに不正にアクセスし、ID やパスワードを取得する行為
          <br />
          ⑨本サービスを提供するサーバに短時間に不正アクセスを集中させ、サーバを機能不全にして本サービスの運営を阻害する行為
          <br />
          ⑩本サービスを提供するサーバにコンピュータウィルス等の有害なプログラムを送信する行為
          <br />
          ⑪本サービスに使用されているソフトウェアをリバースエンジニアリングする行為
          <br />
          ⑫ボットなどの自動化された手段を用いて本サービスを利用する行為
          <br />
          ⑬本サービスのバグ、誤作動を利用する行為
          <br />
          ⑭その他前各号に類似する行為
          <br />
          2.当社は、{APP_NAME}会員が上記の事項に抵触する行為があると判断した場合、当該{APP_NAME}
          会員は当社及び第三者に対して、損害賠償を含む一切の法的責任を負い、 当社は一切の責任を負わないものとします。
        </TermsBlock>
        <TermsBlock subtitle="第12条(登録事項の変更)">
          1.{APP_NAME}
          会員は、メールアドレス等の登録事項に変更のあった場合、すみやかに当社の定める手続きにより当社に届け出るものとします。この届出のない場合、当社は、登録事項の変更のないものとして取り扱うことができるものとします。
          <br />
          2.{APP_NAME}会員は、登録事項を変更したことを当社に届け出なかった場合、本サービスを利用できなくなることがあります。
        </TermsBlock>
        <TermsBlock subtitle="第13条(当社からの通知)">
          当社からの通知は、当社に登録されたメールアドレスにメールを送信することまたは当社が提供するアプリケーションの機能を用いた通知方法をもって行い、当該通知は、メールまたはアプリケーションによる通知が通常到達すべきときに到達したものとみなします。
        </TermsBlock>
        <TermsBlock subtitle="第14条(著作権)">
          本サービスに関する著作物の著作権及び編集・表示を含む一切の権限は、当社又は著作権を有する第三者に帰属します。本サービスに関して
          {APP_NAME}
          会員が当社宛に投稿したもの（web上での会議において投稿される音声もしくはチャット上でコメントを含みますが、これらに限られません。）に係る一切の著作権は当社に帰属します。
        </TermsBlock>
        <TermsBlock subtitle="第15条(商標)">
          本サービスで使用されている商標、ロゴ、当社の名称及びサービスマーク等は、当社の商号、商標または登録商標です。当社のロゴマークのご使用に際しては、当社の書面による事前許諾が必要となります。
        </TermsBlock>
        <TermsBlock subtitle="第16条(本規約の変更)">
          1.当社は、本規約をいつでも変更することができるものとします。本規約を変更した場合、料金その他の本サービスに関する一切の事項は変更後の規約によるものとします。
          <br />
          2.{APP_NAME}
          会員は、定期的に本規約の最新の内容を確認するものとし、本規約が変更された後に本サービスを利用した時点で、当該変更に同意したものとみなします。
          <br />
          3.本規約の変更により{APP_NAME}
          会員に損害が生じた場合、直接損害もしくは間接損害、または予見可能性の有無を問わず、当社は、かかる損害について一切責任を負わないものとします。
        </TermsBlock>
        <TermsBlock subtitle="第17条(サービス廃止)">
          当社は、当社の都合によりいつでも本サービスを廃止できるものとし、当社の措置により{APP_NAME}
          会員に損害が生じても、一切責任を負いません。
        </TermsBlock>
        <TermsBlock subtitle="第18条(退会)">
          {APP_NAME}
          会員は、当社の定める手続きにより退会することができます。この場合、当社は、既に受領した料金及びその他一切の金銭を返還しないものとします。
        </TermsBlock>
        <TermsBlock subtitle="第19条(分離可能性)">
          1.本規約のいずれかの条項またはその一部が無効と判断された場合であっても、当該判断は他の部分に影響を及ぼさず、本規約の残りの部分は、引き続き効力を有するものとします。当社および
          {APP_NAME}
          会員は、当該無効とされた条項または部分の趣旨に従い、これと同等の効果を確保できるように努めるとともに、修正された本規約に拘束されることに同意するものとします。
          <br />
          2.本規約のいずれかの条項またはその一部が、ある{APP_NAME}会員との関係で無効と判断された場合であっても、他の{APP_NAME}
          会員との関係における有効性等には影響を及ぼさないものとします。
        </TermsBlock>
        <TermsBlock subtitle="第20条(準拠法及び管轄裁判所)">
          本規約の解釈及び適用は、日本国法に準拠するものとします。また、本サービス及び本規約に関わるすべての紛争については、
          東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </TermsBlock>
      </Inner>
    </Wrapper>
  )
}
