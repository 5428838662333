import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import { ProductImage, Icon } from '../../atoms'
import { PublishedOrderedProduct, OrderedProductStatus, ProductType, OrderedProductLiveTalkStatus } from '../../../types/graphql'

import playMovieIcon from '../../../assets/images/icon/play_movie.png'
import liveTalkIcon from '../../../assets/images/icon/live_talk.png'

import moment from 'moment'

type Props = {
  className?: string
  purchasedProduct: PublishedOrderedProduct
  onClickPurchasedProduct: (productId: string, productType: ProductType) => void
}

const StyledOrderedProductListItem = styled.div`
  width: 47%;
  margin-bottom: 6.6vw;
  padding: 0;
  cursor: pointer;
  .product-info {
    position: relative;
    display: block;
    color: ${(props): string => props.theme.textColors.white};
    .product-image {
      position: relative;
      margin-bottom: 2.6vw;
      word-break: break-all;
      &.preparing {
        &:after {
          width: 100%;
          height: 100%;
          font-size: 5.603vw;
          font-weight: bold;
          border-radius: 4px;
          background: rgba(34, 34, 34, 0.4);
          color: ${(props): string => props.theme.textColors.primary};
          content: '作成中です';
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &.movie {
        &:after {
          width: 10.6vw;
          height: 10.6vw;
          font-size: 5.603vw;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background: url(${playMovieIcon}) center no-repeat;
          background-size: 10.6vw;
        }
      }
      &.live-talk {
        .live-talk-status {
          height: 4.8vw;
          line-height: 4.8vw;
          font-size: 2.6vw;
          padding: 0 2.1vw;
          position: absolute;
          top: 2vw;
          left: 2vw;
          z-index: 1;
          border-radius: 2.4vw;
          color: ${(props): string => props.theme.textColors.white};
          background-color: ${(props): string => props.theme.backgroundColors.primary};
          &.is-finished {
            background-color: ${(props): string => props.theme.backgroundColors.black};
          }
        }
        &:after {
          width: 16.5vw;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background: url(${liveTalkIcon}) center no-repeat;
          background-size: 16.5vw;
        }
        .live-talk-overlay {
          border-radius: 4px;
          background-color: rgba(34, 34, 34, 0.4);
          height: 100%;
          width: 100%;
          position: absolute;
          z-index: 0;
        }
      }
    }

    .info {
      width: 100%;
      padding: 2vw;
      position: absolute;
      border-radius: 0 0 4px 4px;
      background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.9) 100%);
      bottom: 0;

      .product-name {
        width: 100%;
        font-size: 3.735vw;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-bottom: 2vw;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }
      .paid-at {
        width: 100%;
        font-size: 2.4vw;
        display: inline-block;
        letter-spacing: 0.05em;
        line-height: 3.2vw;
        color: ${(props): string => props.theme.textColors.placeholder};
      }
    }
  }
  .creator-info {
    display: flex;
    .creator-icon {
      width: 6.4vw;
      height: 6.4vw;
      margin-right: 1.6vw;
      border-radius: 50%;
    }
    p {
      font-size: 3.2vw;
      line-height: 2em;
      font-weight: bold;
    }
  }
  ${mediaPc`
    width: 148px;
    margin: 0 26px 34px 0;
    &:nth-child(4n) {
      margin: 0 0 34px;
    }
    .product-info {
      position: relative;

    .product-image {
      width: 100%;
      height: auto;
      margin-bottom: 0;
      &.preparing {
        &:after {
            font-size: 16px;
        }
      }
      &.movie{
        &:after {
          width: 40px;
          height: 40px;
          background-size: 40px;
        }
      }
      &.live-talk{
        .live-talk-status {
          height: 18px;
          padding:1px 8px;
          border-radius: 9px;
          line-height: 18px;
          font-size: 10px;
          top: 6px;
          left: 6px;
        }
        &:after {
          width: 62px;
          height: 42px;
          background-size: 62px;
        }
      }
    }
    .info {
      padding: 20px 10px 7px;
      .product-name {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 0;
      }
      .paid-at {
        width: auto;
        font-size: 10px;
        line-height: 1.6;
      }
    }
  }
    .creator-info {
      padding-top: 10px;
      .creator-icon {
        width:24px;
        height:24px;
        margin:0;
      }
      p {
        font-size:12px;
        line-height: auto;
        padding-left:7px;
      }
    }
  `}
`

export const PurchasedProductListItem: React.FC<Props> = ({ className = '', purchasedProduct, onClickPurchasedProduct }) => {
  const isPreparing = (): boolean => {
    return purchasedProduct.status === OrderedProductStatus.Undelivered || purchasedProduct.status === OrderedProductStatus.Processing
  }

  const isLiveTalkFinished = purchasedProduct.live_talk_status === OrderedProductLiveTalkStatus.Talked

  return (
    <StyledOrderedProductListItem
      className={className}
      onClick={() => onClickPurchasedProduct(purchasedProduct.id, purchasedProduct.product.product_type)}
    >
      <div className="product-info">
        <div
          className={
            purchasedProduct.product.product_type === ProductType.LiveTalk
              ? `product-image live-talk`
              : isPreparing()
              ? 'preparing product-image'
              : `product-image ${purchasedProduct.product.product_type.toLowerCase()}`
          }
        >
          {purchasedProduct.product.is_live_talk && (
            <>
              <div className={isLiveTalkFinished ? 'live-talk-status is-finished' : 'live-talk-status'}>
                {isLiveTalkFinished
                  ? '終了しました'
                  : `${moment(purchasedProduct.product.live_talk?.started_at).format('YYYY-MM-DD HH:mm')}~`}
              </div>
              {isLiveTalkFinished && <div className="live-talk-overlay" />}
            </>
          )}
          <ProductImage src={purchasedProduct.product.thumbnail_image} />
        </div>
        <div className="info">
          <div className="product-name">{purchasedProduct.product.name}</div>
          <p className="paid-at">支払日: {moment(purchasedProduct.order.paid_at).format('YYYY-MM-DD HH:mm')}</p>
        </div>
      </div>
      <div className="creator-info">
        <Icon src={purchasedProduct.creator.icon_url} alt={purchasedProduct.creator.name} className="creator-icon" />
        <p>{purchasedProduct.creator.name}</p>
      </div>
    </StyledOrderedProductListItem>
  )
}
