import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { UserFavoriteNewProductsTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { PublishedProduct, usePagedUserFavoritedCreatorLatestProductsLazyQuery } from '../../../types/graphql'
import { useSetUserProfile } from '../../../lib/SetMyProfile'
import { useValidateUserToken } from '../../../lib/ValidateUserToken'

type Props = {} & RouteComponentProps

export const UserFavoriteNewProducts: React.FC<Props> = (props) => {
  const [products, setProducts] = useState<PublishedProduct[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const [pagedUserFavoritedCreatorLatestProductsLazyQuery, { loading }] = usePagedUserFavoritedCreatorLatestProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProducts(data.PagedUserFavoritedCreatorLatestProducts?.data ?? [])
      setLastPage(data.PagedUserFavoritedCreatorLatestProducts?.paginatorInfo.lastPage ?? 1)
    },
    onError: (e) => {
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  useValidateUserToken()
  useSetUserProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }

    pagedUserFavoritedCreatorLatestProductsLazyQuery({
      variables: {
        first: 15,
        page: currentPage,
      },
    })
  }, [currentPage, pagedUserFavoritedCreatorLatestProductsLazyQuery, props.location.search])

  return (
    <>
      <Meta title="お気に入り新着一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <UserFavoriteNewProductsTpl products={products} lastPage={lastPage} currentPage={currentPage} />
    </>
  )
}
