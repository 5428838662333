import React from 'react'
import styled from 'styled-components'
import { Label, Selectbox } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  label?: string
  name?: string
  id?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string | number
  options?: { value: string | number; label: string }[]
}

const Wrapper = styled.div`
  .label {
    margin-bottom: 2.1vw;
  }

  ${mediaPc`
    .label {
      margin-bottom: 5px;
    }
  `}
`

export const SelectboxWithLabel: React.FC<Props> = ({
  className = '',
  label = '',
  name = '',
  id = '',
  value = '',
  options = [],
  onChange = () => {},
}) => {
  return (
    <Wrapper className={className}>
      <Label className="label">{label}</Label>
      <Selectbox name={name} id={id} value={value} options={options} onChange={onChange} />
    </Wrapper>
  )
}
