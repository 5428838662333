import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  name?: string
  value?: string
  maxLength?: number
  placeholder?: string
  error?: boolean
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const StyledTextarea = styled.textarea<Props>`
  width: 100%;
  height: 29.3vw;
  padding: 3.2vw;
  border-radius: 4px;
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  color: ${(props): string => props.theme.textColors.black};
  font-size: 3.2vw;
  letter-spacing: 0.05em;
  line-height: 1.6;
  border: 1px solid ${(props): string => (props.error ? props.theme.textColors.red : 'transparent')};

  &:focus {
    outline: 0;
    border: 1px solid ${(props): string => (props.error ? props.theme.textColors.red : props.theme.borderColors.primary)};
  }

  ${mediaPc`
    height: auto;
    min-height: 150px;
    padding: 15px;
    font-size: 14px;
    line-height: 1.7;
  `}
`

export const Textarea: React.FC<Props> = ({
  className = '',
  name = '',
  value = '',
  maxLength,
  placeholder = '',
  error = false,
  onChange,
}) => {
  return (
    <StyledTextarea
      className={className}
      name={name}
      defaultValue={value}
      placeholder={placeholder}
      onChange={onChange}
      maxLength={maxLength}
      error={error}
    />
  )
}
