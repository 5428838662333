import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { UserRegistrationTpl } from '../../../components/templates'
import { FlashMessageType, RegisterUserInput } from '../../../types/myTypes'
import { useUserRegisterAccountMutation } from '../../../types/graphql'
import { Meta } from '../../../Meta'

export const UserRegistration: React.FC = () => {
  const userInitialState: RegisterUserInput = {
    name: '',
    email: '',
    password: '',
    password_confirm: '',
  }
  const [user, setUser] = useState<RegisterUserInput>(userInitialState)
  const [loading, setLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const history = useHistory()

  const [userRegisterAccountMutation] = useUserRegisterAccountMutation({
    onCompleted: (data) => {
      setLoading(false)
      if (data.UserRegisterAccount) {
        history.push({ pathname: '/registration/complete', state: { id: data.UserRegisterAccount.id, email: user.email } })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const onChangeState = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const name = event.target.name
    setUser(user ? { ...user, [name]: value } : user)
  }

  const onClickUserRegister = (): void => {
    setLoading(true)
    userRegisterAccountMutation({
      variables: {
        input: {
          name: user.name,
          email: user.email,
          password: user.password,
          twitter_username: user.twitter_username,
        },
      },
    })
  }

  return (
    <>
      <Meta title="新規登録" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <UserRegistrationTpl user={user} onChangeState={onChangeState} onClickUserRegister={onClickUserRegister} />
    </>
  )
}
