import React from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  selectedDuration: 'weekly' | 'monthly'
  onChangeDuration: (durationType: 'weekly' | 'monthly') => void
}

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  border: 1px solid ${(props): string => props.theme.borderColors.black};
  border-radius: 4px;
  overflow: hidden;

  button {
    width: 25vw;
    font-size: 3.2vw;
    line-height: 2.4;
    border: none;
    border-right: 1px solid ${(props): string => props.theme.borderColors.black};
    border-radius: 0;
    transition: 0.3s all;

    &:last-child {
      border-right: none;
    }

    &:hover,
    &.selected {
      background: ${(props): string => props.theme.backgroundColors.primary};
    }
  }

  ${mediaPc`
    width: 119px;
    height: 30px;

    button {
      width: 60px;
      font-size: 12px;
    }
  `}
`

export const CreatorRankingDurationTab: React.FC<Props> = ({ className, selectedDuration, onChangeDuration }) => {
  return (
    <Wrapper className={className}>
      <Button
        buttonType="white"
        onClick={() => {
          onChangeDuration('weekly')
        }}
        className={selectedDuration === 'weekly' ? 'selected' : ''}
      >
        Weekly
      </Button>
      <Button
        buttonType="white"
        onClick={() => {
          onChangeDuration('monthly')
        }}
        className={selectedDuration === 'monthly' ? 'selected' : ''}
      >
        Monthly
      </Button>
    </Wrapper>
  )
}
