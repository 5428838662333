import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { LoadingCircle, FlashMessage } from '../../../components/atoms'
import { CreatorTwoColumnLayout, CreatorSalesPerOrderTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { SalesBreakdown, usePagedCreatorSalesPerMonthBreakdownLazyQuery } from '../../../types/graphql'
import { FlashMessageType } from '../../../types/myTypes'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'

type Props = {} & RouteComponentProps<{ month: string }>

export const CreatorSalesPerOrder: React.FC<Props> = (props) => {
  const [sales, setSales] = useState<SalesBreakdown[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const [pagedCreatorSalesPerMonthBreakdownLazyQuery] = usePagedCreatorSalesPerMonthBreakdownLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setSales(data.PagedCreatorSalesPerMonthBreakdown?.data ?? [])
        setLastPage(data.PagedCreatorSalesPerMonthBreakdown?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  useValidateCreatorToken()
  useSetCreatorProfile()

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search)
    const pageParam = Number(searchParams.get('page'))

    setCurrentPage(pageParam || 1)

    pagedCreatorSalesPerMonthBreakdownLazyQuery({
      variables: {
        input: {
          year_month: props.match.params.month,
        },
        first: 40,
        page: pageParam,
      },
    })
  }, [pagedCreatorSalesPerMonthBreakdownLazyQuery, props.location.search, props.match.params.month])

  return (
    <>
      <Meta title="売上内訳" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="sales">
        <CreatorSalesPerOrderTpl sales={sales} lastPage={lastPage} currentPage={currentPage} />
      </CreatorTwoColumnLayout>
    </>
  )
}
