import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import { BusinessHomeSlider, CreatorSearch, HomeCreatorRanking, HomeProductsOnSale } from '../../organisms'
import { CreatorRanking, PublishedProduct } from '../../../types/graphql'

type Props = {
  searchWord: string
  creatorRanking: CreatorRanking[]
  productsOnSale: PublishedProduct[]
  selectedDuration: 'weekly' | 'monthly'
  onClickSearchButton: () => void
  onChangeSearchWord: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeDuration: (durationType: 'weekly' | 'monthly') => void
}

const Wrapper = styled.div`
  .creator-ranking,
  .products-on-sale {
    padding: 10vw 4.8vw 6vw;
  }

  ${mediaPc`
    width: 100%;
    overflow: hidden;
    .creator-ranking, .products-on-sale {
      width: 1040px;
      margin: 0 auto 95px;
    }
    .creator-ranking {
      padding: 83px 20px 0;
    }
    .products-on-sale{
      padding: 0 20px;
    }
  `}
`

export const BusinessHomeTpl: React.FC<Props> = ({
  searchWord,
  creatorRanking,
  productsOnSale,
  selectedDuration,
  onChangeSearchWord,
  onClickSearchButton,
  onChangeDuration,
}) => {
  return (
    <Wrapper>
      <BusinessHomeSlider />
      <HomeCreatorRanking
        selectedDuration={selectedDuration}
        creatorRanking={creatorRanking}
        onChangeDuration={onChangeDuration}
        className="creator-ranking"
      />
      <HomeProductsOnSale className="products-on-sale" productsOnSale={productsOnSale} />
      <CreatorSearch searchWord={searchWord} onChange={onChangeSearchWord} onClick={onClickSearchButton} className="sp" />
    </Wrapper>
  )
}
