import React, { useState, useEffect, useContext, useCallback } from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { LoadingCircle, FlashMessage } from '../../../components/atoms'
import { Meta } from '../../../Meta'
import { UserMypageTopTpl, PurchasedProductModalTpl, UserTwoColumnLayout } from '../../../components/templates'
import {
  PublishedOrderedProduct,
  usePagedUserPurchasedProductsLazyQuery,
  useUserPurchasedProductLazyQuery,
  useUpdateUserEmailByTokenMutation,
  ProductType,
} from '../../../types/graphql'
import { FlashMessageType } from '../../../types/myTypes'
import { useSetUserProfile } from '../../../lib/SetMyProfile'
import { useValidateUserToken } from '../../../lib/ValidateUserToken'
import { UserProfileContext } from '../../../context'

type Props = {} & RouteComponentProps<{ id?: string }>

export const UserMypageTop: React.FC<Props> = (props) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [purchasedProducts, setPurchasedProducts] = useState<PublishedOrderedProduct[]>([])
  const [productDetailModalVisible, setProductDetailModalVisible] = useState<boolean>(false)
  const [selectedPurchasedProduct, setSelectedPurchasedProduct] = useState<PublishedOrderedProduct>()
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const { updateUser } = useContext(UserProfileContext)

  const history = useHistory()
  const location = useLocation<{ from?: string; editComplete?: boolean }>()
  const { search, state } = location

  const [pagedUserPurchasedProductsLazyQuery] = usePagedUserPurchasedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setPurchasedProducts(data.PagedUserPurchasedProducts?.data ?? [])
        setLastPage(data.PagedUserPurchasedProducts?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [userPurchasedProductLazyQuery] = useUserPurchasedProductLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setSelectedPurchasedProduct(data.UserPurchasedProduct)
        setLoading(false)
        setProductDetailModalVisible(true)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [updateUserEmailByTokenMutation] = useUpdateUserEmailByTokenMutation({
    onCompleted: (data) => {
      if (data.UpdateUserEmailByToken) {
        updateUser(data.UpdateUserEmailByToken)
      }
      setLoading(false)
      setFlashMessage({ type: 'success', message: 'メールアドレスを更新しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickPurchasedProduct = useCallback(
    (productId: string, productType?: ProductType): void => {
      if (productType === ProductType.LiveTalk) {
        history.push(`/mypage/product/live-talk/${productId}`)
      }
      setLoading(true)
      setFlashMessage(null)
      userPurchasedProductLazyQuery({
        variables: {
          ordered_product_id: productId,
        },
      })
    },
    [history, userPurchasedProductLazyQuery],
  )

  const closeModal = (): void => {
    setProductDetailModalVisible(false)
  }

  const onClickDownloadButton = (): void => {
    setProductDetailModalVisible(false)
    const downLoadLink = document.createElement('a')
    downLoadLink.download = ''
    downLoadLink.href = selectedPurchasedProduct?.latest_delivered_product?.temporary_download_url ?? ''
    downLoadLink.click()
  }

  useValidateUserToken()
  useSetUserProfile()

  useEffect(() => {
    const paramsCurrentPage = new URLSearchParams(props.location.search).get('page')
    if (paramsCurrentPage) {
      setCurrentPage(Number(paramsCurrentPage))
    }

    pagedUserPurchasedProductsLazyQuery({
      variables: {
        first: 12,
        page: Number(paramsCurrentPage),
      },
    })
  }, [pagedUserPurchasedProductsLazyQuery, props.location.search])

  useEffect(() => {
    if (state) {
      // 商品購入成功からの遷移
      if (state.from === 'purchaseSuccess') {
        setFlashMessage({ type: 'success', message: '決済が完了しました。ご購入履歴の反映まで少し時間がかかる場合がございます。' })
      }

      // アカウント編集でアドレス以外を編集した時のリダイレクト
      if (state.editComplete === true) {
        setFlashMessage({ type: 'success', message: '更新しました' })
      }

      // アカウント編集でメールアドレスを変更した時のメールからの遷移
      if (state.from === 'updateEmail') {
        const params = new URLSearchParams(search)
        const id = params.get('id') || null
        const email = params.get('email') || null
        const expires = params.get('expires') || null
        const signature = params.get('signature') || null
        if (id && email && expires && signature) {
          setLoading(true)
          updateUserEmailByTokenMutation({
            variables: {
              input: {
                id: id,
                email: email,
                expires: Number(expires),
                signature: signature,
              },
            },
          })
        } else {
          Sentry.captureException('ユーザーのメールアドレスを更新できませんでした')
          setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
        }
      }
    }
  }, [search, state, updateUserEmailByTokenMutation])

  // location.stateがセットされたままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (state) {
      history.replace({ ...location, state: undefined, search: undefined })
    }
  }, [history, location, state])

  // 納品画像表示用のリンクから遷移した際に利用
  useEffect(() => {
    if (props.match.params.id) {
      onClickPurchasedProduct(props.match.params.id)
    }
  }, [history, location, onClickPurchasedProduct, props.match.params.id, state])

  return (
    <>
      <Meta title="マイページTOP" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {productDetailModalVisible && selectedPurchasedProduct && (
        <PurchasedProductModalTpl
          purchasedProduct={selectedPurchasedProduct}
          onClickDownloadButton={onClickDownloadButton}
          closeModal={closeModal}
        />
      )}
      <UserTwoColumnLayout currentTab="history">
        <UserMypageTopTpl
          purchasedProducts={purchasedProducts}
          currentPage={currentPage}
          lastPage={lastPage}
          onClickPurchasedProduct={onClickPurchasedProduct}
        />
      </UserTwoColumnLayout>
    </>
  )
}
