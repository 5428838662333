import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageSubtitle } from '../../../atoms'
import { CreatorEdit } from '../../../organisms'
import { Creator } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  creator: Creator
  iconUrl: string
  onClickUpdateButton?: () => void
  onChangeState: (name: string, value: string) => void
  onIconLoad: (result: string, file: File) => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }
  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title {
      margin-bottom: 35px;
    }
  `}
`

export const CreatorEditTpl: React.FC<Props> = ({ creator, iconUrl, onClickUpdateButton, onChangeState, onIconLoad }) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle="アカウント情報編集" />
      <CreatorEdit
        creator={creator}
        iconUrl={iconUrl}
        onClickUpdateButton={onClickUpdateButton}
        onChangeState={onChangeState}
        onIconLoad={onIconLoad}
        isBusinessUser={false}
      />
    </Wrapper>
  )
}
