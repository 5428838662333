import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { AccountInfo } from '../../../organisms'
import { TextButton, Button } from '../../../atoms'
import { Creator, CreatorAccountStatus } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  className?: string
  creator: Creator
  onClickResendRegistrationMail?: () => void
}

const Wrapper = styled.div`
  .send-mail {
    margin-bottom: 4.5vw;
  }

  ${mediaPc`
    .send-mail {
      width: 320px;
      margin: 0 auto;
      display: block;
      line-height: 36px;
    }
  `}
`
const Profile = styled.div`
  border-radius: 4px;
  background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
  .creator_link {
    padding: 0 5vw 4vw;
    .link-text {
      display: flex;
      margin-left: auto;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.primary};
      font-weight: 700;
      font-size: 3.46vw;
      line-height: 4vw;
    }
  }
  ${mediaPc`
    .creator_link {
      padding: 0;
      .link-text {
        font-size: 14px;
        line-height: 1.6;
      }
    }
  `}
`

export const CreatorDetail: React.FC<Props> = ({ className, creator, onClickResendRegistrationMail }) => {
  const history = useHistory()

  const goCreatorPage = (): void => {
    history.push(`/business/creator/${creator.id}/user`)
  }
  const isCreatorActive = creator.status === CreatorAccountStatus.Active

  return (
    <Wrapper className={className}>
      {creator.status === CreatorAccountStatus.Request && (
        <Button className="send-mail" buttonType="red" onClick={onClickResendRegistrationMail}>
          アカウント登録メールを再送する
        </Button>
      )}
      <Profile>
        <AccountInfo creator={creator} />
        {isCreatorActive && (
          <div className="creator_link">
            <TextButton className="link-text" onClick={goCreatorPage}>
              ＞ クリエイターページを確認
            </TextButton>
          </div>
        )}
      </Profile>
    </Wrapper>
  )
}
