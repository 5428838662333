import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { ProfileItem, SnsList } from '../../molecules'
import { Icon } from '../../atoms'
import { Creator } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

import defaultIcon from '../../../assets/images/icon/man_icon_default.png'

type Props = {
  creator: Creator
}

const Profile = styled.div`
  width: 100%;
  padding: 5vw 5vw 0;
  border-radius: 4px;
  background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

  .creator-photo {
    width: 30vw;
    height: 30vw;
    margin: 0 auto 8vw;
    .photo {
      width: 100%;
      object-fit: cover;
      height: 30vw;
      border-radius: 50%;
      &.default {
        border-radius: 0;
      }
    }
  }
  dl {
    .profile {
      dd {
        padding: 5px 0;
        span {
          line-height: 1.6;
          display: block;
          font-weight: bold;
        }
      }
    }
  }

  ${mediaPc`
    width: 100%;
    padding: 35px 0 0;

    .creator-photo {
      width: 100px;
      height: 100px;
      margin: 0 auto 35px;

      .photo {
        height: 100px;
      }
    }
    dl {
      dd {
        a {
          transition: 0.3s all;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .profile {
        dd {
          span {
            font-weight: 400;
          }
        }
      }
      .twitter > dd::before {
        margin-right: 5px;
        content: '@';
        line-height: 2.4em;
        font-size: 14px;
        color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.placeholder};
      }
      .last {
        dd {
          margin-bottom: 10px;
        }
      }
    }
  `}
`

export const AccountInfo: React.FC<Props> = ({ creator }) => {
  return (
    <Profile>
      <div className="creator-photo">
        <Icon
          className={creator.icon_url !== '' ? 'photo' : 'photo default'}
          src={creator.icon_url !== '' ? creator.icon_url : defaultIcon}
          alt={creator.name}
        />
      </div>
      <dl>
        <ProfileItem label="クリエイター名">{creator.name !== '' ? creator.name : '未登録'}</ProfileItem>
        <ProfileItem label="メールアドレス">{creator.email}</ProfileItem>
        <ProfileItem className="profile" label="プロフィールテキスト">
          {creator.profile !== '' ? <span>{creator.profile}</span> : '未登録'}
        </ProfileItem>
        <ProfileItem label="所属">{creator.affiliation?.name ?? '所属なし'}</ProfileItem>
        <ProfileItem className={creator.twitter_username !== '' ? 'twitter pc' : 'pc'} label="twitterユーザー名">
          {creator.twitter_username !== '' ? (
            <a href={`https://twitter.com/${creator.twitter_username}`} target="_blank" rel="noopener noreferrer">
              {creator.twitter_username}
            </a>
          ) : (
            '未登録'
          )}
        </ProfileItem>
        <ProfileItem className="pc" label="instagram ユーザーネーム">
          {creator.instagram_username !== '' ? (
            <a href={`https://www.instagram.com/${creator.instagram_username}`} target="_blank" rel="noopener noreferrer">
              {creator.instagram_username}
            </a>
          ) : (
            '未登録'
          )}
        </ProfileItem>
        <ProfileItem className="pc" label="Youtube チャンネル URL">
          {creator.youtube_channel_url !== '' ? (
            <a href={`https://www.instagram.com/${creator.youtube_channel_url}`} target="_blank" rel="noopener noreferrer">
              {creator.youtube_channel_url}
            </a>
          ) : (
            '未登録'
          )}
        </ProfileItem>
        <ProfileItem className="pc last" label="Kindle販売ページ URL">
          {creator.kindle_author_url !== '' ? (
            <a href={`https://www.amazon.co.jp/${creator.kindle_author_url}`} target="_blank" rel="noopener noreferrer">
              {creator.kindle_author_url}
            </a>
          ) : (
            '未登録'
          )}
        </ProfileItem>
        <ProfileItem label="SNSリンク" className="sns sp">
          <SnsList creator={creator} />
        </ProfileItem>
      </dl>
    </Profile>
  )
}
