import React from 'react'
import styled from 'styled-components'
import { CreatorIconWithName } from '../../molecules'
import { Creator } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

type Props = {
  className?: string
  creators: Creator[]
  isUserLoggedIn?: boolean
  onClickFavorite?: (creatorId: string, isFavorite: boolean) => void
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .creator-icon-with-name {
    margin-bottom: 6.6vw;
  }

  ${mediaPc`
    justify-content: flex-start;

    .creator-icon-with-name {
      margin-right: 67px;
      &:nth-child(5n) {
        margin-right: 0
      }
    }
  `}
`

export const CreatorSearchResult: React.FC<Props> = ({ className, creators, isUserLoggedIn, onClickFavorite }) => {
  const getCreatorTopUrl = (creator: Creator): string => {
    if (userType() === 'business') {
      return `/business/creator/${creator.id}/user`
    } else if (userType() === 'creator') {
      return `/creator/${creator.id}/user`
    } else if (userType() === 'user') {
      return `/creators/${creator.id}`
    }
    return ''
  }

  return (
    <Wrapper className={className}>
      {creators.map((creator) => (
        <CreatorIconWithName
          key={creator.id}
          link={getCreatorTopUrl(creator)}
          iconSrc={creator.icon_url}
          iconAlt={creator.name}
          creatorName={creator.name}
          creatorId={creator.id}
          isFavorite={creator.is_favorite}
          displayFavorite={userType() === 'user' && !!isUserLoggedIn}
          onClickFavorite={onClickFavorite}
          className="creator-icon-with-name"
        />
      ))}
    </Wrapper>
  )
}
