import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../atoms'
import { ProductsOnSaleTitle } from '../../molecules'
import { ProductListOnSale } from '..'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'
import { PublishedProduct } from '../../../types/graphql'

type Props = {
  className?: string
  productsOnSale: PublishedProduct[]
}

const Wrapper = styled.div`
  .head {
    margin-bottom: 4vw;
  }
  .more-button {
    width: 50vw;
    margin: 0 auto;
    display: block;
    font-size: 3.2vw;
    line-height: 2.8;
  }
  .no-data {
    margin: 0 auto 6.6vw;
    text-align: center;
  }
  ${mediaPc`

    .head {
      margin-bottom: 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .list {
      & > div {
        margin: 0 64px 48px 0;
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
      & > div:last-child {
        margin-right: auto;
      }
    }

    .no-data {
      margin: 0 auto 45px;
    }

    .more-button {
      width: 210px;
      height: 46px;
      font-size: 12px;
      line-height: 46px;
    }
  `}
`

export const HomeProductsOnSale: React.FC<Props> = ({ className, productsOnSale }) => {
  const history = useHistory()
  const goToProductsOnSale = (): void => {
    const type = userType()
    switch (type) {
      case 'user':
        return history.push('/products-on-sale')
      case 'creator':
        return history.push('/creator/products-on-sale')
      case 'business':
        return history.push('/business/products-on-sale')
      default:
        return
    }
  }

  return (
    <Wrapper className={className}>
      <div className="head">
        <ProductsOnSaleTitle className="products-on-sale-title" />
      </div>
      {productsOnSale.length > 0 ? (
        <ProductListOnSale className="list" products={productsOnSale} />
      ) : (
        <div className="no-data">販売中の商品はありません</div>
      )}
      <Button buttonType="white" onClick={goToProductsOnSale} className="more-button">
        全ての商品はこちら
      </Button>
    </Wrapper>
  )
}
