import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { CONTACT_URL } from '../../../../const'

import { mediaPc } from '../../../../lib/MediaQuery'

const Wrapper = styled.div`
  .title {
    margin-bottom: 2.7vw;

    h2 {
      width: 100%;
      font-size: 3.2vw;
      font-weight: bold;
      color: #222;
      letter-spacing: 0.05em;
    }
  }
  p {
    width: 100%;
    margin-bottom: 4.8vw;
    font-size: 3.2vw;
    text-align: center;
    color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    letter-spacing: 0.05em;
  }
  a {
    width: 100%;
    height: auto;
    display: block;
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    border-radius: 4px;
    font-size: 3.733vw;
    line-height: 3.3em;
    font-weight: bold;
    color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.white};
    text-align: center;
  }
  ${mediaPc`
    .title {
      margin-bottom: 35px;
      h2 {
        font-size: 16px;
      }
    }
    p {
      margin-bottom: 40px;
      font-size: 16px;
    }
    a {
      font-size: 14px;
      line-height: 40px;
      transition: 0.3s all;

      &:hover {
        opacity: 0.8;
      }
    }
  `}
}
`
export const BusinessLoginContact: React.FC = () => {
  return (
    <Wrapper>
      <div className="contact">
        <div className="title">
          <h2>アカウントをお持ちでない方</h2>
        </div>
        <p>
          アカウントをお持ちでない方、
          <br />
          PINTO 4 YOUにご興味をお持ちの方は
          <br />
          こちらからお問い合わせください
        </p>
        <a href={CONTACT_URL} target="_black">
          お問い合わせ
        </a>
      </div>
    </Wrapper>
  )
}
