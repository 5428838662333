import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { UserRegistrationForm } from '../../../organisms'
import { RegisterUserInput } from '../../../../types/myTypes'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  user: RegisterUserInput
  onClickUserRegister: () => void
  onChangeState: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props): string => props.theme.backgroundColors.gray};
  padding: 0 7.2vw 20vw;
  ${mediaPc`
    background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 0px 0px 190px;
  `}
`

export const UserRegistrationTpl: React.FC<Props> = ({ user, onClickUserRegister, onChangeState }) => {
  return (
    <Wrapper>
      <UserRegistrationForm user={user} onChangeState={onChangeState} onClickUserRegister={onClickUserRegister} />
    </Wrapper>
  )
}
