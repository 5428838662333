import React, { useState } from 'react'
import * as Theme from './theme'
import { CookiesProvider } from 'react-cookie'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { config } from './config'

import { mediaPc } from './lib/MediaQuery'

import { UserRoutes } from './routes/user'
import { CreatorRoutes } from './routes/creator'
import { BusinessRoutes } from './routes/business'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const baseStyle = require('./assets/style/base.css')
// グローバルスタイル設定
const GlobalStyle = createGlobalStyle`
  ${baseStyle}
  .pc {
    display: none;
    ${mediaPc`
      display: block;
    `}
  }
  .sp {
    display: block;
    ${mediaPc`
      display: none;
    `}
  }

  #root {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    overflow: scroll;
  }

  main {
    padding-top: 60px;
    flex: 1;
    display: flex;
    flex-flow: column;

    & > div {
      flex: 1;
    }

    ${mediaPc`
      min-width: 1040px;
      padding-top: 70px;
    `}
  }
`

export const App: React.FC = () => {
  const [headerToken, setHeaderToken] = useState<string>('')
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: config.apiEndpoint,
      headers: { authorization: headerToken ? `Bearer ${headerToken}` : '' },
    }),
  })

  const storeHeaderToken = (token: string): void => {
    if (token !== headerToken) {
      setHeaderToken(token)
    }
  }

  return (
    <CookiesProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={Theme.theme}>
          <GlobalStyle theme={Theme.theme} />
          {/* 一般ユーザー */}
          {document.domain === config.userFrontDomain && <UserRoutes storeHeaderToken={storeHeaderToken} />}
          {/* クリエイター */}
          {document.domain === config.creatorFrontDomain && <CreatorRoutes storeHeaderToken={storeHeaderToken} />}
          {/* ビジネスユーザー */}
          {document.domain === config.businessFrontDomain && <BusinessRoutes storeHeaderToken={storeHeaderToken} />}
        </ThemeProvider>
      </ApolloProvider>
    </CookiesProvider>
  )
}
export default App
