import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorDetailTpl, BusinessTwoColumnLayout, DialogTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  CreatorAccountStatus,
  SalesPerMonth,
  OrderedProduct,
  Product,
  useResendCreatorAccountRegistrationMutation,
  useDeleteCreatorAccountMutation,
  useBusinessUndeliveredOrderedProductsLazyQuery,
  useBusinessCreatorMypageLazyQuery,
  useFinishProductMutation,
  useDisableProductMutation,
  useDisplayProductMutation,
} from '../../../types/graphql'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { useSetMyProfile } from '../../../lib/SetMyProfile'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessCreatorDetail: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [sales, setSales] = useState<SalesPerMonth[]>([])
  const [disabledProducts, setDisabledProduct] = useState<Product[]>([])
  const [publishedProducts, setPublishedProduct] = useState<Product[]>([])
  const [reservedProducts, setReservedProduct] = useState<Product[]>([])
  const [selectedProductId, setSelectedProductId] = useState<string>('')
  const [orders, setOrders] = useState<OrderedProduct[]>([])

  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [editComplete, setEditComplete] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [deleteCreatorDialogVisible, setDeleteCreatorDialogVisible] = useState<boolean>(false)
  const [toEndDialogVisible, setToEndDialogVisible] = useState<boolean>(false)

  const location = useLocation<{ editComplete: boolean }>()
  const history = useHistory()

  const [resendCreatorAccountRegistration] = useResendCreatorAccountRegistrationMutation({
    onCompleted: () => {
      setLoading(false)
      setFlashMessage({ type: 'success', message: 'アカウント登録メールを再送信しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'アカウント登録メールを再送信できませんでした' })
      }
    },
  })

  const [deleteCreatorAccountMutation] = useDeleteCreatorAccountMutation({
    onCompleted: () => {
      history.push({ pathname: '/business/creator', state: { deleteCreator: true } })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'クリエイターを削除できませんでした' })
      }
    },
  })

  const [businessUndeliveredOrderedProductsLazyQuery] = useBusinessUndeliveredOrderedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setOrders(data.BusinessUndeliveredOrderedProducts ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [businessCreatorMypageLazyQuery] = useBusinessCreatorMypageLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setCreator(data.BusinessCreatorMypage?.creator ? data.BusinessCreatorMypage.creator : creator)
        setSales(data.BusinessCreatorMypage?.sales ? [data.BusinessCreatorMypage.sales] : sales)
        setPublishedProduct(
          data.BusinessCreatorMypage.published_products ? (data.BusinessCreatorMypage.published_products as Product[]) : [],
        )
        setDisabledProduct(
          data.BusinessCreatorMypage?.disabled_products ? (data.BusinessCreatorMypage?.disabled_products as Product[]) : [],
        )
        setReservedProduct(
          data.BusinessCreatorMypage?.reserved_products ? (data.BusinessCreatorMypage?.reserved_products as Product[]) : [],
        )
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [disableProductMutation] = useDisableProductMutation({
    onCompleted: () => {
      businessCreatorMypageLazyQuery({
        variables: {
          id: props.match.params.id,
        },
      })
      setFlashMessage({ type: 'success', message: '選択した商品を非表示にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const [finishProductMutation] = useFinishProductMutation({
    onCompleted: () => {
      businessCreatorMypageLazyQuery({
        variables: {
          id: props.match.params.id,
        },
      })
      setFlashMessage({ type: 'success', message: '選択した商品を販売終了にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const [displayProductMutation] = useDisplayProductMutation({
    onCompleted: () => {
      businessCreatorMypageLazyQuery({
        variables: {
          id: props.match.params.id,
        },
      })
      setFlashMessage({ type: 'success', message: '選択した商品を再表示しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickResendRegistrationMail = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await resendCreatorAccountRegistration({
      variables: {
        creator_id: props.match.params.id,
      },
    })
  }

  const onClickDeleteCreator = (): void => {
    setDeleteCreatorDialogVisible(true)
  }

  const onClickDeleteCreatorDialogOk = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await deleteCreatorAccountMutation({
      variables: {
        creator_id: props.match.params.id,
      },
    })
  }

  const onClickDeleteCreatorDialogCancel = (): void => {
    setDeleteCreatorDialogVisible(false)
  }

  const onClickToDisabled = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    disableProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }
  const onClickToEnd = (productId: string): void => {
    setToEndDialogVisible(true)
    setSelectedProductId(productId)
  }
  const onClickToEndDialogOk = (): void => {
    setToEndDialogVisible(false)
    setLoading(true)
    setFlashMessage(null)
    finishProductMutation({
      variables: {
        product_id: selectedProductId,
      },
    })
  }
  const onClickToEndDialogCancel = (): void => {
    setToEndDialogVisible(false)
  }
  const onClickToDisplay = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    displayProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    if (location.state) {
      setEditComplete(location.state.editComplete)
    }
  }, [location.state])

  // location.state.editCompete = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (location.state && location.state.editComplete === true) {
      history.replace({ ...location, state: undefined })
    }
  }, [history, location])

  useEffect(() => {
    businessUndeliveredOrderedProductsLazyQuery({
      variables: {
        creator_id: props.match.params.id,
      },
    })
    businessCreatorMypageLazyQuery({
      variables: {
        id: props.match.params.id,
      },
    })
  }, [businessCreatorMypageLazyQuery, businessUndeliveredOrderedProductsLazyQuery, props.match.params.id])

  return (
    <>
      <Meta
        title={
          creator?.status === CreatorAccountStatus.Active
            ? `${creator?.name}さん 情報詳細`
            : creator?.status === CreatorAccountStatus.Request
            ? '仮登録クリエイター 情報詳細'
            : ''
        }
      />
      {toEndDialogVisible && (
        <DialogTpl title="この商品を販売終了にしますか？" onClickOk={onClickToEndDialogOk} onClickCancel={onClickToEndDialogCancel}>
          販売終了にすると、この商品を再度販売できなくなります。再販する場合、新たに商品登録をお願い致します。
        </DialogTpl>
      )}
      {deleteCreatorDialogVisible && (
        <DialogTpl title="アカウント削除" onClickOk={onClickDeleteCreatorDialogOk} onClickCancel={onClickDeleteCreatorDialogCancel}>
          このアカウントを削除しますか？
        </DialogTpl>
      )}
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {editComplete && <FlashMessage flashMessage={{ message: '更新が完了しました', type: 'success' }} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <>
          {creator && (
            <CreatorDetailTpl
              creator={creator}
              sales={sales}
              publishedProducts={publishedProducts ?? []}
              reservedProducts={reservedProducts ?? []}
              disabledProducts={disabledProducts ?? []}
              orders={orders ?? []}
              onClickResendRegistrationMail={onClickResendRegistrationMail}
              onClickDeleteCreator={onClickDeleteCreator}
              onClickToDisabled={onClickToDisabled}
              onClickToEnd={onClickToEnd}
              onClickToDisplay={onClickToDisplay}
            />
          )}
        </>
      </BusinessTwoColumnLayout>
    </>
  )
}
