import React from 'react'
import styled from 'styled-components'
import { CreatorIconWithName } from '../../../molecules'
import { Creator } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  className?: string
  creators: Creator[]
  onClickFavorite: (creatorId: string, isFavorite: boolean) => void
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .creator-icon-with-name {
    margin-bottom: 6.6vw;
  }

  ${mediaPc`
    justify-content: flex-start;

    .creator-icon-with-name {
      margin: 0 67px 66px 0;

      &:nth-child(3n) {
        margin-right: 0
      }
    }
  `}
`

export const FavoriteCreators: React.FC<Props> = ({ className, creators, onClickFavorite }) => {
  return (
    <Wrapper className={className}>
      {creators.map((creator) => (
        <CreatorIconWithName
          key={creator.id}
          link={`/creators/${creator.id}`}
          iconSrc={creator.icon_url}
          iconAlt={creator.name}
          creatorName={creator.name}
          displayFavorite
          isFavorite={creator.is_favorite}
          creatorId={creator.id}
          onClickFavorite={onClickFavorite}
          className="creator-icon-with-name"
        />
      ))}
    </Wrapper>
  )
}
