import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../atoms'
import { FavoriteNewTitle } from '../../molecules'
import { ProductListOnSale } from '../../organisms'
import { mediaPc } from '../../../lib/MediaQuery'
import { PublishedProduct } from '../../../types/graphql'

type Props = {
  className?: string
  favoriteNewProducts: PublishedProduct[]
}

const Wrapper = styled.div`
  .favorite-title {
    margin-bottom: 4vw;
  }

  .no-data {
    text-align: center;
    margin-bottom: 6.6vw;
  }

  .more-ranking {
    width: 50vw;
    margin: 0 auto;
    display: block;
    font-size: 3.2vw;
    line-height: 2.8;
  }
  ${mediaPc`
    .favorite-title {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .favorite-new-products {
      & > div {
        margin: 0 64px 48px 0;
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
      & > div:last-child {
        margin-right: auto;
      }
    }
    .no-data {
      margin-bottom: 45px;
    }
    .more-ranking {
      width: 210px;
      height: 46px;
      font-size: 12px;
      line-height: 46px;
    }
  `}
`

export const HomeFavoriteNew: React.FC<Props> = ({ className, favoriteNewProducts }) => {
  const history = useHistory()

  const goToFavoriteNewProducts = (): void => {
    history.push('/favorite/products/new')
  }

  return (
    <Wrapper className={className}>
      <FavoriteNewTitle className="favorite-title" />

      {favoriteNewProducts.length > 0 ? (
        <ProductListOnSale className="favorite-new-products" products={favoriteNewProducts} />
      ) : (
        <div className="no-data">お気に入りしたクリエイターの新着商品はありません</div>
      )}

      <Button buttonType="white" onClick={goToFavoriteNewProducts} className="more-ranking">
        もっと見る
      </Button>
    </Wrapper>
  )
}
