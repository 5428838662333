import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorEditCompleteTpl, BusinessTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'

import { useBusinessResendCreatorAccountUpdateMutation } from '../../../types/graphql'
import { FlashMessageType } from '../../../types/myTypes'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessCreatorEditComplete: React.FC<Props> = (props) => {
  const [email, setEmail] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const location = useLocation<{ email: string }>()

  const [businessResendCreatorAccountUpdateMutation, { loading }] = useBusinessResendCreatorAccountUpdateMutation({
    onCompleted: () => {
      setFlashMessage({ type: 'success', message: '確認メールを再送しました' })
    },
    onError: (e) => {
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '確認メールを再送できませんでした' })
      }
    },
  })

  const onClickResendConfirmMail = async (): Promise<void> => {
    setFlashMessage(null)
    await businessResendCreatorAccountUpdateMutation({
      variables: {
        creator_id: props.match.params.id,
        input: {
          email,
        },
      },
    })
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    setEmail(location.state.email)
  }, [location.state.email])

  return (
    <>
      <Meta title="メールアドレス更新用メール送信" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <CreatorEditCompleteTpl email={email} onClickResendConfirmMail={onClickResendConfirmMail} />
      </BusinessTwoColumnLayout>
    </>
  )
}
