import React from 'react'
import styled from 'styled-components'
import playMovieGreen from '../../assets/images/icon/play_movie_green.png'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  duration?: number | null
}

const StyledMovieDuration = styled.p`
  width: auto;
  height: 7.4vw;
  padding: 0 3.6vw 0 8.6vw;
  background: url(${playMovieGreen}) 1.5vw center no-repeat ${(props): string => props.theme.backgroundColors.primary};
  background-size: 5.3vw;
  color: ${(props): string => props.theme.textColors.white};
  font-size: 3.2vw;
  line-height: 7.4vw;
  border-radius: 3.7vw;
  display: inline-block;

  ${mediaPc`
    height: 28px;
    padding: 0 12px 0 31px;
    background-position: 5px center;
    background-size: 20px;
    font-size: 12px;
    line-height: 28px;
    border-radius: 14px;
  `}
`

export const MovieDuration: React.FC<Props> = ({ className = '', duration = '' }) => {
  return <StyledMovieDuration className={className}>{duration}秒動画</StyledMovieDuration>
}
