import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { PageTitle, Button } from '../../../atoms'
import { TwoColumnLayoutTab } from '../../../molecules'
import { CreatorProfile } from '../../../organisms'

import { mediaPc } from '../../../../lib/MediaQuery'
import mypageIcon from '../../../../assets/images/icon/mypage_green.png'

type Props = {
  children?: React.ReactNode
  currentTab?: 'creatorInfo' | 'products' | 'orders' | 'sales' | 'ranking' | 'users'
  isVisibleCreatorEditButton?: boolean
  isVisibleAddProductLink?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  .title-wrapper {
    width: 100%;
    padding: 5vw 7.2vw;
    text-align: center;
    background: ${(props): string => props.theme.backgroundColors.gray};

    .page-title {
      width: auto;
      padding-left: 7vw;
      display: inline-block;
      letter-spacing: 0.1em;
      background: url('${mypageIcon}') left center no-repeat;
      background-size: 5vw;
    }
  }

  .content {
    width: 100vw;
    background: ${(props): string => props.theme.backgroundColors.gray};
    & > .inner {
      width: 100%;
      padding: 5vw 7.2vw 15vw;
    }

    .add-product-button.sp {
      margin-bottom: 7vw;
    }

    .right-column {
      width: 100%;

      .tab {
        margin-bottom: 7.5vw;
      }
    }
  }

  ${mediaPc`
    .title-wrapper {
      width: 1040px;
      margin: 0 auto;
      padding: 80px 20px 45px;
      background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

      .page-title {
        padding-left: 50px;
        letter-spacing: 0.3em;
        background-size: 32px;
      }
    }

    .content {
      width: 100%;
      min-width: 1040px;

      & > .inner {
        width: 1040px;
        margin: 0 auto;
        padding: 34px 20px 50px;
        display: flex;
        justify-content: space-between;
      }

      .right-column {
        width: 720px;
        .row {
          display: flex;
          justify-content: space-between;
          .tab {
            margin-bottom: 16px;
          }
          .add-product-button {
            cursor:pointer;
            font-weight: bold;
            font-size:14px;
            color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.buttonColors.red};
          }
        }

        .right-column-content {
          border-radius: 4px;
          min-height: 317px;
          padding: 25px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
        }
      }
    }
  `}
`

export const CreatorTwoColumnLayout: React.FC<Props> = ({
  children,
  currentTab,
  isVisibleCreatorEditButton,
  isVisibleAddProductLink = true,
}) => {
  const history = useHistory()
  const { pathname } = useLocation()

  const tabs: { label: string; selected: boolean; onClick: () => void }[] = [
    {
      label: 'クリエイター情報',
      selected: currentTab === 'creatorInfo',
      onClick: () => {
        history.push('/creator/mypage')
      },
    },
    {
      label: '商品',
      selected: currentTab === 'products',
      onClick: () => {
        history.push('/creator/products')
      },
    },
    {
      label: '注文状況',
      selected: currentTab === 'orders',
      onClick: () => {
        history.push('/creator/orders')
      },
    },
    {
      label: '売上一覧',
      selected: currentTab === 'sales',
      onClick: () => {
        history.push('/creator/sales')
      },
    },
    {
      label: 'ランキング',
      selected: currentTab === 'ranking',
      onClick: () => {
        history.push('/creator/purchase-ranking')
      },
    },
    {
      label: '購入者',
      selected: currentTab === 'users',
      onClick: () => {
        history.push('/creator/ordered-users')
      },
    },
  ]

  const goToRegisterProduct = (): void => {
    history.push(`/creator/product/register`)
  }

  return (
    <Wrapper>
      <div className="title-wrapper">
        <PageTitle title="マイページ" className="page-title" />
      </div>
      <div className="content">
        <div className="inner">
          {pathname === '/creator/mypage' && (
            <>
              <CreatorProfile isVisibleEditButton={isVisibleCreatorEditButton} className="sp" />
              <Button className="add-product-button sp" buttonType="red" onClick={goToRegisterProduct}>
                新しい商品を販売する
              </Button>
            </>
          )}
          <CreatorProfile isVisibleEditButton={isVisibleCreatorEditButton} className="pc" />
          <div className="right-column">
            <div className="row">
              <TwoColumnLayoutTab tabs={tabs} className="tab" />
              {isVisibleAddProductLink && (
                <div className="add-product-button pc" onClick={goToRegisterProduct}>
                  新しい商品を販売する
                </div>
              )}
            </div>
            <div className="right-column-content">{children}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
