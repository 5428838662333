interface Config {
  userFrontEndpoint: string
  creatorFrontEndpoint: string
  businessFrontEndpoint: string
  apiEndpoint: string
  shareEndpoint: string
  userFrontDomain: string
  creatorFrontDomain: string
  businessFrontDomain: string
}

export const config: Config = {
  userFrontEndpoint: process.env.REACT_APP_USER_FRONT_ENDPOINT ?? '',
  creatorFrontEndpoint: process.env.REACT_APP_CREATOR_FRONT_ENDPOINT ?? '',
  businessFrontEndpoint: process.env.REACT_APP_BUSINESS_FRONT_ENDPOINT ?? '',
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT ?? '',
  shareEndpoint: process.env.REACT_APP_SHARE_ENDPOINT ?? '',
  userFrontDomain: process.env.REACT_APP_USER_DOMAIN ?? '',
  creatorFrontDomain: process.env.REACT_APP_CREATOR_DOMAIN ?? '',
  businessFrontDomain: process.env.REACT_APP_BUSINESS_DOMAIN ?? '',
}

interface ExternalLink {
  userLogin: string
  creatorLogin: string
}

export const externalLink: ExternalLink = {
  userLogin: `${config.userFrontEndpoint}/login`,
  creatorLogin: `${config.creatorFrontEndpoint}/login`,
}
