import React from 'react'
import styled from 'styled-components'
import { CreatorIconWithName } from '../../molecules'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'
import { CreatorRanking } from '../../../types/graphql'

type Props = {
  className?: string
  creatorRanking: CreatorRanking[]
  isUserLoggedIn?: boolean
  onClickFavorite?: (creatorId: string, isFavorite: boolean) => void
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .ranked-creator-icon {
    width: 39vw;
    margin-bottom: 6.6vw;

    &:nth-child(odd) {
      margin-right: 11vw;
    }
  }

  .no-ranking {
    margin: 0 auto 6.6vw;
  }

  ${mediaPc`
    justify-content: flex-start;

    .ranked-creator-icon {
      width: 146px;
      margin: 0 66px 45px 0;

      &:nth-child(odd) {
        margin-right: 66px;
      }

      &:nth-child(5n) {
        margin-right: 0;
      }
    }

    .no-ranking {
      margin: 0 auto 45px;
    }
  `}
`

export const RankedCreators: React.FC<Props> = ({ className, creatorRanking, isUserLoggedIn, onClickFavorite }) => {
  const getCreatorTopUrl = (creatorRanking: CreatorRanking): string => {
    if (userType() === 'business') {
      return `/business/creator/${creatorRanking.creator.id}/user`
    } else if (userType() === 'creator') {
      return `/creator/${creatorRanking.creator.id}/user`
    } else if (userType() === 'user') {
      return `/creators/${creatorRanking.creator.id}`
    }
    return ''
  }

  return (
    <Wrapper className={className}>
      {creatorRanking.length !== 0 &&
        creatorRanking.map((creatorRanking) => (
          <CreatorIconWithName
            key={creatorRanking.creator.id}
            displayRank
            rank={creatorRanking.rank}
            link={getCreatorTopUrl(creatorRanking)}
            iconSrc={creatorRanking.creator.icon_url}
            iconAlt={creatorRanking.creator.name}
            creatorName={creatorRanking.creator.name}
            creatorId={creatorRanking.creator.id}
            displayFavorite={userType() === 'user' && isUserLoggedIn}
            isFavorite={creatorRanking.creator.is_favorite}
            className="ranked-creator-icon"
            onClickFavorite={onClickFavorite}
          />
        ))}
      {creatorRanking.length === 0 && <p className="no-ranking">指定の期間のクリエイターランキングはありません</p>}
    </Wrapper>
  )
}
