import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SalesTable, SubtitleWithLink } from '../../../molecules'
import { SalesPerMonth } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import moment from 'moment'

type Props = {
  sales: SalesPerMonth[]
}

const Wrapper = styled.div`
  .title {
    margin-bottom: 2.1vw;
    ${mediaPc`
      margin-bottom: 16px;
  `}
  }
`

export const BusinessMypageTopTpl: React.FC<Props> = ({ sales }) => {
  const history = useHistory()
  const goSalesListPage = (): void => {
    history.push(`/business/sales/affiliation`)
  }
  const goBreakDown = (month: string): void => {
    history.push(`/business/sales/affiliation/creator/${moment(month).format('YYYYMM')}`)
  }
  return (
    <Wrapper>
      <SubtitleWithLink subtitle="今月の売上" linkText="売上一覧" className="title" onClick={goSalesListPage} />
      <SalesTable sales={sales} noDataText="今月の売上がこちらに表示されます" onClick={(month) => goBreakDown(month)} />
    </Wrapper>
  )
}
