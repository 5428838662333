export const APP_NAME = 'PINTO 4 YOU'
export const APP_URL = 'https://pintoforyou.com'
export const CONTACT_URL = 'https://tayori.com/form/4522f209bb45dafdf271db5e279ef0c9fbb0227c'
export const USER_FAQ_URL = 'https://pintoforyou.tayori.com/faq/00071060f7d33cfe5cfe0f42f7ba2991357c0660'
export const CREATOR_AND_BUSINESS_FAQ_URL = 'https://pintoforyou.tayori.com/faq/a8d84a3ceb31dddad83e7324d31522342921b55f'
export const CREATOR_REGISTRATION_URL = 'https://pintoforyou.tayori.com/form/245f46710a9a64b0c6371508dd3e9d38dcc263d6'
export const CREATOR_BANK_ACCOUNT_REGISTRATION_URL = 'https://pintoforyou.tayori.com/form/ee965284e2b88fd977e9c9add0e31d696fb88425'
export const SOCIAL_TWITTER_URL = 'https://twitter.com/p4you_jp'
export const SOCIAL_FACEBOOK_URL = 'https://www.facebook.com/P4you-109524284096439'
export const SOCIAL_INSTAGRAM_URL = 'https://www.instagram.com/p4you_jp/'
export const SOCIAL_YOUTUBE_URL = 'https://www.youtube.com/channel/UCE_SoG5pnUq-0FjLIq_f_yA?view_as=subscriber'
export const HASHTAG = 'p4you'
export const FLASH_MESSAGE_ANIMATION_DURATION = 6000
export const META_DESCRIPTION =
  '先着4名まで、あなたの推しからの特別なメッセージを受け取ることができるサービス。写真や動画などのデジタルコンテンツにてお届けしますので、スマホでいつでも閲覧可能、推しをより身近に感じることができます。'
