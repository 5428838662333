import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ContentTitleWithLink, SalesTable } from '../../../molecules'
import { Creator, SalesPerMonth } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import { userType } from '../../../../lib/UserType'
import moment from 'moment'

type Props = {
  creator?: Creator
  sales: SalesPerMonth[]
  className?: string
}

const Wrapper = styled.div`
  .sales-title {
    width: 100%;
    margin-bottom: 3vw;
  }
  ${mediaPc`
    .sales-title {
      font-size: 16px;
      margin-bottom: 10px;
    }
  `}
`

export const CreatorSaleThisMonth: React.FC<Props> = ({ className, creator, sales }) => {
  const history = useHistory()

  const goToList = (): void => {
    if (userType() === 'business') {
      history.push(`/business/sales/creator/${creator?.id}`)
    } else if (userType() === 'creator') {
      history.push(`/creator/sales`)
    }
  }

  const goToBreakDown = (month: string): void => {
    if (userType() === 'business') {
      history.push(`/business/sales/affiliation/creator/${creator?.id}/${moment(month).format('YYYYMM')}`)
    } else if (userType() === 'creator') {
      history.push(`/creator/sales/${moment(month).format('YYYYMM')}`)
    }
  }

  return (
    <Wrapper className={className}>
      <ContentTitleWithLink className="sales-title" title="今月の売上" linkText="売上一覧" onClick={goToList} />
      <SalesTable sales={sales} noDataText="月別の売上が一覧で表示されます" onClick={(month) => goToBreakDown(month)} />
    </Wrapper>
  )
}
