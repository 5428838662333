import React from 'react'
import styled from 'styled-components'
import { ProductListForManagementItem } from '../../molecules'
import { Product, PublishedProduct } from '../../../types/graphql'
import { ProductListType } from '../../../types/myTypes'

import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  productListType: ProductListType
  className?: string
  oneLineItemsNum?: number
  products: (Product | PublishedProduct)[]
  onClickToDisabled?: (productId: string) => void
  onClickToEnd?: (productId: string) => void
  onClickToDisplay?: (productId: string) => void
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mediaPc`
    padding: 0;
  `}
`

export const ProductListForManagement: React.FC<Props> = ({
  productListType,
  products,
  oneLineItemsNum,
  onClickToDisabled,
  onClickToEnd,
  onClickToDisplay,
}) => {
  return (
    <Wrapper>
      {products.map((product: Product | PublishedProduct, index: number) => (
        <ProductListForManagementItem
          productListType={productListType}
          oneLineItemsNum={oneLineItemsNum}
          product={product}
          key={index}
          onClickToDisabled={onClickToDisabled}
          onClickToEnd={onClickToEnd}
          onClickToDisplay={onClickToDisplay}
        />
      ))}
    </Wrapper>
  )
}
