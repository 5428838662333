import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessMypageTopTpl, BusinessTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { SalesPerMonth, useBusinessSalesThisMonthLazyQuery, useUpdateBusinessUserEmailByTokenMutation } from '../../../types/graphql'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { BusinessUserProfileContext } from '../../../context'

type Props = {}

export const BusinessMypageTop: React.FC<Props> = () => {
  const [sales, setSales] = useState<SalesPerMonth[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const { updateBusinessEmail } = useContext(BusinessUserProfileContext)

  const history = useHistory()
  const location = useLocation<{ type?: FlashMessageType['type']; message?: FlashMessageType['message']; from?: string }>()

  const [businessSalesThisMonthQuery] = useBusinessSalesThisMonthLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setSales(data.BusinessSalesThisMonth ? [data.BusinessSalesThisMonth] : [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [updateBusinessUserEmailByTokenMutation] = useUpdateBusinessUserEmailByTokenMutation({
    onCompleted: (data) => {
      if (data.UpdateBusinessUserEmailByToken) {
        updateBusinessEmail(data.UpdateBusinessUserEmailByToken.email)
      }
      setLoading(false)
      setFlashMessage({ type: 'success', message: 'メールアドレスを更新しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })
  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    businessSalesThisMonthQuery()
  }, [businessSalesThisMonthQuery])

  useEffect(() => {
    if (location.state) {
      // アカウント編集で名前のみ編集した時のリダイレクト
      if (location.state.type && location.state.message) {
        const { type, message } = location.state
        setFlashMessage({ type, message })
      }

      // アカウント編集でメールアドレスを変更した時のメールからの遷移
      if (location.state.from === 'updateEmail') {
        const params = new URLSearchParams(location.search)
        const id = params.get('id') || null
        const email = params.get('email') || null
        const expires = params.get('expires') || null
        const signature = params.get('signature') || null
        if (id && email && expires && signature) {
          setLoading(true)
          updateBusinessUserEmailByTokenMutation({
            variables: {
              input: {
                id: id,
                email: email,
                expires: Number(expires),
                signature: signature,
              },
            },
          })
        } else {
          Sentry.captureException('ビジネスユーザーのメールアドレスを更新できませんでした')
          setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
        }
      }

      // アカウント編集でメールアドレスを変更した時のメールからの遷移
      if (location.state.from === 'updateEmail') {
        const updateEmailError = new URLSearchParams(location.search).get('error_message')
        if (updateEmailError) {
          setFlashMessage({ type: 'systemError', message: updateEmailError })
        } else {
          setFlashMessage({ type: 'success', message: '更新しました' })
        }
      }
    }
  }, [location.search, location.state, updateBusinessUserEmailByTokenMutation])

  // location.stateがセットされたままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (location.state) {
      history.replace({ ...location, state: undefined, search: undefined })
    }
  }, [history, location])

  return (
    <>
      <Meta title="マイページTOP" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout>
        <BusinessMypageTopTpl sales={sales} />
      </BusinessTwoColumnLayout>
    </>
  )
}
