import React, { useEffect, useState } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../../../lib/MediaQuery'
import { ProductImage, MovieDuration, Button, Selectbox, Icon, Link } from '../../../atoms'

import moment from 'moment'

import { PublishedProduct, ProductType } from '../../../../types/graphql'
import { paymentType } from '../../../../types/myTypes'
import { paymentList } from '../../../../lib/SelectOptions'
import selectTriangleGreenImg from '../../../../assets/images/icon/select_triangle_green.png'
import liveTalkIcon from '../../../../assets/images/icon/live_talk.png'
import externalLink from '../../../../assets/images/icon/external_link.png'

type Props = {
  className?: string
  product: PublishedProduct
  payment: '' | paymentType
  onChangePayment: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onClickPurchase: () => void
}

const StyledProductPurchase = styled.div`
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  padding: 0 6.6vw 14.8vw;

  .product-image {
    width: 53.3vw;
    max-width: 100%;
    margin: 0 auto 4.8vw;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .movie-seconds-sp {
      position: absolute;
      width: auto;
      height: 7.4vw;
      padding: 0 3.6vw 0 8.6vw;
      bottom: 3vw;
      left: 3vw;
      z-index: 5;
      font-size: 3.2vw;
      line-height: 7.4vw;
      border-radius: 3.7vw;
    }

    .live-talk-icon {
      position: absolute;
      width: 26.6vw;
      z-index: 5;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
    }
  }

  .product-info {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5.6vw;

    .name {
      margin-bottom: 3.2vw;
      font-size: 4.27vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.black};
    }

    .price {
      margin-bottom: 4.8vw;
      font-size: 4.27vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    }

    .live-talk-attention {
      margin-bottom: 4.3vw;
      padding: 2.9vw;
      color: ${(props): string => props.theme.textColors.red};
      font-size: 3.2vw;
      font-weight: bold;
      line-height: 1.7;
      background: ${(props): string => props.theme.backgroundColors.bodyBg};
      border: 1px solid ${(props): string => props.theme.borderColors.red};
      border-radius: 4px;
    }

    .livetalk-lp-link-wrapper {
      margin-bottom: 4.2vw;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .link {
        margin-right: 0.266vw;
        color: ${(props): string => props.theme.textColors.primary};
        font-size: 2.66vw;
        font-weight: bold;
        line-height: 1.2;
        text-decoration-line: underline;
      }

      .icon {
        width: 2.66vw;
        height: 2.66vw;
      }
    }

    .schedule-wrapper {
      margin-bottom: 4.27vw;
      display: flex;
      align-items: center;

      > p {
        font-size: 3.733vw;
      }

      .schedule-title {
        margin-right: 3.2vw;
      }
    }

    .live-talk-note {
      margin-bottom: 2.9vw;
      color: ${(props): string => props.theme.textColors.red};
      font-size: 3.2vw;
      font-weight: bold;
      line-height: 1.7;
    }
  }

  .purchase {
    margin-bottom: 5.33vw;

    .payment-select {
      margin-bottom: 2.4vw;
      background: url(${selectTriangleGreenImg}) 96% center no-repeat ${(props): string => props.theme.backgroundColors.selectboxGray};
      background-size: 3.7vw;
      color: ${(props): string => props.theme.textColors.black};
      font-weight: bold;
    }

    .paidy-attention {
      list-style: disc;
      margin-top: 5.2vw;
      padding-left: 7.2vw;
      font-size: 3.2vw;

      .paidy-description {
        margin-top: 2.1vw;
      }

      .paidy-payment-method {
        margin-bottom: 6.4vw;
        list-style: none;
        color: ${(props): string => props.theme.textColors.red};
      }
    }

    .purchase-button {
      width: 100%;
      height: 12.2vw;
      line-height: 12.2vw;
      font-size: 3.735vw;
    }
  }

  .purchase-attention {
    margin: 0 auto;
    color: ${(props): string => props.theme.textColors.darkBlack};
    letter-spacing: 0.05em;
    font-size: 3.2vw;
    font-weight: 500;

    .paragraph-spacing {
      margin-bottom: 1em;
    }

    .paragraph-another-product {
      margin-bottom: 3.2vw;
    }

    .span-limit-notice {
      margin-bottom: 2.13vw;
      display: block;
      color: ${(props): string => props.theme.textColors.red};
      font-weight: 700;
      font-size: 3.46vw;
      line-height: 1.38;
      letter-spacing: 0.05em;
    }

    .span-adblock-notice {
      margin-bottom: 4.125vw;
      display: block;
      color: ${(props): string => props.theme.textColors.red};
      font-weight: bold;
      font-size: 3.46vw;
      line-height: 1.38;
      letter-spacing: 0.05em;
    }
  }

  ${mediaPc`
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      min-height: 317px;
      padding: 25px;

      .product-image {
        width: 329px;
        margin: initial;

        .live-talk-icon {
          width: 121px;
        }
      }

      .right-content-pc {
        width: 300px;
      }

      .product-info {
        margin: 0;
        padding: 20px 0 0 0;

        .name {
          font-weight: bold;
          font-size: 16px;
          padding: 0 0 19px;
          margin: 0;
          color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
        }

        .movie-seconds-pc {
          width: 96px;
          height: 28px;
          background-size: 20px;
          background-position: 4px;
          padding: 0 0 0 31px;
          font-size: 12px;
          line-height: 28px;
        }

        .price {
          font-weight: bold;
          margin: 0;
          padding: 53px 0 33px;
          text-align: right;
          font-size: 24px;
          color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
        }

        .live-talk-attention {
          margin-bottom: 9px;
          padding: 11px;
          font-size: 12px;
        }

        .livetalk-lp-link-wrapper {
          margin-bottom: 8px;
          .link{
            margin-right: 1px;
            font-size: 10px;
          }
          .icon {
            width: 10px;
            height: 10px;
          }
        }
    
        .schedule-wrapper {
          margin-bottom: 16px;
    
          > p {
            font-size: 14px;
          }
    
          .schedule-title {
            margin-right: 12px;
          }
        }
    
        .live-talk-note {
          margin-bottom: 30px;
          font-size: 12px;
        }
      }

      .purchase {
        margin-bottom: 18px;
        
        .payment-select {
          margin-bottom: 13px;
          background-size: 10px;
          border: none;
          font-size: 14px;
          font-weight: bold;
          line-height: 40px;
        }

        .paidy-attention {
          margin-top: 10px;
          padding-left: 20px;
          font-size: 12px;
    
          .paidy-description {
            margin-top: 10px;
          }
    
          .paidy-payment-method {
            margin-bottom: 20px;
            list-style: none;
          }
        }

        .purchase-button {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
      }

      .purchase-attention {
        width: 100%;
        font-size: 12px;
        line-height: 21px;
        
        .paragraph-another-product {
          margin-bottom: 12px;
        }
        
        .span-limit-notice {
          margin-bottom: 8px;
          font-size: 12px;
          line-height: 1.5;
        }
        
        .span-adblock-notice {
          font-size: 12px;
          line-height: 1.5;
        }
      }
  `}
`

export const ProductPurchase: React.FC<Props> = ({ className = '', product, payment, onChangePayment, onClickPurchase }) => {
  const [paymentLimit, setPaymentLimit] = useState<string>('')
  const paymentOptions: { value: string; label: string }[] = [{ value: '', label: '支払い方法を選択してください' }]
  paymentList.forEach((payment) => {
    paymentOptions.push({ value: payment.value, label: payment.label })
  })

  useEffect(() => {
    setPaymentLimit(moment().add(15, 'minutes').format('MM月DD日HH:mm'))
  }, [])

  return (
    <StyledProductPurchase className={className}>
      <div className="product-image">
        <ProductImage src={product.thumbnail_image} />
        {product.product_type === ProductType.Movie && <MovieDuration className="movie-seconds-sp sp" duration={product.movie_seconds} />}
        {product.product_type === ProductType.LiveTalk && <Icon className="live-talk-icon" src={liveTalkIcon} alt="ライブトーク" />}
      </div>
      <div className="right-content-pc">
        <div className="product-info">
          <div className="name">{product.name}</div>
          {product.product_type === ProductType.Movie && <MovieDuration className="movie-seconds-pc pc" duration={product.movie_seconds} />}
          <div className={product.product_type === ProductType.LiveTalk ? 'price sp' : 'price'}>¥{product.price.toLocaleString()}</div>
          {product.product_type === ProductType.LiveTalk && (
            <>
              <p className="live-talk-attention">
                オンラインでお話できる商品です。
                <br />
                ご購入の前に必ず日程をご確認ください。
              </p>
              <div className="livetalk-lp-link-wrapper">
                <Link url="/lp/live-talk" text="ライブトークとは" target="_blank" rel="noopener noreferrer" className="link" />
                <Icon src={externalLink} className="icon" />
              </div>
              <div className="schedule-wrapper">
                <p className="schedule-title">日付</p>
                <p className="schedule">{moment(product.live_talk?.started_at).format('YYYY/MM/DD')}</p>
              </div>
              <div className="schedule-wrapper">
                <p className="schedule-title">時間</p>
                <p className="schedule">
                  {moment(product.live_talk?.started_at).format('HH時mm分')}~
                  {moment(product.live_talk?.started_at).add(product.live_talk?.talk_minutes, 'minutes').format('HH時mm分')}　（
                  {product.live_talk?.talk_minutes}分間）
                </p>
              </div>
              <p className="live-talk-note">ご購入後、上記開始日時にマイページの購入履歴からライブトーク画面にお入りください。</p>
              <div className="price pc">¥{product.price.toLocaleString()}</div>
            </>
          )}
        </div>
        <div className="purchase">
          <Selectbox options={paymentOptions} name="payment" value={payment} onChange={onChangePayment} className="payment-select" />
          {payment === 'paidy' && (
            <ul className="paidy-attention">
              <li className="paidy-description">
                メールアドレスと携帯電話番号だけでご利用いただける決済方法です。事前登録・クレジットカードは必要ありません。
              </li>
              <li className="paidy-description">
                月に何回お買い物をしても、お支払いは翌月にまとめて１回。1ヶ月分のご利用金額は、翌月1日に確定し、メールとSMSでお知らせします。
              </li>
              <li className="paidy-description">下記のお支払い方法がご利用いただけます。</li>
              <li className="paidy-payment-method">
                口座振替(支払手数料：無料)
                <br />
                コンビニ(支払手数料：356円税込)
                <br />
                銀行振込(支払手数料：金融機関により異なります)
              </li>
            </ul>
          )}
          <Button className="purchase-button" buttonType="blue" disabled={payment === ''} onClick={onClickPurchase}>
            決済画面へ
          </Button>
        </div>
        <p className="purchase-attention">
          <p className="paragraph-spacing">
            注文から決済までは１商品づつお願い致します。
            注文中に他の商品を注文するとエラーが発生し、決済されることがあるのでご注意ください。
          </p>
          <p className="paragraph-spacing">
            先着{product.product_type === ProductType.LiveTalk ? 1 : 4}
            名様の商品のため15分以内に決済が完了しない場合は、購入がキャンセルとなりますのでご注意ください。
          </p>
          <p className="paragraph-another-product">
            及び、別の商品を注文した際に、最初の商品の注文がキャンセルになりますのでご注意ください。
          </p>
          <span className="span-limit-notice">※ {paymentLimit}までに決済を完了してください。</span>
          <span className="span-adblock-notice">※ AdBlock等の広告ブロックをしている場合、決済画面が表示されない場合があります。</span>
        </p>
      </div>
    </StyledProductPurchase>
  )
}
