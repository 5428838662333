import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorReservedProductsTpl, CreatorTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { Product, usePagedCreatorReservedProductsLazyQuery, useCreatorDisableProductMutation } from '../../../types/graphql'

import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'

type Props = {} & RouteComponentProps

export const CreatorReservedProducts: React.FC<Props> = (props) => {
  const [reservedProducts, setReservedProducts] = useState<Product[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const history = useHistory()
  const location = useLocation<{ registerProductComplete: boolean }>()

  const [pagedCreatorReservedProductsLazyQuery] = usePagedCreatorReservedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setReservedProducts(data.PagedCreatorReservedProducts?.data ?? [])
        setLastPage(data.PagedCreatorReservedProducts?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [creatorDisableProductMutation] = useCreatorDisableProductMutation({
    onCompleted: () => {
      pagedCreatorReservedProductsLazyQuery({
        variables: {
          first: 20,
          page: currentPage,
        },
      })
      setFlashMessage({ type: 'success', message: '選択した商品を非表示にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickToDisabled = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    creatorDisableProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }

  useValidateCreatorToken()
  useSetCreatorProfile()
  useEffect(() => {
    if (location.state) {
      if (location.state.registerProductComplete) {
        setFlashMessage({ type: 'success', message: '登録が完了しました' })
      }
    }
  }, [location.state])

  // location.state.registerProductComplete = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (location.state && location.state.registerProductComplete === true) {
      history.replace({ ...location, state: undefined })
    }
  }, [history, location])

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    pagedCreatorReservedProductsLazyQuery({
      variables: {
        first: 20,
        page: Number(pageParam),
      },
    })
  }, [props.location.search, pagedCreatorReservedProductsLazyQuery])

  return (
    <>
      <Meta title="公開日時指定商品一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="products">
        <CreatorReservedProductsTpl
          currentPage={currentPage}
          lastPage={lastPage}
          reservedProducts={reservedProducts}
          onClickToDisabled={onClickToDisabled}
        />
      </CreatorTwoColumnLayout>
    </>
  )
}
