import React from 'react'
import styled from 'styled-components'
import checkboxIcon from '../../assets/images/icon/checkbox.png'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  checkboxOptions: { id: string; name: string; value: string; label: string }[]
  checkedValue: string[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  & {
    margin-right: 5.3vw;
    ${mediaPc`
      margin: 0 20px 20px 0;
    `}
  }
`
const StyledCheckbox = styled.input`
  width: 5.06vw;
  height: 5.06vw;
  cursor: pointer;
  padding: 6px 8px;
  border: 1px solid ${(props): string => props.theme.borderColors.gray};
  transition: all 0.2s ease;
  &:checked {
    background: url(${checkboxIcon}) center no-repeat ${(props): string => props.theme.backgroundColors.primary};
    background-size: 3.7vw;
    border: 1px solid ${(props): string => props.theme.borderColors.primary};
  }
  ${mediaPc`
    width: 19px;
    height: 19px;
    &:checked {
      background-size: 14px;
    }
  `}
`

const StyledLabel = styled.label`
  padding-left: 1.6vw;
  position: relative;
  display: block;
  font-size: 3.467vw;
  line-height: calc(5.8vw + 2px);
  color: ${(props): string => props.theme.textColors.gray};
  cursor: pointer;
  ${mediaPc`
    padding-left:10px;
    font-size: 14px;
    line-height: 24px;
  `}
`

export const Checkbox: React.FC<Props> = ({ className = '', checkboxOptions, checkedValue, onChange }) => {
  return (
    <Wrapper className={className}>
      {checkboxOptions.map((option) => (
        <CheckboxWrapper key={option.value}>
          <StyledCheckbox
            type="checkbox"
            checked={checkedValue.includes(option.value)}
            onChange={onChange}
            id={option.id}
            name={option.name}
            value={option.value}
          />
          <StyledLabel htmlFor={option.id}>{option.label}</StyledLabel>
        </CheckboxWrapper>
      ))}
    </Wrapper>
  )
}
