import React, { ChangeEvent } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Pagination } from '../../../molecules'
import { OrderedUser } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import { OrderedUserTable } from '../../../molecules/Common/OrderedUserTable'
import { ContentTitleWithSelectBox } from '../../../molecules/Common/ContentTitleWithSelectBox'

type Props = {
  orderedUsers: OrderedUser[]
  currentPage: number
  lastPage: number
  orderedUsersSortItems: { label: string; value: string }[]
  onChangeDropdown: (e: ChangeEvent<HTMLSelectElement>) => void
  selectedValue: string
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }

  .order-table-title {
    margin-bottom: 3vw;
  }

  .delivered-orders {
    margin-bottom: 7vw;
  }

  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title {
      margin-bottom: 35px;
    }

    .order-table-title {
      margin-bottom: 15px;
    }
  
    .delivered-orders {
      margin-bottom: 35px;
    }
  `}
`

export const CreatorOrderedUsersTpl: React.FC<Props> = ({
  orderedUsers,
  currentPage,
  lastPage,
  orderedUsersSortItems,
  onChangeDropdown,
  selectedValue,
}) => {
  return (
    <Wrapper>
      <ContentTitleWithSelectBox
        className="title"
        title={`購入者一覧`}
        name="ordered-users-sort"
        id="ordered-users-sort"
        onChange={onChangeDropdown}
        options={orderedUsersSortItems}
        value={selectedValue}
      />
      <div className="delivered-orders">
        <OrderedUserTable creatorId={''} orderedUsers={orderedUsers} noDataText="購入したユーザーはいません" />
        <Pagination lastPage={lastPage} currentPage={currentPage} />
      </div>
    </Wrapper>
  )
}
