import React from 'react'
import styled from 'styled-components'

import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  title?: string
}

const StyledContentTitle = styled.h3`
  font-size: 2.933vw;
  color: ${(props): string => props.theme.textColors.black};
  letter-spacing: 0.05em;

  ${mediaPc`
    font-size: 14px;
  `}
`

export const ContentTitle: React.FC<Props> = ({ className = '', title = '' }) => {
  return <StyledContentTitle className={className}>{title}</StyledContentTitle>
}
