import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { LoadingCircle, FlashMessage } from '../../../components/atoms'
import { UserTwoColumnLayout, UserFavoriteCreatorsTpl } from '../../../components/templates'
import { Creator, usePagedUserActiveCreatorsFavoritedLazyQuery, useUnfavoriteCreatorMutation } from '../../../types/graphql'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { useSetUserProfile } from '../../../lib/SetMyProfile'
import { useValidateUserToken } from '../../../lib/ValidateUserToken'
import { UserProfileContext } from '../../../context'

type Props = {} & RouteComponentProps

export const UserFavoriteCreators: React.FC<Props> = (props) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [creators, setCreators] = useState<Creator[]>([])
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const { updateFavoriteCreatorsNumber } = useContext(UserProfileContext)

  const [pagedUserActiveCreatorsFavoritedLazyQuery] = usePagedUserActiveCreatorsFavoritedLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data.PagedUserActiveCreatorsFavorited) {
        setCreators(data.PagedUserActiveCreatorsFavorited.data ?? [])
        setLastPage(data.PagedUserActiveCreatorsFavorited.paginatorInfo.lastPage ?? 1)
        updateFavoriteCreatorsNumber(data.PagedUserActiveCreatorsFavorited.paginatorInfo.total)
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'データを取得できませんでした' })
      }
    },
  })

  const [unfavoriteCreatorMutation] = useUnfavoriteCreatorMutation({
    onCompleted: () => {
      pagedUserActiveCreatorsFavoritedLazyQuery({
        variables: {
          first: 12,
          page: currentPage,
        },
      })
      setFlashMessage({ type: 'success', message: 'お気に入り解除しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り解除できませんでした' })
      }
    },
  })

  const onClickFavorite = (creatorId: string, isFavorite: boolean): void => {
    setLoading(true)
    setFlashMessage(null)

    //お気に入りクリエイター一覧ページなのでisFavoriteはtrueのはず
    if (!isFavorite) return

    unfavoriteCreatorMutation({
      variables: {
        creator_id: creatorId,
      },
    })
  }

  useValidateUserToken()
  useSetUserProfile()

  useEffect(() => {
    const paramsCurrentPage = new URLSearchParams(props.location.search).get('page')
    if (paramsCurrentPage) {
      setCurrentPage(Number(paramsCurrentPage))
    }
    pagedUserActiveCreatorsFavoritedLazyQuery({
      variables: {
        first: 12,
        page: Number(paramsCurrentPage),
      },
    })
  }, [pagedUserActiveCreatorsFavoritedLazyQuery, props.location.search])

  return (
    <>
      <Meta title="お気に入りクリエイター一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <UserTwoColumnLayout currentTab="favoriteList">
        <UserFavoriteCreatorsTpl currentPage={currentPage} lastPage={lastPage} creators={creators} onClickFavorite={onClickFavorite} />
      </UserTwoColumnLayout>
    </>
  )
}
