import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ProductImage, Icon, Button } from '../../../atoms'
import { PublishedOrderedProduct, OrderedProductStatus } from '../../../../types/graphql'

import { mediaPc } from '../../../../lib/MediaQuery'
import modalCloseIcon from '../../../../assets/images/icon/modal_close.png'
import playMovieIcon from '../../../../assets/images/icon/play_movie.png'
import moment from 'moment'

type Props = {
  purchasedProduct: PublishedOrderedProduct
  closeModal: () => void
  onClickDownloadButton: () => void
}

const Modal = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(129, 202, 196, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  .overlay-for-close {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;
  }
  .close-button {
    position: absolute;
    width: 8vw;
    max-width: 30px;
    height: 8vw;
    max-height: 30px;
    top: 20px;
    right: 20px;
    z-index: 23;
    cursor: pointer;
  }
  .inner {
    width: 100%;
    max-width: 550px;
    max-height: 100%;
    position: relative;
    z-index: 21;
    overflow: scroll;
    z-index: 21;
    padding: 6.5vw 7.4vw;
    margin: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .creator-info {
      margin-bottom: 6.4vw;
      display: flex;
      .creator-icon {
        width: 10vw;
        height: 10vw;
        margin-right: 1.6vw;
        border-radius: 50%;
      }
      p {
        font-size: 3７vw;
        line-height: 2em;
        font-weight: bold;
        color: ${(props): string => props.theme.textColors.white};
      }
    }
    .product-info {
      position: relative;
      display: block;
      color: ${(props): string => props.theme.textColors.white};
      .product-image {
        position: relative;
        margin-bottom: 2.6vw;
        word-break: break-all;
        &.preparing {
          &:after {
            width: 100%;
            height: 100%;
            font-size: 5.603vw;
            font-weight: bold;
            border-radius: 4px;
            background: rgba(34, 34, 34, 0.4);
            color: ${(props): string => props.theme.textColors.primary};
            content: '作成中です';
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &.movie {
          &:after {
            width: 10.6vw;
            height: 10.6vw;
            font-size: 5.603vw;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background: url(${playMovieIcon}) center no-repeat;
            background-size: 10.6vw;
          }
        }
      }
      video {
        width: 100%;
      }
      .info {
        width: 100%;
        padding: 2vw;
        border-radius: 0 0 4px 4px;
        .product-name {
          width: 100%;
          font-size: 3.735vw;
          font-weight: bold;
          letter-spacing: 0.05em;
          margin-bottom: 2vw;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }
        .paid-at {
          width: 61%;
          font-size: 2.4vw;
          display: inline-block;
          letter-spacing: 0.05em;
          line-height: 3.2vw;
          color: ${(props): string => props.theme.textColors.darkBlack};
        }
      }
    }
  }

  ${mediaPc`
    padding:0;
    .close-button {
      width: 60px;
      height: 60px;
      max-width: initial;
      max-height: initial;
      top: 44px;
      right: 44px;
    }
    .inner {
      width: 100%;
      max-width: 300px;
      height: auto;
      padding:106px 0 106px;
      .creator-info {
        margin-bottom: 0;
        padding:0 0 17px;
        .creator-icon {
          width:40px;
          height:40px;
          margin:0;
        }
        p {
          font-size:14px;
          line-height: auto;
          padding-left:7px;
        }
      }
      .product-info {
        position: relative;
        .product-image {
          width: 300px;
          height: auto;
          margin-bottom: 0;
          &.preparing {
            &:after {
                font-size: 16px;
            }
          }
          &.movie {
            &:after {
              width: 40px;
              height: 40px;
              content: "";
              position: absolute;
              background-size: 40px;
            }
          }
        }
        .info {
          padding: 13px 0px 7px;
          .product-name {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 0;
          }
          .paid-at {
            width: auto;
            font-size: 10px;
            line-height: 1.6;
          }
        }
      }
      
    }
  `}
`

export const PurchasedProductModalTpl: React.FC<Props> = ({ purchasedProduct, closeModal, onClickDownloadButton }) => {
  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'

    return () => {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  })
  const isPreparing = (): boolean => {
    return purchasedProduct.status === OrderedProductStatus.Undelivered || purchasedProduct.status === OrderedProductStatus.Processing
  }
  const isPhoto = purchasedProduct.product.is_photo
  return (
    <Modal>
      <div className="overlay-for-close" onClick={closeModal} />
      <div className="close-button" onClick={closeModal}>
        <Icon src={modalCloseIcon} />
      </div>
      <div className="inner">
        <div className="creator-info">
          <Icon src={purchasedProduct.creator.icon_url} alt={purchasedProduct.creator.name} className="creator-icon" />
          <p>{purchasedProduct.creator.name}</p>
        </div>
        <div className="product-info">
          {isPreparing() && (
            <div className="preparing product-image">
              <ProductImage src={purchasedProduct.product.thumbnail_image} />
            </div>
          )}
          {!isPreparing() && isPhoto && (
            <div className="product-image">
              <ProductImage src={purchasedProduct.latest_delivered_product?.delivered_product_url || ''} />
            </div>
          )}
          {!isPreparing() && !isPhoto && (
            <video controls src={purchasedProduct.latest_delivered_product?.delivered_product_url || ''}></video>
          )}
          <div className="info">
            <div className="product-name">{purchasedProduct.product.name}</div>
            <p className="paid-at">支払日: {moment(purchasedProduct.order.paid_at).format('YYYY-MM-DD HH:mm')}</p>
          </div>
          {!isPreparing() && (
            <Button buttonType="black" onClick={onClickDownloadButton}>
              ダウンロード
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}
