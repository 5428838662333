import React, { useState, useEffect, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorTwoColumnLayout, ProductDeliveryTpl, DialogTpl, ProgressBarTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  ProductType,
  OrderedProduct,
  OrderedProductStatus,
  useCreatorOrderedProductLazyQuery,
  useCreatorMovieDeliveryProductUploadUrlLazyQuery,
  useCreatorDeliveryMovieProductMutation,
  useCreatorDeliveryPhotoProductMutation,
  useCreatorDeliveryLiveTalkProductMutation,
} from '../../../types/graphql'

import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'

type Props = {} & RouteComponentProps<{ id: string }>

export const CreatorProductDelivery: React.FC<Props> = (props) => {
  const [order, setOrder] = useState<OrderedProduct | null>()
  const [uploadedProduct, setUploadedProduct] = useState<File | null>(null)
  const [uploadedProductUrl, setUploadedProductUrl] = useState<string>('')
  const [tempUploadedProductUrl, setTempUploadedProductUrl] = useState<string>('')
  const [uploading, setUploading] = useState<boolean>(false)
  const [uploadingText, setUploadingText] = useState<string>('')
  const [uploadingTextDotTimer, setUploadingTextDotTimer] = useState<number | null>(null)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [movieUploadPath, setMovieUploadPath] = useState<string>('')
  const [deliveryDialogVisible, setDeliveryDialogVisible] = useState<boolean>(false)
  const [completeDialogVisible, setCompleteDialogVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [metaTitle, setMetaTitle] = useState<string>('')

  const [creatorMovieDeliveryProductUploadUrlLazyQuery] = useCreatorMovieDeliveryProductUploadUrlLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // 動画の本アップロード用のpathをset
      setMovieUploadPath(data.CreatorMovieDeliveryProductUploadUrl.upload_file_path ?? '')

      // s3に動画一時アップロード
      if (data.CreatorMovieDeliveryProductUploadUrl.upload_url) {
        // s3に動画一時アップロード中のプログレスバー表示
        setUploading(true)
        setUploadingText('アップロード中')
        setUploadProgress(0)
        let textDotNum = 0
        const timer = setInterval(() => {
          textDotNum = ++textDotNum % 4
          setUploadingText('アップロード中' + Array(textDotNum + 1).join('.'))
        }, 500)
        setUploadingTextDotTimer(timer)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        tempUploadMovie(data.CreatorMovieDeliveryProductUploadUrl.upload_url)
      } else {
        setFlashMessage({ type: 'systemError', message: '動画をアップロードできませんでした' })
      }
    },
    onError: (e) => {
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '動画をアップロードできませんでした' })
      }
      Sentry.captureException(e)
    },
  })

  const [creatorOrderedProductLazyQuery] = useCreatorOrderedProductLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setOrder(data.CreatorOrderedProduct)
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [creatorDeliveryMovieProductMutation] = useCreatorDeliveryMovieProductMutation({
    onCompleted: () => {
      setLoading(false)
      setCompleteDialogVisible(true)
    },
    onError: (e) => {
      setLoading(false)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '動画をアップロードできませんでした' })
      }
      Sentry.captureException(e)
    },
  })

  const [creatorDeliveryPhotoProductMutation] = useCreatorDeliveryPhotoProductMutation({
    onCompleted: () => {
      setUploadedProductUrl('')
      creatorOrderedProductLazyQuery({
        variables: {
          ordered_product_id: props.match.params.id,
        },
      })
      setFlashMessage({ type: 'success', message: '納品が完了しました' })
    },
    onError: (e) => {
      setLoading(false)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '写真をアップロードできませんでした' })
      }
      Sentry.captureException(e)
    },
  })

  const [creatorDeliveryLiveTalkProductMutation] = useCreatorDeliveryLiveTalkProductMutation({
    onCompleted: () => {
      creatorOrderedProductLazyQuery({
        variables: {
          ordered_product_id: props.match.params.id,
        },
      })
      window.open(`/creator/live-talk/${order?.product_id}`, '_blank')
    },
    onError: (e) => {
      setLoading(false)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'ライブトークを開始できませんでした' })
      }
      Sentry.captureException(e)
    },
  })

  const uploadProduct = (file: File, url: string, type: 'photo' | 'movie'): void => {
    if (type === 'photo') {
      setUploadedProduct(file)
      setUploadedProductUrl(url)
    }
    if (type === 'movie') {
      // s3動画一時アップロード用にファイルセット && 成功後に動画を表示するために一旦tempにurl保存
      setUploadedProduct(file)
      setTempUploadedProductUrl(url)
      setFlashMessage(null)

      creatorMovieDeliveryProductUploadUrlLazyQuery({
        variables: {
          ordered_product_id: props.match.params.id,
        },
      })
    }
  }

  const tempUploadMovie = async (url: string): Promise<void> => {
    new Promise((res, rej) => {
      const xhr = new XMLHttpRequest()

      xhr.open('PUT', url)
      xhr.setRequestHeader('Content-Type', 'multipart/form-data')

      xhr.onload = () => {
        // 一瞬で一時アップロードが終わったら一瞬でプログレスバーが消えてよくわからないことになるので、setTimeout追加
        setTimeout(() => {
          setUploading(false)
          setUploadedProductUrl(tempUploadedProductUrl)
          if (uploadingTextDotTimer !== null) {
            clearInterval(uploadingTextDotTimer)
          }
        }, 300)
      }

      xhr.onerror = rej

      if (xhr.upload) {
        xhr.upload.onprogress = (e) => {
          setUploadProgress((e.loaded / e.total) * 100)
        }
      }

      xhr.send(uploadedProduct)
    }).catch((e) => {
      setUploading(false)
      setFlashMessage({ type: 'systemError', message: '動画をアップロードできませんでした' })
      Sentry.captureException(e)
    })
  }

  const showDeliveryDialog = (): void => {
    setDeliveryDialogVisible(true)
  }

  const onClickDeliveryDialogOk = (): void => {
    setDeliveryDialogVisible(false)
    setLoading(true)
    setFlashMessage(null)

    if (order?.product?.product_type === ProductType.Photo) {
      creatorDeliveryPhotoProductMutation({
        variables: {
          input: {
            ordered_product_id: props.match.params.id,
            delivery_photo_file: uploadedProduct,
          },
        },
      })
    }
    if (order?.product?.product_type === ProductType.Movie) {
      creatorDeliveryMovieProductMutation({
        variables: {
          input: {
            ordered_product_id: props.match.params.id,
            delivery_movie_path: movieUploadPath,
          },
        },
      })
    }
  }

  const onClickDeliveryDialogCancel = (): void => {
    setDeliveryDialogVisible(false)
  }

  const onClickCompleteDialogOk = (): void => {
    setCompleteDialogVisible(false)
    setLoading(true)
    setFlashMessage(null)
    setUploadedProductUrl('')
    creatorOrderedProductLazyQuery({
      variables: {
        ordered_product_id: props.match.params.id,
      },
    })
  }

  const refetchLiveTalkProductWithTimer = (): void => {
    const currentDate = moment()
    const secondsLeftBeforeStart = moment(order?.product?.live_talk?.started_at).diff(currentDate)
    const secondsLeftBeforeFinish = moment(order?.product?.live_talk?.started_at)
      .add(order?.product?.live_talk?.talk_minutes, 'minutes')
      .diff(currentDate)

    setTimeout(() => {
      creatorOrderedProductLazyQuery({
        variables: {
          ordered_product_id: props.match.params.id,
        },
      })
    }, secondsLeftBeforeStart)

    setTimeout(() => {
      creatorOrderedProductLazyQuery({
        variables: {
          ordered_product_id: props.match.params.id,
        },
      })
    }, secondsLeftBeforeFinish)
  }

  const goToLiveTalk = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    const currentDate = moment().format()
    const enterableDate = moment(order?.product.live_talk?.started_at).subtract(5, 'm').format()
    const finishDate = moment(order?.product.live_talk?.started_at).add(Number(order?.product.live_talk?.talk_minutes), 'm').format()
    if (moment(currentDate).isBetween(enterableDate, finishDate)) {
      if (order?.status === OrderedProductStatus.Undelivered) {
        creatorDeliveryLiveTalkProductMutation({
          variables: {
            ordered_product_id: props.match.params.id,
          },
        })
      } else {
        creatorOrderedProductLazyQuery({
          variables: {
            ordered_product_id: props.match.params.id,
          },
        })
        window.open(`/creator/live-talk/${order?.product_id}`, '_blank')
      }
      refetchLiveTalkProductWithTimer()
    } else {
      setTimeout(() => {
        setLoading(false)
        setFlashMessage({ type: 'systemError', message: 'トーク時間外です。開始5分前にもう一度クリックしてください。' })
        // setFlashMessage(null)待ち
      }, 2000)
    }
  }

  useValidateCreatorToken()
  useSetCreatorProfile()

  const getTextPerStatus = (status: OrderedProductStatus): string => {
    switch (status) {
      case OrderedProductStatus.Delivered:
        return '納品済の注文'
      case OrderedProductStatus.Undelivered:
        return '未納品の注文'
      case OrderedProductStatus.Processing:
        return '処理中の注文'
      case OrderedProductStatus.Canceled:
        return 'キャンセル済みの注文'
      default:
        return ''
    }
  }
  const getMetaTitle = useCallback(() => {
    const creatorName = order?.product?.creator ? `${order.product.creator.name}さん` : ''
    let textPerStatus
    if (order?.status !== undefined) {
      const productStatus = order.status
      textPerStatus = `${getTextPerStatus(productStatus)}`
    }
    setMetaTitle(`${creatorName} ${textPerStatus}`)
  }, [order])

  useEffect(() => {
    getMetaTitle()
    creatorOrderedProductLazyQuery({
      variables: {
        ordered_product_id: props.match.params.id,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorOrderedProductLazyQuery, props.match.params.id, getMetaTitle])

  return (
    <>
      <Meta title={order ? metaTitle : ''} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {uploading && <ProgressBarTpl text={uploadingText} progress={uploadProgress} />}
      {deliveryDialogVisible && (
        <DialogTpl title="内容の確認" onClickOk={onClickDeliveryDialogOk} onClickCancel={onClickDeliveryDialogCancel}>
          {`この内容で${order?.product.product_type === ProductType.Photo ? '納品' : 'アップロード(納品)'}しますか？`}
        </DialogTpl>
      )}
      {completeDialogVisible && (
        <DialogTpl title="アップロードが完了しました" onClickOk={onClickCompleteDialogOk} displayCancel={false}>
          動画の変換処理には少しお時間がかかります。
          <br />
          納品済の状態になるまでしばらくお待ちください。
        </DialogTpl>
      )}
      {order && (
        <CreatorTwoColumnLayout currentTab="products">
          <ProductDeliveryTpl
            order={order}
            uploadedProductUrl={uploadedProductUrl}
            uploadProduct={uploadProduct}
            showDeliveryDialog={showDeliveryDialog}
            goToLiveTalk={goToLiveTalk}
          />
        </CreatorTwoColumnLayout>
      )}
    </>
  )
}
