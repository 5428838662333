import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import {
  AffiliationDetailTpl,
  CreatorRegistrationModalTpl,
  AffiliationEditModalTpl,
  BusinessTwoColumnLayout,
} from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  SalesPerMonth,
  Affiliation,
  useAffiliationInBusinessLazyQuery,
  useCreatorsInAffiliationLazyQuery,
  useAffiliationsInBusinessLazyQuery,
  useRegisterNewCreatorMutation,
  useEditAffiliationMutation,
  useAffiliationSaleThisMonthLazyQuery,
} from '../../../types/graphql'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessAffiliationDetail: React.FC<Props> = (props) => {
  const [affiliationName, setAffiliationName] = useState<string>('')
  const [creators, setCreators] = useState<Creator[]>([])
  const [sales, setSales] = useState<SalesPerMonth[]>([])
  const [affiliations, setAffiliations] = useState<Affiliation[]>([])
  const [newAffiliationName, setNewAffiliationName] = useState<string>('')
  const [newCreatorEmail, setNewCreatorEmail] = useState<string>('')
  const [newCreatorAffiliation, setNewCreatorAffiliation] = useState<string>(props.match.params.id)
  const [affiliationEditModalVisible, setAffiliationEditModalVisible] = useState<boolean>(false)
  const [creatorRegistrationModalVisible, setCreatorRegistrationModalVisible] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const history = useHistory()
  const location = useLocation<{ creatorRegistrationComplete: boolean }>()

  const [affiliationInBusinessLazyQuery] = useAffiliationInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAffiliationName(data.AffiliationInBusiness?.name ?? '')
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [creatorsInAffiliationLazyQuery] = useCreatorsInAffiliationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCreators(data.CreatorsInAffiliation ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [affiliationsInBusinessLazyQuery] = useAffiliationsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAffiliations(data.AffiliationsInBusiness ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [affiliationSaleThisMonthLazyQuery] = useAffiliationSaleThisMonthLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setSales(data.AffiliationSaleThisMonth.sale_this_month ? [data.AffiliationSaleThisMonth.sale_this_month] : [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [registerNewCreatorMutation] = useRegisterNewCreatorMutation({
    onCompleted: () => {
      setLoading(false)
      if (newCreatorAffiliation === '') {
        // 所属なしで登録した場合はクリエイター一覧に遷移
        history.push({ pathname: '/business/creator', state: { creatorRegistrationComplete: true } })
      } else if (newCreatorAffiliation !== props.match.params.id) {
        // 所属を変更して登録した場合は変更後の所属詳細に遷移
        history.push({ pathname: `/business/affiliation/${newCreatorAffiliation}`, state: { creatorRegistrationComplete: true } })
      } else {
        // 表示中の所属のクリエイターを登録した場合はモーダルを閉じて表示を更新
        setNewCreatorEmail('')
        setNewCreatorAffiliation(props.match.params.id)
        setFlashMessage({ type: 'success', message: '仮登録が完了しました' })
        // 登録完了後再度クリエイター一覧取得クエリ実行
        creatorsInAffiliationLazyQuery({
          variables: {
            affiliation_id: props.match.params.id,
          },
        })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '仮登録できませんでした' })
      }
    },
  })

  const [editAffiliationMutation] = useEditAffiliationMutation({
    onCompleted: () => {
      setLoading(false)
      setFlashMessage({ type: 'success', message: '更新が完了しました' })
      setNewAffiliationName(newAffiliationName)
      // 登録完了後再度事務所情報取得クエリ実行
      affiliationInBusinessLazyQuery({
        variables: {
          affiliation_id: props.match.params.id,
        },
      })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const openAffiliationEditModal = (): void => {
    setAffiliationEditModalVisible(true)
    setNewAffiliationName(affiliationName)
  }

  const closeAffiliationEditModal = (): void => {
    setAffiliationEditModalVisible(false)
  }

  const onChangeNewAffiliationName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewAffiliationName(event.target.value)
  }

  const openCreatorRegistrationModal = (): void => {
    setCreatorRegistrationModalVisible(true)
    setNewCreatorAffiliation(props.match.params.id)
  }

  const closeCreatorRegistrationModal = (): void => {
    setCreatorRegistrationModalVisible(false)
    setNewCreatorEmail('')
  }

  const onChangeNewCreatorEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewCreatorEmail(event.target.value)
  }

  const onChangeNewCreatorAffiliation = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setNewCreatorAffiliation(event.target.value)
  }

  const onClickCreatorRegistration = (): void => {
    setLoading(true)
    setFlashMessage(null)
    registerNewCreatorMutation({
      variables: {
        input: {
          email: newCreatorEmail,
          affiliation_id: newCreatorAffiliation,
        },
      },
    })
  }

  const onClickUpdateAffiliation = (): void => {
    setLoading(true)
    setFlashMessage(null)
    editAffiliationMutation({
      variables: {
        affiliation_id: props.match.params.id,
        input: {
          affiliation_name: newAffiliationName,
        },
      },
    })
  }

  useEffect(() => {
    if (location.state && location.state.creatorRegistrationComplete === true) {
      setFlashMessage({ message: '仮登録が完了しました', type: 'success' })
    }
  }, [location.state])

  // location.state.creatorRegistrationComplete = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (location.state && location.state.creatorRegistrationComplete === true) {
      history.replace({ ...location, state: undefined })
    }
  }, [history, location])

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    affiliationInBusinessLazyQuery({
      variables: {
        affiliation_id: props.match.params.id,
      },
    })
    creatorsInAffiliationLazyQuery({
      variables: {
        affiliation_id: props.match.params.id,
      },
    })
    affiliationsInBusinessLazyQuery()
    affiliationSaleThisMonthLazyQuery({
      variables: {
        affiliation_id: props.match.params.id,
      },
    })
  }, [
    affiliationInBusinessLazyQuery,
    affiliationSaleThisMonthLazyQuery,
    affiliationsInBusinessLazyQuery,
    creatorsInAffiliationLazyQuery,
    props.match.params.id,
  ])

  return (
    <>
      <Meta title={`${affiliationName} 情報詳細`} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}

      <BusinessTwoColumnLayout currentTab="affiliation">
        <AffiliationDetailTpl
          affiliationId={props.match.params.id}
          affiliationName={affiliationName}
          creators={creators}
          sales={sales}
          openAffiliationEditModal={openAffiliationEditModal}
          openCreatorRegistrationModal={openCreatorRegistrationModal}
        />
      </BusinessTwoColumnLayout>
      {affiliationEditModalVisible && (
        <AffiliationEditModalTpl
          closeModal={closeAffiliationEditModal}
          newAffiliationName={newAffiliationName}
          onChangeNewAffiliationName={onChangeNewAffiliationName}
          onClickUpdateAffiliation={onClickUpdateAffiliation}
        />
      )}
      {creatorRegistrationModalVisible && (
        <CreatorRegistrationModalTpl
          affiliations={affiliations}
          newCreatorEmail={newCreatorEmail}
          newCreatorAffiliation={newCreatorAffiliation}
          onChangeNewCreatorEmail={onChangeNewCreatorEmail}
          onChangeNewCreatorAffiliation={onChangeNewCreatorAffiliation}
          onClickCreatorRegistration={onClickCreatorRegistration}
          closeModal={closeCreatorRegistrationModal}
        />
      )}
    </>
  )
}
