import React from 'react'
import styled from 'styled-components'

import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  title?: string
}

const StyledPageTitle = styled.h1`
  font-size: 4.267vw;
  font-weight: bold;
  color: ${(props): string => props.theme.textColors.black};
  letter-spacing: 0.05em;

  ${mediaPc`
    font-size: 24px;
  `}
`

export const PageTitle: React.FC<Props> = ({ className = '', title = '' }) => {
  return <StyledPageTitle className={className}>{title}</StyledPageTitle>
}
