import React from 'react'
import styled from 'styled-components'
import { PageSubtitle } from '../../atoms'
import { ProductRegisterForm } from '../../organisms'
import { Creator } from '../../../types/graphql'
import { RegisterProductInput } from '../../../types/myTypes'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

type Props = {
  creator?: Creator
  product: RegisterProductInput
  onChangeState: (name: string, value: string | number | null) => void
  onClickShowProductThumbnailEditSelectingModal?: () => void
  onClickShowPreview?: () => void
  onClickProductRegister?: () => void
  uploadProductImage: (result: string, file: File) => void
}

const Wrapper = styled.div`
  .title {
    margin-bottom: 3.4vw;
    font-size: 4.27vw;
    font-weight: bold;
  }
  ${mediaPc`
    .title {
      margin:0 0 15px;
      font-size: 16px;
  }
  `}
`

export const ProductRegisterTpl: React.FC<Props> = ({
  creator,
  product,
  onChangeState,
  uploadProductImage,
  onClickShowProductThumbnailEditSelectingModal,
  onClickShowPreview,
  onClickProductRegister,
}) => {
  return (
    <Wrapper>
      <PageSubtitle
        className="title"
        subtitle={userType() === 'business' && creator ? `${creator.name}さん 新しい商品の登録` : '新しい商品の登録'}
      />
      <ProductRegisterForm
        product={product}
        onChangeState={onChangeState}
        uploadProductImage={uploadProductImage}
        onClickShowProductThumbnailEditSelectingModal={onClickShowProductThumbnailEditSelectingModal}
        onClickShowPreview={onClickShowPreview}
        onClickProductRegister={onClickProductRegister}
      />
    </Wrapper>
  )
}
