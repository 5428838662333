import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ProgressBar } from '../../molecules'

type Props = {
  text?: string
  progress: number
}

const Wrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProgressBarTpl: React.FC<Props> = ({ text, progress }) => {
  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'

    return () => {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  })

  return (
    <Wrapper>
      <ProgressBar text={text} progress={progress} />
    </Wrapper>
  )
}
