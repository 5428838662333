import React from 'react'
import styled from 'styled-components'
import { ContentTitle, Selectbox } from '../../atoms'

import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  title?: string
  name?: string
  id?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string | number
  options?: { value: string | number; label: string }[]
}

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-title {
    width: auto;
  }

  .text-button {
    font-size: 2.933vw;
  }

  .content-select-box {
    width: 34.7vw;
    font-size: 2.6vw;
    padding: 0 1.5vw;
  }

  ${mediaPc`
    .text-button {
      font-size: 14px;
    }
    .content-select-box {
      width: 200px;
      font-size: 14px;
      padding: 0 20px 0 10px;
    }
  `}
`

export const ContentTitleWithSelectBox: React.FC<Props> = ({
  className = '',
  title = '',
  name = '',
  id = '',
  onChange = () => {},
  options = [],
  value = '',
}) => {
  return (
    <TitleRow className={className}>
      <ContentTitle title={title} className="content-title" />
      <Selectbox className="content-select-box" name={name} id={id} onChange={onChange} options={options} value={value} />
    </TitleRow>
  )
}
