import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  releasedAt: string
}

const StyledProductPublishedDateLabel = styled.div`
  width: 29.3vw;
  height: 6.9vw;
  border-radius: 4px;
  text-align: center;
  line-height: 2.17;
  font-size: 3.2vw;
  background: ${(props): string => props.theme.buttonColors.gray};
  color: ${(props): string => props.theme.textColors.white};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  ${mediaPc`
    width: 110px;
    height: 26px;
    font-size: 12px;
  `}
`

export const ProductPublishedDateLabel: React.FC<Props> = ({ className = '', releasedAt = '' }) => {
  const publishedDate = moment(releasedAt).format('MM/DD HH:mm')

  return <StyledProductPublishedDateLabel className={className}>{publishedDate}&nbsp;公開</StyledProductPublishedDateLabel>
}
