import React from 'react'
import styled, { DefaultTheme, ThemeProps } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import { PageTitle, Icon } from '../../atoms'
import { Pagination } from '../../molecules'
import { ProductListOnSale } from '../../organisms'
import { PublishedProduct } from '../../../types/graphql'
import productIcon from '../../../assets/images/icon/mountain.png'

type Props = {
  products?: PublishedProduct[]
  currentPage: number
  lastPage: number
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};

  .title-wrapper {
    padding: 5vw 7.2vw;
    & > .inner {
      .title {
        margin-bottom: 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
        .product-icon {
          width: 5vw;
          margin-right: 2vw;
        }
        .page-title {
          letter-spacing: 0.3em;
        }
      }
      .subtitle {
        font-size: 2.1vw;
        text-align: center;
      }
    }
  }

  .content {
    & > .inner {
      width: 100%;
      padding: 0 4.8vw 6vw;
    }
    .no-data {
      padding-top: 5vw;
      text-align: center;
    }
  }

  ${mediaPc`
  .title-wrapper {
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 80px 20px 27px;

    & > .inner {
      width: 1040px;
      margin: 0 auto;
      .title {
        margin-bottom: 7px;
        .product-icon {
          width: 32px;
          margin-right: 18px;
        }
      }
      .subtitle {
        font-size: 12px;
      }
    }
  }

  .content {
      width: 100%;
      min-width: 1040px;
      & > .inner {
        width: 1040px;
        margin: 0 auto;
        padding: 50px 20px 74px;
        .no-data {
          padding-top: 50px;
        }
      }
    }
  `}
`
export const ProductsOnSaleTpl: React.FC<Props> = ({ products, currentPage, lastPage }) => {
  return (
    <Wrapper>
      <div className="title-wrapper">
        <div className="inner">
          <div className="title">
            <Icon src={productIcon} alt="販売中の商品" className="product-icon" />
            <PageTitle title="販売中の商品" className="page-title" />
          </div>
          <p className="subtitle">すべてのクリエイターの商品一覧</p>
        </div>
      </div>
      <div className="content">
        <div className="inner">
          {products && products.length > 0 ? (
            <>
              <div className="product-list">
                <ProductListOnSale products={products} oneLineItemsNum={5} />
              </div>
              <Pagination lastPage={lastPage} currentPage={currentPage} />
            </>
          ) : (
            <div className="no-data">販売中の商品はありません</div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}
