import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { mediaPc } from '../../../lib/MediaQuery'
import { ProductImage, ProductPublishedDateLabel, ProductStockLabel, MovieDuration, ProductChangeStatusButton, Icon } from '../../atoms'
import { CopyLinkWithAlert } from '../../molecules'
import { Product, PublishedProduct, ProductStatus, ProductType } from '../../../types/graphql'
import { ProductListType } from '../../../types/myTypes'
import { config } from '../../../config'
import { userType } from '../../../lib/UserType'

import moment from 'moment'
import liveTalkIcon from '../../../assets/images/icon/live_talk.png'

type Props = {
  productListType: ProductListType
  className?: string
  product: Product | PublishedProduct
  oneLineItemsNum?: number
  onClickToDisabled?: (productId: string) => void
  onClickToEnd?: (productId: string) => void
  onClickToDisplay?: (productId: string) => void
}

const StyledProductListForManagementItem = styled.div<{ oneLineItemsNum: number | undefined }>`
  width: 47%;
  margin-bottom: 6.6vw;
  padding: 0;
  a {
    position: relative;
    display: block;
    color: ${(props): string => props.theme.textColors.white};

    .product-image {
      position: relative;
      margin-bottom: 2.6vw;
      word-break: break-all;

      .movie-duration {
        position: absolute;
        bottom: 15vw;
        left: 6px;
        z-index: 5;
      }

      .live-talk-icon {
        position: absolute;
        width: 16.5vw;
        z-index: 5;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .info {
      width: 100%;
      padding: 2vw;
      position: absolute;
      border-radius: 0 0 4px 4px;
      background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.9) 100%);
      bottom: 0;

      .product-name {
        width: 100%;
        font-size: 3.735vw;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-bottom: 2vw;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }
      .row {
        display: flex;
        justify-content: space-between;
        .price {
          width: 33%;
          margin-right: 2%;
          font-size: 3.2vw;
          font-weight: bold;
          display: inline-block;
          letter-spacing: 0.05em;
          line-height: 3.2vw;
        }
        .released-date {
          width: 61%;
          font-size: 2.4vw;
          display: inline-block;
          letter-spacing: 0.05em;
          text-align: right;
          line-height: 3.2vw;
          color: ${(props): string => props.theme.textColors.placeholder};
        }
      }
    }
  }
  .button-area {
    margin-bottom: 2.6vw;
    display: flex;
    justify-content: space-between;
  }
  .copy-url {
    font-size: 2.935vw;
  }

  ${mediaPc`
    width: 148px;
    margin: 0 0 34px 0;
    &:last-child {
      margin-right: auto;
    }
    a {
      margin-bottom: 10px;
      position: relative;

      .product-image {
        width: 100%;
        height: auto;
        margin-bottom: 0;

        .movie-duration {
          width: 96px;
          height: 28px;
          line-height: 28px;
          background-size: 20px;
          background-position: 5px;
          font-size: 12px;
          position: absolute;
          top: 190px;
          left: 8px;
          padding: 0 5px 0 30px;
        }

        .live-talk-icon {
          width: 62px;
        }
      }

      .info {
        padding: 20px 10px 7px;
        .product-name {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 0;
        }
        .row {
          .price {
            font-size: 10px;
            font-weight:bold;
            width: auto;
            line-height: 1.6;
          }
          .released-date {
            width: auto;
            font-size: 10px;
            line-height: 1.6;
          }
        }
      }
    }
    .button-area {
      margin-bottom: 10px;
    }
    .copy-url {
      font-size: 11px;
    }
  `}
  ${(props) =>
    props.oneLineItemsNum !== undefined &&
    css`
      ${mediaPc`
        margin: 0 calc( (100% - (148px * ${props.oneLineItemsNum}) ) / (${props.oneLineItemsNum} - 1) ) 48px 0;
        &:nth-child(${props.oneLineItemsNum}n) {
          margin-right: 0;
        }
        &:last-child {
          margin-right: auto;
        }
      `}
    `}
`

export const ProductListForManagementItem: React.FC<Props> = ({
  productListType,
  className = '',
  product,
  oneLineItemsNum,
  onClickToDisabled,
  onClickToEnd,
  onClickToDisplay,
}) => {
  return (
    <StyledProductListForManagementItem className={className} oneLineItemsNum={oneLineItemsNum}>
      <Link to={userType() === 'business' ? `/${userType()}/creator/product/${product.id}` : `/creator/product/${product.id}`}>
        <div className="product-image">
          <ProductImage src={product.thumbnail_image} />
          {productListType === 'reserved' ? (
            <ProductPublishedDateLabel releasedAt={product.released_at} />
          ) : (
            <ProductStockLabel
              status={product.status}
              stock={product.remaining_stock}
              type={product.product_type}
              className="stock-label"
            />
          )}
          {product.product_type === ProductType.Movie && <MovieDuration className="movie-duration" duration={product.movie_seconds} />}
          {product.product_type === ProductType.LiveTalk && <Icon className="live-talk-icon" src={liveTalkIcon} alt="ライブトーク" />}
        </div>
        <div className="info">
          <div className="product-name">{product.name}</div>
          <div className="row">
            <p className="price">¥{product.price.toLocaleString()}</p>
            <p className="released-date">{moment(product.released_at).format('YYYY-MM-DD')} 公開</p>
          </div>
        </div>
      </Link>
      <div className="button-area">
        <ProductChangeStatusButton
          className="finish-button"
          onClick={onClickToEnd ? () => onClickToEnd(product.id) : undefined}
          buttonType="toEnd"
          disabled={product.status !== ProductStatus.Sale || product.remaining_stock === 0 ? true : false}
        />
        {productListType !== 'disabled' && (
          <>
            <ProductChangeStatusButton
              className="disable-button"
              onClick={onClickToDisabled ? () => onClickToDisabled(product.id) : undefined}
              buttonType="toDisabled"
            />
          </>
        )}
        {productListType === 'disabled' && (
          <>
            <ProductChangeStatusButton
              className="disable-button"
              onClick={onClickToDisplay ? () => onClickToDisplay(product.id) : undefined}
              buttonType="toDisplay"
            />
          </>
        )}
      </div>

      {productListType === 'published' && (
        <CopyLinkWithAlert
          className="copy-url"
          copyTarget={`${config.userFrontEndpoint}/product/${product.id} #p4you`}
          alertText="シェア用のURLをコピーしました"
        >
          シェア用のURLをコピー
        </CopyLinkWithAlert>
      )}
    </StyledProductListForManagementItem>
  )
}
