import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorRegistrationTpl, CreatorIconTrimmingModalTpl, CreatorRegistrationExpiredTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'

import { FlashMessageType, CreatorRegisterAccountInput, CreatorRegisterErrors } from '../../../types/myTypes'
import {
  useCurrentCreatorLazyQuery,
  useLoginCreatorMutation,
  useRequestCreatorLazyQuery,
  useUpdateCreatorAccountByTokenMutation,
} from '../../../types/graphql'
import {
  validateProfile,
  validateAtSignUserName,
  validateUrl,
  validateRequired,
  validatePassword,
  validatePasswordAndPasswordConfirm,
  validatePasswordConfirm,
  validateImage,
} from '../../../lib/Validate'
import { useCookies } from 'react-cookie'
import { CreatorProfileContext, CreatorTokenContext } from '../../../context'

type Props = {} & RouteComponentProps<{ id: string }>

export const CreatorRegistration: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<CreatorRegisterAccountInput>()
  const [iconFileName, setIconFileName] = useState<string>('')
  const [iconUrl, setIconUrl] = useState<string>('')
  const [iconBeforeTrimming, setIconBeforeTrimming] = useState<string>('')
  const [iconFileAfterTrimming, setIconFileAfterTrimming] = useState<File | null>(null)
  const [errorItems, setErrorItems] = useState<CreatorRegisterErrors>({
    icon_url: null,
    name: null,
    password: null,
    password_confirm: null,
    password_match: null,
    profile: null,
    twitter_username: null,
    instagram_username: null,
    youtube_channel_url: null,
    kindle_author_url: null,
  })
  const [token, setToken] = useState<string>('')
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [expired, setExpired] = useState<boolean>(false)

  const history = useHistory()
  const expires = Number(new URLSearchParams(props.location.search).get('expires'))
  const signature = new URLSearchParams(props.location.search).get('signature') ?? ''

  const { updateCreator } = useContext(CreatorProfileContext)
  const { updateCreatorToken } = useContext(CreatorTokenContext)

  const [, setCookie] = useCookies(['token'])

  const [loginCreatorMutation] = useLoginCreatorMutation({
    onCompleted: (data) => {
      const token = data?.LoginCreator?.token ?? ''
      setToken(token)
      updateCreatorToken(token)
      setCookie('creatorToken', token, { path: '/' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'ログインできませんでした' })
      }
    },
  })

  const [currentCreatorLazyQuery] = useCurrentCreatorLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.CurrentCreator) {
        updateCreator(data.CurrentCreator)
      }
      history.push({ pathname: `/creator/mypage`, state: { registerComplete: true } })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'ログインできませんでした' })
      }
    },
  })

  const [requestCreatorLazyQuery] = useRequestCreatorLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setCreator({ ...data.RequestCreator, expires, signature, creator_id: props.match.params.id, name: '', password: '', icon: null })
      }
    },
    onError: (e) => {
      setLoading(false)
      if (e.message) {
        setFlashMessage({ type: 'systemError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: e.message })
      }
      Sentry.captureException(e)
    },
  })

  const [updateCreatorAccountByTokenMutation] = useUpdateCreatorAccountByTokenMutation({
    onCompleted: (data) => {
      if (creator) {
        loginCreatorMutation({
          variables: {
            input: {
              email: data.UpdateCreatorAccountByToken.email,
              password: creator.password,
            },
          },
        })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const validate = (name: string, value: string): string | null => {
    switch (name) {
      case 'name':
        return validateRequired(value) !== null ? `クリエイター名は${validateRequired(value)}` : null
      case 'profile':
        return validateProfile(value)
      case 'password':
        return validatePasswordAndPasswordConfirm(value, creator?.password_confirm || '')
      case 'password_confirm':
        return validatePasswordConfirm(value)
      case 'twitter_username':
        return validateAtSignUserName(value) !== null ? `twitter ユーザー名は${validateAtSignUserName(value)}` : null
      case 'instagram_username':
        return validateAtSignUserName(value) !== null ? `instagram ユーザー名は${validateAtSignUserName(value)}` : null
      case 'youtube_channel_url':
        return validateUrl(value) !== null ? `YouTube チャンネル URLは${validateUrl(value)}` : null
      case 'kindle_author_url':
        return validateUrl(value) !== null ? `Kindle販売ページ URLは${validateUrl(value)}` : null
      default:
        return ''
    }
  }

  const onChangeForm = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value
    const name = event.target.name
    setCreator(creator ? { ...creator, [name]: value } : creator)
    if (name === 'password') {
      const errorText: string | null = validatePassword(event.target.value)
      const matchErrorText: string | null = validatePasswordAndPasswordConfirm(event.target.value, creator?.password_confirm || '')
      setErrorItems({ ...errorItems, password: errorText, password_match: matchErrorText })
    } else if (name === 'password_confirm') {
      const errorText: string | null = validatePasswordConfirm(event.target.value)
      const matchErrorText: string | null = validatePasswordAndPasswordConfirm(creator?.password || '', event.target.value)
      setErrorItems({ ...errorItems, password_confirm: errorText, password_match: matchErrorText })
    } else {
      const errorText: string | null = validate(name, value)
      setErrorItems({ ...errorItems, [name]: errorText })
    }
  }

  const onIconLoad = (result: string, file: File): void => {
    setIconBeforeTrimming(result)
    setIconFileName(file.name)
    setModalVisible(true)
  }

  const onChangeIcon = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null && event.target.files[0] !== undefined) {
      const file = event.target.files[0]
      const errorText: string | null = validateImage(file)
      if (errorText === null) {
        const objectUrl = URL.createObjectURL(file)
        onIconLoad(objectUrl, file)
      }
      setErrorItems({ ...errorItems, icon_url: errorText })
    }
  }

  const closeModal = (): void => {
    setModalVisible(false)
    setIconBeforeTrimming('')
  }

  const registerCreator = (): void => {
    setLoading(true)
    setFlashMessage(null)
    updateCreatorAccountByTokenMutation({
      variables: {
        input: {
          expires,
          signature,
          creator_id: props.match.params.id,
          name: creator?.name ?? '',
          profile: creator?.profile,
          password: creator?.password ?? '',
          twitter_username: creator?.twitter_username,
          instagram_username: creator?.instagram_username,
          youtube_channel_url: creator?.youtube_channel_url,
          kindle_author_url: creator?.kindle_author_url,
          icon: iconFileAfterTrimming,
        },
      },
    })
  }

  useEffect(() => {
    if (expires < Math.floor(new Date().getTime() / 1000)) {
      setExpired(true)
      setLoading(false)
    } else {
      requestCreatorLazyQuery({
        variables: {
          creator_id: props.match.params.id,
          input: {
            expires,
            signature,
          },
        },
      })
    }
  }, [expires, props.match.params.id, requestCreatorLazyQuery, signature])

  useEffect(() => {
    if (token) {
      currentCreatorLazyQuery()
    }
  }, [currentCreatorLazyQuery, token])

  return (
    <>
      <Meta title="新規登録" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}

      {expired && <CreatorRegistrationExpiredTpl />}

      {creator && !expired && (
        <CreatorRegistrationTpl
          creator={creator}
          iconUrl={iconUrl}
          errorItems={errorItems}
          onChangeForm={onChangeForm}
          onChangeIcon={onChangeIcon}
          registerCreator={registerCreator}
        />
      )}
      {modalVisible && !expired && (
        <CreatorIconTrimmingModalTpl
          src={iconBeforeTrimming}
          iconFileName={iconFileName}
          closeModal={closeModal}
          setIconUrlAfterTrimming={(url: string) => {
            setIconUrl(url)
          }}
          setIconFileAfterTrimming={(file: File) => {
            setIconFileAfterTrimming(file)
          }}
        />
      )}
    </>
  )
}
