export const userType = (): string => {
  const path = window.location.host
  let type

  if (path.match('business') !== null) {
    type = 'business'
  } else if (path.match('creator') !== null) {
    type = 'creator'
  } else {
    type = 'user'
  }
  return type
}
