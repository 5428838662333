import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageSubtitle } from '../../atoms'
import { SubtitleWithLink } from '../../molecules'
import { OrderInfo, OrderDelivery } from '../../organisms'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'
import { OrderedProduct } from '../../../types/graphql'

type Props = {
  order: OrderedProduct
  uploadedProductUrl: string
  uploadProduct: (file: File, url: string, type: 'photo' | 'movie') => void
  showDeliveryDialog: () => void
  goToLiveTalk: () => void
}

const Wrapper = styled.div`
  width: 100%;
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }

  .order-info {
    margin-bottom: 7.4vw;
  }

  .order-delivery {
    margin-bottom: 29.6vw;
  }

  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title {
      margin-bottom: 35px;
    }

    .order-info,
    .order-delivery {
      margin-bottom: 25px;
    }
  `}
`

export const ProductDeliveryTpl: React.FC<Props> = ({ order, uploadedProductUrl, uploadProduct, showDeliveryDialog, goToLiveTalk }) => {
  const history = useHistory()

  const goToCreatorDetail = (): void => {
    history.push(`/business/creator/${order.product?.creator_id}`)
  }
  const subtitle = `${order.creator.name}さん 注文情報`

  return (
    <Wrapper>
      {userType() === 'business' && (
        <SubtitleWithLink className="title" subtitle={subtitle} linkText="クリエイター情報詳細へ戻る >" onClick={goToCreatorDetail} />
      )}
      {userType() === 'creator' && <PageSubtitle className="title" subtitle="注文情報" />}
      <OrderInfo orderedProduct={order} className="order-info" />
      <OrderDelivery
        order={order}
        uploadedProductUrl={uploadedProductUrl}
        uploadProduct={uploadProduct}
        showDeliveryDialog={showDeliveryDialog}
        goToLiveTalk={goToLiveTalk}
        className="order-delivery"
      />
    </Wrapper>
  )
}
