import React from 'react'
import styled from 'styled-components'
import { convertToVw } from '../../lib/ConvertSize'
import { mediaPc } from '../../lib/MediaQuery'

import twitterLink from '../../assets/images/icon/twitter_link.png'
import twitterGray from '../../assets/images/icon/twitter_gray.png'
import twitterWhite from '../../assets/images/icon/twitter_white.png'

import instagramLink from '../../assets/images/icon/instagram_link.png'
import instagramGray from '../../assets/images/icon/instagram_gray.png'
import instagramWhite from '../../assets/images/icon/instagram_white.png'

import youtubeLink from '../../assets/images/icon/youtube_link.png'
import youtubeGray from '../../assets/images/icon/youtube_gray.png'
import youtubeWhite from '../../assets/images/icon/youtube_white.png'

import kindleLink from '../../assets/images/icon/amazon_link.png'
import kindleGray from '../../assets/images/icon/amazon_gray.png'
import kindleWhite from '../../assets/images/icon/amazon_white.png'

// Footer用
import twitterFooter from '../../assets/images/icon/twitter.png'
import instagramFooter from '../../assets/images/icon/instagram.png'
import youtubeFooter from '../../assets/images/icon/youtube.png'
import facebookFooter from '../../assets/images/icon/facebook.png'

type Props = {
  className?: string
  disabled?: boolean
  sns: 'twitter' | 'instagram' | 'youtube' | 'kindle' | 'facebook'
  type?: 'white' | 'disabled' | 'footer'
}

const StyledIcon = styled.img`
  width: ${convertToVw('36px')};
  height: ${convertToVw('36px')};
  transition: 0.3s all;
  border-radius: 50%;

  ${mediaPc`
    width: 36px;
    height: 36px;
  `}
`

const icon: { [key: string]: { [key: string]: string } } = {
  twitter: {
    white: twitterWhite,
    disabled: twitterGray,
    link: twitterLink,
    footer: twitterFooter,
  },
  instagram: {
    white: instagramWhite,
    disabled: instagramGray,
    link: instagramLink,
    footer: instagramFooter,
  },
  youtube: {
    white: youtubeWhite,
    disabled: youtubeGray,
    link: youtubeLink,
    footer: youtubeFooter,
  },
  kindle: {
    white: kindleWhite,
    disabled: kindleGray,
    link: kindleLink,
  },
  facebook: {
    footer: facebookFooter,
  },
}

export const SnsIcon: React.FC<Props> = (props) => {
  const type = props.type ?? 'link'
  return <StyledIcon src={icon[props.sns][type]} alt={props.sns} className={props.className} />
}
