import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Button } from '../../../atoms'
import { mediaPc } from '../../../../lib/MediaQuery'
import { CREATOR_REGISTRATION_URL } from '../../../../const'

const Wrapper = styled.div`
  .title {
    margin-bottom: 4.8vw;

    h2 {
      width: 100%;
      font-size: 3.2vw;
      font-weight: bold;
      color: #222;
      letter-spacing: 0.05em;
    }
  }
  p {
    width: 100%;
    margin-bottom: 4.8vw;
    font-size: 3.2vw;
    color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    letter-spacing: 0.05em;
  }
  ${mediaPc`
    .title {
      margin-bottom: 50px;
      h2 {
        font-size: 16px;
      }
    }
    p {
      margin-bottom: 50px;
      font-size: 13px;
    }
  `}
`

export const CreatorLoginRegister: React.FC = () => {
  const goToRegister = (): void => {
    window.open(CREATOR_REGISTRATION_URL, '_blank')
  }

  return (
    <Wrapper>
      <div className="title">
        <h2>クリエイター新規登録（販売者）</h2>
      </div>
      <p>
        アカウントをお持ちでない方はこちらから作成をお願いいたします
        <br />
        <br />
        現在、無料で登録可能！！
        <br />
        その他、ノルマ、初期費用等もございません
        <br />
        お気軽に登録していただき、お好きなタイミングで販売してください！！
      </p>
      <Button buttonType="black" onClick={(): void => goToRegister()}>
        新規登録はこちら
      </Button>
    </Wrapper>
  )
}
