import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import selectTriangleGreenImg from '../../assets/images/icon/select_triangle_green.png'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  name?: string
  id?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string | number
  options?: { value: string | number; label: string }[]
}

const StyledSelect = styled.select`
  width: 100%;
  height: 12.3vw;
  padding: 0 4.5vw;
  display: block;
  border-radius: 4px;
  color: ${(props): string => props.theme.textColors.placeholder};
  font-size: 3.2vw;
  line-height: 3.83;
  font-weight: 500;
  letter-spacing: 0.05em;
  position: relative;
  background: url(${selectTriangleGreenImg}) 96% center no-repeat ${(props): string => props.theme.backgroundColors.bodyBg};
  background-size: 3.7vw;
  &.selected {
    color: ${(props): string => props.theme.textColors.black};
    font-weight: bold;
  }

  ${mediaPc`
    height: auto;
    padding: 0 20px 0 10px;
    font-size: 14px;
    font-weight: normal;
    line-height: 36px;
    background-size: 10px;
    background-position: right 13px center;
    border: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.black};

    &.selected {
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.gray};
      font-weight: normal;
    }
  `}
`

export const Selectbox: React.FC<Props> = ({ className = '', name = '', id = '', onChange = () => {}, value = '', options = [] }) => {
  return (
    <StyledSelect className={value === '' ? className : `selected ${className}`} name={name} id={id} onChange={onChange} value={value}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  )
}
