import React from 'react'
import styled from 'styled-components'
import { SelectboxWithLabel } from '../../../molecules'
import { mediaPc } from '../../../../lib/MediaQuery'
import { Affiliation, Creator } from '../../../../types/graphql'
import moment from 'moment'

type Props = {
  className?: string
  affiliations: Affiliation[]
  months: string[]
  creators?: Creator[]
  selectedAffiliation: string
  selectedMonth: string
  selectedCreator?: string
  displayCreatorSelectbox?: boolean
  onSelectAffiliation: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onSelectMonth: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onSelectCreator?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  .csv-export-select {
    margin-bottom: 4vw;
  }

  ${mediaPc`
    .csv-export-select {
      width: 240px;
      margin: 0 auto 30px;
    }
  `}
`

export const CsvExportSelectbox: React.FC<Props> = ({
  className = '',
  affiliations,
  months,
  creators,
  selectedAffiliation,
  selectedMonth,
  selectedCreator,
  displayCreatorSelectbox = true,
  onSelectAffiliation,
  onSelectMonth,
  onSelectCreator,
}) => {
  const affiliationOptions: { value: string; label: string }[] = [
    { value: '', label: '選択してください' },
    { value: '0', label: '所属なし' },
  ]
  affiliations.forEach((affiliation) => {
    affiliationOptions.push({ value: affiliation.id, label: affiliation.name })
  })

  const monthOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  months.forEach((month) => {
    monthOptions.push({ value: month, label: moment(month).format('YYYY年MM月') })
  })

  const creatorOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  if (creators) {
    creators.forEach((creator) => {
      creatorOptions.push({ value: creator.id, label: creator.name })
    })
  }

  return (
    <Wrapper className={className}>
      <SelectboxWithLabel
        label="所属"
        options={affiliationOptions}
        value={selectedAffiliation}
        onChange={onSelectAffiliation}
        className="csv-export-select"
      />
      <SelectboxWithLabel
        label="年月"
        options={monthOptions}
        value={selectedMonth}
        onChange={onSelectMonth}
        className="csv-export-select"
      />
      {displayCreatorSelectbox && (
        <SelectboxWithLabel
          label="クリエイター"
          options={creatorOptions}
          value={selectedCreator}
          onChange={onSelectCreator}
          className="csv-export-select"
        />
      )}
    </Wrapper>
  )
}
