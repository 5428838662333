import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { LinkButton } from '../../atoms'
import { Creator, CreatorAccountStatus } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { omitName } from '../../../lib/OmitName'

type Props = {
  className?: string
  creators: Creator[]
  displayAffiliation?: boolean
}

const Wrapper = styled.div`
  .creator {
    justify-content: flex-start;

    .request {
      color: ${(props): string => props.theme.textColors.red};
    }

    .affiliation {
      font-size: inherit;
      font-weight: inherit;
    }

    & + .creator {
      margin-top: 2.7vw;
    }
  }

  ${mediaPc`
    .creator {
      & + .creator {
        margin-top: 10px;
      }
    }
  `}
`

export const CreatorList: React.FC<Props> = ({ className = '', creators = [], displayAffiliation = false }) => {
  const history = useHistory()
  const goToCreatorDetail = (id: string): void => {
    history.push(`/business/creator/${id}`)
  }
  return (
    <Wrapper className={className}>
      {creators?.map((creator) => (
        <LinkButton key={creator.id} className="creator" onClick={() => goToCreatorDetail(creator.id)}>
          {creator.status === CreatorAccountStatus.Request && <span className="request">登録中：</span>}
          {creator.status === CreatorAccountStatus.Request ? omitName(creator.email, 10) : omitName(creator.name, 10)}
          {displayAffiliation && (
            <span className="affiliation">{creator.affiliation?.name ? `(${creator.affiliation?.name})` : '(所属なし)'}</span>
          )}
        </LinkButton>
      ))}
    </Wrapper>
  )
}
