import moment from 'moment'

export const validateEmail = (email: string): string | null => {
  const re = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
  let message = null

  if (!email) {
    message = 'メールアドレスを入力してください'
  } else if (!re.test(email)) {
    message = 'メールアドレスの形式ではありません'
  }
  return message
}

export const validatePassword = (password: string): string | null => {
  let message = null

  if (!password) {
    message = 'パスワードを入力してください'
  } else if (password.length < 8) {
    message = 'パスワードは8桁以上で入力してください'
  }

  return message
}

export const validatePasswordConfirm = (passwordConfirm: string): string | null => {
  let message = null

  if (!passwordConfirm) {
    message = '確認用のパスワードを入力してください'
  }
  return message
}

export const validatePasswordAndPasswordConfirm = (password: string, passwordConfirm?: string): string | null => {
  let message = null

  if (!!password && !!passwordConfirm && password !== passwordConfirm) {
    message = 'パスワードと確認用のパスワードが合致しません'
  }
  return message
}

export const validateTwitterUserName = (userName: string): string | null => {
  let message = null

  if (userName.match(/@/)) {
    message = '@マーク以降を入力してください'
  }
  return message
}

export const validateAtSignUserName = (userName: string): string | null => {
  let message = null

  if (userName.match(/@/)) {
    message = '@マーク以降を入力してください'
  }
  return message
}

export const validateUrl = (url: string): string | null => {
  const re = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.?%&=]*)?/
  let message = null
  if (url !== '' && !re.test(url)) {
    message = '有効なURL形式で指定してください'
  }
  return message
}

export const validateRequired = (value: string): string | null => {
  let message = null

  if (value === '') {
    message = '必須項目です'
  }
  return message
}

export const validateImage = (file: File): string | null => {
  const size = file.size,
    type = file.type
  let message = null

  const limit = 5
  // 拡張子は .jpg .gif .png のみ許可
  if (type !== 'image/jpeg' && type !== 'image/gif' && type !== 'image/png') {
    message = '.jpg、.gif、.pngのいずれかのファイルを選択してください'
  } else if (size > limit * 1024 * 1024) {
    message = `ファイルの上限サイズ${limit}MBを超えています`
  }
  return message
}

export const validateProductName = (name: string): string | null => {
  let message = null

  if (name.length > 40) {
    message = '40文字以内で設定してください'
  }
  return message
}

export const validateReleasedAt = (date: string): string | null => {
  let message = null

  const currentDate = moment().format('YYYY-MM-DD HH:mm')
  const oneWeekLater = moment().add(7, 'days').endOf('day').format('YYYY-MM-DD HH:mm')
  if (date <= currentDate) {
    message = '公開日時は現時刻以降で設定してください'
  } else if (date >= oneWeekLater) {
    message = '公開日時は一週間以内で設定してください'
  }
  return message
}

export const validateProfile = (name: string): string | null => {
  let message = null

  if (name.length > 200) {
    message = 'プロフィールは200文字以内で設定してください'
  }
  return message
}
