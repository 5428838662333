import React, { useState } from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'
import useInterval from 'use-interval'
import { useEffect } from 'react'

type Props = {
  className?: string
  talkSeconds: number
}

const StyledCountDownTimer = styled.div`
  font-size: 3.2vw;
  line-height: 9vw;
  width: 25vw;
  height: 9vw;
  text-align: center;
  border-radius: 4.5vw;
  color: ${(props): string => props.theme.textColors.white};
  background-color: rgba(34, 34, 34, 0.4);
  font-weight: bold;
  ${mediaPc`
    font-size: 16px;
    line-height: 40px;
    width:130px;
    height:40px;
    border-radius: 20px;
  `}
`

export const LiveTalkCountDownTimer: React.FC<Props> = ({ className = '', talkSeconds }) => {
  const [leftMinutes, setLeftMinutes] = useState<number>(0)
  const [leftSeconds, setLeftSeconds] = useState<number>(0)

  useEffect(() => {
    setLeftMinutes(Math.trunc(talkSeconds / 60))
    setLeftSeconds(talkSeconds % 60)
  }, [talkSeconds])

  useInterval(() => {
    if (leftMinutes === 0 && leftSeconds === 0) return

    if (leftSeconds > 0) {
      setLeftSeconds(leftSeconds - 1)
    }
    if (leftSeconds === 0) {
      setLeftMinutes(leftMinutes - 1)
      setLeftSeconds(59)
    }
  }, 1000)

  return (
    <StyledCountDownTimer className={className}>
      残り {('00' + leftMinutes).slice(-2)}:{('00' + leftSeconds).slice(-2)}
    </StyledCountDownTimer>
  )
}
