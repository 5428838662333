import React from 'react'
import styled from 'styled-components'
import { TextButton, PageSubtitle } from '../../atoms'

type Props = {
  className?: string
  subtitle?: string
  linkText?: string
  textType?: 'red'
  onClick?: () => void
}

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .subtitle {
    width: auto;
  }
`

export const SubtitleWithLink: React.FC<Props> = ({ className = '', subtitle = '', linkText, textType, onClick }) => {
  return (
    <TitleRow className={className}>
      <PageSubtitle subtitle={subtitle} className="subtitle" />
      <TextButton onClick={onClick} textType={textType}>
        {linkText}
      </TextButton>
    </TitleRow>
  )
}
