import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Button } from '../../../atoms'
import { mediaPc } from '../../../../lib/MediaQuery'
import { CONTACT_URL } from '../../../../const'

const Wrapper = styled.div`
  padding: 13.3vw 0 39.47vw;
  background: ${(props): string => props.theme.backgroundColors.gray};
    
  .inner {
    width: 85.3vw;
    margin 0 auto;
        
    >p {
      text-align: center;
      font-size: 3.733vw;
      letter-spacing: 0.05em;
    }
        
    .caution{
      font-weight: bold;
      line-height: 1.14;
      color: ${(props): string => props.theme.textColors.red};
      margin-bottom: 2.66vw;
    }
        
    .information{
      font-weight: 500;
      line-height: 1.7;
      margin-bottom: 10.66vw;
      color: ${(props): string => props.theme.textColors.gray};
    }
  }
    
  ${mediaPc`
    padding: 110px 0 413px;
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
        
    .inner {
      width: 503px;
             
      >p {
        font-size: 14px;
      }
            
      .caution {
        margin-bottom: 10px;
      }
       
      .information {
        line-height: 1.14;
        margin-bottom: 60px;
      }
    }
    
    .contact-button{
      display: block;
      margin: 0 auto;
      width: 320px;
    }
  `}
`

const goToContactPage = (): void => {
  window.open(CONTACT_URL, '_blank', 'noreferrer')
}

export const CreatorRegistrationExpiredTpl: React.FC = () => {
  return (
    <Wrapper>
      <div className="inner">
        <p className="caution">このURLは有効期限切れです。</p>
        <p className="information">
          お手数ですが、お問い合わせから
          <br className="sp" />
          アカウント再発行の申請をしてください。
        </p>
        <Button buttonType="primary" className="contact-button" onClick={goToContactPage}>
          お問い合わせ
        </Button>
      </div>
    </Wrapper>
  )
}
