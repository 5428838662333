import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { AffiliationDetail, AffiliationCreators, AffiliationSaleThisMonth } from '../../../organisms'
import { Creator, SalesPerMonth } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  affiliationId: string
  affiliationName: string
  creators: Creator[]
  sales: SalesPerMonth[]
  openAffiliationEditModal: () => void
  openCreatorRegistrationModal: () => void
}

const Wrapper = styled.div`
  background: ${(props): string => props.theme.backgroundColors.gray};

  .affiliation-detail {
    margin-bottom: 7.7vw;
  }

  .affiliation-creators {
    margin-bottom: 8.5vw;
  }

  ${mediaPc`
    width: 100%;
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

    .affiliation-detail {
      margin-bottom: 20px;
    }
  
    .affiliation-creators {
      margin-bottom: 30px;
    }
  `}
`

export const AffiliationDetailTpl: React.FC<Props> = ({
  affiliationId,
  affiliationName,
  creators,
  sales,
  openAffiliationEditModal,
  openCreatorRegistrationModal,
}) => {
  return (
    <Wrapper>
      <AffiliationDetail
        affiliationName={affiliationName}
        openAffiliationEditModal={openAffiliationEditModal}
        className="affiliation-detail"
      />
      <AffiliationCreators
        creators={creators}
        openCreatorRegistrationModal={openCreatorRegistrationModal}
        className="affiliation-creators"
      />
      <AffiliationSaleThisMonth affiliationId={affiliationId} sales={sales} />
    </Wrapper>
  )
}
