import React from 'react'
import styled from 'styled-components'
import { PageSubtitle } from '../../../atoms'
import { AffiliationSalesPerMonth } from '../../../organisms'
import { Affiliation, SalesPerMonth } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  affiliationId?: string
  selectedAffiliation: string
  affiliations: Affiliation[]
  sales: SalesPerMonth[]
  onAffiliationChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  .title {
    width: 100%;
    margin-bottom: 4.8vw;
  }

  ${mediaPc`
    .title {
      font-size: 16px;
      margin-bottom: 17px;
    }
  `}
`

export const AffiliationSalesPerMonthTpl: React.FC<Props> = ({
  affiliationId,
  selectedAffiliation,
  affiliations,
  sales,
  onAffiliationChange,
}) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle="売上一覧" />
      <AffiliationSalesPerMonth
        affiliationId={affiliationId}
        selectedAffiliation={selectedAffiliation}
        affiliations={affiliations}
        sales={sales}
        onAffiliationChange={onAffiliationChange}
      />
    </Wrapper>
  )
}
