import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button, PageTitle, Icon } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

import passwordIcon from '../../../assets/images/icon/icon_password.png'

type Props = {}

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props): string => props.theme.backgroundColors.gray};
  padding: 7.7vw 7.2vw 51vw;
  text-align: center;
  .title {
    display: flex;
    justify-content: center;
    .icon {
      width: 4.27vw;
      margin-right: 1.4vw;
    }
    .terms-title-text {
      margin-left: 17px;
      font-size: 24px;
      line-height: 1.33;
      text-align: center;
    }
  }
  .page-title {
    margin: 0 4.2vw 5.3vw 0;
  }
  .lead-text {
    margin-bottom: 5.1vw;
    color: ${(props): string => props.theme.textColors.black};
    text-align: center;
    font-weight: 400;
  }
  .button {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 4px;
    font-size: 3.733vw;
    font-weight: bold;
    line-height: 3.3em;
    text-align: center;
    cursor: pointer;
  }
  ${mediaPc`
    width: 100%;
    background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 72px 0px 190px;
    .inner {
      margin-top:50px;
      padding: 69px 0 93px;
      background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
    }
    .title {
      .icon {
        width :21px;
        margin-right:0;
      }
    }
    .page-title {
      margin: 0 0 0 14px;
    }
    .lead-text {
      font-size: 14px;
      margin-bottom: 31px;
      font-weight: bold;
    }
    .button {
      width: 320px;
      height: 46px;
      line-height: 46px;
      margin: 0px auto 0px;
      font-size: 14px;
      font-weight: bold;
    }
  `}
`

export const PasswordRenewComplete: React.FC<Props> = () => {
  const history = useHistory()

  const goLoginPage = (): void => {
    if (userType() === 'creator') {
      history.push('/creator/login')
    } else if (userType() === 'business') {
      history.push('/business/login')
    } else {
      history.push('/login')
    }
  }
  return (
    <Wrapper>
      <div className="title">
        <div className="icon">
          <Icon src={passwordIcon} alt="パスワード再設定" />
        </div>
        <PageTitle className="page-title" title="パスワード再設定" />
      </div>
      <div className="inner">
        <p className="lead-text">パスワードの再設定が完了しました</p>
        <Button className="button" onClick={(): void => goLoginPage()} buttonType="primary">
          ログイン画面へ
        </Button>
      </div>
    </Wrapper>
  )
}
