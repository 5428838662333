import React, { useContext } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button, TextButton, Icon } from '../../atoms'
import { ProfileItem, SnsList, SubtitleWithLink, CopyLinkWithAlert } from '../../molecules'
import defaultIcon from '../../../assets/images/icon/man_icon_default.png'
import { mediaPc } from '../../../lib/MediaQuery'
import { useCreatorLogout } from '../../../lib/Logout'
import { userType } from '../../../lib/UserType'
import { config } from '../../../config'
import { CREATOR_BANK_ACCOUNT_REGISTRATION_URL } from '../../../const'

import { CreatorProfileContext } from '../../../context'

type Props = {
  isVisibleEditButton?: boolean
  className?: string
}

const Wrapper = styled.div`
  .title {
    margin-bottom: 2vw;
  }
  .inner {
    margin-bottom: 8.5vw;
    border-radius: 4px;
    padding: 5.3vw;
    background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
    .icon {
      border-radius: 50%;
      width: 26.6vw;
      height: 26.6vw;
      object-fit: cover;
    }
    .creator-info {
      width: 100%;
      & > dd {
        border: none;
        line-height: 1.6;
      }
    }
    .creator-url {
      & > dt {
        margin-bottom: 1.3vw;
      }
      & > dd {
        line-height: 1.3;

        .url {
          margin-bottom: 1.3vw;
        }
      }
    }
    .creator-affiliation {
      & > dd {
        font-size: 3.2vw;
        font-weight: bold;
      }
    }
    .creator-profile {
      & > dd {
        line-height: 1.6;
        font-weight: 500;
        font-size: 3.2vw;
      }
    }
    .edit {
      margin-top: 5vw;
      line-height: 2.4;
    }
    .creator-page.sp {
      width: 100%;
      margin-top: 2.6vw;
      font-size: 3.46vw;
      font-weight: 700;
      text-align: right;
    }
    .creator-url dd {
      margin-bottom: 7.47vw;
    }
    .bank-account-notice-div {
      margin-bottom: 2.2vw;
    }
    .bank-account-notice-headline {
      font-size: 3.2vw;
      font-weight: 500;
      line-height: 2;
      color: ${(props): string => props.theme.textColors.black};
      letter-spacing: 0.05em;
      & > a {
        font-size: 3.47vw;
        line-height: 1.15;
        margin-left: 0.27vw;
        color: ${(props): string => props.theme.textColors.primary};
      }
    }
    .bank-account-notice-message {
      font-size: 2.93vw;
      line-height: 1.5;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.red};
    }
  }
  ${mediaPc`
    margin: 41px 0 0;
    .inner {
      color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
      width: 240px;
      padding: 1px 15px 20px;
      background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
      .icon {
        width: 120px;
        height: 120px;
        margin: -30px auto 10px;
      }
      .creator-info {
        & > dd {
          width: 100%;
          padding: 5px 0 20px;
          margin:0;
          line-height: 16px;
        }
      }
      .creator-name {
        & > dt {
          display:none;
        }
        & > dd {
          text-align:center;
          font-weight: bold;
          padding: 5px 0 30px;
          margin: 0 auto;
          line-height: 16px;
        }
      }
      .creator-email,.creator-affiliation {
        & > dd {
          font-weight: bold;
          font-size: 12px;
        }
      }
      .creator-profile {
        & > dt {
          display:none;
        }
        & > dd {
          width: 100%;
          font-size: 13px;
          line-height: 24px;
          padding-top:0;
        }
      }
      .creator-sns {
        & > dt {
          display:none;
        }
        > dd {
          width: 100%;
          padding-top: 0;
          margin:0;
        }
      }
      .creator-url {
        & > dt {
          margin: 0;
        }
        & > dd {
         font-size: 14px;
         padding: 5px 0 40px;

         .url {
           margin-bottom: 8px;
         }
       }
      }
      .edit {
        margin:0 auto 15px;
        font-size: 12px;
        line-height: 30px;
      }
      .logout {
        width: 50%;
        margin: 0 auto;
        font-weight: bold;
        line-height: 14px;
        font-size: 12px;
      }
      .creator-url dd {
        padding-bottom: 30px;
      }
      .bank-account-notice-div {
        margin-bottom: 36px;
      }
      .bank-account-notice-headline {
        font-size: 12px;
        & > a {
          font-size: 13px;
          line-height: 15px;
          margin-left: 1px;
        }
      }
      .bank-account-notice-message {
        font-size: 11px;
      }
    }
  `}
`

export const CreatorProfile: React.FC<Props> = ({ isVisibleEditButton = true, className }) => {
  const history = useHistory()
  const { creator } = useContext(CreatorProfileContext)

  const goToEdit = (): void => {
    history.push('/creator/mypage/edit')
  }

  const goToCreatorPage = (): void => {
    history.push(`/creator/${creator.id}/user`)
  }

  return (
    <Wrapper className={className}>
      <div className="sp title">
        <SubtitleWithLink subtitle="アカウント情報" linkText="ログアウト" onClick={useCreatorLogout()} />
      </div>
      <div className="inner">
        <Icon className="icon pc" src={creator.icon_url ? creator.icon_url : defaultIcon} alt={creator.name} />
        <ProfileItem className="creator-info creator-name" label="クリエイター名">
          {creator.name}
        </ProfileItem>
        <ProfileItem className="creator-info creator-email" label="メールアドレス">
          {creator.email}
        </ProfileItem>
        <ProfileItem className="creator-info creator-profile" label="プロフィールテキスト">
          {creator.profile}
        </ProfileItem>
        <ProfileItem className="creator-info creator-affiliation" label="所属">
          {creator.affiliation?.name ?? '所属なし'}
        </ProfileItem>
        <ProfileItem className="creator-info creator-sns">
          <SnsList creator={creator} />
        </ProfileItem>
        <ProfileItem className="creator-info creator-favorited-num" label="お気に入り登録者数">
          {creator.favorite_users_count}人
        </ProfileItem>
        <ProfileItem className="creator-info creator-url" label="販売ページURL">
          <p className="url">https://www.pintforyou.com/creators/{creator.id}</p>
          <CopyLinkWithAlert
            copyTarget={`${config.userFrontEndpoint}/creators/${creator.id} #p4you`}
            alertText="販売ページURLをコピーしました"
          >
            シェア用のURLをコピー
          </CopyLinkWithAlert>
        </ProfileItem>
        <div className="bank-account-notice-div">
          <p className="bank-account-notice-headline">
            報酬支払い口座登録・変更は
            <a target="_blank" rel="noopener noreferrer" href={CREATOR_BANK_ACCOUNT_REGISTRATION_URL}>
              こちら
            </a>
          </p>
          <p className="bank-account-notice-message">
            ※ 報酬支払いは月末締め、翌月末払いでお支払いさせていただきます。
            <br />
            売上が発生していても納品されていない場合、お支払いはできかねます。
          </p>
        </div>
        {isVisibleEditButton && (
          <Button buttonType="white" onClick={goToEdit} className="edit">
            編集
          </Button>
        )}
        {userType() === 'creator' && (
          <TextButton className="creator-page sp" onClick={goToCreatorPage}>
            ＞ クリエイターページを確認
          </TextButton>
        )}
        <TextButton onClick={useCreatorLogout()} className="logout pc">
          ログアウト
        </TextButton>
      </div>
    </Wrapper>
  )
}
