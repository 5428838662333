import React, { useState, useRef, useEffect } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { ProductListOnSale, RecommendCreators } from '../../organisms'
import { CreatorInfoSideColumn, Pagination } from '../../molecules'
import { Creator, PublishedProduct } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'
import paperAirplane from '../../../assets/images/icon/paper_airplane.png'

type Props = {
  creator: Creator
  products: PublishedProduct[]
  lastPage: number
  currentPage: number
  recommendCreators: Creator[]
  isUserValid?: boolean
  onClickFavorite: (creatorId: string) => void
  onClickUnfavorite: (creatorId: string) => void
  onClickProductRequestButton: () => void
}

const Wrapper = styled.div`
  width: 100%;

  .content {
    width: 100vw;
    background: ${(props): string => props.theme.backgroundColors.gray};

    & > .inner {
      width: 100%;
    }
    .right-column {
      width: 100%;
      background: ${(props): string => props.theme.backgroundColors.bodyBg};
      .right-column-inner {
        padding: 6.6vw 4.7vw 17vw;
        .content-title {
          width: 100%;
          font-size: 3.735vw;
          line-height: 3.735vw;
          margin-bottom: 4vw;
          font-weight: bold;
          letter-spacing: 0.05em;
          color: ${(props): string => props.theme.textColors.black};
        }
        .right-column-contents {
          padding: 0 0 8.8vw;
          .product-list {
            padding: 0 0 3.735vw;
          }
          .no-data {
            font-size: 3.735vw;
            text-align: center;
            font-weight: 500;
            color: ${(props): string => props.theme.textColors.gray};
            line-height: 1.14;
            letter-spacing: 0.05em;
          }
        }
        .request-button-wrapper {
          height: 14.6vw;
          .request-button {
            display: block;
            width: 69vw;
            height: 14.6vw;
            padding: 0 0 0 12.6vw;
            margin: 0 auto;
            border-radius: 8vw;
            background: ${(props): string => props.theme.buttonColors.red};
            background-image: url(${paperAirplane});
            background-repeat: no-repeat;
            background-position: 5.3vw;
            background-size: 6.4vw;
            color: ${(props): string => props.theme.textColors.white};
            font-size: 4.8vw;
            font-weight: bold;
            text-align: left;
            z-index: 6;
            &.fixed {
              position: fixed;
              bottom: 5.3vw;
              left: 0;
              right: 0;
            }
          }
        }
      }
      .recommend-inner {
        padding: 0 0 29.3vw 4.7vw;
      }
    }
  }

  ${mediaPc`
  .content {
    width: 100%;
    min-width: 1040px;
    & > .inner {
      width: 1040px;
      margin: 0 auto;
      padding: 34px 20px 50px;
      display: flex;
      justify-content: space-between;
      align-items: start;
    }
    .creator-info {
      padding: 50px 0 0;
    }
    .right-column {
      width: 720px;
      margin: 46px 0 0;
      background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
     .right-column-inner {
        padding: 1px 0 0;
        .content-title {
          line-height: 16.41px;
          padding: 0;
          margin:0;
          font-size:14px;
        }
        .right-column-contents {
          padding: 25px;
          border-radius: 4px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
          .product-list {
            padding: 25px 0 0;
          }
          .no-data {
            font-size: 14px;
            padding: 45px 0 129px;
          }
        }
      }
      .recommend-inner {
        padding: 25px 0 25px 25px;
        background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
      }
    }
  }
`}
`

export const CreatorTopTpl: React.FC<Props> = ({
  creator,
  products,
  lastPage,
  currentPage,
  recommendCreators,
  isUserValid,
  onClickFavorite,
  onClickUnfavorite,
  onClickProductRequestButton,
}) => {
  const [isFixedRequestButton, setIsFixedRequestButton] = useState<boolean>(true)
  const requestButtonRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollForRequestButton = (): void => {
    if (requestButtonRef?.current) {
      const windowInnerHeight = window.innerHeight
      const currentScrollY = window.scrollY

      const { top } = requestButtonRef.current.getBoundingClientRect()
      // cssで当てられてるVwに合わせてbottom位置を計算
      const bottomPosition = windowInnerHeight - window.innerWidth * 0.19
      const fixedPosition = top + currentScrollY - bottomPosition

      if (fixedPosition < currentScrollY) {
        setIsFixedRequestButton(false)
      }
      if (fixedPosition > currentScrollY) {
        setIsFixedRequestButton(true)
      }
    }
  }

  useEffect(() => {
    if (userType() !== 'user') return

    window.addEventListener('scroll', handleScrollForRequestButton)
    return () => window.removeEventListener('scroll', handleScrollForRequestButton)
  }, [handleScrollForRequestButton])

  return (
    <Wrapper>
      <div className="content">
        <div className="inner">
          <div className="creator-info">
            <CreatorInfoSideColumn
              creator={creator}
              isUserValid={isUserValid}
              displayRequestButton={true}
              onClickFavorite={onClickFavorite}
              onClickUnfavorite={onClickUnfavorite}
              isCreatorTopPage
              onClickProductRequestButton={onClickProductRequestButton}
            />
          </div>
          <div className="right-column">
            <div className="right-column-inner">
              <div className="right-column-contents">
                <p className="content-title">販売中の商品</p>
                {products.length > 0 ? (
                  <>
                    <div className="product-list">
                      <ProductListOnSale products={products} oneLineItemsNum={4} />
                    </div>
                    <Pagination lastPage={lastPage} currentPage={currentPage} />
                  </>
                ) : (
                  <div className="no-data">販売中の商品はありません</div>
                )}
              </div>
              {userType() === 'user' && (
                <div className="request-button-wrapper sp" ref={requestButtonRef}>
                  <button
                    className={isFixedRequestButton && products.length > 0 ? 'request-button fixed' : 'request-button'}
                    onClick={onClickProductRequestButton}
                  >
                    販売リクエストを送る
                  </button>
                </div>
              )}
            </div>
            <div className="recommend-inner">
              <div className="recommend-creators">
                <RecommendCreators creators={recommendCreators} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
