import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  url?: string
  target?: string
  text?: string
  rel?: string
}

const StyledLink = styled.a`
  font-size: 3.4vw;
  color: ${(props): string => props.theme.textColors.primary};
  line-height: 1.65;
  letter-spacing: 0.05em;

  ${mediaPc`
    font-size: 13px;
  `}
`

export const Link: React.FC<Props> = ({ className = '', url = '', text = '', target = '_self', rel }) => {
  return (
    <StyledLink className={className} href={url} target={target} rel={rel}>
      {text}
    </StyledLink>
  )
}
