import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  type?: string
  name?: string
  value?: string
  placeholder?: string
  readOnly?: boolean
  error?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const StyledInput = styled.input<Props>`
  width: 100%;
  height: 12vw;
  padding: 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid ${(props): string => (props.error ? props.theme.textColors.red : 'transparent')};
  border-radius: 4px;
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  color: ${(props): string => props.theme.textColors.black};
  font-size: 3.733vw;
  line-height: 2.86;
  transition: 0.3s all;
  box-sizing: border-box;

  &:focus {
    outline: 0;
    border: 1px solid ${(props): string => (props.error ? props.theme.textColors.red : props.theme.borderColors.primary)};
  }
  &::placeholder {
    color: ${(props): string => props.theme.textColors.placeholder};
    font-weight: 500;
  }

  ${mediaPc`
    height: auto;
    font-size: 14px;
    line-height: 3.28;
  `}
`

export const TextInput: React.FC<Props> = ({
  className = '',
  type = 'text',
  name = '',
  placeholder = '',
  value = '',
  readOnly = false,
  error = false,
  onChange,
}) => {
  return (
    <StyledInput
      className={className}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      onChange={onChange}
      error={error}
    />
  )
}
