import React from 'react'
import styled, { css } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  colorType?: 'primary' | 'pink'
  children?: React.ReactNode
}

const StyledNotifications = styled.div<Props>`
  width: 85.6vw;
  margin: 0 auto;
  div {
    padding: 2.6vw;
    display: flex;
    align-items: center;
    background-color: ${(props): string => props.theme.backgroundColors.lightGreen};
    font-weight: bold;
    border-radius: 4px;
    p {
      font-size: 3.12vw;
      font-weight: bold;
    }
    .new {
      background-color: ${(props): string => props.theme.backgroundColors.primary};
      color: ${(props): string => props.theme.textColors.white};
      margin: 0 2vw 0 0;
      padding: 1.3vw 3.64vw;
      border-radius: 4px;
    }
    ${(props) =>
      props.colorType === 'pink' &&
      css`
        background-color: ${(props): string => props.theme.backgroundColors.lightRed};
        .new {
          background-color: ${(props): string => props.theme.backgroundColors.red};
        }
      `}
  }
  ${mediaPc`
    width: 100%;
    div {
      padding: 10px;
      p {
        font-size: 14px;
      }
      .new  {
        margin: 0 7px 0 0;
        padding: 5px 18px;
      }
    }
  `}
`

export const Notifications: React.FC<Props> = ({ className = '', colorType = 'primary', children }) => {
  return (
    <StyledNotifications className={className} colorType={colorType}>
      <div>
        <p className="new">NEW</p>
        <p>{children}</p>
      </div>
    </StyledNotifications>
  )
}
