import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Pagination } from '../../../molecules'
import { FavoriteCreators } from '../../../organisms'
import { Creator } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  creators: Creator[]
  lastPage: number
  currentPage: number
  onClickFavorite: (creatorId: string, isFavorite: boolean) => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};

  .pagination {
    margin-bottom: 13vw;
  }

  .no-creator {
    font-size: 3.735vw;
    text-align: center;
    font-weight: 500;
    color: ${(props): string => props.theme.textColors.gray};
    line-height: 1.14;
    letter-spacing: 0.05em;
  }

  ${mediaPc`
    width: 100%;
    padding: 28px;
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

    .pagination { 
      margin: 0;
    }

    .no-creator {
      font-size: 14px;
    }  
  `}
`

export const UserFavoriteCreatorsTpl: React.FC<Props> = ({ creators, currentPage, lastPage, onClickFavorite }) => {
  return (
    <Wrapper>
      {creators.length !== 0 && (
        <>
          <FavoriteCreators creators={creators} onClickFavorite={onClickFavorite} className="favorite-creator" />
          <Pagination currentPage={currentPage} lastPage={lastPage} className="pagination" />
        </>
      )}
      {creators.length === 0 && <p className="no-creator">お気に入りのクリエイターはいません</p>}
    </Wrapper>
  )
}
