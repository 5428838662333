import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { ProductsOnSaleTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { PublishedProduct, usePagedProductsOnSaleLazyQuery } from '../../../types/graphql'

type Props = {} & RouteComponentProps

export const ProductsOnSale: React.FC<Props> = (props) => {
  const [products, setProducts] = useState<PublishedProduct[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [pagedProductsOnSaleLazyQuery] = usePagedProductsOnSaleLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setProducts(data.PagedProductsOnSale?.data ?? [])
      setLastPage(data.PagedProductsOnSale?.paginatorInfo.lastPage ?? 1)
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    pagedProductsOnSaleLazyQuery({
      variables: {
        first: 20,
        page: Number(pageParam),
      },
    })
  }, [pagedProductsOnSaleLazyQuery, props.location.search])

  return (
    <>
      <Meta title="販売中の商品" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <ProductsOnSaleTpl products={products} lastPage={lastPage} currentPage={currentPage} />
    </>
  )
}
