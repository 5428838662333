import React from 'react'
import styled from 'styled-components'
import { CreatorIconWithName } from '../../../molecules'
import { Creator } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  className?: string
  creators: Creator[]
}

const Wrapper = styled.div`
  .content-title {
    width: 100%;
    padding: 0 0 8.5vw;
    font-size: 3.735vw;
    line-height: 3.735vw;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: ${(props): string => props.theme.textColors.black};
  }
  .scroll-wrapper {
    overflow-x: scroll;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    /* Edge スクロールバー非表示対応 */
    -ms-overflow-style: none;
    /* Firefox スクロールバー非表示対応 */
    scrollbar-width: none;
    .list {
      width: fit-content;
      display: flex;
      -webkit-box-pack: start;
      justify-content: flex-start;
      flex-wrap: nowrap;
      white-space: nowrap;
      .creator-icon-with-name {
        width: 34.6vw;
        margin: 0 5.3vw 0 0;
        .creator-icon {
          height: 34.6vw;
        }
      }
    }
  }
  ${mediaPc`
    .content-title {
      font-size:14px;
      line-height: 16.41px;
      padding: 0 0 22px;
    }
    .scroll-wrapper {
      .list {
        .creator-icon-with-name {
          width: 130px;
          margin: 0 20px 0 0;
          .creator-icon{
            height: 130px;
            margin: 0 0 10px;
          }
        }
      }
    }
  `}
`

export const RecommendCreators: React.FC<Props> = ({ className, creators }) => {
  return (
    <Wrapper className={className}>
      <p className="content-title">このクリエイターを見ている方へのおすすめ</p>
      <div className="scroll-wrapper">
        <div className="list">
          {creators.map((creator) => (
            <CreatorIconWithName
              className="creator-icon-with-name"
              key={creator.id}
              link={`/creators/${creator.id}`}
              iconSrc={creator.icon_url}
              iconAlt={creator.name}
              creatorName={creator.name}
              creatorId={creator.id}
              displayFavorite={false}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  )
}
