import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
}

const Wrapper = styled.div`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${(props): string => props.theme.borderColors.red};
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  p {
    font-weight: bold;
    padding: 0 0 3px;
  }
  ul {
    padding: 0 0 11px;
    &:last-child {
      padding: 0;
    }
    li {
      padding-left: 1.2em;
      position: relative;
      font-weight: bold;
      line-height: 21px;
      letter-spacing: 0.05em;
      &:before {
        content: '-';
        position: absolute;
        left: 5px;
      }
      span {
        font-weight: bold;
        color: ${(props): string => props.theme.textColors.red};
      }
    }
  }

  ${mediaPc`
    width: 100%;
  `}
`

export const DeliveryAttention: React.FC<Props> = ({ className = '' }) => {
  return (
    <Wrapper className={className}>
      <p>【写真・動画について】</p>
      <ul>
        <li>
          <span>未納品を押して納品を行ってください</span>
        </li>
        <li>
          購入日から<span>3日以内に納品</span>をお願いします
        </li>
        <li>
          納品が遅れる場合は<span>アカウント停止</span>となります
        </li>
      </ul>
      <p>【ライブトークについて】</p>
      <ul>
        <li>
          <span>開始時間の5分前になりましたら、トーク前を押し、商品画像をタップしてお待ちください</span>
        </li>
        <li>
          開始時間までにライブトークに参加できない場合は<span>アカウント停止</span>となります
        </li>
      </ul>
    </Wrapper>
  )
}
