import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import * as Sentry from '@sentry/react'

import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { UserLoginTpl } from '../../../components/templates'

import { Meta } from '../../../Meta'

import { validateEmail, validatePassword } from '../../../lib/Validate'

import { UserTokenContext } from '../../../context'

import { FlashMessageType } from '../../../types/myTypes'
import { useLoginUserMutation } from '../../../types/graphql'

export const UserLogin: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [redirect, setRedirect] = useState<boolean>(false)
  const [redirectFrom, setRedirectFrom] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const history = useHistory()
  const location = useLocation<{ redirect: boolean; from?: string; redirectFrom: string }>()
  const { search, state } = location

  const { updateUserToken } = useContext(UserTokenContext)

  const [, setCookie] = useCookies(['token'])

  const [loginUserMutation] = useLoginUserMutation({
    onCompleted: (data) => {
      setLoading(false)
      const token = data?.LoginUser?.token ?? ''
      updateUserToken(token)
      setCookie('userToken', token, { path: '/' })

      if (!!redirectFrom) {
        history.push(redirectFrom)
      } else {
        history.push('/')
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'ログインできませんでした' })
      }
    },
  })

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
    setEmailError(null)
    const emailError: string | null = validateEmail(event.target.value)
    if (emailError) {
      setEmailError(emailError)
    }
  }
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value)
    setPasswordError(null)
    const passwordError: string | null = validatePassword(event.target.value)
    if (passwordError) {
      setPasswordError(passwordError)
    }
  }

  const login = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await loginUserMutation({
      variables: {
        input: {
          email,
          password,
        },
      },
    })
  }

  useEffect(() => {
    if (state) {
      // 不正なtokenによるリダイレクト
      if (state.redirect) {
        setRedirect(state.redirect)
        setRedirectFrom(state.redirectFrom)
      }
      if (state.from === 'productRequest') {
        setFlashMessage({ type: 'systemError', message: '販売リクエスト機能のご利用には、ログインが必要です。' })
      } else if (redirect) {
        setFlashMessage({ type: 'systemError', message: '再度ログインしてください' })
      }
    }
  }, [redirect, search, state])

  // state.redirect = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (state && state.redirect === true) {
      history.replace({ ...location, state: undefined })
    }
  }, [history, location, state])

  return (
    <>
      <Meta title="ログイン" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <UserLoginTpl
        email={email}
        password={password}
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        login={login}
        emailError={emailError}
        passwordError={passwordError}
      />
    </>
  )
}
