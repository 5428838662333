import React from 'react'
import styled from 'styled-components'
import { Label, Checkbox } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  label?: string
  checkedValue: string[]
  checkboxOptions: { id: string; name: string; value: string; label: string }[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Wrapper = styled.div`
  .label {
    margin-bottom: 2.1vw;
  }

  ${mediaPc`
    .label {
      margin-bottom: 10px;
    }
  `}
`

export const CheckboxWithLabel: React.FC<Props> = ({ className = '', label = '', checkboxOptions = [], checkedValue = [], onChange }) => {
  return (
    <Wrapper className={className}>
      <Label className="label">{label}</Label>
      <Checkbox checkedValue={checkedValue} checkboxOptions={checkboxOptions} onChange={onChange} />
    </Wrapper>
  )
}
