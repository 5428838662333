import React from 'react'
import styled, { ThemedStyledProps, ThemeProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  onClick?: () => void
  disabled?: boolean
  buttonType: 'toDisabled' | 'toEnd' | 'toDisplay'
}

const getTypes = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  switch (props.buttonType) {
    case 'toEnd':
      return `
        color: ${props.theme.textColors.black};
        border: 1px solid ${props.theme.borderColors.black};
      `
    case 'toDisabled':
      return `
        color: ${props.theme.textColors.red};
        border: 1px solid ${props.theme.borderColors.red};
        padding: 0 2px;
      `
    default:
      return `
        color: ${props.theme.textColors.primary};
        border: 1px solid ${props.theme.borderColors.primary};
      `
  }
}

const getText = (props: string): string => {
  switch (props) {
    case 'toEnd':
      return '販売終了'
    case 'toDisabled':
      return '非表示にする'
    default:
      return '再表示する'
  }
}

const StyledProductChangeStatusButton = styled.button<Props>`
  width: 47%;
  height: auto;
  text-align: center;
  line-height: 2.5;
  font-size: 2.67vw;
  z-index: 5;
  border-radius: calc(2.67vw * 2.5 / 2);
  transition: 0.3s all;
  ${(props) => getTypes(props)}

  &:disabled {
    color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.placeholder};
    border: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.gray};
  }
  &:not(:disabled):hover {
    opacity: 0.8;
  }
  ${mediaPc`
    width: 70px;
    height: 28px;
    line-height: 28px;
    font-size: 9px;
    border-radius: 14px;
  `}
`

export const ProductChangeStatusButton: React.FC<Props> = ({ className = '', buttonType, onClick, disabled }) => {
  const text = getText(buttonType)
  return (
    <StyledProductChangeStatusButton buttonType={buttonType} className={className} type="button" onClick={onClick} disabled={disabled}>
      {text}
    </StyledProductChangeStatusButton>
  )
}
