import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorOrdersTpl, CreatorTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  OrderedProduct,
  useCreatorUndeliveredOrderedProductsLazyQuery,
  usePagedCreatorDeliveredOrderedProductsLazyQuery,
} from '../../../types/graphql'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'

type Props = {} & RouteComponentProps

export const CreatorOrders: React.FC<Props> = (props) => {
  const [undeliveredOrders, setUndeliveredOrders] = useState<OrderedProduct[]>([])
  const [deliveredOrders, setDeliveredOrders] = useState<OrderedProduct[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [creatorUndeliveredOrderedProductsLazyQuery] = useCreatorUndeliveredOrderedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setUndeliveredOrders(data.CreatorUndeliveredOrderedProducts ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedCreatorDeliveredOrderedProductsLazyQuery] = usePagedCreatorDeliveredOrderedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setDeliveredOrders(data.PagedCreatorDeliveredOrderedProducts?.data ?? [])
        setLastPage(data.PagedCreatorDeliveredOrderedProducts?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const onClickOrderedUsers = (): void => {
    props.history.push(`/creator/ordered-users`)
  }

  useValidateCreatorToken()
  useSetCreatorProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    creatorUndeliveredOrderedProductsLazyQuery()
    pagedCreatorDeliveredOrderedProductsLazyQuery({
      variables: {
        first: 30,
        page: Number(pageParam),
      },
    })
  }, [creatorUndeliveredOrderedProductsLazyQuery, pagedCreatorDeliveredOrderedProductsLazyQuery, props.location.search])

  return (
    <>
      <Meta title="注文状況一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="orders">
        <CreatorOrdersTpl
          undeliveredOrders={undeliveredOrders}
          deliveredOrders={deliveredOrders}
          currentPage={currentPage}
          lastPage={lastPage}
          onClickOrderedUsers={onClickOrderedUsers}
        />
      </CreatorTwoColumnLayout>
    </>
  )
}
