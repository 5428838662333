import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import {
  ProductRegisterTpl,
  CreatorTwoColumnLayout,
  ProductThumbnailTrimmingModalTpl,
  ProductThumbnailEditSelectingModalTpl,
  PreviewModalTpl,
  DialogTpl,
} from '../../../components/templates'
import { Meta } from '../../../Meta'
import { useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  useCreatorRegisterPhotoProductMutation,
  useCreatorRegisterMovieProductMutation,
  useCreatorRegisterLiveTalkProductMutation,
  ProductType,
} from '../../../types/graphql'
import { RegisterProductInput, FlashMessageType, ThumbnailEditingType, ThumbnailEditingTypeEnum } from '../../../types/myTypes'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import { blurWeakWhite, blurStrongWhite, blurWeakBlack, blurStrongBlack } from '../../../lib/BlurPhotoEditing'

export const CreatorProductRegister: React.FC = () => {
  const productInitialState: RegisterProductInput = {
    name: '',
    price: '',
    thumbnail_image: '',
    movie_seconds: '',
    released_at: null,
    product_type: ProductType.Photo,
    start_at: `${moment().format('YYYY-MM-DD')} 00:00`,
    talk_minutes: '',
  }
  const [product, setProduct] = useState<RegisterProductInput>(productInitialState)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [productThumbnailEditSelectingModalVisible, setProductThumbnailEditSelectingModalVisible] = useState<boolean>(false)
  const [previewModalVisible, setPreviewModalVisible] = useState<boolean>(false)
  const [productThumbnailFileName, setProductThumbnailFileName] = useState<string>('')
  const [productThumbnailBeforeTrimming, setProductThumbnailBeforeTrimming] = useState<string>('')
  const [productThumbnailCanvasAfterTrimming, setProductThumbnailCanvasAfterTrimming] = useState<HTMLCanvasElement | null>(null)
  const [productThumbnailUrlAfterTrimming, setProductThumbnailUrlAfterTrimming] = useState<string>('')
  const [productThumbnailFileAfterTrimming, setProductThumbnailFileAfterTrimming] = useState<File | null>(null)
  const [thumbnailEditingType, setThumbnailEditingType] = useState<ThumbnailEditingType>(ThumbnailEditingTypeEnum.BLUR_WEAK_WHITE)
  const [blurWeakWhiteThumbnailUrl, setBlurWeakWhiteThumbnailUrl] = useState<string>('')
  const [blurWeakWhiteThumbnailFile, setBlurWeakWhiteThumbnailFile] = useState<File | null>(null)
  const [blurStrongWhiteThumbnailUrl, setBlurStrongWhiteThumbnailUrl] = useState<string>('')
  const [blurStrongWhiteThumbnailFile, setBlurStrongWhiteThumbnailFile] = useState<File | null>(null)
  const [blurWeakBlackThumbnailUrl, setBlurWeakBlackThumbnailUrl] = useState<string>('')
  const [blurWeakBlackThumbnailFile, setBlurWeakBlackThumbnailFile] = useState<File | null>(null)
  const [blurStrongBlackThumbnailUrl, setBlurStrongBlackThumbnailUrl] = useState<string>('')
  const [blurStrongBlackThumbnailFile, setBlurStrongBlackThumbnailFile] = useState<File | null>(null)
  const [productThumbnailFileAfterEditSelecting, setProductThumbnailFileAfterEditSelecting] = useState<File | null>(null)
  const [trimmingModalVisible, setTrimmingModalVisible] = useState<boolean>(false)
  const [dialogVisible, setDialogVisible] = useState<boolean>(false)

  const history = useHistory()
  const location = useLocation<{ fromLiveTalkLp: boolean }>()

  const [creatorRegisterPhotoProductMutation] = useCreatorRegisterPhotoProductMutation({
    onCompleted: () => {
      setLoading(false)
      if (product.released_at) {
        history.push({ pathname: `/creator/products/reserved`, state: { registerProductComplete: true } })
      } else {
        history.push({ pathname: `/creator/products/published`, state: { registerProductComplete: true } })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const [creatorRegisterMovieProductMutation] = useCreatorRegisterMovieProductMutation({
    onCompleted: () => {
      setLoading(false)
      if (product.released_at) {
        history.push({ pathname: `/creator/products/reserved`, state: { registerProductComplete: true } })
      } else {
        history.push({ pathname: `/creator/products/published`, state: { registerProductComplete: true } })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const [creatorRegisterLiveTalkProductMutation] = useCreatorRegisterLiveTalkProductMutation({
    onCompleted: () => {
      setLoading(false)
      if (product.released_at) {
        history.push({ pathname: `/creator/products/reserved`, state: { registerProductComplete: true } })
      } else {
        history.push({ pathname: `/creator/products/published`, state: { registerProductComplete: true } })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const onChangeState = (name: string, value: string | number | null): void => {
    if (name === 'product_type' && value === ProductType.LiveTalk && product.price === '500') {
      setProduct({ ...product, [name]: value, price: '' })
    } else {
      setProduct({ ...product, [name]: value })
    }
  }

  const uploadProductImage = (result: string, file: File): void => {
    setBlurWeakWhiteThumbnailUrl('')
    setBlurStrongWhiteThumbnailUrl('')
    setBlurWeakBlackThumbnailUrl('')
    setBlurStrongBlackThumbnailUrl('')
    setBlurWeakWhiteThumbnailFile(null)
    setBlurStrongWhiteThumbnailFile(null)
    setBlurWeakBlackThumbnailFile(null)
    setBlurStrongBlackThumbnailFile(null)
    setProductThumbnailBeforeTrimming(result)
    setProductThumbnailFileName(file.name)
    setTrimmingModalVisible(true)
  }

  const setBlurImages = (): void => {
    if (
      blurWeakWhiteThumbnailUrl &&
      blurWeakWhiteThumbnailFile &&
      blurStrongWhiteThumbnailUrl &&
      blurStrongWhiteThumbnailFile &&
      blurWeakBlackThumbnailUrl &&
      blurWeakBlackThumbnailFile &&
      blurStrongBlackThumbnailUrl &&
      blurStrongBlackThumbnailFile
    ) {
      return
    }
    const image = new Image()
    image.src = productThumbnailUrlAfterTrimming
    if (!productThumbnailCanvasAfterTrimming) {
      return
    }
    const blurWeakWhiteCanvas = blurWeakWhite(productThumbnailCanvasAfterTrimming)
    setBlurWeakWhiteThumbnailUrl(blurWeakWhiteCanvas.toDataURL())
    blurWeakWhiteCanvas.toBlob((blob) => {
      if (blob !== null) {
        setBlurWeakWhiteThumbnailFile(new File([blob], productThumbnailFileName))
      }
    })
    const blurStrongWhiteCanvas = blurStrongWhite(productThumbnailCanvasAfterTrimming)
    setBlurStrongWhiteThumbnailUrl(blurStrongWhiteCanvas.toDataURL())
    blurStrongWhiteCanvas.toBlob((blob) => {
      if (blob !== null) {
        setBlurStrongWhiteThumbnailFile(new File([blob], productThumbnailFileName))
      }
    })
    const blurWeakBlackCanvas = blurWeakBlack(productThumbnailCanvasAfterTrimming)
    setBlurWeakBlackThumbnailUrl(blurWeakBlackCanvas.toDataURL())
    blurWeakBlackCanvas.toBlob((blob) => {
      if (blob !== null) {
        setBlurWeakBlackThumbnailFile(new File([blob], productThumbnailFileName))
      }
    })
    const blurStrongBlackCanvas = blurStrongBlack(productThumbnailCanvasAfterTrimming)
    setBlurStrongBlackThumbnailUrl(blurStrongBlackCanvas.toDataURL())
    blurStrongBlackCanvas.toBlob((blob) => {
      if (blob !== null) {
        setBlurStrongBlackThumbnailFile(new File([blob], productThumbnailFileName))
      }
    })
  }

  const closeTrimmingModal = (): void => {
    setTrimmingModalVisible(false)
    setProductThumbnailBeforeTrimming('')
  }

  const onClickShowProductThumbnailEditSelectingModal = (): void => {
    setBlurImages()
    setFlashMessage(null)
    setProductThumbnailEditSelectingModalVisible(true)
  }
  const closeProductThumbnailEditingModal = (): void => {
    setProductThumbnailEditSelectingModalVisible(false)
  }

  const onClickShowPreview = (): void => {
    setFlashMessage(null)
    setPreviewModalVisible(true)
  }
  const closePreviewModal = (): void => {
    setPreviewModalVisible(false)
  }

  const registerPhotoProduct = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await creatorRegisterPhotoProductMutation({
      variables: {
        input: {
          name: product.name,
          price: Number(product.price),
          thumbnail_image: productThumbnailFileAfterEditSelecting
            ? productThumbnailFileAfterEditSelecting
            : productThumbnailFileAfterTrimming,
          released_at: product.released_at ? moment(product.released_at).format('YYYY-MM-DD HH:mm:ss') : null,
        },
      },
    })
  }

  const registerMovieProduct = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await creatorRegisterMovieProductMutation({
      variables: {
        input: {
          name: product.name,
          price: Number(product.price),
          movie_seconds: Number(product.movie_seconds),
          thumbnail_image: productThumbnailFileAfterEditSelecting
            ? productThumbnailFileAfterEditSelecting
            : productThumbnailFileAfterTrimming,
          released_at: product.released_at ? moment(product.released_at).format('YYYY-MM-DD HH:mm:ss') : null,
        },
      },
    })
  }

  const registerLiveTalkProduct = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await creatorRegisterLiveTalkProductMutation({
      variables: {
        input: {
          name: product.name,
          price: Number(product.price),
          talk_minutes: Number(product.talk_minutes),
          start_at: moment(product.start_at).format('YYYY-MM-DD HH:mm:ss'),
          thumbnail_image: productThumbnailFileAfterEditSelecting
            ? productThumbnailFileAfterEditSelecting
            : productThumbnailFileAfterTrimming,
          released_at: product.released_at ? moment(product.released_at).format('YYYY-MM-DD HH:mm:ss') : null,
        },
      },
    })
  }

  const onClickProductRegister = (): void => {
    setFlashMessage(null)
    setDialogVisible(true)
  }

  const onClickProductRegisterDialogOk = (): void => {
    setDialogVisible(false)
    if (product.product_type === ProductType.Photo) {
      registerPhotoProduct()
    } else if (product.product_type === ProductType.Movie) {
      registerMovieProduct()
    } else if (product.product_type === ProductType.LiveTalk) {
      registerLiveTalkProduct()
    }
  }

  const onClickProductRegisterDialogCancel = (): void => {
    setDialogVisible(false)
  }
  useValidateCreatorToken()
  useSetCreatorProfile()

  useEffect(() => {
    if (location.state) {
      setProduct({ ...product, product_type: ProductType.LiveTalk })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  return (
    <>
      <Meta title="新しい商品の登録" />
      {dialogVisible && (
        <DialogTpl title="登録内容の確認" onClickOk={onClickProductRegisterDialogOk} onClickCancel={onClickProductRegisterDialogCancel}>
          この内容で登録しますか？
        </DialogTpl>
      )}
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="products" isVisibleAddProductLink={false}>
        <ProductRegisterTpl
          product={product}
          onChangeState={onChangeState}
          uploadProductImage={uploadProductImage}
          onClickShowProductThumbnailEditSelectingModal={onClickShowProductThumbnailEditSelectingModal}
          onClickShowPreview={onClickShowPreview}
          onClickProductRegister={onClickProductRegister}
        />
      </CreatorTwoColumnLayout>
      {previewModalVisible && <PreviewModalTpl product={product} closePreviewModal={closePreviewModal} />}
      {trimmingModalVisible && (
        <ProductThumbnailTrimmingModalTpl
          src={productThumbnailBeforeTrimming}
          productThumbnailFileName={productThumbnailFileName}
          closeModal={closeTrimmingModal}
          setProductThumbnailUrlAfterTrimming={(url: string) => {
            onChangeState('thumbnail_image', url)
            setProductThumbnailUrlAfterTrimming(url)
          }}
          setProductThumbnailFileAfterTrimming={(file: File) => {
            setProductThumbnailFileAfterTrimming(file)
          }}
          setProductThumbnailCanvasAfterTrimming={(canvas: HTMLCanvasElement) => {
            setProductThumbnailCanvasAfterTrimming(canvas)
          }}
        />
      )}
      {productThumbnailEditSelectingModalVisible && productThumbnailCanvasAfterTrimming && (
        <ProductThumbnailEditSelectingModalTpl
          originalThumbnailUrl={productThumbnailUrlAfterTrimming}
          originalThumbnailFile={productThumbnailFileAfterTrimming}
          blurWeakWhiteThumbnailUrl={blurWeakWhiteThumbnailUrl}
          blurWeakWhiteThumbnailFile={blurWeakWhiteThumbnailFile}
          blurStrongWhiteThumbnailUrl={blurStrongWhiteThumbnailUrl}
          blurStrongWhiteThumbnailFile={blurStrongWhiteThumbnailFile}
          blurWeakBlackThumbnailUrl={blurWeakBlackThumbnailUrl}
          blurWeakBlackThumbnailFile={blurWeakBlackThumbnailFile}
          blurStrongBlackThumbnailUrl={blurStrongBlackThumbnailUrl}
          blurStrongBlackThumbnailFile={blurStrongBlackThumbnailFile}
          thumbnailEditingType={thumbnailEditingType}
          setThumbnailEditingType={setThumbnailEditingType}
          closeModal={closeProductThumbnailEditingModal}
          setProductThumbnailUrlAfterEditSelecting={(url: string) => {
            onChangeState('thumbnail_image', url)
          }}
          setProductThumbnailFileAfterEditSelecting={(file: File | null) => {
            setProductThumbnailFileAfterEditSelecting(file)
          }}
        />
      )}
    </>
  )
}
