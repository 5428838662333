import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useValidateCreatorTokenLazyQuery } from '../types/graphql'

export const useValidateCreatorToken = (): void => {
  const history = useHistory()
  const { pathname } = useLocation()

  const [validateCreatorTokenLazyQuery] = useValidateCreatorTokenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.ValidateCreatorToken === true) return
    },
    onError: () => {
      history.push({ pathname: '/creator/login', state: { redirect: true, redirectFrom: pathname } })
    },
  })

  useEffect(() => {
    validateCreatorTokenLazyQuery()
  }, [validateCreatorTokenLazyQuery])
}
