import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorTwoColumnLayout, CreatorSalesPerMonthTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { SalesPerMonth, useCreatorSalesPerMonthInYearLazyQuery } from '../../../types/graphql'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import moment from 'moment'

export const CreatorSalesPerMonth: React.FC = () => {
  const [sales, setSales] = useState<SalesPerMonth[]>([])
  const [years, setYears] = useState<string[]>([])
  const [selectedYear, setSelectedYear] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()
  const location = useLocation()

  const [creatorSalesPerMonthInYearLazyQuery] = useCreatorSalesPerMonthInYearLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setSales(data.CreatorSalesPerMonthInYear)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const onSelectYear = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedYear(e.target.value)
    history.push(`/creator/sales/?year=${e.target.value}`)
  }

  useValidateCreatorToken()
  useSetCreatorProfile()

  useEffect(() => {
    const yearParam = new URLSearchParams(location.search).get('year')
    creatorSalesPerMonthInYearLazyQuery({
      variables: {
        input: {
          year: yearParam,
        },
      },
    })

    const yearsFromServiceStart = moment().diff(moment('202001'), 'years')
    const years = []
    for (let i = 0; i < yearsFromServiceStart + 1; i++) {
      years.push(moment('202001').add(i, 'years').format('YYYY'))
    }
    setYears(years)
  }, [creatorSalesPerMonthInYearLazyQuery, location.search])

  return (
    <>
      <Meta title="売上一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="sales">
        <CreatorSalesPerMonthTpl sales={sales} years={years} selectedYear={selectedYear} onSelectYear={onSelectYear} />
      </CreatorTwoColumnLayout>
    </>
  )
}
