import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageSubtitle, Button } from '../../../atoms'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  email: string
  onClickResendConfirmMail: () => void
}

const Wrapper = styled.div`
  .inner {
    padding: 7.4vw 0;
    background-color: ${(props): string => props.theme.backgroundColors.gray};

    .title {
      margin-bottom: 5.3vw;
      font-size: 4.267vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.black};
      text-align: center;
    }

    .info {
      width: 100%;
      margin-bottom: 5.3vw;
      font-size: 3.467vw;
      word-wrap: wrap;
      word-break: break-all;
      color: ${(props): string => props.theme.textColors.gray};
    }

    .email {
      min-height: 46px;
      margin-bottom: 5.3vw;
      padding: 17px;
      font-size: 3.467vw;
      color: ${(props): string => props.theme.textColors.black};
      border: 1px solid ${(props): string => props.theme.borderColors.black};
      border-radius: 4px;
      line-height: 1.6em;
      text-align: center;
    }

    .resend {
      margin: 15vw 0 5.3vw;
      font-size: 3.2vw;
      line-height: 1.5em;
      color: ${(props): string => props.theme.textColors.red};
    }
  }

  ${mediaPc`
  background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
  .inner {
    margin: 15px 0;
    padding: 25px 95px;

    .title {
      margin-bottom: 15px;
      font-size: 16px;
    }

    .info {
      margin-bottom: 12px;
      font-size: 12px;
      text-align: center;
    }

    .email {
      width: 320px;
      margin: 0 auto 10px;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }

    .resend {
      margin: 23px 0;
      font-size: 12px;
      text-align: center;
    }

    .resend-button {
      width: 200px;
      margin: 0 auto;
      display: block;
      font-size: 12px;
      line-height: 30px;
    }
  }
 `}
`

export const CreatorMypageEditCompleteTpl: React.FC<Props> = ({ email, onClickResendConfirmMail }) => {
  return (
    <Wrapper>
      <PageSubtitle subtitle="アカウント情報編集" />
      <div className="inner">
        <p className="title">
          メールアドレスの更新は
          <br className="sp" />
          まだ完了しておりません
        </p>
        <p className="info">下記のメールアドレスに確認メールをお送りさせていただいております。</p>
        <p className="email">{email}</p>
        <p className="info">メール本文に記載のURLからアドレスの認証を行っていただくと更新が完了となります。</p>
        <p className="resend">万が一、メールが届いていない場合は、確認メールの再送ボタンを押してください。</p>
        <Button buttonType="gray" onClick={onClickResendConfirmMail} className="resend-button">
          確認メールの再送
        </Button>
      </div>
    </Wrapper>
  )
}
