// ぼかし度合いはblurの値を調整
const blurImage = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, blur: number): void => {
  let sum = 0
  const delta = 5
  const alphaLeft = 1 / (2 * Math.PI * delta * delta)
  const step = blur < 3 ? 1 : 2
  for (let y = -blur; y <= blur; y += step) {
    for (let x = -blur; x <= blur; x += step) {
      const weight = alphaLeft * Math.exp(-(x * x + y * y) / (2 * delta * delta))
      sum += weight
    }
  }
  for (let y = -blur; y <= blur; y += step) {
    for (let x = -blur; x <= blur; x += step) {
      ctx.globalAlpha = ((alphaLeft * Math.exp(-(x * x + y * y) / (2 * delta * delta))) / sum) * blur
      ctx.drawImage(canvas, x, y)
    }
  }
}

export const copyCanvas = (canvas: HTMLCanvasElement): { newCanvas: HTMLCanvasElement; newCtx: CanvasRenderingContext2D } => {
  const newCanvas = document.createElement('canvas')
  newCanvas.width = canvas.width
  newCanvas.height = canvas.height
  const newCtx = newCanvas.getContext('2d') as CanvasRenderingContext2D
  newCtx.drawImage(canvas, 0, 0)

  return { newCanvas, newCtx }
}

export const blurWeakWhite = (canvas: HTMLCanvasElement): HTMLCanvasElement => {
  // 他の処理に影響するのでcanvasをコピー
  const { newCanvas, newCtx } = copyCanvas(canvas)
  blurImage(newCanvas, newCtx, 3)

  newCtx.fillStyle = '#FFF'
  newCtx.globalAlpha = 0.3
  newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height)
  return newCanvas
}

export const blurStrongWhite = (canvas: HTMLCanvasElement): HTMLCanvasElement => {
  // 他の処理に影響するのでcanvasをコピー
  const { newCanvas, newCtx } = copyCanvas(canvas)
  blurImage(newCanvas, newCtx, 5)

  newCtx.fillStyle = '#FFF'
  newCtx.globalAlpha = 0.3
  newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height)
  return newCanvas
}

export const blurWeakBlack = (canvas: HTMLCanvasElement): HTMLCanvasElement => {
  // 他の処理に影響するのでcanvasをコピー
  const { newCanvas, newCtx } = copyCanvas(canvas)
  blurImage(newCanvas, newCtx, 3)

  newCtx.fillStyle = '#222'
  newCtx.globalAlpha = 0.3
  newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height)
  return newCanvas
}

export const blurStrongBlack = (canvas: HTMLCanvasElement): HTMLCanvasElement => {
  // 他の処理に影響するのでcanvasをコピー
  const { newCanvas, newCtx } = copyCanvas(canvas)
  blurImage(newCanvas, newCtx, 5)

  newCtx.fillStyle = '#222'
  newCtx.globalAlpha = 0.3
  newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height)
  return newCanvas
}
