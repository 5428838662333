import React from 'react'
import { FLASH_MESSAGE_ANIMATION_DURATION } from '../../const'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'
import { convertToVw } from '../../lib/ConvertSize'
import { convertToSecond } from '../../lib/ConvertTime'
import { mediaPc } from '../../lib/MediaQuery'
import { FlashMessageType } from '../../types/myTypes'

type Props = {
  className?: string
  flashMessage: FlashMessageType
  isHeaderInPage?: boolean
}

const getColor = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  switch (props.flashMessage.type) {
    case 'success':
      return `
        color: ${props.theme.textColors.white};
        background: ${props.theme.backgroundColors.primary};
      `
    case 'inputError':
      return `
        color: ${props.theme.textColors.black};
        background: ${props.theme.backgroundColors.yellow};
      `
    case 'systemError':
      return `
        color: ${props.theme.textColors.white};
        background: ${props.theme.backgroundColors.red};
      `
    default:
      return `
        color: ${props.theme.textColors.white};
        background: ${props.theme.backgroundColors.primary};
      `
  }
}

const StyledFlashMessage = styled.p<Props>`
  width: 100vw;
  font-size: ${convertToVw('14px')};
  line-height: ${convertToVw('30px')};
  text-align: center;
  position: fixed;
  top: ${(props) => (props.isHeaderInPage ? 0 : '60px')};
  left: 0;
  z-index: ${(props) => (props.isHeaderInPage ? 8 : 21)};
  animation: slideDown ${convertToSecond(FLASH_MESSAGE_ANIMATION_DURATION)}s forwards;
  @keyframes slideDown {
    0% {
      top: ${(props) => (props.isHeaderInPage ? 0 : '-60px')};
    }
    5% {
      top: ${(props) => (props.isHeaderInPage ? '60px' : 0)};
    }
    95% {
      top: ${(props) => (props.isHeaderInPage ? '60px' : 0)};
    }
    100% {
      top: ${(props) => (props.isHeaderInPage ? 0 : '-60px')};
    }
  }

  ${(props) => getColor(props)}

  ${mediaPc`
    font-size: 14px;
    line-height: 45px;
    @keyframes slideDown {
    0% {
      top: ${(props: ThemedStyledProps<Props, DefaultTheme>) => (props.isHeaderInPage ? 0 : '-45px')};
    }
    5% {
      top: ${(props: ThemedStyledProps<Props, DefaultTheme>) => (props.isHeaderInPage ? '70px' : 0)};
    }
    95% {
      top: ${(props: ThemedStyledProps<Props, DefaultTheme>) => (props.isHeaderInPage ? '70px' : 0)};
    }
    100% {
      top: ${(props: ThemedStyledProps<Props, DefaultTheme>) => (props.isHeaderInPage ? 0 : '-45px')};
    }
    }
  `}
`

export const FlashMessage: React.FC<Props> = ({ className = '', flashMessage, isHeaderInPage = true }) => {
  return (
    <StyledFlashMessage className={className} flashMessage={flashMessage} isHeaderInPage={isHeaderInPage}>
      {flashMessage.message}
    </StyledFlashMessage>
  )
}
