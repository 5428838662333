import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { LoadingCircle, FlashMessage } from '../../../components/atoms'
import { Meta } from '../../../Meta'
import { UserMypageProductDetailTpl } from '../../../components/templates'
import { PublishedOrderedProduct, useUserPurchasedProductLazyQuery } from '../../../types/graphql'
import { FlashMessageType } from '../../../types/myTypes'
import { useValidateUserToken } from '../../../lib/ValidateUserToken'

type Props = {} & RouteComponentProps<{ id: string }>

export const UserMypageProductDetail: React.FC<Props> = (props) => {
  const [product, setProduct] = useState<PublishedOrderedProduct>()
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [userPurchasedProductLazyQuery] = useUserPurchasedProductLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setProduct(data.UserPurchasedProduct)
        setLoading(false)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const refetchLiveTalkProductWithTimer = (): void => {
    const currentDate = moment()
    const secondsLeftBeforeFinish = moment(product?.product?.live_talk?.started_at)
      .add(product?.product?.live_talk?.talk_minutes, 'minutes')
      .diff(currentDate)
    setTimeout(() => {
      userPurchasedProductLazyQuery({
        variables: {
          ordered_product_id: props.match.params.id,
        },
      })
    }, secondsLeftBeforeFinish)
  }

  const goToLiveTalk = async (): Promise<void> => {
    setFlashMessage(null)
    const currentDate = moment().format()
    const enterableDate = moment(product?.product.live_talk?.started_at).subtract(5, 'm').format()
    const finishDate = moment(product?.product.live_talk?.started_at).add(Number(product?.product.live_talk?.talk_minutes), 'm').format()
    if (moment(currentDate).isBetween(enterableDate, finishDate)) {
      refetchLiveTalkProductWithTimer()
      window.open(`/live-talk/${product?.product_id}`, '_blank')
    } else {
      setLoading(true)
      setTimeout(() => {
        setFlashMessage({ type: 'systemError', message: 'トーク時間外です。開始5分前にもう一度クリックしてください。' })
        setLoading(false)
        // setFlashMessage(null)待ち
      }, 2000)
    }
  }

  useValidateUserToken()

  useEffect(() => {
    if (props.match.params.id) {
      userPurchasedProductLazyQuery({
        variables: {
          ordered_product_id: props.match.params.id,
        },
      })
    }
  }, [props.match.params.id, userPurchasedProductLazyQuery])

  return (
    <>
      <Meta title="マイページTOP" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {product && <UserMypageProductDetailTpl product={product} goToLiveTalk={goToLiveTalk} />}
    </>
  )
}
