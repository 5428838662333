import React from 'react'
import styled from 'styled-components'
import { Icon, LiveTalkBrowseAttention } from '../../../atoms'
import { PurchasedProductDetail } from '../../../organisms'
import { PublishedOrderedProduct, OrderedProductLiveTalkStatus } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  product: PublishedOrderedProduct
  goToLiveTalk: () => void
}

const Wrapper = styled.div`
  width: 100%;

  .content {
    width: 100vw;
    padding: 5vw 7.5vw 14.1vw;
    background: ${(props): string => props.theme.backgroundColors.gray};

    & > .inner {
      width: 100%;
    }

    .creator-info {
      margin-bottom: 4.3vw;
      display: flex;
      align-items: center;

      .creator-icon {
        width: 10.7vw;
        height: 10.7vw;
        margin-right: 2.1vw;
        border-radius: 50%;
      }

      p {
        font-size: 3.7vw;
        line-height: 2em;
      }
    }
  }

  ${mediaPc`
  .content {
    width: 100%;
    min-width: 1040px;
    padding: 32px 0 67px;

    & > .inner {
      width: 720px;
      margin: 0 auto;
    }

    .creator-info {
      margin-bottom: 17px;

      .creator-icon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }

      p {
        font-size: 14px;
      }
    }
    .browse-attention {
      padding:12px 30px 0;
    }
  }
`}
`

export const UserMypageProductDetailTpl: React.FC<Props> = ({ product, goToLiveTalk }) => {
  const isLiveTalkFinished = product.live_talk_status === OrderedProductLiveTalkStatus.Talked
  const isLiveTalkCanceled = product.live_talk_status === OrderedProductLiveTalkStatus.Canceled
  return (
    <Wrapper>
      <div className="content">
        <div className="inner">
          <div className="creator-info">
            <Icon src={product.creator.icon_url} alt={product.creator.name} className="creator-icon" />
            <p>{product.creator.name}</p>
          </div>
          <PurchasedProductDetail product={product} goToLiveTalk={goToLiveTalk} />
          {!(isLiveTalkFinished || isLiveTalkCanceled) && <LiveTalkBrowseAttention className="browse-attention pc" />}
        </div>
      </div>
    </Wrapper>
  )
}
