import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SubtitleWithLink, SalesTable } from '../../../molecules'
import { SalesPerMonth } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import moment from 'moment'

type Props = {
  className?: string
  sales: SalesPerMonth[]
  affiliationId: string
}

const Wrapper = styled.div`
  width: 100%;

  .contents-title {
    margin-bottom: 2.1vw;
  }

  ${mediaPc`
    width: 100%;

    .contents-title {
      margin-bottom: 5px;

      .subtitle {
        font-size: 14px;
        font-weight: normal;
      }
    }
  `}
`

export const AffiliationSaleThisMonth: React.FC<Props> = ({ className = '', sales, affiliationId }) => {
  const history = useHistory()
  const goToSalesList = (): void => {
    history.push(`/business/sales/affiliation/${affiliationId}`)
  }
  const goBreakDown = (month: string): void => {
    history.push(`/business/sales/affiliation/creator/${moment(month).format('YYYYMM')}?affiliationId=${affiliationId}`)
  }
  return (
    <Wrapper className={className}>
      <SubtitleWithLink className="contents-title" subtitle="今月の売上" linkText="売上一覧" onClick={goToSalesList} />
      <SalesTable sales={sales} noDataText="今月の売上がこちらに表示されます" onClick={(month) => goBreakDown(month)} />
    </Wrapper>
  )
}
