import React, { useState } from 'react'
import styled from 'styled-components'
import { ErrorMessagesBox } from '../../../molecules'
import { TextInput, Button, PageTitle, Link } from '../../../atoms'
import { RegisterUserInput, UserRegisterErrors } from '../../../../types/myTypes'

import { mediaPc } from '../../../../lib/MediaQuery'
import {
  validateEmail,
  validatePassword,
  validatePasswordConfirm,
  validatePasswordAndPasswordConfirm,
  validateTwitterUserName,
} from '../../../../lib/Validate'
import mypageGreen from '../../../../assets/images/icon/mypage_green.png'

type Props = {
  user: RegisterUserInput
  onChangeState: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClickUserRegister: () => void
}

const Wrapper = styled.div`
  text-align: center;
  .title {
    padding: 5vw 7.2vw;
    .page-title {
      width: auto;
      padding-left: 7vw;
      display: inline-block;
      letter-spacing: 0.1em;
      background: url("${mypageGreen}") left center no-repeat;
      background-size: 5vw;
    }
  }
  .inner {
    background: ${(props): string => props.theme.backgroundColors.gray};
    padding 5vw 0 0 0;
    .text-input {
      width: 100%;
      height: 12vw;
      padding: 0 17px;
      display: block;
      margin-bottom: 4.8vw;
      border-radius: 4px;
      color: ${(props): string => props.theme.textColors.black};
      font-size: 4.27vw;
      line-height: 1;
      font-weight: bold;
      letter-spacing: 0.05em;
      &.text-input-twitter {
        padding: 0 15px 0 12%;
      }
      &.error {
        border: 1px solid color: ${(props): string => props.theme.textColors.red};
      }
      &::placeholder {
        font-size: 3.2vw;
        line-height: 1.875;
      }
      &.text-input-confirm {
        margin-top: 25px;
      }
    }
    .text-input-twitter-wrap {
      position: relative;
      width: 100%;
      height: 12vw;
      margin-bottom: 4.8vw;
      &::before {
        margin-right: 5px;
        content: '@';
        line-height: 2.4em;
        color: #ccc;
        display: block;
        font-size: 3.733vw;
        position: absolute;
        top: 15%;
        left: 5%;
      }
    }
    .button {
      margin: 5.6vw 0 4vw 0;
      font-size: 3.733vw;
      font-weight: bold;
    }
    .error-messages {
      text-align: left;
    }

    .go-to-login {
      text-align: center;
      font-size: 3.2vw;
      display: block;
    }
  }
  ${mediaPc`
  .title {
    padding:80px 20px 45px 0px;
    display: flex;
    justify-content: center;
    .page-title {
      padding-left: 50px;
      letter-spacing: 0.3em;
      background-size: 32px;
    }
  }
    .inner {
      padding: 69px 0 64px 0;
      .text-input {
        width: 320px;
        height: 40px;
        margin: 0 auto 20px;
        padding: 0 15px;
        font-size: 12px;
        &::placeholder {
          font-size: 12px;
        }
        &.text-input-twitter {
          padding-left: 30px;
        }
      }
      .text-input-twitter-wrap {
        width: 320px;
        height: 40px;
        margin: 0 auto 20px;
        &::before {
          margin-right: 5px;
          line-height: 2.4em;
          font-size: 14px;
          top: 3px;
          left: 10px;
        }
      }
      .error-messages {
        width: 320px;
        margin: 25px auto 25px;
        padding: 10px 15px;
        p {
          font-size: 12px;
        }
      }

      .button {
        width: 320px;
        height: 40px;
        line-height: 40px;
        margin: 0 auto 13px;
        padding: 0 15px;
        font-size: 14px;
      }

      .go-to-login {
        width: 320px;
        margin: 0 auto;
        font-size: 12px;
        text-align: right;
      }
    }
  `}
`
export const UserRegistrationForm: React.FC<Props> = ({ user, onChangeState, onClickUserRegister }) => {
  const [errors, setErrors] = useState<UserRegisterErrors>({
    name: null,
    email: null,
    password: null,
    password_confirm: null,
    password_match: null,
    twitter_username: null,
  })

  // ErrorMessagesBoxに渡すために配列作成
  let errorsArray: string[] = []
  errorsArray = Object.values(errors).filter((v) => v) as string[]

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setErrors({ ...errors, name: null })
    if (!event.target.value) {
      const errorText = 'ユーザー名を入力してください'
      setErrors({ ...errors, name: errorText })
    }
    onChangeState(event)
  }

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorText: string | null = validateEmail(event.target.value)
    setErrors({ ...errors, email: null })
    if (errorText) {
      setErrors({ ...errors, email: errorText })
    }
    onChangeState(event)
  }

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorText: string | null = validatePassword(event.target.value)
    const matchErrorText: string | null = validatePasswordAndPasswordConfirm(event.target.value, user.password_confirm)
    setErrors({ ...errors, password: null, password_match: null })
    if (errorText) {
      setErrors({ ...errors, password: errorText, password_match: null })
    } else if (matchErrorText) {
      setErrors({ ...errors, password: null, password_match: matchErrorText })
    }
    onChangeState(event)
  }

  const onChangePasswordConfirm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorText: string | null = validatePasswordConfirm(event.target.value)
    const matchErrorText: string | null = validatePasswordAndPasswordConfirm(user.password, event.target.value)
    setErrors({ ...errors, password_confirm: null, password_match: null })
    if (errorText) {
      setErrors({ ...errors, password_confirm: errorText, password_match: null })
    } else if (matchErrorText) {
      setErrors({ ...errors, password_confirm: null, password_match: matchErrorText })
    }
    onChangeState(event)
  }

  const onChangeTwitterUserName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const errorText: string | null = validateTwitterUserName(event.target.value)
    setErrors({ ...errors, twitter_username: null })
    if (errorText) {
      setErrors({ ...errors, twitter_username: errorText })
    }
    onChangeState(event)
  }

  const isDisable = (): boolean => {
    if (!user.name || !user.email || !user.password || !user.password_confirm || errorsArray.length > 0) {
      return true
    }
    return false
  }

  return (
    <Wrapper>
      <div className="title">
        <PageTitle title="新規登録" className="page-title" />
      </div>
      <div className="inner">
        <TextInput
          className="text-input"
          placeholder="ユーザー名(登録後に変更が可能です)"
          type="text"
          name="name"
          value={user.name}
          error={!!errors.name}
          onChange={onChangeName}
        />
        <TextInput
          className="text-input"
          placeholder="メールアドレス"
          type="email"
          name="email"
          value={user.email}
          error={!!errors.email}
          onChange={onChangeEmail}
        />
        <TextInput
          className="text-input"
          placeholder="パスワード"
          type="password"
          name="password"
          value={user.password}
          error={!!errors.password || !!errors.password_match}
          onChange={onChangePassword}
        />
        <TextInput
          className="text-input"
          placeholder="確認用パスワード"
          type="password"
          name="password_confirm"
          value={user.password_confirm}
          error={!!errors.password_confirm || !!errors.password_match}
          onChange={onChangePasswordConfirm}
        />

        <div className="text-input-twitter-wrap">
          <TextInput
            className="text-input text-input-twitter"
            placeholder="twitterユーザー名【任意】"
            type="text"
            name="twitter_username"
            value={user.twitter_username}
            error={!!errors.twitter_username}
            onChange={onChangeTwitterUserName}
          />
        </div>

        {errorsArray.length > 0 && <ErrorMessagesBox className="error-messages" errors={errorsArray} />}
        <Button className="button" onClick={onClickUserRegister} buttonType="primary" disabled={isDisable()}>
          新規登録
        </Button>
        <Link text="アカウントをお持ちの方はこちらから" url="/login" className="go-to-login" />
      </div>
    </Wrapper>
  )
}
