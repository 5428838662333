import React from 'react'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  textType?: 'red' | 'lightGray'
  onClick?: () => void
  children?: React.ReactNode
}

const getTextColor = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  switch (props.textType) {
    case 'red':
      return `${props.theme.textColors.red}`
    case 'lightGray':
      return `${props.theme.textColors.lightGray}`
    default:
      return `${props.theme.buttonColors.primary}`
  }
}

const StyledButton = styled.button<Props>`
  font-size: 3.2vw;
  color: ${(props): string => getTextColor(props)};
  line-height: 1.65;
  letter-spacing: 0.05em;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }

  ${mediaPc`
    font-size: 14px;
  `}
`

export const TextButton: React.FC<Props> = ({ className = '', textType, children, onClick }) => {
  return (
    <StyledButton className={className} onClick={onClick} textType={textType}>
      {children}
    </StyledButton>
  )
}
