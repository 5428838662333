import React from 'react'
import styled from 'styled-components'
import { PageSubtitle, Button } from '../../atoms'
import { convertToVw } from '../../../lib/ConvertSize'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  subtitle?: string
  subtitleInfo?: string
  buttonText?: string
  buttonType?: 'primary' | 'gray' | 'black' | 'red'
  onClick?: () => void
}

const TitleRow = styled.div`
  .subtitle-row {
    margin-bottom: 2.5vw;
    .subtitle-info {
      margin-top: 1vw;
      font-size: ${convertToVw('10px')};
      font-weight: normal;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.gray};
    }
  }

  ${mediaPc`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subtitle-row {
      display: flex;
      margin: 0;
      
      .subtitle {
        width: auto;
        margin-right: 8px;
      }

      .subtitle-info {
        width: auto;
        margin: 0;
        font-size: 10px;
        line-height: 24px;
      }
    }

    .button {
      width: 150px;
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      font-weight: bold;
      border-radius: 4px;
      transition: 0.3s all;
    }
  `}
`

export const SubtitleWithButton: React.FC<Props> = ({ className = '', subtitle = '', subtitleInfo, buttonText, buttonType, onClick }) => {
  return (
    <TitleRow className={className}>
      <div className="subtitle-row">
        <PageSubtitle subtitle={subtitle} className="subtitle" />
        {subtitleInfo && <PageSubtitle subtitle={subtitleInfo} className="subtitle-info" />}
      </div>
      <Button onClick={onClick} buttonType={buttonType} className="button">
        {buttonText}
      </Button>
    </TitleRow>
  )
}
