import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Icon, Button } from '../../atoms'
import favoritePink from '../../../assets/images/icon/favorite_pink.png'
import favoriteWhite from '../../../assets/images/icon/favorite_white.png'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  displayRank?: boolean
  rank?: number
  link: string
  iconSrc?: string
  iconAlt?: string
  creatorName?: string
  creatorId?: string
  displayFavorite?: boolean
  isFavorite?: boolean | null
  onClickFavorite?: (creatorId: string, isFavorite: boolean) => void
}

const Wrapper = styled.div`
  width: 39vw;
  position: relative;

  .rank {
    width: 8.5vw;
    height: 8.5vw;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    border: 1px solid ${(props): string => props.theme.borderColors.black};
    text-align: center;
    font-size: 4.27vw;
    font-weight: bold;
    line-height: 8.5vw;
    background: ${(props): string => props.theme.backgroundColors.bodyBg};
  }

  .creator-icon {
    width: 100%;
    height: 39vw;
    margin-bottom: 4vw;
    border-radius: 50%;
    object-fit: cover;
  }

  .creator-name {
    color: ${(props): string => props.theme.textColors.black};
    font-size: 3.2vw;
    font-weight: bold;
    text-align: center;
  }

  .favorite {
    width: 8.5vw;
    height: 8.5vw;
    position: absolute;
    top: 31.5vw;
    right: 0;
    z-index: 5;
    border-radius: 50%;
    background: url(${favoritePink}) center 55% no-repeat ${(props): string => props.theme.backgroundColors.bodyBg};
    background-size: 55%;

    &.is-favorite {
      background: url(${favoriteWhite}) center 55% no-repeat ${(props): string => props.theme.backgroundColors.red};
      background-size: 55%;
    }
  }

  ${mediaPc`
      width: 146px;

      .rank {
        width: 30px;
        height: 30px;
        font-size: 18px;
        line-height: 28px;
      }

      .creator-icon {
        height: 146px;
        margin-bottom: 20px;
        transition: 0.3s all;
      }

      .creator-name {
        font-size: 12px;
      }

      .favorite {
        width: 32px;
        height: 32px;
        top: 114px;
      }
    `}
`

export const CreatorIconWithName: React.FC<Props> = ({
  className,
  displayRank = false,
  rank,
  link,
  iconSrc,
  iconAlt,
  creatorName,
  creatorId,
  displayFavorite = true,
  isFavorite = false,
  onClickFavorite = () => {},
}) => {
  return (
    <Wrapper className={className}>
      {displayRank && <div className="rank">{rank}</div>}
      <Link to={link}>
        <Icon src={iconSrc} alt={iconAlt} className="creator-icon" />
        <p className="creator-name">{creatorName}</p>
      </Link>
      {displayFavorite && creatorId && isFavorite !== null && (
        <Button
          onClick={() => {
            onClickFavorite(creatorId, isFavorite)
          }}
          className={isFavorite ? 'favorite is-favorite' : 'favorite'}
        />
      )}
    </Wrapper>
  )
}
