import React from 'react'
import styled from 'styled-components'
import { ProductListOnSaleItem } from '../../molecules'
import { Product, PublishedProduct } from '../../../types/graphql'

import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  products: (Product | PublishedProduct)[]
  oneLineItemsNum?: number
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mediaPc`
    padding: 0;
  `}
`

export const ProductListOnSale: React.FC<Props> = ({ className, products, oneLineItemsNum }) => {
  return (
    <Wrapper className={className}>
      {products.map((product: Product | PublishedProduct, index: number) => (
        <ProductListOnSaleItem product={product} key={index} oneLineItemsNum={oneLineItemsNum} />
      ))}
    </Wrapper>
  )
}
