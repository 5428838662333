import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import { Button, ModalTitle, RadioButtonByImage } from '../../atoms'
import { Modal } from '..'
import { ThumbnailEditingType, ThumbnailEditingTypeEnum } from '../../../types/myTypes'

type Props = {
  originalThumbnailUrl: string
  originalThumbnailFile: File | null
  blurWeakWhiteThumbnailUrl: string
  blurWeakWhiteThumbnailFile: File | null
  blurStrongWhiteThumbnailUrl: string
  blurStrongWhiteThumbnailFile: File | null
  blurWeakBlackThumbnailUrl: string
  blurWeakBlackThumbnailFile: File | null
  blurStrongBlackThumbnailUrl: string
  blurStrongBlackThumbnailFile: File | null
  thumbnailEditingType: ThumbnailEditingType
  setThumbnailEditingType: (thumbnailEditingType: ThumbnailEditingType) => void
  closeModal: () => void
  setProductThumbnailUrlAfterEditSelecting: (url: string) => void
  setProductThumbnailFileAfterEditSelecting: (file: File | null) => void
}

const ModalContents = styled.div`
  padding: 32px 0;
  .modal-title {
    margin-bottom: 8vw;
  }
  .input-item {
    width: 80vw;
    margin: 0 auto;
    display: block;
  }
  .select-img {
    margin: 0 0 4.4vw;
    > div {
      width: 47%;
    }
  }
  .complete-selecting {
    width: 53vw;
    font-size: 3.2vw;
    line-height: 8vw;
    display: block;
    margin: 0 auto;
  }

  ${mediaPc`
    padding: 22px 22px 43px;
    .modal-title {
      margin-bottom: 30px;
    }
    .input-item {
      width: 480px;
    }
    .select-img {
      margin: 0 0 14px;
    }
    .complete-selecting {
      width: 200px;
      font-size: 12px;
      line-height: 30px;
    }
  `}
`

export const ProductThumbnailEditSelectingModalTpl: React.FC<Props> = ({
  originalThumbnailUrl,
  originalThumbnailFile,
  blurWeakWhiteThumbnailUrl,
  blurWeakWhiteThumbnailFile,
  blurStrongWhiteThumbnailUrl,
  blurStrongWhiteThumbnailFile,
  blurWeakBlackThumbnailUrl,
  blurWeakBlackThumbnailFile,
  blurStrongBlackThumbnailUrl,
  blurStrongBlackThumbnailFile,
  thumbnailEditingType,
  setThumbnailEditingType,
  closeModal,
  setProductThumbnailUrlAfterEditSelecting,
  setProductThumbnailFileAfterEditSelecting,
}) => {
  const onCompleteEditSelecting = (): void => {
    if (thumbnailEditingType === ThumbnailEditingTypeEnum.BLUR_WEAK_WHITE) {
      setProductThumbnailUrlAfterEditSelecting(blurWeakWhiteThumbnailUrl)
      if (blurWeakWhiteThumbnailFile) {
        setProductThumbnailFileAfterEditSelecting(blurWeakWhiteThumbnailFile)
      }
    } else if (thumbnailEditingType === ThumbnailEditingTypeEnum.BLUR_STRONG_WHITE) {
      setProductThumbnailUrlAfterEditSelecting(blurStrongWhiteThumbnailUrl)
      if (blurStrongWhiteThumbnailFile) {
        setProductThumbnailFileAfterEditSelecting(blurStrongWhiteThumbnailFile)
      }
    } else if (thumbnailEditingType === ThumbnailEditingTypeEnum.BLUR_WEAK_BLACK) {
      setProductThumbnailUrlAfterEditSelecting(blurWeakBlackThumbnailUrl)
      if (blurWeakBlackThumbnailFile) {
        setProductThumbnailFileAfterEditSelecting(blurWeakBlackThumbnailFile)
      }
    } else if (thumbnailEditingType === ThumbnailEditingTypeEnum.BLUR_STRONG_BLACK) {
      setProductThumbnailUrlAfterEditSelecting(blurStrongBlackThumbnailUrl)
      if (blurStrongBlackThumbnailFile) {
        setProductThumbnailFileAfterEditSelecting(blurStrongBlackThumbnailFile)
      }
    } else {
      setProductThumbnailUrlAfterEditSelecting(originalThumbnailUrl)
      setProductThumbnailFileAfterEditSelecting(originalThumbnailFile)
    }
    closeModal()
  }

  return (
    <Modal closeModal={closeModal}>
      <ModalContents>
        <ModalTitle className="modal-title" title="加工方法を選択してください" />
        <div className="input-item">
          <RadioButtonByImage
            className={'select-img'}
            checkedValue={thumbnailEditingType}
            buttons={[
              {
                id: ThumbnailEditingTypeEnum.BLUR_WEAK_WHITE,
                name: 'thumbnail_editing_type',
                value: ThumbnailEditingTypeEnum.BLUR_WEAK_WHITE,
                imageSrc: blurWeakWhiteThumbnailUrl,
                imageAlt: 'BlurWeakWhite',
                onChange: () => {
                  setThumbnailEditingType(ThumbnailEditingTypeEnum.BLUR_WEAK_WHITE)
                },
              },
              {
                id: ThumbnailEditingTypeEnum.BLUR_STRONG_WHITE,
                name: 'thumbnail_editing_type',
                value: ThumbnailEditingTypeEnum.BLUR_STRONG_WHITE,
                imageSrc: blurStrongWhiteThumbnailUrl,
                imageAlt: 'BlurStrongWhite',
                onChange: () => {
                  setThumbnailEditingType(ThumbnailEditingTypeEnum.BLUR_STRONG_WHITE)
                },
              },
              {
                id: ThumbnailEditingTypeEnum.BLUR_WEAK_BLACK,
                name: 'thumbnail_editing_type',
                value: ThumbnailEditingTypeEnum.BLUR_WEAK_BLACK,
                imageSrc: blurWeakBlackThumbnailUrl,
                imageAlt: 'BlurWeakBlack',
                onChange: () => {
                  setThumbnailEditingType(ThumbnailEditingTypeEnum.BLUR_WEAK_BLACK)
                },
              },
              {
                id: ThumbnailEditingTypeEnum.BLUR_STRONG_BLACK,
                name: 'thumbnail_editing_type',
                value: ThumbnailEditingTypeEnum.BLUR_STRONG_BLACK,
                imageSrc: blurStrongBlackThumbnailUrl,
                imageAlt: 'BlurStrongBlack',
                onChange: () => {
                  setThumbnailEditingType(ThumbnailEditingTypeEnum.BLUR_STRONG_BLACK)
                },
              },
            ]}
          />
        </div>
        <Button buttonType="primary" className="complete-selecting" onClick={onCompleteEditSelecting}>
          OK
        </Button>
      </ModalContents>
    </Modal>
  )
}
