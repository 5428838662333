import React, { useState, useEffect, ChangeEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  useCreatorInBusinessLazyQuery,
  OrderedUser,
  usePagedBusinessOrderedUsersLazyQuery,
  OrderedUsersSort,
} from '../../../types/graphql'

import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { BusinessCreatorOrderedUsersTpl } from '../../../components/templates/Business/Creator/OrderedUsers'
import { orderedUsersSortItems } from '../../../lib/UsersOrderSortItems'

type Props = {} & RouteComponentProps<{ id: string; user_id: string }>

export const BusinessCreatorOrderedUsers: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [orderedUsers, setOrderedUsers] = useState<OrderedUser[]>([])

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [selectedValue, setSelectedValue] = useState<string>(OrderedUsersSort.LastPaidAtDesc)

  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [creatorInBusinessLazyQuery] = useCreatorInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setCreator(data.CreatorInBusiness)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedBusinessOrderedUsersLazyQuery] = usePagedBusinessOrderedUsersLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setOrderedUsers(data.PagedBusinessOrderedUsers?.data ?? [])
        setLastPage(data.PagedBusinessOrderedUsers?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    creatorInBusinessLazyQuery({
      variables: {
        id: props.match.params.id,
      },
    })
    pagedBusinessOrderedUsersLazyQuery({
      variables: {
        creator_id: props.match.params.id,
        first: 30,
        page: Number(pageParam),
        sort: OrderedUsersSort['LastPaidAtDesc'],
      },
    })
  }, [
    creatorInBusinessLazyQuery,
    props.match.params.id,
    props.match.params.user_id,
    props.location.search,
    pagedBusinessOrderedUsersLazyQuery,
  ])

  const onChangeDropdown = (e: ChangeEvent<HTMLSelectElement>): void => {
    pagedBusinessOrderedUsersLazyQuery({
      variables: {
        first: 30,
        page: 1,
        sort: e.target.value,
        creator_id: props.match.params.id,
      },
    })
    setSelectedValue(e.target.value)
  }

  return (
    <>
      <Meta title={`${creator?.name}さん 購入者一覧`} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <>
          {creator && (
            <BusinessCreatorOrderedUsersTpl
              creator={creator}
              orderedUsers={orderedUsers}
              currentPage={currentPage}
              lastPage={lastPage}
              selectedValue={selectedValue}
              orderedUsersSortItems={orderedUsersSortItems}
              onChangeDropdown={onChangeDropdown}
            />
          )}
        </>
      </BusinessTwoColumnLayout>
    </>
  )
}
