import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessTwoColumnLayout, AffiliationSalesPerMonthTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Affiliation,
  SalesPerMonth,
  useAffiliationsInBusinessLazyQuery,
  useAffiliationSalesPerMonthLazyQuery,
  useBusinessSalesPerMonthLazyQuery,
} from '../../../types/graphql'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessAffiliationSalesPerMonth: React.FC<Props> = (props) => {
  const [affiliations, setAffiliations] = useState<Affiliation[]>([])
  const [sales, setSales] = useState<SalesPerMonth[]>([])
  const [selectedAffiliation, setSelectedAffiliation] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()

  const [affiliationsInBusinessLazyQuery] = useAffiliationsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setAffiliations(data.AffiliationsInBusiness)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [businessSalesPerMonthLazyQuery] = useBusinessSalesPerMonthLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setSales(data.BusinessSalesPerMonth.sales_per_month)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [affiliationSalesPerMonthLazyQuery] = useAffiliationSalesPerMonthLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setSales(data.AffiliationSalesPerMonth.sales_per_month)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const onAffiliationChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedAffiliation(e.target.value)
    history.push(`/business/sales/affiliation/${e.target.value}`)
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    setLoading(true)
    affiliationsInBusinessLazyQuery()
    if (props.match.params.id) {
      const affiliationId = props.match.params.id === '0' ? null : props.match.params.id
      affiliationSalesPerMonthLazyQuery({
        variables: {
          affiliation_id: affiliationId,
        },
      })
    } else {
      businessSalesPerMonthLazyQuery()
    }
    setSelectedAffiliation(props.match.params.id ?? '')
  }, [affiliationSalesPerMonthLazyQuery, affiliationsInBusinessLazyQuery, businessSalesPerMonthLazyQuery, props.match.params.id])

  return (
    <>
      <Meta title="売上一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="sales">
        <AffiliationSalesPerMonthTpl
          affiliationId={props.match.params.id}
          selectedAffiliation={selectedAffiliation}
          affiliations={affiliations}
          sales={sales}
          onAffiliationChange={onAffiliationChange}
        />
      </BusinessTwoColumnLayout>
    </>
  )
}
