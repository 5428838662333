import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageTitle, Button } from '../../../atoms'
import { mediaPc } from '../../../../lib/MediaQuery'
import mypageGreen from '../../../../assets/images/icon/mypage_green.png'

type Props = {
  email: string
  onClickResendConfirmMail: () => void
}

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props): string => props.theme.backgroundColors.gray};
  padding: 0 7.2vw 10.4vw;
  .page-title {
    padding: 5vw 7.2vw;
    text-align: center;
    .page-title-text {
      width: auto;
      padding-left: 7vw;
      display: inline-block;
      letter-spacing: 0.1em;
      background: url('${mypageGreen}') left center no-repeat;
      background-size: 5vw;
    }
  }
  .inner {
    background: ${(props): string => props.theme.backgroundColors.gray};
    padding-top: 5vw;
    text-align: left;
    .title {
      margin-bottom: 4.2vw;
      font-size: 4.267vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.black};
      text-align: center;
    }

    .info {
      width: 100%;
      margin-bottom: 2.6vw;
      font-size: 3.467vw;
      word-wrap: wrap;
      word-break: break-all;
      color: ${(props): string => props.theme.textColors.gray};
      &.lead-text {
        margin-bottom: 17.6vw;
      }
    }

    .email {
      min-height: 46px;
      margin-bottom: 2.6vw;
      padding: 17px;
      font-size: 3.467vw;
      color: ${(props): string => props.theme.textColors.black};
      border: 1px solid ${(props): string => props.theme.borderColors.black};
      border-radius: 4px;
      line-height: 1.6em;
      text-align: center;
    }

    .resend {
      margin: 0 0 3.1vw;
      font-size: 3.2vw;
      line-height: 1.5em;
      color: ${(props): string => props.theme.textColors.red};
    }
  }
  ${mediaPc`
    background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 0 0 190px;
    .page-title {
      padding:80px 20px 45px 0;
      display: flex;
      justify-content: center;
      .page-title-text {
        padding-left: 50px;
        letter-spacing: 0.3em;
        background-size: 32px;
      }
    }
    .inner {
      padding:42px 0 40px;
      .title {
        margin-bottom: 15px;
        font-size: 16px;
      }

      .info {
        margin-bottom: 23px;
        font-size: 12px;
        text-align: center;
        &.lead-text {
          margin-bottom: 72px;
        }
      }

      .email {
        width: 320px;
        margin: 0 auto 16px;
        background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
      }

      .resend {
        margin: 0 0 23px;
        font-size: 12px;
        text-align: center;
      }

      .resend-button {
        width: 320px;
        margin: 0 auto;
        display: block;
        font-size: 12px;
        line-height: 40px;
      }
    }
  `}
`

export const UserRegistrationCompleteTpl: React.FC<Props> = ({ email, onClickResendConfirmMail }) => {
  return (
    <Wrapper>
      <div className="page-title">
        <PageTitle title="新規登録" className="page-title-text" />
      </div>
      <div className="inner">
        <p className="title">登録はまだ完了しておりません</p>
        <p className="info">下記のメールアドレスに確認メールをお送りさせていただいております。</p>
        <p className="email">{email}</p>
        <p className="info lead-text">メール本文に記載のURLから認証を行っていただくと登録が完了となります。</p>
        <p className="resend">万が一、メールが届いていない場合は、確認メールの再送ボタンを押してください</p>
        <Button buttonType="gray" onClick={onClickResendConfirmMail} className="resend-button">
          確認メールの再送
        </Button>
      </div>
    </Wrapper>
  )
}
