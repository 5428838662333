import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorDisabledProductsTpl, CreatorTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { Product, usePagedCreatorDisabledProductsLazyQuery, useCreatorDisplayProductMutation } from '../../../types/graphql'

import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'

type Props = {} & RouteComponentProps

export const CreatorDisabledProducts: React.FC<Props> = (props) => {
  const [disabledProducts, setDisabledProducts] = useState<Product[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [pagedCreatorDisabledProductsLazyQuery] = usePagedCreatorDisabledProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setLoading(false)
        setDisabledProducts(data.PagedCreatorDisabledProducts?.data ?? [])
        setLastPage(data.PagedCreatorDisabledProducts?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [creatorDisplayProductMutation] = useCreatorDisplayProductMutation({
    onCompleted: () => {
      pagedCreatorDisabledProductsLazyQuery({
        variables: {
          first: 20,
          page: currentPage,
        },
      })
      setFlashMessage({ type: 'success', message: '選択した商品を再表示しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickToDisplay = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    creatorDisplayProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }

  useValidateCreatorToken()
  useSetCreatorProfile()
  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    pagedCreatorDisabledProductsLazyQuery({
      variables: {
        first: 20,
        page: Number(pageParam),
      },
    })
  }, [props.location.search, pagedCreatorDisabledProductsLazyQuery])

  return (
    <>
      <Meta title="非表示の商品一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="products">
        <CreatorDisabledProductsTpl
          currentPage={currentPage}
          lastPage={lastPage}
          disabledProducts={disabledProducts}
          onClickToDisplay={onClickToDisplay}
        />
      </CreatorTwoColumnLayout>
    </>
  )
}
