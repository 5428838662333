import React from 'react'
import { TermsOfUseTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'

type Props = {}

export const TermsOfUse: React.FC<Props> = () => {
  return (
    <>
      <Meta title="利用規約" />
      <TermsOfUseTpl />
    </>
  )
}
