import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { UserRegistrationCompleteTpl } from '../../../components/templates'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { useResendUserAccountRegistrationMutation } from '../../../types/graphql'

export const UserRegistrationComplete: React.FC = () => {
  const [userId, setUserId] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const location = useLocation<{ id: string; email: string }>()

  const [resendUserAccountRegistrationMutation, { loading }] = useResendUserAccountRegistrationMutation({
    onCompleted: () => {
      setFlashMessage({ type: 'success', message: '確認メールを再送しました' })
    },
    onError: (e) => {
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '確認メールを再送できませんでした' })
      }
    },
  })
  const onClickResendConfirmMail = (): void => {
    resendUserAccountRegistrationMutation({
      variables: {
        user_id: userId,
        input: {
          email: email,
        },
      },
    })
  }
  useEffect(() => {
    setUserId(location.state.id)
    setEmail(location.state.email)
  }, [location.state])
  return (
    <>
      <Meta title="新規登録確認メール送信" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <UserRegistrationCompleteTpl email={email} onClickResendConfirmMail={onClickResendConfirmMail} />
    </>
  )
}
