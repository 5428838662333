import React from 'react'
import styled from 'styled-components'
import { AffiliationList, SubtitleWithButton, Pagination } from '../../molecules'
import { Affiliation } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  affiliations: Affiliation[]
  lastPage: number
  currentPage: number
  openAffiliationRegistrationModal: () => void
}

const Wrapper = styled.div`
  .title {
    margin-bottom: 5vw;
  }
  .affiliation-list {
    margin: 0 0 10.7vw;
  }
  .no-data {
    font-size: 3.735vw;
    text-align: center;
    font-weight: 500;
    color: ${(props): string => props.theme.textColors.gray};
    line-height: 1.14;
    letter-spacing: 0.05em;
  }
  ${mediaPc`
    .title {
      margin-bottom: 20px;
    }
    .affiliation-list {
      margin: 0 0 40px;
    }
    .no-data {
      margin-top: 30px;
      font-size: 14px;
    }
  `}
`

export const BusinessAffiliations: React.FC<Props> = ({ affiliations, lastPage, currentPage, openAffiliationRegistrationModal }) => {
  return (
    <Wrapper>
      {!!affiliations.length && (
        <>
          <SubtitleWithButton
            subtitle="所属リスト一覧"
            subtitleInfo="※クリエイターが所属する店舗、カテゴリ等を作成してください"
            buttonType="red"
            buttonText="+ 所属新規追加"
            className="title"
            onClick={openAffiliationRegistrationModal}
          />
          <AffiliationList className="affiliation-list" affiliations={affiliations} />
          <Pagination lastPage={lastPage} currentPage={currentPage} />
        </>
      )}
      {!affiliations.length && <div className="no-data">登録中の所属事務所はありません</div>}
    </Wrapper>
  )
}
