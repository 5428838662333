import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PageSubtitle } from '../../../atoms'
import { SalesTable } from '../../../molecules'
import { Creator, SalesPerMonth } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import moment from 'moment'

type Props = {
  creator: Creator
  sales: SalesPerMonth[]
}

const Wrapper = styled.div`
  .title {
    width: 100%;
    margin-bottom: 4.8vw;
  }
  ${mediaPc`
    .title {
      font-size: 16px;
      margin-bottom: 32px;
    }
  `}
`

export const BusinessCreatorSalesPerMonth: React.FC<Props> = ({ creator, sales }) => {
  const history = useHistory()
  const goToBreakDown = (month: string): void => {
    history.push(`/business/sales/affiliation/creator/${creator.id}/${moment(month).format('YYYYMM')}`)
  }
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle={`${creator.name}さんの売上一覧`} />
      <SalesTable sales={sales} noDataText="月別の売上が一覧で表示されます" onClick={(month) => goToBreakDown(month)} />
    </Wrapper>
  )
}
