import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { mediaPc } from '../../../lib/MediaQuery'
import { ProductImage, ProductStockLabel, MovieDuration, Icon } from '../../atoms'
import { Product, PublishedProduct, ProductType } from '../../../types/graphql'
import { userType } from '../../../lib/UserType'

import moment from 'moment'
import liveTalkIcon from '../../../assets/images/icon/live_talk.png'

type Props = {
  className?: string
  product: Product | PublishedProduct
  oneLineItemsNum?: number
}

const StyledProductListOnSaleItem = styled.div<{ oneLineItemsNum: number | undefined }>`
  width: 47%;
  margin-bottom: 6.6vw;
  padding: 0;
  a {
    color: ${(props): string => props.theme.textColors.black};
  }
  .product-image {
    position: relative;
    margin-bottom: 2.6vw;

    .movie-duration {
      position: absolute;
      bottom: 6px;
      left: 6px;
      z-index: 5;
    }
    .live-talk-icon {
      position: absolute;
      width: 16.5vw;
      z-index: 5;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
    }
  }
  .product-name {
    width: 100%;
    font-size: 3.735vw;
    font-weight: bold;
    letter-spacing: 0.05em;
    display: flex;
    margin-bottom: 2.6vw;
    word-break: break-all;
  }
  .row {
    display: flex;
    justify-content: space-between;
    .price {
      width: 33%;
      margin-right: 2%;
      font-size: 3.2vw;
      font-weight: bold;
      display: inline-block;
      letter-spacing: 0.05em;
      line-height: 3.2vw;
    }
    .released-date {
      width: 61%;
      font-size: 2.4vw;
      display: inline-block;
      letter-spacing: 0.05em;
      text-align: right;
      line-height: 3.2vw;
      color: ${(props): string => props.theme.textColors.placeholder};
    }
  }

  ${mediaPc`
    width: 148px;
    margin: 0 0 34px 0;
    &:last-child {
      margin-right: auto;
    }
    .product-image {
      width: 100%;
      height: auto;
      margin-bottom: 0;

      .movie-duration {
          width: 96px;
          height: 28px;
          line-height: 28px;
          background-size: 20px;
          background-position: 5px;
          font-size: 12px;
          padding: 0 5px 0 30px;
      }

      .live-talk-icon {
        width: 62px;
      }
    }
    .product-name {
      padding: 10px 0 5px;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 0;
      display: block;
    }
    .row {
      .price {
        font-size: 10px;
        font-weight:bold;
        width: auto;
        line-height: inherit;
      }
      .released-date {
        width: auto;
        font-size: 10px;
        line-height: inherit;
      }
    }
  `}
  ${(props) =>
    props.oneLineItemsNum !== undefined &&
    css`
      ${mediaPc`
        margin: 0 calc( (100% - (148px * ${props.oneLineItemsNum}) ) / (${props.oneLineItemsNum} - 1) ) 48px 0;
        &:nth-child(${props.oneLineItemsNum}n) {
          margin-right: 0;
        }
        &:last-child {
          margin-right: auto;
        }
      `}
    `}
`

export const ProductListOnSaleItem: React.FC<Props> = ({ className = '', product, oneLineItemsNum }) => {
  let productDetailUrl = ''
  if (userType() === 'business') {
    productDetailUrl = `/business/product/${product.id}`
  } else if (userType() === 'creator') {
    productDetailUrl = `/creator/product/${product.id}/user`
  } else if (userType() === 'user') {
    productDetailUrl = `/product/${product.id}`
  }

  return (
    <StyledProductListOnSaleItem className={className} oneLineItemsNum={oneLineItemsNum}>
      <Link to={productDetailUrl}>
        <div className="product-image">
          <ProductImage src={product.thumbnail_image} />
          <ProductStockLabel status={product.status} stock={product.remaining_stock} type={product.product_type} />
          {product.product_type === ProductType.Movie && <MovieDuration className="movie-duration" duration={product.movie_seconds} />}
          {product.product_type === ProductType.LiveTalk && <Icon className="live-talk-icon" src={liveTalkIcon} alt="ライブトーク" />}
        </div>
        <div className="product-name">{product.name}</div>
        <div className="row">
          <p className="price">¥{product.price.toLocaleString()}</p>
          <p className="released-date">{moment(product.released_at).format('YYYY-MM-DD')} 公開</p>
        </div>
      </Link>
    </StyledProductListOnSaleItem>
  )
}
