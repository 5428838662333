import React from 'react'
import styled from 'styled-components'
import { PageSubtitle } from '../../../atoms'
import { SelectboxWithLabel } from '../../../molecules'
import { Creator, Affiliation } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import moment from 'moment'

type Props = {
  className?: string
  affiliationName?: Affiliation['name']
  month: string
  creators: Creator[]
  selectedCreator: string
  onCreatorChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  width: 100%;

  .order-title {
    margin-bottom: 2.1vw;
    font-size: 2.6vw;
  }

  ${mediaPc`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .order-title {
      margin: 0;
      font-size: 14px;
      font-weight: normal;
      line-height: 36px;
    }

    .creator-select {
      width: 200px;
    }
  `}
`

export const CreatorSalesPerOrderTitle: React.FC<Props> = ({
  className = '',
  affiliationName = '',
  month = '',
  creators,
  selectedCreator,
  onCreatorChange,
}) => {
  // }
  const creatorOptions: { value: string; label: string }[] = [{ value: '', label: '全て' }]
  creators.forEach((creator) => {
    creatorOptions.push({ value: creator.id, label: creator.name })
  })

  return (
    <Wrapper className={className}>
      <PageSubtitle subtitle={`所属：${affiliationName ?? ''}　${moment(month).format('YYYY年M月')}`} className="order-title" />
      <SelectboxWithLabel
        label="クリエイター絞り込み"
        options={creatorOptions}
        value={selectedCreator}
        onChange={onCreatorChange}
        className="creator-select"
      />
    </Wrapper>
  )
}
