import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'
import { convertToVw } from '../../lib/ConvertSize'

type Props = {
  message: string
  className?: string
}

const StyledNews = styled.p`
  width: 100vw;
  font-size: ${convertToVw('14px')};
  line-height: ${convertToVw('30px')};
  background: ${(props): string => props.theme.backgroundColors.primary};
  color: ${(props): string => props.theme.textColors.white};
  text-align: center;

  ${mediaPc`
    font-size: 14px;
    line-height: 45px;
  `}
`
export const News: React.FC<Props> = ({ className = '', message = '' }) => {
  return <StyledNews className={className}>{message}</StyledNews>
}
