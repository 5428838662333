import React from 'react'
import styled from 'styled-components'
import { TextButton, ContentTitle } from '../../atoms'

import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  title?: string
  linkText?: string
  textType?: 'red'
  onClick?: () => void
}

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-title {
    width: auto;
  }

  .text-button {
    font-size: 2.933vw;
  }

  ${mediaPc`
    .text-button {
      font-size: 14px;
    }
  `}
`

export const ContentTitleWithLink: React.FC<Props> = ({ className = '', title = '', linkText, textType, onClick }) => {
  return (
    <TitleRow className={className}>
      <ContentTitle title={title} className="content-title" />
      <TextButton onClick={onClick} textType={textType} className="text-button">
        {linkText}
      </TextButton>
    </TitleRow>
  )
}
