import React from 'react'
import styled from 'styled-components'
import { PageTitle, Icon } from '../../atoms'
import Ranking from '../../../assets/images/icon/ranking.png'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: self-start;

  .ranking-icon {
    width: 8.5vw;
    margin-right: 2vw;
  }

  .page-title {
    margin: 1.2vw 0 1.5vw;
    line-height: 1;
    letter-spacing: 0.3em;
  }

  .page-subtitle {
    font-size: 2.67vw;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  ${mediaPc`
    .ranking-icon {
      width: 47px;
      margin-right: 13px;
    }

    .page-title {
      margin: 10px 0;
      line-height: 0.9;
    }

    .page-subtitle {
      font-size: 12px;
      line-height: 1.2;
    }
  `}
`

export const CreatorRankingTitle: React.FC<Props> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Icon src={Ranking} alt="ランキング" className="ranking-icon" />
      <div className="title">
        <PageTitle title="CREATOR RANKING" className="page-title" />
        <p className="page-subtitle">人気クリエイター販売数ランキング</p>
      </div>
    </Wrapper>
  )
}
