import React from 'react'
import { CreatorLiveTalkLpTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'

export const CreatorLiveTalkLp: React.FC = () => {
  return (
    <>
      <Meta />
      <CreatorLiveTalkLpTpl />
    </>
  )
}
