import React from 'react'
import styled from 'styled-components'
import { TextButton } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  tabs: { label: string; selected: boolean; onClick: () => void }[]
}

const StyledTab = styled.div`
  display: flex;
  justify-content: space-around;

  .tab-label {
    border-bottom: 2px solid transparent;
    transition: 0.3s all;

    &:hover,
    &.selected {
      border-bottom: 2px solid ${(props): string => props.theme.borderColors.primary};
      font-weight: bold;
      color: ${(props): string => props.theme.textColors.gray};
    }
    &:first-child:nth-last-child(6),
    &:first-child:nth-last-child(6) ~ .tab-label {
      font-size: 2.7vw;
    }
  }
  ${mediaPc`
    justify-content: flex-start;
    .tab-label {
      margin-right: 23px;
      font-size: 14px;
      &:first-child:nth-last-child(6),
      &:first-child:nth-last-child(6) ~ .tab-label {
        font-size: 14px;
      }
    }
  `}
`

export const TwoColumnLayoutTab: React.FC<Props> = ({ className = '', tabs }) => {
  return (
    <StyledTab className={className}>
      {tabs.map((tab, index) => (
        <TextButton textType="lightGray" onClick={tab.onClick} className={tab.selected ? 'selected tab-label' : 'tab-label'} key={index}>
          {tab.label}
        </TextButton>
      ))}
    </StyledTab>
  )
}
