import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageSubtitle } from '../../atoms'
import { SubtitleWithButton, Pagination } from '../../molecules'
import { ProductListForManagement } from '../../organisms'
import { Creator, Product } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

type Props = {
  creator?: Creator
  disabledProducts: Product[]
  lastPage: number
  currentPage: number
  onClickToDisplay?: (productId: string) => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }
  .creator-detail {
    margin-bottom: 7vw;
  }
  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title {
      margin-bottom: 35px;
    }
  `}
`

export const CreatorDisabledProductsTpl: React.FC<Props> = ({ creator, disabledProducts, lastPage, currentPage, onClickToDisplay }) => {
  const history = useHistory()

  const goToRegisterProduct = (): void => {
    if (userType() === 'business') {
      history.push(`/business/creator/${creator?.id}/product/register`)
    } else if (userType() === 'creator') {
      history.push('/creator/product/register')
    }
  }
  return (
    <Wrapper>
      {userType() === 'business' && (
        <SubtitleWithButton
          subtitle={`${creator?.name}さん 非表示の商品一覧`}
          buttonType="red"
          buttonText="新しい商品を販売する"
          className="title"
          onClick={goToRegisterProduct}
        />
      )}
      {userType() === 'creator' && (
        <>
          <PageSubtitle subtitle="非表示の商品一覧" className="title pc" />
          <SubtitleWithButton
            subtitle={'非表示の商品一覧'}
            buttonType="red"
            buttonText="新しい商品を販売する"
            className="title sp"
            onClick={goToRegisterProduct}
          />
        </>
      )}
      {!!disabledProducts.length && (
        <>
          <ProductListForManagement
            productListType="disabled"
            products={disabledProducts}
            onClickToDisplay={onClickToDisplay}
            oneLineItemsNum={4}
          />
          <Pagination lastPage={lastPage} currentPage={currentPage} />
        </>
      )}
      {!disabledProducts.length && (
        <div className="no-data">
          <p>非表示の商品はありません</p>
        </div>
      )}
    </Wrapper>
  )
}
