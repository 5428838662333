import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal } from '../../'
import { Button, Textarea } from '../../../atoms'
import { RadioButtonWithLabel, CheckboxWithLabel, TextareaWithLabel } from '../../../molecules'
import { ProductType } from '../../../../types/graphql'
import { RequestProduct } from '../../../../types/myTypes'

import { mediaPc } from '../../../../lib/MediaQuery'
import { requestPhotoOptions, requestMovieOptions, requestLiveTalkOptions } from '../../../../lib/CheckboxOptions'

type Props = {
  productRequest: RequestProduct
  onChangeState: (name: string, value: string | string[] | ProductType) => void
  closeModal: () => void
  onClickSendProductRequest: () => void
}

const ModalContents = styled.div`
  padding: 10.1vw 5.3vw 12.8vw;
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  .title {
    font-weight: bold;
    text-align: center;
    font-size: 3.7vw;
    padding: 0 0 7.7vw;
  }
  .form {
    .input-product-type {
      padding-bottom: 8vw;
      > div {
        justify-content: space-between;
        > div {
          margin: 0;
        }
      }
    }
    .input-contents {
      > div {
        display: block;
        > div {
          margin-bottom: 2.6vw;
        }
      }
    }
    .input-other-text,
    .input-requested-dates > textarea {
      height: 18.9vw;
      border: 1px solid ${(props): string => props.theme.borderColors.gray};
      &:focus {
        border: 1px solid ${(props): string => props.theme.borderColors.primary};
      }
      &::placeholder {
        font-size: 2.6vw;
      }
    }
    .input-requested-dates {
      padding-top: 8vw;
    }
    .input-product-type > label,
    .input-contents > label,
    .input-requested-dates label {
      font-size: 3.2vw;
      font-weight: bold;
    }
  }
  .attention {
    margin: 4vw 0 6.1vw;
    font-size: 3.2vw;
    padding: 3.2vw;
    line-height: 170%;
    font-weight: bold;
    color: ${(props): string => props.theme.textColors.red};
    border: 1px solid ${(props): string => props.theme.borderColors.red};
    border-radius: 4px;
  }

  ${mediaPc`
    padding:40px 0 64px;
    .title {
      padding: 0 0 38px;
      font-size: 16px;
      line-height: 18.5px;
    }
    .form {
      margin:0 auto;
      max-width: 568px;
      .input-product-type {
        padding: 0 0 38px;
        > div {
          justify-content: flex-start;
          > div {
            margin-right: 40px;
          }
        }
      }
      .input-other-text {
        padding: 15px;
      }
      .input-contents {
        > div {
          display: flex;
          > div {
            margin-bottom: 19px;
          }
        }
      }
      .input-requested-dates {
        padding-top: 34px;
      }
      .input-other-text,
      .input-requested-dates > textarea {
        height: 71px;
        min-height: 71px;
        &::placeholder {
          font-size: 12px;
        }
      }
      .input-product-type > label,
      .input-contents > label,
      .input-requested-dates label {
        font-size: 12px;
        font-weight: bold;
      }
    }
    .attention {
      padding: 13px 14px;
      margin: 23px auto 32px;
      font-size: 12px;
    }
  `}
`

export const RequestProductModalTpl: React.FC<Props> = ({ productRequest, closeModal, onChangeState, onClickSendProductRequest }) => {
  const [checkboxOptions, setCheckboxOptions] = useState<{ id: string; name: string; value: string; label: string }[]>(requestPhotoOptions)

  const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (productRequest.request_contents.includes(event.target.value)) {
      productRequest.request_contents = productRequest.request_contents.filter((v: string) => !event.target.value.includes(v))
    } else {
      productRequest.request_contents.push(event.target.value)
    }
    onChangeState('request_contents', productRequest.request_contents)
  }

  const checkOtherText = (): void => {
    // その他のご希望内容が入力された場合、チェックボックス「その他」にもチェック入れるように
    if (!productRequest.request_contents.includes('is_other')) {
      productRequest.request_contents.push('is_other')
      onChangeState('request_contents', productRequest.request_contents)
    }
  }

  const isDisable = (): boolean => {
    if (productRequest.product_type === ProductType.LiveTalk && productRequest.requested_dates === '') {
      return true
    }

    return false
  }

  useEffect(() => {
    switch (productRequest.product_type) {
      case ProductType.Photo:
        setCheckboxOptions(requestPhotoOptions)
        break
      case ProductType.Movie:
        setCheckboxOptions(requestMovieOptions)
        break
      case ProductType.LiveTalk:
        setCheckboxOptions(requestLiveTalkOptions)
        break
      default:
        break
    }
  }, [onChangeState, productRequest.product_type])

  return (
    <Modal closeModal={closeModal}>
      <ModalContents>
        <p className="title">販売してほしい商品をリクエスト</p>
        <div className="form">
          <RadioButtonWithLabel
            className="input-product-type"
            label="ご希望の販売形式"
            checkedValue={productRequest.product_type}
            buttons={[
              {
                id: 'photo',
                name: 'product_type',
                value: ProductType.Photo,
                label: '写真',
                onChange: () => {
                  onChangeState('product_type', ProductType.Photo)
                },
              },
              {
                id: 'movie',
                name: 'product_type',
                value: ProductType.Movie,
                label: '動画',
                onChange: () => {
                  onChangeState('product_type', ProductType.Movie)
                },
              },
              {
                id: 'live_talk',
                name: 'product_type',
                value: ProductType.LiveTalk,
                label: 'ライブトーク',
                onChange: () => {
                  onChangeState('product_type', ProductType.LiveTalk)
                },
              },
            ]}
          />
          <CheckboxWithLabel
            className="input-contents"
            label="ご希望内容  ※複数選択可能"
            checkedValue={productRequest.request_contents}
            checkboxOptions={checkboxOptions}
            onChange={onChangeCheckBox}
          />
          <Textarea
            className="input-other-text"
            name="other_text"
            value={productRequest.other_text}
            onChange={(event) => {
              onChangeState('other_text', event.target.value)
              checkOtherText()
            }}
            placeholder="その他のご希望内容がありましたらご記入ください(200文字まで)"
            maxLength={200}
          />
          {productRequest.product_type === ProductType.LiveTalk && (
            <TextareaWithLabel
              name="requested_dates"
              className="input-requested-dates"
              label="ご希望日時  ※2〜3つご記入ください"
              required={true}
              value={productRequest.requested_dates}
              maxLength={200}
              textCountVisible={false}
              placeholder="例  ○月○日○時〜、平日○時以降 (200文字まで)"
              onChange={(event) => {
                onChangeState('requested_dates', event.target.value)
              }}
            />
          )}
          <div className="attention">
            ・リクエストすると、このクリエイターを自動的にお気に入り登録します。
            <br />
            ・クリエイター様が必ずご希望内容通りの商品を販売することを保証するものではございません。あらかじめご了承ください。
            <br />
            ・クリエイター様を不快にさせるような不適切な文言や、利用規約第5条に違反する文言を記載した場合、アカウント停止させて頂きます。
          </div>
          <Button buttonType="primary" disabled={isDisable()} onClick={onClickSendProductRequest} className="send-request-button">
            リクエスト送信する
          </Button>
        </div>
      </ModalContents>
    </Modal>
  )
}
