export const priceList = [
  {
    value: '1500',
    label: '¥1,500',
  },
  {
    value: '2000',
    label: '¥2,000',
  },
  {
    value: '2500',
    label: '¥2,500',
  },
  {
    value: '3000',
    label: '¥3,000',
  },
  {
    value: '3500',
    label: '¥3,500',
  },
  {
    value: '4000',
    label: '¥4,000',
  },
  {
    value: '4500',
    label: '¥4,500',
  },
  {
    value: '5000',
    label: '¥5,000',
  },
  {
    value: '5500',
    label: '¥5,500',
  },
  {
    value: '6000',
    label: '¥6,000',
  },
  {
    value: '6500',
    label: '¥6,500',
  },
  {
    value: '7000',
    label: '¥7,000',
  },
  {
    value: '7500',
    label: '¥7,500',
  },
  {
    value: '8000',
    label: '¥8,000',
  },
  {
    value: '8500',
    label: '¥8,500',
  },
  {
    value: '9000',
    label: '¥9,000',
  },
  {
    value: '9500',
    label: '¥9,500',
  },
  {
    value: '10000',
    label: '¥10,000',
  },
  {
    value: '11000',
    label: '¥11,000',
  },
  {
    value: '12000',
    label: '¥12,000',
  },
  {
    value: '13000',
    label: '¥13,000',
  },
  {
    value: '14000',
    label: '¥14,000',
  },
  {
    value: '15000',
    label: '¥15,000',
  },
  {
    value: '16000',
    label: '¥16,000',
  },
  {
    value: '17000',
    label: '¥17,000',
  },
  {
    value: '18000',
    label: '¥18,000',
  },
  {
    value: '19000',
    label: '¥19,000',
  },
  {
    value: '20000',
    label: '¥20,000',
  },
]

export const liveTalkPriceList = [
  {
    value: '1500',
    label: '¥1,500',
  },
  {
    value: '2000',
    label: '¥2,000',
  },
  {
    value: '2500',
    label: '¥2,500',
  },
  {
    value: '3000',
    label: '¥3,000',
  },
  {
    value: '3500',
    label: '¥3,500',
  },
  {
    value: '4000',
    label: '¥4,000',
  },
  {
    value: '4500',
    label: '¥4,500',
  },
  {
    value: '5000',
    label: '¥5,000',
  },
  {
    value: '5500',
    label: '¥5,500',
  },
  {
    value: '6000',
    label: '¥6,000',
  },
  {
    value: '6500',
    label: '¥6,500',
  },
  {
    value: '7000',
    label: '¥7,000',
  },
  {
    value: '7500',
    label: '¥7,500',
  },
  {
    value: '8000',
    label: '¥8,000',
  },
  {
    value: '8500',
    label: '¥8,500',
  },
  {
    value: '9000',
    label: '¥9,000',
  },
  {
    value: '9500',
    label: '¥9,500',
  },
  {
    value: '10000',
    label: '¥10,000',
  },
  {
    value: '11000',
    label: '¥11,000',
  },
  {
    value: '12000',
    label: '¥12,000',
  },
  {
    value: '13000',
    label: '¥13,000',
  },
  {
    value: '14000',
    label: '¥14,000',
  },
  {
    value: '15000',
    label: '¥15,000',
  },
  {
    value: '16000',
    label: '¥16,000',
  },
  {
    value: '17000',
    label: '¥17,000',
  },
  {
    value: '18000',
    label: '¥18,000',
  },
  {
    value: '19000',
    label: '¥19,000',
  },
  {
    value: '20000',
    label: '¥20,000',
  },
]

export const movieDurationList = [
  {
    value: '5',
    label: '5秒',
  },
  {
    value: '10',
    label: '10秒',
  },
  {
    value: '20',
    label: '20秒',
  },
  {
    value: '30',
    label: '30秒',
  },
  {
    value: '40',
    label: '40秒',
  },
  {
    value: '50',
    label: '50秒',
  },
  {
    value: '60',
    label: '60秒',
  },
]

export const releasedAtTimeList = [
  {
    value: '00:00',
    label: '00:00',
  },
  {
    value: '00:30',
    label: '00:30',
  },
  {
    value: '01:00',
    label: '01:00',
  },
  {
    value: '01:30',
    label: '01:30',
  },
  {
    value: '02:00',
    label: '02:00',
  },
  {
    value: '02:30',
    label: '02:30',
  },
  {
    value: '03:00',
    label: '03:00',
  },
  {
    value: '03:30',
    label: '03:30',
  },
  {
    value: '04:00',
    label: '04:00',
  },
  {
    value: '04:30',
    label: '04:30',
  },
  {
    value: '05:00',
    label: '05:00',
  },
  {
    value: '05:30',
    label: '05:30',
  },
  {
    value: '06:00',
    label: '06:00',
  },
  {
    value: '06:30',
    label: '06:30',
  },
  {
    value: '07:00',
    label: '07:00',
  },
  {
    value: '07:30',
    label: '07:30',
  },
  {
    value: '08:00',
    label: '08:00',
  },
  {
    value: '08:30',
    label: '08:30',
  },
  {
    value: '09:00',
    label: '09:00',
  },
  {
    value: '09:30',
    label: '09:30',
  },
  {
    value: '10:00',
    label: '10:00',
  },
  {
    value: '10:30',
    label: '10:30',
  },
  {
    value: '11:00',
    label: '11:00',
  },
  {
    value: '11:30',
    label: '11:30',
  },
  {
    value: '12:00',
    label: '12:00',
  },
  {
    value: '12:30',
    label: '12:30',
  },
  {
    value: '13:00',
    label: '13:00',
  },
  {
    value: '13:30',
    label: '13:30',
  },
  {
    value: '14:00',
    label: '14:00',
  },
  {
    value: '14:30',
    label: '14:30',
  },
  {
    value: '15:00',
    label: '15:00',
  },
  {
    value: '15:30',
    label: '15:30',
  },
  {
    value: '16:00',
    label: '16:00',
  },
  {
    value: '16:30',
    label: '16:30',
  },
  {
    value: '17:00',
    label: '17:00',
  },
  {
    value: '17:30',
    label: '17:30',
  },
  {
    value: '18:00',
    label: '18:00',
  },
  {
    value: '18:30',
    label: '18:30',
  },
  {
    value: '19:00',
    label: '19:00',
  },
  {
    value: '19:30',
    label: '19:30',
  },
  {
    value: '20:00',
    label: '20:00',
  },
  {
    value: '20:30',
    label: '20:30',
  },
  {
    value: '21:00',
    label: '21:00',
  },
  {
    value: '21:30',
    label: '21:30',
  },
  {
    value: '22:00',
    label: '22:00',
  },
  {
    value: '22:30',
    label: '22:30',
  },
  {
    value: '23:00',
    label: '23:00',
  },
  {
    value: '23:30',
    label: '23:30',
  },
]

export const paymentList = [
  {
    value: 'robotpayment',
    label: 'クレジットカード払い',
  },
  {
    value: 'paidy',
    label: 'Paidy翌月払い（コンビニ/銀行）',
  },
]

export const hourList = [
  {
    value: '00',
    label: '00',
  },
  {
    value: '01',
    label: '01',
  },
  {
    value: '02',
    label: '02',
  },
  {
    value: '03',
    label: '03',
  },
  {
    value: '04',
    label: '04',
  },
  {
    value: '05',
    label: '05',
  },
  {
    value: '06',
    label: '06',
  },
  {
    value: '07',
    label: '07',
  },
  {
    value: '08',
    label: '08',
  },
  {
    value: '09',
    label: '09',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '11',
    label: '11',
  },
  {
    value: '12',
    label: '12',
  },
  {
    value: '13',
    label: '13',
  },
  {
    value: '14',
    label: '14',
  },
  {
    value: '15',
    label: '15',
  },
  {
    value: '16',
    label: '16',
  },
  {
    value: '17',
    label: '17',
  },
  {
    value: '18',
    label: '18',
  },
  {
    value: '19',
    label: '19',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '21',
    label: '21',
  },
  {
    value: '22',
    label: '22',
  },
  {
    value: '23',
    label: '23',
  },
]

export const minuteList = [
  {
    value: '00',
    label: '00',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '30',
    label: '30',
  },
  {
    value: '40',
    label: '40',
  },
  {
    value: '50',
    label: '50',
  },
]

export const liveTalkMinutesList = [
  {
    value: '1',
    label: '1分',
  },
  {
    value: '2',
    label: '2分',
  },
  {
    value: '3',
    label: '3分',
  },
  {
    value: '4',
    label: '4分',
  },
  {
    value: '5',
    label: '5分',
  },
  {
    value: '6',
    label: '6分',
  },
  {
    value: '7',
    label: '7分',
  },
  {
    value: '8',
    label: '8分',
  },
  {
    value: '9',
    label: '9分',
  },
  {
    value: '10',
    label: '10分',
  },
]
