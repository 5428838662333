import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageSubtitle, ContentTitle } from '../../../atoms'
import { OrderTable, DeliveryAttention, Pagination } from '../../../molecules'
import { OrderedProduct } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  username: string
  undeliveredOrders: OrderedProduct[]
  deliveredOrders: OrderedProduct[]
  currentPage: number
  lastPage: number
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }

  .order-table-title {
    margin-bottom: 3vw;
  }

  .undelivered-orders {
    margin-bottom: 7vw;
  }

  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title {
      margin-bottom: 35px;
    }

    .order-table-title {
      margin-bottom: 15px;
    }
  
    .undelivered-orders {
      margin-bottom: 35px;
    }
  `}
`

export const CreatorOrderedProductPerUserTpl: React.FC<Props> = ({
  username,
  undeliveredOrders,
  deliveredOrders,
  currentPage,
  lastPage,
}) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle={`${username}さんの購入一覧`} />
      {currentPage === 1 && (
        <div className="undelivered-orders">
          <ContentTitle title="納品が完了していない注文" className="order-table-title" />
          <OrderTable
            orderType="undelivered"
            orders={undeliveredOrders}
            noDataText={`商品が購入されるとこちらに表示されます。${'\n'}納品を済ませることで完了となります。`}
          />
          <DeliveryAttention />
        </div>
      )}
      <div className="delivered-orders">
        <ContentTitle title="納品が完了した注文" className="order-table-title" />
        <OrderTable orderType="delivered" orders={deliveredOrders} noDataText="納品が完了した注文はありません" />
        <Pagination lastPage={lastPage} currentPage={currentPage} />
      </div>
    </Wrapper>
  )
}
