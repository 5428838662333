import React from 'react'
import styled from 'styled-components'
import { CreatorSearchInput } from '../../molecules'

type Props = {
  searchWord?: string
  onChangeSearchForm?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClickSearchButton?: () => void
}

const Wrapper = styled.div`
  width: 100%;
  padding: 5vw 7.2vw;
  background: ${(props): string => props.theme.backgroundColors.gray};
`

export const CreatorSearchTpl: React.FC<Props> = ({ searchWord, onChangeSearchForm, onClickSearchButton }) => {
  return (
    <Wrapper className="sp">
      <CreatorSearchInput searchWord={searchWord} onChange={onChangeSearchForm} onClick={onClickSearchButton} />
    </Wrapper>
  )
}
