import React from 'react'
import styled from 'styled-components'
import { TextInput, TextButton } from '../../atoms'
import searchGreen from '../../../assets/images/icon/search_green.png'

type Props = {
  className?: string
  searchWord?: string
  onClick?: () => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .inputSearchWord {
    width: 72vw;
    height: 12.48vw;
    margin-right: 2.4vw;
    padding: 0 13px;
    border-radius: 4px;
    font-size: 4.27vw;
    line-height: 1;

    &::placeholder {
      font-size: 3.2vw;
      line-height: 1.875;
    }
  }

  .submit {
    width: 16vw;
    height: 12.48vw;
    border-radius: 4px;
    background: url(${searchGreen}) center no-repeat ${(props): string => props.theme.backgroundColors.primary};
    background-size: 7.5vw;

    &:hover {
      opacity: 0.8;
    }
  }
`

export const CreatorSearchInput: React.FC<Props> = ({ className, searchWord, onChange, onClick }) => {
  return (
    <Wrapper className={className}>
      <TextInput className="inputSearchWord" onChange={onChange} placeholder="クリエイターの名前を入力" value={searchWord} />
      <TextButton className="submit" onClick={onClick} />
    </Wrapper>
  )
}
