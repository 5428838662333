import React from 'react'
import styled from 'styled-components'
import { PageSubtitle } from '../../../atoms'
import { CreatorSalesPerMonth } from '../../../organisms'
import { SalesPerMonth } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  sales: SalesPerMonth[]
  years: string[]
  selectedYear: string
  onSelectYear: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  .title {
    width: 100%;
    margin-bottom: 4.8vw;
  }

  ${mediaPc`
    .title {
      font-size: 16px;
      margin-bottom: 17px;
    }
  `}
`

export const CreatorSalesPerMonthTpl: React.FC<Props> = ({ sales, years, selectedYear, onSelectYear }) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle="売上一覧" />
      <CreatorSalesPerMonth sales={sales} years={years} selectedYear={selectedYear} onSelectYear={onSelectYear} />
    </Wrapper>
  )
}
