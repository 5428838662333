import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import { CreatorHomeSlider, CreatorSearch, HomeCreatorRanking, HomeProductsOnSale } from '../../organisms'
import { CreatorRanking, PublishedProduct } from '../../../types/graphql'
import { CREATOR_REGISTRATION_URL } from '../../../const'

/* images */
import { ReactComponent as RegisterLinkIcon } from '../../../assets/images/icon/register-link.svg'
import { ReactComponent as ArrowIcon } from '../../../assets/images/icon/arrow-green.svg'

type Props = {
  isLoggedIn: boolean
  searchWord: string
  creatorRanking: CreatorRanking[]
  productsOnSale: PublishedProduct[]
  selectedDuration: 'weekly' | 'monthly'
  onClickSearchButton: () => void
  onChangeSearchWord: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeDuration: (durationType: 'weekly' | 'monthly') => void
}

const Wrapper = styled.div`
  .creator-ranking,
  .products-on-sale {
    padding: 10vw 4.8vw 6vw;
  }
  .register-link {
    display: flex;
    align-items: center;
    width: 92.27vw;
    height: 13.6vw;
    margin: 5.87vw auto 0;
    padding: 0 3.47vw;
    border-radius: 2.13vw;
    font-size: 3.47vw;
    color: ${(props): string => props.theme.textColors.black};
    border: 0.53vw solid ${(props): string => props.theme.textColors.primary};
    .register-icon {
      width: 5.6vw;
      margin: 0 2.13vw 0 0;
    }
    p {
      font-weight: 700;
      letter-spacing: 0.1em;
    }
    .arrow-icon {
      width: 1.6vw;
      margin-left: auto;
    }
  }
  ${mediaPc`
    width: 100%;
    overflow: hidden;
    .creator-ranking, .products-on-sale {
      width: 1040px;
      margin: 0 auto 95px;
    }
    .creator-ranking {
      padding: 64px 20px 0;
    }
    .products-on-sale{
      padding: 0 20px;
    }
    .register-link {
      width: 675px;
      height: 90px;
      margin: 26px auto 0;
      padding: 0 29px 0 41px;
      border-radius: 18px;
      font-size: 24px;
      border: 4px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.primary};
      .register-icon {
        width: 32px;
        margin: 0 21px 0 0;
      }
      p {
        font-weight: 700;
      }
      .arrow-icon {
        width: 13px;
      }
    }
  `}
`

export const CreatorHomeTpl: React.FC<Props> = ({
  isLoggedIn,
  searchWord,
  creatorRanking,
  productsOnSale,
  selectedDuration,
  onChangeSearchWord,
  onClickSearchButton,
  onChangeDuration,
}) => {
  return (
    <Wrapper>
      <CreatorHomeSlider />
      {!isLoggedIn && (
        <a className="register-link" href={CREATOR_REGISTRATION_URL} target="_blank" rel="noopener noreferrer">
          <RegisterLinkIcon className="register-icon" />
          <p>アカウント新規登録(無料)はこちらから！</p>
          <ArrowIcon className="arrow-icon" />
        </a>
      )}
      <HomeCreatorRanking
        selectedDuration={selectedDuration}
        creatorRanking={creatorRanking}
        onChangeDuration={onChangeDuration}
        className="creator-ranking"
      />
      <HomeProductsOnSale className="products-on-sale" productsOnSale={productsOnSale} />
      <CreatorSearch searchWord={searchWord} onChange={onChangeSearchWord} onClick={onClickSearchButton} className="sp" />
    </Wrapper>
  )
}
