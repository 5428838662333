import React, { useEffect, useCallback } from 'react'
import { APP_NAME, META_DESCRIPTION } from './const'

type Props = {
  title?: string
  description?: string
}

export const Meta: React.FC<Props> = ({ title, description }) => {
  const setMetaTitle = useCallback(() => {
    document.title = title ? `${title} | ${APP_NAME}` : APP_NAME
  }, [title])

  const setMetaDescription = useCallback(() => {
    const currentDescription = document?.querySelector('meta[name="description"]')
    if (currentDescription !== null) {
      currentDescription.setAttribute('content', description ?? META_DESCRIPTION)
    }
  }, [description])

  useEffect(() => {
    setMetaTitle()
    setMetaDescription()
  }, [setMetaTitle, setMetaDescription])

  return null
}
