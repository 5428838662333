import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { CreatorPurchaseUserRanking } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  noDataText: string
  ranking: CreatorPurchaseUserRanking[]
}

const Table = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid ${(props): string => props.theme.borderColors.black};
  .rank {
    flex-grow: 0;
    text-align: center;
  }
  .username {
    flex-grow: 2;
    dd {
      text-align: left;
    }
  }
  .purchase-count {
    flex-grow: 0;
    dd {
      text-align: center;
    }
  }
  .amount {
    flex-grow: 1;
    dd {
      text-align: right;
    }
  }
  .twitter-username {
    flex-grow: 2;
    text-align: left;
    .detail-link {
      font-weight: 900;
      color: ${(props): string => props.theme.textColors.primary};
      cursor: pointer;
    }
  }
  .table-inner {
    width: auto;
    display: flex;
    dl {
      border-right: 1px solid ${(props): string => props.theme.borderColors.black};
      dt {
        height: auto;
        padding: 0 2.3vw;
        border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
        font-size: 3.2vw;
        font-weight: bold;
        color: ${(props): string => props.theme.textColors.black};
        line-height: 3em;
        background: ${(props): string => props.theme.backgroundColors.bodyBg};
      }
      dd {
        height: auto;
        padding: 0 2.3vw;
        font-size: 3.2vw;
        font-weight: 900;
        color: ${(props): string => props.theme.textColors.black};
        line-height: 3em;
        border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
      }
      &:last-child,
      dd:last-child {
        border: none;
      }
    }
  }
  .no-data {
    padding: 2.3vw;
    font-size: 3.2vw;
  }

  ${mediaPc`
    .table-inner {
      dl {
        dt {
          padding: 5px 15px 0;
          font-size: 14px;
          line-height: 28px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
        }
        dd {
          padding: 6px 15px 0;
          font-size: 14px;
          font-weight: normal;
          line-height: 35px;
        }
      }
    }
    .no-data {
      padding: 15px;
      font-size: 14px;
    }
  `}
`

export const UserRankingTable: React.FC<Props> = ({ className = '', ranking, noDataText }) => {
  return (
    <Table className={className}>
      {ranking.length !== 0 && (
        <div className="table-inner">
          <dl className="rank">
            <dt>順位</dt>
            {ranking.map((user, index) => (
              <dd key={++index}>{index}位</dd>
            ))}
          </dl>
          <dl className="username">
            <dt>購入者</dt>
            {ranking.map((user, index) => (
              <dd key={index}>{user.user_name}</dd>
            ))}
          </dl>
          <dl className="purchase-count">
            <dt>枚数</dt>
            {ranking.map((user, index) => (
              <dd key={index}>{user.purchase_count.toLocaleString()}</dd>
            ))}
          </dl>
          <dl className="amount">
            <dt>金額</dt>
            {ranking.map((user, index) => (
              <dd key={index}>{user.amount.toLocaleString()}</dd>
            ))}
          </dl>
          <dl className="twitter-username">
            <dt>Twitter</dt>
            {ranking.map((user, index) => (
              <dd key={index}>
                {user.twitter_username !== null ? (
                  <a
                    className="detail-link"
                    href={`https://twitter.com/${user.twitter_username}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {user.twitter_username}
                  </a>
                ) : (
                  <button></button>
                )}
              </dd>
            ))}
          </dl>
        </div>
      )}
      {/* ランキングが無い場合 styleは削除 */}
      {!ranking.length && (
        <>
          <div className="table-inner">
            <dl className="rank">
              <dt>順位</dt>
            </dl>
            <dl className="username">
              <dt>購入者</dt>
            </dl>
            <dl className="purchase-count">
              <dt>枚数</dt>
            </dl>
            <dl className="amount">
              <dt>金額</dt>
            </dl>
            <dl className="twitter-username">
              <dt>Twitterユーザー名</dt>
            </dl>
          </div>
          <div className="no-data">
            <p>{noDataText}</p>
          </div>
        </>
      )}
    </Table>
  )
}
