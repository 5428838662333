import React from 'react'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  label?: string
  inputType?: 'text' | 'email' | 'select'
  children?: React.ReactNode
}

const getStylePerInputTypeSp = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  if (props.inputType === 'text' || props.inputType === 'email') {
    return `
      input[type='email'],
      input[type='text'] {
        width: 100%;
        height: 10.6vw;
        margin: 0 auto;
        padding: 0 4vw;
        font-size: 3.2vw;
      }`
  } else if (props.inputType === 'select') {
    return `
       select {
        width: 100%;
        height: 10.6vw;
        margin: 0 auto;
        padding: 0 4vw;
        font-size: 3.2vw;
        border: 1px solid ${props.theme.borderColors.white};
      }
     `
  } else {
    return ''
  }
}

const getStylePerInputTypePc = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  if (props.inputType === 'text' || props.inputType === 'email') {
    return `
      input[type='email'],
      input[type='text'] {
        width: 320px;
        height: 40px;
        margin: 0 auto;
        padding: 0 15px;
        font-size: 12px;
      }`
  } else if (props.inputType === 'select') {
    return `
       select {
        width: 320px;
        height: 40px;
        margin: 0 auto;
        padding: 0 15px;
        font-size: 12px;
        border: 1px solid ${props.theme.borderColors.white};
      }
     `
  } else {
    return ''
  }
}

const StyledModalInputItem = styled.div<Props>`
  width: 100%;
  margin: 0 auto;
  p {
    color: ${(props): string => props.theme.textColors.gray};
    font-size: 2.6vw;
    padding: 0 0 2.6vw;
  }
  ${(props) => getStylePerInputTypeSp(props)}
  ${mediaPc`
    width: 320px;
    ${(props: ThemedStyledProps<Props, DefaultTheme>) => getStylePerInputTypePc(props)}
    p {
      font-size: 10px;
      padding: 0 0 10px;
    }
  `}
`

export const ModalInputItem: React.FC<Props> = ({ className = '', label = '', inputType, children }) => {
  return (
    <StyledModalInputItem className={className} inputType={inputType}>
      <p>{label}</p>
      <div>{children}</div>
    </StyledModalInputItem>
  )
}
