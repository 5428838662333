import React from 'react'
import styled from 'styled-components'
import { PageSubtitle } from '../../../atoms'
import { SalesPerOrderTable, Pagination } from '../../../molecules'
import { SalesBreakdown } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  sales: SalesBreakdown[]
  lastPage: number
  currentPage: number
}

const Wrapper = styled.div`
  .title {
    width: 100%;
    margin-bottom: 4.8vw;
  }

  .sales-per-order {
    margin-bottom: 10.7vw;
  }

  ${mediaPc`
    .title {
      font-size: 16px;
      margin-bottom: 13px;
    }

    .sales-per-order {
      margin-bottom: 40px;
    }
  `}
`

export const CreatorSalesPerOrderTpl: React.FC<Props> = ({ sales, lastPage, currentPage }) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle="売上一覧" />
      <SalesPerOrderTable sales={sales} noDataText="月の売上が一覧で表示されます" className="sales-per-order" />
      <Pagination lastPage={lastPage} currentPage={currentPage} />
    </Wrapper>
  )
}
