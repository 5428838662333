import React from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms'
import { config } from '../../../config'
import { useHistory } from 'react-router-dom'
import { convertToVw } from '../../../lib/ConvertSize'
import { mediaPc } from '../../../lib/MediaQuery'
import errorImagePc from '../../../assets/images/common/error_image_pc.png'
import errorImageSp from '../../../assets/images/common/error_image_sp.png'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColors.primary};
  text-align: center;
  width: 100vw;
  ${mediaPc`
  width: 100%;
  `}
  h1 {
    color: ${(props) => props.theme.textColors.black};
    font-weight: bold;
    font-size: ${convertToVw('62px')};
    padding-top: ${convertToVw('16px')};
    ${mediaPc`
        font-size: 240px;
        line-height: 1;
        padding: 60px 0 22px;
    `}
  }
  p {
    font-size: 3.2vw;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05rem;
    ${mediaPc`
        font-size: 24px;
        padding:0 0 45px;
    `}
  }
  .button {
    background-color: ${(props) => props.theme.textColors.black};
    z-index: 2;
    position: relative;
    width: ${convertToVw('120px')};
    height: ${convertToVw('40px')};
    line-height: ${convertToVw('40px')};
    border-radius: ${convertToVw('20px')};
    margin: ${convertToVw('15px')} auto 0;
    font-size: ${convertToVw('13px')};
    ${mediaPc`
        width: 120px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        font-size: 13px;
        margin: 0 auto 0 ;
    `}
  }
  img {
    margin: 0 auto;
    max-width: 100%;
    ${mediaPc`
      max-width: 600px;
      margin: -9px auto 0;
      padding:0 0 53px
    `}
  }
`
export const NotFoundTpl: React.FC = () => {
  const history = useHistory()

  const goHome = (): void => {
    if (config.businessFrontDomain === document.domain) {
      history.push('/business')
    } else if (config.creatorFrontDomain === document.domain) {
      history.push('/creator')
    } else {
      history.push('/')
    }
  }

  return (
    <Wrapper>
      <h1>404</h1>
      <p>
        申し訳ありません。
        <br className="sp" />
        指定のページが見つかりません。
      </p>
      <Button onClick={(): void => goHome()} buttonType="black" className="button">
        ホームへ戻る
      </Button>
      <img className="sp" src={errorImageSp} alt="わたしの写真と動画もあるかもよ！" />
      <img className="pc" src={errorImagePc} alt="わたしの写真と動画もあるかもよ！" />
    </Wrapper>
  )
}
