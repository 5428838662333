import React from 'react'
import styled from 'styled-components'
import { Modal } from '..'
import { ProductDetailPreview } from '../../organisms'
import { mediaPc } from '../../../lib/MediaQuery'
import { RegisterProductInput } from '../../../types/myTypes'

type Props = {
  product: RegisterProductInput
  closePreviewModal: () => void
}

const ModalContents = styled.div`
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  .inner {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 8vw;
  }
  ${mediaPc`
    padding: 27px 55px;
    .inner {
      width: 100%;
      padding-bottom: 0px;
    }
  `}
`

export const PreviewModalTpl: React.FC<Props> = ({ product, closePreviewModal }) => {
  return (
    <Modal closeModal={closePreviewModal}>
      <ModalContents>
        <div className="inner">
          <ProductDetailPreview product={product} closePreviewModal={closePreviewModal} />
        </div>
      </ModalContents>
    </Modal>
  )
}
