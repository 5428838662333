import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { LinkButton } from '../../atoms'
import { omitName } from '../../../lib/OmitName'
import { mediaPc } from '../../../lib/MediaQuery'
import { Affiliation } from '../../../types/graphql'

type Props = {
  className?: string
  affiliations: Affiliation[] | null
}

const Wrapper = styled.div`
  .affiliation {
    span {
      color: ${(props): string => props.theme.textColors.black};
      font-weight: inherit;
      line-height: inherit;
      font-size: inherit;
    }

    & + .affiliation {
      margin-top: 2.7vw;
    }
  }

  ${mediaPc`
    .affiliation {
      & + .affiliation {
        margin-top: 10px;
      }
    }
  `}
`

export const AffiliationList: React.FC<Props> = ({ className, affiliations = [] }) => {
  const history = useHistory()
  const goToAffiliationDetail = (id: string): void => {
    history.push(`/business/affiliation/${id}`)
  }

  return (
    <Wrapper className={className}>
      {affiliations?.map((affiliation) => (
        <LinkButton key={affiliation.id} className="affiliation" onClick={() => goToAffiliationDetail(affiliation.id)}>
          <span>{omitName(affiliation.name, 10)}</span>
          <span>({affiliation.creators_count})</span>
        </LinkButton>
      ))}
    </Wrapper>
  )
}
