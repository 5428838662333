import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import * as Sentry from '@sentry/react'
import { LoadingCircle, FlashMessage } from '../../components/atoms'
import { CreatorHomeTpl } from '../../components/templates'
import { Meta } from '../../Meta'
import {
  CreatorRanking,
  PublishedProduct,
  useCreatorRankingOfMonthlySalesCountLazyQuery,
  useCreatorRankingOfWeeklySalesCountLazyQuery,
  usePagedProductsOnSaleLazyQuery,
} from '../../types/graphql'
import { FlashMessageType } from '../../types/myTypes'

type Props = { isLoggedIn: boolean }

export const CreatorHome: React.FC<Props> = ({ isLoggedIn }) => {
  const [searchWord, setSearchWord] = useState<string>('')
  const [creatorRanking, setCreatorRanking] = useState<CreatorRanking[]>([])
  const [productsOnSale, setProductsOnSale] = useState<PublishedProduct[]>([])
  const [selectedDuration, setSelectedDuration] = useState<'weekly' | 'monthly'>('weekly')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const history = useHistory()

  const [creatorRankingOfWeeklySalesCountLazyQuery] = useCreatorRankingOfWeeklySalesCountLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setCreatorRanking(data.CreatorRankingOfWeeklySalesCount ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [creatorRankingOfMonthlySalesCountLazyQuery] = useCreatorRankingOfMonthlySalesCountLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setCreatorRanking(data.CreatorRankingOfMonthlySalesCount ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedProductsOnSaleLazyQuery] = usePagedProductsOnSaleLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProductsOnSale(data.PagedProductsOnSale?.data ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const fetchCreatorRanking = useCallback(
    (type: 'weekly' | 'monthly') => {
      switch (type) {
        case 'weekly':
          creatorRankingOfWeeklySalesCountLazyQuery({
            variables: {
              input: {
                limit: isMobile ? 6 : 10,
              },
            },
          })
          break
        case 'monthly':
          creatorRankingOfMonthlySalesCountLazyQuery({
            variables: {
              input: {
                limit: isMobile ? 6 : 10,
              },
            },
          })
          break
        default:
          break
      }
    },
    [creatorRankingOfMonthlySalesCountLazyQuery, creatorRankingOfWeeklySalesCountLazyQuery],
  )

  const onChangeSearchWord = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchWord(event.target.value)
  }

  const onClickSearchButton = (): void => {
    history.push(`/creator/search/result?keyword=${searchWord}`)
  }

  const onChangeDuration = (durationType: 'weekly' | 'monthly'): void => {
    setSelectedDuration(durationType)
    setLoading(true)
    fetchCreatorRanking(durationType)
  }

  useEffect(() => {
    fetchCreatorRanking(selectedDuration)
    pagedProductsOnSaleLazyQuery({
      variables: {
        first: isMobile ? 6 : 10,
      },
    })
  }, [fetchCreatorRanking, pagedProductsOnSaleLazyQuery, selectedDuration])

  return (
    <>
      <Meta />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorHomeTpl
        isLoggedIn={isLoggedIn}
        searchWord={searchWord}
        creatorRanking={creatorRanking}
        selectedDuration={selectedDuration}
        productsOnSale={productsOnSale}
        onChangeSearchWord={onChangeSearchWord}
        onClickSearchButton={onClickSearchButton}
        onChangeDuration={onChangeDuration}
      />
    </>
  )
}
