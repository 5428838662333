import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  children?: React.ReactNode
  htmlFor?: string
}

const StyledLabel = styled.label`
  width: 100%;
  display: block;
  font-size: 2.6vw;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: ${(props): string => props.theme.textColors.gray};

  ${mediaPc`
    font-size: 14px;
    font-weight: normal;
  `}
`

export const Label: React.FC<Props> = ({ className = '', children, htmlFor = '' }) => {
  return (
    <StyledLabel className={className} htmlFor={htmlFor}>
      {children}
    </StyledLabel>
  )
}
