import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorPublishedProductsTpl, BusinessTwoColumnLayout, DialogTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  Product,
  useCreatorInBusinessLazyQuery,
  usePagedBusinessPublishedProductsLazyQuery,
  useDisableProductMutation,
  useFinishProductMutation,
} from '../../../types/graphql'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { useSetMyProfile } from '../../../lib/SetMyProfile'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessCreatorPublishedProducts: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [publishedProducts, setPublishedProducts] = useState<Product[]>([])
  const [selectedProductId, setSelectedProductId] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [dialogVisible, setDialogVisible] = useState<boolean>(false)
  const [registerProductComplete, setRegisterProductComplete] = useState<boolean>(false)

  const history = useHistory()
  const location = useLocation<{ registerProductComplete: boolean }>()

  const [creatorInBusinessLazyQuery] = useCreatorInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setCreator(data.CreatorInBusiness ?? creator)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedBusinessPublishedProductsLazyQuery] = usePagedBusinessPublishedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setPublishedProducts(data.PagedBusinessPublishedProducts?.data ?? [])
        setLastPage(data.PagedBusinessPublishedProducts?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [disableProductMutation] = useDisableProductMutation({
    onCompleted: () => {
      pagedBusinessPublishedProductsLazyQuery({
        variables: {
          creator_id: props.match.params.id,
          first: 20,
          page: currentPage,
        },
      })
      setFlashMessage({ type: 'success', message: '選択した商品を非表示にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const [finishProductMutation] = useFinishProductMutation({
    onCompleted: () => {
      pagedBusinessPublishedProductsLazyQuery({
        variables: {
          creator_id: props.match.params.id,
          first: 20,
          page: currentPage,
        },
      })
      setFlashMessage({ type: 'success', message: '選択した商品を販売終了にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickToDisabled = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    disableProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }
  const onClickToEnd = (productId: string): void => {
    setDialogVisible(true)
    setSelectedProductId(productId)
  }
  const onClickToEndDialogOk = (): void => {
    setDialogVisible(false)
    setLoading(true)
    setFlashMessage(null)
    finishProductMutation({
      variables: {
        product_id: selectedProductId,
      },
    })
  }
  const onClickToEndDialogCancel = (): void => {
    setDialogVisible(false)
  }
  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    if (location.state) {
      setRegisterProductComplete(location.state.registerProductComplete)
    }
  }, [location.state])

  // location.state.registerProductComplete = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (location.state && location.state.registerProductComplete === true) {
      history.replace({ ...location, state: undefined })
    }
  }, [history, location])

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    creatorInBusinessLazyQuery({
      variables: {
        id: props.match.params.id,
      },
    })
    pagedBusinessPublishedProductsLazyQuery({
      variables: {
        creator_id: props.match.params.id,
        first: 20,
        page: Number(pageParam),
      },
    })
  }, [creatorInBusinessLazyQuery, pagedBusinessPublishedProductsLazyQuery, props.location.search, props.match.params.id])

  return (
    <>
      <Meta title={creator ? `${creator.name}さん 公開中の商品一覧` : ''} />
      {dialogVisible && (
        <DialogTpl title="この商品を販売終了にしますか？" onClickOk={onClickToEndDialogOk} onClickCancel={onClickToEndDialogCancel}>
          販売終了にすると、この商品を再度販売できなくなります。再販する場合、新たに商品登録をお願い致します。
        </DialogTpl>
      )}
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {registerProductComplete && <FlashMessage flashMessage={{ message: '登録が完了しました', type: 'success' }} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <>
          {creator && (
            <CreatorPublishedProductsTpl
              creator={creator}
              currentPage={currentPage}
              lastPage={lastPage}
              publishedProducts={publishedProducts}
              onClickToDisabled={onClickToDisabled}
              onClickToEnd={onClickToEnd}
            />
          )}
        </>
      </BusinessTwoColumnLayout>
    </>
  )
}
