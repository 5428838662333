import React from 'react'
import styled from 'styled-components'
import { CreatorSearchInput } from '../../molecules'

type Props = {
  className?: string
  searchWord: string
  onClick: () => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Wrapper = styled.div`
  width: 100vw;
  padding: 5.3vw 4.8vw;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .inner {
    width: 100%;
    flex-wrap: wrap;
    padding: 0 !important;
    p {
      width: 100%;
      margin-bottom: 10px;
      font-size: 3.735vw;
      line-height: 2.4em;
      font-weight: 500;
      color: ${(props): string => props.theme.textColors.primary};
      letter-spacing: 0.1em;
      text-align: center;
    }
  }
`

export const CreatorSearch: React.FC<Props> = ({ className, searchWord, onChange, onClick }) => {
  return (
    <Wrapper className={className}>
      <div className="inner">
        <p>クリエイターを検索してみよう</p>
        <CreatorSearchInput searchWord={searchWord} onChange={onChange} onClick={onClick} />
      </div>
    </Wrapper>
  )
}
