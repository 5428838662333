import React from 'react'
import { PasswordRenewComplete } from '../../../components/templates'
import { Meta } from '../../../Meta'

type Props = {}

export const UserPasswordRenewComplete: React.FC<Props> = () => {
  return (
    <>
      <Meta title="パスワード再設定完了" />
      <PasswordRenewComplete />
    </>
  )
}
