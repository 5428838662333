import React, { useState } from 'react'
import styled from 'styled-components'
import ReactCrop from 'react-image-crop'
import { mediaPc } from '../../../lib/MediaQuery'
import { ModalTitle, Button } from '../../atoms'
import { Modal } from '..'
import 'react-image-crop/dist/ReactCrop.css'

type Props = {
  src: string
  productThumbnailFileName: string
  closeModal: () => void
  setProductThumbnailUrlAfterTrimming: (url: string) => void
  setProductThumbnailFileAfterTrimming: (file: File) => void
  setProductThumbnailCanvasAfterTrimming: (canvas: HTMLCanvasElement) => void
}

const ModalContents = styled.div`
  padding: 32px 0;
  .modal-title {
    margin-bottom: 8vw;
  }
  .react-crop {
    width: 80vw;
    margin: 0 auto 8vw;
    display: block;
  }
  .complete-trimming {
    width: 53vw;
    font-size: 3.2vw;
    line-height: 8vw;
    display: block;
    margin: 0 auto;
  }

  ${mediaPc`
    .modal-title {
      margin-bottom: 30px;
    }

    .react-crop {
      width: 320px;
      margin: 0 auto 30px;
    }

    .complete-trimming {
      width: 200px;
      font-size: 12px;
      line-height: 30px;
    }
  `}
`

export const ProductThumbnailTrimmingModalTpl: React.FC<Props> = ({
  src,
  productThumbnailFileName,
  closeModal,
  setProductThumbnailUrlAfterTrimming,
  setProductThumbnailFileAfterTrimming,
  setProductThumbnailCanvasAfterTrimming,
}) => {
  const [crop, setCrop] = useState<ReactCrop.Crop>()
  const [image, setImage] = useState<HTMLImageElement>()

  const onCompleteTrimming = (): void => {
    const canvas = document.createElement('canvas')
    if (!image || !crop) return
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width ?? 0
    canvas.height = crop.height ?? 0

    const ctx = canvas.getContext('2d')
    if (ctx === null) return
    ctx.drawImage(
      image,
      (crop.x ?? 0) * scaleX,
      (crop.y ?? 0) * scaleY,
      (crop.width ?? 0) * scaleX,
      (crop.height ?? 0) * scaleY,
      0,
      0,
      crop.width ?? 0,
      crop.height ?? 0,
    )

    const url = canvas.toDataURL()
    setProductThumbnailUrlAfterTrimming(url)
    setProductThumbnailCanvasAfterTrimming(canvas)
    canvas.toBlob((blob) => {
      if (blob !== null) {
        setProductThumbnailFileAfterTrimming(new File([blob], productThumbnailFileName))
      }
    })
    closeModal()
  }

  return (
    <Modal closeModal={closeModal}>
      <ModalContents>
        <ModalTitle className="modal-title" title="枠の中に写真を納めてください" />
        <ReactCrop
          src={src}
          onChange={(crop) => {
            setCrop(crop)
          }}
          crop={crop}
          className="react-crop"
          onImageLoaded={(image) => {
            setCrop({ width: image.width, aspect: 1 / 1.796 })
            setImage(image)
            return false // Return false when setting crop state in here.
          }}
          keepSelection
        />
        <Button buttonType="primary" className="complete-trimming" onClick={onCompleteTrimming}>
          OK
        </Button>
      </ModalContents>
    </Modal>
  )
}
