import 'styled-components'

export const theme = {
  backgroundColors: {
    bodyBg: '#fff',
    primary: '#81cac4',
    secondary: '#ca8194',
    gray: '#f5f5f5',
    darkGray: '#393939',
    lightGray: '#eee',
    statusGray: '#666',
    selectboxGray: '#e5e5e5',
    black: '#000',
    dialogBlack: '#1e1e1e',
    yellow: '#f9ca50',
    red: '#f95061',
    lightRed: '#fff2f4',
    blue: '#3478f6',
    lightGreen: '#E6F4F3',
  },
  buttonColors: {
    primary: '#81cac4',
    secondary: '#ca8194',
    white: '#fff',
    gray: '#666',
    black: '#222',
    red: '#f95061',
    blue: '#4597ec',
  },
  textColors: {
    primary: '#81cac4',
    white: '#fff',
    black: '#222',
    darkBlack: '#000',
    red: '#f95061',
    blue: '#3478f6',
    yellow: '#f9ca50',
    gray: '#666',
    lightGray: '#999',
    placeholder: '#ccc',
  },
  borderColors: {
    primary: '#81cac4',
    white: '#fff',
    black: '#222',
    darkBlack: '#000',
    dialogBlack: '#393939',
    gray: '#ccc',
    lightGray: '#ddd',
    red: '#f95061',
  },
} as const

type Theme = typeof theme

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}
