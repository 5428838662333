import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import * as Sentry from '@sentry/react'
import { MutationFunctionOptions, FetchResult } from '@apollo/client'
import {
  Exact,
  LogoutBusinessUserMutation,
  useLogoutBusinessUserMutation,
  useLogoutCreatorMutation,
  LogoutCreatorMutation,
  useLogoutUserMutation,
  LogoutUserMutation,
} from '../types/graphql'
import {
  initialBusinessUserToken,
  BusinessUserTokenContext,
  CreatorTokenContext,
  initialCreatorToken,
  UserTokenContext,
  initialUserToken,
} from '../context'

export const useBusinessUserLogout = (): ((
  options?:
    | MutationFunctionOptions<
        LogoutBusinessUserMutation,
        Exact<{
          [key: string]: never
        }>
      >
    | undefined,
) => Promise<FetchResult<LogoutBusinessUserMutation>>) => {
  const history = useHistory()

  const { updateBusinessUserToken } = useContext(BusinessUserTokenContext)
  const [, , removeCookie] = useCookies(['token'])

  const [logoutBusinessUserMutation] = useLogoutBusinessUserMutation({
    onCompleted: () => {
      history.push('/business/login')
      updateBusinessUserToken(initialBusinessUserToken.businessUserToken)
      removeCookie('businessUserToken', { path: '/' })
    },
    onError: (e) => {
      Sentry.captureException(e)
    },
  })

  return logoutBusinessUserMutation
}

export const useCreatorLogout = (): ((
  options?:
    | MutationFunctionOptions<
        LogoutCreatorMutation,
        Exact<{
          [key: string]: never
        }>
      >
    | undefined,
) => Promise<FetchResult<LogoutCreatorMutation>>) => {
  const history = useHistory()

  const { updateCreatorToken } = useContext(CreatorTokenContext)
  const [, , removeCookie] = useCookies(['token'])

  const [logoutCreatorMutation] = useLogoutCreatorMutation({
    onCompleted: () => {
      history.push('/creator/login')
      updateCreatorToken(initialCreatorToken.creatorToken)
      removeCookie('creatorToken', { path: '/' })
    },
    onError: (e) => {
      Sentry.captureException(e)
    },
  })

  return logoutCreatorMutation
}

export const useUserLogout = (): ((
  options?:
    | MutationFunctionOptions<
        LogoutUserMutation,
        Exact<{
          [key: string]: never
        }>
      >
    | undefined,
) => Promise<FetchResult<LogoutUserMutation>>) => {
  const history = useHistory()

  const { updateUserToken } = useContext(UserTokenContext)
  const [, , removeCookie] = useCookies(['token'])

  const [logoutUserMutation] = useLogoutUserMutation({
    onCompleted: () => {
      history.push('/login')
      updateUserToken(initialUserToken.userToken)
      removeCookie('userToken', { path: '/' })
    },
    onError: (e) => {
      Sentry.captureException(e)
    },
  })

  return logoutUserMutation
}
