import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { UserMypageEditCompleteTpl, UserTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'

import { useUpdateUserAccountMutation } from '../../../types/graphql'
import { useSetUserProfile } from '../../../lib/SetMyProfile'
import { useValidateUserToken } from '../../../lib/ValidateUserToken'

import { FlashMessageType } from '../../../types/myTypes'
import { UserProfileContext } from '../../../context'

export const UserMypageEditComplete: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const { user } = useContext(UserProfileContext)

  const location = useLocation<{ email: string }>()

  const [updateUserAccountMutation, { loading }] = useUpdateUserAccountMutation({
    onCompleted: () => {
      setFlashMessage({ type: 'success', message: '確認メールを再送しました' })
    },
    onError: (e) => {
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '再送できませんでした' })
      }
    },
  })

  const onClickResendConfirmMail = async (): Promise<void> => {
    updateUserAccountMutation({
      variables: {
        input: {
          name: user?.name,
          email: email,
          twitter_username: user?.twitter_username,
        },
      },
    })
  }

  useValidateUserToken()
  useSetUserProfile()

  useEffect(() => {
    setEmail(location.state.email)
  }, [location.state.email])

  return (
    <>
      <Meta title="メールアドレス更新用メール送信" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <UserTwoColumnLayout isVisibleUserEditButton={false}>
        <UserMypageEditCompleteTpl email={email} onClickResendConfirmMail={onClickResendConfirmMail} />
      </UserTwoColumnLayout>
    </>
  )
}
