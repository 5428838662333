import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorTwoColumnLayout, CreatorProductDetailTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { Product, usePagedCreatorPublishedProductsLazyQuery, useCreatorProductLazyQuery } from '../../../types/graphql'
import { FlashMessageType } from '../../../types/myTypes'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import { CreatorProfileContext } from '../../../context'

type Props = {} & RouteComponentProps<{ id: string }>

export const CreatorProductDetail: React.FC<Props> = (props) => {
  const [product, setProduct] = useState<Product>()
  const [otherProducts, setOtherProducts] = useState<Product[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const creatorId = useContext(CreatorProfileContext).creator.id

  const [pagedCreatorPublishedProductsLazyQuery] = usePagedCreatorPublishedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setOtherProducts(data.PagedCreatorPublishedProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [creatorProductLazyQuery] = useCreatorProductLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setProduct(data.CreatorProduct)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  useValidateCreatorToken()
  useSetCreatorProfile()

  useEffect(() => {
    creatorProductLazyQuery({
      variables: {
        product_id: props.match.params.id,
      },
    })
    if (creatorId) {
      pagedCreatorPublishedProductsLazyQuery({
        variables: {
          exclude_product_id: props.match.params.id,
          first: 8,
          page: 1,
        },
      })
    }
  }, [props.match.params.id, creatorId, creatorProductLazyQuery, pagedCreatorPublishedProductsLazyQuery])
  return (
    <>
      <Meta title="商品詳細" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {product && (
        <>
          <CreatorTwoColumnLayout currentTab="products">
            <CreatorProductDetailTpl product={product} otherProducts={otherProducts} />
          </CreatorTwoColumnLayout>
        </>
      )}
    </>
  )
}
