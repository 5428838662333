import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { BusinessUserProfileContext, CreatorProfileContext, UserProfileContext } from '../context'
import {
  useCurrentBusinessUserLazyQuery,
  useCurrentCreatorLazyQuery,
  useCurrentUserLazyQuery,
  usePagedUserActiveCreatorsFavoritedLazyQuery,
} from '../types/graphql'

export const useSetMyProfile = (): void => {
  const { businessName, businessEmail, updateBusinessId, updateBusinessName, updateBusinessEmail } = useContext(BusinessUserProfileContext)

  const history = useHistory()

  const [currentBusinessUserQuery] = useCurrentBusinessUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      updateBusinessId(data.CurrentBusinessUser?.id ?? '')
      updateBusinessName(data.CurrentBusinessUser?.name ?? '')
      updateBusinessEmail(data.CurrentBusinessUser?.email ?? '')
    },
    onError: (e) => {
      Sentry.captureException(e)
      history.push({ pathname: '/business/login', state: { redirect: true } })
    },
  })

  useEffect(() => {
    if (businessName === '' || businessEmail === '') {
      currentBusinessUserQuery()
    }
  }, [businessEmail, businessName, currentBusinessUserQuery])
}

export const useSetCreatorProfile = (): void => {
  const { creator, updateCreator } = useContext(CreatorProfileContext)

  const history = useHistory()

  const [currentCreatorQuery] = useCurrentCreatorLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.CurrentCreator) {
        updateCreator(data.CurrentCreator)
      }
    },
    onError: (e) => {
      Sentry.captureException(e)
      history.push({ pathname: '/business/login', state: { redirect: true } })
    },
  })

  useEffect(() => {
    if (creator) {
      currentCreatorQuery()
    }
  }, [creator, currentCreatorQuery])
}

export const useSetUserProfile = (): void => {
  const { user, updateUser, updateFavoriteCreatorsNumber } = useContext(UserProfileContext)

  const history = useHistory()

  const [currentUserLazyQuery] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.CurrentUser) {
        updateUser(data.CurrentUser)
      }
    },
    onError: (e) => {
      Sentry.captureException(e)
      history.push({ pathname: '/login', state: { redirect: true } })
    },
  })

  const [pagedUserActiveCreatorsFavoritedLazyQuery] = usePagedUserActiveCreatorsFavoritedLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.PagedUserActiveCreatorsFavorited) {
        updateFavoriteCreatorsNumber(data.PagedUserActiveCreatorsFavorited.paginatorInfo.total)
      }
    },
    onError: (e) => {
      Sentry.captureException(e)
      history.push({ pathname: '/login', state: { redirect: true } })
    },
  })

  useEffect(() => {
    if (user) {
      currentUserLazyQuery()
      pagedUserActiveCreatorsFavoritedLazyQuery({
        variables: {
          // totalの数が欲しいだけなので適当に指定
          first: 1,
        },
      })
    }
  }, [user, currentUserLazyQuery, pagedUserActiveCreatorsFavoritedLazyQuery])
}
