export const requestPhotoOptions = [
  {
    id: 'is_newly_taken',
    name: 'is_newly_taken',
    value: 'is_newly_taken',
    label: 'あなたのために撮り下ろした写真',
  },
  {
    id: 'is_casual_clothes',
    name: 'is_casual_clothes',
    value: 'is_casual_clothes',
    label: '私服',
  },
  {
    id: 'is_pajamas',
    name: 'is_pajamas',
    value: 'is_pajamas',
    label: 'パジャマ姿',
  },
  {
    id: 'is_other',
    name: 'is_other',
    value: 'is_other',
    label: 'その他',
  },
]

export const requestMovieOptions = [
  {
    id: 'is_casual_clothes',
    name: 'is_casual_clothes',
    value: 'is_casual_clothes',
    label: '私服',
  },
  {
    id: 'is_pajamas',
    name: 'is_pajamas',
    value: 'is_pajamas',
    label: 'パジャマ姿',
  },
  {
    id: 'is_good_morning_message',
    name: 'is_good_morning_message',
    value: 'is_good_morning_message',
    label: 'おはようメッセージ',
  },
  {
    id: 'is_good_night_message',
    name: 'is_good_night_message',
    value: 'is_good_night_message',
    label: 'おやすみメッセージ',
  },
  {
    id: 'is_say_you_like_me',
    name: 'is_say_you_like_me',
    value: 'is_say_you_like_me',
    label: '好きと言ってほしい',
  },
  {
    id: 'is_other',
    name: 'is_other',
    value: 'is_other',
    label: 'その他',
  },
]

export const requestLiveTalkOptions = [
  {
    id: 'is_casual_clothes',
    name: 'is_casual_clothes',
    value: 'is_casual_clothes',
    label: '私服',
  },
  {
    id: 'is_pajamas',
    name: 'is_pajamas',
    value: 'is_pajamas',
    label: 'パジャマ姿',
  },
  {
    id: 'is_other',
    name: 'is_other',
    value: 'is_other',
    label: 'その他',
  },
]
