import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  src?: string
  alt?: string
  onClick?: () => void
}

const StyledImg = styled.img``

export const Icon: React.FC<Props> = ({ className = '', src = '', alt = '', onClick }) => {
  return <StyledImg className={className} src={src} alt={alt} onClick={onClick} />
}
