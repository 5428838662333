import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  OrderedProduct,
  usePagedCreatorDeliveredOrderedProductPerUserLazyQuery,
  usePagedCreatorUndeliveredOrderedProductPerUserLazyQuery,
} from '../../../types/graphql'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import { CreatorOrderedProductPerUserTpl } from '../../../components/templates/Creator/Orders/OrderedProductPerUser'

type Props = {} & RouteComponentProps<{ id: string }>

export const CreatorOrderedProductPerUser: React.FC<Props> = (props) => {
  const [undeliveredOrders, setUndeliveredOrders] = useState<OrderedProduct[]>([])
  const [deliveredOrders, setDeliveredOrders] = useState<OrderedProduct[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [pagedCreatorUndeliveredOrderedProductPerUserLazyQuery] = usePagedCreatorUndeliveredOrderedProductPerUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setUndeliveredOrders(data.PagedCreatorUndeliveredOrderedProductPerUser?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedCreatorDeliveredOrderedProductsLazyQuery] = usePagedCreatorDeliveredOrderedProductPerUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setDeliveredOrders(data.PagedCreatorDeliveredOrderedProductPerUser?.data ?? [])
        setLastPage(data.PagedCreatorDeliveredOrderedProductPerUser?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  useValidateCreatorToken()
  useSetCreatorProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    pagedCreatorUndeliveredOrderedProductPerUserLazyQuery({
      variables: {
        first: 30,
        page: 1,
        user_id: props.match.params.id,
      },
    })
    pagedCreatorDeliveredOrderedProductsLazyQuery({
      variables: {
        first: 30,
        page: Number(pageParam),
        user_id: props.match.params.id,
      },
    })
  }, [
    pagedCreatorUndeliveredOrderedProductPerUserLazyQuery,
    pagedCreatorDeliveredOrderedProductsLazyQuery,
    props.location.search,
    props.match.params.id,
  ])

  let username = undeliveredOrders.length > 0 ? undeliveredOrders[0].order.user.name : ''
  username = deliveredOrders.length > 0 ? deliveredOrders[0].order.user.name : username

  return (
    <>
      <Meta title="購入者の注文一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="users">
        <CreatorOrderedProductPerUserTpl
          username={username}
          undeliveredOrders={undeliveredOrders}
          deliveredOrders={deliveredOrders}
          currentPage={currentPage}
          lastPage={lastPage}
        />
      </CreatorTwoColumnLayout>
    </>
  )
}
