import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css, ThemeProps, DefaultTheme } from 'styled-components'
import mediaQuery from 'styled-media-query'
import { externalLink } from '../../../config'
import { Icon, SnsIcon } from '../../atoms'
import {
  APP_NAME,
  CONTACT_URL,
  USER_FAQ_URL,
  CREATOR_AND_BUSINESS_FAQ_URL,
  SOCIAL_TWITTER_URL,
  SOCIAL_FACEBOOK_URL,
  SOCIAL_INSTAGRAM_URL,
  SOCIAL_YOUTUBE_URL,
} from '../../../const'
import logoFooter from '../../../assets/images/common/logo_footer.png'
import forCreator from '../../../assets/images/icon/for_creator.png'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

type Props = {}

const StyledFooter = styled.footer`
  width: 100vw;
  background: ${(props): string => props.theme.backgroundColors.secondary};

  .inner {
    padding: 30px 5.6vw 15px;
    position: relative;

    .logo {
      padding: 8.5px 0;
    }

    .footer-bottom {
      & + .footer-side {
        margin-top: 35px;
      }

      .link {
        width: 100%;
        padding: 23px 0 0;

        li {
          padding: 5px 0 5px 10px;
          position: relative;

          &:before {
            position: absolute;
            top: 13px;
            left: 0;
            width: 3px;
            height: 1px;
            background: ${(props): string => props.theme.backgroundColors.bodyBg};
            content: '';
          }

          a {
            color: ${(props): string => props.theme.textColors.white};
            letter-spacing: 0.1em;
          }
        }

        & + .creator-login,
        & + .user-login {
          margin-top: 19px;
        }
      }

      .creator-login,
      .user-login {
        width: 100%;
        height: 30px;
        margin-bottom: 17px;
        border: 1px solid ${(props): string => props.theme.borderColors.white};
        border-radius: 4px;

        a {
          width: 100%;
          height: 100%;
          font-weight: 500;
          font-size: 12px;
          line-height: 30px;
          letter-spacing: 0.1em;
          color: ${(props): string => props.theme.textColors.white};
          text-align: center;
          display: block;
          cursor: pointer;
        }
      }
    }

    .footer-side {
      width: 100%;

      .copyright {
        width: 100%;
        text-align: center;

        small {
          letter-spacing: 0.15em;
          color: ${(props): string => props.theme.textColors.white};
          line-height: 20px;
          font-size: 1.5vw;
        }
      }

      .sns {
        width: 146px;
        position: absolute;
        right: 5.6vw;
        top: 30px;

        ul {
          display: flex;
          justify-content: space-between;

          .sns-icon {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  ${mediaPc`
    width: 100%;
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

    .inner {
      width: 1040px;
      margin: 0 auto;
      padding: 30px 20px 50px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .footer-bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & + .footer-side {
          margin: 0;
        }

        .link {
          width: calc(100% - 252px);
          min-width: 720px;
          padding: 23px 0 0;

          ul{
            display: flex;
            justify-content: space-between;
            li {
              margin-right: 20px;
              padding: 5px 0;
              position: relative;

              &:before {
                position: absolute;
                top: 13px;
                left: -10px;
                width: 3px;
                height: 1px;
                background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
                content: '';
              }

              a {
                color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
                font-size: 12px;
                letter-spacing: 0.1em;
              }
            }
          }
        }

        .creator-login,
        .user-login {
          height: 36px;
          padding: 0 15px 0 12px;
          border: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.black};
          border-radius: 4px;

          a{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .for-creator-icon {
              width: 17px;
              height: 17px;
            }

            span {
              color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
              font-size: 12px;
              line-height: 36px;
              letter-spacing: 0.1em;
            }
          }
        }
        .creator-login {
          width: 210px;
        }
        .user-login {
          width: 189px;
        }
      }

      .footer-side {
        width: 61px;
        height: 566px;
        margin: 0;
        position: fixed;
        left: -40px;
        top: 180px;
        z-index: 10;
        background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.secondary};
        border-radius: 0 10px 10px 0;
        transition: 0.3s all;
        display: flex;
        flex-wrap: wrap;

        &:hover {
          left: 0;
        }

        .sns {
          position: static;

          ul {
            width: 24px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            li{
              margin-bottom: 15px;
              transition: 0.3s all;

              &:hover{
                opacity: 0.8;
              }

              .sns-icon {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        .copyright {
          width: 61px;
          height: 400px;

          small {
            width: 400px;
            height: 61px;
            letter-spacing: 0.1em;
            line-height: 61px;
            font-size: 9px;
            transform: rotate(-90deg);
            transform-origin: 183px 185px;
            display: block;
          }
        }
      }
    }
  `}
  ${
    userType() === 'creator' &&
    css`
      ${mediaPc`
        .footer-side {
          border: 1px solid #fff;
          border-width: 1px 1px 1px 0;
        }
      `}
    `
  }

  /* 仮 フッターがコンテンツに隠れないウィンドウサイズ */
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  ${mediaQuery.greaterThan('1120px')`
    .inner .footer-side {
      left: 0;
    }
  `}
`

export const Footer: React.FC<Props> = () => {
  const urlPerUser = (pathName: string): string => {
    if (userType() === 'creator') {
      return `/creator${pathName}`
    }
    if (userType() === 'business') {
      return `/business${pathName}`
    }
    return `${pathName}`
  }

  return (
    <StyledFooter>
      <div className="inner">
        <div className="logo sp">
          <Link to={urlPerUser('/')}>
            <Icon src={logoFooter} alt={APP_NAME} />
          </Link>
        </div>

        <div className="footer-bottom">
          <div className="link">
            <ul>
              <li>
                <a href={CONTACT_URL} target="_blank" rel="noopener noreferrer">
                  お問い合わせ
                </a>
              </li>
              <li>
                <a href={userType() === 'user' ? USER_FAQ_URL : CREATOR_AND_BUSINESS_FAQ_URL} target="_blank" rel="noopener noreferrer">
                  よくある質問
                </a>
              </li>
              <li>
                <Link to={urlPerUser('/terms-of-sale')}>特定商取引法に基づく表記</Link>
              </li>
              <li>
                <Link to={urlPerUser('/privacy')}>プライバシーポリシー</Link>
              </li>
              <li>
                <Link to={urlPerUser('/terms-of-use')}>利用規約</Link>
              </li>
            </ul>
          </div>

          {userType() !== 'creator' && (
            <div className="creator-login">
              <a href={externalLink.creatorLogin} target="_blank" rel="noopener noreferrer">
                <Icon src={forCreator} alt="クリエイターの方はこちら" className="pc for-creator-icon" />
                <span>クリエイターの方はこちら</span>
              </a>
            </div>
          )}
          {userType() === 'creator' && (
            <div className="user-login">
              <a href={externalLink.userLogin} target="_blank" rel="noopener noreferrer">
                <Icon src={forCreator} alt="ユーザーの方はこちら" className="pc for-creator-icon" />
                <span>ユーザーの方はこちら</span>
              </a>
            </div>
          )}
        </div>

        <div className="footer-side">
          <div className="copyright">
            <small>Copyright(C) {APP_NAME}. All Rights Reserved.</small>
          </div>

          <div className="sns">
            <ul>
              <li>
                <a href={SOCIAL_TWITTER_URL} target="_blank" rel="noopener noreferrer">
                  <SnsIcon sns="twitter" type="footer" className="sns-icon" />
                </a>
              </li>
              <li>
                <a href={SOCIAL_INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
                  <SnsIcon sns="instagram" type="footer" className="sns-icon" />
                </a>
              </li>
              <li>
                <a href={SOCIAL_YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
                  <SnsIcon sns="youtube" type="footer" className="sns-icon" />
                </a>
              </li>
              <li>
                <a href={SOCIAL_FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
                  <SnsIcon sns="facebook" type="footer" className="sns-icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}
