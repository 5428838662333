import React from 'react'
import styled from 'styled-components'
import { CreatorList, SubtitleWithButton, Pagination } from '../../molecules'
import { Creator } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  creators: Creator[]
  lastPage: number
  currentPage: number
  openCreatorRegistrationModal: () => void
}

const Wrapper = styled.div`
  .title {
    margin-bottom: 5vw;
  }

  .creator-list {
    margin-bottom: 10.7vw;
  }

  .no-creator {
    font-size: 3.735vw;
    text-align: center;
    font-weight: 500;
    color: ${(props): string => props.theme.textColors.gray};
    line-height: 1.14;
    letter-spacing: 0.05em;
  }

  ${mediaPc`
    .title {
      margin-bottom: 20px;
    }

    .creator-list {
      margin-bottom: 40px;
    }

    .no-creator {
      margin-top: 30px;
      font-size: 14px;
    }
  `}
`

export const BusinessCreators: React.FC<Props> = ({ creators, lastPage, currentPage, openCreatorRegistrationModal }) => {
  return (
    <Wrapper>
      <SubtitleWithButton
        subtitle="クリエイター一覧"
        buttonType="red"
        buttonText="+ 新規クリエイター登録"
        className="title"
        onClick={openCreatorRegistrationModal}
      />
      {!!creators.length && (
        <>
          <CreatorList creators={creators} displayAffiliation className="creator-list" />
          <Pagination lastPage={lastPage} currentPage={currentPage} />
        </>
      )}
      {!creators.length && <p className="no-creator">クリエイターは存在しません</p>}
    </Wrapper>
  )
}
