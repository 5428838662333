import React from 'react'
import styled from 'styled-components'
import { UserRankingTable } from '../../../molecules'
import { mediaPc } from '../../../../lib/MediaQuery'
import { CreatorPurchaseUserRanking } from '../../../../types/graphql'

type Props = {
  className?: string
  ranking: CreatorPurchaseUserRanking[]
}

const Wrapper = styled.div`
  .year-select {
    margin-bottom: 6.4vw;
    select {
      color: ${(props): string => props.theme.textColors.black};
    }
  }

  ${mediaPc`
    .year-select {
      margin-bottom: 15px;
      width: 200px;
    }
  `}
`

export const CreatorUserRanking: React.FC<Props> = ({ className = '', ranking }) => {
  return (
    <Wrapper className={className}>
      <UserRankingTable ranking={ranking} noDataText="購入ユーザーのランキングが表示されます" />
    </Wrapper>
  )
}
