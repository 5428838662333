import React from 'react'
import styled from 'styled-components'
import { Pagination } from '../../../molecules'
import { PurchasedProductList } from '../../../organisms'
import { PublishedOrderedProduct, ProductType } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  purchasedProducts: PublishedOrderedProduct[]
  lastPage: number
  currentPage: number
  onClickPurchasedProduct: (productId: string, productType: ProductType) => void
}

const Wrapper = styled.div`
  .no-data {
    font-size: 3.735vw;
    text-align: center;
    font-weight: 500;
    color: ${(props): string => props.theme.textColors.gray};
    line-height: 1.14;
    letter-spacing: 0.05em;
  }
  ${mediaPc`
    .no-data {
      margin-top: 30px;
      font-size: 14px;
    }
  `}
`
export const UserMypageTopTpl: React.FC<Props> = ({ purchasedProducts, lastPage, currentPage, onClickPurchasedProduct }) => {
  return (
    <Wrapper>
      {!!purchasedProducts.length && (
        <>
          <PurchasedProductList purchasedProducts={purchasedProducts} onClickPurchasedProduct={onClickPurchasedProduct} />
          <Pagination lastPage={lastPage} currentPage={currentPage} />
        </>
      )}
      {!purchasedProducts.length && <p className="no-data">購入した写真・動画はありません</p>}
    </Wrapper>
  )
}
