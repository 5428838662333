import React from 'react'
import styled from 'styled-components'
import { PageTitle, Icon } from '../../atoms'
import productIcon from '../../../assets/images/icon/mountain.png'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    width: 7.6vw;
    margin-right: 2vw;
  }

  .page-title {
    margin: 1.2vw 0 1.5vw;
    line-height: 1;
    letter-spacing: 0.3em;
  }

  ${mediaPc`
    .icon {
      width: 58px;
      margin-right: 13px;
    }

    .page-title {
      margin:0;
      line-height: 32px;
    }
  `}
`

export const ProductsOnSaleTitle: React.FC<Props> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Icon src={productIcon} alt="新着商品" className="icon" />
      <div className="title">
        <PageTitle title="新着商品" className="page-title" />
      </div>
    </Wrapper>
  )
}
