import React, { useState } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Icon, Button, FileInput } from '../../atoms'
import { SelectboxWithLabel, TextInputWithLabel, TextareaWithLabel } from '../../molecules'

import { Creator, Affiliation } from '../../../types/graphql'
import { CreatorEditErrors } from '../../../types/myTypes'

import { mediaPc } from '../../../lib/MediaQuery'
import { validateImage, validateEmail, validateAtSignUserName, validateUrl, validateRequired } from '../../../lib/Validate'

import defaultIcon from '../../../assets/images/icon/man_icon_default.png'

type Props = {
  creator: Creator
  affiliations?: Affiliation[]
  iconUrl: string
  isBusinessUser?: boolean
  onClickUpdateButton?: () => void
  onChangeState: (name: string, value: string) => void
  onIconLoad: (result: string, file: File) => void
}

const Profile = styled.div`
  .error-message {
    transition: 0.3s all;
    color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.red};
  }
  width: 100%;
  margin-bottom: 4.5vw;
  padding: 5vw 5vw 10vw;
  background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

  .creator-photo {
    width: 100%;
    margin: 0 auto;
    span {
      margin-bottom: 2.1vw;
      font-size: 2.6vw;
      color: ${(props): string => props.theme.textColors.lightGray};
    }
    .photo {
      width: 30vw;
      height: 30vw;
      margin: 0 auto 2.1vw;
      object-fit: cover;
      border-radius: 50%;
      &.default {
        border-radius: 0;
      }
    }
    .file-select {
      width: 30vw;
      margin: 0 auto;
    }
  }
  .affiliation-row {
    width: 100%;
    margin-bottom: 5.1vw;
    display: block;
    font-size: 2.667vw;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: ${(props): string => props.theme.textColors.gray};
    line-height: 1.2em;
    p {
      margin: 4.4vw 0 8.8vw;
      font-size: 3.733vw;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.black};
    }
  }

  ${mediaPc`
    width: 100%;
    margin-bottom: 25px;
    padding: 15px 0 0;

    .creator-photo {
      margin: 0 auto;
      span {
        font-size: 12px;
        color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.lightGray};
      }
      .photo {
        width: 100px;
        height: 100px;
        margin: 0 auto 10px;
      }
      .file-select {
        width: 100px;
      }
    }
    .affiliation-row {
      display: flex;
      margin:0;
      padding-bottom: 40px;
      label {
        width: 63px;
        font-size:12px;
        line-height: initial;
      }
      p {
        font-size:14px;
        margin:0;
        line-height: initial;
      }
    }
    .update {
      width: 150px;
      height: 40px;
      margin: 0 auto;
      display: block;
      line-height: 40px;
    }
  `}
`

const EditItem = styled.div`
  position: relative;
  padding-bottom: 8vw;
  .edit {
    label {
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.lightGray};
    }
    input[type='text'],
    input[type='email'],
    textarea,
    select {
      border: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.lightGray};

      &:focus {
        outline: 0;
        border: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.primary};
      }
      &::placeholder {
        color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.placeholder};
        font-weight: 500;
      }
      &.error {
        border: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.red};
        &:focus {
          border: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.red};
        }
      }
    }
    &.at-sign-input {
      position: relative;
      input {
        padding: 0 15px 0 12%;
      }
      &::before {
        margin-right: 5px;
        content: '@';
        line-height: 2.4em;
        color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.placeholder};
        display: block;
        font-size: 3.733vw;
        position: absolute;
        top: 45%;
        left: 5%;
      }
    }
  }
  .error-message {
    position: absolute;
    left: 0;
    bottom: 3vw;
    animation-duration: 0.3s;
    animation-name: viewOpacity;
    font-size: 2.4vw;
  }
  @keyframes viewOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${mediaPc`
    padding-bottom: 40px;

    .edit {
      label {
        margin-bottom: 10px;
        font-size: 12px;
      }
      &.at-sign-input {
        input {
          padding-left: 30px;
        }
        &::before {
          margin-right: 5px;
          line-height: 2.4em;
          font-size: 14px;
          top: 35px;
          left: 10px;
        }
      }
    }
    .error-message {
      bottom: 15px;
      font-size: 12px;
    }
  `}
`

export const CreatorEdit: React.FC<Props> = ({
  creator,
  iconUrl,
  affiliations,
  isBusinessUser = true,
  onClickUpdateButton,
  onChangeState,
  onIconLoad,
}) => {
  const [errors, setError] = useState<CreatorEditErrors>({
    icon_url: null,
    name: null,
    email: null,
    profile: null,
    twitter_username: null,
    instagram_username: null,
    youtube_channel_url: null,
    kindle_author_url: null,
  })

  // バリデーション無し
  const onChangeValue = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const value = event.target.value
    const name = event.target.name
    if (name === 'profile') {
      onChangeState(name, value)
    } else if (name === 'affiliation_id') {
      onChangeState(name, value)
    } else if (name === 'instagram_username') {
      onChangeState(name, value)
    }
  }

  const onChangeIcon = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null && event.target.files[0] !== undefined) {
      const file = event.target.files[0]
      const errorText: string | null = validateImage(file)
      if (errorText === null) {
        const objectUrl = URL.createObjectURL(file)
        onIconLoad(objectUrl, file)
      }
      setError({ ...errors, icon_url: errorText })
    }
  }

  const oncChangeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const name = event.target.name
    let errorText: string | null = validateRequired(value)
    if (errorText !== null) {
      errorText = `クリエイター名の登録は${errorText}`
    }
    onChangeState(name, value)
    setError({ ...errors, name: errorText })
  }

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const name = event.target.name
    const errorText: string | null = validateEmail(value)
    onChangeState(name, value)
    setError({ ...errors, email: errorText })
  }

  const onChangeAtSignUserName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const name = event.target.name
    const errorText: string | null = validateAtSignUserName(value)
    onChangeState(name, value)
    setError({ ...errors, [name]: errorText })
  }

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const name = event.target.name
    let errorText: string | null = validateUrl(value)

    if (name === 'youtube_channel_url') {
      if (errorText !== null) {
        errorText = `YouTube チャンネル URLは${errorText}`
      }
      onChangeState(name, value)
      setError({ ...errors, youtube_channel_url: errorText })
    } else if (name === 'kindle_author_url') {
      if (errorText !== null) {
        errorText = `Kindle 販売ページURLは${errorText}`
      }
      onChangeState(name, value)
      setError({ ...errors, kindle_author_url: errorText })
    }
  }

  // affiliationsからoptionに渡す配列を抽出
  const affiliationOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]

  if (isBusinessUser && affiliations) {
    affiliations.forEach((affiliation) => {
      affiliationOptions.push({ value: affiliation.id, label: affiliation.name })
    })
  }

  const isDisable = (): boolean => {
    const errorValues = Object.values(errors)
    return errorValues.some((v) => v !== null)
  }

  return (
    <Profile>
      <EditItem>
        <div className="creator-photo">
          {iconUrl === '' && <span className="test">アイコンを登録してください</span>}
          <Icon className={iconUrl !== '' ? 'photo' : 'photo default'} src={iconUrl !== '' ? iconUrl : defaultIcon} alt={creator.name} />
          <FileInput className="file-select" name="icon_url" onChange={onChangeIcon}>
            写真を選択
          </FileInput>
          {errors.icon_url !== null && <p className="error-message">{errors.icon_url}</p>}
        </div>
      </EditItem>

      <EditItem>
        <TextInputWithLabel
          className={errors.name !== null ? 'edit error' : 'edit'}
          required
          label="クリエイター名"
          name="name"
          value={creator.name}
          placeholder="クリエイターの名前を入力してください"
          onChange={oncChangeName}
        />
        {errors.name !== null && <p className="error-message">{errors.name}</p>}
      </EditItem>

      <EditItem>
        <TextInputWithLabel
          className={errors.email !== null ? 'edit error' : 'edit'}
          required
          type="email"
          label="メールアドレス"
          name="email"
          value={creator.email}
          placeholder="メールアドレスを入力してください"
          onChange={onChangeEmail}
        />
        {errors.email !== null && <p className="error-message">{errors.email}</p>}
      </EditItem>

      <EditItem>
        <TextareaWithLabel
          className="edit"
          label="プロフィールテキスト(200文字まで)【任意】"
          name="profile"
          value={creator.profile}
          maxLength={200}
          placeholder="プロフィールを入力してください(200文字)"
          onChange={onChangeValue}
        />
      </EditItem>
      {isBusinessUser && affiliations && (
        <EditItem>
          <SelectboxWithLabel
            className="edit"
            label="所属【任意】"
            name="affiliation_id"
            options={affiliationOptions}
            value={creator.affiliation_id ?? ''}
            onChange={onChangeValue}
          />
        </EditItem>
      )}
      {!isBusinessUser && (
        <div className="affiliation-row">
          <label>所属</label>
          <p>{creator.affiliation?.name ?? '所属なし'}</p>
        </div>
      )}

      <EditItem>
        <TextInputWithLabel
          className={errors.twitter_username !== null ? 'edit at-sign-input error' : 'edit at-sign-input'}
          label="twitter ユーザー名【任意】"
          name="twitter_username"
          value={creator.twitter_username}
          placeholder="@マーク以降を入力してください"
          onChange={onChangeAtSignUserName}
        />
        {errors.twitter_username !== null && <p className="error-message">{errors.twitter_username}</p>}
      </EditItem>

      <EditItem>
        <TextInputWithLabel
          className="edit at-sign-input"
          label="instagram ユーザーネーム【任意】"
          name="instagram_username"
          value={creator.instagram_username}
          placeholder="@マーク以降を入力してください"
          onChange={onChangeAtSignUserName}
        />
        {errors.instagram_username !== null && <p className="error-message">{errors.instagram_username}</p>}
      </EditItem>

      <EditItem>
        <TextInputWithLabel
          className={errors.youtube_channel_url !== null ? 'edit error' : 'edit'}
          label="YouTube チャンネル URL【任意】"
          name="youtube_channel_url"
          value={creator.youtube_channel_url}
          placeholder="URLを入力してください"
          onChange={onChangeUrl}
        />
        {errors.youtube_channel_url !== null && <p className="error-message">{errors.youtube_channel_url}</p>}
      </EditItem>

      <EditItem>
        <TextInputWithLabel
          className={errors.kindle_author_url !== null ? 'edit error' : 'edit'}
          label="Kindle販売ページ URL【任意】"
          name="kindle_author_url"
          value={creator.kindle_author_url}
          placeholder="URLを入力してください"
          onChange={onChangeUrl}
        />
        {errors.kindle_author_url !== null && <p className="error-message">{errors.kindle_author_url}</p>}
      </EditItem>

      <Button disabled={isDisable()} className="update" buttonType="primary" onClick={onClickUpdateButton}>
        更新
      </Button>
    </Profile>
  )
}
