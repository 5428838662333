import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  OrderedProduct,
  useCreatorInBusinessLazyQuery,
  usePagedBusinessUndeliveredOrderedProductPerUserLazyQuery,
  usePagedBusinessDeliveredOrderedProductPerUserLazyQuery,
} from '../../../types/graphql'

import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { BusinessCreatorOrderedProductPerUserTpl } from '../../../components/templates/Business/Creator/OrderedProductPerUser'

type Props = {} & RouteComponentProps<{ id: string; user_id: string }>

export const BusinessCreatorOrderedProductPerUser: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [undeliveredOrders, setUndeliveredOrders] = useState<OrderedProduct[]>([])
  const [deliveredOrders, setDeliveredOrders] = useState<OrderedProduct[]>([])

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)

  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [creatorInBusinessLazyQuery] = useCreatorInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setCreator(data.CreatorInBusiness)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedBusinessUndeliveredOrderedProductPerUserLazyQuery] = usePagedBusinessUndeliveredOrderedProductPerUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setUndeliveredOrders(data.PagedBusinessUndeliveredOrderedProductPerUser?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedBusinessDeliveredOrderedProductPerUserLazyQuery] = usePagedBusinessDeliveredOrderedProductPerUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setDeliveredOrders(data.PagedBusinessDeliveredOrderedProductPerUser?.data ?? [])
        setLastPage(data.PagedBusinessDeliveredOrderedProductPerUser?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    const pageParam = new URLSearchParams(props.location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    creatorInBusinessLazyQuery({
      variables: {
        id: props.match.params.id,
      },
    })
    pagedBusinessUndeliveredOrderedProductPerUserLazyQuery({
      variables: {
        creator_id: props.match.params.id,
        user_id: props.match.params.user_id,
        first: 30,
        page: 1,
      },
    })
    pagedBusinessDeliveredOrderedProductPerUserLazyQuery({
      variables: {
        creator_id: props.match.params.id,
        user_id: props.match.params.user_id,
        first: 30,
        page: Number(pageParam),
      },
    })
  }, [
    creatorInBusinessLazyQuery,
    props.match.params.id,
    props.match.params.user_id,
    props.location.search,
    pagedBusinessUndeliveredOrderedProductPerUserLazyQuery,
    pagedBusinessDeliveredOrderedProductPerUserLazyQuery,
  ])

  let username = undeliveredOrders.length > 0 ? undeliveredOrders[0].order.user.name : ''
  username = deliveredOrders.length > 0 ? deliveredOrders[0].order.user.name : username

  return (
    <>
      <Meta title={`購入者の注文一覧`} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="creator">
        <>
          {creator && (
            <BusinessCreatorOrderedProductPerUserTpl
              username={username}
              undeliveredOrders={undeliveredOrders}
              deliveredOrders={deliveredOrders}
              currentPage={currentPage}
              lastPage={lastPage}
            />
          )}
        </>
      </BusinessTwoColumnLayout>
    </>
  )
}
