import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { LiveTalk, LiveTalkCountDownTimer, Icon } from '../../../atoms'
import { NotEnteredTalkRoomView, AbsenceLiveTalkView } from '../../../molecules'
import { LocalVideoTrack, LocalAudioTrack, RemoteVideoTrack, RemoteAudioTrack } from 'twilio-video'
import { PublishedProduct } from '../../../../types/graphql'
import { LiveTalkStatus } from '../../../../types/myTypes'
import { useInnerHeight, useOrientationType, OrientationType } from '../../../../lib/SetWindow'
import whiteLeftArrow from '../../../../assets/images/icon/white_left_arrow.png'
import whiteRightArrow from '../../../../assets/images/icon/white_right_arrow.png'

type Props = {
  liveTalkStatus?: LiveTalkStatus
  localTracks: (LocalVideoTrack | LocalAudioTrack | null)[]
  remoteTracks: (RemoteVideoTrack | RemoteAudioTrack | null)[]
  product: PublishedProduct
  talkSeconds: number
}

type StyleProps = {
  innerHeight: number
  orientationType: OrientationType
}

const Wrapper = styled.div<StyleProps>`
  background-color: ${(props): string => props.theme.backgroundColors.black};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 20;
  .inner {
    display: flex;
    justify-content: center;
    .not-entered-wrapper {
      width: calc(100vh * 9 / 16);
      max-height: ${(props): string => `${props.innerHeight}px`};
    }
    .video-wrapper {
      width: auto;
      height: ${(props): string => `${props.innerHeight}px`};
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
      .timer {
        position: absolute;
        left: 9px;
        top: 17px;
        z-index: 4;
      }
      .hide-button {
        display: none;
      }
      .is-audio,
      .visible .is-audio {
        display: none;
      }
    }
  }
  ${(props) =>
    props.orientationType === 'portrait' &&
    css`
      .inner {
        .video-wrapper {
          width: calc(100vh * 9 / 16);
          .hide-button {
            width: 33px;
            height: 44px;
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 15px;
            right: 0px;
            z-index: 2;
            .hide-button-icon {
              width: 7px;
              height: auto;
            }
          }
          .local-video {
            position: absolute;
            bottom: 6px;
            right: -100px;
            border-radius: 4px;
            z-index: 3;
          }
          .visible {
            .hide-button {
              right: 105px;
            }
            .local-video {
              bottom: 6px;
              right: 5px;
            }
          }
        }
      }
    `}
  ${(props) =>
    props.orientationType === 'landscape' &&
    css`
      .inner {
        .video-wrapper {
          width: auto;
          .local-video-wapper {
            width: calc(100vh * 9 / 16);
            height: ${`${props.innerHeight}px`};
          }
        }
      }
    `}
`

export const UserLiveTalkTpl: React.FC<Props> = ({ localTracks, remoteTracks, liveTalkStatus, product, talkSeconds }) => {
  const [localVideoVisible, setLocalVideoVisible] = useState<boolean>(true)
  const innerHeight = useInnerHeight()
  const orientationType = useOrientationType()

  const onClickHideButton = (): void => {
    setLocalVideoVisible(!localVideoVisible)
  }

  return (
    <Wrapper innerHeight={innerHeight} orientationType={orientationType}>
      <div className="inner">
        {!!liveTalkStatus && liveTalkStatus !== 'talking' && (
          <div className="not-entered-wrapper">
            <NotEnteredTalkRoomView liveTalkStatus={liveTalkStatus} thumbnail={product.thumbnail_image} />
          </div>
        )}
        {liveTalkStatus === 'talking' && (
          <div className="video-wrapper">
            <LiveTalkCountDownTimer className="timer" talkSeconds={talkSeconds} />
            <div className={localVideoVisible ? 'visible local-video-wapper' : 'local-video-wapper'}>
              <div className="hide-button" onClick={() => onClickHideButton()}>
                <Icon className="hide-button-icon" src={localVideoVisible ? whiteRightArrow : whiteLeftArrow} alt="ライブトーク" />
              </div>
              {localTracks.map((track) => (
                <LiveTalk
                  track={track}
                  key={track?.name}
                  className={track?.kind === 'audio' ? 'is-audio' : 'local-video'}
                  isLocalVideo={true}
                />
              ))}
            </div>
            {remoteTracks.length > 0 && (
              <>
                {remoteTracks.map((track) => (
                  <LiveTalk track={track} key={track?.name} className={track?.kind === 'audio' ? 'is-audio' : ''} />
                ))}
              </>
            )}
            {remoteTracks.length === 0 && (
              <AbsenceLiveTalkView icon={product.creator?.icon_url} name={product.creator?.name} isUser={true} />
            )}
          </div>
        )}
      </div>
    </Wrapper>
  )
}
