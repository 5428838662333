import { createContext } from 'react'
import { BusinessUser, Creator, User, CreatorAccountStatus, UserAccountStatus } from './types/graphql'

// User
export type UserProfile = {
  user: User
  favoriteCreatorsNumber: number
  updateUser: (user: User) => void
  updateFavoriteCreatorsNumber: (number: number) => void
}

export type UserToken = {
  userToken: string
  updateUserToken: (token: string) => void
}

export const initialUserToken: UserToken = {
  userToken: '',
  updateUserToken: () => {},
}

export const initialUserProfile: UserProfile = {
  user: {
    id: '',
    status: UserAccountStatus.Init,
    name: '',
    email: '',
    twitter_username: '',
    created_at: '',
    updated_at: '',
  },
  favoriteCreatorsNumber: 0,
  updateUser: () => {},
  updateFavoriteCreatorsNumber: () => {},
}

export const UserTokenContext = createContext(initialUserToken)
export const UserProfileContext = createContext(initialUserProfile)

// Creator
export type CreatorProfile = {
  creator: Creator
  updateCreator: (creator: Creator) => void
}

export type CreatorToken = {
  creatorToken: string
  updateCreatorToken: (token: string) => void
}

export const initialCreatorToken: CreatorToken = {
  creatorToken: '',
  updateCreatorToken: () => {},
}

export const initialCreatorProfile: CreatorProfile = {
  creator: {
    id: '',
    business_id: '',
    status: CreatorAccountStatus.Request,
    icon_url: '',
    name: '',
    email: '',
    profile: '',
    twitter_username: '',
    instagram_username: '',
    youtube_channel_url: '',
    kindle_author_url: '',
    created_at: '',
    updated_at: '',
    status_name: '',
    favorite_users_count: 0,
  },
  updateCreator: () => {},
}

export const CreatorTokenContext = createContext(initialCreatorToken)
export const CreatorProfileContext = createContext(initialCreatorProfile)

// Business
export type BusinessUserProfile = {
  businessId: BusinessUser['id']
  businessName: BusinessUser['name']
  businessEmail: BusinessUser['email']
  updateBusinessId: (businessId: BusinessUser['id']) => void
  updateBusinessName: (businessName: BusinessUser['name']) => void
  updateBusinessEmail: (businessEmail: BusinessUser['email']) => void
}

export type BusinessUserToken = {
  businessUserToken: string
  updateBusinessUserToken: (token: string) => void
}

export const initialBusinessUserToken: BusinessUserToken = {
  businessUserToken: '',
  updateBusinessUserToken: () => {},
}

export const initialBusinessUserProfile: BusinessUserProfile = {
  businessId: '',
  businessName: '',
  businessEmail: '',
  updateBusinessId: () => {},
  updateBusinessName: () => {},
  updateBusinessEmail: () => {},
}

export const BusinessUserTokenContext = createContext(initialBusinessUserToken)
export const BusinessUserProfileContext = createContext(initialBusinessUserProfile)
