import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  children?: string
}

const StyledErrorMessage = styled.p`
  font-size: 3.2vw;
  color: ${(props): string => props.theme.textColors.red};
`

export const ErrorMessage: React.FC<Props> = ({ className = '', children }) => {
  return <StyledErrorMessage className={className}>{children}</StyledErrorMessage>
}
