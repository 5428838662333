import React from 'react'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'
import { ProductStatus, ProductType } from '../../types/graphql'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  stock: number
  status: ProductStatus
  type: ProductType
}

const getBackgroundColor = (props: ThemedStyledProps<{ type: string }, DefaultTheme>): string => {
  switch (props.type) {
    case 'last':
      return `${props.theme.buttonColors.red}`
    case 'notSold':
      return `${props.theme.buttonColors.black}`
    case 'default':
      return `${props.theme.buttonColors.primary}`
    default:
      return `${props.theme.buttonColors.primary}`
  }
}

const StyledProductStockLabel = styled.div<{ type: string }>`
  width: 21.3vw;
  height: 6.9vw;
  border-radius: 4px;
  text-align: center;
  line-height: 2.17;
  font-size: 3.2vw;
  background: ${(props): string => getBackgroundColor(props)};
  color: ${(props): string => props.theme.textColors.white};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  ${mediaPc`
    width: 80px;
    height: 26px;
    font-size: 12px;
  `}
`

export const ProductStockLabel: React.FC<Props> = ({ className = '', stock, status, type }) => {
  const getLabel = (): string => {
    if (status === ProductStatus.End || stock === 0) return '販売終了'

    if (type === ProductType.LiveTalk) return `${stock}枚限定`

    if (stock >= 1) return `残り${stock}枚`

    return ''
  }

  const getType = (): string => {
    if (status === ProductStatus.End || stock === 0) return 'notSold'

    if (stock === 1) return 'last'

    if (stock >= 2) return 'default'

    return ''
  }

  return (
    <StyledProductStockLabel className={className} type={getType()}>
      {getLabel()}
    </StyledProductStockLabel>
  )
}
