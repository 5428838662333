import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessMypageEditTpl, BusinessTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'

import { FlashMessageType } from '../../../types/myTypes'
import { useUpdateBusinessUserAccountMutation, useCurrentBusinessUserLazyQuery } from '../../../types/graphql'
import { validateEmail } from '../../../lib/Validate'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { BusinessUserProfileContext } from '../../../context'

type Props = {}

export const BusinessMypageEdit: React.FC<Props> = () => {
  const businessUserProfile = useContext(BusinessUserProfileContext)
  const { updateBusinessName } = businessUserProfile
  const [businessName, setBusinessName] = useState<string>(businessUserProfile.businessName ?? '')
  const [businessEmail, setBusinessEmail] = useState<string>(businessUserProfile.businessEmail ?? '')
  const [nameError, setNameError] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()

  const [currentBusinessUserQuery] = useCurrentBusinessUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      updateBusinessName(data.CurrentBusinessUser?.name ?? '')
      history.push({ pathname: '/business/mypage', state: { type: 'success', message: '更新しました' } })
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [updateBusinessUserAccountMutation] = useUpdateBusinessUserAccountMutation({
    onCompleted: (data) => {
      if (data.UpdateBusinessUserAccount?.is_change_request_email === true) {
        history.push({ pathname: '/business/mypage/edit/complete', state: { email: businessEmail } })
      } else {
        currentBusinessUserQuery()
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const onChangeBusinessName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBusinessName(event.target.value)
    setNameError(null)
    if (!event.target.value) {
      setNameError('ビジネスアカウントを入力してください')
    }
  }

  const onChangeBusinessEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBusinessEmail(event.target.value)
    setEmailError(null)
    const emailError: string | null = validateEmail(event.target.value)
    if (emailError) {
      setEmailError(emailError)
    }
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  const onClickUpdateBusinessUserAccount = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await updateBusinessUserAccountMutation({
      variables: {
        input: {
          name: businessName,
          email: businessEmail,
        },
      },
    })
  }

  return (
    <>
      <Meta title="ビジネスアカウント情報編集" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout isVisibleBusinessUserEditButton={false}>
        <BusinessMypageEditTpl
          businessName={businessName}
          businessEmail={businessEmail}
          nameError={nameError}
          emailError={emailError}
          onChangeBusinessName={onChangeBusinessName}
          onChangeBusinessEmail={onChangeBusinessEmail}
          onClickUpdateBusinessUserAccount={onClickUpdateBusinessUserAccount}
        />
      </BusinessTwoColumnLayout>
    </>
  )
}
