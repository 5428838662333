import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SelectboxWithLabel, SalesTable } from '../../../molecules'
import { mediaPc } from '../../../../lib/MediaQuery'
import { SalesPerMonth } from '../../../../types/graphql'
import moment from 'moment'

type Props = {
  className?: string
  sales: SalesPerMonth[]
  years: string[]
  selectedYear: string
  onSelectYear: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  .year-select {
    margin-bottom: 6.4vw;
    select {
      color: ${(props): string => props.theme.textColors.black};
    }
  }

  ${mediaPc`
    .year-select {
      margin-bottom: 15px;
      width: 200px;
    }
  `}
`

export const CreatorSalesPerMonth: React.FC<Props> = ({ className = '', sales, years, selectedYear, onSelectYear }) => {
  const history = useHistory()

  const goToCreatorMonthlySales = (month: string): void => {
    history.push(`/creator/sales/${moment(month).format('YYYYMM')}`)
  }

  const yearOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  years.forEach((year) => {
    yearOptions.push({ value: year, label: moment(year).format('YYYY年') })
  })

  return (
    <Wrapper className={className}>
      <SelectboxWithLabel label="年選択" value={selectedYear} options={yearOptions} onChange={onSelectYear} className="year-select" />
      <SalesTable sales={sales} noDataText="月別の売上が一覧で表示されます" onClick={(month) => goToCreatorMonthlySales(month)} />
    </Wrapper>
  )
}
