import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button, PageTitle, PageSubtitle, Icon } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

import passwordIcon from '../../../assets/images/icon/icon_password.png'

type Props = {}

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props): string => props.theme.backgroundColors.gray};
  padding: 7.7vw 7.2vw 51vw;
  text-align: center;
  .title {
    display: flex;
    justify-content: center;
    .icon {
      width: 4.27vw;
      margin-right: 1.4vw;
    }
    .terms-title-text {
      margin-left: 17px;
      font-size: 24px;
      line-height: 1.33;
      text-align: center;
    }
  }
  .page-title {
    margin: 0 4.2vw 4.2vw 0;
  }
  .page-subtitle {
    font-size: 3.735vw;
    padding: 0 0 4.2vw;
    color: ${(props): string => props.theme.textColors.black};
  }
  .lead-text {
    margin-bottom: 5.1vw;
    color: ${(props): string => props.theme.textColors.black};
    text-align: center;
    font-weight: 400;
    font-size: 3.2vw;
  }
  .button {
    font-size: 3.733vw;
    font-weight: bold;
  }
  ${mediaPc`
  width: 100%;
    background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 72px 0px 190px;
    .inner {
      margin-top:50px;
      padding-top: 69px;
      background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
      .page-subtitle {
        font-size: 16px;
        padding: 0 0 10px;
      }
    }
    .title {
      .icon {
        width :21px;
        margin-right:0;
      }
    }
    .page-title {
      margin: 0 0 0 14px;
    }
    .lead-text {
      font-size: 14px;
      margin-bottom: 31px;
    }
    .button {
      width: 320px;
      height: 40px;
      line-height: 40px;
      margin: 0px auto 64px;
      padding: 0 15px;
      font-size: 14px;
      font-weight: bold;
    }
  `}
`

export const PasswordSendComplete: React.FC<Props> = () => {
  const history = useHistory()

  const goHome = (): void => {
    if (userType() === 'creator') {
      history.push('/creator')
    } else if (userType() === 'business') {
      history.push('/business')
    } else {
      history.push('/')
    }
  }
  return (
    <Wrapper>
      <div className="title">
        <div className="icon">
          <Icon src={passwordIcon} alt="パスワード再設定" />
        </div>
        <PageTitle className="page-title" title="パスワード再設定" />
      </div>
      <div className="inner">
        <PageSubtitle className="page-subtitle" subtitle="送信完了" />
        <p className="lead-text">
          メールに記載の再設定URLから
          <br className="sp" />
          パスワードの再設定を行ってください。
        </p>
        <Button className="button" onClick={(): void => goHome()} buttonType="primary">
          HOMEへ
        </Button>
      </div>
    </Wrapper>
  )
}
