import React, { useState, useRef, useEffect } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { ProductDetail, ProductListOnSale, RecommendCreators } from '../../organisms'
import { CreatorInfoSideColumn } from '../../molecules'

import { Creator, PublishedProduct, ProductType } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

import paperAirplane from '../../../assets/images/icon/paper_airplane.png'

type Props = {
  creator: Creator
  product: PublishedProduct
  otherProducts?: PublishedProduct[]
  recommendCreators: Creator[]
  isUserValid?: boolean
  onClickFavorite: (creatorId: string) => void
  onClickUnfavorite: (creatorId: string) => void
  onClickProductRequestButton: () => void
}

const Wrapper = styled.div`
  width: 100%;

  .content {
    width: 100vw;
    background: ${(props): string => props.theme.backgroundColors.gray};

    & > .inner {
      width: 100%;
    }

    .right-column {
      background: ${(props): string => props.theme.backgroundColors.bodyBg};
      width: 100%;
      .attention {
        margin: 0 auto;
        padding: 4vw 0 0 6.6vw;
        color: ${(props): string => props.theme.textColors.red};
        font-size: 2.933vw;
        font-weight: 700;
        line-height: 1;
      }

      .right-column-inner {
        .right-column-content-product {
          padding: 8.5vw 6.6vw;
          background: ${(props): string => props.theme.backgroundColors.bodyBg};
          .content-title {
            width: 100%;
            font-size: 3.735vw;
            line-height: 3.735vw;
            margin-bottom: 4vw;
            font-weight: bold;
            letter-spacing: 0.05em;
            color: ${(props): string => props.theme.textColors.black};
          }
        }
        .no-data {
          padding: 0 0 8.8vw;
          font-size: 3.735vw;
          text-align: center;
          font-weight: 500;
          color: ${(props): string => props.theme.textColors.gray};
          line-height: 1.14;
          letter-spacing: 0.05em;
        }
        .request-button-wrapper {
          height: 14.6vw;
          .request-button {
            display: block;
            width: 69vw;
            height: 14.6vw;
            padding: 0 0 0 12.6vw;
            margin: 0 auto;
            border-radius: 8vw;
            background: ${(props): string => props.theme.buttonColors.red};
            background-image: url(${paperAirplane});
            background-repeat: no-repeat;
            background-position: 5.3vw;
            background-size: 6.4vw;
            color: ${(props): string => props.theme.textColors.white};
            font-size: 4.8vw;
            font-weight: bold;
            text-align: left;
            z-index: 6;
            &.fixed {
              position: fixed;
              bottom: 5.3vw;
              left: 0;
              right: 0;
            }
          }
        }
        .recommend-creators {
          width: 100%;
          padding: 8.5vw 0 29.3vw 6.6vw;
          background: ${(props): string => props.theme.backgroundColors.bodyBg};
        }
      }
    }
  }

  ${mediaPc`
    .content {
      width: 100%;
      min-width: 1040px;
      & > .inner {
        width: 1040px;
        margin: 0 auto;
        padding: 64px 20px 50px;
        display: flex;
        justify-content: space-between;
        align-items: start;
      }

      .creator-info {
        margin-top: 23px;
      }
      .right-column {
        background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
        width: 720px;
        .attention {
          width: 720px;
          margin: 0 0 12px 0;
          padding: 0;
          font-size: 11px;
        }
        .right-column-inner {
          .right-column-content-product {
            padding: 25px 25px 0;
            .content-title {
               padding: 0;
               margin:0;
              font-size:14px;
            }
          }
          .recommend-creators {
            padding: 25px 0 50px 25px;
          }

          .no-data {
            padding: 20px 0 53px;
            font-size: 14px;
          }
        }
      }
    }
  `}
`

export const ProductDetailTpl: React.FC<Props> = ({
  creator,
  product,
  otherProducts,
  recommendCreators,
  isUserValid,
  onClickFavorite,
  onClickUnfavorite,
  onClickProductRequestButton,
}) => {
  const [isFixedRequestButton, setIsFixedRequestButton] = useState<boolean>(false)
  const productonSaleRef = useRef<HTMLDivElement>(null)
  const requestButtonRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollForRequestButton = (): void => {
    if (requestButtonRef?.current && productonSaleRef?.current) {
      const currentScrollY = window.scrollY
      const windowInnerHeight = window.innerHeight

      const productonSaleTop = productonSaleRef.current.getBoundingClientRect().top
      const requestButtonTop = requestButtonRef.current.getBoundingClientRect().top

      // cssで当てられてるVwに合わせてbottom位置を計算
      const bottomPosition = windowInnerHeight - window.innerWidth * 0.19
      const bottomPositionStart = windowInnerHeight - window.innerWidth * 0.5
      const fixedStartPosition = productonSaleTop - bottomPositionStart + currentScrollY
      const fixedEndPosition = requestButtonTop + currentScrollY - bottomPosition

      if (fixedEndPosition < currentScrollY || currentScrollY < fixedStartPosition) {
        setIsFixedRequestButton(false)
      }
      if (fixedEndPosition > currentScrollY && currentScrollY > fixedStartPosition) {
        setIsFixedRequestButton(true)
      }
    }
  }

  useEffect(() => {
    if (userType() !== 'user') return

    window.addEventListener('scroll', handleScrollForRequestButton)
    return () => window.removeEventListener('scroll', handleScrollForRequestButton)
  }, [handleScrollForRequestButton])

  return (
    <Wrapper>
      <div className="content">
        <div className="inner">
          <CreatorInfoSideColumn
            creator={creator}
            isUserValid={isUserValid}
            displayRequestButton={true}
            onClickFavorite={onClickFavorite}
            onClickUnfavorite={onClickUnfavorite}
            onClickProductRequestButton={onClickProductRequestButton}
            className="creator-info"
          />
          <div className="right-column">
            <p className="attention">※こちらの商品は、先着{product.product_type === ProductType.LiveTalk ? 1 : 4}名さま限定です</p>
            <div className="right-column-inner">
              <ProductDetail product={product} className="right-column-content-product" />
              <div className="right-column-content-product" ref={productonSaleRef}>
                <p className="content-title">販売中の商品</p>
                {otherProducts && otherProducts.length > 0 ? (
                  <ProductListOnSale className="product-list" products={otherProducts} oneLineItemsNum={4} />
                ) : (
                  <div className="no-data">他に販売中の商品はありません</div>
                )}
                {userType() === 'user' && (
                  <div className="request-button-wrapper sp" ref={requestButtonRef}>
                    <button
                      className={
                        isFixedRequestButton && otherProducts && otherProducts.length > 0 ? 'request-button fixed' : 'request-button'
                      }
                      onClick={onClickProductRequestButton}
                    >
                      販売リクエストを送る
                    </button>
                  </div>
                )}
              </div>
              <div className="recommend-creators">
                <RecommendCreators creators={recommendCreators} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
