import React from 'react'
import styled from 'styled-components'
import { ErrorMessage } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  errors: string[]
}

const StyledErrorMessageBox = styled.div`
  width: 100%;
  margin-bottom: 4.8vw;
  border-radius: 4px;
  border: 1px solid ${(props): string => props.theme.textColors.red};
  padding: 4vw;
  background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
  animation-duration: 0.3s;
  animation-name: viewOpacity;
  }
  @keyframes viewOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  p {
    color: ${(props): string => props.theme.textColors.red};
    font-size: 3.2vw;
    &::before {
      content: '・';
    }
  }
  ${mediaPc`
    margin-bottom: 24px;
    padding: 10px 17px;
    p {
      font-size: 12px;
      &::before {
        content: '・';
      }
    }
  `}
`

export const ErrorMessagesBox: React.FC<Props> = ({ className, errors }) => {
  return (
    <StyledErrorMessageBox className={className}>
      {errors.map((error: string, index: number) => (
        <ErrorMessage key={index}>{error}</ErrorMessage>
      ))}
    </StyledErrorMessageBox>
  )
}
