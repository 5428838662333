import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessTwoColumnLayout, CreatorProductDetailTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { Product, useBusinessProductLazyQuery, usePagedBusinessPublishedProductsLazyQuery } from '../../../types/graphql'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { FlashMessageType } from '../../../types/myTypes'

type Props = {} & RouteComponentProps<{ id: string }>

export const BusinessCreatorProductDetail: React.FC<Props> = (props) => {
  const [product, setProduct] = useState<Product>()
  const [otherProducts, setOtherProducts] = useState<Product[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const [pagedBusinessPublishedProductsLazyQuery] = usePagedBusinessPublishedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setOtherProducts(data.PagedBusinessPublishedProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [businessProductLazyQuery] = useBusinessProductLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setProduct(data.BusinessProduct)
        if (data.BusinessProduct) {
          pagedBusinessPublishedProductsLazyQuery({
            variables: {
              creator_id: data.BusinessProduct.creator_id,
              exclude_product_id: props.match.params.id,
              first: 8,
              page: 1,
            },
          })
        }
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    businessProductLazyQuery({
      variables: {
        product_id: props.match.params.id,
      },
    })
  }, [businessProductLazyQuery, props.match.params.id])
  return (
    <>
      <Meta title={product?.creator ? `${product.creator.name}さん 商品詳細` : ''} />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {product && (
        <>
          <BusinessTwoColumnLayout currentTab="creator">
            <CreatorProductDetailTpl product={product} otherProducts={otherProducts} />
          </BusinessTwoColumnLayout>
        </>
      )}
    </>
  )
}
