import React from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms'

import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  title?: string
  children?: React.ReactNode
  displayCancel?: boolean
  onClickCancel?: () => void
  onClickOk?: () => void
}

const StyledDialog = styled.div`
  width: 90vw;
  max-width: 550px;
  max-height: 90vh;
  background: ${(props): string => props.theme.backgroundColors.dialogBlack};
  border-radius: 4px;

  .dialog-content {
    padding: 25px;
    border-bottom: 1px solid ${(props): string => props.theme.borderColors.dialogBlack};

    .title {
      margin-bottom: 4.3vw;
      color: ${(props): string => props.theme.textColors.white};
      font-size: 4.5vw;
    }

    .content {
      color: ${(props): string => props.theme.textColors.gray};
      font-size: 4.2vw;
      line-height: 1.5;
    }
  }

  .dialog-button {
    height: 14vw;
    padding: 0 3vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 24vw;
      font-size: 3.733vw;
      line-height: 2.5;

      & + button {
        margin-left: 36vw;
      }
    }
  }

  ${mediaPc`
    width: 400px;
    
    .dialog-content {
      .title {
        margin-bottom: 16px;
        font-size: 20px;
      }

      .content {
        font-size: 14px;
      }
    }

    .dialog-button {
      height: 52px;
      padding: 0 8px;

      button {
        width: 94px;
        height: 35px;
        font-size: 14px;
        line-height: 35px;

        & + button {
          margin-left: 196px;
        }
      }
    }
  `}
`

export const Dialog: React.FC<Props> = ({ className = '', title = '', children, displayCancel = true, onClickCancel, onClickOk }) => {
  return (
    <StyledDialog className={className}>
      <div className="dialog-content">
        <p className="title">{title}</p>
        <p className="content">{children}</p>
      </div>
      <div className="dialog-button">
        {displayCancel && (
          <Button buttonType="gray" onClick={onClickCancel}>
            キャンセル
          </Button>
        )}
        <Button buttonType="blue" onClick={onClickOk}>
          OK
        </Button>
      </div>
    </StyledDialog>
  )
}
