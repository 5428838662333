import React from 'react'
import styled, { DefaultTheme, ThemeProps } from 'styled-components'
import { PageSubtitle, Button } from '../../../atoms'
import { CsvExportSelectbox } from '../../../organisms'
import { Affiliation, Creator } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import { CsvExportBy } from '../../../../types/myTypes'

type Props = {
  affiliations: Affiliation[]
  months: string[]
  creators: Creator[]
  selectedAffiliationForExportByCreator: string
  selectedAffiliationForExportByProduct: string
  selectedMonthForExportByCreator: string
  selectedMonthForExportByProduct: string
  selectedCreatorForExportByProduct: string
  onSelectAffiliation: (e: React.ChangeEvent<HTMLSelectElement>, exportBy: CsvExportBy) => void
  onSelectMonth: (e: React.ChangeEvent<HTMLSelectElement>, exportBy: CsvExportBy) => void
  onSelectCreator: (e: React.ChangeEvent<HTMLSelectElement>) => void
  exportCsv: (exportBy: CsvExportBy) => void
}

const Wrapper = styled.div`
  .title {
    margin-bottom: 5vw;
  }

  .inner {
    .export-title {
      margin-bottom: 3vw;
      font-size: 2.667vw;
      color: ${(props): string => props.theme.textColors.black};
      letter-spacing: 0.05em;
    }

    .selectboxes {
      margin-bottom: 10vw;
    }

    .export-button {
      margin-bottom: 8vw;
    }
  }

  ${mediaPc`
    .title {
      font-size: 16px;
      margin-bottom: 43px;
    }

    .inner {
      margin-bottom: 40px;
      padding: 0 25px 0 5px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .export-title {
        margin-bottom: 32px;
        font-size: 14px;
        text-align: center;
      }

      .selectboxes {
        margin-bottom: 30px;

        &.without-creator {
          margin-bottom: 125px;
        }
      }

      .export-button {
        width: 240px;
        margin: 0 auto;
        display: block;
      }

      &::after {
        content: '';
        width: 0;
        height: 100%;
        border-right: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.darkBlack};
        position: absolute;
        top: 0;
        left: 50%;
      }
    }
  `}
`

export const CsvExportTpl: React.FC<Props> = ({
  affiliations,
  months,
  creators,
  selectedAffiliationForExportByCreator,
  selectedAffiliationForExportByProduct,
  selectedMonthForExportByCreator,
  selectedMonthForExportByProduct,
  selectedCreatorForExportByProduct,
  onSelectAffiliation,
  onSelectMonth,
  onSelectCreator,
  exportCsv,
}) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle="CSV書き出し" />
      <div className="inner">
        <div>
          <p className="export-title">クリエイター単位のCSV書き出しはこちらから</p>
          <CsvExportSelectbox
            affiliations={affiliations}
            months={months}
            creators={creators}
            selectedAffiliation={selectedAffiliationForExportByCreator}
            selectedMonth={selectedMonthForExportByCreator}
            displayCreatorSelectbox={false}
            onSelectAffiliation={(e) => {
              onSelectAffiliation(e, 'creator')
            }}
            onSelectMonth={(e) => {
              onSelectMonth(e, 'creator')
            }}
            className="selectboxes without-creator"
          />
          <Button
            buttonType="primary"
            onClick={() => {
              exportCsv('creator')
            }}
            className="export-button"
          >
            選択した条件でCSVを書き出し
          </Button>
        </div>
        <div>
          <p className="export-title">販売商品単位のCSV書き出しはこちらから</p>
          <CsvExportSelectbox
            affiliations={affiliations}
            months={months}
            creators={creators}
            selectedAffiliation={selectedAffiliationForExportByProduct}
            selectedMonth={selectedMonthForExportByProduct}
            selectedCreator={selectedCreatorForExportByProduct}
            onSelectAffiliation={(e) => {
              onSelectAffiliation(e, 'product')
            }}
            onSelectMonth={(e) => {
              onSelectMonth(e, 'product')
            }}
            onSelectCreator={onSelectCreator}
            className="selectboxes"
          />
          <Button
            buttonType="primary"
            onClick={() => {
              exportCsv('product')
            }}
            className="export-button"
          >
            選択した条件でCSVを書き出し
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}
