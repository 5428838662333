import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  children?: React.ReactNode
  onClick?: () => void
}

const Button = styled.button`
  width: 100%;
  height: auto;
  padding-left: 4.5vw;
  padding-right: calc(6.9vw + 11.4px);
  color: ${(props): string => props.theme.textColors.black};
  font-weight: bold;
  line-height: 3.9;
  font-size: 3.2vw;
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: space-between;

  &::after {
    width: 2.6vw;
    height: 2.6vw;
    content: '';
    position: absolute;
    top: calc(50% - 1.3vw);
    left: 92.5%;
    border: ${(props): string => props.theme.borderColors.primary} solid;
    border-width: 0 2px 2px 0;
    transform: rotate(-45deg);
  }

  ${mediaPc`
  width: 100%;
  padding: 0 calc(2% + 8.5px) 0 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 26px;
  border-bottom: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.gray};
  border-radius: 0;
  transition: 0.3s all;

  &::after {
    width: 6px;
    height: 6px;
    top: 8px;
    left: 98%;
  }
  `}
`

export const LinkButton: React.FC<Props> = ({ className = '', children, onClick }) => {
  return (
    <Button className={className} onClick={onClick}>
      {children}
    </Button>
  )
}
