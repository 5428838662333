import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sO`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
  /** 年月: YYYYMM */
  Year: any;
  /** 年月: YYYYMM */
  YearMonth: any;
};

export type ActiveCreatorsInput = {
  keyword?: Maybe<Scalars['String']>;
};

/** 所属 */
export type Affiliation = {
  __typename?: 'Affiliation';
  /** 所属ID */
  id: Scalars['ID'];
  /** 所属の名前 */
  name: Scalars['String'];
  /** 所属に登録されているクリエイター数(LEAVE以外) */
  creators_count?: Maybe<Scalars['Int']>;
  /** 所属に登録されているクリエイター数(ACTIVEのみ) */
  active_creators_count?: Maybe<Scalars['Int']>;
  /** 登録日 */
  created_at: Scalars['DateTime'];
  /** 更新日 */
  updated_at: Scalars['DateTime'];
};

/** A paginated list of Affiliation items. */
export type AffiliationPaginator = {
  __typename?: 'AffiliationPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Affiliation items. */
  data: Array<Affiliation>;
};

/** 所属毎の月別売上 */
export type AffiliationSaleThisMonth = {
  __typename?: 'AffiliationSaleThisMonth';
  affiliation?: Maybe<Affiliation>;
  sale_this_month?: Maybe<SalesPerMonth>;
};

/** 所属毎の月別売上 */
export type AffiliationSalesPerMonth = {
  __typename?: 'AffiliationSalesPerMonth';
  affiliation?: Maybe<Affiliation>;
  sales_per_month: Array<SalesPerMonth>;
  total_sales?: Maybe<Scalars['Int']>;
};

/** 注文(ユーザ側には参照させない) SQL集計結果用 */
export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  /** 注文商品ID */
  id: Scalars['ID'];
  /** 納品日 */
  delivered_at?: Maybe<Scalars['DateTime']>;
  /** 支払日 */
  paid_at: Scalars['DateTime'];
  /** 購入ユーザー名 */
  user_name: Scalars['String'];
  /** 商品名 */
  product_name: Scalars['String'];
  /** 商品の種類 */
  product_type: ProductType;
  /** 動画の長さ */
  movie_seconds?: Maybe<Scalars['Int']>;
  /** 注文ID */
  order_id: Scalars['ID'];
  /** 注文商品ステータス */
  status: OrderStatus;
  /** 注文したユーザー */
  user: User;
  /** 注文した商品 */
  product: Product;
};

export type BusinessResendCreatorAccountUpdateInput = {
  email: Scalars['String'];
};

/** ビジネス毎の月別売上 */
export type BusinessSalesPerMonth = {
  __typename?: 'BusinessSalesPerMonth';
  sales_per_month: Array<SalesPerMonth>;
  total_sales?: Maybe<Scalars['Int']>;
};

/** ビジネスユーザー */
export type BusinessUser = {
  __typename?: 'BusinessUser';
  /** ビジネスユーザーID */
  id: Scalars['ID'];
  /** ビジネスユーザーの名前 */
  name: Scalars['String'];
  /** ビジネスユーザーのメールアドレス */
  email: Scalars['String'];
  /** 登録日 */
  created_at: Scalars['DateTime'];
  /** 更新日 */
  updated_at: Scalars['DateTime'];
};

export enum BusinessUserAccountStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Suspend */
  Suspend = 'SUSPEND',
  /** Leave */
  Leave = 'LEAVE'
}

/** クリエイター */
export type Creator = {
  __typename?: 'Creator';
  /** クリエイターID */
  id: Scalars['ID'];
  /** ビジネスID */
  business_id: Scalars['ID'];
  /** 所属ID */
  affiliation_id?: Maybe<Scalars['ID']>;
  /** クリエイターのステータス */
  status: CreatorAccountStatus;
  /** クリエイターのアイコンURL */
  icon_url: Scalars['String'];
  /** クリエイターの名前 */
  name: Scalars['String'];
  /** クリエイターのメールアドレス */
  email: Scalars['String'];
  /** クリエイターのプロフィール */
  profile: Scalars['String'];
  /** クリエイターのTwitterユーザー名 */
  twitter_username: Scalars['String'];
  /** クリエイターのInstagramユーザー名 */
  instagram_username: Scalars['String'];
  /** クリエイターのYouTubeチャンネルURL */
  youtube_channel_url: Scalars['String'];
  /** クリエイターのKindle著者URL */
  kindle_author_url: Scalars['String'];
  /** クリエイターの退会日 */
  left_at?: Maybe<Scalars['DateTime']>;
  /** 登録日 */
  created_at: Scalars['DateTime'];
  /** 更新日 */
  updated_at: Scalars['DateTime'];
  /** クリエイターのステータス名 */
  status_name: Scalars['String'];
  /** クリエイターをお気に入り済みか */
  is_favorite?: Maybe<Scalars['Boolean']>;
  /** 所属 */
  affiliation?: Maybe<Affiliation>;
  /** お気に入り人数 */
  favorite_users_count: Scalars['Int'];
};

export enum CreatorAccountStatus {
  /** 登録依頼中 */
  Request = 'REQUEST',
  /** 有効 */
  Active = 'ACTIVE',
  /** 停止 */
  Suspend = 'SUSPEND',
  /** 退会 */
  Leave = 'LEAVE'
}

export enum CreatorCategory {
  /** メイド */
  Maid = 'MAID',
  /** アイドル */
  Idol = 'IDOL',
  /** グラビア */
  PinupModel = 'PINUP_MODEL',
  /** モデル */
  Model = 'MODEL',
  /** 動画配信（ライバー、ユーチューバー） */
  Streamer = 'STREAMER',
  /** 女優 */
  Actress = 'ACTRESS'
}

/** クリエイターマイページ */
export type CreatorMypage = {
  __typename?: 'CreatorMypage';
  /** クリエイター情報 */
  creator: Creator;
  /** 今月の売上 */
  sales?: Maybe<SalesPerMonth>;
  /** 公開中の商品 */
  published_products?: Maybe<Array<Maybe<Product>>>;
  /** 公開日時指定商品 */
  reserved_products?: Maybe<Array<Maybe<Product>>>;
  /** 非表示の商品 */
  disabled_products?: Maybe<Array<Maybe<Product>>>;
};

/** A paginated list of Creator items. */
export type CreatorPaginator = {
  __typename?: 'CreatorPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Creator items. */
  data: Array<Creator>;
};

/** ユーザー購入ランキング */
export type CreatorPurchaseUserRanking = {
  __typename?: 'CreatorPurchaseUserRanking';
  /** ユーザーID */
  user_id: Scalars['Int'];
  /** ユーザーの名前 */
  user_name: Scalars['String'];
  /** ユーザーのTwitterユーザーネーム */
  twitter_username?: Maybe<Scalars['String']>;
  /** 購入金額 */
  amount: Scalars['Int'];
  /** 購入枚数 */
  purchase_count: Scalars['Int'];
};

/** クリエイターランキング */
export type CreatorRanking = {
  __typename?: 'CreatorRanking';
  /** ランキング */
  rank: Scalars['Int'];
  /** クリエイター */
  creator: Creator;
};

export type CreatorRankingInput = {
  limit: Scalars['Int'];
};

export enum CreatorReminderDeliveryType {
  /** L i m i t 1 d a y  a g o */
  Limit_1DayAgo = 'LIMIT_1DAY_AGO',
  /** L i m i t 1 h o u r  a g o */
  Limit_1HourAgo = 'LIMIT_1HOUR_AGO',
  /** Limit after */
  LimitAfter = 'LIMIT_AFTER'
}

/** クリエイター毎の月別売上 */
export type CreatorSalesPerMonth = {
  __typename?: 'CreatorSalesPerMonth';
  sales_per_month: Array<SalesPerMonth>;
  total_sales?: Maybe<Scalars['Int']>;
};

export type CreatorSalesPerMonthInYearInput = {
  /** YYYY 未指定の場合は直近12ヶ月のデータを取得 */
  year?: Maybe<Scalars['Year']>;
};

export type CurrentCreatorUpdateAccountInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  profile?: Maybe<Scalars['String']>;
  twitter_username?: Maybe<Scalars['String']>;
  instagram_username?: Maybe<Scalars['String']>;
  youtube_channel_url?: Maybe<Scalars['String']>;
  kindle_author_url?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
};




/** 納品済み商品 */
export type DeliveredProduct = {
  __typename?: 'DeliveredProduct';
  /** 納品済み商品ID */
  id?: Maybe<Scalars['ID']>;
  /** 注文済み商品ID */
  ordered_product_id?: Maybe<Scalars['ID']>;
  /** 作成済み商品の保存パス */
  delivered_product_url?: Maybe<Scalars['String']>;
  /** 作成済み商品のサムネイルのパス */
  delivered_product_thumbnail_url?: Maybe<Scalars['String']>;
  /** ダウンロードのパス */
  temporary_download_url?: Maybe<Scalars['String']>;
  /** 納品時刻 */
  delivered_at?: Maybe<Scalars['DateTime']>;
  /** 登録日 */
  created_at?: Maybe<Scalars['DateTime']>;
  /** 更新日 */
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type DeliveryMovieProductInput = {
  ordered_product_id: Scalars['ID'];
  delivery_movie_path: Scalars['String'];
};

export type DeliveryPhotoProductInput = {
  ordered_product_id: Scalars['ID'];
  delivery_photo_file: Scalars['Upload'];
};

export type EditAffiliationInput = {
  affiliation_name: Scalars['String'];
};

export enum ExceptionCategory {
  /** System */
  System = 'SYSTEM',
  /** User */
  User = 'USER'
}

export type GenerateTemporaryUrlForFilteredMonthlySalesByCreatorInput = {
  /** 全所属対象。has_all_affiliationが有効な場合、affiliation_idとcreator_idの指定は無視されます */
  has_all_affiliation: Scalars['Boolean'];
  affiliation_id?: Maybe<Scalars['ID']>;
  year_month?: Maybe<Scalars['YearMonth']>;
};

export type GenerateTemporaryUrlForFilteredMonthlySalesInput = {
  /** 全所属対象。has_all_affiliationが有効な場合、affiliation_idとcreator_idの指定は無視されます */
  has_all_affiliation: Scalars['Boolean'];
  affiliation_id?: Maybe<Scalars['ID']>;
  creator_id?: Maybe<Scalars['ID']>;
  year_month?: Maybe<Scalars['YearMonth']>;
};

export type GenerateTemporaryUrlForMonthlySalesByCreatorInput = {
  year_month?: Maybe<Scalars['YearMonth']>;
};

export type GenerateTemporaryUrlForMonthlySalesInput = {
  year_month: Scalars['YearMonth'];
};

export enum KawaiiCollectionModel {
  /** Tokyo */
  Tokyo = 'TOKYO',
  /** Kansai */
  Kansai = 'KANSAI',
  /** Kyushu */
  Kyushu = 'KYUSHU',
  /** Aichi */
  Aichi = 'AICHI',
  /** Sapporo */
  Sapporo = 'SAPPORO'
}

/** ログイン情報 */
export type LoginData = {
  __typename?: 'LoginData';
  token?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  ttl?: Maybe<Scalars['Int']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum MediaConvertJobStatus {
  /** Init */
  Init = 'INIT',
  /** Submitted */
  Submitted = 'SUBMITTED',
  /** Progressing */
  Progressing = 'PROGRESSING',
  /** Complete */
  Complete = 'COMPLETE',
  /** Canceled */
  Canceled = 'CANCELED',
  /** Error */
  Error = 'ERROR'
}

/** 動画アップロードに必要な情報 */
export type MovieProductUpload = {
  __typename?: 'MovieProductUpload';
  /** 動画アップロードするURL */
  upload_url?: Maybe<Scalars['String']>;
  /** 動画アップロードするパス */
  upload_file_path?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** [ビジネス]クリエイターのメール変更を再送するミューテーション */
  BusinessResendCreatorAccountUpdate?: Maybe<Scalars['Boolean']>;
  /** [クリエイター]ライブトークのルームに参加した時のミューテーション */
  CreatorDeliveryLiveTalkProduct: Scalars['Boolean'];
  /** [クリエイター]動画の商品を納品をするミューテーション */
  CreatorDeliveryMovieProduct: Scalars['Boolean'];
  /** [クリエイター]クリエイターが商品を納品をするミューテーション */
  CreatorDeliveryPhotoProduct: Scalars['Boolean'];
  /** [クリエイター]商品を非表示にするミューテーション */
  CreatorDisableProduct?: Maybe<Scalars['Boolean']>;
  /** [クリエイター]商品を表示にするミューテーション */
  CreatorDisplayProduct?: Maybe<Scalars['Boolean']>;
  /** [クリエイター]公開中の商品を販売終了にするミューテーション */
  CreatorFinishProduct: Scalars['Boolean'];
  /** [ゲスト]クリエイターのパスワードをリセットするミューテーション */
  CreatorPasswordReset: Scalars['Boolean'];
  /** [クリエイター]ライブトークの商品登録をするミューテーション */
  CreatorRegisterLiveTalkProduct: Scalars['Boolean'];
  /** [クリエイター]動画の商品登録をするミューテーション */
  CreatorRegisterMovieProduct: Scalars['Boolean'];
  /** [クリエイター]写真の商品登録をするミューテーション */
  CreatorRegisterPhotoProduct: Scalars['Boolean'];
  /** [クリエイター]クリエイターのパスワードを更新するミューテーション */
  CreatorUpdatePassword?: Maybe<Scalars['Boolean']>;
  /** [クリエイター]現在ログインしているクリエイターを更新する */
  CurrentCreatorUpdateAccount?: Maybe<UpdatedCreator>;
  /** [ビジネス]仮登録中のクリエイターのアカウント削除するミューテーション */
  DeleteCreatorAccount?: Maybe<Scalars['Boolean']>;
  DeliveryLiveTalkProductByBusinessUser: Scalars['Boolean'];
  DeliveryMovieProductByBusinessUser: Scalars['Boolean'];
  DeliveryPhotoProductByBusinessUser: Scalars['Boolean'];
  /** [ビジネス]商品を非表示にするミューテーション */
  DisableProduct?: Maybe<Scalars['Boolean']>;
  /** [ビジネス]商品を表示にするミューテーション */
  DisplayProduct?: Maybe<Scalars['Boolean']>;
  /** [ビジネス]所属を更新するミューテーション */
  EditAffiliation?: Maybe<Affiliation>;
  /** [ユーザー]クリエイターお気に入り登録するミューテーション */
  FavoriteCreator?: Maybe<Scalars['Boolean']>;
  /** [ビジネス]商品を販売終了にするミューテーション */
  FinishProduct?: Maybe<Scalars['Boolean']>;
  /** [ゲスト]ビジネスユーザーとしてログインするミューテーション */
  LoginBusinessUser?: Maybe<LoginData>;
  /** [ゲスト]クリエイターとしてログインするミューテーション */
  LoginCreator?: Maybe<LoginData>;
  /** [ゲスト]一般ユーザーとしてログインするミューテーション */
  LoginUser?: Maybe<LoginData>;
  /** [ビジネス]ログアウトするミューテーション */
  LogoutBusinessUser?: Maybe<Scalars['Boolean']>;
  /** [クリエイター]ログアウトするミューテーション */
  LogoutCreator?: Maybe<Scalars['Boolean']>;
  /** [ユーザー]ログアウトするミューテーション */
  LogoutUser?: Maybe<Scalars['Boolean']>;
  /** [ゲスト]ビジネスユーザーのパスワードをリセットするミューテーション */
  PasswordResetBusinessUser?: Maybe<Scalars['Boolean']>;
  /** [ビジネス]新規所属登録ミューテーション */
  RegisterAffiliation?: Maybe<Affiliation>;
  RegisterLiveTalkProductByBusinessUser: Scalars['Boolean'];
  RegisterMovieProductByBusinessUser: Scalars['Boolean'];
  /** [ビジネス]新規クリエイター登録ミューテーション */
  RegisterNewCreator?: Maybe<Creator>;
  RegisterPhotoProductByBusinessUser: Scalars['Boolean'];
  /** [ビジネス]アカウント登録メールをクリエイターへ再送するミューテーション */
  ResendCreatorAccountRegistration?: Maybe<Scalars['Boolean']>;
  /** [ビジネス]メールアドレス更新時の認証メールを再送するミューテーション */
  ResendEmailForVerifyingUpdatedBusinessUserEmail?: Maybe<Scalars['Boolean']>;
  /** [ユーザー]アカウント登録メールをユーザーへ再送するミューテーション */
  ResendUserAccountRegistration?: Maybe<Scalars['Boolean']>;
  /** [ユーザー]クリエイターお気に入り登録を解除するミューテーション */
  UnfavoriteCreator?: Maybe<Scalars['Boolean']>;
  /** [ビジネス]アカウント情報を更新するミューテーション */
  UpdateBusinessUserAccount: UpdateAccount;
  /** [ゲスト]ビジネスユーザーのメールアドレス変更時の認証ミューテーション */
  UpdateBusinessUserEmailByToken: BusinessUser;
  /** [ビジネス]クリエイターのアカウント情報編集ミューテーション */
  UpdateCreatorAccount?: Maybe<UpdatedCreator>;
  /** [ゲスト]クリエイターのアカウント情報登録ミューテーション */
  UpdateCreatorAccountByToken: Creator;
  /** [ゲスト]クリエイターのメールアドレス変更時の認証ミューテーション */
  UpdateCreatorEmailByToken: Creator;
  /** [ゲスト]ビジネスユーザーのパスワードを更新するミューテーション */
  UpdatePasswordBusinessUser?: Maybe<Scalars['Boolean']>;
  /** [ユーザー]アカウント情報編集ミューテーション */
  UpdateUserAccount: UpdateAccount;
  /** [ゲスト]ユーザーのメールアドレス変更時の認証ミューテーション */
  UpdateUserEmailByToken: User;
  /** [ゲスト]ユーザーのパスワードをリセットするミューテーション */
  UserPasswordReset: Scalars['Boolean'];
  /** [ユーザー]商品を購入するミューテーション */
  UserPurchaseProduct: PurchaseProduct;
  /** [ゲスト]新規ユーザー登録ミューテーション */
  UserRegisterAccount?: Maybe<User>;
  /** [ユーザー]ライブトークをリクエストするミューテーション */
  UserRequestLiveTalk: Scalars['Boolean'];
  /** [ユーザー]動画をリクエストするミューテーション */
  UserRequestMovie: Scalars['Boolean'];
  /** [ユーザー]写真をリクエストするミューテーション */
  UserRequestPhoto: Scalars['Boolean'];
  /** [ユーザー]ユーザーのパスワードを更新するミューテーション */
  UserUpdatePassword?: Maybe<Scalars['Boolean']>;
  /** [ゲスト]ユーザーの初回登録時の認証ミューテーション */
  UserVerifyAccount?: Maybe<LoginData>;
};


export type MutationBusinessResendCreatorAccountUpdateArgs = {
  creator_id: Scalars['ID'];
  input?: Maybe<BusinessResendCreatorAccountUpdateInput>;
};


export type MutationCreatorDeliveryLiveTalkProductArgs = {
  ordered_product_id: Scalars['ID'];
};


export type MutationCreatorDeliveryMovieProductArgs = {
  input: DeliveryMovieProductInput;
};


export type MutationCreatorDeliveryPhotoProductArgs = {
  input: DeliveryPhotoProductInput;
};


export type MutationCreatorDisableProductArgs = {
  product_id: Scalars['ID'];
};


export type MutationCreatorDisplayProductArgs = {
  product_id: Scalars['ID'];
};


export type MutationCreatorFinishProductArgs = {
  product_id: Scalars['ID'];
};


export type MutationCreatorPasswordResetArgs = {
  input: PasswordResetInput;
};


export type MutationCreatorRegisterLiveTalkProductArgs = {
  input: RegisterLiveTalkProductInput;
};


export type MutationCreatorRegisterMovieProductArgs = {
  input: RegisterMovieProductInput;
};


export type MutationCreatorRegisterPhotoProductArgs = {
  input: RegisterPhotoProductInput;
};


export type MutationCreatorUpdatePasswordArgs = {
  creator_id: Scalars['ID'];
  input: UpdatePasswordInput;
};


export type MutationCurrentCreatorUpdateAccountArgs = {
  creator_id: Scalars['ID'];
  input: CurrentCreatorUpdateAccountInput;
};


export type MutationDeleteCreatorAccountArgs = {
  creator_id: Scalars['ID'];
};


export type MutationDeliveryLiveTalkProductByBusinessUserArgs = {
  ordered_product_id: Scalars['ID'];
};


export type MutationDeliveryMovieProductByBusinessUserArgs = {
  input: DeliveryMovieProductInput;
};


export type MutationDeliveryPhotoProductByBusinessUserArgs = {
  input: DeliveryPhotoProductInput;
};


export type MutationDisableProductArgs = {
  product_id: Scalars['ID'];
};


export type MutationDisplayProductArgs = {
  product_id: Scalars['ID'];
};


export type MutationEditAffiliationArgs = {
  affiliation_id: Scalars['ID'];
  input: EditAffiliationInput;
};


export type MutationFavoriteCreatorArgs = {
  creator_id: Scalars['ID'];
};


export type MutationFinishProductArgs = {
  product_id: Scalars['ID'];
};


export type MutationLoginBusinessUserArgs = {
  input: LoginInput;
};


export type MutationLoginCreatorArgs = {
  input: LoginInput;
};


export type MutationLoginUserArgs = {
  input: LoginInput;
};


export type MutationPasswordResetBusinessUserArgs = {
  input: PasswordResetInput;
};


export type MutationRegisterAffiliationArgs = {
  input: RegisterAffiliationInput;
};


export type MutationRegisterLiveTalkProductByBusinessUserArgs = {
  creator_id: Scalars['ID'];
  input: RegisterLiveTalkProductInput;
};


export type MutationRegisterMovieProductByBusinessUserArgs = {
  creator_id: Scalars['ID'];
  input: RegisterMovieProductInput;
};


export type MutationRegisterNewCreatorArgs = {
  input: RegisterNewCreatorInput;
};


export type MutationRegisterPhotoProductByBusinessUserArgs = {
  creator_id: Scalars['ID'];
  input: RegisterPhotoProductInput;
};


export type MutationResendCreatorAccountRegistrationArgs = {
  creator_id: Scalars['ID'];
};


export type MutationResendEmailForVerifyingUpdatedBusinessUserEmailArgs = {
  input: ResendEmailForVerifyingUpdatedBusinessUserEmailInput;
};


export type MutationResendUserAccountRegistrationArgs = {
  user_id: Scalars['ID'];
  input: ResendUserAccountRegistrationInput;
};


export type MutationUnfavoriteCreatorArgs = {
  creator_id: Scalars['ID'];
};


export type MutationUpdateBusinessUserAccountArgs = {
  input: UpdateBusinessUserAccountInput;
};


export type MutationUpdateBusinessUserEmailByTokenArgs = {
  input: UpdateEmailByTokenInput;
};


export type MutationUpdateCreatorAccountArgs = {
  creator_id: Scalars['ID'];
  input: UpdateCreatorAccountInput;
};


export type MutationUpdateCreatorAccountByTokenArgs = {
  input: UpdateCreatorAccountByTokenInput;
};


export type MutationUpdateCreatorEmailByTokenArgs = {
  input: UpdateEmailByTokenInput;
};


export type MutationUpdatePasswordBusinessUserArgs = {
  business_user_id: Scalars['ID'];
  input: UpdatePasswordInput;
};


export type MutationUpdateUserAccountArgs = {
  input: UpdateUserAccountInput;
};


export type MutationUpdateUserEmailByTokenArgs = {
  input: UpdateEmailByTokenInput;
};


export type MutationUserPasswordResetArgs = {
  input: PasswordResetInput;
};


export type MutationUserPurchaseProductArgs = {
  product_id: Scalars['ID'];
};


export type MutationUserRegisterAccountArgs = {
  input: UserRegisterAccountInput;
};


export type MutationUserRequestLiveTalkArgs = {
  input: RequestLiveTalkInput;
};


export type MutationUserRequestMovieArgs = {
  input: RequestMovieInput;
};


export type MutationUserRequestPhotoArgs = {
  input: RequestPhotoInput;
};


export type MutationUserUpdatePasswordArgs = {
  user_id: Scalars['ID'];
  input: UpdatePasswordInput;
};


export type MutationUserVerifyAccountArgs = {
  user_id: Scalars['ID'];
  input: UserVerifyAccountInput;
};

/** 注文(ユーザ側には参照させない) Eloquent用 */
export type Order = {
  __typename?: 'Order';
  /** 注文ID */
  id: Scalars['ID'];
  /** 納品日 */
  delivered_at?: Maybe<Scalars['DateTime']>;
  /** 支払日 */
  paid_at: Scalars['DateTime'];
  /** 動画の長さ */
  movie_seconds?: Maybe<Scalars['Int']>;
  /** 注文商品ステータス */
  status: Scalars['String'];
  /** 注文したユーザー */
  user: User;
  /** 注文した商品 */
  product: Product;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export enum OrderStatus {
  /** Init */
  Init = 'INIT',
  /** Paid */
  Paid = 'PAID',
  /** Invalid */
  Invalid = 'INVALID',
  /** Canceled */
  Canceled = 'CANCELED'
}

/** 注文済み商品 */
export type OrderedProduct = {
  __typename?: 'OrderedProduct';
  /** 注文済み商品ID */
  id: Scalars['ID'];
  /** 注文済み商品のステータス */
  status: OrderedProductStatus;
  /** 注文済みライブトーク商品ステータス */
  live_talk_status?: Maybe<OrderedProductLiveTalkStatus>;
  /** 注文済み商品のクリエイターID */
  creator_id: Scalars['ID'];
  /** 注文済み商品の注文ID */
  order_id: Scalars['ID'];
  /** 注文済み商品の商品ID */
  product_id: Scalars['ID'];
  /** 納品催促メール1日前をお送信済みか */
  is_sent_reminder_delivery_1day_ago_mail?: Maybe<Scalars['Int']>;
  /** 納品催促メール1時間前を送信済みか */
  is_sent_reminder_delivery_1hour_ago_mail?: Maybe<Scalars['Int']>;
  /** 納品催促メール納期期限切れを送信済みか */
  is_sent_reminder_delivery_after_mail?: Maybe<Scalars['Int']>;
  /** 登録日 */
  created_at: Scalars['DateTime'];
  /** 更新日 */
  updated_at: Scalars['DateTime'];
  /** 納期期限 */
  delivery_limit_date: Scalars['DateTime'];
  /** 納品済みか */
  is_delivered: Scalars['Boolean'];
  /** 作業中か */
  is_processing: Scalars['Boolean'];
  /** 未納品か */
  is_undelivered: Scalars['Boolean'];
  /** クリエイター */
  creator: Creator;
  /** 注文 */
  order: Order;
  /** 商品 */
  product: Product;
  /** 納品日時 */
  delivered_at?: Maybe<Scalars['DateTime']>;
  /** 納品済み商品 */
  latest_delivered_product?: Maybe<DeliveredProduct>;
};

export enum OrderedProductLiveTalkStatus {
  /** Un talked */
  UnTalked = 'UN_TALKED',
  /** Waiting */
  Waiting = 'WAITING',
  /** Talking */
  Talking = 'TALKING',
  /** Talked */
  Talked = 'TALKED',
  /** Canceled */
  Canceled = 'CANCELED'
}

/** A paginated list of OrderedProduct items. */
export type OrderedProductPaginator = {
  __typename?: 'OrderedProductPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of OrderedProduct items. */
  data: Array<OrderedProduct>;
};

export enum OrderedProductStatus {
  /** Undelivered */
  Undelivered = 'UNDELIVERED',
  /** Processing */
  Processing = 'PROCESSING',
  /** Delivered */
  Delivered = 'DELIVERED',
  /** Canceled */
  Canceled = 'CANCELED'
}

/** 注文したユーザー */
export type OrderedUser = {
  __typename?: 'OrderedUser';
  last_paid_at: Scalars['DateTime'];
  number_of_purchases: Scalars['Int'];
  user_name: Scalars['String'];
  user_id: Scalars['ID'];
};

/** A paginated list of OrderedUser items. */
export type OrderedUserPaginator = {
  __typename?: 'OrderedUserPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of OrderedUser items. */
  data: Array<OrderedUser>;
};

export enum OrderedUsersSort {
  /** Last paid at asc */
  LastPaidAtAsc = 'LAST_PAID_AT_ASC',
  /** Last paid at desc */
  LastPaidAtDesc = 'LAST_PAID_AT_DESC',
  /** Number of purchases asc */
  NumberOfPurchasesAsc = 'NUMBER_OF_PURCHASES_ASC',
  /** Number of purchases desc */
  NumberOfPurchasesDesc = 'NUMBER_OF_PURCHASES_DESC'
}

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

export type PagedCreatorSalesPerMonthBreakdownInput = {
  /** YYYYMM 未指定の場合は当月のデータを取得 */
  year_month?: Maybe<Scalars['YearMonth']>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

/** Paidy */
export type Paidy = {
  __typename?: 'Paidy';
  /** ペイロード */
  payload: PaidyPayload;
  /** ペイロード(JSONエンコード済み) */
  payload_json: Scalars['String'];
  /** 公開キー */
  public_key: Scalars['String'];
};

/** PaidyBuyer */
export type PaidyBuyer = {
  __typename?: 'PaidyBuyer';
  email?: Maybe<Scalars['String']>;
};

/** PaidyBuyerData */
export type PaidyBuyerData = {
  __typename?: 'PaidyBuyerData';
  user_id?: Maybe<Scalars['String']>;
  age: Scalars['Int'];
  order_count: Scalars['Int'];
  ltv: Scalars['Int'];
  last_order_amount: Scalars['Int'];
  last_order_at: Scalars['Int'];
};

/** PaidyOrder */
export type PaidyOrder = {
  __typename?: 'PaidyOrder';
  items: Array<PaidyOrderItem>;
  order_ref?: Maybe<Scalars['String']>;
};

/** PaidyOrderItem */
export type PaidyOrderItem = {
  __typename?: 'PaidyOrderItem';
  id?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  title: Scalars['String'];
  unit_price: Scalars['Int'];
};

/** PaidyPayload */
export type PaidyPayload = {
  __typename?: 'PaidyPayload';
  /** 金額 */
  amount: Scalars['Int'];
  /** 通貨 */
  currency: Scalars['String'];
  /** 店舗名 */
  store_name: Scalars['String'];
  /** 買い手 */
  buyer?: Maybe<PaidyBuyer>;
  /** 買い手情報 */
  buyer_data: PaidyBuyerData;
  /** 注文情報 */
  order: PaidyOrder;
};

export type PasswordResetInput = {
  email: Scalars['String'];
};

export enum PaymentMethod {
  /** Stripe */
  Stripe = 'STRIPE',
  /** Paidy */
  Paidy = 'PAIDY',
  /** Robot payment */
  RobotPayment = 'ROBOT_PAYMENT'
}

/** 非表示も含めた商品 */
export type Product = {
  __typename?: 'Product';
  /** 商品ID */
  id: Scalars['ID'];
  /** 商品名 */
  name: Scalars['String'];
  /** 商品のクリエイターID */
  creator_id: Scalars['ID'];
  /** 商品のステータス */
  status: ProductStatus;
  /** 商品の価格 */
  price: Scalars['Int'];
  /** 商品の特別価格 */
  special_price?: Maybe<Scalars['Int']>;
  /** 商品の在庫数 */
  stock: Scalars['Int'];
  /** 商品の残り在庫数 */
  remaining_stock: Scalars['Int'];
  /** 商品が売り切れたか */
  sold_outed: Scalars['Int'];
  /** 商品のサムネイル画像URL */
  thumbnail_image: Scalars['String'];
  /** 公開日 */
  released_at: Scalars['DateTime'];
  /** 商品の種類 */
  product_type: ProductType;
  /** 商品の種類名 */
  product_type_name: Scalars['String'];
  /** 動画の長さ */
  movie_seconds?: Maybe<Scalars['Int']>;
  /** 登録日 */
  created_at: Scalars['DateTime'];
  /** 更新日 */
  updated_at: Scalars['DateTime'];
  /** 削除日 */
  deleted_at?: Maybe<Scalars['DateTime']>;
  /** クリエイター */
  creator?: Maybe<Creator>;
  /** 商品タイプが動画か */
  is_movie: Scalars['Boolean'];
  /** 商品タイプが写真か */
  is_photo: Scalars['Boolean'];
  /** 商品タイプがライブトークか */
  is_live_talk: Scalars['Boolean'];
  /** ライブトーク商品 */
  live_talk?: Maybe<ProductLiveTalk>;
};

export enum ProductLabelByAdmin {
  /** Hidden from products on sale */
  HiddenFromProductsOnSale = 'HIDDEN_FROM_PRODUCTS_ON_SALE'
}

/** ライブトーク商品 */
export type ProductLiveTalk = {
  __typename?: 'ProductLiveTalk';
  /** 商品ID */
  product_id: Scalars['ID'];
  /** トーク開始時間 */
  started_at: Scalars['DateTime'];
  /** トーク時間(分) */
  talk_minutes: Scalars['Int'];
};

export enum ProductMovieLength {
  /** Sixty */
  Sixty = 'SIXTY',
  /** Fifty */
  Fifty = 'FIFTY',
  /** Fourty */
  Fourty = 'FOURTY',
  /** Thirty */
  Thirty = 'THIRTY',
  /** Twenty */
  Twenty = 'TWENTY',
  /** Ten */
  Ten = 'TEN',
  /** Five */
  Five = 'FIVE'
}

/** A paginated list of Product items. */
export type ProductPaginator = {
  __typename?: 'ProductPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Product items. */
  data: Array<Product>;
};

export enum ProductStatus {
  /** Sale */
  Sale = 'SALE',
  /** Reserve */
  Reserve = 'RESERVE',
  /** Draft */
  Draft = 'DRAFT',
  /** Disable */
  Disable = 'DISABLE',
  /** End */
  End = 'END'
}

export enum ProductType {
  /** 写真 */
  Photo = 'PHOTO',
  /** 動画 */
  Movie = 'MOVIE',
  /** ライブトーク */
  LiveTalk = 'LIVE_TALK'
}

/** 注文済み商品 */
export type PublishedOrderedProduct = {
  __typename?: 'PublishedOrderedProduct';
  /** 注文済み商品ID */
  id: Scalars['ID'];
  /** 注文済み商品のステータス */
  status: OrderedProductStatus;
  /** 注文済みライブトーク商品ステータス */
  live_talk_status?: Maybe<OrderedProductLiveTalkStatus>;
  /** 注文済み商品のクリエイターID */
  creator_id: Scalars['ID'];
  /** 注文済み商品の注文ID */
  order_id: Scalars['ID'];
  /** 注文済み商品の商品ID */
  product_id: Scalars['ID'];
  /** 納品催促メール1日前をお送信済みか */
  is_sent_reminder_delivery_1day_ago_mail?: Maybe<Scalars['Int']>;
  /** 納品催促メール1時間前を送信済みか */
  is_sent_reminder_delivery_1hour_ago_mail?: Maybe<Scalars['Int']>;
  /** 納品催促メール納期期限切れを送信済みか */
  is_sent_reminder_delivery_after_mail?: Maybe<Scalars['Int']>;
  /** 登録日 */
  created_at: Scalars['DateTime'];
  /** 更新日 */
  updated_at: Scalars['DateTime'];
  /** 納期期限 */
  delivery_limit_date: Scalars['DateTime'];
  /** 納品済みか */
  is_delivered: Scalars['Boolean'];
  /** 作業中か */
  is_processing: Scalars['Boolean'];
  /** 未納品か */
  is_undelivered: Scalars['Boolean'];
  /** クリエイター */
  creator: Creator;
  /** 注文 */
  order: Order;
  /** 商品 */
  product: PublishedProduct;
  /** 納品済み商品 */
  latest_delivered_product?: Maybe<DeliveredProduct>;
};

/** A paginated list of PublishedOrderedProduct items. */
export type PublishedOrderedProductPaginator = {
  __typename?: 'PublishedOrderedProductPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of PublishedOrderedProduct items. */
  data: Array<PublishedOrderedProduct>;
};

/** 公開用の商品 */
export type PublishedProduct = {
  __typename?: 'PublishedProduct';
  /** 商品ID */
  id: Scalars['ID'];
  /** 商品名 */
  name: Scalars['String'];
  /** 商品のクリエイターID */
  creator_id: Scalars['ID'];
  /** 商品のステータス */
  status: ProductStatus;
  /** 商品の価格 */
  price: Scalars['Int'];
  /** 商品の特別価格 */
  special_price?: Maybe<Scalars['Int']>;
  /** 商品の在庫数 */
  stock: Scalars['Int'];
  /** 商品の残り在庫数 */
  remaining_stock: Scalars['Int'];
  /** 商品が売り切れたか */
  sold_outed: Scalars['Int'];
  /** 商品のサムネイル画像URL */
  thumbnail_image: Scalars['String'];
  /** 公開日 */
  released_at: Scalars['DateTime'];
  /** 商品の種類 */
  product_type: ProductType;
  /** 商品の種類名 */
  product_type_name: Scalars['String'];
  /** 動画の長さ */
  movie_seconds?: Maybe<Scalars['Int']>;
  /** クリエイター */
  creator?: Maybe<Creator>;
  /** 商品タイプが動画か */
  is_movie: Scalars['Boolean'];
  /** 商品タイプが写真か */
  is_photo: Scalars['Boolean'];
  /** 商品タイプがライブトークか */
  is_live_talk: Scalars['Boolean'];
  /** ライブトーク商品 */
  live_talk?: Maybe<ProductLiveTalk>;
};

/** A paginated list of PublishedProduct items. */
export type PublishedProductPaginator = {
  __typename?: 'PublishedProductPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of PublishedProduct items. */
  data: Array<PublishedProduct>;
};

/** 購入注文[ユーザー用] */
export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  /** 注文ID */
  id: Scalars['ID'];
  /** 価格 */
  price: Scalars['Int'];
  /** 購入完了期限 */
  paid_limit: Scalars['DateTime'];
  /** 注文した商品 */
  product: PublishedProduct;
};

/** 購入商品[ユーザー用] */
export type PurchaseProduct = {
  __typename?: 'PurchaseProduct';
  /** 注文 */
  order: PurchaseOrder;
  /** Stripe */
  stripe: Stripe;
  /** Paidy */
  paidy: Paidy;
  /** Robot Payment */
  robot_payment: RobotPayment;
  /** 注文有効時間 */
  available_order_minutes: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** [ゲスト]アクティブなクリエイターをID指定して取得するクエリー */
  ActiveCreator: Creator;
  /** [ビジネス]所属内のアクティブなクリエイター一覧を取得するクエリー */
  ActiveCreatorsInAffiliation: Array<Creator>;
  /** [ビジネス]クリエイターリストクエリー */
  ActiveCreatorsInBusiness: Array<Creator>;
  /** [ビジネス]IDを指定して所属を取得するクエリー */
  AffiliationInBusiness: Affiliation;
  /** [ビジネス]所属毎の今月の売上一覧クエリー */
  AffiliationSaleThisMonth: AffiliationSaleThisMonth;
  /** [ビジネス]所属毎の月別売上一覧クエリー */
  AffiliationSalesPerMonth: AffiliationSalesPerMonth;
  /** [ビジネス]ビジネス内の所属を全て取得するクエリー */
  AffiliationsInBusiness: Array<Affiliation>;
  /** [ビジネス]チャットルームのビデオトークンの取得 */
  BusinessCreateVideoChatRoomToken: Scalars['String'];
  /** [ビジネス]クリエーターマイページクエリー */
  BusinessCreatorMypage: CreatorMypage;
  BusinessOrderedProduct?: Maybe<OrderedProduct>;
  /** [ビジネス]商品詳細を取得するクエリー */
  BusinessProduct: Product;
  /** [ビジネス]指定クリエイターの公開商品一覧クエリー */
  BusinessPublishedProducts: Array<Maybe<Product>>;
  /** [ビジネス]ビジネス内の月別売上一覧クエリー */
  BusinessSalesPerMonth: BusinessSalesPerMonth;
  /** [ビジネス]今月の売上クエリー */
  BusinessSalesThisMonth?: Maybe<SalesPerMonth>;
  /** [ビジネス]指定クリエイターの未納品注文一覧クエリー */
  BusinessUndeliveredOrderedProducts: Array<OrderedProduct>;
  /** [クリエイター]チャットルームのビデオトークンの取得 */
  CreatorCreateVideoChatRoomToken: Scalars['String'];
  /** [ビジネス]クリエイタークエリー */
  CreatorInBusiness: Creator;
  /** [クリエイター]動画納品用のアップロードの一時URLを取得するクエリー */
  CreatorMovieDeliveryProductUploadUrl: MovieProductUpload;
  CreatorOrderedProduct?: Maybe<OrderedProduct>;
  /** [クリエイター]商品詳細を取得するクエリー */
  CreatorProduct: Product;
  /** [クリエイター]自分の公開商品一覧クエリー */
  CreatorPublishedProducts: Array<Maybe<Product>>;
  /** [クリエイター]指定したクリエイターのユーザー購入ランキングを取得するクエリー */
  CreatorPurchaseUserRanking: Array<CreatorPurchaseUserRanking>;
  /** [ゲスト]日次販売数クリエイターランキングを取得するクエリー */
  CreatorRankingOfDailySalesCount: Array<CreatorRanking>;
  /** [ゲスト]月次販売数クリエイターランキングを取得するクエリー */
  CreatorRankingOfMonthlySalesCount: Array<CreatorRanking>;
  /** [ゲスト]週次販売数クリエイターランキングを取得するクエリー */
  CreatorRankingOfWeeklySalesCount: Array<CreatorRanking>;
  /** [ビジネス]指定したクリエイターの今月の売上を取得するクエリー */
  CreatorSaleThisMonth?: Maybe<SalesPerMonth>;
  /** [ビジネス]クリエイター毎の月別売上一覧クエリー */
  CreatorSalesPerMonth: CreatorSalesPerMonth;
  /** [クリエイター]年間の月別売上一覧 */
  CreatorSalesPerMonthInYear: Array<SalesPerMonth>;
  /** [クリエイター]指定クリエイターの未納品注文一覧クエリー */
  CreatorUndeliveredOrderedProducts: Array<OrderedProduct>;
  /** [ビジネス]所属内のクリエイター一覧を取得するクエリー */
  CreatorsInAffiliation: Array<Creator>;
  /** [ビジネス]ログイン中のビジネスユーザーを取得するクエリー */
  CurrentBusinessUser?: Maybe<BusinessUser>;
  /** [クリエイター]ログイン中のクリエイターを取得するクエリー */
  CurrentCreator?: Maybe<Creator>;
  /** [ユーザー]ログイン中の一般ユーザーを取得するクエリー */
  CurrentUser?: Maybe<User>;
  /** [ユーザー]お気に入り済みのクリエイターページネーションを取得するクエリー */
  FavoriteCreators?: Maybe<CreatorPaginator>;
  /** [ビジネス]フィルターしたクリエイター毎の月次売上の一時URLを生成 */
  GenerateTemporaryUrlForFilteredMonthlySalesByCreator: Scalars['String'];
  /** [ビジネス]フィルターした月次売上の一時URLを生成 */
  GenerateTemporaryUrlForFilteredMonthlySales: Scalars['String'];
  /** [ビジネス]月次売上の一時URLを生成 */
  GenerateTemporaryUrlForMonthlySales: Scalars['String'];
  /** [ビジネス]クリエイター毎の月次売上の一時URLを生成 */
  GenerateTemporaryUrlForMonthlySalesByCreator: Scalars['String'];
  /** [ゲスト]商品登録時のライブトークの長さの設定可能な範囲を取得するクエリー */
  LiveTalkMinutesRange: Array<Scalars['Int']>;
  /** [ビジネス]動画納品用のアップロードの一時URLを取得するクエリー */
  MovieDeliveryProductUploadUrl: MovieProductUpload;
  /** [ゲスト]商品登録時の動画の長さの設定可能な範囲を取得するクエリー */
  MovieSecondsRange: Array<Scalars['Int']>;
  /** [ゲスト]アクティブなクリエイターを検索するクエリー */
  PagedActiveCreators?: Maybe<CreatorPaginator>;
  /** [ビジネス]ビジネス内の所属を全て取得するクエリー(ペジネーション付き) */
  PagedAffiliationsInBusiness?: Maybe<AffiliationPaginator>;
  /** [ビジネス]ユーザー毎注文済み商品一覧クエリー(ページネーション付き) */
  PagedBusinessDeliveredOrderedProductPerUser?: Maybe<OrderedProductPaginator>;
  /** [ビジネス]納品済み注文一覧 */
  PagedBusinessDeliveredOrderedProducts?: Maybe<OrderedProductPaginator>;
  PagedBusinessDisabledProducts?: Maybe<ProductPaginator>;
  /** [ビジネス]購入者一覧 */
  PagedBusinessOrderedUsers?: Maybe<OrderedUserPaginator>;
  /** [ビジネス]指定クリエイターの公開商品一覧クエリー(ページネーション付き) */
  PagedBusinessPublishedProducts?: Maybe<ProductPaginator>;
  PagedBusinessReservedProducts?: Maybe<ProductPaginator>;
  /** [ビジネス]ユーザー毎納品済み商品一覧クエリー(ページネーション付き) */
  PagedBusinessUndeliveredOrderedProductPerUser?: Maybe<OrderedProductPaginator>;
  /** [クリエイター]ユーザー毎注文済み商品一覧クエリー(ページネーション付き) */
  PagedCreatorDeliveredOrderedProductPerUser?: Maybe<OrderedProductPaginator>;
  /** [クリエイター]納品済み注文一覧 */
  PagedCreatorDeliveredOrderedProducts?: Maybe<OrderedProductPaginator>;
  PagedCreatorDisabledProducts?: Maybe<ProductPaginator>;
  /** [クリエイター]購入者一覧 */
  PagedCreatorOrderedUsers?: Maybe<OrderedUserPaginator>;
  /** [クリエイター]公開商品一覧クエリー(ページネーション付き) */
  PagedCreatorPublishedProducts?: Maybe<ProductPaginator>;
  PagedCreatorReservedProducts?: Maybe<ProductPaginator>;
  /** [クリエイター]月別売上内訳 */
  PagedCreatorSalesPerMonthBreakdown?: Maybe<SalesBreakdownPaginator>;
  /** [クリエイター]ユーザー毎納品済み商品一覧クエリー(ページネーション付き) */
  PagedCreatorUndeliveredOrderedProductPerUser?: Maybe<OrderedProductPaginator>;
  /** [ビジネス]クリエイターリストクエリー */
  PagedCreatorsInBusiness?: Maybe<CreatorPaginator>;
  /** [ゲスト]販売中商品を取得するクエリー */
  PagedProductsOnSale?: Maybe<PublishedProductPaginator>;
  /** [ゲスト]公開商品を取得するクエリー */
  PagedPublishedProducts?: Maybe<PublishedProductPaginator>;
  /** [ビジネス]注文別売上一覧クエリー */
  PagedSalesPerOrder?: Maybe<SalesPerOrderPaginator>;
  /** [ユーザー]お気に入りクリエイター一覧クエリー */
  PagedUserActiveCreatorsFavorited?: Maybe<CreatorPaginator>;
  /** [ユーザ]お気に入りクリエイターの新着商品クエリー */
  PagedUserFavoritedCreatorLatestProducts?: Maybe<PublishedProductPaginator>;
  /** [ユーザー]指定クリエイターの公開商品一覧クエリー(ページネーション付き) */
  PagedUserPublishedProducts?: Maybe<PublishedProductPaginator>;
  /** [ユーザー]購入履歴商品一覧クエリー */
  PagedUserPurchasedProducts?: Maybe<PublishedOrderedProductPaginator>;
  /** [ゲスト]商品登録時の価格の設定可能な範囲を取得するクエリー */
  PriceRange: Array<Scalars['Int']>;
  /** [ゲスト]公開商品を取得するクエリー */
  PublishedProduct: PublishedProduct;
  /** [ゲスト]このクリエイターを見ている方へのおすすめを取得するクエリー */
  RecommendedCreators: Array<Creator>;
  /** [ゲスト]アクティブなクリエイターをID指定して取得するクエリー */
  RequestCreator: RequestCreator;
  /** [ビジネス]所属別売上一覧クエリー */
  SalesPerAffiliation: SalesPerAffiliation;
  /** [ビジネス]所属毎、月毎のクリエイター別売上一覧クエリー */
  SalesPerCreator: SalesPerCreatorGroup;
  /** [ユーザ]チャットルームのビデオトークンの取得 */
  UserCreateVideoChatRoomToken: Scalars['String'];
  /** [ユーザー]商品詳細を取得するクエリー */
  UserPublishedProduct: PublishedProduct;
  /** [ユーザー]購入商品を取得するクエリ */
  UserPurchasedProduct: PublishedOrderedProduct;
  /** [ビジネス]ビジネスユーザーのトークンの有効性を判定するクエリー */
  ValidateBusinessUserToken: Scalars['Boolean'];
  /** [クリエイター]クリエイターのトークンの有効性を判定するクエリー */
  ValidateCreatorToken: Scalars['Boolean'];
  /** [ユーザー]ユーザーのトークンの有効性を判定するクエリー */
  ValidateUserToken: Scalars['Boolean'];
};


export type QueryActiveCreatorArgs = {
  id: Scalars['ID'];
};


export type QueryActiveCreatorsInAffiliationArgs = {
  affiliation_id?: Maybe<Scalars['ID']>;
};


export type QueryAffiliationInBusinessArgs = {
  affiliation_id?: Maybe<Scalars['ID']>;
};


export type QueryAffiliationSaleThisMonthArgs = {
  affiliation_id?: Maybe<Scalars['ID']>;
};


export type QueryAffiliationSalesPerMonthArgs = {
  affiliation_id?: Maybe<Scalars['ID']>;
};


export type QueryBusinessCreateVideoChatRoomTokenArgs = {
  product_id: Scalars['ID'];
  creator_id: Scalars['ID'];
};


export type QueryBusinessCreatorMypageArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessOrderedProductArgs = {
  ordered_product_id: Scalars['ID'];
};


export type QueryBusinessProductArgs = {
  product_id: Scalars['ID'];
};


export type QueryBusinessPublishedProductsArgs = {
  creator_id: Scalars['ID'];
};


export type QueryBusinessUndeliveredOrderedProductsArgs = {
  creator_id: Scalars['ID'];
};


export type QueryCreatorCreateVideoChatRoomTokenArgs = {
  product_id: Scalars['ID'];
};


export type QueryCreatorInBusinessArgs = {
  id: Scalars['ID'];
};


export type QueryCreatorMovieDeliveryProductUploadUrlArgs = {
  ordered_product_id: Scalars['ID'];
};


export type QueryCreatorOrderedProductArgs = {
  ordered_product_id: Scalars['ID'];
};


export type QueryCreatorProductArgs = {
  product_id: Scalars['ID'];
};


export type QueryCreatorRankingOfDailySalesCountArgs = {
  input: CreatorRankingInput;
};


export type QueryCreatorRankingOfMonthlySalesCountArgs = {
  input: CreatorRankingInput;
};


export type QueryCreatorRankingOfWeeklySalesCountArgs = {
  input: CreatorRankingInput;
};


export type QueryCreatorSaleThisMonthArgs = {
  creator_id: Scalars['ID'];
};


export type QueryCreatorSalesPerMonthArgs = {
  creator_id: Scalars['ID'];
};


export type QueryCreatorSalesPerMonthInYearArgs = {
  input: CreatorSalesPerMonthInYearInput;
};


export type QueryCreatorsInAffiliationArgs = {
  affiliation_id?: Maybe<Scalars['ID']>;
};


export type QueryFavoriteCreatorsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGenerateTemporaryUrlForFilteredMonthlySalesByCreatorArgs = {
  input: GenerateTemporaryUrlForFilteredMonthlySalesByCreatorInput;
};


export type QueryGenerateTemporaryUrlForFilteredMonthlySalesArgs = {
  input: GenerateTemporaryUrlForFilteredMonthlySalesInput;
};


export type QueryGenerateTemporaryUrlForMonthlySalesArgs = {
  input: GenerateTemporaryUrlForMonthlySalesInput;
};


export type QueryGenerateTemporaryUrlForMonthlySalesByCreatorArgs = {
  input: GenerateTemporaryUrlForMonthlySalesByCreatorInput;
};


export type QueryMovieDeliveryProductUploadUrlArgs = {
  ordered_product_id: Scalars['ID'];
};


export type QueryPagedActiveCreatorsArgs = {
  input: ActiveCreatorsInput;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedAffiliationsInBusinessArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedBusinessDeliveredOrderedProductPerUserArgs = {
  user_id?: Maybe<Scalars['ID']>;
  creator_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedBusinessDeliveredOrderedProductsArgs = {
  creator_id: Scalars['ID'];
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedBusinessDisabledProductsArgs = {
  creator_id: Scalars['ID'];
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedBusinessOrderedUsersArgs = {
  creator_id?: Maybe<Scalars['ID']>;
  sort?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedBusinessPublishedProductsArgs = {
  creator_id: Scalars['ID'];
  exclude_product_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedBusinessReservedProductsArgs = {
  creator_id: Scalars['ID'];
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedBusinessUndeliveredOrderedProductPerUserArgs = {
  user_id?: Maybe<Scalars['ID']>;
  creator_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorDeliveredOrderedProductPerUserArgs = {
  user_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorDeliveredOrderedProductsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorDisabledProductsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorOrderedUsersArgs = {
  sort?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorPublishedProductsArgs = {
  exclude_product_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorReservedProductsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorSalesPerMonthBreakdownArgs = {
  input: PagedCreatorSalesPerMonthBreakdownInput;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorUndeliveredOrderedProductPerUserArgs = {
  user_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedCreatorsInBusinessArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedProductsOnSaleArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedPublishedProductsArgs = {
  creator_id?: Maybe<Scalars['ID']>;
  exclude_product_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedSalesPerOrderArgs = {
  has_all_affiliation?: Maybe<Scalars['Boolean']>;
  affiliation_id?: Maybe<Scalars['ID']>;
  creator_id?: Maybe<Scalars['ID']>;
  input: SalesInput;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedUserActiveCreatorsFavoritedArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedUserFavoritedCreatorLatestProductsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedUserPublishedProductsArgs = {
  creator_id: Scalars['ID'];
  exclude_product_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagedUserPurchasedProductsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPublishedProductArgs = {
  product_id: Scalars['ID'];
};


export type QueryRecommendedCreatorsArgs = {
  input: RecommendedCreatorsInput;
};


export type QueryRequestCreatorArgs = {
  creator_id: Scalars['ID'];
  input: RequestCreatorInput;
};


export type QuerySalesPerAffiliationArgs = {
  input: SalesInput;
};


export type QuerySalesPerCreatorArgs = {
  affiliation_id?: Maybe<Scalars['ID']>;
  input: SalesInput;
};


export type QueryUserCreateVideoChatRoomTokenArgs = {
  product_id: Scalars['ID'];
};


export type QueryUserPublishedProductArgs = {
  product_id: Scalars['ID'];
};


export type QueryUserPurchasedProductArgs = {
  ordered_product_id: Scalars['ID'];
};

export type RecommendedCreatorsInput = {
  creator_id: Scalars['ID'];
};

export type RegisterAffiliationInput = {
  name: Scalars['String'];
};

export type RegisterLiveTalkProductInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  start_at: Scalars['DateTime'];
  talk_minutes: Scalars['Int'];
  thumbnail_image: Scalars['Upload'];
  released_at?: Maybe<Scalars['DateTime']>;
};

export type RegisterMovieProductInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  movie_seconds: Scalars['Int'];
  thumbnail_image: Scalars['Upload'];
  released_at?: Maybe<Scalars['DateTime']>;
};

export type RegisterNewCreatorInput = {
  email: Scalars['String'];
  affiliation_id?: Maybe<Scalars['ID']>;
};

export type RegisterPhotoProductInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  thumbnail_image: Scalars['Upload'];
  released_at?: Maybe<Scalars['DateTime']>;
};

/** 登録依頼中のクリエイター */
export type RequestCreator = {
  __typename?: 'RequestCreator';
  /** クリエイターID */
  id: Scalars['ID'];
  /** ビジネスID */
  business_id: Scalars['ID'];
  /** 所属ID */
  affiliation_id?: Maybe<Scalars['ID']>;
  /** クリエイターのステータス */
  status: CreatorAccountStatus;
  /** クリエイターのメールアドレス */
  email: Scalars['String'];
  /** クリエイターのステータス名 */
  status_name: Scalars['String'];
  /** 所属 */
  affiliation?: Maybe<Affiliation>;
};

export type RequestCreatorInput = {
  signature: Scalars['String'];
  expires: Scalars['Int'];
};

export type RequestLiveTalkInput = {
  creator_id: Scalars['ID'];
  is_casual_clothes: Scalars['Boolean'];
  is_pajamas: Scalars['Boolean'];
  is_other: Scalars['Boolean'];
  other_text?: Maybe<Scalars['String']>;
  requested_dates: Scalars['String'];
};

export type RequestMovieInput = {
  creator_id: Scalars['ID'];
  is_casual_clothes: Scalars['Boolean'];
  is_pajamas: Scalars['Boolean'];
  is_good_morning_message: Scalars['Boolean'];
  is_good_night_message: Scalars['Boolean'];
  is_say_you_like_me: Scalars['Boolean'];
  is_other: Scalars['Boolean'];
  other_text?: Maybe<Scalars['String']>;
};

export type RequestPhotoInput = {
  creator_id: Scalars['ID'];
  is_newly_taken: Scalars['Boolean'];
  is_casual_clothes: Scalars['Boolean'];
  is_pajamas: Scalars['Boolean'];
  is_other: Scalars['Boolean'];
  other_text?: Maybe<Scalars['String']>;
};

export type ResendEmailForVerifyingUpdatedBusinessUserEmailInput = {
  email: Scalars['String'];
};

export type ResendUserAccountRegistrationInput = {
  email: Scalars['String'];
};

/** RobotPayment */
export type RobotPayment = {
  __typename?: 'RobotPayment';
  /** クレジットカード決済画面URL */
  credit_card_link: Scalars['String'];
  /** 店舗ID */
  store_id: Scalars['String'];
  /** 商品金額 */
  price: Scalars['Int'];
  /** 税額 */
  tax: Scalars['Int'];
  /** 送料 */
  shipping_fee: Scalars['Int'];
  /** ジョブタイプ */
  job_type: Scalars['String'];
  /** 店舗オーダー番号 */
  order_id: Scalars['String'];
  /** 購入者Eメール */
  email: Scalars['String'];
  /** 商品名 */
  product_name: Scalars['String'];
  /** 商品番号 */
  product_id: Scalars['String'];
};

export enum RobotPaymentResultCode {
  /** Success */
  Success = 'SUCCESS',
  /** Failure */
  Failure = 'FAILURE'
}

/** 売上内訳 */
export type SalesBreakdown = {
  __typename?: 'SalesBreakdown';
  /** 納品日 */
  delivered_at?: Maybe<Scalars['DateTime']>;
  /** 購入日 */
  paid_at: Scalars['DateTime'];
  /** 購入者名 */
  user_name: Scalars['String'];
  /** 購入商品 */
  product_name: Scalars['String'];
  /** 形式 */
  product_type: ProductType;
  /** 形式名 */
  product_type_name: Scalars['String'];
  /** 秒数 */
  movie_seconds?: Maybe<Scalars['Int']>;
  /** トーク開始時間 */
  started_at?: Maybe<Scalars['DateTime']>;
  /** トーク時間(分) */
  talk_minutes?: Maybe<Scalars['Int']>;
  /** 注文ID */
  order_id: Scalars['ID'];
  /** 商品の価格 */
  sales: Scalars['Int'];
};

/** A paginated list of SalesBreakdown items. */
export type SalesBreakdownPaginator = {
  __typename?: 'SalesBreakdownPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of SalesBreakdown items. */
  data: Array<SalesBreakdown>;
};

export type SalesInput = {
  year_month: Scalars['YearMonth'];
};

/** 所属別売上 */
export type SalesPerAffiliation = {
  __typename?: 'SalesPerAffiliation';
  sales_per_month: Array<SalesPerMonthAffiliation>;
  total_sales?: Maybe<Scalars['Int']>;
};

/** 所属毎、月毎のクリエイター別売上 */
export type SalesPerCreator = {
  __typename?: 'SalesPerCreator';
  id: Scalars['ID'];
  name: Scalars['String'];
  count: Scalars['Int'];
  sales: Scalars['Int'];
};

export type SalesPerCreatorGroup = {
  __typename?: 'SalesPerCreatorGroup';
  affiliation?: Maybe<Affiliation>;
  sales_per_creator: Array<SalesPerCreator>;
  total_sales?: Maybe<Scalars['Int']>;
};

/** 月別売上 */
export type SalesPerMonth = {
  __typename?: 'SalesPerMonth';
  /** 年月 YYYYMM */
  month: Scalars['String'];
  /** 販売件数 */
  count: Scalars['Int'];
  /** 売上 */
  sales: Scalars['Int'];
};

/** 所属の月別売上 */
export type SalesPerMonthAffiliation = {
  __typename?: 'SalesPerMonthAffiliation';
  id: Scalars['ID'];
  name: Scalars['String'];
  count: Scalars['Int'];
  sales: Scalars['Int'];
};

/** 注文別売上 */
export type SalesPerOrder = {
  __typename?: 'SalesPerOrder';
  /** 納品日時 */
  delivered_at?: Maybe<Scalars['DateTime']>;
  /** 決済日時 */
  paid_at: Scalars['DateTime'];
  /** 購入者名 */
  user_name: Scalars['String'];
  /** 購入商品 */
  product_name: Scalars['String'];
  /** 所属名 */
  affiliation_name?: Maybe<Scalars['String']>;
  /** クリエイター名 */
  creator_name: Scalars['String'];
  /** 商品形式 */
  product_type: ProductType;
  /** 商品形式名 */
  product_type_name: Scalars['String'];
  /** 動画の長さ */
  movie_seconds?: Maybe<Scalars['Int']>;
  /** トーク開始時間 */
  started_at?: Maybe<Scalars['DateTime']>;
  /** トーク時間(分) */
  talk_minutes?: Maybe<Scalars['Int']>;
  /** 注文ID */
  order_id: Scalars['ID'];
  /** 商品の価格 */
  sales: Scalars['Int'];
};

/** 注文別売上の月別売上 */
export type SalesPerOrderMonth = {
  __typename?: 'SalesPerOrderMonth';
  sales_per_month: Array<SalesPerOrder>;
  total_sales?: Maybe<Scalars['Int']>;
};

/** A paginated list of SalesPerOrder items. */
export type SalesPerOrderPaginator = {
  __typename?: 'SalesPerOrderPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of SalesPerOrder items. */
  data: Array<SalesPerOrder>;
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Stripe */
export type Stripe = {
  __typename?: 'Stripe';
  /** セッション */
  checkout_session: StripeCheckoutSession;
  /** 公開キー */
  public_key: Scalars['String'];
  /** チェックアウトURL */
  checkout_url: Scalars['String'];
};

/** StripeCheckoutSession */
export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  id: Scalars['String'];
  object: Scalars['String'];
  allow_promotion_codes?: Maybe<Scalars['Boolean']>;
  amount_subtotal?: Maybe<Scalars['Int']>;
  amount_total?: Maybe<Scalars['Int']>;
  billing_address_collection?: Maybe<Scalars['String']>;
  cancel_url: Scalars['String'];
  client_reference_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  livemode: Scalars['Boolean'];
  locale?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  payment_method_types: Array<Scalars['String']>;
  payment_status: Scalars['String'];
  submit_type?: Maybe<Scalars['String']>;
  success_url: Scalars['String'];
};

export enum ToolTables {
  /** 管理ユーザ */
  AdminUsers = 'ADMIN_USERS',
  /** 所属 */
  Affiliations = 'AFFILIATIONS',
  /** ビジネスユーザ */
  BusinessUsers = 'BUSINESS_USERS',
  /** ビジネスパートナー */
  Businesses = 'BUSINESSES',
  /** クリエイター向け納品リマインダー送信履歴 */
  CreatorReminderDeliveries = 'CREATOR_REMINDER_DELIVERIES',
  /** クリエイター */
  Creators = 'CREATORS',
  /** クリエイターメモ */
  CreatorMemos = 'CREATOR_MEMOS',
  /** クリエイター属性 */
  CategoryCreators = 'CATEGORY_CREATORS',
  /** 納品済み商品 */
  DeliveredProducts = 'DELIVERED_PRODUCTS',
  /** 失敗したジョブ */
  FailedJobs = 'FAILED_JOBS',
  /** マイグレーション */
  Migrations = 'MIGRATIONS',
  /** 注文済み商品 */
  OrderedProducts = 'ORDERED_PRODUCTS',
  /** 注文 */
  Orders = 'ORDERS',
  /** パスワードリセット状態 */
  PasswordResets = 'PASSWORD_RESETS',
  /** Paidyレシート */
  PaidyReceipts = 'PAIDY_RECEIPTS',
  /** 課金履歴 */
  PaymentLogs = 'PAYMENT_LOGS',
  /** ライブトーク商品 */
  ProductLiveTalks = 'PRODUCT_LIVE_TALKS',
  /** 商品 */
  Products = 'PRODUCTS',
  /** Stripeレシート */
  StripeReceipts = 'STRIPE_RECEIPTS',
  /** RobotPaymentレシート */
  RobotPaymentReceipts = 'ROBOT_PAYMENT_RECEIPTS',
  /** ユーザがお気に入りしているクリエイター */
  UserFavoriteCreators = 'USER_FAVORITE_CREATORS',
  /** ユーザが通知をONにしているクリエイター */
  UserNoticeCreators = 'USER_NOTICE_CREATORS',
  /** ユーザー */
  Users = 'USERS',
  /** 写真リクエスト */
  PhotoRequests = 'PHOTO_REQUESTS',
  /** 動画リクエスト */
  MovieRequests = 'MOVIE_REQUESTS',
  /** ライブトークリクエスト */
  LiveTalkRequests = 'LIVE_TALK_REQUESTS'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export enum TwitterFetchTaskStatus {
  /** In progress */
  InProgress = 'IN_PROGRESS',
  /** Completed */
  Completed = 'COMPLETED'
}

/** アカウント更新データ */
export type UpdateAccount = {
  __typename?: 'UpdateAccount';
  /** メールアドレスに変更があるか */
  is_change_request_email: Scalars['Boolean'];
  /** 変更依頼したメールアドレス */
  change_request_email?: Maybe<Scalars['String']>;
};

export type UpdateBusinessUserAccountInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type UpdateCreatorAccountByTokenInput = {
  expires: Scalars['Int'];
  signature: Scalars['String'];
  creator_id: Scalars['ID'];
  name: Scalars['String'];
  profile?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  twitter_username?: Maybe<Scalars['String']>;
  instagram_username?: Maybe<Scalars['String']>;
  youtube_channel_url?: Maybe<Scalars['String']>;
  kindle_author_url?: Maybe<Scalars['String']>;
  icon: Scalars['Upload'];
};

export type UpdateCreatorAccountInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  profile?: Maybe<Scalars['String']>;
  affiliation_id?: Maybe<Scalars['ID']>;
  twitter_username?: Maybe<Scalars['String']>;
  instagram_username?: Maybe<Scalars['String']>;
  youtube_channel_url?: Maybe<Scalars['String']>;
  kindle_author_url?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
};

export type UpdateEmailByTokenInput = {
  id: Scalars['ID'];
  email: Scalars['String'];
  expires: Scalars['Int'];
  signature: Scalars['String'];
};

export type UpdatePasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateUserAccountInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  twitter_username?: Maybe<Scalars['String']>;
};

/** 更新クリエイター */
export type UpdatedCreator = {
  __typename?: 'UpdatedCreator';
  /** クリエイター情報 */
  creator: Creator;
  /** メールアドレス更新リクエストがあるか */
  is_change_request_email?: Maybe<Scalars['Boolean']>;
  /** 変更後のメールアドレス */
  change_request_email?: Maybe<Scalars['String']>;
};


/** 一般ユーザー */
export type User = {
  __typename?: 'User';
  /** ユーザーID */
  id: Scalars['ID'];
  /** ユーザーのステータス */
  status: UserAccountStatus;
  /** ユーザーの名前 */
  name: Scalars['String'];
  /** ユーザーのメールアドレス */
  email: Scalars['String'];
  /** ユーザーのTwitterユーザー名 */
  twitter_username?: Maybe<Scalars['String']>;
  /** 登録日 */
  created_at: Scalars['DateTime'];
  /** 更新日 */
  updated_at: Scalars['DateTime'];
};

export enum UserAccountStatus {
  /** 登録中 */
  Init = 'INIT',
  /** 有効 */
  Active = 'ACTIVE',
  /** 停止 */
  Suspend = 'SUSPEND',
  /** 退会 */
  Leave = 'LEAVE'
}

export type UserRegisterAccountInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  twitter_username?: Maybe<Scalars['String']>;
};

export type UserVerifyAccountInput = {
  email: Scalars['String'];
  expires: Scalars['Int'];
  signature: Scalars['String'];
};



export type BusinessResendCreatorAccountUpdateMutationVariables = Exact<{
  creator_id: Scalars['ID'];
  input: BusinessResendCreatorAccountUpdateInput;
}>;


export type BusinessResendCreatorAccountUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BusinessResendCreatorAccountUpdate'>
);

export type CreatorDeliveryLiveTalkProductMutationVariables = Exact<{
  ordered_product_id: Scalars['ID'];
}>;


export type CreatorDeliveryLiveTalkProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorDeliveryLiveTalkProduct'>
);

export type CreatorDeliveryMovieProductMutationVariables = Exact<{
  input: DeliveryMovieProductInput;
}>;


export type CreatorDeliveryMovieProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorDeliveryMovieProduct'>
);

export type CreatorDeliveryPhotoProductMutationVariables = Exact<{
  input: DeliveryPhotoProductInput;
}>;


export type CreatorDeliveryPhotoProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorDeliveryPhotoProduct'>
);

export type CreatorDisableProductMutationVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type CreatorDisableProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorDisableProduct'>
);

export type CreatorDisplayProductMutationVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type CreatorDisplayProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorDisplayProduct'>
);

export type CreatorFinishProductMutationVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type CreatorFinishProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorFinishProduct'>
);

export type CreatorPasswordResetMutationVariables = Exact<{
  input: PasswordResetInput;
}>;


export type CreatorPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorPasswordReset'>
);

export type CreatorRegisterLiveTalkProductMutationVariables = Exact<{
  input: RegisterLiveTalkProductInput;
}>;


export type CreatorRegisterLiveTalkProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorRegisterLiveTalkProduct'>
);

export type CreatorRegisterMovieProductMutationVariables = Exact<{
  input: RegisterMovieProductInput;
}>;


export type CreatorRegisterMovieProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorRegisterMovieProduct'>
);

export type CreatorRegisterPhotoProductMutationVariables = Exact<{
  input: RegisterPhotoProductInput;
}>;


export type CreatorRegisterPhotoProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorRegisterPhotoProduct'>
);

export type CreatorUpdatePasswordMutationVariables = Exact<{
  creator_id: Scalars['ID'];
  input: UpdatePasswordInput;
}>;


export type CreatorUpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreatorUpdatePassword'>
);

export type CurrentCreatorUpdateAccountMutationVariables = Exact<{
  creator_id: Scalars['ID'];
  input: CurrentCreatorUpdateAccountInput;
}>;


export type CurrentCreatorUpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { CurrentCreatorUpdateAccount?: Maybe<(
    { __typename?: 'UpdatedCreator' }
    & Pick<UpdatedCreator, 'is_change_request_email' | 'change_request_email'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name'>
    ) }
  )> }
);

export type DeleteCreatorAccountMutationVariables = Exact<{
  creator_id: Scalars['ID'];
}>;


export type DeleteCreatorAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteCreatorAccount'>
);

export type DeliveryLiveTalkProductByBusinessUserMutationVariables = Exact<{
  ordered_product_id: Scalars['ID'];
}>;


export type DeliveryLiveTalkProductByBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeliveryLiveTalkProductByBusinessUser'>
);

export type DeliveryMovieProductByBusinessUserMutationVariables = Exact<{
  input: DeliveryMovieProductInput;
}>;


export type DeliveryMovieProductByBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeliveryMovieProductByBusinessUser'>
);

export type DeliveryPhotoProductByBusinessUserMutationVariables = Exact<{
  input: DeliveryPhotoProductInput;
}>;


export type DeliveryPhotoProductByBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeliveryPhotoProductByBusinessUser'>
);

export type DisableProductMutationVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type DisableProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DisableProduct'>
);

export type DisplayProductMutationVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type DisplayProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DisplayProduct'>
);

export type EditAffiliationMutationVariables = Exact<{
  affiliation_id: Scalars['ID'];
  input: EditAffiliationInput;
}>;


export type EditAffiliationMutation = (
  { __typename?: 'Mutation' }
  & { EditAffiliation?: Maybe<(
    { __typename?: 'Affiliation' }
    & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
  )> }
);

export type FavoriteCreatorMutationVariables = Exact<{
  creator_id: Scalars['ID'];
}>;


export type FavoriteCreatorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FavoriteCreator'>
);

export type FinishProductMutationVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type FinishProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FinishProduct'>
);

export type LoginBusinessUserMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & { LoginBusinessUser?: Maybe<(
    { __typename?: 'LoginData' }
    & Pick<LoginData, 'token' | 'type' | 'ttl'>
  )> }
);

export type LoginCreatorMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginCreatorMutation = (
  { __typename?: 'Mutation' }
  & { LoginCreator?: Maybe<(
    { __typename?: 'LoginData' }
    & Pick<LoginData, 'token' | 'type' | 'ttl'>
  )> }
);

export type LoginUserMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginUserMutation = (
  { __typename?: 'Mutation' }
  & { LoginUser?: Maybe<(
    { __typename?: 'LoginData' }
    & Pick<LoginData, 'token' | 'type' | 'ttl'>
  )> }
);

export type LogoutBusinessUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LogoutBusinessUser'>
);

export type LogoutCreatorMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutCreatorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LogoutCreator'>
);

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LogoutUser'>
);

export type PasswordResetBusinessUserMutationVariables = Exact<{
  input: PasswordResetInput;
}>;


export type PasswordResetBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PasswordResetBusinessUser'>
);

export type RegisterAffiliationMutationVariables = Exact<{
  input: RegisterAffiliationInput;
}>;


export type RegisterAffiliationMutation = (
  { __typename?: 'Mutation' }
  & { RegisterAffiliation?: Maybe<(
    { __typename?: 'Affiliation' }
    & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
  )> }
);

export type RegisterLiveTalkProductByBusinessUserMutationVariables = Exact<{
  creator_id: Scalars['ID'];
  input: RegisterLiveTalkProductInput;
}>;


export type RegisterLiveTalkProductByBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RegisterLiveTalkProductByBusinessUser'>
);

export type RegisterMovieProductByBusinessUserMutationVariables = Exact<{
  creator_id: Scalars['ID'];
  input: RegisterMovieProductInput;
}>;


export type RegisterMovieProductByBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RegisterMovieProductByBusinessUser'>
);

export type RegisterNewCreatorMutationVariables = Exact<{
  input: RegisterNewCreatorInput;
}>;


export type RegisterNewCreatorMutation = (
  { __typename?: 'Mutation' }
  & { RegisterNewCreator?: Maybe<(
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type RegisterPhotoProductByBusinessUserMutationVariables = Exact<{
  creator_id: Scalars['ID'];
  input: RegisterPhotoProductInput;
}>;


export type RegisterPhotoProductByBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RegisterPhotoProductByBusinessUser'>
);

export type ResendCreatorAccountRegistrationMutationVariables = Exact<{
  creator_id: Scalars['ID'];
}>;


export type ResendCreatorAccountRegistrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ResendCreatorAccountRegistration'>
);

export type ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationVariables = Exact<{
  input: ResendEmailForVerifyingUpdatedBusinessUserEmailInput;
}>;


export type ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ResendEmailForVerifyingUpdatedBusinessUserEmail'>
);

export type ResendUserAccountRegistrationMutationVariables = Exact<{
  user_id: Scalars['ID'];
  input: ResendUserAccountRegistrationInput;
}>;


export type ResendUserAccountRegistrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ResendUserAccountRegistration'>
);

export type UnfavoriteCreatorMutationVariables = Exact<{
  creator_id: Scalars['ID'];
}>;


export type UnfavoriteCreatorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnfavoriteCreator'>
);

export type UpdateBusinessUserAccountMutationVariables = Exact<{
  input: UpdateBusinessUserAccountInput;
}>;


export type UpdateBusinessUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { UpdateBusinessUserAccount: (
    { __typename?: 'UpdateAccount' }
    & Pick<UpdateAccount, 'is_change_request_email' | 'change_request_email'>
  ) }
);

export type UpdateBusinessUserEmailByTokenMutationVariables = Exact<{
  input: UpdateEmailByTokenInput;
}>;


export type UpdateBusinessUserEmailByTokenMutation = (
  { __typename?: 'Mutation' }
  & { UpdateBusinessUserEmailByToken: (
    { __typename?: 'BusinessUser' }
    & Pick<BusinessUser, 'id' | 'name' | 'email' | 'created_at' | 'updated_at'>
  ) }
);

export type UpdateCreatorAccountMutationVariables = Exact<{
  creator_id: Scalars['ID'];
  input: UpdateCreatorAccountInput;
}>;


export type UpdateCreatorAccountMutation = (
  { __typename?: 'Mutation' }
  & { UpdateCreatorAccount?: Maybe<(
    { __typename?: 'UpdatedCreator' }
    & Pick<UpdatedCreator, 'is_change_request_email' | 'change_request_email'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name'>
    ) }
  )> }
);

export type UpdateCreatorAccountByTokenMutationVariables = Exact<{
  input: UpdateCreatorAccountByTokenInput;
}>;


export type UpdateCreatorAccountByTokenMutation = (
  { __typename?: 'Mutation' }
  & { UpdateCreatorAccountByToken: (
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  ) }
);

export type UpdateCreatorEmailByTokenMutationVariables = Exact<{
  input: UpdateEmailByTokenInput;
}>;


export type UpdateCreatorEmailByTokenMutation = (
  { __typename?: 'Mutation' }
  & { UpdateCreatorEmailByToken: (
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'favorite_users_count'>
  ) }
);

export type UpdatePasswordBusinessUserMutationVariables = Exact<{
  business_user_id: Scalars['ID'];
  input: UpdatePasswordInput;
}>;


export type UpdatePasswordBusinessUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdatePasswordBusinessUser'>
);

export type UpdateUserAccountMutationVariables = Exact<{
  input: UpdateUserAccountInput;
}>;


export type UpdateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { UpdateUserAccount: (
    { __typename?: 'UpdateAccount' }
    & Pick<UpdateAccount, 'is_change_request_email' | 'change_request_email'>
  ) }
);

export type UpdateUserEmailByTokenMutationVariables = Exact<{
  input: UpdateEmailByTokenInput;
}>;


export type UpdateUserEmailByTokenMutation = (
  { __typename?: 'Mutation' }
  & { UpdateUserEmailByToken: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
  ) }
);

export type UserPasswordResetMutationVariables = Exact<{
  input: PasswordResetInput;
}>;


export type UserPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UserPasswordReset'>
);

export type UserPurchaseProductMutationVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type UserPurchaseProductMutation = (
  { __typename?: 'Mutation' }
  & { UserPurchaseProduct: (
    { __typename?: 'PurchaseProduct' }
    & { order: (
      { __typename?: 'PurchaseOrder' }
      & Pick<PurchaseOrder, 'id' | 'price' | 'paid_limit'>
      & { product: (
        { __typename?: 'PublishedProduct' }
        & Pick<PublishedProduct, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ) }
    ), stripe: (
      { __typename?: 'Stripe' }
      & Pick<Stripe, 'public_key' | 'checkout_url'>
      & { checkout_session: (
        { __typename?: 'StripeCheckoutSession' }
        & Pick<StripeCheckoutSession, 'id' | 'object' | 'allow_promotion_codes' | 'amount_subtotal' | 'amount_total' | 'billing_address_collection' | 'cancel_url' | 'client_reference_id' | 'currency' | 'customer_email' | 'livemode' | 'locale' | 'mode' | 'payment_method_types' | 'payment_status' | 'submit_type' | 'success_url'>
      ) }
    ), paidy: (
      { __typename?: 'Paidy' }
      & Pick<Paidy, 'payload_json' | 'public_key'>
      & { payload: (
        { __typename?: 'PaidyPayload' }
        & Pick<PaidyPayload, 'amount' | 'currency' | 'store_name'>
        & { buyer?: Maybe<(
          { __typename?: 'PaidyBuyer' }
          & Pick<PaidyBuyer, 'email'>
        )>, buyer_data: (
          { __typename?: 'PaidyBuyerData' }
          & Pick<PaidyBuyerData, 'user_id' | 'age' | 'order_count' | 'ltv' | 'last_order_amount' | 'last_order_at'>
        ), order: (
          { __typename?: 'PaidyOrder' }
          & Pick<PaidyOrder, 'order_ref'>
          & { items: Array<(
            { __typename?: 'PaidyOrderItem' }
            & Pick<PaidyOrderItem, 'id' | 'quantity' | 'title' | 'unit_price'>
          )> }
        ) }
      ) }
    ), robot_payment: (
      { __typename?: 'RobotPayment' }
      & Pick<RobotPayment, 'credit_card_link' | 'store_id' | 'price' | 'tax' | 'shipping_fee' | 'job_type' | 'order_id' | 'email' | 'product_name' | 'product_id'>
    ) }
  ) }
);

export type UserRegisterAccountMutationVariables = Exact<{
  input: UserRegisterAccountInput;
}>;


export type UserRegisterAccountMutation = (
  { __typename?: 'Mutation' }
  & { UserRegisterAccount?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
  )> }
);

export type UserRequestLiveTalkMutationVariables = Exact<{
  input: RequestLiveTalkInput;
}>;


export type UserRequestLiveTalkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UserRequestLiveTalk'>
);

export type UserRequestMovieMutationVariables = Exact<{
  input: RequestMovieInput;
}>;


export type UserRequestMovieMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UserRequestMovie'>
);

export type UserRequestPhotoMutationVariables = Exact<{
  input: RequestPhotoInput;
}>;


export type UserRequestPhotoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UserRequestPhoto'>
);

export type UserUpdatePasswordMutationVariables = Exact<{
  user_id: Scalars['ID'];
  input: UpdatePasswordInput;
}>;


export type UserUpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UserUpdatePassword'>
);

export type UserVerifyAccountMutationVariables = Exact<{
  user_id: Scalars['ID'];
  input: UserVerifyAccountInput;
}>;


export type UserVerifyAccountMutation = (
  { __typename?: 'Mutation' }
  & { UserVerifyAccount?: Maybe<(
    { __typename?: 'LoginData' }
    & Pick<LoginData, 'token' | 'type' | 'ttl'>
  )> }
);

export type ActiveCreatorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActiveCreatorQuery = (
  { __typename?: 'Query' }
  & { ActiveCreator: (
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  ) }
);

export type ActiveCreatorsInAffiliationQueryVariables = Exact<{
  affiliation_id?: Maybe<Scalars['ID']>;
}>;


export type ActiveCreatorsInAffiliationQuery = (
  { __typename?: 'Query' }
  & { ActiveCreatorsInAffiliation: Array<(
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type ActiveCreatorsInBusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveCreatorsInBusinessQuery = (
  { __typename?: 'Query' }
  & { ActiveCreatorsInBusiness: Array<(
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type AffiliationInBusinessQueryVariables = Exact<{
  affiliation_id?: Maybe<Scalars['ID']>;
}>;


export type AffiliationInBusinessQuery = (
  { __typename?: 'Query' }
  & { AffiliationInBusiness: (
    { __typename?: 'Affiliation' }
    & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
  ) }
);

export type AffiliationSaleThisMonthQueryVariables = Exact<{
  affiliation_id: Scalars['ID'];
}>;


export type AffiliationSaleThisMonthQuery = (
  { __typename?: 'Query' }
  & { AffiliationSaleThisMonth: (
    { __typename?: 'AffiliationSaleThisMonth' }
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )>, sale_this_month?: Maybe<(
      { __typename?: 'SalesPerMonth' }
      & Pick<SalesPerMonth, 'month' | 'count' | 'sales'>
    )> }
  ) }
);

export type AffiliationSalesPerMonthQueryVariables = Exact<{
  affiliation_id?: Maybe<Scalars['ID']>;
}>;


export type AffiliationSalesPerMonthQuery = (
  { __typename?: 'Query' }
  & { AffiliationSalesPerMonth: (
    { __typename?: 'AffiliationSalesPerMonth' }
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )>, sales_per_month: Array<(
      { __typename?: 'SalesPerMonth' }
      & Pick<SalesPerMonth, 'month' | 'count' | 'sales'>
    )> }
  ) }
);

export type AffiliationsInBusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type AffiliationsInBusinessQuery = (
  { __typename?: 'Query' }
  & { AffiliationsInBusiness: Array<(
    { __typename?: 'Affiliation' }
    & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
  )> }
);

export type BusinessCreateVideoChatRoomTokenQueryVariables = Exact<{
  product_id: Scalars['ID'];
  creator_id: Scalars['ID'];
}>;


export type BusinessCreateVideoChatRoomTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BusinessCreateVideoChatRoomToken'>
);

export type BusinessCreatorMypageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessCreatorMypageQuery = (
  { __typename?: 'Query' }
  & { BusinessCreatorMypage: (
    { __typename?: 'CreatorMypage' }
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ), sales?: Maybe<(
      { __typename?: 'SalesPerMonth' }
      & Pick<SalesPerMonth, 'month' | 'count' | 'sales'>
    )>, published_products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at'>
    )>>>, reserved_products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at'>
    )>>>, disabled_products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at'>
    )>>> }
  ) }
);

export type BusinessOrderedProductQueryVariables = Exact<{
  ordered_product_id: Scalars['ID'];
}>;


export type BusinessOrderedProductQuery = (
  { __typename?: 'Query' }
  & { BusinessOrderedProduct?: Maybe<(
    { __typename?: 'OrderedProduct' }
    & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivered_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ), order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ) }
    ), product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    ), latest_delivered_product?: Maybe<(
      { __typename?: 'DeliveredProduct' }
      & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type BusinessProductQueryVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type BusinessProductQuery = (
  { __typename?: 'Query' }
  & { BusinessProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
    & { creator?: Maybe<(
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    )>, live_talk?: Maybe<(
      { __typename?: 'ProductLiveTalk' }
      & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
    )> }
  ) }
);

export type BusinessSalesPerMonthQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessSalesPerMonthQuery = (
  { __typename?: 'Query' }
  & { BusinessSalesPerMonth: (
    { __typename?: 'BusinessSalesPerMonth' }
    & Pick<BusinessSalesPerMonth, 'total_sales'>
    & { sales_per_month: Array<(
      { __typename?: 'SalesPerMonth' }
      & Pick<SalesPerMonth, 'month' | 'count' | 'sales'>
    )> }
  ) }
);

export type BusinessSalesThisMonthQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessSalesThisMonthQuery = (
  { __typename?: 'Query' }
  & { BusinessSalesThisMonth?: Maybe<(
    { __typename?: 'SalesPerMonth' }
    & Pick<SalesPerMonth, 'month' | 'count' | 'sales'>
  )> }
);

export type BusinessUndeliveredOrderedProductsQueryVariables = Exact<{
  creator_id: Scalars['ID'];
}>;


export type BusinessUndeliveredOrderedProductsQuery = (
  { __typename?: 'Query' }
  & { BusinessUndeliveredOrderedProducts: Array<(
    { __typename?: 'OrderedProduct' }
    & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivered_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ), order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ) }
    ), product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    ), latest_delivered_product?: Maybe<(
      { __typename?: 'DeliveredProduct' }
      & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type UserCreateVideoChatRoomTokenQueryVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type UserCreateVideoChatRoomTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'UserCreateVideoChatRoomToken'>
);

export type CreatorInBusinessQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CreatorInBusinessQuery = (
  { __typename?: 'Query' }
  & { CreatorInBusiness: (
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  ) }
);

export type CreatorMovieDeliveryProductUploadUrlQueryVariables = Exact<{
  ordered_product_id: Scalars['ID'];
}>;


export type CreatorMovieDeliveryProductUploadUrlQuery = (
  { __typename?: 'Query' }
  & { CreatorMovieDeliveryProductUploadUrl: (
    { __typename?: 'MovieProductUpload' }
    & Pick<MovieProductUpload, 'upload_url' | 'upload_file_path'>
  ) }
);

export type CreatorOrderedProductQueryVariables = Exact<{
  ordered_product_id: Scalars['ID'];
}>;


export type CreatorOrderedProductQuery = (
  { __typename?: 'Query' }
  & { CreatorOrderedProduct?: Maybe<(
    { __typename?: 'OrderedProduct' }
    & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivered_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ), order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ) }
    ), product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    ), latest_delivered_product?: Maybe<(
      { __typename?: 'DeliveredProduct' }
      & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type CreatorProductQueryVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type CreatorProductQuery = (
  { __typename?: 'Query' }
  & { CreatorProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
    & { creator?: Maybe<(
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    )>, live_talk?: Maybe<(
      { __typename?: 'ProductLiveTalk' }
      & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
    )> }
  ) }
);

export type CreatorPurchaseUserRankingQueryVariables = Exact<{ [key: string]: never; }>;


export type CreatorPurchaseUserRankingQuery = (
  { __typename?: 'Query' }
  & { CreatorPurchaseUserRanking: Array<(
    { __typename?: 'CreatorPurchaseUserRanking' }
    & Pick<CreatorPurchaseUserRanking, 'user_id' | 'user_name' | 'twitter_username' | 'amount' | 'purchase_count'>
  )> }
);

export type CreatorRankingOfDailySalesCountQueryVariables = Exact<{
  input: CreatorRankingInput;
}>;


export type CreatorRankingOfDailySalesCountQuery = (
  { __typename?: 'Query' }
  & { CreatorRankingOfDailySalesCount: Array<(
    { __typename?: 'CreatorRanking' }
    & Pick<CreatorRanking, 'rank'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ) }
  )> }
);

export type CreatorRankingOfMonthlySalesCountQueryVariables = Exact<{
  input: CreatorRankingInput;
}>;


export type CreatorRankingOfMonthlySalesCountQuery = (
  { __typename?: 'Query' }
  & { CreatorRankingOfMonthlySalesCount: Array<(
    { __typename?: 'CreatorRanking' }
    & Pick<CreatorRanking, 'rank'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ) }
  )> }
);

export type CreatorRankingOfWeeklySalesCountQueryVariables = Exact<{
  input: CreatorRankingInput;
}>;


export type CreatorRankingOfWeeklySalesCountQuery = (
  { __typename?: 'Query' }
  & { CreatorRankingOfWeeklySalesCount: Array<(
    { __typename?: 'CreatorRanking' }
    & Pick<CreatorRanking, 'rank'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ) }
  )> }
);

export type CreatorSaleThisMonthQueryVariables = Exact<{
  creator_id: Scalars['ID'];
}>;


export type CreatorSaleThisMonthQuery = (
  { __typename?: 'Query' }
  & { CreatorSaleThisMonth?: Maybe<(
    { __typename?: 'SalesPerMonth' }
    & Pick<SalesPerMonth, 'month' | 'count' | 'sales'>
  )> }
);

export type CreatorSalesPerMonthQueryVariables = Exact<{
  creator_id: Scalars['ID'];
}>;


export type CreatorSalesPerMonthQuery = (
  { __typename?: 'Query' }
  & { CreatorSalesPerMonth: (
    { __typename?: 'CreatorSalesPerMonth' }
    & Pick<CreatorSalesPerMonth, 'total_sales'>
    & { sales_per_month: Array<(
      { __typename?: 'SalesPerMonth' }
      & Pick<SalesPerMonth, 'month' | 'count' | 'sales'>
    )> }
  ) }
);

export type CreatorSalesPerMonthInYearQueryVariables = Exact<{
  input: CreatorSalesPerMonthInYearInput;
}>;


export type CreatorSalesPerMonthInYearQuery = (
  { __typename?: 'Query' }
  & { CreatorSalesPerMonthInYear: Array<(
    { __typename?: 'SalesPerMonth' }
    & Pick<SalesPerMonth, 'month' | 'count' | 'sales'>
  )> }
);

export type CreatorUndeliveredOrderedProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type CreatorUndeliveredOrderedProductsQuery = (
  { __typename?: 'Query' }
  & { CreatorUndeliveredOrderedProducts: Array<(
    { __typename?: 'OrderedProduct' }
    & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivered_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ), order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ) }
    ), product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    ), latest_delivered_product?: Maybe<(
      { __typename?: 'DeliveredProduct' }
      & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type CreatorsInAffiliationQueryVariables = Exact<{
  affiliation_id?: Maybe<Scalars['ID']>;
}>;


export type CreatorsInAffiliationQuery = (
  { __typename?: 'Query' }
  & { CreatorsInAffiliation: Array<(
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type CurrentBusinessUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentBusinessUserQuery = (
  { __typename?: 'Query' }
  & { CurrentBusinessUser?: Maybe<(
    { __typename?: 'BusinessUser' }
    & Pick<BusinessUser, 'id' | 'name' | 'email' | 'created_at' | 'updated_at'>
  )> }
);

export type CurrentCreatorQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentCreatorQuery = (
  { __typename?: 'Query' }
  & { CurrentCreator?: Maybe<(
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { CurrentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
  )> }
);

export type GenerateTemporaryUrlForFilteredMonthlySalesQueryVariables = Exact<{
  input: GenerateTemporaryUrlForFilteredMonthlySalesInput;
}>;


export type GenerateTemporaryUrlForFilteredMonthlySalesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GenerateTemporaryUrlForFilteredMonthlySales'>
);

export type GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQueryVariables = Exact<{
  input: GenerateTemporaryUrlForFilteredMonthlySalesByCreatorInput;
}>;


export type GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GenerateTemporaryUrlForFilteredMonthlySalesByCreator'>
);

export type MovieDeliveryProductUploadUrlQueryVariables = Exact<{
  ordered_product_id: Scalars['ID'];
}>;


export type MovieDeliveryProductUploadUrlQuery = (
  { __typename?: 'Query' }
  & { MovieDeliveryProductUploadUrl: (
    { __typename?: 'MovieProductUpload' }
    & Pick<MovieProductUpload, 'upload_url' | 'upload_file_path'>
  ) }
);

export type PagedActiveCreatorsQueryVariables = Exact<{
  input: ActiveCreatorsInput;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type PagedActiveCreatorsQuery = (
  { __typename?: 'Query' }
  & { PagedActiveCreators?: Maybe<(
    { __typename?: 'CreatorPaginator' }
    & { data: Array<(
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'perPage' | 'total'>
    ) }
  )> }
);

export type PagedAffiliationsInBusinessQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type PagedAffiliationsInBusinessQuery = (
  { __typename?: 'Query' }
  & { PagedAffiliationsInBusiness?: Maybe<(
    { __typename?: 'AffiliationPaginator' }
    & { data: Array<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedBusinessDeliveredOrderedProductPerUserQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  user_id: Scalars['ID'];
  creator_id: Scalars['ID'];
}>;


export type PagedBusinessDeliveredOrderedProductPerUserQuery = (
  { __typename?: 'Query' }
  & { PagedBusinessDeliveredOrderedProductPerUser?: Maybe<(
    { __typename?: 'OrderedProductPaginator' }
    & { data: Array<(
      { __typename?: 'OrderedProduct' }
      & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivered_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      ), order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
          & { creator?: Maybe<(
            { __typename?: 'Creator' }
            & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
            & { affiliation?: Maybe<(
              { __typename?: 'Affiliation' }
              & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
            )> }
          )>, live_talk?: Maybe<(
            { __typename?: 'ProductLiveTalk' }
            & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
          )> }
        ) }
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ), latest_delivered_product?: Maybe<(
        { __typename?: 'DeliveredProduct' }
        & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedBusinessDeliveredOrderedProductsQueryVariables = Exact<{
  creator_id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedBusinessDeliveredOrderedProductsQuery = (
  { __typename?: 'Query' }
  & { PagedBusinessDeliveredOrderedProducts?: Maybe<(
    { __typename?: 'OrderedProductPaginator' }
    & { data: Array<(
      { __typename?: 'OrderedProduct' }
      & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivered_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      ), order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
          & { creator?: Maybe<(
            { __typename?: 'Creator' }
            & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
            & { affiliation?: Maybe<(
              { __typename?: 'Affiliation' }
              & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
            )> }
          )>, live_talk?: Maybe<(
            { __typename?: 'ProductLiveTalk' }
            & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
          )> }
        ) }
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ), latest_delivered_product?: Maybe<(
        { __typename?: 'DeliveredProduct' }
        & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedBusinessDisabledProductsQueryVariables = Exact<{
  creator_id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedBusinessDisabledProductsQuery = (
  { __typename?: 'Query' }
  & { PagedBusinessDisabledProducts?: Maybe<(
    { __typename?: 'ProductPaginator' }
    & { data: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedBusinessOrderedUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  sort?: Maybe<Scalars['String']>;
  creator_id: Scalars['ID'];
}>;


export type PagedBusinessOrderedUsersQuery = (
  { __typename?: 'Query' }
  & { PagedBusinessOrderedUsers?: Maybe<(
    { __typename?: 'OrderedUserPaginator' }
    & { data: Array<(
      { __typename?: 'OrderedUser' }
      & Pick<OrderedUser, 'user_id' | 'user_name' | 'last_paid_at' | 'number_of_purchases'>
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedBusinessPublishedProductsQueryVariables = Exact<{
  creator_id: Scalars['ID'];
  exclude_product_id?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedBusinessPublishedProductsQuery = (
  { __typename?: 'Query' }
  & { PagedBusinessPublishedProducts?: Maybe<(
    { __typename?: 'ProductPaginator' }
    & { data: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedBusinessReservedProductsQueryVariables = Exact<{
  creator_id: Scalars['ID'];
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type PagedBusinessReservedProductsQuery = (
  { __typename?: 'Query' }
  & { PagedBusinessReservedProducts?: Maybe<(
    { __typename?: 'ProductPaginator' }
    & { data: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedBusinessUndeliveredOrderedProductPerUserQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  user_id: Scalars['ID'];
  creator_id: Scalars['ID'];
}>;


export type PagedBusinessUndeliveredOrderedProductPerUserQuery = (
  { __typename?: 'Query' }
  & { PagedBusinessUndeliveredOrderedProductPerUser?: Maybe<(
    { __typename?: 'OrderedProductPaginator' }
    & { data: Array<(
      { __typename?: 'OrderedProduct' }
      & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      ), order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
          & { creator?: Maybe<(
            { __typename?: 'Creator' }
            & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
            & { affiliation?: Maybe<(
              { __typename?: 'Affiliation' }
              & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
            )> }
          )>, live_talk?: Maybe<(
            { __typename?: 'ProductLiveTalk' }
            & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
          )> }
        ) }
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ) }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedCreatorDeliveredOrderedProductPerUserQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  user_id: Scalars['ID'];
}>;


export type PagedCreatorDeliveredOrderedProductPerUserQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorDeliveredOrderedProductPerUser?: Maybe<(
    { __typename?: 'OrderedProductPaginator' }
    & { data: Array<(
      { __typename?: 'OrderedProduct' }
      & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivered_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      ), order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
          & { creator?: Maybe<(
            { __typename?: 'Creator' }
            & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
            & { affiliation?: Maybe<(
              { __typename?: 'Affiliation' }
              & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
            )> }
          )>, live_talk?: Maybe<(
            { __typename?: 'ProductLiveTalk' }
            & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
          )> }
        ) }
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ), latest_delivered_product?: Maybe<(
        { __typename?: 'DeliveredProduct' }
        & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedCreatorDeliveredOrderedProductsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedCreatorDeliveredOrderedProductsQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorDeliveredOrderedProducts?: Maybe<(
    { __typename?: 'OrderedProductPaginator' }
    & { data: Array<(
      { __typename?: 'OrderedProduct' }
      & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivered_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      ), order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
          & { creator?: Maybe<(
            { __typename?: 'Creator' }
            & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
            & { affiliation?: Maybe<(
              { __typename?: 'Affiliation' }
              & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
            )> }
          )>, live_talk?: Maybe<(
            { __typename?: 'ProductLiveTalk' }
            & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
          )> }
        ) }
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ), latest_delivered_product?: Maybe<(
        { __typename?: 'DeliveredProduct' }
        & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedCreatorDisabledProductsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedCreatorDisabledProductsQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorDisabledProducts?: Maybe<(
    { __typename?: 'ProductPaginator' }
    & { data: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedCreatorOrderedUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  sort?: Maybe<Scalars['String']>;
}>;


export type PagedCreatorOrderedUsersQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorOrderedUsers?: Maybe<(
    { __typename?: 'OrderedUserPaginator' }
    & { data: Array<(
      { __typename?: 'OrderedUser' }
      & Pick<OrderedUser, 'user_id' | 'user_name' | 'last_paid_at' | 'number_of_purchases'>
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedCreatorPublishedProductsQueryVariables = Exact<{
  exclude_product_id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedCreatorPublishedProductsQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorPublishedProducts?: Maybe<(
    { __typename?: 'ProductPaginator' }
    & { data: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedCreatorReservedProductsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedCreatorReservedProductsQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorReservedProducts?: Maybe<(
    { __typename?: 'ProductPaginator' }
    & { data: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedCreatorSalesPerMonthBreakdownQueryVariables = Exact<{
  input: PagedCreatorSalesPerMonthBreakdownInput;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type PagedCreatorSalesPerMonthBreakdownQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorSalesPerMonthBreakdown?: Maybe<(
    { __typename?: 'SalesBreakdownPaginator' }
    & { data: Array<(
      { __typename?: 'SalesBreakdown' }
      & Pick<SalesBreakdown, 'delivered_at' | 'paid_at' | 'user_name' | 'product_name' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'started_at' | 'talk_minutes' | 'order_id' | 'sales'>
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'perPage' | 'total'>
    ) }
  )> }
);

export type PagedCreatorUndeliveredOrderedProductPerUserQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  user_id: Scalars['ID'];
}>;


export type PagedCreatorUndeliveredOrderedProductPerUserQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorUndeliveredOrderedProductPerUser?: Maybe<(
    { __typename?: 'OrderedProductPaginator' }
    & { data: Array<(
      { __typename?: 'OrderedProduct' }
      & Pick<OrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      ), order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
          & { creator?: Maybe<(
            { __typename?: 'Creator' }
            & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
            & { affiliation?: Maybe<(
              { __typename?: 'Affiliation' }
              & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
            )> }
          )>, live_talk?: Maybe<(
            { __typename?: 'ProductLiveTalk' }
            & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
          )> }
        ) }
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ) }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedCreatorsInBusinessQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type PagedCreatorsInBusinessQuery = (
  { __typename?: 'Query' }
  & { PagedCreatorsInBusiness?: Maybe<(
    { __typename?: 'CreatorPaginator' }
    & { data: Array<(
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'perPage' | 'total'>
    ) }
  )> }
);

export type PagedProductsOnSaleQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type PagedProductsOnSaleQuery = (
  { __typename?: 'Query' }
  & { PagedProductsOnSale?: Maybe<(
    { __typename?: 'PublishedProductPaginator' }
    & { data: Array<(
      { __typename?: 'PublishedProduct' }
      & Pick<PublishedProduct, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedPublishedProductsQueryVariables = Exact<{
  creator_id: Scalars['ID'];
  exclude_product_id?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedPublishedProductsQuery = (
  { __typename?: 'Query' }
  & { PagedPublishedProducts?: Maybe<(
    { __typename?: 'PublishedProductPaginator' }
    & { data: Array<(
      { __typename?: 'PublishedProduct' }
      & Pick<PublishedProduct, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedSalesPerOrderQueryVariables = Exact<{
  has_all_affiliation?: Maybe<Scalars['Boolean']>;
  affiliation_id?: Maybe<Scalars['ID']>;
  creator_id?: Maybe<Scalars['ID']>;
  input: SalesInput;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type PagedSalesPerOrderQuery = (
  { __typename?: 'Query' }
  & { PagedSalesPerOrder?: Maybe<(
    { __typename?: 'SalesPerOrderPaginator' }
    & { data: Array<(
      { __typename?: 'SalesPerOrder' }
      & Pick<SalesPerOrder, 'delivered_at' | 'paid_at' | 'user_name' | 'product_name' | 'creator_name' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'started_at' | 'talk_minutes' | 'order_id' | 'sales'>
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'perPage' | 'total'>
    ) }
  )> }
);

export type PagedUserActiveCreatorsFavoritedQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type PagedUserActiveCreatorsFavoritedQuery = (
  { __typename?: 'Query' }
  & { PagedUserActiveCreatorsFavorited?: Maybe<(
    { __typename?: 'CreatorPaginator' }
    & { data: Array<(
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'perPage' | 'total'>
    ) }
  )> }
);

export type PagedUserFavoritedCreatorLatestProductsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedUserFavoritedCreatorLatestProductsQuery = (
  { __typename?: 'Query' }
  & { PagedUserFavoritedCreatorLatestProducts?: Maybe<(
    { __typename?: 'PublishedProductPaginator' }
    & { data: Array<(
      { __typename?: 'PublishedProduct' }
      & Pick<PublishedProduct, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PagedUserPurchasedProductsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type PagedUserPurchasedProductsQuery = (
  { __typename?: 'Query' }
  & { PagedUserPurchasedProducts?: Maybe<(
    { __typename?: 'PublishedOrderedProductPaginator' }
    & { data: Array<(
      { __typename?: 'PublishedOrderedProduct' }
      & Pick<PublishedOrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      ), order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
          & { creator?: Maybe<(
            { __typename?: 'Creator' }
            & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
            & { affiliation?: Maybe<(
              { __typename?: 'Affiliation' }
              & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
            )> }
          )>, live_talk?: Maybe<(
            { __typename?: 'ProductLiveTalk' }
            & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
          )> }
        ) }
      ), product: (
        { __typename?: 'PublishedProduct' }
        & Pick<PublishedProduct, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ), latest_delivered_product?: Maybe<(
        { __typename?: 'DeliveredProduct' }
        & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'delivered_at' | 'created_at' | 'updated_at'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'total' | 'perPage'>
    ) }
  )> }
);

export type PublishedProductQueryVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type PublishedProductQuery = (
  { __typename?: 'Query' }
  & { PublishedProduct: (
    { __typename?: 'PublishedProduct' }
    & Pick<PublishedProduct, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'is_movie' | 'is_photo' | 'is_live_talk'>
    & { creator?: Maybe<(
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    )>, live_talk?: Maybe<(
      { __typename?: 'ProductLiveTalk' }
      & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
    )> }
  ) }
);

export type RecommendedCreatorsQueryVariables = Exact<{
  input: RecommendedCreatorsInput;
}>;


export type RecommendedCreatorsQuery = (
  { __typename?: 'Query' }
  & { RecommendedCreators: Array<(
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type RequestCreatorQueryVariables = Exact<{
  creator_id: Scalars['ID'];
  input: RequestCreatorInput;
}>;


export type RequestCreatorQuery = (
  { __typename?: 'Query' }
  & { RequestCreator: (
    { __typename?: 'RequestCreator' }
    & Pick<RequestCreator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'email' | 'status_name'>
    & { affiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
    )> }
  ) }
);

export type CreatorCreateVideoChatRoomTokenQueryVariables = Exact<{
  product_id: Scalars['ID'];
}>;


export type CreatorCreateVideoChatRoomTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreatorCreateVideoChatRoomToken'>
);

export type UserPurchasedProductQueryVariables = Exact<{
  ordered_product_id: Scalars['ID'];
}>;


export type UserPurchasedProductQuery = (
  { __typename?: 'Query' }
  & { UserPurchasedProduct: (
    { __typename?: 'PublishedOrderedProduct' }
    & Pick<PublishedOrderedProduct, 'id' | 'status' | 'live_talk_status' | 'creator_id' | 'order_id' | 'product_id' | 'is_sent_reminder_delivery_1day_ago_mail' | 'is_sent_reminder_delivery_1hour_ago_mail' | 'is_sent_reminder_delivery_after_mail' | 'created_at' | 'updated_at' | 'delivery_limit_date' | 'is_delivered' | 'is_processing' | 'is_undelivered'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
      & { affiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
      )> }
    ), order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'delivered_at' | 'paid_at' | 'movie_seconds' | 'status'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'status' | 'name' | 'email' | 'twitter_username' | 'created_at' | 'updated_at'>
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_movie' | 'is_photo' | 'is_live_talk'>
        & { creator?: Maybe<(
          { __typename?: 'Creator' }
          & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
          & { affiliation?: Maybe<(
            { __typename?: 'Affiliation' }
            & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
          )> }
        )>, live_talk?: Maybe<(
          { __typename?: 'ProductLiveTalk' }
          & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
        )> }
      ) }
    ), product: (
      { __typename?: 'PublishedProduct' }
      & Pick<PublishedProduct, 'id' | 'name' | 'creator_id' | 'status' | 'price' | 'special_price' | 'stock' | 'remaining_stock' | 'sold_outed' | 'thumbnail_image' | 'released_at' | 'product_type' | 'product_type_name' | 'movie_seconds' | 'is_movie' | 'is_photo' | 'is_live_talk'>
      & { creator?: Maybe<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'business_id' | 'affiliation_id' | 'status' | 'icon_url' | 'name' | 'email' | 'profile' | 'twitter_username' | 'instagram_username' | 'youtube_channel_url' | 'kindle_author_url' | 'left_at' | 'created_at' | 'updated_at' | 'status_name' | 'is_favorite' | 'favorite_users_count'>
        & { affiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'name' | 'creators_count' | 'created_at' | 'updated_at'>
        )> }
      )>, live_talk?: Maybe<(
        { __typename?: 'ProductLiveTalk' }
        & Pick<ProductLiveTalk, 'product_id' | 'started_at' | 'talk_minutes'>
      )> }
    ), latest_delivered_product?: Maybe<(
      { __typename?: 'DeliveredProduct' }
      & Pick<DeliveredProduct, 'id' | 'ordered_product_id' | 'delivered_product_url' | 'delivered_product_thumbnail_url' | 'temporary_download_url' | 'delivered_at' | 'created_at' | 'updated_at'>
    )> }
  ) }
);

export type ValidateBusinessUserTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateBusinessUserTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ValidateBusinessUserToken'>
);

export type ValidateCreatorTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateCreatorTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ValidateCreatorToken'>
);

export type ValidateUserTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateUserTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ValidateUserToken'>
);


export const BusinessResendCreatorAccountUpdateDocument = gql`
    mutation BusinessResendCreatorAccountUpdate($creator_id: ID!, $input: BusinessResendCreatorAccountUpdateInput!) {
  BusinessResendCreatorAccountUpdate(creator_id: $creator_id, input: $input)
}
    `;
export type BusinessResendCreatorAccountUpdateMutationFn = Apollo.MutationFunction<BusinessResendCreatorAccountUpdateMutation, BusinessResendCreatorAccountUpdateMutationVariables>;

/**
 * __useBusinessResendCreatorAccountUpdateMutation__
 *
 * To run a mutation, you first call `useBusinessResendCreatorAccountUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessResendCreatorAccountUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessResendCreatorAccountUpdateMutation, { data, loading, error }] = useBusinessResendCreatorAccountUpdateMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessResendCreatorAccountUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BusinessResendCreatorAccountUpdateMutation, BusinessResendCreatorAccountUpdateMutationVariables>) {
        return Apollo.useMutation<BusinessResendCreatorAccountUpdateMutation, BusinessResendCreatorAccountUpdateMutationVariables>(BusinessResendCreatorAccountUpdateDocument, baseOptions);
      }
export type BusinessResendCreatorAccountUpdateMutationHookResult = ReturnType<typeof useBusinessResendCreatorAccountUpdateMutation>;
export type BusinessResendCreatorAccountUpdateMutationResult = Apollo.MutationResult<BusinessResendCreatorAccountUpdateMutation>;
export type BusinessResendCreatorAccountUpdateMutationOptions = Apollo.BaseMutationOptions<BusinessResendCreatorAccountUpdateMutation, BusinessResendCreatorAccountUpdateMutationVariables>;
export const CreatorDeliveryLiveTalkProductDocument = gql`
    mutation CreatorDeliveryLiveTalkProduct($ordered_product_id: ID!) {
  CreatorDeliveryLiveTalkProduct(ordered_product_id: $ordered_product_id)
}
    `;
export type CreatorDeliveryLiveTalkProductMutationFn = Apollo.MutationFunction<CreatorDeliveryLiveTalkProductMutation, CreatorDeliveryLiveTalkProductMutationVariables>;

/**
 * __useCreatorDeliveryLiveTalkProductMutation__
 *
 * To run a mutation, you first call `useCreatorDeliveryLiveTalkProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorDeliveryLiveTalkProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorDeliveryLiveTalkProductMutation, { data, loading, error }] = useCreatorDeliveryLiveTalkProductMutation({
 *   variables: {
 *      ordered_product_id: // value for 'ordered_product_id'
 *   },
 * });
 */
export function useCreatorDeliveryLiveTalkProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorDeliveryLiveTalkProductMutation, CreatorDeliveryLiveTalkProductMutationVariables>) {
        return Apollo.useMutation<CreatorDeliveryLiveTalkProductMutation, CreatorDeliveryLiveTalkProductMutationVariables>(CreatorDeliveryLiveTalkProductDocument, baseOptions);
      }
export type CreatorDeliveryLiveTalkProductMutationHookResult = ReturnType<typeof useCreatorDeliveryLiveTalkProductMutation>;
export type CreatorDeliveryLiveTalkProductMutationResult = Apollo.MutationResult<CreatorDeliveryLiveTalkProductMutation>;
export type CreatorDeliveryLiveTalkProductMutationOptions = Apollo.BaseMutationOptions<CreatorDeliveryLiveTalkProductMutation, CreatorDeliveryLiveTalkProductMutationVariables>;
export const CreatorDeliveryMovieProductDocument = gql`
    mutation CreatorDeliveryMovieProduct($input: DeliveryMovieProductInput!) {
  CreatorDeliveryMovieProduct(input: $input)
}
    `;
export type CreatorDeliveryMovieProductMutationFn = Apollo.MutationFunction<CreatorDeliveryMovieProductMutation, CreatorDeliveryMovieProductMutationVariables>;

/**
 * __useCreatorDeliveryMovieProductMutation__
 *
 * To run a mutation, you first call `useCreatorDeliveryMovieProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorDeliveryMovieProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorDeliveryMovieProductMutation, { data, loading, error }] = useCreatorDeliveryMovieProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorDeliveryMovieProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorDeliveryMovieProductMutation, CreatorDeliveryMovieProductMutationVariables>) {
        return Apollo.useMutation<CreatorDeliveryMovieProductMutation, CreatorDeliveryMovieProductMutationVariables>(CreatorDeliveryMovieProductDocument, baseOptions);
      }
export type CreatorDeliveryMovieProductMutationHookResult = ReturnType<typeof useCreatorDeliveryMovieProductMutation>;
export type CreatorDeliveryMovieProductMutationResult = Apollo.MutationResult<CreatorDeliveryMovieProductMutation>;
export type CreatorDeliveryMovieProductMutationOptions = Apollo.BaseMutationOptions<CreatorDeliveryMovieProductMutation, CreatorDeliveryMovieProductMutationVariables>;
export const CreatorDeliveryPhotoProductDocument = gql`
    mutation CreatorDeliveryPhotoProduct($input: DeliveryPhotoProductInput!) {
  CreatorDeliveryPhotoProduct(input: $input)
}
    `;
export type CreatorDeliveryPhotoProductMutationFn = Apollo.MutationFunction<CreatorDeliveryPhotoProductMutation, CreatorDeliveryPhotoProductMutationVariables>;

/**
 * __useCreatorDeliveryPhotoProductMutation__
 *
 * To run a mutation, you first call `useCreatorDeliveryPhotoProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorDeliveryPhotoProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorDeliveryPhotoProductMutation, { data, loading, error }] = useCreatorDeliveryPhotoProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorDeliveryPhotoProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorDeliveryPhotoProductMutation, CreatorDeliveryPhotoProductMutationVariables>) {
        return Apollo.useMutation<CreatorDeliveryPhotoProductMutation, CreatorDeliveryPhotoProductMutationVariables>(CreatorDeliveryPhotoProductDocument, baseOptions);
      }
export type CreatorDeliveryPhotoProductMutationHookResult = ReturnType<typeof useCreatorDeliveryPhotoProductMutation>;
export type CreatorDeliveryPhotoProductMutationResult = Apollo.MutationResult<CreatorDeliveryPhotoProductMutation>;
export type CreatorDeliveryPhotoProductMutationOptions = Apollo.BaseMutationOptions<CreatorDeliveryPhotoProductMutation, CreatorDeliveryPhotoProductMutationVariables>;
export const CreatorDisableProductDocument = gql`
    mutation CreatorDisableProduct($product_id: ID!) {
  CreatorDisableProduct(product_id: $product_id)
}
    `;
export type CreatorDisableProductMutationFn = Apollo.MutationFunction<CreatorDisableProductMutation, CreatorDisableProductMutationVariables>;

/**
 * __useCreatorDisableProductMutation__
 *
 * To run a mutation, you first call `useCreatorDisableProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorDisableProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorDisableProductMutation, { data, loading, error }] = useCreatorDisableProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useCreatorDisableProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorDisableProductMutation, CreatorDisableProductMutationVariables>) {
        return Apollo.useMutation<CreatorDisableProductMutation, CreatorDisableProductMutationVariables>(CreatorDisableProductDocument, baseOptions);
      }
export type CreatorDisableProductMutationHookResult = ReturnType<typeof useCreatorDisableProductMutation>;
export type CreatorDisableProductMutationResult = Apollo.MutationResult<CreatorDisableProductMutation>;
export type CreatorDisableProductMutationOptions = Apollo.BaseMutationOptions<CreatorDisableProductMutation, CreatorDisableProductMutationVariables>;
export const CreatorDisplayProductDocument = gql`
    mutation CreatorDisplayProduct($product_id: ID!) {
  CreatorDisplayProduct(product_id: $product_id)
}
    `;
export type CreatorDisplayProductMutationFn = Apollo.MutationFunction<CreatorDisplayProductMutation, CreatorDisplayProductMutationVariables>;

/**
 * __useCreatorDisplayProductMutation__
 *
 * To run a mutation, you first call `useCreatorDisplayProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorDisplayProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorDisplayProductMutation, { data, loading, error }] = useCreatorDisplayProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useCreatorDisplayProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorDisplayProductMutation, CreatorDisplayProductMutationVariables>) {
        return Apollo.useMutation<CreatorDisplayProductMutation, CreatorDisplayProductMutationVariables>(CreatorDisplayProductDocument, baseOptions);
      }
export type CreatorDisplayProductMutationHookResult = ReturnType<typeof useCreatorDisplayProductMutation>;
export type CreatorDisplayProductMutationResult = Apollo.MutationResult<CreatorDisplayProductMutation>;
export type CreatorDisplayProductMutationOptions = Apollo.BaseMutationOptions<CreatorDisplayProductMutation, CreatorDisplayProductMutationVariables>;
export const CreatorFinishProductDocument = gql`
    mutation CreatorFinishProduct($product_id: ID!) {
  CreatorFinishProduct(product_id: $product_id)
}
    `;
export type CreatorFinishProductMutationFn = Apollo.MutationFunction<CreatorFinishProductMutation, CreatorFinishProductMutationVariables>;

/**
 * __useCreatorFinishProductMutation__
 *
 * To run a mutation, you first call `useCreatorFinishProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorFinishProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorFinishProductMutation, { data, loading, error }] = useCreatorFinishProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useCreatorFinishProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorFinishProductMutation, CreatorFinishProductMutationVariables>) {
        return Apollo.useMutation<CreatorFinishProductMutation, CreatorFinishProductMutationVariables>(CreatorFinishProductDocument, baseOptions);
      }
export type CreatorFinishProductMutationHookResult = ReturnType<typeof useCreatorFinishProductMutation>;
export type CreatorFinishProductMutationResult = Apollo.MutationResult<CreatorFinishProductMutation>;
export type CreatorFinishProductMutationOptions = Apollo.BaseMutationOptions<CreatorFinishProductMutation, CreatorFinishProductMutationVariables>;
export const CreatorPasswordResetDocument = gql`
    mutation CreatorPasswordReset($input: PasswordResetInput!) {
  CreatorPasswordReset(input: $input)
}
    `;
export type CreatorPasswordResetMutationFn = Apollo.MutationFunction<CreatorPasswordResetMutation, CreatorPasswordResetMutationVariables>;

/**
 * __useCreatorPasswordResetMutation__
 *
 * To run a mutation, you first call `useCreatorPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorPasswordResetMutation, { data, loading, error }] = useCreatorPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<CreatorPasswordResetMutation, CreatorPasswordResetMutationVariables>) {
        return Apollo.useMutation<CreatorPasswordResetMutation, CreatorPasswordResetMutationVariables>(CreatorPasswordResetDocument, baseOptions);
      }
export type CreatorPasswordResetMutationHookResult = ReturnType<typeof useCreatorPasswordResetMutation>;
export type CreatorPasswordResetMutationResult = Apollo.MutationResult<CreatorPasswordResetMutation>;
export type CreatorPasswordResetMutationOptions = Apollo.BaseMutationOptions<CreatorPasswordResetMutation, CreatorPasswordResetMutationVariables>;
export const CreatorRegisterLiveTalkProductDocument = gql`
    mutation CreatorRegisterLiveTalkProduct($input: RegisterLiveTalkProductInput!) {
  CreatorRegisterLiveTalkProduct(input: $input)
}
    `;
export type CreatorRegisterLiveTalkProductMutationFn = Apollo.MutationFunction<CreatorRegisterLiveTalkProductMutation, CreatorRegisterLiveTalkProductMutationVariables>;

/**
 * __useCreatorRegisterLiveTalkProductMutation__
 *
 * To run a mutation, you first call `useCreatorRegisterLiveTalkProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorRegisterLiveTalkProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorRegisterLiveTalkProductMutation, { data, loading, error }] = useCreatorRegisterLiveTalkProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorRegisterLiveTalkProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorRegisterLiveTalkProductMutation, CreatorRegisterLiveTalkProductMutationVariables>) {
        return Apollo.useMutation<CreatorRegisterLiveTalkProductMutation, CreatorRegisterLiveTalkProductMutationVariables>(CreatorRegisterLiveTalkProductDocument, baseOptions);
      }
export type CreatorRegisterLiveTalkProductMutationHookResult = ReturnType<typeof useCreatorRegisterLiveTalkProductMutation>;
export type CreatorRegisterLiveTalkProductMutationResult = Apollo.MutationResult<CreatorRegisterLiveTalkProductMutation>;
export type CreatorRegisterLiveTalkProductMutationOptions = Apollo.BaseMutationOptions<CreatorRegisterLiveTalkProductMutation, CreatorRegisterLiveTalkProductMutationVariables>;
export const CreatorRegisterMovieProductDocument = gql`
    mutation CreatorRegisterMovieProduct($input: RegisterMovieProductInput!) {
  CreatorRegisterMovieProduct(input: $input)
}
    `;
export type CreatorRegisterMovieProductMutationFn = Apollo.MutationFunction<CreatorRegisterMovieProductMutation, CreatorRegisterMovieProductMutationVariables>;

/**
 * __useCreatorRegisterMovieProductMutation__
 *
 * To run a mutation, you first call `useCreatorRegisterMovieProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorRegisterMovieProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorRegisterMovieProductMutation, { data, loading, error }] = useCreatorRegisterMovieProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorRegisterMovieProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorRegisterMovieProductMutation, CreatorRegisterMovieProductMutationVariables>) {
        return Apollo.useMutation<CreatorRegisterMovieProductMutation, CreatorRegisterMovieProductMutationVariables>(CreatorRegisterMovieProductDocument, baseOptions);
      }
export type CreatorRegisterMovieProductMutationHookResult = ReturnType<typeof useCreatorRegisterMovieProductMutation>;
export type CreatorRegisterMovieProductMutationResult = Apollo.MutationResult<CreatorRegisterMovieProductMutation>;
export type CreatorRegisterMovieProductMutationOptions = Apollo.BaseMutationOptions<CreatorRegisterMovieProductMutation, CreatorRegisterMovieProductMutationVariables>;
export const CreatorRegisterPhotoProductDocument = gql`
    mutation CreatorRegisterPhotoProduct($input: RegisterPhotoProductInput!) {
  CreatorRegisterPhotoProduct(input: $input)
}
    `;
export type CreatorRegisterPhotoProductMutationFn = Apollo.MutationFunction<CreatorRegisterPhotoProductMutation, CreatorRegisterPhotoProductMutationVariables>;

/**
 * __useCreatorRegisterPhotoProductMutation__
 *
 * To run a mutation, you first call `useCreatorRegisterPhotoProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorRegisterPhotoProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorRegisterPhotoProductMutation, { data, loading, error }] = useCreatorRegisterPhotoProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorRegisterPhotoProductMutation(baseOptions?: Apollo.MutationHookOptions<CreatorRegisterPhotoProductMutation, CreatorRegisterPhotoProductMutationVariables>) {
        return Apollo.useMutation<CreatorRegisterPhotoProductMutation, CreatorRegisterPhotoProductMutationVariables>(CreatorRegisterPhotoProductDocument, baseOptions);
      }
export type CreatorRegisterPhotoProductMutationHookResult = ReturnType<typeof useCreatorRegisterPhotoProductMutation>;
export type CreatorRegisterPhotoProductMutationResult = Apollo.MutationResult<CreatorRegisterPhotoProductMutation>;
export type CreatorRegisterPhotoProductMutationOptions = Apollo.BaseMutationOptions<CreatorRegisterPhotoProductMutation, CreatorRegisterPhotoProductMutationVariables>;
export const CreatorUpdatePasswordDocument = gql`
    mutation CreatorUpdatePassword($creator_id: ID!, $input: UpdatePasswordInput!) {
  CreatorUpdatePassword(creator_id: $creator_id, input: $input)
}
    `;
export type CreatorUpdatePasswordMutationFn = Apollo.MutationFunction<CreatorUpdatePasswordMutation, CreatorUpdatePasswordMutationVariables>;

/**
 * __useCreatorUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatorUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatorUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatorUpdatePasswordMutation, { data, loading, error }] = useCreatorUpdatePasswordMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<CreatorUpdatePasswordMutation, CreatorUpdatePasswordMutationVariables>) {
        return Apollo.useMutation<CreatorUpdatePasswordMutation, CreatorUpdatePasswordMutationVariables>(CreatorUpdatePasswordDocument, baseOptions);
      }
export type CreatorUpdatePasswordMutationHookResult = ReturnType<typeof useCreatorUpdatePasswordMutation>;
export type CreatorUpdatePasswordMutationResult = Apollo.MutationResult<CreatorUpdatePasswordMutation>;
export type CreatorUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<CreatorUpdatePasswordMutation, CreatorUpdatePasswordMutationVariables>;
export const CurrentCreatorUpdateAccountDocument = gql`
    mutation CurrentCreatorUpdateAccount($creator_id: ID!, $input: CurrentCreatorUpdateAccountInput!) {
  CurrentCreatorUpdateAccount(creator_id: $creator_id, input: $input) {
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
    }
    is_change_request_email
    change_request_email
  }
}
    `;
export type CurrentCreatorUpdateAccountMutationFn = Apollo.MutationFunction<CurrentCreatorUpdateAccountMutation, CurrentCreatorUpdateAccountMutationVariables>;

/**
 * __useCurrentCreatorUpdateAccountMutation__
 *
 * To run a mutation, you first call `useCurrentCreatorUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCurrentCreatorUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [currentCreatorUpdateAccountMutation, { data, loading, error }] = useCurrentCreatorUpdateAccountMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCurrentCreatorUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CurrentCreatorUpdateAccountMutation, CurrentCreatorUpdateAccountMutationVariables>) {
        return Apollo.useMutation<CurrentCreatorUpdateAccountMutation, CurrentCreatorUpdateAccountMutationVariables>(CurrentCreatorUpdateAccountDocument, baseOptions);
      }
export type CurrentCreatorUpdateAccountMutationHookResult = ReturnType<typeof useCurrentCreatorUpdateAccountMutation>;
export type CurrentCreatorUpdateAccountMutationResult = Apollo.MutationResult<CurrentCreatorUpdateAccountMutation>;
export type CurrentCreatorUpdateAccountMutationOptions = Apollo.BaseMutationOptions<CurrentCreatorUpdateAccountMutation, CurrentCreatorUpdateAccountMutationVariables>;
export const DeleteCreatorAccountDocument = gql`
    mutation DeleteCreatorAccount($creator_id: ID!) {
  DeleteCreatorAccount(creator_id: $creator_id)
}
    `;
export type DeleteCreatorAccountMutationFn = Apollo.MutationFunction<DeleteCreatorAccountMutation, DeleteCreatorAccountMutationVariables>;

/**
 * __useDeleteCreatorAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCreatorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreatorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreatorAccountMutation, { data, loading, error }] = useDeleteCreatorAccountMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useDeleteCreatorAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCreatorAccountMutation, DeleteCreatorAccountMutationVariables>) {
        return Apollo.useMutation<DeleteCreatorAccountMutation, DeleteCreatorAccountMutationVariables>(DeleteCreatorAccountDocument, baseOptions);
      }
export type DeleteCreatorAccountMutationHookResult = ReturnType<typeof useDeleteCreatorAccountMutation>;
export type DeleteCreatorAccountMutationResult = Apollo.MutationResult<DeleteCreatorAccountMutation>;
export type DeleteCreatorAccountMutationOptions = Apollo.BaseMutationOptions<DeleteCreatorAccountMutation, DeleteCreatorAccountMutationVariables>;
export const DeliveryLiveTalkProductByBusinessUserDocument = gql`
    mutation DeliveryLiveTalkProductByBusinessUser($ordered_product_id: ID!) {
  DeliveryLiveTalkProductByBusinessUser(ordered_product_id: $ordered_product_id)
}
    `;
export type DeliveryLiveTalkProductByBusinessUserMutationFn = Apollo.MutationFunction<DeliveryLiveTalkProductByBusinessUserMutation, DeliveryLiveTalkProductByBusinessUserMutationVariables>;

/**
 * __useDeliveryLiveTalkProductByBusinessUserMutation__
 *
 * To run a mutation, you first call `useDeliveryLiveTalkProductByBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryLiveTalkProductByBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryLiveTalkProductByBusinessUserMutation, { data, loading, error }] = useDeliveryLiveTalkProductByBusinessUserMutation({
 *   variables: {
 *      ordered_product_id: // value for 'ordered_product_id'
 *   },
 * });
 */
export function useDeliveryLiveTalkProductByBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<DeliveryLiveTalkProductByBusinessUserMutation, DeliveryLiveTalkProductByBusinessUserMutationVariables>) {
        return Apollo.useMutation<DeliveryLiveTalkProductByBusinessUserMutation, DeliveryLiveTalkProductByBusinessUserMutationVariables>(DeliveryLiveTalkProductByBusinessUserDocument, baseOptions);
      }
export type DeliveryLiveTalkProductByBusinessUserMutationHookResult = ReturnType<typeof useDeliveryLiveTalkProductByBusinessUserMutation>;
export type DeliveryLiveTalkProductByBusinessUserMutationResult = Apollo.MutationResult<DeliveryLiveTalkProductByBusinessUserMutation>;
export type DeliveryLiveTalkProductByBusinessUserMutationOptions = Apollo.BaseMutationOptions<DeliveryLiveTalkProductByBusinessUserMutation, DeliveryLiveTalkProductByBusinessUserMutationVariables>;
export const DeliveryMovieProductByBusinessUserDocument = gql`
    mutation DeliveryMovieProductByBusinessUser($input: DeliveryMovieProductInput!) {
  DeliveryMovieProductByBusinessUser(input: $input)
}
    `;
export type DeliveryMovieProductByBusinessUserMutationFn = Apollo.MutationFunction<DeliveryMovieProductByBusinessUserMutation, DeliveryMovieProductByBusinessUserMutationVariables>;

/**
 * __useDeliveryMovieProductByBusinessUserMutation__
 *
 * To run a mutation, you first call `useDeliveryMovieProductByBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryMovieProductByBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryMovieProductByBusinessUserMutation, { data, loading, error }] = useDeliveryMovieProductByBusinessUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliveryMovieProductByBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<DeliveryMovieProductByBusinessUserMutation, DeliveryMovieProductByBusinessUserMutationVariables>) {
        return Apollo.useMutation<DeliveryMovieProductByBusinessUserMutation, DeliveryMovieProductByBusinessUserMutationVariables>(DeliveryMovieProductByBusinessUserDocument, baseOptions);
      }
export type DeliveryMovieProductByBusinessUserMutationHookResult = ReturnType<typeof useDeliveryMovieProductByBusinessUserMutation>;
export type DeliveryMovieProductByBusinessUserMutationResult = Apollo.MutationResult<DeliveryMovieProductByBusinessUserMutation>;
export type DeliveryMovieProductByBusinessUserMutationOptions = Apollo.BaseMutationOptions<DeliveryMovieProductByBusinessUserMutation, DeliveryMovieProductByBusinessUserMutationVariables>;
export const DeliveryPhotoProductByBusinessUserDocument = gql`
    mutation DeliveryPhotoProductByBusinessUser($input: DeliveryPhotoProductInput!) {
  DeliveryPhotoProductByBusinessUser(input: $input)
}
    `;
export type DeliveryPhotoProductByBusinessUserMutationFn = Apollo.MutationFunction<DeliveryPhotoProductByBusinessUserMutation, DeliveryPhotoProductByBusinessUserMutationVariables>;

/**
 * __useDeliveryPhotoProductByBusinessUserMutation__
 *
 * To run a mutation, you first call `useDeliveryPhotoProductByBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryPhotoProductByBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryPhotoProductByBusinessUserMutation, { data, loading, error }] = useDeliveryPhotoProductByBusinessUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliveryPhotoProductByBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<DeliveryPhotoProductByBusinessUserMutation, DeliveryPhotoProductByBusinessUserMutationVariables>) {
        return Apollo.useMutation<DeliveryPhotoProductByBusinessUserMutation, DeliveryPhotoProductByBusinessUserMutationVariables>(DeliveryPhotoProductByBusinessUserDocument, baseOptions);
      }
export type DeliveryPhotoProductByBusinessUserMutationHookResult = ReturnType<typeof useDeliveryPhotoProductByBusinessUserMutation>;
export type DeliveryPhotoProductByBusinessUserMutationResult = Apollo.MutationResult<DeliveryPhotoProductByBusinessUserMutation>;
export type DeliveryPhotoProductByBusinessUserMutationOptions = Apollo.BaseMutationOptions<DeliveryPhotoProductByBusinessUserMutation, DeliveryPhotoProductByBusinessUserMutationVariables>;
export const DisableProductDocument = gql`
    mutation DisableProduct($product_id: ID!) {
  DisableProduct(product_id: $product_id)
}
    `;
export type DisableProductMutationFn = Apollo.MutationFunction<DisableProductMutation, DisableProductMutationVariables>;

/**
 * __useDisableProductMutation__
 *
 * To run a mutation, you first call `useDisableProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableProductMutation, { data, loading, error }] = useDisableProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useDisableProductMutation(baseOptions?: Apollo.MutationHookOptions<DisableProductMutation, DisableProductMutationVariables>) {
        return Apollo.useMutation<DisableProductMutation, DisableProductMutationVariables>(DisableProductDocument, baseOptions);
      }
export type DisableProductMutationHookResult = ReturnType<typeof useDisableProductMutation>;
export type DisableProductMutationResult = Apollo.MutationResult<DisableProductMutation>;
export type DisableProductMutationOptions = Apollo.BaseMutationOptions<DisableProductMutation, DisableProductMutationVariables>;
export const DisplayProductDocument = gql`
    mutation DisplayProduct($product_id: ID!) {
  DisplayProduct(product_id: $product_id)
}
    `;
export type DisplayProductMutationFn = Apollo.MutationFunction<DisplayProductMutation, DisplayProductMutationVariables>;

/**
 * __useDisplayProductMutation__
 *
 * To run a mutation, you first call `useDisplayProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisplayProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [displayProductMutation, { data, loading, error }] = useDisplayProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useDisplayProductMutation(baseOptions?: Apollo.MutationHookOptions<DisplayProductMutation, DisplayProductMutationVariables>) {
        return Apollo.useMutation<DisplayProductMutation, DisplayProductMutationVariables>(DisplayProductDocument, baseOptions);
      }
export type DisplayProductMutationHookResult = ReturnType<typeof useDisplayProductMutation>;
export type DisplayProductMutationResult = Apollo.MutationResult<DisplayProductMutation>;
export type DisplayProductMutationOptions = Apollo.BaseMutationOptions<DisplayProductMutation, DisplayProductMutationVariables>;
export const EditAffiliationDocument = gql`
    mutation EditAffiliation($affiliation_id: ID!, $input: EditAffiliationInput!) {
  EditAffiliation(affiliation_id: $affiliation_id, input: $input) {
    id
    name
    creators_count
    created_at
    updated_at
  }
}
    `;
export type EditAffiliationMutationFn = Apollo.MutationFunction<EditAffiliationMutation, EditAffiliationMutationVariables>;

/**
 * __useEditAffiliationMutation__
 *
 * To run a mutation, you first call `useEditAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAffiliationMutation, { data, loading, error }] = useEditAffiliationMutation({
 *   variables: {
 *      affiliation_id: // value for 'affiliation_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<EditAffiliationMutation, EditAffiliationMutationVariables>) {
        return Apollo.useMutation<EditAffiliationMutation, EditAffiliationMutationVariables>(EditAffiliationDocument, baseOptions);
      }
export type EditAffiliationMutationHookResult = ReturnType<typeof useEditAffiliationMutation>;
export type EditAffiliationMutationResult = Apollo.MutationResult<EditAffiliationMutation>;
export type EditAffiliationMutationOptions = Apollo.BaseMutationOptions<EditAffiliationMutation, EditAffiliationMutationVariables>;
export const FavoriteCreatorDocument = gql`
    mutation FavoriteCreator($creator_id: ID!) {
  FavoriteCreator(creator_id: $creator_id)
}
    `;
export type FavoriteCreatorMutationFn = Apollo.MutationFunction<FavoriteCreatorMutation, FavoriteCreatorMutationVariables>;

/**
 * __useFavoriteCreatorMutation__
 *
 * To run a mutation, you first call `useFavoriteCreatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteCreatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteCreatorMutation, { data, loading, error }] = useFavoriteCreatorMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useFavoriteCreatorMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteCreatorMutation, FavoriteCreatorMutationVariables>) {
        return Apollo.useMutation<FavoriteCreatorMutation, FavoriteCreatorMutationVariables>(FavoriteCreatorDocument, baseOptions);
      }
export type FavoriteCreatorMutationHookResult = ReturnType<typeof useFavoriteCreatorMutation>;
export type FavoriteCreatorMutationResult = Apollo.MutationResult<FavoriteCreatorMutation>;
export type FavoriteCreatorMutationOptions = Apollo.BaseMutationOptions<FavoriteCreatorMutation, FavoriteCreatorMutationVariables>;
export const FinishProductDocument = gql`
    mutation FinishProduct($product_id: ID!) {
  FinishProduct(product_id: $product_id)
}
    `;
export type FinishProductMutationFn = Apollo.MutationFunction<FinishProductMutation, FinishProductMutationVariables>;

/**
 * __useFinishProductMutation__
 *
 * To run a mutation, you first call `useFinishProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishProductMutation, { data, loading, error }] = useFinishProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useFinishProductMutation(baseOptions?: Apollo.MutationHookOptions<FinishProductMutation, FinishProductMutationVariables>) {
        return Apollo.useMutation<FinishProductMutation, FinishProductMutationVariables>(FinishProductDocument, baseOptions);
      }
export type FinishProductMutationHookResult = ReturnType<typeof useFinishProductMutation>;
export type FinishProductMutationResult = Apollo.MutationResult<FinishProductMutation>;
export type FinishProductMutationOptions = Apollo.BaseMutationOptions<FinishProductMutation, FinishProductMutationVariables>;
export const LoginBusinessUserDocument = gql`
    mutation LoginBusinessUser($input: LoginInput!) {
  LoginBusinessUser(input: $input) {
    token
    type
    ttl
  }
}
    `;
export type LoginBusinessUserMutationFn = Apollo.MutationFunction<LoginBusinessUserMutation, LoginBusinessUserMutationVariables>;

/**
 * __useLoginBusinessUserMutation__
 *
 * To run a mutation, you first call `useLoginBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginBusinessUserMutation, { data, loading, error }] = useLoginBusinessUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginBusinessUserMutation, LoginBusinessUserMutationVariables>) {
        return Apollo.useMutation<LoginBusinessUserMutation, LoginBusinessUserMutationVariables>(LoginBusinessUserDocument, baseOptions);
      }
export type LoginBusinessUserMutationHookResult = ReturnType<typeof useLoginBusinessUserMutation>;
export type LoginBusinessUserMutationResult = Apollo.MutationResult<LoginBusinessUserMutation>;
export type LoginBusinessUserMutationOptions = Apollo.BaseMutationOptions<LoginBusinessUserMutation, LoginBusinessUserMutationVariables>;
export const LoginCreatorDocument = gql`
    mutation LoginCreator($input: LoginInput!) {
  LoginCreator(input: $input) {
    token
    type
    ttl
  }
}
    `;
export type LoginCreatorMutationFn = Apollo.MutationFunction<LoginCreatorMutation, LoginCreatorMutationVariables>;

/**
 * __useLoginCreatorMutation__
 *
 * To run a mutation, you first call `useLoginCreatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginCreatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginCreatorMutation, { data, loading, error }] = useLoginCreatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginCreatorMutation(baseOptions?: Apollo.MutationHookOptions<LoginCreatorMutation, LoginCreatorMutationVariables>) {
        return Apollo.useMutation<LoginCreatorMutation, LoginCreatorMutationVariables>(LoginCreatorDocument, baseOptions);
      }
export type LoginCreatorMutationHookResult = ReturnType<typeof useLoginCreatorMutation>;
export type LoginCreatorMutationResult = Apollo.MutationResult<LoginCreatorMutation>;
export type LoginCreatorMutationOptions = Apollo.BaseMutationOptions<LoginCreatorMutation, LoginCreatorMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($input: LoginInput!) {
  LoginUser(input: $input) {
    token
    type
    ttl
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, baseOptions);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LogoutBusinessUserDocument = gql`
    mutation LogoutBusinessUser {
  LogoutBusinessUser
}
    `;
export type LogoutBusinessUserMutationFn = Apollo.MutationFunction<LogoutBusinessUserMutation, LogoutBusinessUserMutationVariables>;

/**
 * __useLogoutBusinessUserMutation__
 *
 * To run a mutation, you first call `useLogoutBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutBusinessUserMutation, { data, loading, error }] = useLogoutBusinessUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutBusinessUserMutation, LogoutBusinessUserMutationVariables>) {
        return Apollo.useMutation<LogoutBusinessUserMutation, LogoutBusinessUserMutationVariables>(LogoutBusinessUserDocument, baseOptions);
      }
export type LogoutBusinessUserMutationHookResult = ReturnType<typeof useLogoutBusinessUserMutation>;
export type LogoutBusinessUserMutationResult = Apollo.MutationResult<LogoutBusinessUserMutation>;
export type LogoutBusinessUserMutationOptions = Apollo.BaseMutationOptions<LogoutBusinessUserMutation, LogoutBusinessUserMutationVariables>;
export const LogoutCreatorDocument = gql`
    mutation LogoutCreator {
  LogoutCreator
}
    `;
export type LogoutCreatorMutationFn = Apollo.MutationFunction<LogoutCreatorMutation, LogoutCreatorMutationVariables>;

/**
 * __useLogoutCreatorMutation__
 *
 * To run a mutation, you first call `useLogoutCreatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutCreatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutCreatorMutation, { data, loading, error }] = useLogoutCreatorMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutCreatorMutation(baseOptions?: Apollo.MutationHookOptions<LogoutCreatorMutation, LogoutCreatorMutationVariables>) {
        return Apollo.useMutation<LogoutCreatorMutation, LogoutCreatorMutationVariables>(LogoutCreatorDocument, baseOptions);
      }
export type LogoutCreatorMutationHookResult = ReturnType<typeof useLogoutCreatorMutation>;
export type LogoutCreatorMutationResult = Apollo.MutationResult<LogoutCreatorMutation>;
export type LogoutCreatorMutationOptions = Apollo.BaseMutationOptions<LogoutCreatorMutation, LogoutCreatorMutationVariables>;
export const LogoutUserDocument = gql`
    mutation LogoutUser {
  LogoutUser
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, baseOptions);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const PasswordResetBusinessUserDocument = gql`
    mutation PasswordResetBusinessUser($input: PasswordResetInput!) {
  PasswordResetBusinessUser(input: $input)
}
    `;
export type PasswordResetBusinessUserMutationFn = Apollo.MutationFunction<PasswordResetBusinessUserMutation, PasswordResetBusinessUserMutationVariables>;

/**
 * __usePasswordResetBusinessUserMutation__
 *
 * To run a mutation, you first call `usePasswordResetBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetBusinessUserMutation, { data, loading, error }] = usePasswordResetBusinessUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordResetBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetBusinessUserMutation, PasswordResetBusinessUserMutationVariables>) {
        return Apollo.useMutation<PasswordResetBusinessUserMutation, PasswordResetBusinessUserMutationVariables>(PasswordResetBusinessUserDocument, baseOptions);
      }
export type PasswordResetBusinessUserMutationHookResult = ReturnType<typeof usePasswordResetBusinessUserMutation>;
export type PasswordResetBusinessUserMutationResult = Apollo.MutationResult<PasswordResetBusinessUserMutation>;
export type PasswordResetBusinessUserMutationOptions = Apollo.BaseMutationOptions<PasswordResetBusinessUserMutation, PasswordResetBusinessUserMutationVariables>;
export const RegisterAffiliationDocument = gql`
    mutation RegisterAffiliation($input: RegisterAffiliationInput!) {
  RegisterAffiliation(input: $input) {
    id
    name
    creators_count
    created_at
    updated_at
  }
}
    `;
export type RegisterAffiliationMutationFn = Apollo.MutationFunction<RegisterAffiliationMutation, RegisterAffiliationMutationVariables>;

/**
 * __useRegisterAffiliationMutation__
 *
 * To run a mutation, you first call `useRegisterAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAffiliationMutation, { data, loading, error }] = useRegisterAffiliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAffiliationMutation, RegisterAffiliationMutationVariables>) {
        return Apollo.useMutation<RegisterAffiliationMutation, RegisterAffiliationMutationVariables>(RegisterAffiliationDocument, baseOptions);
      }
export type RegisterAffiliationMutationHookResult = ReturnType<typeof useRegisterAffiliationMutation>;
export type RegisterAffiliationMutationResult = Apollo.MutationResult<RegisterAffiliationMutation>;
export type RegisterAffiliationMutationOptions = Apollo.BaseMutationOptions<RegisterAffiliationMutation, RegisterAffiliationMutationVariables>;
export const RegisterLiveTalkProductByBusinessUserDocument = gql`
    mutation RegisterLiveTalkProductByBusinessUser($creator_id: ID!, $input: RegisterLiveTalkProductInput!) {
  RegisterLiveTalkProductByBusinessUser(creator_id: $creator_id, input: $input)
}
    `;
export type RegisterLiveTalkProductByBusinessUserMutationFn = Apollo.MutationFunction<RegisterLiveTalkProductByBusinessUserMutation, RegisterLiveTalkProductByBusinessUserMutationVariables>;

/**
 * __useRegisterLiveTalkProductByBusinessUserMutation__
 *
 * To run a mutation, you first call `useRegisterLiveTalkProductByBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterLiveTalkProductByBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerLiveTalkProductByBusinessUserMutation, { data, loading, error }] = useRegisterLiveTalkProductByBusinessUserMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterLiveTalkProductByBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterLiveTalkProductByBusinessUserMutation, RegisterLiveTalkProductByBusinessUserMutationVariables>) {
        return Apollo.useMutation<RegisterLiveTalkProductByBusinessUserMutation, RegisterLiveTalkProductByBusinessUserMutationVariables>(RegisterLiveTalkProductByBusinessUserDocument, baseOptions);
      }
export type RegisterLiveTalkProductByBusinessUserMutationHookResult = ReturnType<typeof useRegisterLiveTalkProductByBusinessUserMutation>;
export type RegisterLiveTalkProductByBusinessUserMutationResult = Apollo.MutationResult<RegisterLiveTalkProductByBusinessUserMutation>;
export type RegisterLiveTalkProductByBusinessUserMutationOptions = Apollo.BaseMutationOptions<RegisterLiveTalkProductByBusinessUserMutation, RegisterLiveTalkProductByBusinessUserMutationVariables>;
export const RegisterMovieProductByBusinessUserDocument = gql`
    mutation RegisterMovieProductByBusinessUser($creator_id: ID!, $input: RegisterMovieProductInput!) {
  RegisterMovieProductByBusinessUser(creator_id: $creator_id, input: $input)
}
    `;
export type RegisterMovieProductByBusinessUserMutationFn = Apollo.MutationFunction<RegisterMovieProductByBusinessUserMutation, RegisterMovieProductByBusinessUserMutationVariables>;

/**
 * __useRegisterMovieProductByBusinessUserMutation__
 *
 * To run a mutation, you first call `useRegisterMovieProductByBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMovieProductByBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMovieProductByBusinessUserMutation, { data, loading, error }] = useRegisterMovieProductByBusinessUserMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMovieProductByBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMovieProductByBusinessUserMutation, RegisterMovieProductByBusinessUserMutationVariables>) {
        return Apollo.useMutation<RegisterMovieProductByBusinessUserMutation, RegisterMovieProductByBusinessUserMutationVariables>(RegisterMovieProductByBusinessUserDocument, baseOptions);
      }
export type RegisterMovieProductByBusinessUserMutationHookResult = ReturnType<typeof useRegisterMovieProductByBusinessUserMutation>;
export type RegisterMovieProductByBusinessUserMutationResult = Apollo.MutationResult<RegisterMovieProductByBusinessUserMutation>;
export type RegisterMovieProductByBusinessUserMutationOptions = Apollo.BaseMutationOptions<RegisterMovieProductByBusinessUserMutation, RegisterMovieProductByBusinessUserMutationVariables>;
export const RegisterNewCreatorDocument = gql`
    mutation RegisterNewCreator($input: RegisterNewCreatorInput!) {
  RegisterNewCreator(input: $input) {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
  }
}
    `;
export type RegisterNewCreatorMutationFn = Apollo.MutationFunction<RegisterNewCreatorMutation, RegisterNewCreatorMutationVariables>;

/**
 * __useRegisterNewCreatorMutation__
 *
 * To run a mutation, you first call `useRegisterNewCreatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterNewCreatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerNewCreatorMutation, { data, loading, error }] = useRegisterNewCreatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterNewCreatorMutation(baseOptions?: Apollo.MutationHookOptions<RegisterNewCreatorMutation, RegisterNewCreatorMutationVariables>) {
        return Apollo.useMutation<RegisterNewCreatorMutation, RegisterNewCreatorMutationVariables>(RegisterNewCreatorDocument, baseOptions);
      }
export type RegisterNewCreatorMutationHookResult = ReturnType<typeof useRegisterNewCreatorMutation>;
export type RegisterNewCreatorMutationResult = Apollo.MutationResult<RegisterNewCreatorMutation>;
export type RegisterNewCreatorMutationOptions = Apollo.BaseMutationOptions<RegisterNewCreatorMutation, RegisterNewCreatorMutationVariables>;
export const RegisterPhotoProductByBusinessUserDocument = gql`
    mutation RegisterPhotoProductByBusinessUser($creator_id: ID!, $input: RegisterPhotoProductInput!) {
  RegisterPhotoProductByBusinessUser(creator_id: $creator_id, input: $input)
}
    `;
export type RegisterPhotoProductByBusinessUserMutationFn = Apollo.MutationFunction<RegisterPhotoProductByBusinessUserMutation, RegisterPhotoProductByBusinessUserMutationVariables>;

/**
 * __useRegisterPhotoProductByBusinessUserMutation__
 *
 * To run a mutation, you first call `useRegisterPhotoProductByBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPhotoProductByBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPhotoProductByBusinessUserMutation, { data, loading, error }] = useRegisterPhotoProductByBusinessUserMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPhotoProductByBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterPhotoProductByBusinessUserMutation, RegisterPhotoProductByBusinessUserMutationVariables>) {
        return Apollo.useMutation<RegisterPhotoProductByBusinessUserMutation, RegisterPhotoProductByBusinessUserMutationVariables>(RegisterPhotoProductByBusinessUserDocument, baseOptions);
      }
export type RegisterPhotoProductByBusinessUserMutationHookResult = ReturnType<typeof useRegisterPhotoProductByBusinessUserMutation>;
export type RegisterPhotoProductByBusinessUserMutationResult = Apollo.MutationResult<RegisterPhotoProductByBusinessUserMutation>;
export type RegisterPhotoProductByBusinessUserMutationOptions = Apollo.BaseMutationOptions<RegisterPhotoProductByBusinessUserMutation, RegisterPhotoProductByBusinessUserMutationVariables>;
export const ResendCreatorAccountRegistrationDocument = gql`
    mutation ResendCreatorAccountRegistration($creator_id: ID!) {
  ResendCreatorAccountRegistration(creator_id: $creator_id)
}
    `;
export type ResendCreatorAccountRegistrationMutationFn = Apollo.MutationFunction<ResendCreatorAccountRegistrationMutation, ResendCreatorAccountRegistrationMutationVariables>;

/**
 * __useResendCreatorAccountRegistrationMutation__
 *
 * To run a mutation, you first call `useResendCreatorAccountRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCreatorAccountRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCreatorAccountRegistrationMutation, { data, loading, error }] = useResendCreatorAccountRegistrationMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useResendCreatorAccountRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<ResendCreatorAccountRegistrationMutation, ResendCreatorAccountRegistrationMutationVariables>) {
        return Apollo.useMutation<ResendCreatorAccountRegistrationMutation, ResendCreatorAccountRegistrationMutationVariables>(ResendCreatorAccountRegistrationDocument, baseOptions);
      }
export type ResendCreatorAccountRegistrationMutationHookResult = ReturnType<typeof useResendCreatorAccountRegistrationMutation>;
export type ResendCreatorAccountRegistrationMutationResult = Apollo.MutationResult<ResendCreatorAccountRegistrationMutation>;
export type ResendCreatorAccountRegistrationMutationOptions = Apollo.BaseMutationOptions<ResendCreatorAccountRegistrationMutation, ResendCreatorAccountRegistrationMutationVariables>;
export const ResendEmailForVerifyingUpdatedBusinessUserEmailInputDocument = gql`
    mutation ResendEmailForVerifyingUpdatedBusinessUserEmailInput($input: ResendEmailForVerifyingUpdatedBusinessUserEmailInput!) {
  ResendEmailForVerifyingUpdatedBusinessUserEmail(input: $input)
}
    `;
export type ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationFn = Apollo.MutationFunction<ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation, ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationVariables>;

/**
 * __useResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation__
 *
 * To run a mutation, you first call `useResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailForVerifyingUpdatedBusinessUserEmailInputMutation, { data, loading, error }] = useResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation(baseOptions?: Apollo.MutationHookOptions<ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation, ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationVariables>) {
        return Apollo.useMutation<ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation, ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationVariables>(ResendEmailForVerifyingUpdatedBusinessUserEmailInputDocument, baseOptions);
      }
export type ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationHookResult = ReturnType<typeof useResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation>;
export type ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationResult = Apollo.MutationResult<ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation>;
export type ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationOptions = Apollo.BaseMutationOptions<ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutation, ResendEmailForVerifyingUpdatedBusinessUserEmailInputMutationVariables>;
export const ResendUserAccountRegistrationDocument = gql`
    mutation ResendUserAccountRegistration($user_id: ID!, $input: ResendUserAccountRegistrationInput!) {
  ResendUserAccountRegistration(user_id: $user_id, input: $input)
}
    `;
export type ResendUserAccountRegistrationMutationFn = Apollo.MutationFunction<ResendUserAccountRegistrationMutation, ResendUserAccountRegistrationMutationVariables>;

/**
 * __useResendUserAccountRegistrationMutation__
 *
 * To run a mutation, you first call `useResendUserAccountRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserAccountRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserAccountRegistrationMutation, { data, loading, error }] = useResendUserAccountRegistrationMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendUserAccountRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<ResendUserAccountRegistrationMutation, ResendUserAccountRegistrationMutationVariables>) {
        return Apollo.useMutation<ResendUserAccountRegistrationMutation, ResendUserAccountRegistrationMutationVariables>(ResendUserAccountRegistrationDocument, baseOptions);
      }
export type ResendUserAccountRegistrationMutationHookResult = ReturnType<typeof useResendUserAccountRegistrationMutation>;
export type ResendUserAccountRegistrationMutationResult = Apollo.MutationResult<ResendUserAccountRegistrationMutation>;
export type ResendUserAccountRegistrationMutationOptions = Apollo.BaseMutationOptions<ResendUserAccountRegistrationMutation, ResendUserAccountRegistrationMutationVariables>;
export const UnfavoriteCreatorDocument = gql`
    mutation UnfavoriteCreator($creator_id: ID!) {
  UnfavoriteCreator(creator_id: $creator_id)
}
    `;
export type UnfavoriteCreatorMutationFn = Apollo.MutationFunction<UnfavoriteCreatorMutation, UnfavoriteCreatorMutationVariables>;

/**
 * __useUnfavoriteCreatorMutation__
 *
 * To run a mutation, you first call `useUnfavoriteCreatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfavoriteCreatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfavoriteCreatorMutation, { data, loading, error }] = useUnfavoriteCreatorMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useUnfavoriteCreatorMutation(baseOptions?: Apollo.MutationHookOptions<UnfavoriteCreatorMutation, UnfavoriteCreatorMutationVariables>) {
        return Apollo.useMutation<UnfavoriteCreatorMutation, UnfavoriteCreatorMutationVariables>(UnfavoriteCreatorDocument, baseOptions);
      }
export type UnfavoriteCreatorMutationHookResult = ReturnType<typeof useUnfavoriteCreatorMutation>;
export type UnfavoriteCreatorMutationResult = Apollo.MutationResult<UnfavoriteCreatorMutation>;
export type UnfavoriteCreatorMutationOptions = Apollo.BaseMutationOptions<UnfavoriteCreatorMutation, UnfavoriteCreatorMutationVariables>;
export const UpdateBusinessUserAccountDocument = gql`
    mutation UpdateBusinessUserAccount($input: UpdateBusinessUserAccountInput!) {
  UpdateBusinessUserAccount(input: $input) {
    is_change_request_email
    change_request_email
  }
}
    `;
export type UpdateBusinessUserAccountMutationFn = Apollo.MutationFunction<UpdateBusinessUserAccountMutation, UpdateBusinessUserAccountMutationVariables>;

/**
 * __useUpdateBusinessUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUserAccountMutation, { data, loading, error }] = useUpdateBusinessUserAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessUserAccountMutation, UpdateBusinessUserAccountMutationVariables>) {
        return Apollo.useMutation<UpdateBusinessUserAccountMutation, UpdateBusinessUserAccountMutationVariables>(UpdateBusinessUserAccountDocument, baseOptions);
      }
export type UpdateBusinessUserAccountMutationHookResult = ReturnType<typeof useUpdateBusinessUserAccountMutation>;
export type UpdateBusinessUserAccountMutationResult = Apollo.MutationResult<UpdateBusinessUserAccountMutation>;
export type UpdateBusinessUserAccountMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessUserAccountMutation, UpdateBusinessUserAccountMutationVariables>;
export const UpdateBusinessUserEmailByTokenDocument = gql`
    mutation UpdateBusinessUserEmailByToken($input: UpdateEmailByTokenInput!) {
  UpdateBusinessUserEmailByToken(input: $input) {
    id
    name
    email
    created_at
    updated_at
  }
}
    `;
export type UpdateBusinessUserEmailByTokenMutationFn = Apollo.MutationFunction<UpdateBusinessUserEmailByTokenMutation, UpdateBusinessUserEmailByTokenMutationVariables>;

/**
 * __useUpdateBusinessUserEmailByTokenMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUserEmailByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUserEmailByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUserEmailByTokenMutation, { data, loading, error }] = useUpdateBusinessUserEmailByTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessUserEmailByTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessUserEmailByTokenMutation, UpdateBusinessUserEmailByTokenMutationVariables>) {
        return Apollo.useMutation<UpdateBusinessUserEmailByTokenMutation, UpdateBusinessUserEmailByTokenMutationVariables>(UpdateBusinessUserEmailByTokenDocument, baseOptions);
      }
export type UpdateBusinessUserEmailByTokenMutationHookResult = ReturnType<typeof useUpdateBusinessUserEmailByTokenMutation>;
export type UpdateBusinessUserEmailByTokenMutationResult = Apollo.MutationResult<UpdateBusinessUserEmailByTokenMutation>;
export type UpdateBusinessUserEmailByTokenMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessUserEmailByTokenMutation, UpdateBusinessUserEmailByTokenMutationVariables>;
export const UpdateCreatorAccountDocument = gql`
    mutation UpdateCreatorAccount($creator_id: ID!, $input: UpdateCreatorAccountInput!) {
  UpdateCreatorAccount(creator_id: $creator_id, input: $input) {
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
    }
    is_change_request_email
    change_request_email
  }
}
    `;
export type UpdateCreatorAccountMutationFn = Apollo.MutationFunction<UpdateCreatorAccountMutation, UpdateCreatorAccountMutationVariables>;

/**
 * __useUpdateCreatorAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCreatorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatorAccountMutation, { data, loading, error }] = useUpdateCreatorAccountMutation({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreatorAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreatorAccountMutation, UpdateCreatorAccountMutationVariables>) {
        return Apollo.useMutation<UpdateCreatorAccountMutation, UpdateCreatorAccountMutationVariables>(UpdateCreatorAccountDocument, baseOptions);
      }
export type UpdateCreatorAccountMutationHookResult = ReturnType<typeof useUpdateCreatorAccountMutation>;
export type UpdateCreatorAccountMutationResult = Apollo.MutationResult<UpdateCreatorAccountMutation>;
export type UpdateCreatorAccountMutationOptions = Apollo.BaseMutationOptions<UpdateCreatorAccountMutation, UpdateCreatorAccountMutationVariables>;
export const UpdateCreatorAccountByTokenDocument = gql`
    mutation UpdateCreatorAccountByToken($input: UpdateCreatorAccountByTokenInput!) {
  UpdateCreatorAccountByToken(input: $input) {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    favorite_users_count
  }
}
    `;
export type UpdateCreatorAccountByTokenMutationFn = Apollo.MutationFunction<UpdateCreatorAccountByTokenMutation, UpdateCreatorAccountByTokenMutationVariables>;

/**
 * __useUpdateCreatorAccountByTokenMutation__
 *
 * To run a mutation, you first call `useUpdateCreatorAccountByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatorAccountByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatorAccountByTokenMutation, { data, loading, error }] = useUpdateCreatorAccountByTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreatorAccountByTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreatorAccountByTokenMutation, UpdateCreatorAccountByTokenMutationVariables>) {
        return Apollo.useMutation<UpdateCreatorAccountByTokenMutation, UpdateCreatorAccountByTokenMutationVariables>(UpdateCreatorAccountByTokenDocument, baseOptions);
      }
export type UpdateCreatorAccountByTokenMutationHookResult = ReturnType<typeof useUpdateCreatorAccountByTokenMutation>;
export type UpdateCreatorAccountByTokenMutationResult = Apollo.MutationResult<UpdateCreatorAccountByTokenMutation>;
export type UpdateCreatorAccountByTokenMutationOptions = Apollo.BaseMutationOptions<UpdateCreatorAccountByTokenMutation, UpdateCreatorAccountByTokenMutationVariables>;
export const UpdateCreatorEmailByTokenDocument = gql`
    mutation UpdateCreatorEmailByToken($input: UpdateEmailByTokenInput!) {
  UpdateCreatorEmailByToken(input: $input) {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    favorite_users_count
  }
}
    `;
export type UpdateCreatorEmailByTokenMutationFn = Apollo.MutationFunction<UpdateCreatorEmailByTokenMutation, UpdateCreatorEmailByTokenMutationVariables>;

/**
 * __useUpdateCreatorEmailByTokenMutation__
 *
 * To run a mutation, you first call `useUpdateCreatorEmailByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatorEmailByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatorEmailByTokenMutation, { data, loading, error }] = useUpdateCreatorEmailByTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreatorEmailByTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreatorEmailByTokenMutation, UpdateCreatorEmailByTokenMutationVariables>) {
        return Apollo.useMutation<UpdateCreatorEmailByTokenMutation, UpdateCreatorEmailByTokenMutationVariables>(UpdateCreatorEmailByTokenDocument, baseOptions);
      }
export type UpdateCreatorEmailByTokenMutationHookResult = ReturnType<typeof useUpdateCreatorEmailByTokenMutation>;
export type UpdateCreatorEmailByTokenMutationResult = Apollo.MutationResult<UpdateCreatorEmailByTokenMutation>;
export type UpdateCreatorEmailByTokenMutationOptions = Apollo.BaseMutationOptions<UpdateCreatorEmailByTokenMutation, UpdateCreatorEmailByTokenMutationVariables>;
export const UpdatePasswordBusinessUserDocument = gql`
    mutation UpdatePasswordBusinessUser($business_user_id: ID!, $input: UpdatePasswordInput!) {
  UpdatePasswordBusinessUser(business_user_id: $business_user_id, input: $input)
}
    `;
export type UpdatePasswordBusinessUserMutationFn = Apollo.MutationFunction<UpdatePasswordBusinessUserMutation, UpdatePasswordBusinessUserMutationVariables>;

/**
 * __useUpdatePasswordBusinessUserMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordBusinessUserMutation, { data, loading, error }] = useUpdatePasswordBusinessUserMutation({
 *   variables: {
 *      business_user_id: // value for 'business_user_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordBusinessUserMutation, UpdatePasswordBusinessUserMutationVariables>) {
        return Apollo.useMutation<UpdatePasswordBusinessUserMutation, UpdatePasswordBusinessUserMutationVariables>(UpdatePasswordBusinessUserDocument, baseOptions);
      }
export type UpdatePasswordBusinessUserMutationHookResult = ReturnType<typeof useUpdatePasswordBusinessUserMutation>;
export type UpdatePasswordBusinessUserMutationResult = Apollo.MutationResult<UpdatePasswordBusinessUserMutation>;
export type UpdatePasswordBusinessUserMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordBusinessUserMutation, UpdatePasswordBusinessUserMutationVariables>;
export const UpdateUserAccountDocument = gql`
    mutation UpdateUserAccount($input: UpdateUserAccountInput!) {
  UpdateUserAccount(input: $input) {
    is_change_request_email
    change_request_email
  }
}
    `;
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>) {
        return Apollo.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(UpdateUserAccountDocument, baseOptions);
      }
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = Apollo.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;
export const UpdateUserEmailByTokenDocument = gql`
    mutation UpdateUserEmailByToken($input: UpdateEmailByTokenInput!) {
  UpdateUserEmailByToken(input: $input) {
    id
    status
    name
    email
    twitter_username
    created_at
    updated_at
  }
}
    `;
export type UpdateUserEmailByTokenMutationFn = Apollo.MutationFunction<UpdateUserEmailByTokenMutation, UpdateUserEmailByTokenMutationVariables>;

/**
 * __useUpdateUserEmailByTokenMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailByTokenMutation, { data, loading, error }] = useUpdateUserEmailByTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEmailByTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEmailByTokenMutation, UpdateUserEmailByTokenMutationVariables>) {
        return Apollo.useMutation<UpdateUserEmailByTokenMutation, UpdateUserEmailByTokenMutationVariables>(UpdateUserEmailByTokenDocument, baseOptions);
      }
export type UpdateUserEmailByTokenMutationHookResult = ReturnType<typeof useUpdateUserEmailByTokenMutation>;
export type UpdateUserEmailByTokenMutationResult = Apollo.MutationResult<UpdateUserEmailByTokenMutation>;
export type UpdateUserEmailByTokenMutationOptions = Apollo.BaseMutationOptions<UpdateUserEmailByTokenMutation, UpdateUserEmailByTokenMutationVariables>;
export const UserPasswordResetDocument = gql`
    mutation UserPasswordReset($input: PasswordResetInput!) {
  UserPasswordReset(input: $input)
}
    `;
export type UserPasswordResetMutationFn = Apollo.MutationFunction<UserPasswordResetMutation, UserPasswordResetMutationVariables>;

/**
 * __useUserPasswordResetMutation__
 *
 * To run a mutation, you first call `useUserPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPasswordResetMutation, { data, loading, error }] = useUserPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<UserPasswordResetMutation, UserPasswordResetMutationVariables>) {
        return Apollo.useMutation<UserPasswordResetMutation, UserPasswordResetMutationVariables>(UserPasswordResetDocument, baseOptions);
      }
export type UserPasswordResetMutationHookResult = ReturnType<typeof useUserPasswordResetMutation>;
export type UserPasswordResetMutationResult = Apollo.MutationResult<UserPasswordResetMutation>;
export type UserPasswordResetMutationOptions = Apollo.BaseMutationOptions<UserPasswordResetMutation, UserPasswordResetMutationVariables>;
export const UserPurchaseProductDocument = gql`
    mutation UserPurchaseProduct($product_id: ID!) {
  UserPurchaseProduct(product_id: $product_id) {
    order {
      id
      price
      paid_limit
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
    }
    stripe {
      checkout_session {
        id
        object
        allow_promotion_codes
        amount_subtotal
        amount_total
        billing_address_collection
        cancel_url
        client_reference_id
        currency
        customer_email
        livemode
        locale
        mode
        payment_method_types
        payment_status
        submit_type
        success_url
      }
      public_key
      checkout_url
    }
    paidy {
      payload {
        amount
        currency
        store_name
        buyer {
          email
        }
        buyer_data {
          user_id
          age
          order_count
          ltv
          last_order_amount
          last_order_at
        }
        order {
          items {
            id
            quantity
            title
            unit_price
          }
          order_ref
        }
      }
      payload_json
      public_key
    }
    robot_payment {
      credit_card_link
      store_id
      price
      tax
      shipping_fee
      job_type
      order_id
      email
      product_name
      product_id
    }
  }
}
    `;
export type UserPurchaseProductMutationFn = Apollo.MutationFunction<UserPurchaseProductMutation, UserPurchaseProductMutationVariables>;

/**
 * __useUserPurchaseProductMutation__
 *
 * To run a mutation, you first call `useUserPurchaseProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPurchaseProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPurchaseProductMutation, { data, loading, error }] = useUserPurchaseProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUserPurchaseProductMutation(baseOptions?: Apollo.MutationHookOptions<UserPurchaseProductMutation, UserPurchaseProductMutationVariables>) {
        return Apollo.useMutation<UserPurchaseProductMutation, UserPurchaseProductMutationVariables>(UserPurchaseProductDocument, baseOptions);
      }
export type UserPurchaseProductMutationHookResult = ReturnType<typeof useUserPurchaseProductMutation>;
export type UserPurchaseProductMutationResult = Apollo.MutationResult<UserPurchaseProductMutation>;
export type UserPurchaseProductMutationOptions = Apollo.BaseMutationOptions<UserPurchaseProductMutation, UserPurchaseProductMutationVariables>;
export const UserRegisterAccountDocument = gql`
    mutation UserRegisterAccount($input: UserRegisterAccountInput!) {
  UserRegisterAccount(input: $input) {
    id
    status
    name
    email
    twitter_username
    created_at
    updated_at
  }
}
    `;
export type UserRegisterAccountMutationFn = Apollo.MutationFunction<UserRegisterAccountMutation, UserRegisterAccountMutationVariables>;

/**
 * __useUserRegisterAccountMutation__
 *
 * To run a mutation, you first call `useUserRegisterAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRegisterAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRegisterAccountMutation, { data, loading, error }] = useUserRegisterAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRegisterAccountMutation(baseOptions?: Apollo.MutationHookOptions<UserRegisterAccountMutation, UserRegisterAccountMutationVariables>) {
        return Apollo.useMutation<UserRegisterAccountMutation, UserRegisterAccountMutationVariables>(UserRegisterAccountDocument, baseOptions);
      }
export type UserRegisterAccountMutationHookResult = ReturnType<typeof useUserRegisterAccountMutation>;
export type UserRegisterAccountMutationResult = Apollo.MutationResult<UserRegisterAccountMutation>;
export type UserRegisterAccountMutationOptions = Apollo.BaseMutationOptions<UserRegisterAccountMutation, UserRegisterAccountMutationVariables>;
export const UserRequestLiveTalkDocument = gql`
    mutation UserRequestLiveTalk($input: RequestLiveTalkInput!) {
  UserRequestLiveTalk(input: $input)
}
    `;
export type UserRequestLiveTalkMutationFn = Apollo.MutationFunction<UserRequestLiveTalkMutation, UserRequestLiveTalkMutationVariables>;

/**
 * __useUserRequestLiveTalkMutation__
 *
 * To run a mutation, you first call `useUserRequestLiveTalkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRequestLiveTalkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRequestLiveTalkMutation, { data, loading, error }] = useUserRequestLiveTalkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRequestLiveTalkMutation(baseOptions?: Apollo.MutationHookOptions<UserRequestLiveTalkMutation, UserRequestLiveTalkMutationVariables>) {
        return Apollo.useMutation<UserRequestLiveTalkMutation, UserRequestLiveTalkMutationVariables>(UserRequestLiveTalkDocument, baseOptions);
      }
export type UserRequestLiveTalkMutationHookResult = ReturnType<typeof useUserRequestLiveTalkMutation>;
export type UserRequestLiveTalkMutationResult = Apollo.MutationResult<UserRequestLiveTalkMutation>;
export type UserRequestLiveTalkMutationOptions = Apollo.BaseMutationOptions<UserRequestLiveTalkMutation, UserRequestLiveTalkMutationVariables>;
export const UserRequestMovieDocument = gql`
    mutation UserRequestMovie($input: RequestMovieInput!) {
  UserRequestMovie(input: $input)
}
    `;
export type UserRequestMovieMutationFn = Apollo.MutationFunction<UserRequestMovieMutation, UserRequestMovieMutationVariables>;

/**
 * __useUserRequestMovieMutation__
 *
 * To run a mutation, you first call `useUserRequestMovieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRequestMovieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRequestMovieMutation, { data, loading, error }] = useUserRequestMovieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRequestMovieMutation(baseOptions?: Apollo.MutationHookOptions<UserRequestMovieMutation, UserRequestMovieMutationVariables>) {
        return Apollo.useMutation<UserRequestMovieMutation, UserRequestMovieMutationVariables>(UserRequestMovieDocument, baseOptions);
      }
export type UserRequestMovieMutationHookResult = ReturnType<typeof useUserRequestMovieMutation>;
export type UserRequestMovieMutationResult = Apollo.MutationResult<UserRequestMovieMutation>;
export type UserRequestMovieMutationOptions = Apollo.BaseMutationOptions<UserRequestMovieMutation, UserRequestMovieMutationVariables>;
export const UserRequestPhotoDocument = gql`
    mutation UserRequestPhoto($input: RequestPhotoInput!) {
  UserRequestPhoto(input: $input)
}
    `;
export type UserRequestPhotoMutationFn = Apollo.MutationFunction<UserRequestPhotoMutation, UserRequestPhotoMutationVariables>;

/**
 * __useUserRequestPhotoMutation__
 *
 * To run a mutation, you first call `useUserRequestPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRequestPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRequestPhotoMutation, { data, loading, error }] = useUserRequestPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRequestPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UserRequestPhotoMutation, UserRequestPhotoMutationVariables>) {
        return Apollo.useMutation<UserRequestPhotoMutation, UserRequestPhotoMutationVariables>(UserRequestPhotoDocument, baseOptions);
      }
export type UserRequestPhotoMutationHookResult = ReturnType<typeof useUserRequestPhotoMutation>;
export type UserRequestPhotoMutationResult = Apollo.MutationResult<UserRequestPhotoMutation>;
export type UserRequestPhotoMutationOptions = Apollo.BaseMutationOptions<UserRequestPhotoMutation, UserRequestPhotoMutationVariables>;
export const UserUpdatePasswordDocument = gql`
    mutation UserUpdatePassword($user_id: ID!, $input: UpdatePasswordInput!) {
  UserUpdatePassword(user_id: $user_id, input: $input)
}
    `;
export type UserUpdatePasswordMutationFn = Apollo.MutationFunction<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;

/**
 * __useUserUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUserUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePasswordMutation, { data, loading, error }] = useUserUpdatePasswordMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>) {
        return Apollo.useMutation<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>(UserUpdatePasswordDocument, baseOptions);
      }
export type UserUpdatePasswordMutationHookResult = ReturnType<typeof useUserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationResult = Apollo.MutationResult<UserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;
export const UserVerifyAccountDocument = gql`
    mutation UserVerifyAccount($user_id: ID!, $input: UserVerifyAccountInput!) {
  UserVerifyAccount(user_id: $user_id, input: $input) {
    token
    type
    ttl
  }
}
    `;
export type UserVerifyAccountMutationFn = Apollo.MutationFunction<UserVerifyAccountMutation, UserVerifyAccountMutationVariables>;

/**
 * __useUserVerifyAccountMutation__
 *
 * To run a mutation, you first call `useUserVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userVerifyAccountMutation, { data, loading, error }] = useUserVerifyAccountMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<UserVerifyAccountMutation, UserVerifyAccountMutationVariables>) {
        return Apollo.useMutation<UserVerifyAccountMutation, UserVerifyAccountMutationVariables>(UserVerifyAccountDocument, baseOptions);
      }
export type UserVerifyAccountMutationHookResult = ReturnType<typeof useUserVerifyAccountMutation>;
export type UserVerifyAccountMutationResult = Apollo.MutationResult<UserVerifyAccountMutation>;
export type UserVerifyAccountMutationOptions = Apollo.BaseMutationOptions<UserVerifyAccountMutation, UserVerifyAccountMutationVariables>;
export const ActiveCreatorDocument = gql`
    query ActiveCreator($id: ID!) {
  ActiveCreator(id: $id) {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    favorite_users_count
  }
}
    `;

/**
 * __useActiveCreatorQuery__
 *
 * To run a query within a React component, call `useActiveCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveCreatorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActiveCreatorQuery(baseOptions?: Apollo.QueryHookOptions<ActiveCreatorQuery, ActiveCreatorQueryVariables>) {
        return Apollo.useQuery<ActiveCreatorQuery, ActiveCreatorQueryVariables>(ActiveCreatorDocument, baseOptions);
      }
export function useActiveCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveCreatorQuery, ActiveCreatorQueryVariables>) {
          return Apollo.useLazyQuery<ActiveCreatorQuery, ActiveCreatorQueryVariables>(ActiveCreatorDocument, baseOptions);
        }
export type ActiveCreatorQueryHookResult = ReturnType<typeof useActiveCreatorQuery>;
export type ActiveCreatorLazyQueryHookResult = ReturnType<typeof useActiveCreatorLazyQuery>;
export type ActiveCreatorQueryResult = Apollo.QueryResult<ActiveCreatorQuery, ActiveCreatorQueryVariables>;
export const ActiveCreatorsInAffiliationDocument = gql`
    query ActiveCreatorsInAffiliation($affiliation_id: ID) {
  ActiveCreatorsInAffiliation(affiliation_id: $affiliation_id) {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    favorite_users_count
  }
}
    `;

/**
 * __useActiveCreatorsInAffiliationQuery__
 *
 * To run a query within a React component, call `useActiveCreatorsInAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveCreatorsInAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveCreatorsInAffiliationQuery({
 *   variables: {
 *      affiliation_id: // value for 'affiliation_id'
 *   },
 * });
 */
export function useActiveCreatorsInAffiliationQuery(baseOptions?: Apollo.QueryHookOptions<ActiveCreatorsInAffiliationQuery, ActiveCreatorsInAffiliationQueryVariables>) {
        return Apollo.useQuery<ActiveCreatorsInAffiliationQuery, ActiveCreatorsInAffiliationQueryVariables>(ActiveCreatorsInAffiliationDocument, baseOptions);
      }
export function useActiveCreatorsInAffiliationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveCreatorsInAffiliationQuery, ActiveCreatorsInAffiliationQueryVariables>) {
          return Apollo.useLazyQuery<ActiveCreatorsInAffiliationQuery, ActiveCreatorsInAffiliationQueryVariables>(ActiveCreatorsInAffiliationDocument, baseOptions);
        }
export type ActiveCreatorsInAffiliationQueryHookResult = ReturnType<typeof useActiveCreatorsInAffiliationQuery>;
export type ActiveCreatorsInAffiliationLazyQueryHookResult = ReturnType<typeof useActiveCreatorsInAffiliationLazyQuery>;
export type ActiveCreatorsInAffiliationQueryResult = Apollo.QueryResult<ActiveCreatorsInAffiliationQuery, ActiveCreatorsInAffiliationQueryVariables>;
export const ActiveCreatorsInBusinessDocument = gql`
    query ActiveCreatorsInBusiness {
  ActiveCreatorsInBusiness {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    favorite_users_count
  }
}
    `;

/**
 * __useActiveCreatorsInBusinessQuery__
 *
 * To run a query within a React component, call `useActiveCreatorsInBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveCreatorsInBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveCreatorsInBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveCreatorsInBusinessQuery(baseOptions?: Apollo.QueryHookOptions<ActiveCreatorsInBusinessQuery, ActiveCreatorsInBusinessQueryVariables>) {
        return Apollo.useQuery<ActiveCreatorsInBusinessQuery, ActiveCreatorsInBusinessQueryVariables>(ActiveCreatorsInBusinessDocument, baseOptions);
      }
export function useActiveCreatorsInBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveCreatorsInBusinessQuery, ActiveCreatorsInBusinessQueryVariables>) {
          return Apollo.useLazyQuery<ActiveCreatorsInBusinessQuery, ActiveCreatorsInBusinessQueryVariables>(ActiveCreatorsInBusinessDocument, baseOptions);
        }
export type ActiveCreatorsInBusinessQueryHookResult = ReturnType<typeof useActiveCreatorsInBusinessQuery>;
export type ActiveCreatorsInBusinessLazyQueryHookResult = ReturnType<typeof useActiveCreatorsInBusinessLazyQuery>;
export type ActiveCreatorsInBusinessQueryResult = Apollo.QueryResult<ActiveCreatorsInBusinessQuery, ActiveCreatorsInBusinessQueryVariables>;
export const AffiliationInBusinessDocument = gql`
    query AffiliationInBusiness($affiliation_id: ID) {
  AffiliationInBusiness(affiliation_id: $affiliation_id) {
    id
    name
    creators_count
    created_at
    updated_at
  }
}
    `;

/**
 * __useAffiliationInBusinessQuery__
 *
 * To run a query within a React component, call `useAffiliationInBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliationInBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliationInBusinessQuery({
 *   variables: {
 *      affiliation_id: // value for 'affiliation_id'
 *   },
 * });
 */
export function useAffiliationInBusinessQuery(baseOptions?: Apollo.QueryHookOptions<AffiliationInBusinessQuery, AffiliationInBusinessQueryVariables>) {
        return Apollo.useQuery<AffiliationInBusinessQuery, AffiliationInBusinessQueryVariables>(AffiliationInBusinessDocument, baseOptions);
      }
export function useAffiliationInBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AffiliationInBusinessQuery, AffiliationInBusinessQueryVariables>) {
          return Apollo.useLazyQuery<AffiliationInBusinessQuery, AffiliationInBusinessQueryVariables>(AffiliationInBusinessDocument, baseOptions);
        }
export type AffiliationInBusinessQueryHookResult = ReturnType<typeof useAffiliationInBusinessQuery>;
export type AffiliationInBusinessLazyQueryHookResult = ReturnType<typeof useAffiliationInBusinessLazyQuery>;
export type AffiliationInBusinessQueryResult = Apollo.QueryResult<AffiliationInBusinessQuery, AffiliationInBusinessQueryVariables>;
export const AffiliationSaleThisMonthDocument = gql`
    query AffiliationSaleThisMonth($affiliation_id: ID!) {
  AffiliationSaleThisMonth(affiliation_id: $affiliation_id) {
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    sale_this_month {
      month
      count
      sales
    }
  }
}
    `;

/**
 * __useAffiliationSaleThisMonthQuery__
 *
 * To run a query within a React component, call `useAffiliationSaleThisMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliationSaleThisMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliationSaleThisMonthQuery({
 *   variables: {
 *      affiliation_id: // value for 'affiliation_id'
 *   },
 * });
 */
export function useAffiliationSaleThisMonthQuery(baseOptions?: Apollo.QueryHookOptions<AffiliationSaleThisMonthQuery, AffiliationSaleThisMonthQueryVariables>) {
        return Apollo.useQuery<AffiliationSaleThisMonthQuery, AffiliationSaleThisMonthQueryVariables>(AffiliationSaleThisMonthDocument, baseOptions);
      }
export function useAffiliationSaleThisMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AffiliationSaleThisMonthQuery, AffiliationSaleThisMonthQueryVariables>) {
          return Apollo.useLazyQuery<AffiliationSaleThisMonthQuery, AffiliationSaleThisMonthQueryVariables>(AffiliationSaleThisMonthDocument, baseOptions);
        }
export type AffiliationSaleThisMonthQueryHookResult = ReturnType<typeof useAffiliationSaleThisMonthQuery>;
export type AffiliationSaleThisMonthLazyQueryHookResult = ReturnType<typeof useAffiliationSaleThisMonthLazyQuery>;
export type AffiliationSaleThisMonthQueryResult = Apollo.QueryResult<AffiliationSaleThisMonthQuery, AffiliationSaleThisMonthQueryVariables>;
export const AffiliationSalesPerMonthDocument = gql`
    query AffiliationSalesPerMonth($affiliation_id: ID) {
  AffiliationSalesPerMonth(affiliation_id: $affiliation_id) {
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    sales_per_month {
      month
      count
      sales
    }
  }
}
    `;

/**
 * __useAffiliationSalesPerMonthQuery__
 *
 * To run a query within a React component, call `useAffiliationSalesPerMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliationSalesPerMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliationSalesPerMonthQuery({
 *   variables: {
 *      affiliation_id: // value for 'affiliation_id'
 *   },
 * });
 */
export function useAffiliationSalesPerMonthQuery(baseOptions?: Apollo.QueryHookOptions<AffiliationSalesPerMonthQuery, AffiliationSalesPerMonthQueryVariables>) {
        return Apollo.useQuery<AffiliationSalesPerMonthQuery, AffiliationSalesPerMonthQueryVariables>(AffiliationSalesPerMonthDocument, baseOptions);
      }
export function useAffiliationSalesPerMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AffiliationSalesPerMonthQuery, AffiliationSalesPerMonthQueryVariables>) {
          return Apollo.useLazyQuery<AffiliationSalesPerMonthQuery, AffiliationSalesPerMonthQueryVariables>(AffiliationSalesPerMonthDocument, baseOptions);
        }
export type AffiliationSalesPerMonthQueryHookResult = ReturnType<typeof useAffiliationSalesPerMonthQuery>;
export type AffiliationSalesPerMonthLazyQueryHookResult = ReturnType<typeof useAffiliationSalesPerMonthLazyQuery>;
export type AffiliationSalesPerMonthQueryResult = Apollo.QueryResult<AffiliationSalesPerMonthQuery, AffiliationSalesPerMonthQueryVariables>;
export const AffiliationsInBusinessDocument = gql`
    query AffiliationsInBusiness {
  AffiliationsInBusiness {
    id
    name
    creators_count
    created_at
    updated_at
  }
}
    `;

/**
 * __useAffiliationsInBusinessQuery__
 *
 * To run a query within a React component, call `useAffiliationsInBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliationsInBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliationsInBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useAffiliationsInBusinessQuery(baseOptions?: Apollo.QueryHookOptions<AffiliationsInBusinessQuery, AffiliationsInBusinessQueryVariables>) {
        return Apollo.useQuery<AffiliationsInBusinessQuery, AffiliationsInBusinessQueryVariables>(AffiliationsInBusinessDocument, baseOptions);
      }
export function useAffiliationsInBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AffiliationsInBusinessQuery, AffiliationsInBusinessQueryVariables>) {
          return Apollo.useLazyQuery<AffiliationsInBusinessQuery, AffiliationsInBusinessQueryVariables>(AffiliationsInBusinessDocument, baseOptions);
        }
export type AffiliationsInBusinessQueryHookResult = ReturnType<typeof useAffiliationsInBusinessQuery>;
export type AffiliationsInBusinessLazyQueryHookResult = ReturnType<typeof useAffiliationsInBusinessLazyQuery>;
export type AffiliationsInBusinessQueryResult = Apollo.QueryResult<AffiliationsInBusinessQuery, AffiliationsInBusinessQueryVariables>;
export const BusinessCreateVideoChatRoomTokenDocument = gql`
    query BusinessCreateVideoChatRoomToken($product_id: ID!, $creator_id: ID!) {
  BusinessCreateVideoChatRoomToken(product_id: $product_id, creator_id: $creator_id)
}
    `;

/**
 * __useBusinessCreateVideoChatRoomTokenQuery__
 *
 * To run a query within a React component, call `useBusinessCreateVideoChatRoomTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessCreateVideoChatRoomTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessCreateVideoChatRoomTokenQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useBusinessCreateVideoChatRoomTokenQuery(baseOptions?: Apollo.QueryHookOptions<BusinessCreateVideoChatRoomTokenQuery, BusinessCreateVideoChatRoomTokenQueryVariables>) {
        return Apollo.useQuery<BusinessCreateVideoChatRoomTokenQuery, BusinessCreateVideoChatRoomTokenQueryVariables>(BusinessCreateVideoChatRoomTokenDocument, baseOptions);
      }
export function useBusinessCreateVideoChatRoomTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessCreateVideoChatRoomTokenQuery, BusinessCreateVideoChatRoomTokenQueryVariables>) {
          return Apollo.useLazyQuery<BusinessCreateVideoChatRoomTokenQuery, BusinessCreateVideoChatRoomTokenQueryVariables>(BusinessCreateVideoChatRoomTokenDocument, baseOptions);
        }
export type BusinessCreateVideoChatRoomTokenQueryHookResult = ReturnType<typeof useBusinessCreateVideoChatRoomTokenQuery>;
export type BusinessCreateVideoChatRoomTokenLazyQueryHookResult = ReturnType<typeof useBusinessCreateVideoChatRoomTokenLazyQuery>;
export type BusinessCreateVideoChatRoomTokenQueryResult = Apollo.QueryResult<BusinessCreateVideoChatRoomTokenQuery, BusinessCreateVideoChatRoomTokenQueryVariables>;
export const BusinessCreatorMypageDocument = gql`
    query BusinessCreatorMypage($id: ID!) {
  BusinessCreatorMypage(id: $id) {
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    sales {
      month
      count
      sales
    }
    published_products {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      movie_seconds
      created_at
      updated_at
      deleted_at
    }
    reserved_products {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      movie_seconds
      created_at
      updated_at
      deleted_at
    }
    disabled_products {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      movie_seconds
      created_at
      updated_at
      deleted_at
    }
  }
}
    `;

/**
 * __useBusinessCreatorMypageQuery__
 *
 * To run a query within a React component, call `useBusinessCreatorMypageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessCreatorMypageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessCreatorMypageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessCreatorMypageQuery(baseOptions?: Apollo.QueryHookOptions<BusinessCreatorMypageQuery, BusinessCreatorMypageQueryVariables>) {
        return Apollo.useQuery<BusinessCreatorMypageQuery, BusinessCreatorMypageQueryVariables>(BusinessCreatorMypageDocument, baseOptions);
      }
export function useBusinessCreatorMypageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessCreatorMypageQuery, BusinessCreatorMypageQueryVariables>) {
          return Apollo.useLazyQuery<BusinessCreatorMypageQuery, BusinessCreatorMypageQueryVariables>(BusinessCreatorMypageDocument, baseOptions);
        }
export type BusinessCreatorMypageQueryHookResult = ReturnType<typeof useBusinessCreatorMypageQuery>;
export type BusinessCreatorMypageLazyQueryHookResult = ReturnType<typeof useBusinessCreatorMypageLazyQuery>;
export type BusinessCreatorMypageQueryResult = Apollo.QueryResult<BusinessCreatorMypageQuery, BusinessCreatorMypageQueryVariables>;
export const BusinessOrderedProductDocument = gql`
    query BusinessOrderedProduct($ordered_product_id: ID!) {
  BusinessOrderedProduct(ordered_product_id: $ordered_product_id) {
    id
    status
    live_talk_status
    creator_id
    order_id
    product_id
    is_sent_reminder_delivery_1day_ago_mail
    is_sent_reminder_delivery_1hour_ago_mail
    is_sent_reminder_delivery_after_mail
    created_at
    updated_at
    delivered_at
    delivery_limit_date
    is_delivered
    is_processing
    is_undelivered
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    order {
      id
      delivered_at
      paid_at
      movie_seconds
      status
      user {
        id
        status
        name
        email
        twitter_username
        created_at
        updated_at
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
    }
    product {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    latest_delivered_product {
      id
      ordered_product_id
      delivered_product_url
      delivered_product_thumbnail_url
      delivered_at
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useBusinessOrderedProductQuery__
 *
 * To run a query within a React component, call `useBusinessOrderedProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessOrderedProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessOrderedProductQuery({
 *   variables: {
 *      ordered_product_id: // value for 'ordered_product_id'
 *   },
 * });
 */
export function useBusinessOrderedProductQuery(baseOptions?: Apollo.QueryHookOptions<BusinessOrderedProductQuery, BusinessOrderedProductQueryVariables>) {
        return Apollo.useQuery<BusinessOrderedProductQuery, BusinessOrderedProductQueryVariables>(BusinessOrderedProductDocument, baseOptions);
      }
export function useBusinessOrderedProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessOrderedProductQuery, BusinessOrderedProductQueryVariables>) {
          return Apollo.useLazyQuery<BusinessOrderedProductQuery, BusinessOrderedProductQueryVariables>(BusinessOrderedProductDocument, baseOptions);
        }
export type BusinessOrderedProductQueryHookResult = ReturnType<typeof useBusinessOrderedProductQuery>;
export type BusinessOrderedProductLazyQueryHookResult = ReturnType<typeof useBusinessOrderedProductLazyQuery>;
export type BusinessOrderedProductQueryResult = Apollo.QueryResult<BusinessOrderedProductQuery, BusinessOrderedProductQueryVariables>;
export const BusinessProductDocument = gql`
    query BusinessProduct($product_id: ID!) {
  BusinessProduct(product_id: $product_id) {
    id
    name
    creator_id
    status
    price
    special_price
    stock
    remaining_stock
    sold_outed
    thumbnail_image
    released_at
    product_type
    product_type_name
    movie_seconds
    created_at
    updated_at
    deleted_at
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    is_movie
    is_photo
    is_live_talk
    live_talk {
      product_id
      started_at
      talk_minutes
    }
  }
}
    `;

/**
 * __useBusinessProductQuery__
 *
 * To run a query within a React component, call `useBusinessProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessProductQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useBusinessProductQuery(baseOptions?: Apollo.QueryHookOptions<BusinessProductQuery, BusinessProductQueryVariables>) {
        return Apollo.useQuery<BusinessProductQuery, BusinessProductQueryVariables>(BusinessProductDocument, baseOptions);
      }
export function useBusinessProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessProductQuery, BusinessProductQueryVariables>) {
          return Apollo.useLazyQuery<BusinessProductQuery, BusinessProductQueryVariables>(BusinessProductDocument, baseOptions);
        }
export type BusinessProductQueryHookResult = ReturnType<typeof useBusinessProductQuery>;
export type BusinessProductLazyQueryHookResult = ReturnType<typeof useBusinessProductLazyQuery>;
export type BusinessProductQueryResult = Apollo.QueryResult<BusinessProductQuery, BusinessProductQueryVariables>;
export const BusinessSalesPerMonthDocument = gql`
    query BusinessSalesPerMonth {
  BusinessSalesPerMonth {
    sales_per_month {
      month
      count
      sales
    }
    total_sales
  }
}
    `;

/**
 * __useBusinessSalesPerMonthQuery__
 *
 * To run a query within a React component, call `useBusinessSalesPerMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessSalesPerMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessSalesPerMonthQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessSalesPerMonthQuery(baseOptions?: Apollo.QueryHookOptions<BusinessSalesPerMonthQuery, BusinessSalesPerMonthQueryVariables>) {
        return Apollo.useQuery<BusinessSalesPerMonthQuery, BusinessSalesPerMonthQueryVariables>(BusinessSalesPerMonthDocument, baseOptions);
      }
export function useBusinessSalesPerMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessSalesPerMonthQuery, BusinessSalesPerMonthQueryVariables>) {
          return Apollo.useLazyQuery<BusinessSalesPerMonthQuery, BusinessSalesPerMonthQueryVariables>(BusinessSalesPerMonthDocument, baseOptions);
        }
export type BusinessSalesPerMonthQueryHookResult = ReturnType<typeof useBusinessSalesPerMonthQuery>;
export type BusinessSalesPerMonthLazyQueryHookResult = ReturnType<typeof useBusinessSalesPerMonthLazyQuery>;
export type BusinessSalesPerMonthQueryResult = Apollo.QueryResult<BusinessSalesPerMonthQuery, BusinessSalesPerMonthQueryVariables>;
export const BusinessSalesThisMonthDocument = gql`
    query BusinessSalesThisMonth {
  BusinessSalesThisMonth {
    month
    count
    sales
  }
}
    `;

/**
 * __useBusinessSalesThisMonthQuery__
 *
 * To run a query within a React component, call `useBusinessSalesThisMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessSalesThisMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessSalesThisMonthQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessSalesThisMonthQuery(baseOptions?: Apollo.QueryHookOptions<BusinessSalesThisMonthQuery, BusinessSalesThisMonthQueryVariables>) {
        return Apollo.useQuery<BusinessSalesThisMonthQuery, BusinessSalesThisMonthQueryVariables>(BusinessSalesThisMonthDocument, baseOptions);
      }
export function useBusinessSalesThisMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessSalesThisMonthQuery, BusinessSalesThisMonthQueryVariables>) {
          return Apollo.useLazyQuery<BusinessSalesThisMonthQuery, BusinessSalesThisMonthQueryVariables>(BusinessSalesThisMonthDocument, baseOptions);
        }
export type BusinessSalesThisMonthQueryHookResult = ReturnType<typeof useBusinessSalesThisMonthQuery>;
export type BusinessSalesThisMonthLazyQueryHookResult = ReturnType<typeof useBusinessSalesThisMonthLazyQuery>;
export type BusinessSalesThisMonthQueryResult = Apollo.QueryResult<BusinessSalesThisMonthQuery, BusinessSalesThisMonthQueryVariables>;
export const BusinessUndeliveredOrderedProductsDocument = gql`
    query BusinessUndeliveredOrderedProducts($creator_id: ID!) {
  BusinessUndeliveredOrderedProducts(creator_id: $creator_id) {
    id
    status
    live_talk_status
    creator_id
    order_id
    product_id
    is_sent_reminder_delivery_1day_ago_mail
    is_sent_reminder_delivery_1hour_ago_mail
    is_sent_reminder_delivery_after_mail
    created_at
    updated_at
    delivered_at
    delivery_limit_date
    is_delivered
    is_processing
    is_undelivered
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    order {
      id
      delivered_at
      paid_at
      movie_seconds
      status
      user {
        id
        status
        name
        email
        twitter_username
        created_at
        updated_at
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
    }
    product {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    latest_delivered_product {
      id
      ordered_product_id
      delivered_product_url
      delivered_product_thumbnail_url
      delivered_at
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useBusinessUndeliveredOrderedProductsQuery__
 *
 * To run a query within a React component, call `useBusinessUndeliveredOrderedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessUndeliveredOrderedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessUndeliveredOrderedProductsQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useBusinessUndeliveredOrderedProductsQuery(baseOptions?: Apollo.QueryHookOptions<BusinessUndeliveredOrderedProductsQuery, BusinessUndeliveredOrderedProductsQueryVariables>) {
        return Apollo.useQuery<BusinessUndeliveredOrderedProductsQuery, BusinessUndeliveredOrderedProductsQueryVariables>(BusinessUndeliveredOrderedProductsDocument, baseOptions);
      }
export function useBusinessUndeliveredOrderedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessUndeliveredOrderedProductsQuery, BusinessUndeliveredOrderedProductsQueryVariables>) {
          return Apollo.useLazyQuery<BusinessUndeliveredOrderedProductsQuery, BusinessUndeliveredOrderedProductsQueryVariables>(BusinessUndeliveredOrderedProductsDocument, baseOptions);
        }
export type BusinessUndeliveredOrderedProductsQueryHookResult = ReturnType<typeof useBusinessUndeliveredOrderedProductsQuery>;
export type BusinessUndeliveredOrderedProductsLazyQueryHookResult = ReturnType<typeof useBusinessUndeliveredOrderedProductsLazyQuery>;
export type BusinessUndeliveredOrderedProductsQueryResult = Apollo.QueryResult<BusinessUndeliveredOrderedProductsQuery, BusinessUndeliveredOrderedProductsQueryVariables>;
export const UserCreateVideoChatRoomTokenDocument = gql`
    query UserCreateVideoChatRoomToken($product_id: ID!) {
  UserCreateVideoChatRoomToken(product_id: $product_id)
}
    `;

/**
 * __useUserCreateVideoChatRoomTokenQuery__
 *
 * To run a query within a React component, call `useUserCreateVideoChatRoomTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreateVideoChatRoomTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreateVideoChatRoomTokenQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUserCreateVideoChatRoomTokenQuery(baseOptions?: Apollo.QueryHookOptions<UserCreateVideoChatRoomTokenQuery, UserCreateVideoChatRoomTokenQueryVariables>) {
        return Apollo.useQuery<UserCreateVideoChatRoomTokenQuery, UserCreateVideoChatRoomTokenQueryVariables>(UserCreateVideoChatRoomTokenDocument, baseOptions);
      }
export function useUserCreateVideoChatRoomTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCreateVideoChatRoomTokenQuery, UserCreateVideoChatRoomTokenQueryVariables>) {
          return Apollo.useLazyQuery<UserCreateVideoChatRoomTokenQuery, UserCreateVideoChatRoomTokenQueryVariables>(UserCreateVideoChatRoomTokenDocument, baseOptions);
        }
export type UserCreateVideoChatRoomTokenQueryHookResult = ReturnType<typeof useUserCreateVideoChatRoomTokenQuery>;
export type UserCreateVideoChatRoomTokenLazyQueryHookResult = ReturnType<typeof useUserCreateVideoChatRoomTokenLazyQuery>;
export type UserCreateVideoChatRoomTokenQueryResult = Apollo.QueryResult<UserCreateVideoChatRoomTokenQuery, UserCreateVideoChatRoomTokenQueryVariables>;
export const CreatorInBusinessDocument = gql`
    query CreatorInBusiness($id: ID!) {
  CreatorInBusiness(id: $id) {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    favorite_users_count
  }
}
    `;

/**
 * __useCreatorInBusinessQuery__
 *
 * To run a query within a React component, call `useCreatorInBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorInBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorInBusinessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreatorInBusinessQuery(baseOptions?: Apollo.QueryHookOptions<CreatorInBusinessQuery, CreatorInBusinessQueryVariables>) {
        return Apollo.useQuery<CreatorInBusinessQuery, CreatorInBusinessQueryVariables>(CreatorInBusinessDocument, baseOptions);
      }
export function useCreatorInBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorInBusinessQuery, CreatorInBusinessQueryVariables>) {
          return Apollo.useLazyQuery<CreatorInBusinessQuery, CreatorInBusinessQueryVariables>(CreatorInBusinessDocument, baseOptions);
        }
export type CreatorInBusinessQueryHookResult = ReturnType<typeof useCreatorInBusinessQuery>;
export type CreatorInBusinessLazyQueryHookResult = ReturnType<typeof useCreatorInBusinessLazyQuery>;
export type CreatorInBusinessQueryResult = Apollo.QueryResult<CreatorInBusinessQuery, CreatorInBusinessQueryVariables>;
export const CreatorMovieDeliveryProductUploadUrlDocument = gql`
    query CreatorMovieDeliveryProductUploadUrl($ordered_product_id: ID!) {
  CreatorMovieDeliveryProductUploadUrl(ordered_product_id: $ordered_product_id) {
    upload_url
    upload_file_path
  }
}
    `;

/**
 * __useCreatorMovieDeliveryProductUploadUrlQuery__
 *
 * To run a query within a React component, call `useCreatorMovieDeliveryProductUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorMovieDeliveryProductUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorMovieDeliveryProductUploadUrlQuery({
 *   variables: {
 *      ordered_product_id: // value for 'ordered_product_id'
 *   },
 * });
 */
export function useCreatorMovieDeliveryProductUploadUrlQuery(baseOptions?: Apollo.QueryHookOptions<CreatorMovieDeliveryProductUploadUrlQuery, CreatorMovieDeliveryProductUploadUrlQueryVariables>) {
        return Apollo.useQuery<CreatorMovieDeliveryProductUploadUrlQuery, CreatorMovieDeliveryProductUploadUrlQueryVariables>(CreatorMovieDeliveryProductUploadUrlDocument, baseOptions);
      }
export function useCreatorMovieDeliveryProductUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorMovieDeliveryProductUploadUrlQuery, CreatorMovieDeliveryProductUploadUrlQueryVariables>) {
          return Apollo.useLazyQuery<CreatorMovieDeliveryProductUploadUrlQuery, CreatorMovieDeliveryProductUploadUrlQueryVariables>(CreatorMovieDeliveryProductUploadUrlDocument, baseOptions);
        }
export type CreatorMovieDeliveryProductUploadUrlQueryHookResult = ReturnType<typeof useCreatorMovieDeliveryProductUploadUrlQuery>;
export type CreatorMovieDeliveryProductUploadUrlLazyQueryHookResult = ReturnType<typeof useCreatorMovieDeliveryProductUploadUrlLazyQuery>;
export type CreatorMovieDeliveryProductUploadUrlQueryResult = Apollo.QueryResult<CreatorMovieDeliveryProductUploadUrlQuery, CreatorMovieDeliveryProductUploadUrlQueryVariables>;
export const CreatorOrderedProductDocument = gql`
    query CreatorOrderedProduct($ordered_product_id: ID!) {
  CreatorOrderedProduct(ordered_product_id: $ordered_product_id) {
    id
    status
    live_talk_status
    creator_id
    order_id
    product_id
    is_sent_reminder_delivery_1day_ago_mail
    is_sent_reminder_delivery_1hour_ago_mail
    is_sent_reminder_delivery_after_mail
    created_at
    updated_at
    delivered_at
    delivery_limit_date
    is_delivered
    is_processing
    is_undelivered
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    order {
      id
      delivered_at
      paid_at
      movie_seconds
      status
      user {
        id
        status
        name
        email
        twitter_username
        created_at
        updated_at
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
    }
    product {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    latest_delivered_product {
      id
      ordered_product_id
      delivered_product_url
      delivered_product_thumbnail_url
      delivered_at
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useCreatorOrderedProductQuery__
 *
 * To run a query within a React component, call `useCreatorOrderedProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorOrderedProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorOrderedProductQuery({
 *   variables: {
 *      ordered_product_id: // value for 'ordered_product_id'
 *   },
 * });
 */
export function useCreatorOrderedProductQuery(baseOptions?: Apollo.QueryHookOptions<CreatorOrderedProductQuery, CreatorOrderedProductQueryVariables>) {
        return Apollo.useQuery<CreatorOrderedProductQuery, CreatorOrderedProductQueryVariables>(CreatorOrderedProductDocument, baseOptions);
      }
export function useCreatorOrderedProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorOrderedProductQuery, CreatorOrderedProductQueryVariables>) {
          return Apollo.useLazyQuery<CreatorOrderedProductQuery, CreatorOrderedProductQueryVariables>(CreatorOrderedProductDocument, baseOptions);
        }
export type CreatorOrderedProductQueryHookResult = ReturnType<typeof useCreatorOrderedProductQuery>;
export type CreatorOrderedProductLazyQueryHookResult = ReturnType<typeof useCreatorOrderedProductLazyQuery>;
export type CreatorOrderedProductQueryResult = Apollo.QueryResult<CreatorOrderedProductQuery, CreatorOrderedProductQueryVariables>;
export const CreatorProductDocument = gql`
    query CreatorProduct($product_id: ID!) {
  CreatorProduct(product_id: $product_id) {
    id
    name
    creator_id
    status
    price
    special_price
    stock
    remaining_stock
    sold_outed
    thumbnail_image
    released_at
    product_type
    product_type_name
    movie_seconds
    created_at
    updated_at
    deleted_at
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    is_movie
    is_photo
    is_live_talk
    live_talk {
      product_id
      started_at
      talk_minutes
    }
  }
}
    `;

/**
 * __useCreatorProductQuery__
 *
 * To run a query within a React component, call `useCreatorProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorProductQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useCreatorProductQuery(baseOptions?: Apollo.QueryHookOptions<CreatorProductQuery, CreatorProductQueryVariables>) {
        return Apollo.useQuery<CreatorProductQuery, CreatorProductQueryVariables>(CreatorProductDocument, baseOptions);
      }
export function useCreatorProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorProductQuery, CreatorProductQueryVariables>) {
          return Apollo.useLazyQuery<CreatorProductQuery, CreatorProductQueryVariables>(CreatorProductDocument, baseOptions);
        }
export type CreatorProductQueryHookResult = ReturnType<typeof useCreatorProductQuery>;
export type CreatorProductLazyQueryHookResult = ReturnType<typeof useCreatorProductLazyQuery>;
export type CreatorProductQueryResult = Apollo.QueryResult<CreatorProductQuery, CreatorProductQueryVariables>;
export const CreatorPurchaseUserRankingDocument = gql`
    query CreatorPurchaseUserRanking {
  CreatorPurchaseUserRanking {
    user_id
    user_name
    twitter_username
    amount
    purchase_count
  }
}
    `;

/**
 * __useCreatorPurchaseUserRankingQuery__
 *
 * To run a query within a React component, call `useCreatorPurchaseUserRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorPurchaseUserRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorPurchaseUserRankingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreatorPurchaseUserRankingQuery(baseOptions?: Apollo.QueryHookOptions<CreatorPurchaseUserRankingQuery, CreatorPurchaseUserRankingQueryVariables>) {
        return Apollo.useQuery<CreatorPurchaseUserRankingQuery, CreatorPurchaseUserRankingQueryVariables>(CreatorPurchaseUserRankingDocument, baseOptions);
      }
export function useCreatorPurchaseUserRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorPurchaseUserRankingQuery, CreatorPurchaseUserRankingQueryVariables>) {
          return Apollo.useLazyQuery<CreatorPurchaseUserRankingQuery, CreatorPurchaseUserRankingQueryVariables>(CreatorPurchaseUserRankingDocument, baseOptions);
        }
export type CreatorPurchaseUserRankingQueryHookResult = ReturnType<typeof useCreatorPurchaseUserRankingQuery>;
export type CreatorPurchaseUserRankingLazyQueryHookResult = ReturnType<typeof useCreatorPurchaseUserRankingLazyQuery>;
export type CreatorPurchaseUserRankingQueryResult = Apollo.QueryResult<CreatorPurchaseUserRankingQuery, CreatorPurchaseUserRankingQueryVariables>;
export const CreatorRankingOfDailySalesCountDocument = gql`
    query CreatorRankingOfDailySalesCount($input: CreatorRankingInput!) {
  CreatorRankingOfDailySalesCount(input: $input) {
    rank
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
  }
}
    `;

/**
 * __useCreatorRankingOfDailySalesCountQuery__
 *
 * To run a query within a React component, call `useCreatorRankingOfDailySalesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorRankingOfDailySalesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorRankingOfDailySalesCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorRankingOfDailySalesCountQuery(baseOptions?: Apollo.QueryHookOptions<CreatorRankingOfDailySalesCountQuery, CreatorRankingOfDailySalesCountQueryVariables>) {
        return Apollo.useQuery<CreatorRankingOfDailySalesCountQuery, CreatorRankingOfDailySalesCountQueryVariables>(CreatorRankingOfDailySalesCountDocument, baseOptions);
      }
export function useCreatorRankingOfDailySalesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorRankingOfDailySalesCountQuery, CreatorRankingOfDailySalesCountQueryVariables>) {
          return Apollo.useLazyQuery<CreatorRankingOfDailySalesCountQuery, CreatorRankingOfDailySalesCountQueryVariables>(CreatorRankingOfDailySalesCountDocument, baseOptions);
        }
export type CreatorRankingOfDailySalesCountQueryHookResult = ReturnType<typeof useCreatorRankingOfDailySalesCountQuery>;
export type CreatorRankingOfDailySalesCountLazyQueryHookResult = ReturnType<typeof useCreatorRankingOfDailySalesCountLazyQuery>;
export type CreatorRankingOfDailySalesCountQueryResult = Apollo.QueryResult<CreatorRankingOfDailySalesCountQuery, CreatorRankingOfDailySalesCountQueryVariables>;
export const CreatorRankingOfMonthlySalesCountDocument = gql`
    query CreatorRankingOfMonthlySalesCount($input: CreatorRankingInput!) {
  CreatorRankingOfMonthlySalesCount(input: $input) {
    rank
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
  }
}
    `;

/**
 * __useCreatorRankingOfMonthlySalesCountQuery__
 *
 * To run a query within a React component, call `useCreatorRankingOfMonthlySalesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorRankingOfMonthlySalesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorRankingOfMonthlySalesCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorRankingOfMonthlySalesCountQuery(baseOptions?: Apollo.QueryHookOptions<CreatorRankingOfMonthlySalesCountQuery, CreatorRankingOfMonthlySalesCountQueryVariables>) {
        return Apollo.useQuery<CreatorRankingOfMonthlySalesCountQuery, CreatorRankingOfMonthlySalesCountQueryVariables>(CreatorRankingOfMonthlySalesCountDocument, baseOptions);
      }
export function useCreatorRankingOfMonthlySalesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorRankingOfMonthlySalesCountQuery, CreatorRankingOfMonthlySalesCountQueryVariables>) {
          return Apollo.useLazyQuery<CreatorRankingOfMonthlySalesCountQuery, CreatorRankingOfMonthlySalesCountQueryVariables>(CreatorRankingOfMonthlySalesCountDocument, baseOptions);
        }
export type CreatorRankingOfMonthlySalesCountQueryHookResult = ReturnType<typeof useCreatorRankingOfMonthlySalesCountQuery>;
export type CreatorRankingOfMonthlySalesCountLazyQueryHookResult = ReturnType<typeof useCreatorRankingOfMonthlySalesCountLazyQuery>;
export type CreatorRankingOfMonthlySalesCountQueryResult = Apollo.QueryResult<CreatorRankingOfMonthlySalesCountQuery, CreatorRankingOfMonthlySalesCountQueryVariables>;
export const CreatorRankingOfWeeklySalesCountDocument = gql`
    query CreatorRankingOfWeeklySalesCount($input: CreatorRankingInput!) {
  CreatorRankingOfWeeklySalesCount(input: $input) {
    rank
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
  }
}
    `;

/**
 * __useCreatorRankingOfWeeklySalesCountQuery__
 *
 * To run a query within a React component, call `useCreatorRankingOfWeeklySalesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorRankingOfWeeklySalesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorRankingOfWeeklySalesCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorRankingOfWeeklySalesCountQuery(baseOptions?: Apollo.QueryHookOptions<CreatorRankingOfWeeklySalesCountQuery, CreatorRankingOfWeeklySalesCountQueryVariables>) {
        return Apollo.useQuery<CreatorRankingOfWeeklySalesCountQuery, CreatorRankingOfWeeklySalesCountQueryVariables>(CreatorRankingOfWeeklySalesCountDocument, baseOptions);
      }
export function useCreatorRankingOfWeeklySalesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorRankingOfWeeklySalesCountQuery, CreatorRankingOfWeeklySalesCountQueryVariables>) {
          return Apollo.useLazyQuery<CreatorRankingOfWeeklySalesCountQuery, CreatorRankingOfWeeklySalesCountQueryVariables>(CreatorRankingOfWeeklySalesCountDocument, baseOptions);
        }
export type CreatorRankingOfWeeklySalesCountQueryHookResult = ReturnType<typeof useCreatorRankingOfWeeklySalesCountQuery>;
export type CreatorRankingOfWeeklySalesCountLazyQueryHookResult = ReturnType<typeof useCreatorRankingOfWeeklySalesCountLazyQuery>;
export type CreatorRankingOfWeeklySalesCountQueryResult = Apollo.QueryResult<CreatorRankingOfWeeklySalesCountQuery, CreatorRankingOfWeeklySalesCountQueryVariables>;
export const CreatorSaleThisMonthDocument = gql`
    query CreatorSaleThisMonth($creator_id: ID!) {
  CreatorSaleThisMonth(creator_id: $creator_id) {
    month
    count
    sales
  }
}
    `;

/**
 * __useCreatorSaleThisMonthQuery__
 *
 * To run a query within a React component, call `useCreatorSaleThisMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorSaleThisMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorSaleThisMonthQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useCreatorSaleThisMonthQuery(baseOptions?: Apollo.QueryHookOptions<CreatorSaleThisMonthQuery, CreatorSaleThisMonthQueryVariables>) {
        return Apollo.useQuery<CreatorSaleThisMonthQuery, CreatorSaleThisMonthQueryVariables>(CreatorSaleThisMonthDocument, baseOptions);
      }
export function useCreatorSaleThisMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorSaleThisMonthQuery, CreatorSaleThisMonthQueryVariables>) {
          return Apollo.useLazyQuery<CreatorSaleThisMonthQuery, CreatorSaleThisMonthQueryVariables>(CreatorSaleThisMonthDocument, baseOptions);
        }
export type CreatorSaleThisMonthQueryHookResult = ReturnType<typeof useCreatorSaleThisMonthQuery>;
export type CreatorSaleThisMonthLazyQueryHookResult = ReturnType<typeof useCreatorSaleThisMonthLazyQuery>;
export type CreatorSaleThisMonthQueryResult = Apollo.QueryResult<CreatorSaleThisMonthQuery, CreatorSaleThisMonthQueryVariables>;
export const CreatorSalesPerMonthDocument = gql`
    query CreatorSalesPerMonth($creator_id: ID!) {
  CreatorSalesPerMonth(creator_id: $creator_id) {
    sales_per_month {
      month
      count
      sales
    }
    total_sales
  }
}
    `;

/**
 * __useCreatorSalesPerMonthQuery__
 *
 * To run a query within a React component, call `useCreatorSalesPerMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorSalesPerMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorSalesPerMonthQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useCreatorSalesPerMonthQuery(baseOptions?: Apollo.QueryHookOptions<CreatorSalesPerMonthQuery, CreatorSalesPerMonthQueryVariables>) {
        return Apollo.useQuery<CreatorSalesPerMonthQuery, CreatorSalesPerMonthQueryVariables>(CreatorSalesPerMonthDocument, baseOptions);
      }
export function useCreatorSalesPerMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorSalesPerMonthQuery, CreatorSalesPerMonthQueryVariables>) {
          return Apollo.useLazyQuery<CreatorSalesPerMonthQuery, CreatorSalesPerMonthQueryVariables>(CreatorSalesPerMonthDocument, baseOptions);
        }
export type CreatorSalesPerMonthQueryHookResult = ReturnType<typeof useCreatorSalesPerMonthQuery>;
export type CreatorSalesPerMonthLazyQueryHookResult = ReturnType<typeof useCreatorSalesPerMonthLazyQuery>;
export type CreatorSalesPerMonthQueryResult = Apollo.QueryResult<CreatorSalesPerMonthQuery, CreatorSalesPerMonthQueryVariables>;
export const CreatorSalesPerMonthInYearDocument = gql`
    query CreatorSalesPerMonthInYear($input: CreatorSalesPerMonthInYearInput!) {
  CreatorSalesPerMonthInYear(input: $input) {
    month
    count
    sales
  }
}
    `;

/**
 * __useCreatorSalesPerMonthInYearQuery__
 *
 * To run a query within a React component, call `useCreatorSalesPerMonthInYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorSalesPerMonthInYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorSalesPerMonthInYearQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatorSalesPerMonthInYearQuery(baseOptions?: Apollo.QueryHookOptions<CreatorSalesPerMonthInYearQuery, CreatorSalesPerMonthInYearQueryVariables>) {
        return Apollo.useQuery<CreatorSalesPerMonthInYearQuery, CreatorSalesPerMonthInYearQueryVariables>(CreatorSalesPerMonthInYearDocument, baseOptions);
      }
export function useCreatorSalesPerMonthInYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorSalesPerMonthInYearQuery, CreatorSalesPerMonthInYearQueryVariables>) {
          return Apollo.useLazyQuery<CreatorSalesPerMonthInYearQuery, CreatorSalesPerMonthInYearQueryVariables>(CreatorSalesPerMonthInYearDocument, baseOptions);
        }
export type CreatorSalesPerMonthInYearQueryHookResult = ReturnType<typeof useCreatorSalesPerMonthInYearQuery>;
export type CreatorSalesPerMonthInYearLazyQueryHookResult = ReturnType<typeof useCreatorSalesPerMonthInYearLazyQuery>;
export type CreatorSalesPerMonthInYearQueryResult = Apollo.QueryResult<CreatorSalesPerMonthInYearQuery, CreatorSalesPerMonthInYearQueryVariables>;
export const CreatorUndeliveredOrderedProductsDocument = gql`
    query CreatorUndeliveredOrderedProducts {
  CreatorUndeliveredOrderedProducts {
    id
    status
    live_talk_status
    creator_id
    order_id
    product_id
    is_sent_reminder_delivery_1day_ago_mail
    is_sent_reminder_delivery_1hour_ago_mail
    is_sent_reminder_delivery_after_mail
    created_at
    updated_at
    delivered_at
    delivery_limit_date
    is_delivered
    is_processing
    is_undelivered
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    order {
      id
      delivered_at
      paid_at
      movie_seconds
      status
      user {
        id
        status
        name
        email
        twitter_username
        created_at
        updated_at
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
    }
    product {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    latest_delivered_product {
      id
      ordered_product_id
      delivered_product_url
      delivered_product_thumbnail_url
      delivered_at
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useCreatorUndeliveredOrderedProductsQuery__
 *
 * To run a query within a React component, call `useCreatorUndeliveredOrderedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorUndeliveredOrderedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorUndeliveredOrderedProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreatorUndeliveredOrderedProductsQuery(baseOptions?: Apollo.QueryHookOptions<CreatorUndeliveredOrderedProductsQuery, CreatorUndeliveredOrderedProductsQueryVariables>) {
        return Apollo.useQuery<CreatorUndeliveredOrderedProductsQuery, CreatorUndeliveredOrderedProductsQueryVariables>(CreatorUndeliveredOrderedProductsDocument, baseOptions);
      }
export function useCreatorUndeliveredOrderedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorUndeliveredOrderedProductsQuery, CreatorUndeliveredOrderedProductsQueryVariables>) {
          return Apollo.useLazyQuery<CreatorUndeliveredOrderedProductsQuery, CreatorUndeliveredOrderedProductsQueryVariables>(CreatorUndeliveredOrderedProductsDocument, baseOptions);
        }
export type CreatorUndeliveredOrderedProductsQueryHookResult = ReturnType<typeof useCreatorUndeliveredOrderedProductsQuery>;
export type CreatorUndeliveredOrderedProductsLazyQueryHookResult = ReturnType<typeof useCreatorUndeliveredOrderedProductsLazyQuery>;
export type CreatorUndeliveredOrderedProductsQueryResult = Apollo.QueryResult<CreatorUndeliveredOrderedProductsQuery, CreatorUndeliveredOrderedProductsQueryVariables>;
export const CreatorsInAffiliationDocument = gql`
    query CreatorsInAffiliation($affiliation_id: ID) {
  CreatorsInAffiliation(affiliation_id: $affiliation_id) {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    favorite_users_count
  }
}
    `;

/**
 * __useCreatorsInAffiliationQuery__
 *
 * To run a query within a React component, call `useCreatorsInAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorsInAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorsInAffiliationQuery({
 *   variables: {
 *      affiliation_id: // value for 'affiliation_id'
 *   },
 * });
 */
export function useCreatorsInAffiliationQuery(baseOptions?: Apollo.QueryHookOptions<CreatorsInAffiliationQuery, CreatorsInAffiliationQueryVariables>) {
        return Apollo.useQuery<CreatorsInAffiliationQuery, CreatorsInAffiliationQueryVariables>(CreatorsInAffiliationDocument, baseOptions);
      }
export function useCreatorsInAffiliationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorsInAffiliationQuery, CreatorsInAffiliationQueryVariables>) {
          return Apollo.useLazyQuery<CreatorsInAffiliationQuery, CreatorsInAffiliationQueryVariables>(CreatorsInAffiliationDocument, baseOptions);
        }
export type CreatorsInAffiliationQueryHookResult = ReturnType<typeof useCreatorsInAffiliationQuery>;
export type CreatorsInAffiliationLazyQueryHookResult = ReturnType<typeof useCreatorsInAffiliationLazyQuery>;
export type CreatorsInAffiliationQueryResult = Apollo.QueryResult<CreatorsInAffiliationQuery, CreatorsInAffiliationQueryVariables>;
export const CurrentBusinessUserDocument = gql`
    query CurrentBusinessUser {
  CurrentBusinessUser {
    id
    name
    email
    created_at
    updated_at
  }
}
    `;

/**
 * __useCurrentBusinessUserQuery__
 *
 * To run a query within a React component, call `useCurrentBusinessUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentBusinessUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentBusinessUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentBusinessUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentBusinessUserQuery, CurrentBusinessUserQueryVariables>) {
        return Apollo.useQuery<CurrentBusinessUserQuery, CurrentBusinessUserQueryVariables>(CurrentBusinessUserDocument, baseOptions);
      }
export function useCurrentBusinessUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentBusinessUserQuery, CurrentBusinessUserQueryVariables>) {
          return Apollo.useLazyQuery<CurrentBusinessUserQuery, CurrentBusinessUserQueryVariables>(CurrentBusinessUserDocument, baseOptions);
        }
export type CurrentBusinessUserQueryHookResult = ReturnType<typeof useCurrentBusinessUserQuery>;
export type CurrentBusinessUserLazyQueryHookResult = ReturnType<typeof useCurrentBusinessUserLazyQuery>;
export type CurrentBusinessUserQueryResult = Apollo.QueryResult<CurrentBusinessUserQuery, CurrentBusinessUserQueryVariables>;
export const CurrentCreatorDocument = gql`
    query CurrentCreator {
  CurrentCreator {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    favorite_users_count
  }
}
    `;

/**
 * __useCurrentCreatorQuery__
 *
 * To run a query within a React component, call `useCurrentCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCreatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCreatorQuery(baseOptions?: Apollo.QueryHookOptions<CurrentCreatorQuery, CurrentCreatorQueryVariables>) {
        return Apollo.useQuery<CurrentCreatorQuery, CurrentCreatorQueryVariables>(CurrentCreatorDocument, baseOptions);
      }
export function useCurrentCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentCreatorQuery, CurrentCreatorQueryVariables>) {
          return Apollo.useLazyQuery<CurrentCreatorQuery, CurrentCreatorQueryVariables>(CurrentCreatorDocument, baseOptions);
        }
export type CurrentCreatorQueryHookResult = ReturnType<typeof useCurrentCreatorQuery>;
export type CurrentCreatorLazyQueryHookResult = ReturnType<typeof useCurrentCreatorLazyQuery>;
export type CurrentCreatorQueryResult = Apollo.QueryResult<CurrentCreatorQuery, CurrentCreatorQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  CurrentUser {
    id
    status
    name
    email
    twitter_username
    created_at
    updated_at
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GenerateTemporaryUrlForFilteredMonthlySalesDocument = gql`
    query GenerateTemporaryUrlForFilteredMonthlySales($input: GenerateTemporaryUrlForFilteredMonthlySalesInput!) {
  GenerateTemporaryUrlForFilteredMonthlySales(input: $input)
}
    `;

/**
 * __useGenerateTemporaryUrlForFilteredMonthlySalesQuery__
 *
 * To run a query within a React component, call `useGenerateTemporaryUrlForFilteredMonthlySalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateTemporaryUrlForFilteredMonthlySalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateTemporaryUrlForFilteredMonthlySalesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateTemporaryUrlForFilteredMonthlySalesQuery(baseOptions?: Apollo.QueryHookOptions<GenerateTemporaryUrlForFilteredMonthlySalesQuery, GenerateTemporaryUrlForFilteredMonthlySalesQueryVariables>) {
        return Apollo.useQuery<GenerateTemporaryUrlForFilteredMonthlySalesQuery, GenerateTemporaryUrlForFilteredMonthlySalesQueryVariables>(GenerateTemporaryUrlForFilteredMonthlySalesDocument, baseOptions);
      }
export function useGenerateTemporaryUrlForFilteredMonthlySalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateTemporaryUrlForFilteredMonthlySalesQuery, GenerateTemporaryUrlForFilteredMonthlySalesQueryVariables>) {
          return Apollo.useLazyQuery<GenerateTemporaryUrlForFilteredMonthlySalesQuery, GenerateTemporaryUrlForFilteredMonthlySalesQueryVariables>(GenerateTemporaryUrlForFilteredMonthlySalesDocument, baseOptions);
        }
export type GenerateTemporaryUrlForFilteredMonthlySalesQueryHookResult = ReturnType<typeof useGenerateTemporaryUrlForFilteredMonthlySalesQuery>;
export type GenerateTemporaryUrlForFilteredMonthlySalesLazyQueryHookResult = ReturnType<typeof useGenerateTemporaryUrlForFilteredMonthlySalesLazyQuery>;
export type GenerateTemporaryUrlForFilteredMonthlySalesQueryResult = Apollo.QueryResult<GenerateTemporaryUrlForFilteredMonthlySalesQuery, GenerateTemporaryUrlForFilteredMonthlySalesQueryVariables>;
export const GenerateTemporaryUrlForFilteredMonthlySalesByCreatorDocument = gql`
    query GenerateTemporaryUrlForFilteredMonthlySalesByCreator($input: GenerateTemporaryUrlForFilteredMonthlySalesByCreatorInput!) {
  GenerateTemporaryUrlForFilteredMonthlySalesByCreator(input: $input)
}
    `;

/**
 * __useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery__
 *
 * To run a query within a React component, call `useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery(baseOptions?: Apollo.QueryHookOptions<GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery, GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQueryVariables>) {
        return Apollo.useQuery<GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery, GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQueryVariables>(GenerateTemporaryUrlForFilteredMonthlySalesByCreatorDocument, baseOptions);
      }
export function useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery, GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQueryVariables>) {
          return Apollo.useLazyQuery<GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery, GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQueryVariables>(GenerateTemporaryUrlForFilteredMonthlySalesByCreatorDocument, baseOptions);
        }
export type GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQueryHookResult = ReturnType<typeof useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery>;
export type GenerateTemporaryUrlForFilteredMonthlySalesByCreatorLazyQueryHookResult = ReturnType<typeof useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorLazyQuery>;
export type GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQueryResult = Apollo.QueryResult<GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQuery, GenerateTemporaryUrlForFilteredMonthlySalesByCreatorQueryVariables>;
export const MovieDeliveryProductUploadUrlDocument = gql`
    query MovieDeliveryProductUploadUrl($ordered_product_id: ID!) {
  MovieDeliveryProductUploadUrl(ordered_product_id: $ordered_product_id) {
    upload_url
    upload_file_path
  }
}
    `;

/**
 * __useMovieDeliveryProductUploadUrlQuery__
 *
 * To run a query within a React component, call `useMovieDeliveryProductUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovieDeliveryProductUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovieDeliveryProductUploadUrlQuery({
 *   variables: {
 *      ordered_product_id: // value for 'ordered_product_id'
 *   },
 * });
 */
export function useMovieDeliveryProductUploadUrlQuery(baseOptions?: Apollo.QueryHookOptions<MovieDeliveryProductUploadUrlQuery, MovieDeliveryProductUploadUrlQueryVariables>) {
        return Apollo.useQuery<MovieDeliveryProductUploadUrlQuery, MovieDeliveryProductUploadUrlQueryVariables>(MovieDeliveryProductUploadUrlDocument, baseOptions);
      }
export function useMovieDeliveryProductUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MovieDeliveryProductUploadUrlQuery, MovieDeliveryProductUploadUrlQueryVariables>) {
          return Apollo.useLazyQuery<MovieDeliveryProductUploadUrlQuery, MovieDeliveryProductUploadUrlQueryVariables>(MovieDeliveryProductUploadUrlDocument, baseOptions);
        }
export type MovieDeliveryProductUploadUrlQueryHookResult = ReturnType<typeof useMovieDeliveryProductUploadUrlQuery>;
export type MovieDeliveryProductUploadUrlLazyQueryHookResult = ReturnType<typeof useMovieDeliveryProductUploadUrlLazyQuery>;
export type MovieDeliveryProductUploadUrlQueryResult = Apollo.QueryResult<MovieDeliveryProductUploadUrlQuery, MovieDeliveryProductUploadUrlQueryVariables>;
export const PagedActiveCreatorsDocument = gql`
    query PagedActiveCreators($input: ActiveCreatorsInput!, $first: Int!, $page: Int) {
  PagedActiveCreators(input: $input, first: $first, page: $page) {
    data {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
    `;

/**
 * __usePagedActiveCreatorsQuery__
 *
 * To run a query within a React component, call `usePagedActiveCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedActiveCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedActiveCreatorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePagedActiveCreatorsQuery(baseOptions?: Apollo.QueryHookOptions<PagedActiveCreatorsQuery, PagedActiveCreatorsQueryVariables>) {
        return Apollo.useQuery<PagedActiveCreatorsQuery, PagedActiveCreatorsQueryVariables>(PagedActiveCreatorsDocument, baseOptions);
      }
export function usePagedActiveCreatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedActiveCreatorsQuery, PagedActiveCreatorsQueryVariables>) {
          return Apollo.useLazyQuery<PagedActiveCreatorsQuery, PagedActiveCreatorsQueryVariables>(PagedActiveCreatorsDocument, baseOptions);
        }
export type PagedActiveCreatorsQueryHookResult = ReturnType<typeof usePagedActiveCreatorsQuery>;
export type PagedActiveCreatorsLazyQueryHookResult = ReturnType<typeof usePagedActiveCreatorsLazyQuery>;
export type PagedActiveCreatorsQueryResult = Apollo.QueryResult<PagedActiveCreatorsQuery, PagedActiveCreatorsQueryVariables>;
export const PagedAffiliationsInBusinessDocument = gql`
    query PagedAffiliationsInBusiness($first: Int!, $page: Int) {
  PagedAffiliationsInBusiness(first: $first, page: $page) {
    data {
      id
      name
      creators_count
      created_at
      updated_at
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedAffiliationsInBusinessQuery__
 *
 * To run a query within a React component, call `usePagedAffiliationsInBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedAffiliationsInBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedAffiliationsInBusinessQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePagedAffiliationsInBusinessQuery(baseOptions?: Apollo.QueryHookOptions<PagedAffiliationsInBusinessQuery, PagedAffiliationsInBusinessQueryVariables>) {
        return Apollo.useQuery<PagedAffiliationsInBusinessQuery, PagedAffiliationsInBusinessQueryVariables>(PagedAffiliationsInBusinessDocument, baseOptions);
      }
export function usePagedAffiliationsInBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedAffiliationsInBusinessQuery, PagedAffiliationsInBusinessQueryVariables>) {
          return Apollo.useLazyQuery<PagedAffiliationsInBusinessQuery, PagedAffiliationsInBusinessQueryVariables>(PagedAffiliationsInBusinessDocument, baseOptions);
        }
export type PagedAffiliationsInBusinessQueryHookResult = ReturnType<typeof usePagedAffiliationsInBusinessQuery>;
export type PagedAffiliationsInBusinessLazyQueryHookResult = ReturnType<typeof usePagedAffiliationsInBusinessLazyQuery>;
export type PagedAffiliationsInBusinessQueryResult = Apollo.QueryResult<PagedAffiliationsInBusinessQuery, PagedAffiliationsInBusinessQueryVariables>;
export const PagedBusinessDeliveredOrderedProductPerUserDocument = gql`
    query PagedBusinessDeliveredOrderedProductPerUser($page: Int, $first: Int!, $user_id: ID!, $creator_id: ID!) {
  PagedBusinessDeliveredOrderedProductPerUser(first: $first, page: $page, user_id: $user_id, creator_id: $creator_id) {
    data {
      id
      status
      live_talk_status
      creator_id
      order_id
      product_id
      is_sent_reminder_delivery_1day_ago_mail
      is_sent_reminder_delivery_1hour_ago_mail
      is_sent_reminder_delivery_after_mail
      created_at
      updated_at
      delivered_at
      delivery_limit_date
      is_delivered
      is_processing
      is_undelivered
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      order {
        id
        delivered_at
        paid_at
        movie_seconds
        status
        user {
          id
          status
          name
          email
          twitter_username
          created_at
          updated_at
        }
        product {
          id
          name
          creator_id
          status
          price
          special_price
          stock
          remaining_stock
          sold_outed
          thumbnail_image
          released_at
          product_type
          product_type_name
          movie_seconds
          created_at
          updated_at
          deleted_at
          creator {
            id
            business_id
            affiliation_id
            status
            icon_url
            name
            email
            profile
            twitter_username
            instagram_username
            youtube_channel_url
            kindle_author_url
            left_at
            created_at
            updated_at
            status_name
            is_favorite
            affiliation {
              id
              name
              creators_count
              created_at
              updated_at
            }
            favorite_users_count
          }
          is_movie
          is_photo
          is_live_talk
          live_talk {
            product_id
            started_at
            talk_minutes
          }
        }
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
      latest_delivered_product {
        id
        ordered_product_id
        delivered_product_url
        delivered_product_thumbnail_url
        delivered_at
        created_at
        updated_at
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedBusinessDeliveredOrderedProductPerUserQuery__
 *
 * To run a query within a React component, call `usePagedBusinessDeliveredOrderedProductPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedBusinessDeliveredOrderedProductPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedBusinessDeliveredOrderedProductPerUserQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *      user_id: // value for 'user_id'
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function usePagedBusinessDeliveredOrderedProductPerUserQuery(baseOptions?: Apollo.QueryHookOptions<PagedBusinessDeliveredOrderedProductPerUserQuery, PagedBusinessDeliveredOrderedProductPerUserQueryVariables>) {
        return Apollo.useQuery<PagedBusinessDeliveredOrderedProductPerUserQuery, PagedBusinessDeliveredOrderedProductPerUserQueryVariables>(PagedBusinessDeliveredOrderedProductPerUserDocument, baseOptions);
      }
export function usePagedBusinessDeliveredOrderedProductPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedBusinessDeliveredOrderedProductPerUserQuery, PagedBusinessDeliveredOrderedProductPerUserQueryVariables>) {
          return Apollo.useLazyQuery<PagedBusinessDeliveredOrderedProductPerUserQuery, PagedBusinessDeliveredOrderedProductPerUserQueryVariables>(PagedBusinessDeliveredOrderedProductPerUserDocument, baseOptions);
        }
export type PagedBusinessDeliveredOrderedProductPerUserQueryHookResult = ReturnType<typeof usePagedBusinessDeliveredOrderedProductPerUserQuery>;
export type PagedBusinessDeliveredOrderedProductPerUserLazyQueryHookResult = ReturnType<typeof usePagedBusinessDeliveredOrderedProductPerUserLazyQuery>;
export type PagedBusinessDeliveredOrderedProductPerUserQueryResult = Apollo.QueryResult<PagedBusinessDeliveredOrderedProductPerUserQuery, PagedBusinessDeliveredOrderedProductPerUserQueryVariables>;
export const PagedBusinessDeliveredOrderedProductsDocument = gql`
    query PagedBusinessDeliveredOrderedProducts($creator_id: ID!, $page: Int, $first: Int!) {
  PagedBusinessDeliveredOrderedProducts(creator_id: $creator_id, first: $first, page: $page) {
    data {
      id
      status
      live_talk_status
      creator_id
      order_id
      product_id
      is_sent_reminder_delivery_1day_ago_mail
      is_sent_reminder_delivery_1hour_ago_mail
      is_sent_reminder_delivery_after_mail
      created_at
      updated_at
      delivered_at
      delivery_limit_date
      is_delivered
      is_processing
      is_undelivered
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      order {
        id
        delivered_at
        paid_at
        movie_seconds
        status
        user {
          id
          status
          name
          email
          twitter_username
          created_at
          updated_at
        }
        product {
          id
          name
          creator_id
          status
          price
          special_price
          stock
          remaining_stock
          sold_outed
          thumbnail_image
          released_at
          product_type
          product_type_name
          movie_seconds
          created_at
          updated_at
          deleted_at
          creator {
            id
            business_id
            affiliation_id
            status
            icon_url
            name
            email
            profile
            twitter_username
            instagram_username
            youtube_channel_url
            kindle_author_url
            left_at
            created_at
            updated_at
            status_name
            is_favorite
            affiliation {
              id
              name
              creators_count
              created_at
              updated_at
            }
            favorite_users_count
          }
          is_movie
          is_photo
          is_live_talk
          live_talk {
            product_id
            started_at
            talk_minutes
          }
        }
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
      latest_delivered_product {
        id
        ordered_product_id
        delivered_product_url
        delivered_product_thumbnail_url
        delivered_at
        created_at
        updated_at
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedBusinessDeliveredOrderedProductsQuery__
 *
 * To run a query within a React component, call `usePagedBusinessDeliveredOrderedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedBusinessDeliveredOrderedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedBusinessDeliveredOrderedProductsQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedBusinessDeliveredOrderedProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedBusinessDeliveredOrderedProductsQuery, PagedBusinessDeliveredOrderedProductsQueryVariables>) {
        return Apollo.useQuery<PagedBusinessDeliveredOrderedProductsQuery, PagedBusinessDeliveredOrderedProductsQueryVariables>(PagedBusinessDeliveredOrderedProductsDocument, baseOptions);
      }
export function usePagedBusinessDeliveredOrderedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedBusinessDeliveredOrderedProductsQuery, PagedBusinessDeliveredOrderedProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedBusinessDeliveredOrderedProductsQuery, PagedBusinessDeliveredOrderedProductsQueryVariables>(PagedBusinessDeliveredOrderedProductsDocument, baseOptions);
        }
export type PagedBusinessDeliveredOrderedProductsQueryHookResult = ReturnType<typeof usePagedBusinessDeliveredOrderedProductsQuery>;
export type PagedBusinessDeliveredOrderedProductsLazyQueryHookResult = ReturnType<typeof usePagedBusinessDeliveredOrderedProductsLazyQuery>;
export type PagedBusinessDeliveredOrderedProductsQueryResult = Apollo.QueryResult<PagedBusinessDeliveredOrderedProductsQuery, PagedBusinessDeliveredOrderedProductsQueryVariables>;
export const PagedBusinessDisabledProductsDocument = gql`
    query PagedBusinessDisabledProducts($creator_id: ID!, $page: Int, $first: Int!) {
  PagedBusinessDisabledProducts(creator_id: $creator_id, first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedBusinessDisabledProductsQuery__
 *
 * To run a query within a React component, call `usePagedBusinessDisabledProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedBusinessDisabledProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedBusinessDisabledProductsQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedBusinessDisabledProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedBusinessDisabledProductsQuery, PagedBusinessDisabledProductsQueryVariables>) {
        return Apollo.useQuery<PagedBusinessDisabledProductsQuery, PagedBusinessDisabledProductsQueryVariables>(PagedBusinessDisabledProductsDocument, baseOptions);
      }
export function usePagedBusinessDisabledProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedBusinessDisabledProductsQuery, PagedBusinessDisabledProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedBusinessDisabledProductsQuery, PagedBusinessDisabledProductsQueryVariables>(PagedBusinessDisabledProductsDocument, baseOptions);
        }
export type PagedBusinessDisabledProductsQueryHookResult = ReturnType<typeof usePagedBusinessDisabledProductsQuery>;
export type PagedBusinessDisabledProductsLazyQueryHookResult = ReturnType<typeof usePagedBusinessDisabledProductsLazyQuery>;
export type PagedBusinessDisabledProductsQueryResult = Apollo.QueryResult<PagedBusinessDisabledProductsQuery, PagedBusinessDisabledProductsQueryVariables>;
export const PagedBusinessOrderedUsersDocument = gql`
    query PagedBusinessOrderedUsers($page: Int, $first: Int!, $sort: String, $creator_id: ID!) {
  PagedBusinessOrderedUsers(first: $first, page: $page, sort: $sort, creator_id: $creator_id) {
    data {
      user_id
      user_name
      last_paid_at
      number_of_purchases
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedBusinessOrderedUsersQuery__
 *
 * To run a query within a React component, call `usePagedBusinessOrderedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedBusinessOrderedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedBusinessOrderedUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function usePagedBusinessOrderedUsersQuery(baseOptions?: Apollo.QueryHookOptions<PagedBusinessOrderedUsersQuery, PagedBusinessOrderedUsersQueryVariables>) {
        return Apollo.useQuery<PagedBusinessOrderedUsersQuery, PagedBusinessOrderedUsersQueryVariables>(PagedBusinessOrderedUsersDocument, baseOptions);
      }
export function usePagedBusinessOrderedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedBusinessOrderedUsersQuery, PagedBusinessOrderedUsersQueryVariables>) {
          return Apollo.useLazyQuery<PagedBusinessOrderedUsersQuery, PagedBusinessOrderedUsersQueryVariables>(PagedBusinessOrderedUsersDocument, baseOptions);
        }
export type PagedBusinessOrderedUsersQueryHookResult = ReturnType<typeof usePagedBusinessOrderedUsersQuery>;
export type PagedBusinessOrderedUsersLazyQueryHookResult = ReturnType<typeof usePagedBusinessOrderedUsersLazyQuery>;
export type PagedBusinessOrderedUsersQueryResult = Apollo.QueryResult<PagedBusinessOrderedUsersQuery, PagedBusinessOrderedUsersQueryVariables>;
export const PagedBusinessPublishedProductsDocument = gql`
    query PagedBusinessPublishedProducts($creator_id: ID!, $exclude_product_id: ID, $page: Int, $first: Int!) {
  PagedBusinessPublishedProducts(creator_id: $creator_id, exclude_product_id: $exclude_product_id, first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedBusinessPublishedProductsQuery__
 *
 * To run a query within a React component, call `usePagedBusinessPublishedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedBusinessPublishedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedBusinessPublishedProductsQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      exclude_product_id: // value for 'exclude_product_id'
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedBusinessPublishedProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedBusinessPublishedProductsQuery, PagedBusinessPublishedProductsQueryVariables>) {
        return Apollo.useQuery<PagedBusinessPublishedProductsQuery, PagedBusinessPublishedProductsQueryVariables>(PagedBusinessPublishedProductsDocument, baseOptions);
      }
export function usePagedBusinessPublishedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedBusinessPublishedProductsQuery, PagedBusinessPublishedProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedBusinessPublishedProductsQuery, PagedBusinessPublishedProductsQueryVariables>(PagedBusinessPublishedProductsDocument, baseOptions);
        }
export type PagedBusinessPublishedProductsQueryHookResult = ReturnType<typeof usePagedBusinessPublishedProductsQuery>;
export type PagedBusinessPublishedProductsLazyQueryHookResult = ReturnType<typeof usePagedBusinessPublishedProductsLazyQuery>;
export type PagedBusinessPublishedProductsQueryResult = Apollo.QueryResult<PagedBusinessPublishedProductsQuery, PagedBusinessPublishedProductsQueryVariables>;
export const PagedBusinessReservedProductsDocument = gql`
    query PagedBusinessReservedProducts($creator_id: ID!, $first: Int!, $page: Int) {
  PagedBusinessReservedProducts(creator_id: $creator_id, first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedBusinessReservedProductsQuery__
 *
 * To run a query within a React component, call `usePagedBusinessReservedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedBusinessReservedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedBusinessReservedProductsQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePagedBusinessReservedProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedBusinessReservedProductsQuery, PagedBusinessReservedProductsQueryVariables>) {
        return Apollo.useQuery<PagedBusinessReservedProductsQuery, PagedBusinessReservedProductsQueryVariables>(PagedBusinessReservedProductsDocument, baseOptions);
      }
export function usePagedBusinessReservedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedBusinessReservedProductsQuery, PagedBusinessReservedProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedBusinessReservedProductsQuery, PagedBusinessReservedProductsQueryVariables>(PagedBusinessReservedProductsDocument, baseOptions);
        }
export type PagedBusinessReservedProductsQueryHookResult = ReturnType<typeof usePagedBusinessReservedProductsQuery>;
export type PagedBusinessReservedProductsLazyQueryHookResult = ReturnType<typeof usePagedBusinessReservedProductsLazyQuery>;
export type PagedBusinessReservedProductsQueryResult = Apollo.QueryResult<PagedBusinessReservedProductsQuery, PagedBusinessReservedProductsQueryVariables>;
export const PagedBusinessUndeliveredOrderedProductPerUserDocument = gql`
    query PagedBusinessUndeliveredOrderedProductPerUser($page: Int, $first: Int!, $user_id: ID!, $creator_id: ID!) {
  PagedBusinessUndeliveredOrderedProductPerUser(first: $first, page: $page, user_id: $user_id, creator_id: $creator_id) {
    data {
      id
      status
      live_talk_status
      creator_id
      order_id
      product_id
      is_sent_reminder_delivery_1day_ago_mail
      is_sent_reminder_delivery_1hour_ago_mail
      is_sent_reminder_delivery_after_mail
      created_at
      updated_at
      delivery_limit_date
      is_delivered
      is_processing
      is_undelivered
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      order {
        id
        delivered_at
        paid_at
        movie_seconds
        status
        user {
          id
          status
          name
          email
          twitter_username
          created_at
          updated_at
        }
        product {
          id
          name
          creator_id
          status
          price
          special_price
          stock
          remaining_stock
          sold_outed
          thumbnail_image
          released_at
          product_type
          product_type_name
          movie_seconds
          created_at
          updated_at
          deleted_at
          creator {
            id
            business_id
            affiliation_id
            status
            icon_url
            name
            email
            profile
            twitter_username
            instagram_username
            youtube_channel_url
            kindle_author_url
            left_at
            created_at
            updated_at
            status_name
            is_favorite
            affiliation {
              id
              name
              creators_count
              created_at
              updated_at
            }
            favorite_users_count
          }
          is_movie
          is_photo
          is_live_talk
          live_talk {
            product_id
            started_at
            talk_minutes
          }
        }
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedBusinessUndeliveredOrderedProductPerUserQuery__
 *
 * To run a query within a React component, call `usePagedBusinessUndeliveredOrderedProductPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedBusinessUndeliveredOrderedProductPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedBusinessUndeliveredOrderedProductPerUserQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *      user_id: // value for 'user_id'
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function usePagedBusinessUndeliveredOrderedProductPerUserQuery(baseOptions?: Apollo.QueryHookOptions<PagedBusinessUndeliveredOrderedProductPerUserQuery, PagedBusinessUndeliveredOrderedProductPerUserQueryVariables>) {
        return Apollo.useQuery<PagedBusinessUndeliveredOrderedProductPerUserQuery, PagedBusinessUndeliveredOrderedProductPerUserQueryVariables>(PagedBusinessUndeliveredOrderedProductPerUserDocument, baseOptions);
      }
export function usePagedBusinessUndeliveredOrderedProductPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedBusinessUndeliveredOrderedProductPerUserQuery, PagedBusinessUndeliveredOrderedProductPerUserQueryVariables>) {
          return Apollo.useLazyQuery<PagedBusinessUndeliveredOrderedProductPerUserQuery, PagedBusinessUndeliveredOrderedProductPerUserQueryVariables>(PagedBusinessUndeliveredOrderedProductPerUserDocument, baseOptions);
        }
export type PagedBusinessUndeliveredOrderedProductPerUserQueryHookResult = ReturnType<typeof usePagedBusinessUndeliveredOrderedProductPerUserQuery>;
export type PagedBusinessUndeliveredOrderedProductPerUserLazyQueryHookResult = ReturnType<typeof usePagedBusinessUndeliveredOrderedProductPerUserLazyQuery>;
export type PagedBusinessUndeliveredOrderedProductPerUserQueryResult = Apollo.QueryResult<PagedBusinessUndeliveredOrderedProductPerUserQuery, PagedBusinessUndeliveredOrderedProductPerUserQueryVariables>;
export const PagedCreatorDeliveredOrderedProductPerUserDocument = gql`
    query PagedCreatorDeliveredOrderedProductPerUser($page: Int, $first: Int!, $user_id: ID!) {
  PagedCreatorDeliveredOrderedProductPerUser(first: $first, page: $page, user_id: $user_id) {
    data {
      id
      status
      live_talk_status
      creator_id
      order_id
      product_id
      is_sent_reminder_delivery_1day_ago_mail
      is_sent_reminder_delivery_1hour_ago_mail
      is_sent_reminder_delivery_after_mail
      created_at
      updated_at
      delivered_at
      delivery_limit_date
      is_delivered
      is_processing
      is_undelivered
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      order {
        id
        delivered_at
        paid_at
        movie_seconds
        status
        user {
          id
          status
          name
          email
          twitter_username
          created_at
          updated_at
        }
        product {
          id
          name
          creator_id
          status
          price
          special_price
          stock
          remaining_stock
          sold_outed
          thumbnail_image
          released_at
          product_type
          product_type_name
          movie_seconds
          created_at
          updated_at
          deleted_at
          creator {
            id
            business_id
            affiliation_id
            status
            icon_url
            name
            email
            profile
            twitter_username
            instagram_username
            youtube_channel_url
            kindle_author_url
            left_at
            created_at
            updated_at
            status_name
            is_favorite
            affiliation {
              id
              name
              creators_count
              created_at
              updated_at
            }
            favorite_users_count
          }
          is_movie
          is_photo
          is_live_talk
          live_talk {
            product_id
            started_at
            talk_minutes
          }
        }
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
      latest_delivered_product {
        id
        ordered_product_id
        delivered_product_url
        delivered_product_thumbnail_url
        delivered_at
        created_at
        updated_at
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedCreatorDeliveredOrderedProductPerUserQuery__
 *
 * To run a query within a React component, call `usePagedCreatorDeliveredOrderedProductPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorDeliveredOrderedProductPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorDeliveredOrderedProductPerUserQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function usePagedCreatorDeliveredOrderedProductPerUserQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorDeliveredOrderedProductPerUserQuery, PagedCreatorDeliveredOrderedProductPerUserQueryVariables>) {
        return Apollo.useQuery<PagedCreatorDeliveredOrderedProductPerUserQuery, PagedCreatorDeliveredOrderedProductPerUserQueryVariables>(PagedCreatorDeliveredOrderedProductPerUserDocument, baseOptions);
      }
export function usePagedCreatorDeliveredOrderedProductPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorDeliveredOrderedProductPerUserQuery, PagedCreatorDeliveredOrderedProductPerUserQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorDeliveredOrderedProductPerUserQuery, PagedCreatorDeliveredOrderedProductPerUserQueryVariables>(PagedCreatorDeliveredOrderedProductPerUserDocument, baseOptions);
        }
export type PagedCreatorDeliveredOrderedProductPerUserQueryHookResult = ReturnType<typeof usePagedCreatorDeliveredOrderedProductPerUserQuery>;
export type PagedCreatorDeliveredOrderedProductPerUserLazyQueryHookResult = ReturnType<typeof usePagedCreatorDeliveredOrderedProductPerUserLazyQuery>;
export type PagedCreatorDeliveredOrderedProductPerUserQueryResult = Apollo.QueryResult<PagedCreatorDeliveredOrderedProductPerUserQuery, PagedCreatorDeliveredOrderedProductPerUserQueryVariables>;
export const PagedCreatorDeliveredOrderedProductsDocument = gql`
    query PagedCreatorDeliveredOrderedProducts($page: Int, $first: Int!) {
  PagedCreatorDeliveredOrderedProducts(first: $first, page: $page) {
    data {
      id
      status
      live_talk_status
      creator_id
      order_id
      product_id
      is_sent_reminder_delivery_1day_ago_mail
      is_sent_reminder_delivery_1hour_ago_mail
      is_sent_reminder_delivery_after_mail
      created_at
      updated_at
      delivered_at
      delivery_limit_date
      is_delivered
      is_processing
      is_undelivered
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      order {
        id
        delivered_at
        paid_at
        movie_seconds
        status
        user {
          id
          status
          name
          email
          twitter_username
          created_at
          updated_at
        }
        product {
          id
          name
          creator_id
          status
          price
          special_price
          stock
          remaining_stock
          sold_outed
          thumbnail_image
          released_at
          product_type
          product_type_name
          movie_seconds
          created_at
          updated_at
          deleted_at
          creator {
            id
            business_id
            affiliation_id
            status
            icon_url
            name
            email
            profile
            twitter_username
            instagram_username
            youtube_channel_url
            kindle_author_url
            left_at
            created_at
            updated_at
            status_name
            is_favorite
            affiliation {
              id
              name
              creators_count
              created_at
              updated_at
            }
            favorite_users_count
          }
          is_movie
          is_photo
          is_live_talk
          live_talk {
            product_id
            started_at
            talk_minutes
          }
        }
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
      latest_delivered_product {
        id
        ordered_product_id
        delivered_product_url
        delivered_product_thumbnail_url
        delivered_at
        created_at
        updated_at
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedCreatorDeliveredOrderedProductsQuery__
 *
 * To run a query within a React component, call `usePagedCreatorDeliveredOrderedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorDeliveredOrderedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorDeliveredOrderedProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedCreatorDeliveredOrderedProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorDeliveredOrderedProductsQuery, PagedCreatorDeliveredOrderedProductsQueryVariables>) {
        return Apollo.useQuery<PagedCreatorDeliveredOrderedProductsQuery, PagedCreatorDeliveredOrderedProductsQueryVariables>(PagedCreatorDeliveredOrderedProductsDocument, baseOptions);
      }
export function usePagedCreatorDeliveredOrderedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorDeliveredOrderedProductsQuery, PagedCreatorDeliveredOrderedProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorDeliveredOrderedProductsQuery, PagedCreatorDeliveredOrderedProductsQueryVariables>(PagedCreatorDeliveredOrderedProductsDocument, baseOptions);
        }
export type PagedCreatorDeliveredOrderedProductsQueryHookResult = ReturnType<typeof usePagedCreatorDeliveredOrderedProductsQuery>;
export type PagedCreatorDeliveredOrderedProductsLazyQueryHookResult = ReturnType<typeof usePagedCreatorDeliveredOrderedProductsLazyQuery>;
export type PagedCreatorDeliveredOrderedProductsQueryResult = Apollo.QueryResult<PagedCreatorDeliveredOrderedProductsQuery, PagedCreatorDeliveredOrderedProductsQueryVariables>;
export const PagedCreatorDisabledProductsDocument = gql`
    query PagedCreatorDisabledProducts($page: Int, $first: Int!) {
  PagedCreatorDisabledProducts(first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedCreatorDisabledProductsQuery__
 *
 * To run a query within a React component, call `usePagedCreatorDisabledProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorDisabledProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorDisabledProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedCreatorDisabledProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorDisabledProductsQuery, PagedCreatorDisabledProductsQueryVariables>) {
        return Apollo.useQuery<PagedCreatorDisabledProductsQuery, PagedCreatorDisabledProductsQueryVariables>(PagedCreatorDisabledProductsDocument, baseOptions);
      }
export function usePagedCreatorDisabledProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorDisabledProductsQuery, PagedCreatorDisabledProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorDisabledProductsQuery, PagedCreatorDisabledProductsQueryVariables>(PagedCreatorDisabledProductsDocument, baseOptions);
        }
export type PagedCreatorDisabledProductsQueryHookResult = ReturnType<typeof usePagedCreatorDisabledProductsQuery>;
export type PagedCreatorDisabledProductsLazyQueryHookResult = ReturnType<typeof usePagedCreatorDisabledProductsLazyQuery>;
export type PagedCreatorDisabledProductsQueryResult = Apollo.QueryResult<PagedCreatorDisabledProductsQuery, PagedCreatorDisabledProductsQueryVariables>;
export const PagedCreatorOrderedUsersDocument = gql`
    query PagedCreatorOrderedUsers($page: Int, $first: Int!, $sort: String) {
  PagedCreatorOrderedUsers(first: $first, page: $page, sort: $sort) {
    data {
      user_id
      user_name
      last_paid_at
      number_of_purchases
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedCreatorOrderedUsersQuery__
 *
 * To run a query within a React component, call `usePagedCreatorOrderedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorOrderedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorOrderedUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePagedCreatorOrderedUsersQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorOrderedUsersQuery, PagedCreatorOrderedUsersQueryVariables>) {
        return Apollo.useQuery<PagedCreatorOrderedUsersQuery, PagedCreatorOrderedUsersQueryVariables>(PagedCreatorOrderedUsersDocument, baseOptions);
      }
export function usePagedCreatorOrderedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorOrderedUsersQuery, PagedCreatorOrderedUsersQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorOrderedUsersQuery, PagedCreatorOrderedUsersQueryVariables>(PagedCreatorOrderedUsersDocument, baseOptions);
        }
export type PagedCreatorOrderedUsersQueryHookResult = ReturnType<typeof usePagedCreatorOrderedUsersQuery>;
export type PagedCreatorOrderedUsersLazyQueryHookResult = ReturnType<typeof usePagedCreatorOrderedUsersLazyQuery>;
export type PagedCreatorOrderedUsersQueryResult = Apollo.QueryResult<PagedCreatorOrderedUsersQuery, PagedCreatorOrderedUsersQueryVariables>;
export const PagedCreatorPublishedProductsDocument = gql`
    query PagedCreatorPublishedProducts($exclude_product_id: ID!, $page: Int, $first: Int!) {
  PagedCreatorPublishedProducts(exclude_product_id: $exclude_product_id, first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedCreatorPublishedProductsQuery__
 *
 * To run a query within a React component, call `usePagedCreatorPublishedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorPublishedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorPublishedProductsQuery({
 *   variables: {
 *      exclude_product_id: // value for 'exclude_product_id'
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedCreatorPublishedProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorPublishedProductsQuery, PagedCreatorPublishedProductsQueryVariables>) {
        return Apollo.useQuery<PagedCreatorPublishedProductsQuery, PagedCreatorPublishedProductsQueryVariables>(PagedCreatorPublishedProductsDocument, baseOptions);
      }
export function usePagedCreatorPublishedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorPublishedProductsQuery, PagedCreatorPublishedProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorPublishedProductsQuery, PagedCreatorPublishedProductsQueryVariables>(PagedCreatorPublishedProductsDocument, baseOptions);
        }
export type PagedCreatorPublishedProductsQueryHookResult = ReturnType<typeof usePagedCreatorPublishedProductsQuery>;
export type PagedCreatorPublishedProductsLazyQueryHookResult = ReturnType<typeof usePagedCreatorPublishedProductsLazyQuery>;
export type PagedCreatorPublishedProductsQueryResult = Apollo.QueryResult<PagedCreatorPublishedProductsQuery, PagedCreatorPublishedProductsQueryVariables>;
export const PagedCreatorReservedProductsDocument = gql`
    query PagedCreatorReservedProducts($page: Int, $first: Int!) {
  PagedCreatorReservedProducts(first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      created_at
      updated_at
      deleted_at
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedCreatorReservedProductsQuery__
 *
 * To run a query within a React component, call `usePagedCreatorReservedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorReservedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorReservedProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedCreatorReservedProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorReservedProductsQuery, PagedCreatorReservedProductsQueryVariables>) {
        return Apollo.useQuery<PagedCreatorReservedProductsQuery, PagedCreatorReservedProductsQueryVariables>(PagedCreatorReservedProductsDocument, baseOptions);
      }
export function usePagedCreatorReservedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorReservedProductsQuery, PagedCreatorReservedProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorReservedProductsQuery, PagedCreatorReservedProductsQueryVariables>(PagedCreatorReservedProductsDocument, baseOptions);
        }
export type PagedCreatorReservedProductsQueryHookResult = ReturnType<typeof usePagedCreatorReservedProductsQuery>;
export type PagedCreatorReservedProductsLazyQueryHookResult = ReturnType<typeof usePagedCreatorReservedProductsLazyQuery>;
export type PagedCreatorReservedProductsQueryResult = Apollo.QueryResult<PagedCreatorReservedProductsQuery, PagedCreatorReservedProductsQueryVariables>;
export const PagedCreatorSalesPerMonthBreakdownDocument = gql`
    query PagedCreatorSalesPerMonthBreakdown($input: PagedCreatorSalesPerMonthBreakdownInput!, $first: Int!, $page: Int) {
  PagedCreatorSalesPerMonthBreakdown(input: $input, first: $first, page: $page) {
    data {
      delivered_at
      paid_at
      user_name
      product_name
      product_type
      product_type_name
      movie_seconds
      started_at
      talk_minutes
      order_id
      sales
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
    `;

/**
 * __usePagedCreatorSalesPerMonthBreakdownQuery__
 *
 * To run a query within a React component, call `usePagedCreatorSalesPerMonthBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorSalesPerMonthBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorSalesPerMonthBreakdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePagedCreatorSalesPerMonthBreakdownQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorSalesPerMonthBreakdownQuery, PagedCreatorSalesPerMonthBreakdownQueryVariables>) {
        return Apollo.useQuery<PagedCreatorSalesPerMonthBreakdownQuery, PagedCreatorSalesPerMonthBreakdownQueryVariables>(PagedCreatorSalesPerMonthBreakdownDocument, baseOptions);
      }
export function usePagedCreatorSalesPerMonthBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorSalesPerMonthBreakdownQuery, PagedCreatorSalesPerMonthBreakdownQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorSalesPerMonthBreakdownQuery, PagedCreatorSalesPerMonthBreakdownQueryVariables>(PagedCreatorSalesPerMonthBreakdownDocument, baseOptions);
        }
export type PagedCreatorSalesPerMonthBreakdownQueryHookResult = ReturnType<typeof usePagedCreatorSalesPerMonthBreakdownQuery>;
export type PagedCreatorSalesPerMonthBreakdownLazyQueryHookResult = ReturnType<typeof usePagedCreatorSalesPerMonthBreakdownLazyQuery>;
export type PagedCreatorSalesPerMonthBreakdownQueryResult = Apollo.QueryResult<PagedCreatorSalesPerMonthBreakdownQuery, PagedCreatorSalesPerMonthBreakdownQueryVariables>;
export const PagedCreatorUndeliveredOrderedProductPerUserDocument = gql`
    query PagedCreatorUndeliveredOrderedProductPerUser($page: Int, $first: Int!, $user_id: ID!) {
  PagedCreatorUndeliveredOrderedProductPerUser(first: $first, page: $page, user_id: $user_id) {
    data {
      id
      status
      live_talk_status
      creator_id
      order_id
      product_id
      is_sent_reminder_delivery_1day_ago_mail
      is_sent_reminder_delivery_1hour_ago_mail
      is_sent_reminder_delivery_after_mail
      created_at
      updated_at
      delivery_limit_date
      is_delivered
      is_processing
      is_undelivered
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      order {
        id
        delivered_at
        paid_at
        movie_seconds
        status
        user {
          id
          status
          name
          email
          twitter_username
          created_at
          updated_at
        }
        product {
          id
          name
          creator_id
          status
          price
          special_price
          stock
          remaining_stock
          sold_outed
          thumbnail_image
          released_at
          product_type
          product_type_name
          movie_seconds
          created_at
          updated_at
          deleted_at
          creator {
            id
            business_id
            affiliation_id
            status
            icon_url
            name
            email
            profile
            twitter_username
            instagram_username
            youtube_channel_url
            kindle_author_url
            left_at
            created_at
            updated_at
            status_name
            is_favorite
            affiliation {
              id
              name
              creators_count
              created_at
              updated_at
            }
            favorite_users_count
          }
          is_movie
          is_photo
          is_live_talk
          live_talk {
            product_id
            started_at
            talk_minutes
          }
        }
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedCreatorUndeliveredOrderedProductPerUserQuery__
 *
 * To run a query within a React component, call `usePagedCreatorUndeliveredOrderedProductPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorUndeliveredOrderedProductPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorUndeliveredOrderedProductPerUserQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function usePagedCreatorUndeliveredOrderedProductPerUserQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorUndeliveredOrderedProductPerUserQuery, PagedCreatorUndeliveredOrderedProductPerUserQueryVariables>) {
        return Apollo.useQuery<PagedCreatorUndeliveredOrderedProductPerUserQuery, PagedCreatorUndeliveredOrderedProductPerUserQueryVariables>(PagedCreatorUndeliveredOrderedProductPerUserDocument, baseOptions);
      }
export function usePagedCreatorUndeliveredOrderedProductPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorUndeliveredOrderedProductPerUserQuery, PagedCreatorUndeliveredOrderedProductPerUserQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorUndeliveredOrderedProductPerUserQuery, PagedCreatorUndeliveredOrderedProductPerUserQueryVariables>(PagedCreatorUndeliveredOrderedProductPerUserDocument, baseOptions);
        }
export type PagedCreatorUndeliveredOrderedProductPerUserQueryHookResult = ReturnType<typeof usePagedCreatorUndeliveredOrderedProductPerUserQuery>;
export type PagedCreatorUndeliveredOrderedProductPerUserLazyQueryHookResult = ReturnType<typeof usePagedCreatorUndeliveredOrderedProductPerUserLazyQuery>;
export type PagedCreatorUndeliveredOrderedProductPerUserQueryResult = Apollo.QueryResult<PagedCreatorUndeliveredOrderedProductPerUserQuery, PagedCreatorUndeliveredOrderedProductPerUserQueryVariables>;
export const PagedCreatorsInBusinessDocument = gql`
    query PagedCreatorsInBusiness($first: Int!, $page: Int) {
  PagedCreatorsInBusiness(first: $first, page: $page) {
    data {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
    `;

/**
 * __usePagedCreatorsInBusinessQuery__
 *
 * To run a query within a React component, call `usePagedCreatorsInBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedCreatorsInBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedCreatorsInBusinessQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePagedCreatorsInBusinessQuery(baseOptions?: Apollo.QueryHookOptions<PagedCreatorsInBusinessQuery, PagedCreatorsInBusinessQueryVariables>) {
        return Apollo.useQuery<PagedCreatorsInBusinessQuery, PagedCreatorsInBusinessQueryVariables>(PagedCreatorsInBusinessDocument, baseOptions);
      }
export function usePagedCreatorsInBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedCreatorsInBusinessQuery, PagedCreatorsInBusinessQueryVariables>) {
          return Apollo.useLazyQuery<PagedCreatorsInBusinessQuery, PagedCreatorsInBusinessQueryVariables>(PagedCreatorsInBusinessDocument, baseOptions);
        }
export type PagedCreatorsInBusinessQueryHookResult = ReturnType<typeof usePagedCreatorsInBusinessQuery>;
export type PagedCreatorsInBusinessLazyQueryHookResult = ReturnType<typeof usePagedCreatorsInBusinessLazyQuery>;
export type PagedCreatorsInBusinessQueryResult = Apollo.QueryResult<PagedCreatorsInBusinessQuery, PagedCreatorsInBusinessQueryVariables>;
export const PagedProductsOnSaleDocument = gql`
    query PagedProductsOnSale($first: Int!, $page: Int) {
  PagedProductsOnSale(first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedProductsOnSaleQuery__
 *
 * To run a query within a React component, call `usePagedProductsOnSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedProductsOnSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedProductsOnSaleQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePagedProductsOnSaleQuery(baseOptions?: Apollo.QueryHookOptions<PagedProductsOnSaleQuery, PagedProductsOnSaleQueryVariables>) {
        return Apollo.useQuery<PagedProductsOnSaleQuery, PagedProductsOnSaleQueryVariables>(PagedProductsOnSaleDocument, baseOptions);
      }
export function usePagedProductsOnSaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedProductsOnSaleQuery, PagedProductsOnSaleQueryVariables>) {
          return Apollo.useLazyQuery<PagedProductsOnSaleQuery, PagedProductsOnSaleQueryVariables>(PagedProductsOnSaleDocument, baseOptions);
        }
export type PagedProductsOnSaleQueryHookResult = ReturnType<typeof usePagedProductsOnSaleQuery>;
export type PagedProductsOnSaleLazyQueryHookResult = ReturnType<typeof usePagedProductsOnSaleLazyQuery>;
export type PagedProductsOnSaleQueryResult = Apollo.QueryResult<PagedProductsOnSaleQuery, PagedProductsOnSaleQueryVariables>;
export const PagedPublishedProductsDocument = gql`
    query PagedPublishedProducts($creator_id: ID!, $exclude_product_id: ID, $page: Int, $first: Int!) {
  PagedPublishedProducts(creator_id: $creator_id, exclude_product_id: $exclude_product_id, first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedPublishedProductsQuery__
 *
 * To run a query within a React component, call `usePagedPublishedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedPublishedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedPublishedProductsQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      exclude_product_id: // value for 'exclude_product_id'
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedPublishedProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedPublishedProductsQuery, PagedPublishedProductsQueryVariables>) {
        return Apollo.useQuery<PagedPublishedProductsQuery, PagedPublishedProductsQueryVariables>(PagedPublishedProductsDocument, baseOptions);
      }
export function usePagedPublishedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedPublishedProductsQuery, PagedPublishedProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedPublishedProductsQuery, PagedPublishedProductsQueryVariables>(PagedPublishedProductsDocument, baseOptions);
        }
export type PagedPublishedProductsQueryHookResult = ReturnType<typeof usePagedPublishedProductsQuery>;
export type PagedPublishedProductsLazyQueryHookResult = ReturnType<typeof usePagedPublishedProductsLazyQuery>;
export type PagedPublishedProductsQueryResult = Apollo.QueryResult<PagedPublishedProductsQuery, PagedPublishedProductsQueryVariables>;
export const PagedSalesPerOrderDocument = gql`
    query PagedSalesPerOrder($has_all_affiliation: Boolean, $affiliation_id: ID, $creator_id: ID, $input: SalesInput!, $first: Int!, $page: Int) {
  PagedSalesPerOrder(has_all_affiliation: $has_all_affiliation, affiliation_id: $affiliation_id, creator_id: $creator_id, input: $input, first: $first, page: $page) {
    data {
      delivered_at
      paid_at
      user_name
      product_name
      creator_name
      product_type
      product_type_name
      movie_seconds
      started_at
      talk_minutes
      order_id
      sales
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
    `;

/**
 * __usePagedSalesPerOrderQuery__
 *
 * To run a query within a React component, call `usePagedSalesPerOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedSalesPerOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedSalesPerOrderQuery({
 *   variables: {
 *      has_all_affiliation: // value for 'has_all_affiliation'
 *      affiliation_id: // value for 'affiliation_id'
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePagedSalesPerOrderQuery(baseOptions?: Apollo.QueryHookOptions<PagedSalesPerOrderQuery, PagedSalesPerOrderQueryVariables>) {
        return Apollo.useQuery<PagedSalesPerOrderQuery, PagedSalesPerOrderQueryVariables>(PagedSalesPerOrderDocument, baseOptions);
      }
export function usePagedSalesPerOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedSalesPerOrderQuery, PagedSalesPerOrderQueryVariables>) {
          return Apollo.useLazyQuery<PagedSalesPerOrderQuery, PagedSalesPerOrderQueryVariables>(PagedSalesPerOrderDocument, baseOptions);
        }
export type PagedSalesPerOrderQueryHookResult = ReturnType<typeof usePagedSalesPerOrderQuery>;
export type PagedSalesPerOrderLazyQueryHookResult = ReturnType<typeof usePagedSalesPerOrderLazyQuery>;
export type PagedSalesPerOrderQueryResult = Apollo.QueryResult<PagedSalesPerOrderQuery, PagedSalesPerOrderQueryVariables>;
export const PagedUserActiveCreatorsFavoritedDocument = gql`
    query PagedUserActiveCreatorsFavorited($first: Int!, $page: Int) {
  PagedUserActiveCreatorsFavorited(first: $first, page: $page) {
    data {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
    `;

/**
 * __usePagedUserActiveCreatorsFavoritedQuery__
 *
 * To run a query within a React component, call `usePagedUserActiveCreatorsFavoritedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedUserActiveCreatorsFavoritedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedUserActiveCreatorsFavoritedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePagedUserActiveCreatorsFavoritedQuery(baseOptions?: Apollo.QueryHookOptions<PagedUserActiveCreatorsFavoritedQuery, PagedUserActiveCreatorsFavoritedQueryVariables>) {
        return Apollo.useQuery<PagedUserActiveCreatorsFavoritedQuery, PagedUserActiveCreatorsFavoritedQueryVariables>(PagedUserActiveCreatorsFavoritedDocument, baseOptions);
      }
export function usePagedUserActiveCreatorsFavoritedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedUserActiveCreatorsFavoritedQuery, PagedUserActiveCreatorsFavoritedQueryVariables>) {
          return Apollo.useLazyQuery<PagedUserActiveCreatorsFavoritedQuery, PagedUserActiveCreatorsFavoritedQueryVariables>(PagedUserActiveCreatorsFavoritedDocument, baseOptions);
        }
export type PagedUserActiveCreatorsFavoritedQueryHookResult = ReturnType<typeof usePagedUserActiveCreatorsFavoritedQuery>;
export type PagedUserActiveCreatorsFavoritedLazyQueryHookResult = ReturnType<typeof usePagedUserActiveCreatorsFavoritedLazyQuery>;
export type PagedUserActiveCreatorsFavoritedQueryResult = Apollo.QueryResult<PagedUserActiveCreatorsFavoritedQuery, PagedUserActiveCreatorsFavoritedQueryVariables>;
export const PagedUserFavoritedCreatorLatestProductsDocument = gql`
    query PagedUserFavoritedCreatorLatestProducts($page: Int, $first: Int!) {
  PagedUserFavoritedCreatorLatestProducts(first: $first, page: $page) {
    data {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedUserFavoritedCreatorLatestProductsQuery__
 *
 * To run a query within a React component, call `usePagedUserFavoritedCreatorLatestProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedUserFavoritedCreatorLatestProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedUserFavoritedCreatorLatestProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedUserFavoritedCreatorLatestProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedUserFavoritedCreatorLatestProductsQuery, PagedUserFavoritedCreatorLatestProductsQueryVariables>) {
        return Apollo.useQuery<PagedUserFavoritedCreatorLatestProductsQuery, PagedUserFavoritedCreatorLatestProductsQueryVariables>(PagedUserFavoritedCreatorLatestProductsDocument, baseOptions);
      }
export function usePagedUserFavoritedCreatorLatestProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedUserFavoritedCreatorLatestProductsQuery, PagedUserFavoritedCreatorLatestProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedUserFavoritedCreatorLatestProductsQuery, PagedUserFavoritedCreatorLatestProductsQueryVariables>(PagedUserFavoritedCreatorLatestProductsDocument, baseOptions);
        }
export type PagedUserFavoritedCreatorLatestProductsQueryHookResult = ReturnType<typeof usePagedUserFavoritedCreatorLatestProductsQuery>;
export type PagedUserFavoritedCreatorLatestProductsLazyQueryHookResult = ReturnType<typeof usePagedUserFavoritedCreatorLatestProductsLazyQuery>;
export type PagedUserFavoritedCreatorLatestProductsQueryResult = Apollo.QueryResult<PagedUserFavoritedCreatorLatestProductsQuery, PagedUserFavoritedCreatorLatestProductsQueryVariables>;
export const PagedUserPurchasedProductsDocument = gql`
    query PagedUserPurchasedProducts($page: Int, $first: Int!) {
  PagedUserPurchasedProducts(first: $first, page: $page) {
    data {
      id
      status
      live_talk_status
      creator_id
      order_id
      product_id
      is_sent_reminder_delivery_1day_ago_mail
      is_sent_reminder_delivery_1hour_ago_mail
      is_sent_reminder_delivery_after_mail
      created_at
      updated_at
      delivery_limit_date
      is_delivered
      is_processing
      is_undelivered
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      order {
        id
        delivered_at
        paid_at
        movie_seconds
        status
        user {
          id
          status
          name
          email
          twitter_username
          created_at
          updated_at
        }
        product {
          id
          name
          creator_id
          status
          price
          special_price
          stock
          remaining_stock
          sold_outed
          thumbnail_image
          released_at
          product_type
          product_type_name
          movie_seconds
          created_at
          updated_at
          deleted_at
          creator {
            id
            business_id
            affiliation_id
            status
            icon_url
            name
            email
            profile
            twitter_username
            instagram_username
            youtube_channel_url
            kindle_author_url
            left_at
            created_at
            updated_at
            status_name
            is_favorite
            affiliation {
              id
              name
              creators_count
              created_at
              updated_at
            }
            favorite_users_count
          }
          is_movie
          is_photo
          is_live_talk
          live_talk {
            product_id
            started_at
            talk_minutes
          }
        }
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
      latest_delivered_product {
        id
        ordered_product_id
        delivered_product_url
        delivered_product_thumbnail_url
        delivered_at
        created_at
        updated_at
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __usePagedUserPurchasedProductsQuery__
 *
 * To run a query within a React component, call `usePagedUserPurchasedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedUserPurchasedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedUserPurchasedProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePagedUserPurchasedProductsQuery(baseOptions?: Apollo.QueryHookOptions<PagedUserPurchasedProductsQuery, PagedUserPurchasedProductsQueryVariables>) {
        return Apollo.useQuery<PagedUserPurchasedProductsQuery, PagedUserPurchasedProductsQueryVariables>(PagedUserPurchasedProductsDocument, baseOptions);
      }
export function usePagedUserPurchasedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedUserPurchasedProductsQuery, PagedUserPurchasedProductsQueryVariables>) {
          return Apollo.useLazyQuery<PagedUserPurchasedProductsQuery, PagedUserPurchasedProductsQueryVariables>(PagedUserPurchasedProductsDocument, baseOptions);
        }
export type PagedUserPurchasedProductsQueryHookResult = ReturnType<typeof usePagedUserPurchasedProductsQuery>;
export type PagedUserPurchasedProductsLazyQueryHookResult = ReturnType<typeof usePagedUserPurchasedProductsLazyQuery>;
export type PagedUserPurchasedProductsQueryResult = Apollo.QueryResult<PagedUserPurchasedProductsQuery, PagedUserPurchasedProductsQueryVariables>;
export const PublishedProductDocument = gql`
    query PublishedProduct($product_id: ID!) {
  PublishedProduct(product_id: $product_id) {
    id
    name
    creator_id
    status
    price
    special_price
    stock
    remaining_stock
    sold_outed
    thumbnail_image
    released_at
    product_type
    product_type_name
    movie_seconds
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    is_movie
    is_photo
    is_live_talk
    live_talk {
      product_id
      started_at
      talk_minutes
    }
  }
}
    `;

/**
 * __usePublishedProductQuery__
 *
 * To run a query within a React component, call `usePublishedProductQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedProductQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function usePublishedProductQuery(baseOptions?: Apollo.QueryHookOptions<PublishedProductQuery, PublishedProductQueryVariables>) {
        return Apollo.useQuery<PublishedProductQuery, PublishedProductQueryVariables>(PublishedProductDocument, baseOptions);
      }
export function usePublishedProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishedProductQuery, PublishedProductQueryVariables>) {
          return Apollo.useLazyQuery<PublishedProductQuery, PublishedProductQueryVariables>(PublishedProductDocument, baseOptions);
        }
export type PublishedProductQueryHookResult = ReturnType<typeof usePublishedProductQuery>;
export type PublishedProductLazyQueryHookResult = ReturnType<typeof usePublishedProductLazyQuery>;
export type PublishedProductQueryResult = Apollo.QueryResult<PublishedProductQuery, PublishedProductQueryVariables>;
export const RecommendedCreatorsDocument = gql`
    query RecommendedCreators($input: RecommendedCreatorsInput!) {
  RecommendedCreators(input: $input) {
    id
    business_id
    affiliation_id
    status
    icon_url
    name
    email
    profile
    twitter_username
    instagram_username
    youtube_channel_url
    kindle_author_url
    left_at
    created_at
    updated_at
    status_name
    is_favorite
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
    favorite_users_count
  }
}
    `;

/**
 * __useRecommendedCreatorsQuery__
 *
 * To run a query within a React component, call `useRecommendedCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedCreatorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecommendedCreatorsQuery(baseOptions?: Apollo.QueryHookOptions<RecommendedCreatorsQuery, RecommendedCreatorsQueryVariables>) {
        return Apollo.useQuery<RecommendedCreatorsQuery, RecommendedCreatorsQueryVariables>(RecommendedCreatorsDocument, baseOptions);
      }
export function useRecommendedCreatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecommendedCreatorsQuery, RecommendedCreatorsQueryVariables>) {
          return Apollo.useLazyQuery<RecommendedCreatorsQuery, RecommendedCreatorsQueryVariables>(RecommendedCreatorsDocument, baseOptions);
        }
export type RecommendedCreatorsQueryHookResult = ReturnType<typeof useRecommendedCreatorsQuery>;
export type RecommendedCreatorsLazyQueryHookResult = ReturnType<typeof useRecommendedCreatorsLazyQuery>;
export type RecommendedCreatorsQueryResult = Apollo.QueryResult<RecommendedCreatorsQuery, RecommendedCreatorsQueryVariables>;
export const RequestCreatorDocument = gql`
    query RequestCreator($creator_id: ID!, $input: RequestCreatorInput!) {
  RequestCreator(creator_id: $creator_id, input: $input) {
    id
    business_id
    affiliation_id
    status
    email
    status_name
    affiliation {
      id
      name
      creators_count
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useRequestCreatorQuery__
 *
 * To run a query within a React component, call `useRequestCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestCreatorQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestCreatorQuery(baseOptions?: Apollo.QueryHookOptions<RequestCreatorQuery, RequestCreatorQueryVariables>) {
        return Apollo.useQuery<RequestCreatorQuery, RequestCreatorQueryVariables>(RequestCreatorDocument, baseOptions);
      }
export function useRequestCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestCreatorQuery, RequestCreatorQueryVariables>) {
          return Apollo.useLazyQuery<RequestCreatorQuery, RequestCreatorQueryVariables>(RequestCreatorDocument, baseOptions);
        }
export type RequestCreatorQueryHookResult = ReturnType<typeof useRequestCreatorQuery>;
export type RequestCreatorLazyQueryHookResult = ReturnType<typeof useRequestCreatorLazyQuery>;
export type RequestCreatorQueryResult = Apollo.QueryResult<RequestCreatorQuery, RequestCreatorQueryVariables>;
export const CreatorCreateVideoChatRoomTokenDocument = gql`
    query CreatorCreateVideoChatRoomToken($product_id: ID!) {
  CreatorCreateVideoChatRoomToken(product_id: $product_id)
}
    `;

/**
 * __useCreatorCreateVideoChatRoomTokenQuery__
 *
 * To run a query within a React component, call `useCreatorCreateVideoChatRoomTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorCreateVideoChatRoomTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorCreateVideoChatRoomTokenQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useCreatorCreateVideoChatRoomTokenQuery(baseOptions?: Apollo.QueryHookOptions<CreatorCreateVideoChatRoomTokenQuery, CreatorCreateVideoChatRoomTokenQueryVariables>) {
        return Apollo.useQuery<CreatorCreateVideoChatRoomTokenQuery, CreatorCreateVideoChatRoomTokenQueryVariables>(CreatorCreateVideoChatRoomTokenDocument, baseOptions);
      }
export function useCreatorCreateVideoChatRoomTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorCreateVideoChatRoomTokenQuery, CreatorCreateVideoChatRoomTokenQueryVariables>) {
          return Apollo.useLazyQuery<CreatorCreateVideoChatRoomTokenQuery, CreatorCreateVideoChatRoomTokenQueryVariables>(CreatorCreateVideoChatRoomTokenDocument, baseOptions);
        }
export type CreatorCreateVideoChatRoomTokenQueryHookResult = ReturnType<typeof useCreatorCreateVideoChatRoomTokenQuery>;
export type CreatorCreateVideoChatRoomTokenLazyQueryHookResult = ReturnType<typeof useCreatorCreateVideoChatRoomTokenLazyQuery>;
export type CreatorCreateVideoChatRoomTokenQueryResult = Apollo.QueryResult<CreatorCreateVideoChatRoomTokenQuery, CreatorCreateVideoChatRoomTokenQueryVariables>;
export const UserPurchasedProductDocument = gql`
    query UserPurchasedProduct($ordered_product_id: ID!) {
  UserPurchasedProduct(ordered_product_id: $ordered_product_id) {
    id
    status
    live_talk_status
    creator_id
    order_id
    product_id
    is_sent_reminder_delivery_1day_ago_mail
    is_sent_reminder_delivery_1hour_ago_mail
    is_sent_reminder_delivery_after_mail
    created_at
    updated_at
    delivery_limit_date
    is_delivered
    is_processing
    is_undelivered
    creator {
      id
      business_id
      affiliation_id
      status
      icon_url
      name
      email
      profile
      twitter_username
      instagram_username
      youtube_channel_url
      kindle_author_url
      left_at
      created_at
      updated_at
      status_name
      is_favorite
      affiliation {
        id
        name
        creators_count
        created_at
        updated_at
      }
      favorite_users_count
    }
    order {
      id
      delivered_at
      paid_at
      movie_seconds
      status
      user {
        id
        status
        name
        email
        twitter_username
        created_at
        updated_at
      }
      product {
        id
        name
        creator_id
        status
        price
        special_price
        stock
        remaining_stock
        sold_outed
        thumbnail_image
        released_at
        product_type
        product_type_name
        movie_seconds
        created_at
        updated_at
        deleted_at
        creator {
          id
          business_id
          affiliation_id
          status
          icon_url
          name
          email
          profile
          twitter_username
          instagram_username
          youtube_channel_url
          kindle_author_url
          left_at
          created_at
          updated_at
          status_name
          is_favorite
          affiliation {
            id
            name
            creators_count
            created_at
            updated_at
          }
          favorite_users_count
        }
        is_movie
        is_photo
        is_live_talk
        live_talk {
          product_id
          started_at
          talk_minutes
        }
      }
    }
    product {
      id
      name
      creator_id
      status
      price
      special_price
      stock
      remaining_stock
      sold_outed
      thumbnail_image
      released_at
      product_type
      product_type_name
      movie_seconds
      creator {
        id
        business_id
        affiliation_id
        status
        icon_url
        name
        email
        profile
        twitter_username
        instagram_username
        youtube_channel_url
        kindle_author_url
        left_at
        created_at
        updated_at
        status_name
        is_favorite
        affiliation {
          id
          name
          creators_count
          created_at
          updated_at
        }
        favorite_users_count
      }
      is_movie
      is_photo
      is_live_talk
      live_talk {
        product_id
        started_at
        talk_minutes
      }
    }
    latest_delivered_product {
      id
      ordered_product_id
      delivered_product_url
      delivered_product_thumbnail_url
      temporary_download_url
      delivered_at
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useUserPurchasedProductQuery__
 *
 * To run a query within a React component, call `useUserPurchasedProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPurchasedProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPurchasedProductQuery({
 *   variables: {
 *      ordered_product_id: // value for 'ordered_product_id'
 *   },
 * });
 */
export function useUserPurchasedProductQuery(baseOptions?: Apollo.QueryHookOptions<UserPurchasedProductQuery, UserPurchasedProductQueryVariables>) {
        return Apollo.useQuery<UserPurchasedProductQuery, UserPurchasedProductQueryVariables>(UserPurchasedProductDocument, baseOptions);
      }
export function useUserPurchasedProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPurchasedProductQuery, UserPurchasedProductQueryVariables>) {
          return Apollo.useLazyQuery<UserPurchasedProductQuery, UserPurchasedProductQueryVariables>(UserPurchasedProductDocument, baseOptions);
        }
export type UserPurchasedProductQueryHookResult = ReturnType<typeof useUserPurchasedProductQuery>;
export type UserPurchasedProductLazyQueryHookResult = ReturnType<typeof useUserPurchasedProductLazyQuery>;
export type UserPurchasedProductQueryResult = Apollo.QueryResult<UserPurchasedProductQuery, UserPurchasedProductQueryVariables>;
export const ValidateBusinessUserTokenDocument = gql`
    query ValidateBusinessUserToken {
  ValidateBusinessUserToken
}
    `;

/**
 * __useValidateBusinessUserTokenQuery__
 *
 * To run a query within a React component, call `useValidateBusinessUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateBusinessUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateBusinessUserTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidateBusinessUserTokenQuery(baseOptions?: Apollo.QueryHookOptions<ValidateBusinessUserTokenQuery, ValidateBusinessUserTokenQueryVariables>) {
        return Apollo.useQuery<ValidateBusinessUserTokenQuery, ValidateBusinessUserTokenQueryVariables>(ValidateBusinessUserTokenDocument, baseOptions);
      }
export function useValidateBusinessUserTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateBusinessUserTokenQuery, ValidateBusinessUserTokenQueryVariables>) {
          return Apollo.useLazyQuery<ValidateBusinessUserTokenQuery, ValidateBusinessUserTokenQueryVariables>(ValidateBusinessUserTokenDocument, baseOptions);
        }
export type ValidateBusinessUserTokenQueryHookResult = ReturnType<typeof useValidateBusinessUserTokenQuery>;
export type ValidateBusinessUserTokenLazyQueryHookResult = ReturnType<typeof useValidateBusinessUserTokenLazyQuery>;
export type ValidateBusinessUserTokenQueryResult = Apollo.QueryResult<ValidateBusinessUserTokenQuery, ValidateBusinessUserTokenQueryVariables>;
export const ValidateCreatorTokenDocument = gql`
    query ValidateCreatorToken {
  ValidateCreatorToken
}
    `;

/**
 * __useValidateCreatorTokenQuery__
 *
 * To run a query within a React component, call `useValidateCreatorTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateCreatorTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateCreatorTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidateCreatorTokenQuery(baseOptions?: Apollo.QueryHookOptions<ValidateCreatorTokenQuery, ValidateCreatorTokenQueryVariables>) {
        return Apollo.useQuery<ValidateCreatorTokenQuery, ValidateCreatorTokenQueryVariables>(ValidateCreatorTokenDocument, baseOptions);
      }
export function useValidateCreatorTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateCreatorTokenQuery, ValidateCreatorTokenQueryVariables>) {
          return Apollo.useLazyQuery<ValidateCreatorTokenQuery, ValidateCreatorTokenQueryVariables>(ValidateCreatorTokenDocument, baseOptions);
        }
export type ValidateCreatorTokenQueryHookResult = ReturnType<typeof useValidateCreatorTokenQuery>;
export type ValidateCreatorTokenLazyQueryHookResult = ReturnType<typeof useValidateCreatorTokenLazyQuery>;
export type ValidateCreatorTokenQueryResult = Apollo.QueryResult<ValidateCreatorTokenQuery, ValidateCreatorTokenQueryVariables>;
export const ValidateUserTokenDocument = gql`
    query ValidateUserToken {
  ValidateUserToken
}
    `;

/**
 * __useValidateUserTokenQuery__
 *
 * To run a query within a React component, call `useValidateUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateUserTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidateUserTokenQuery(baseOptions?: Apollo.QueryHookOptions<ValidateUserTokenQuery, ValidateUserTokenQueryVariables>) {
        return Apollo.useQuery<ValidateUserTokenQuery, ValidateUserTokenQueryVariables>(ValidateUserTokenDocument, baseOptions);
      }
export function useValidateUserTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateUserTokenQuery, ValidateUserTokenQueryVariables>) {
          return Apollo.useLazyQuery<ValidateUserTokenQuery, ValidateUserTokenQueryVariables>(ValidateUserTokenDocument, baseOptions);
        }
export type ValidateUserTokenQueryHookResult = ReturnType<typeof useValidateUserTokenQuery>;
export type ValidateUserTokenLazyQueryHookResult = ReturnType<typeof useValidateUserTokenLazyQuery>;
export type ValidateUserTokenQueryResult = Apollo.QueryResult<ValidateUserTokenQuery, ValidateUserTokenQueryVariables>;