import React from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'
import { useHistory, useLocation } from 'react-router-dom'

type Props = {
  className?: string
  lastPage: number
  currentPage: number
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .page-button {
    width: 8vw;
    border-radius: 4px;
    text-align: center;
    margin: 0 2vw;
    line-height: 8vw;
    font-size: 3.2vw;
    font-weight: bold;

    &:disabled {
      background: ${(props) => props.theme.buttonColors.white};
    }
  }

  ${mediaPc`
      .page-button {
        width: 30px;
        margin: 0 15px 0 0;
        line-height: 30px;
        font-size: 12px;
        &:last-child {
          margin-right:0;
        }
      }
    `}
`

export const Pagination: React.FC<Props> = ({ className = '', lastPage, currentPage }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const params = new URLSearchParams(search)

  let pageNums: number[] = []
  if (lastPage < 6) {
    ;[...Array(lastPage)].map((_, i) => {
      return pageNums.push(i + 1)
    })
  } else {
    if (currentPage <= 3) {
      pageNums = [1, 2, 3, 4, 5]
    }
    if (3 < currentPage && currentPage < lastPage - 2) {
      pageNums = [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2]
    }
    if (currentPage >= lastPage - 2) {
      pageNums = [lastPage - 4, lastPage - 3, lastPage - 2, lastPage - 1, lastPage]
    }
  }

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    params.set('page', event.currentTarget.value)
    history.push({
      pathname,
      search: params.toString(),
    })
  }

  const onClickPrev = (): void => {
    params.set('page', `${currentPage - 1}`)
    history.push({
      pathname,
      search: params.toString(),
    })
  }

  const onClickNext = (): void => {
    params.set('page', `${currentPage + 1}`)
    history.push({
      pathname,
      search: params.toString(),
    })
  }

  return (
    <Wrapper className={className}>
      {lastPage > 1 && (
        <>
          {currentPage !== 1 && (
            <Button buttonType="black" onClick={onClickPrev} className="page-button">
              &lsaquo;
            </Button>
          )}
          {pageNums.map((num, index) => (
            <Button
              buttonType={num === currentPage ? 'white' : 'black'}
              disabled={num === currentPage}
              value={num}
              onClick={onClick}
              className="page-button"
              key={index}
            >
              {num}
            </Button>
          ))}
          {currentPage !== lastPage && (
            <Button buttonType="black" onClick={onClickNext} className="page-button">
              &rsaquo;
            </Button>
          )}
        </>
      )}
    </Wrapper>
  )
}
