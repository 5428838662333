import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { TextInput, Button, PageTitle, Icon } from '../../atoms'
import { ErrorMessagesBox } from '../../molecules'

import { mediaPc } from '../../../lib/MediaQuery'
import passwordIcon from '../../../assets/images/icon/icon_password.png'

type Props = {
  email?: string
  emailError: string | null
  onClick: () => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ResetForm = styled.div`
  text-align: center;
  .title {
    display: flex;
    justify-content: center;
    margin-bottom: 2.4vw;
    .icon {
      width: 4.27vw;
      margin-right:1.4vw;
    }

    .terms-title-text {
      margin-left: 17px;
      font-size: 24px;
      line-height: 1.33;
      text-align: center;
    }
  }
  .page-title {
    margin: 0 4.2vw 2.4vw 0;
  }
  .lead-text {
    margin-bottom: 5.1vw;
    color: ${(props): string => props.theme.textColors.black};
    text-align: center;
    font-weight: 400;
    font-size: 3.2vw;
  }
  .text-input {
    width: 100%;
    height: 12vw;
    padding: 0 17px;
    display: block;
    border-radius: 4px;
    color: ${(props): string => props.theme.textColors.black};
    font-size: 4.27vw;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 0.05em;
    &.error {
      border: 1px solid color: ${(props): string => props.theme.textColors.red};
    }
    &::placeholder {
      font-size: 3.2vw;
      line-height: 1.875;
    }
  }

  .error-messages {
    margin-top: 5.5vw;
    text-align: left;
  }

  .send-button {
    margin-top: 5.6vw;
    font-size: 3.733vw;
    font-weight: bold;
  }

  ${mediaPc`
    .inner {
      margin-top:50px;
      padding-top: 69px;
      background-color:  ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
    }
    .title {
      .icon {
        width :21px;
        margin-right:0;
      }
    }
    .page-title {
      margin: 0 0 0 14px;
    }
    .lead-text {
      font-size: 14px;
      margin-bottom: 25px;
    }
    .text-input {
      width: 320px;
      height: 40px;
      margin: 0 auto;
      padding: 0 15px;
      font-size: 12px;
      display: block;
      &::placeholder {
        font-size: 12px;
      }
    }
    .send-button {
      width: 320px;
      height: 40px;
      line-height: 40px;
      margin: 25px auto 64px;
      padding: 0 15px;
      font-size: 14px;
    }

    .error-messages {
      width: 320px;
      margin: 25px auto 0px;
      padding: 10px 15px;
      text-align: left;
      p {
        font-size: 12px;
      }
    }
  `}
`

export const PasswordResetForm: React.FC<Props> = ({ email, emailError, onClick, onChange }) => {
  const errors: string[] = []
  if (emailError) {
    errors.push(emailError)
  }
  return (
    <ResetForm>
      <div className="title">
        <div className="icon">
          <Icon src={passwordIcon} alt="パスワード再設定" />
        </div>
        <PageTitle className="page-title" title="パスワード再設定" />
      </div>
      <div className="inner">
        <p className="lead-text">登録したメールアドレスをご入力ください</p>
        <TextInput
          className="text-input"
          placeholder="メールアドレスを入力"
          type="email"
          value={email}
          error={!!emailError}
          onChange={onChange}
        />
        {!!errors.length && <ErrorMessagesBox className="error-messages" errors={errors} />}
        <Button disabled={errors.length ? true : false || !email} className="send-button" onClick={onClick} buttonType="primary">
          再設定メールを送信する
        </Button>
      </div>
    </ResetForm>
  )
}
