import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  buttons: { id: string; name: string; value: string; imageSrc: string; imageAlt: string; onChange: () => void }[]
  checkedValue: string
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4.4vw 0;

  ${mediaPc`
    gap: 30px 0;
  `}
`

const RadioWrapper = styled.div`
  display: flex;
`

const StyledRadioButton = styled.input`
  display: none;
`

const StyledRadioLabel = styled.label`
  width: 100%;

  ${StyledRadioButton} + & {
    border: 3px solid transparent;
    cursor: pointer;
  }
  ${StyledRadioButton}:checked + & {
    border: 3px solid ${(props): string => props.theme.borderColors.primary};
    border-radius: 4px;
    box-sizing: content-box;
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`

export const RadioButtonByImage: React.FC<Props> = ({ className = '', buttons, checkedValue }) => {
  return (
    <Wrapper className={className}>
      {buttons.map((button) => (
        <RadioWrapper key={button.value}>
          <StyledRadioButton
            type="radio"
            checked={checkedValue === button.value}
            onChange={button.onChange}
            id={button.id}
            name={button.name}
            value={button.value}
          />
          <StyledRadioLabel htmlFor={button.id}>
            <img src={button.imageSrc} alt={button.imageAlt} />
          </StyledRadioLabel>
        </RadioWrapper>
      ))}
    </Wrapper>
  )
}
