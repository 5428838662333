import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { ProductDetail, ProductListOnSale } from '../../organisms'

import { Product, PublishedProduct } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  product: Product | PublishedProduct
  otherProducts?: Product[] | PublishedProduct[]
}

const Wrapper = styled.div`
  width: 100%;
  .other-products {
    padding: 6.6vw 4.7vw;
    background: ${(props): string => props.theme.backgroundColors.bodyBg};
    .other-products-content-title {
      width: 100%;
      font-size: 3.735vw;
      line-height: 3.735vw;
      margin-bottom: 4vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    }
    .no-data {
      font-size: 3.735vw;
      text-align: center;
      font-weight: 500;
      color: ${(props): string => props.theme.textColors.gray};
      line-height: 1.14;
      letter-spacing: 0.05em;
    }
  }
  ${mediaPc`
      .other-products {
        padding: 0 ;
        .other-products-content-title {
           padding: 0;
           margin:0;
          font-size:14px;
        }
        .no-data {
          font-size: 14px;
        }
      }
  `}
`

export const CreatorProductDetailTpl: React.FC<Props> = ({ product, otherProducts }) => {
  return (
    <Wrapper>
      <ProductDetail product={product} />
      <div className="other-products">
        <p className="other-products-content-title">販売中の商品</p>
        {otherProducts && otherProducts?.length > 0 ? (
          <ProductListOnSale className="product-list" products={otherProducts} oneLineItemsNum={4} />
        ) : (
          <div className="no-data">他に販売中の商品はありません</div>
        )}
      </div>
    </Wrapper>
  )
}
