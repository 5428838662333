import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { DefaultTheme, ThemeProps } from 'styled-components'
import { ProductImage, Button, LiveTalkBrowseAttention } from '../../../atoms'
import { PublishedOrderedProduct, OrderedProductLiveTalkStatus } from '../../../../types/graphql'
import moment from 'moment'
import { mediaPc } from '../../../../lib/MediaQuery'
import liveTalkIcon from '../../../../assets/images/icon/live_talk.png'

type Props = {
  product: PublishedOrderedProduct
  goToLiveTalk: () => void
}

const Wrapper = styled.div`
  .product-image {
    width: 74.66vw;
    max-width: 100%;
    margin: 0 auto 4vw;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    &:after {
      width: 37.3vw;
      height: 37.3vw;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: url(${liveTalkIcon}) center no-repeat;
      background-size: 37.3vw;
    }

    &.can-click {
      cursor: pointer;
    }

    img {
      width: 100%;
      height: auto;
    }

    .live-talk-status {
      position: absolute;
      width: 53.3vw;
      color: ${(props): string => props.theme.textColors.white};
      text-align: center;
      top: calc(50% + 16vw);
      left: calc(50% - 26.6vw);
      z-index: 5;
      font-size: 3.2vw;
      line-height: 8.5vw;
      border-radius: 4.25vw;

      &.finished {
        background: ${(props): string => props.theme.buttonColors.black};
      }

      &.not-finished {
        background: ${(props): string => props.theme.buttonColors.primary};
      }
    }
  }

  .product-info {
    .product-info-content {
      width: 74.66vw;
      margin: 0 auto;
    }

    .product-name {
      margin-bottom: 4.267vw;
      font-size: 4.267vw;
      font-weight: bold;
    }

    .schedule-wrapper {
      display: flex;
      align-items: center;

      &:first-of-type {
        margin-bottom: 4.27vw;
      }

      > p {
        font-size: 3.733vw;
      }

      .schedule-title {
        margin-right: 3.2vw;
      }
    }
    .wait-attention {
      margin-top: 6.4vw;
      font-size: 3.455vw;
      font-weight: bold;
      line-height: 5.8vw;
      letter-spacing: 0.05em;
    }
    .attention {
      margin-top: 4vw;
      font-size: 3.2vw;
      padding: 2.9vw 0;
      background: ${(props): string => props.theme.backgroundColors.bodyBg};
      border: 1px solid ${(props): string => props.theme.borderColors.red};
      border-radius: 4px;

      > p {
        width: 67.2vw;
        margin: 0 auto;

        & + p {
          margin-top: 2.1vw;
        }
      }

      .attention-title {
        font-weight: bold;
        color: ${(props): string => props.theme.textColors.red};
      }
    }

    .browse-attention {
      padding: 2.6vw 0 0;
    }

    .to-mypage {
      margin-top: 6.4vw;
    }
  }

  ${mediaPc`
  min-height: 317px;
  padding: 30px;
  background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  .product-image {
    width: 329px;
    margin: 0;

    &:after {
      width: 165px;
      height: 165px;
      background-size: 165px;
    }

    .live-talk-status {
      position: absolute;
      width: 200px;
      top: calc(50% + 60px);
      left: calc(50% - 100px);
      font-size: 12px;
      line-height: 32px;
      border-radius: 16px;
    }
  }

  .product-info {
    .product-info-content {
      width: 300px;
    }
    .product-name {
      margin-bottom: 13px;
      font-size: 16px;
    }
    .schedule-wrapper {
      margin-bottom: 38px;
      &:first-of-type {
        margin-bottom: 16px;
      }
      >p {
        font-size: 14px;
      }
      .schedule-title {
        margin-right: 12px;
      }
    }
    .wait-attention {
      margin-top: 32px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.7;
      letter-spacing: 0.05em;
    }
    .attention {
      margin-top: 15px;
      padding: 11px 0;
      font-size: 12px;
      >p {
        width: 268px;
        margin: 0 auto;
        & + p {
          margin-top: 6px;
        }
      }
    }
    .to-mypage {
      margin-top: 36px;
    }
  }
`}
`
export const PurchasedProductDetail: React.FC<Props> = ({ product, goToLiveTalk }) => {
  const isLiveTalkFinished = product.live_talk_status === OrderedProductLiveTalkStatus.Talked
  const isLiveTalkCanceled = product.live_talk_status === OrderedProductLiveTalkStatus.Canceled
  const history = useHistory()

  const goToMypage = (): void => {
    history.push('/mypage')
  }

  return (
    <Wrapper>
      <>
        <div
          onClick={isLiveTalkFinished || isLiveTalkCanceled ? undefined : goToLiveTalk}
          className={isLiveTalkFinished || isLiveTalkCanceled ? 'product-image' : 'product-image can-click'}
        >
          <ProductImage src={product.product.thumbnail_image} />
          {!isLiveTalkCanceled && (
            <>
              <p className={isLiveTalkFinished ? 'live-talk-status finished' : 'live-talk-status not-finished'}>
                {isLiveTalkFinished ? 'ライブトークは終了しました' : '開始日時までお待ちください'}
              </p>
            </>
          )}
        </div>
        <div className="product-info">
          <div className="product-info-content">
            <p className="product-name">{product.product.name}</p>
            <div className="schedule-wrapper">
              <p className="schedule-title">日付</p>
              <p className="schedule">{moment(product.product.live_talk?.started_at).format('YYYY/MM/DD')}</p>
            </div>
            <div className="schedule-wrapper">
              <p className="schedule-title">時間</p>
              <p className="schedule">
                {moment(product.product.live_talk?.started_at).format('HH時mm分')}~
                {moment(product.product.live_talk?.started_at).add(product.product.live_talk?.talk_minutes, 'minutes').format('HH時mm分')}
                　（{product.product.live_talk?.talk_minutes}分間）
              </p>
            </div>
            {!(isLiveTalkFinished || isLiveTalkCanceled) && (
              <>
                <div className="wait-attention">
                  開始時間の5分前になりましたら、
                  <br className="pc" />
                  商品の画像をタップしトーク開始までお待ちください！
                </div>
                <div className="attention">
                  <p className="attention-title">ライブトークの前に必ずお読みください</p>
                  <p>安全と防犯のため、ビデオ通話の内容は全て録画されます。</p>
                  <p>
                    クリエイター様を不快にさせるような不適切な言動や、利用規約第5条に違反する言動があった場合、ライブトークを強制終了の上、アカウント停止させて頂きます。
                  </p>
                  <p>
                    理由の如何にかかわらず、ユーザ様の責によりライブトークが実施できなかった場合、返金対応致しません。あらかじめご了承ください。
                  </p>
                </div>
              </>
            )}
            {isLiveTalkCanceled && (
              <>
                <div className="attention">
                  <p className="attention-title">このライブトークはキャンセルされました</p>
                  <p>
                    {product.creator.name}
                    さんが参加されなかったようです。ご購入頂き、お待たせしたにもかかわらず対応せず、大変申し訳ございません。
                  </p>
                  <p>返金についてメールをお送りさせていただいております。</p>
                </div>
              </>
            )}
            {!(isLiveTalkFinished || isLiveTalkCanceled) && <LiveTalkBrowseAttention className="browse-attention sp" />}
          </div>
          <Button buttonType="gray" onClick={goToMypage} className="to-mypage">
            購入履歴一覧へ
          </Button>
        </div>
      </>
    </Wrapper>
  )
}
