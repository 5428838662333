import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { PasswordReset } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { validateEmail } from '../../../lib/Validate'
import { FlashMessageType } from '../../../types/myTypes'
import { useUserPasswordResetMutation } from '../../../types/graphql'

type Props = {}

export const UserPasswordReset: React.FC<Props> = () => {
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()

  const [userPasswordResetMutation, { loading }] = useUserPasswordResetMutation({
    onCompleted: () => {
      history.push('/password/send/complete')
    },
    onError: (e) => {
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '送信できませんでした' })
      }
    },
  })

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
    setEmailError(null)
    const emailError: string | null = validateEmail(event.target.value)
    if (emailError) {
      setEmailError(emailError)
    }
  }

  const sendEmail = async (): Promise<void> => {
    if (emailError !== null) {
      return
    }
    setFlashMessage(null)

    await userPasswordResetMutation({
      variables: {
        input: {
          email,
        },
      },
    })
  }

  return (
    <>
      <Meta title="パスワード再設定" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <PasswordReset onClick={sendEmail} onChange={onChangeEmail} email={email} emailError={emailError} />
    </>
  )
}
