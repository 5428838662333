import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { PageTitle } from '../../../atoms'
import { TwoColumnLayoutTab } from '../../../molecules'
import { BusinessProfile } from '../../../organisms'

import { mediaPc } from '../../../../lib/MediaQuery'
import mypageIcon from '../../../../assets/images/icon/mypage_green.png'

type Props = {
  children?: React.ReactNode
  currentTab?: 'affiliation' | 'creator' | 'sales' | 'csv'
  isVisibleBusinessUserEditButton?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  .title-wrapper {
    width: 100%;
    padding: 5vw 7.2vw;
    text-align: center;
    background: ${(props): string => props.theme.backgroundColors.gray};

    .page-title {
      width: auto;
      padding-left: 7vw;
      display: inline-block;
      letter-spacing: 0.1em;
      background: url('${mypageIcon}') left center no-repeat;
      background-size: 5vw;
    }
  }

  .content {
    width: 100vw;
    background: ${(props): string => props.theme.backgroundColors.gray};

    & > .inner {
      width: 100%;
      padding: 5vw 7.2vw 15vw;
    }

    .right-column {
      width: 100%;

      .tab {
        margin-bottom: 7.5vw;
      }
    }
  }

  ${mediaPc`
    .title-wrapper {
      width: 1040px;
      margin: 0 auto;
      padding: 80px 20px 45px;
      background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};

      .page-title {
        padding-left: 50px;
        letter-spacing: 0.3em;
        background-size: 32px;
      }
    }

    .content {
      width: 100%;
      min-width: 1040px;

      & > .inner {
        width: 1040px;
        margin: 0 auto;
        padding: 34px 20px 50px;
        display: flex;
        justify-content: space-between;
      }

      .right-column {
        width: 720px;

        .tab {
          margin-bottom: 16px;
        }

        .right-column-content {
          border-radius: 4px;
          min-height: 317px;
          padding: 25px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
        }
      }
    }
  `}
`

export const BusinessTwoColumnLayout: React.FC<Props> = ({ children, currentTab, isVisibleBusinessUserEditButton }) => {
  const history = useHistory()

  const spTabs: { label: string; selected: boolean; onClick: () => void }[] = [
    {
      label: '所属リスト',
      selected: currentTab === 'affiliation',
      onClick: () => {
        history.push('/business/affiliation')
      },
    },
    {
      label: 'クリエイターリスト',
      selected: currentTab === 'creator',
      onClick: () => {
        history.push('/business/creator')
      },
    },
    {
      label: '売上一覧',
      selected: currentTab === 'sales',
      onClick: () => {
        history.push('/business/sales/affiliation')
      },
    },
  ]

  const pcTabs: { label: string; selected: boolean; onClick: () => void }[] = [
    ...spTabs,
    {
      label: 'CSV書き出し',
      selected: currentTab === 'csv',
      onClick: () => {
        history.push('/business/sales/csv-export')
      },
    },
  ]

  return (
    <Wrapper>
      <div className="title-wrapper">
        <PageTitle title="マイページ" className="page-title" />
      </div>

      <div className="content">
        <div className="inner">
          <BusinessProfile isVisibleEditButton={isVisibleBusinessUserEditButton} />
          <div className="right-column">
            <TwoColumnLayoutTab tabs={isMobile ? spTabs : pcTabs} className="tab" />
            <div className="right-column-content">{children}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
