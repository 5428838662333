import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageSubtitle } from '../../atoms'
import { SubtitleWithButton, Pagination } from '../../molecules'
import { ProductListForManagement } from '../../organisms'
import { Creator, PublishedProduct, Product } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

type Props = {
  creator?: Creator
  publishedProducts: (PublishedProduct | Product)[]
  lastPage: number
  currentPage: number
  onClickToDisabled?: (productId: string) => void
  onClickToEnd?: (productId: string) => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }
  .no-data {
    width: 100%;
    height: auto;
    padding-left: 4vw;
    font-size: 3.2vw;
    font-weight: 500;
    line-height: 3.9em;
    letter-spacing: 0.05em;
    color: ${(props): string => props.theme.textColors.gray};
  }
  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title {
      margin-bottom: 35px;
    }
    .no-data {
      padding-left: 0px;
      text-align:center;
      font-size: 14px;
    }
  `}
`

export const CreatorPublishedProductsTpl: React.FC<Props> = ({
  creator,
  publishedProducts,
  lastPage,
  currentPage,
  onClickToDisabled,
  onClickToEnd,
}) => {
  const history = useHistory()

  const goToRegisterProduct = (): void => {
    if (userType() === 'business') {
      history.push(`/business/creator/${creator?.id}/product/register`)
    } else if (userType() === 'creator') {
      history.push('/creator/product/register')
    }
  }
  return (
    <Wrapper>
      {userType() === 'business' && (
        <SubtitleWithButton
          subtitle={`${creator?.name}さん 公開中の商品一覧`}
          buttonType="red"
          buttonText="新しい商品を販売する"
          className="title"
          onClick={goToRegisterProduct}
        />
      )}
      {userType() === 'creator' && (
        <>
          <PageSubtitle subtitle="公開中の商品一覧" className="title pc" />
          <SubtitleWithButton
            subtitle={'公開中の商品一覧'}
            buttonType="red"
            buttonText="新しい商品を販売する"
            className="title sp"
            onClick={goToRegisterProduct}
          />
        </>
      )}
      {!!publishedProducts.length && (
        <>
          <ProductListForManagement
            productListType="published"
            products={publishedProducts}
            oneLineItemsNum={4}
            onClickToDisabled={onClickToDisabled}
            onClickToEnd={onClickToEnd}
          />
          <Pagination lastPage={lastPage} currentPage={currentPage} />
        </>
      )}
      {!publishedProducts.length && (
        <div className="no-data">
          <p>販売商品はありません</p>
        </div>
      )}
    </Wrapper>
  )
}
