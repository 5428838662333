import React, { useState } from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Modal } from '../..'
import { ModalInputItem, ErrorMessagesBox } from '../../../molecules'
import { ModalTitle, TextInput, Selectbox, Button, TextButton } from '../../../atoms'
import { Affiliation } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import { validateEmail } from '../../../../lib/Validate'

type Props = {
  affiliations: Affiliation[]
  newCreatorEmail: string
  newCreatorAffiliation: string
  onChangeNewCreatorEmail: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeNewCreatorAffiliation: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onClickCreatorRegistration: () => void
  closeModal: () => void
}

const ModalContents = styled.div`
  padding: 32px 0;
  .inner {
    width: 86%;
    margin: 0 auto;
    .modal-title {
      margin-bottom: 8vw;
    }
    .input-email {
      margin-bottom: 3.7vw;
    }
    .input-affiliations {
      margin-bottom: 2.6vw;
    }
    .error-message {
      padding-top: 2.6vw;
      transition: 0.3s all;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.red};
      font-size: 3.2vw;
    }
    p {
      margin: 0 auto;
      line-height: 1;
      font-size: 3.2vw;
      &.note {
        width: 100%;
        margin-bottom: 8vw;
        color: ${(props): string => props.theme.textColors.black};
      }
      &.note-under-button {
        padding: 2.6vw 0 5.3vw;
      }
    }
    .button {
      width: 53vw;
      height: 8vw;
      font-size: 3.2vw;
      line-height: 8vw;
      display: block;
      margin: 0 auto;
    }
    .add-affiliation-link {
      display: block;
      margin: 0 auto;
      line-height: 3.83;
      font-size: 3.2vw;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.buttonColors.red};
    }
  }
  ${mediaPc`
    .inner {
      width: 100%;
      max-width: 375px;
      .modal-title {
        margin-bottom: 45px;
      }
      .input-email {
        margin-bottom: 14px;
      }
      .input-affiliations {
        margin-bottom: 10px;
      }
      .error-message {
        font-size: 12px;
      }
      p {
        font-size:12px;
        line-height: 21px;
        &.note {
          width:320px;
          margin-bottom: 40px;
        }
        &.note-under-button {
          text-align: center;
          padding: 10px 0 20px;
        }
      }
      .add-affiliation-link {
        text-align: center;
        font-size:12px;
        line-height: 21px;
      }
    .button {
        width: 200px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
        display: block;
        margin: 0 auto;
      }
      .add-affiliation-link {
        display: block;
        margin: 0 auto;
        line-height: 21px;
        font-size: 12px;
      }
    }
  `}
`

export const CreatorRegistrationModalTpl: React.FC<Props> = ({
  affiliations,
  newCreatorEmail,
  newCreatorAffiliation,
  onChangeNewCreatorEmail,
  onChangeNewCreatorAffiliation,
  onClickCreatorRegistration,
  closeModal,
}) => {
  const history = useHistory()
  const [emailError, setemailError] = useState<string[]>([])

  const isDisable = (): boolean => {
    if (!newCreatorEmail || emailError.length) {
      return true
    }
    return false
  }
  const affiliationOptions: { value: string; label: string }[] = [{ value: '', label: '所属を選択してください' }]
  affiliations.forEach((affiliation) => {
    affiliationOptions.push({ value: affiliation.id, label: affiliation.name })
  })

  const goToAddAffiliation = (): void => {
    history.push({ pathname: '/business/affiliation', state: { modalVisible: true } })
  }

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const errorText: string | null = validateEmail(value)
    const error = []
    if (errorText !== null) {
      error.push(errorText)
    }
    setemailError(error)
    onChangeNewCreatorEmail(event)
  }

  return (
    <Modal closeModal={closeModal}>
      <ModalContents>
        <div className="inner">
          <ModalTitle className="modal-title" title="新しいクリエイターを登録" />
          <ModalInputItem className="input-email" label="メールアドレス" inputType="email">
            <TextInput
              placeholder="メールアドレスを入力してください"
              type="email"
              value={newCreatorEmail}
              onChange={onChangeEmail}
              error={!!emailError.length}
            />
          </ModalInputItem>

          <ModalInputItem className="input-affiliations" label="所属" inputType="select">
            <Selectbox options={affiliationOptions} value={newCreatorAffiliation} onChange={onChangeNewCreatorAffiliation} />
          </ModalInputItem>
          <p className="note">所属の登録は任意ですが、登録すると、所属ごとの売上を確認できるようになります。</p>
          {!!emailError.length && <ErrorMessagesBox className="error-messages" errors={emailError} />}

          <Button
            buttonType="primary"
            className="button"
            onClick={() => {
              onClickCreatorRegistration()
              closeModal()
            }}
            disabled={isDisable()}
          >
            仮登録
          </Button>
          <p className="note-under-button">仮登録を押すとクリエイターに登録案内のメールが送信されます</p>
          <TextButton className="add-affiliation-link" onClick={goToAddAffiliation}>
            所属の新規追加はこちら
          </TextButton>
        </div>
      </ModalContents>
    </Modal>
  )
}
