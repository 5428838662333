import React from 'react'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'
import { convertToVw } from '../../lib/ConvertSize'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  disabled?: boolean
  value?: number
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: React.ReactNode
  buttonType?: 'primary' | 'secondary' | 'gray' | 'black' | 'red' | 'white' | 'blue'
}

const getTypes = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  if (props.buttonType === 'primary') {
    return `
      background: ${props.theme.buttonColors.primary};
    `
  } else if (props.buttonType === 'secondary') {
    return `
      background: ${props.theme.buttonColors.secondary};
    `
  } else if (props.buttonType === 'gray') {
    return `
      background: ${props.theme.buttonColors.gray};
    `
  } else if (props.buttonType === 'black') {
    return `
      background: ${props.theme.buttonColors.black};
    `
  } else if (props.buttonType === 'red') {
    return `
      background: ${props.theme.buttonColors.red};
    `
  } else if (props.buttonType === 'blue') {
    return `
      background: ${props.theme.buttonColors.blue};
    `
  } else if (props.buttonType === 'white') {
    return `
      color: ${props.theme.textColors.black};
      border: 1px solid ${props.theme.borderColors.black};
      background: ${props.theme.buttonColors.white};
    `
  } else {
    return ''
  }
}

const StyledButton = styled.button<Props>`
  width: 100%;
  height: auto;
  font-size: ${convertToVw('14px')};
  line-height: ${convertToVw('46px')};
  border-radius: 4px;
  text-align: center;
  transition: 0.3s all;
  color: ${(props): string => props.theme.textColors.white};

  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    background: #ccc;
  }

  ${(props) => getTypes(props)}

  ${mediaPc`
    font-size: 14px;
    line-height: 46px;
  `}
`

export const Button: React.FC<Props> = ({ buttonType, className = '', disabled, value, children, onClick }) => {
  return (
    <StyledButton buttonType={buttonType} className={className} disabled={disabled} value={value} onClick={onClick}>
      {children}
    </StyledButton>
  )
}
