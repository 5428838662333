import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'
import { ProductImage, ProductStockLabel, ProductPublishedDateLabel, MovieDuration, Button, Icon, Link } from '../../atoms'

import moment from 'moment'

import { Product, PublishedProduct, ProductType, ProductStatus } from '../../../types/graphql'

import { config } from '../../../config'

import liveTalkIcon from '../../../assets/images/icon/live_talk.png'
import externalLink from '../../../assets/images/icon/external_link.png'

type Props = {
  className?: string
  product: Product | PublishedProduct
}

const StyledProductDetail = styled.div`
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  padding: 5.5vw 0 5vw;
  .product-image {
    width: 75vw;
    max-width: 100%;
    margin: 0 auto 4.8vw;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
    }
    .movie-seconds-sp {
      position: absolute;
      width: auto;
      height: 7.4vw;
      padding: 0 3.6vw 0 8.6vw;
      bottom: 3vw;
      left: 3vw;
      z-index: 5;
      font-size: 3.2vw;
      line-height: 7.4vw;
      border-radius: 3.7vw;
    }
    .live-talk-icon {
      position: absolute;
      width: 32.3vw;
      z-index: 5;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
    }
  }
  .product-info {
    width: 75vw;
    max-width: 100%;
    margin: 0 auto;
    .released-date {
      margin-bottom: 3.2vw;
      font-size: 2.67vw;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.placeholder};
    }
    .name {
      margin-bottom: 3.2vw;
      font-size: 4.27vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.black};
    }
    .live-talk-attention {
      margin-bottom: 2.1vw;
      padding: 2.9vw;
      color: ${(props): string => props.theme.textColors.red};
      font-size: 3.2vw;
      font-weight: bold;
      line-height: 1.7;
      background: ${(props): string => props.theme.backgroundColors.bodyBg};
      border: 1px solid ${(props): string => props.theme.borderColors.red};
      border-radius: 4px;
    }
    .livetalk-lp-link-wrapper {
      margin-bottom: 4.2vw;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .link {
        margin-right: 0.266vw;
        color: ${(props): string => props.theme.textColors.primary};
        font-size: 2.66vw;
        font-weight: bold;
        line-height: 1.2;
        text-decoration-line: underline;
      }

      .icon {
        width: 2.66vw;
        height: 2.66vw;
      }
    }
    .schedule-wrapper {
      margin-bottom: 4.27vw;
      display: flex;
      align-items: center;
      > p {
        font-size: 3.733vw;
      }
      .schedule-title {
        margin-right: 3.2vw;
      }
    }
    .live-talk-note {
      margin-bottom: 2.9vw;
      color: ${(props): string => props.theme.textColors.red};
      font-size: 3.2vw;
      font-weight: bold;
      line-height: 1.7;
    }
    .price {
      margin-bottom: 1.5vw;
      font-size: 4.27vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    }
  }
  .purchase-button {
    width: 100%;
    height: 12.2vw;
    line-height: 12.2vw;
    font-size: 3.735vw;
  }
  .attention-terms {
    margin: 0 auto;
    width: 75vw;
    color: ${(props): string => props.theme.textColors.black};
    padding: 15px 0 0;
    letter-spacing: 0.05em;
    font-size: 3.2vw;
    margin-bottom: 4.2vw;
  }

  ${mediaPc`
      display: flex;
      justify-content: space-between;
      min-height: 317px;
      padding:0 0 40px;
      .product-image {
        width: 329px;
        margin: initial;
        .live-talk-icon {
          width: 121px;
        }
      }
      .product-info {
        width: 310px;
        .released-date {
          padding:0 0 15px;
          margin: 0;
          text-align: right;
          font-size:12px;
          color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.placeholder};
        }
        .name {
          font-weight: bold;
          font-size: 16px;
          padding: 0 0 19px;
          margin: 0;
          color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
        }
        .movie-seconds-pc {
          width: 96px;
          height: 28px;
          background-size: 20px;
          background-position: 4px;
          padding: 0 0 0 31px;
          font-size: 12px;
          line-height: 28px;
        }
        .live-talk-attention {
          margin-bottom: 9px;
          padding: 11px;
          font-size: 12px;
        }
        .livetalk-lp-link-wrapper {
          margin-bottom: 8px;
          .link{
            margin-right: 1px;
            font-size: 10px;
          }
          .icon {
            width: 10px;
            height: 10px;
          }
        }
    
        .schedule-wrapper {
          margin-bottom: 16px;
    
          > p {
            font-size: 14px;
          }
    
          .schedule-title {
            margin-right: 12px;
          }
        }
    
        .live-talk-note {
          margin-bottom: 30px;
          font-size: 12px;
        }
        
        .price {
          font-weight: bold;
          padding: 53px 0 33px;
          text-align: right;
          font-size: 24px;
          color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
        }
      }
      .purchase-button {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }
      .attention-terms {
        width :100%;
        padding: 15px 0 0;
        font-size:12px;
        line-height: 21px;
      }
  `}
`

export const ProductDetail: React.FC<Props> = ({ className = '', product }) => {
  const history = useHistory()

  const goToPurchase = (): void => {
    history.push(`/purchase/${product.id}`)
  }

  const isPurchaseButtonDisabled = product.status !== ProductStatus.Sale || product.remaining_stock === 0

  return (
    <StyledProductDetail className={className}>
      <div className="product-image">
        <ProductImage src={product.thumbnail_image} />
        {product.status === ProductStatus.Reserve ? (
          <ProductPublishedDateLabel className="published-date-label" releasedAt={product.released_at} />
        ) : (
          <ProductStockLabel status={product.status} stock={product.remaining_stock} type={product.product_type} />
        )}
        {product.product_type === ProductType.Movie && <MovieDuration className="movie-seconds-sp sp" duration={product.movie_seconds} />}
        {product.product_type === ProductType.LiveTalk && <Icon className="live-talk-icon" src={liveTalkIcon} alt="ライブトーク" />}
      </div>
      <div className="product-info">
        <div className="released-date pc">
          {product.released_at ? moment(product.released_at).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} 公開
        </div>
        <div className="name">{product.name}</div>
        {product.product_type === ProductType.Movie && <MovieDuration className="movie-seconds-pc pc" duration={product.movie_seconds} />}
        {product.product_type === ProductType.LiveTalk && (
          <>
            <p className="live-talk-attention">
              オンラインでお話できる商品です。
              <br />
              ご購入の前に必ず日程をご確認ください。
            </p>
            <div className="livetalk-lp-link-wrapper">
              {userType() === 'user' || userType() === 'creator' ? (
                <Link
                  url={userType() === 'user' ? '/lp/live-talk' : '/creator/lp/live-talk'}
                  text="ライブトークとは"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                />
              ) : (
                <a href={`${config.creatorFrontEndpoint}/lp/live-talk`} target="_blank" rel="noopener noreferrer" className="link">
                  ライブトークとは
                </a>
              )}
              <Icon src={externalLink} className="icon" />
            </div>
            <div className="schedule-wrapper">
              <p className="schedule-title">日付</p>
              <p className="schedule">{moment(product.live_talk?.started_at).format('YYYY/MM/DD')}</p>
            </div>
            <div className="schedule-wrapper">
              <p className="schedule-title">時間</p>
              <p className="schedule">
                {moment(product.live_talk?.started_at).format('HH時mm分')}~
                {moment(product.live_talk?.started_at).add(product.live_talk?.talk_minutes, 'minutes').format('HH時mm分')}　（
                {product.live_talk?.talk_minutes}分間）
              </p>
            </div>
          </>
        )}
        <div className="price">¥{product.price.toLocaleString()}</div>
        <div className="released-date sp">
          {product.released_at ? moment(product.released_at).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} 公開
        </div>
        {userType() === 'user' && (
          <>
            <Button className="purchase-button" buttonType="primary" onClick={goToPurchase} disabled={isPurchaseButtonDisabled}>
              {isPurchaseButtonDisabled ? 'この商品は販売終了しました' : '購入する'}
            </Button>
            <p className="attention-terms">
              <Link className="link" url="/terms-of-use" text="利用規約" target="_blank" rel="noopener noreferrer" />
              、
              <Link className="link" url="/privacy" text="プライバシーポリシー" target="_blank" rel="noopener noreferrer" />
              をご確認の上、購入画面へお進みください。
            </p>
          </>
        )}
      </div>
    </StyledProductDetail>
  )
}
