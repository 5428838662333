import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  name?: string
  className?: string
  value?: string
  min?: string
  max?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const StyledInput = styled.input`
  width: 100%;
  height: 12vw;
  padding: 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  color: ${(props): string => props.theme.textColors.black};
  font-size: 3.733vw;
  line-height: 2.86;
  transition: 0.3s all;
  &:focus {
    outline: 0;
    border: 1px solid ${(props): string => props.theme.borderColors.primary};
  }
  &::placeholder {
    color: ${(props): string => props.theme.textColors.placeholder};
    font-weight: 500;
  }
  &.error {
    border: 1px solid ${(props): string => props.theme.textColors.red};
  }
  box-sizing: border-box;

  ${mediaPc`
    height: auto;
    font-size: 14px;
    line-height: 3.28;
  `}
`

export const DateInput: React.FC<Props> = ({ name = '', value, className = '', min, max, onChange }) => {
  return <StyledInput className={className} type="date" value={value} min={min} max={max} name={name} onChange={onChange} />
}
