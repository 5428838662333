import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  subtitle?: string
}

const StyledPageSubtitle = styled.h2`
  width: 100%;
  font-size: 3.2vw;
  color: ${(props): string => props.theme.textColors.gray};
  font-weight: bold;
  letter-spacing: 0.05em;

  ${mediaPc`
    font-size: 16px;
  `}
`

export const PageSubtitle: React.FC<Props> = ({ className = '', subtitle = '' }) => {
  return <StyledPageSubtitle className={className}>{subtitle}</StyledPageSubtitle>
}
