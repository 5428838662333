import React from 'react'
import styled, { css } from 'styled-components'
import { LiveTalk, LiveTalkCountDownTimer } from '../../../atoms'
import { NotEnteredTalkRoomView, AbsenceLiveTalkView, FinishLiveTalkDialog } from '../../../molecules'
import { LocalVideoTrack, LocalAudioTrack, RemoteVideoTrack, RemoteAudioTrack } from 'twilio-video'
import { Product } from '../../../../types/graphql'
import { LiveTalkStatus } from '../../../../types/myTypes'
import { useInnerHeight, useOrientationType, OrientationType } from '../../../../lib/SetWindow'

type Props = {
  liveTalkStatus?: LiveTalkStatus
  localTracks: (LocalVideoTrack | LocalAudioTrack | null)[]
  remoteTracks: (RemoteVideoTrack | RemoteAudioTrack | null)[]
  product: Product
  talkSeconds: number
  showFinishButton: boolean
  finishLiveTalk: () => void
}

type StyleProps = {
  innerHeight: number
  orientationType: OrientationType
}

const Wrapper = styled.div<StyleProps>`
  background-color: ${(props): string => props.theme.backgroundColors.black};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 20;
  .inner {
    display: flex;
    justify-content: center;
    .not-entered-wrapper {
      width: calc(100vh * 9 / 16);
      height: ${(props): string => `${props.innerHeight}px`};
    }
    .video-wrapper {
      width: auto;
      height: ${(props): string => `${props.innerHeight}px`};
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
      .timer {
        position: absolute;
        left: 9px;
        top: 17px;
        z-index: 4;
      }
      .finish-dialog {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
      }
      .is-audio,
      .visible .is-audio {
        display: none;
      }
    }
  }
  ${(props) =>
    props.orientationType === 'portrait' &&
    css`
      .inner {
        .video-wrapper {
          width: calc(100vh * 9 / 16);
          height: ${`${props.innerHeight}px`};
          position: relative;
          display: flex;
          justify-content: center;
          overflow: hidden;
          .timer {
            position: absolute;
            left: 9px;
            top: 17px;
            z-index: 4;
          }
          .finish-dialog {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
          }
          .local-video {
            width: 100px;
            height: 175.62px;
            position: absolute;
            bottom: 6px;
            right: 5px;
            border-radius: 4px;
            z-index: 3;
          }
        }
      }
    `}

  ${(props) =>
    props.orientationType === 'landscape' &&
    css`
      .inner {
        .video-wrapper {
          width: auto;
          .local-video {
            position: static;
            bottom: auto;
            right: auto;
            left: 0;
            border-radius: 0px;
          }
        }
      }
    `}
`
export const CreatorLiveTalkTpl: React.FC<Props> = ({
  localTracks,
  remoteTracks,
  liveTalkStatus,
  product,
  talkSeconds,
  showFinishButton,
  finishLiveTalk,
}) => {
  const innerHeight = useInnerHeight()
  const orientationType = useOrientationType()

  return (
    <Wrapper innerHeight={innerHeight} orientationType={orientationType}>
      <div className="inner">
        {!!liveTalkStatus && liveTalkStatus !== 'talking' && (
          <div className="not-entered-wrapper">
            <NotEnteredTalkRoomView liveTalkStatus={liveTalkStatus} thumbnail={product.thumbnail_image} />
          </div>
        )}
        {liveTalkStatus === 'talking' && (
          <div className="video-wrapper">
            {!showFinishButton && <LiveTalkCountDownTimer className="timer" talkSeconds={talkSeconds} />}
            {showFinishButton && <FinishLiveTalkDialog onClickFinish={finishLiveTalk} className="finish-dialog" />}
            {localTracks.map((track) => (
              <LiveTalk
                track={track}
                key={track?.name}
                className={track?.kind === 'audio' ? 'is-audio' : 'local-video'}
                isLocalVideo={true}
              />
            ))}
            {remoteTracks.length > 0 && (
              <>
                {remoteTracks.map((track) => (
                  <LiveTalk track={track} key={track?.name} className={track?.kind === 'audio' ? 'is-audio' : ''} />
                ))}
              </>
            )}

            {remoteTracks.length === 0 && <AbsenceLiveTalkView />}
          </div>
        )}
      </div>
    </Wrapper>
  )
}
