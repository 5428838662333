import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PageSubtitle, ContentTitle } from '../../../atoms'
import { OrderTable, DeliveryAttention, Pagination, ContentTitleWithLink } from '../../../molecules'
import { Creator, OrderedProduct } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  creator: Creator
  undeliveredOrders: OrderedProduct[]
  deliveredOrders: OrderedProduct[]
  currentPage: number
  lastPage: number
  onClickOrderedUsers: () => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }

  .order-table-title {
    margin-bottom: 3vw;
  }

  .undelivered-orders {
    margin-bottom: 7vw;
  }

  .ordered-users {
  }
  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title {
      margin-bottom: 35px;
    }

    .order-table-title {
      margin-bottom: 15px;
    }
  
    .undelivered-orders {
      margin-bottom: 35px;
    }
  `}
`

export const BusinessCreatorOrdersTpl: React.FC<Props> = ({
  creator,
  undeliveredOrders,
  deliveredOrders,
  currentPage,
  lastPage,
  onClickOrderedUsers,
}) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle={`${creator.name}さん　注文状況一覧`} />
      <div className="undelivered-orders">
        <ContentTitle title="納品が完了していない注文" className="order-table-title" />
        <OrderTable
          orderType="undelivered"
          orders={undeliveredOrders}
          noDataText={`商品が購入されるとこちらに表示されます。${'\n'}納品を済ませることで完了となります。`}
        />
        <DeliveryAttention />
      </div>
      <div className="delivered-orders">
        {/* 納品が完了した注文 */}
        <ContentTitleWithLink
          title="納品が完了した注文"
          className="order-table-title"
          linkText="購入者一覧"
          onClick={onClickOrderedUsers}
        />
        <OrderTable orderType="delivered" orders={deliveredOrders} noDataText="納品が完了した注文はありません" />
        <Pagination lastPage={lastPage} currentPage={currentPage} />
      </div>
    </Wrapper>
  )
}
