import { useState, useEffect } from 'react'

export const useInnerHeight = (): number => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  const handleResize = (): void => {
    setInnerHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('orientationchange', handleResize)
    return () => {
      window.removeEventListener('orientationchange', handleResize)
    }
  }, [])

  return innerHeight
}

export type OrientationType = 'portrait' | 'landscape'

const getMediaOrientation = (): OrientationType => {
  if (window.innerHeight > window.innerWidth) {
    return 'portrait'
  }
  return 'landscape'
}

export const useOrientationType = (): OrientationType => {
  const initialOrientationType: 'portrait' | 'landscape' = getMediaOrientation()
  const [orientationType, setOrientationType] = useState<OrientationType>(initialOrientationType)
  const handleOrientationType = (): void => {
    if (window.innerHeight > window.innerWidth) {
      setOrientationType('portrait')
    } else {
      setOrientationType('landscape')
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleOrientationType)
    return () => {
      window.removeEventListener('resize', handleOrientationType)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationType)
    return () => {
      window.removeEventListener('orientationchange', handleOrientationType)
    }
  }, [])

  return orientationType
}
