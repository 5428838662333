import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
  buttons: { id: string; name: string; value: string; label: string; onChange: () => void }[]
  checkedValue: string
}

const Wrapper = styled.div`
  display: flex;
`

const RadioWrapper = styled.div`
  display: flex;

  & + & {
    margin-left: 11.7vw;
    ${mediaPc`
    margin-left: 40px;
  `}
  }
`

const StyledRadioButton = styled.input`
  display: none;
`

const StyledRadioLabel = styled.label`
  ${StyledRadioButton}:checked + &::after {
    opacity: 1;
  }

  ${StyledRadioButton} + & {
    padding-left: 8.8vw;
    position: relative;
    display: block;
    font-size: 3.467vw;
    line-height: calc(5.8vw + 2px);
    color: ${(props): string => props.theme.textColors.gray};
    cursor: pointer;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 5.8vw;
      height: 5.8vw;
      content: '';
      background: ${(props): string => props.theme.backgroundColors.bodyBg};
      border: 1px ${(props): string => props.theme.textColors.placeholder} solid;
      border-radius: 50%;
    }
    &::after {
      width: 2.8vw;
      height: 2.8vw;
      position: absolute;
      top: calc(1.5vw + 1px);
      left: calc(1.5vw + 1px);
      content: '';
      background: ${(props): string => props.theme.backgroundColors.primary};
      border-radius: 50%;
      opacity: 0;
      transition: 0.3s all;
    }
  }
  ${mediaPc`
    ${StyledRadioButton} + & {
      padding-left: 35px;
      font-size: 14px;
      line-height:24px;
      &::before {
        width:22px;
        height:22px;
      }
      &::after {
        width:10px;
        height:10px;
        top: 7px;
        left: 7px;
      }
    }
  `}
`

export const RadioButton: React.FC<Props> = ({ className = '', buttons, checkedValue }) => {
  return (
    <Wrapper className={className}>
      {buttons.map((button) => (
        <RadioWrapper key={button.value}>
          <StyledRadioButton
            type="radio"
            checked={checkedValue === button.value}
            onChange={button.onChange}
            id={button.id}
            name={button.name}
            value={button.value}
          />
          <StyledRadioLabel htmlFor={button.id}>{button.label}</StyledRadioLabel>
        </RadioWrapper>
      ))}
    </Wrapper>
  )
}
