import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorTwoColumnLayout, DialogTpl, CreatorMypageTopTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'

import { FlashMessageType } from '../../../types/myTypes'
import {
  SalesPerMonth,
  OrderedProduct,
  Product,
  PublishedProduct,
  useCreatorUndeliveredOrderedProductsLazyQuery,
  useCreatorSalesPerMonthInYearLazyQuery,
  usePagedPublishedProductsLazyQuery,
  usePagedCreatorDisabledProductsLazyQuery,
  usePagedCreatorReservedProductsLazyQuery,
  useCreatorFinishProductMutation,
  useCreatorDisableProductMutation,
  useCreatorDisplayProductMutation,
  useUpdateCreatorEmailByTokenMutation,
} from '../../../types/graphql'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import { CreatorProfileContext } from '../../../context'

export const CreatorMypageTop: React.FC = () => {
  const [sales, setSales] = useState<SalesPerMonth[]>([])
  const [disabledProducts, setDisabledProducts] = useState<Product[]>([])
  const [publishedProducts, setPublishedProducts] = useState<PublishedProduct[]>([])
  const [reservedProducts, setReservedProducts] = useState<Product[]>([])
  const [selectedProductId, setSelectedProductId] = useState<string>('')
  const [orders, setOrders] = useState<OrderedProduct[]>([])

  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [toEndDialogVisible, setToEndDialogVisible] = useState<boolean>(false)

  const { creator, updateCreator } = useContext(CreatorProfileContext)

  const history = useHistory()
  const location = useLocation<{
    type?: FlashMessageType['type']
    message?: FlashMessageType['message']
    from?: string
    editComplete?: boolean
    registerComplete?: boolean
  }>()

  const [creatorUndeliveredOrderedProductsLazyQuery] = useCreatorUndeliveredOrderedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setOrders(data.CreatorUndeliveredOrderedProducts ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [creatorSalesPerMonthInYearLazyQuery] = useCreatorSalesPerMonthInYearLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setSales([data.CreatorSalesPerMonthInYear[0]])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedPublishedProductsLazyQuery] = usePagedPublishedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setPublishedProducts(data.PagedPublishedProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedCreatorDisabledProductsLazyQuery] = usePagedCreatorDisabledProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setDisabledProducts(data.PagedCreatorDisabledProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedCreatorReservedProductsLazyQuery] = usePagedCreatorReservedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setReservedProducts(data.PagedCreatorReservedProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [creatorDisableProductMutation] = useCreatorDisableProductMutation({
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchProducts()
      setFlashMessage({ type: 'success', message: '選択した商品を非表示にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const [creatorFinishProductMutation] = useCreatorFinishProductMutation({
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchProducts()
      setFlashMessage({ type: 'success', message: '選択した商品を販売終了にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const [creatorDisplayProductMutation] = useCreatorDisplayProductMutation({
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchProducts()
      setFlashMessage({ type: 'success', message: '選択した商品を再表示しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const [updateCreatorEmailByTokenMutation] = useUpdateCreatorEmailByTokenMutation({
    onCompleted: (data) => {
      if (data.UpdateCreatorEmailByToken) {
        updateCreator(data.UpdateCreatorEmailByToken)
      }
      setLoading(false)
      setFlashMessage({ type: 'success', message: 'メールアドレスを更新しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickToDisabled = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    creatorDisableProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }

  const onClickToEnd = (productId: string): void => {
    setToEndDialogVisible(true)
    setSelectedProductId(productId)
  }

  const onClickToEndDialogOk = (): void => {
    setToEndDialogVisible(false)
    setLoading(true)
    setFlashMessage(null)
    creatorFinishProductMutation({
      variables: {
        product_id: selectedProductId,
      },
    })
  }

  const onClickToEndDialogCancel = (): void => {
    setToEndDialogVisible(false)
  }

  const onClickToDisplay = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    creatorDisplayProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }

  const fetchProducts = useCallback(() => {
    pagedPublishedProductsLazyQuery({
      variables: {
        creator_id: creator.id,
        first: isMobile ? 4 : 8,
        page: 1,
      },
    })
    pagedCreatorDisabledProductsLazyQuery({
      variables: {
        first: isMobile ? 4 : 8,
        page: 1,
      },
    })
    pagedCreatorReservedProductsLazyQuery({
      variables: {
        first: isMobile ? 4 : 8,
        page: 1,
      },
    })
  }, [creator, pagedPublishedProductsLazyQuery, pagedCreatorDisabledProductsLazyQuery, pagedCreatorReservedProductsLazyQuery])

  useValidateCreatorToken()
  useSetCreatorProfile()
  useEffect(() => {
    creatorUndeliveredOrderedProductsLazyQuery()
    creatorSalesPerMonthInYearLazyQuery({
      variables: {
        // year未指定で直近の12ヶ月を取得してくれる
        input: {},
      },
    })
    if (creator.id) {
      fetchProducts()
    }
  }, [creator, creatorSalesPerMonthInYearLazyQuery, creatorUndeliveredOrderedProductsLazyQuery, fetchProducts])

  useEffect(() => {
    if (location.state) {
      // アカウントを登録した時のリダイレクト
      if (location.state.registerComplete === true) {
        setFlashMessage({ type: 'success', message: '登録しました' })
      }

      // アカウント編集でアドレス以外を編集した時のリダイレクト
      if (location.state.editComplete === true) {
        setFlashMessage({ type: 'success', message: '更新しました' })
      }

      // アカウント編集でメールアドレスを変更した時のメールからの遷移
      if (location.state.from === 'updateEmail') {
        const params = new URLSearchParams(location.search)
        const id = params.get('id') || null
        const email = params.get('email') || null
        const expires = params.get('expires') || null
        const signature = params.get('signature') || null
        if (id && email && expires && signature) {
          setLoading(true)
          updateCreatorEmailByTokenMutation({
            variables: {
              input: {
                id: id,
                email: email,
                expires: Number(expires),
                signature: signature,
              },
            },
          })
        } else {
          Sentry.captureException('クリエイターのメールアドレスを更新できませんでした')
          setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
        }
      }
    }
  }, [location, updateCreatorEmailByTokenMutation])

  // location.stateがセットされたままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (location.state) {
      history.replace({ ...location, state: undefined, search: undefined })
    }
  }, [history, location])

  return (
    <>
      <Meta title="マイページTOP" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {toEndDialogVisible && (
        <DialogTpl title="この商品を販売終了にしますか？" onClickOk={onClickToEndDialogOk} onClickCancel={onClickToEndDialogCancel}>
          販売終了にすると、この商品を再度販売できなくなります。再販する場合、新たに商品登録をお願い致します。
        </DialogTpl>
      )}
      <CreatorTwoColumnLayout currentTab="creatorInfo">
        <>
          <CreatorMypageTopTpl
            sales={sales}
            publishedProducts={publishedProducts ?? []}
            reservedProducts={reservedProducts ?? []}
            disabledProducts={disabledProducts ?? []}
            orders={orders ?? []}
            onClickToDisabled={onClickToDisabled}
            onClickToEnd={onClickToEnd}
            onClickToDisplay={onClickToDisplay}
          />
        </>
      </CreatorTwoColumnLayout>
    </>
  )
}
