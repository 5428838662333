import React from 'react'
import styled from 'styled-components'
import { PageSubtitle } from '../../../atoms'
import { SalesPerOrderTable, Pagination } from '../../../molecules'
import { CreatorSalesPerOrderTitle } from '../../../organisms'
import { SalesPerMonth, SalesPerOrder, Creator } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'

type Props = {
  affiliationName?: string
  month: SalesPerMonth['month']
  sales: SalesPerOrder[]
  creators: Creator[]
  selectedCreator: string
  lastPage: number
  currentPage: number
  onCreatorChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  .title {
    width: 100%;
    margin-bottom: 4.8vw;
  }

  .table-title {
    margin-bottom: 3vw;
  }

  .sales-per-order {
    margin-bottom: 10.7vw;
  }

  ${mediaPc`
    .title {
      font-size: 16px;
      margin-bottom: 13px;
    }

    .table-title {
      margin-bottom: 22px;
    }

    .sales-per-order {
      margin-bottom: 40px;
    }
  `}
`

export const BusinessCreatorSalesPerOrderTpl: React.FC<Props> = ({
  affiliationName,
  month,
  sales,
  creators,
  selectedCreator,
  lastPage,
  currentPage,
  onCreatorChange,
}) => {
  return (
    <Wrapper>
      <PageSubtitle className="title" subtitle="売上一覧" />
      <CreatorSalesPerOrderTitle
        affiliationName={affiliationName || '所属なし'}
        month={month}
        creators={creators}
        selectedCreator={selectedCreator}
        onCreatorChange={onCreatorChange}
        className="table-title"
      />
      <SalesPerOrderTable sales={sales} noDataText="月の売上が一覧で表示されます" className="sales-per-order" />
      <Pagination lastPage={lastPage} currentPage={currentPage} />
    </Wrapper>
  )
}
