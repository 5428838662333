import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Button, PageSubtitle } from '../../../atoms'
import { SubtitleWithButton, ContentTitleWithLink, OrderTable, DeliveryAttention } from '../../../molecules'
import { CreatorSaleThisMonth, ProductListForManagement } from '../../../organisms'
import { SalesPerMonth, Product, PublishedProduct, OrderedProduct } from '../../../../types/graphql'
import { mediaPc } from '../../../../lib/MediaQuery'
import { CreatorProfileContext } from '../../../../context'

type Props = {
  sales: SalesPerMonth[]
  publishedProducts: PublishedProduct[]
  reservedProducts: Product[]
  disabledProducts: Product[]
  orders: OrderedProduct[]
  onClickToDisabled?: (productId: string) => void
  onClickToEnd?: (productId: string) => void
  onClickToDisplay?: (productId: string) => void
}

const Wrapper = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.backgroundColors.gray};
  .title {
    width: 100%;
    margin-bottom: 4.3vw;
  }
  .orders {
    margin-bottom: 7vw;

    .table-title {
      margin-bottom: 3vw;
    }
  }

  .head-line {
    margin-bottom: 4.3vw;
  }

  .creator-sale-this-month {
    margin-bottom: 7vw;
  }

  .products {
    margin-bottom: 7vw;

    .no-products {
      width: 100%;
      padding-bottom: 15px;
      text-align: center;
      color: ${(props): string => props.theme.textColors.gray};
    }
  }

  .go-to-registration-product {
    width: 100%;

    &.sp {
      margin-bottom: 7vw;
    }
  }

  ${mediaPc`
    background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    .title.pc {
      margin-bottom: 25px;
      display: flex;

      button {
        width: 190px;

      }
    }
    .orders {
      margin-bottom: 60px;

      .table-title {
        margin-bottom: 10px;
      }
    }

    .head-line {
      margin-bottom: 15px;
    }

    .creator-sale-this-month {
      margin-bottom: 60px;
    }

    .products {
      margin-bottom: 60px;
    }

    .go-to-registration-product {
      width: 320px;
      margin: 0 auto 45px;
      display: block;

      &.sp{
        display: none;
      }
    }
  `}
`

export const CreatorMypageTopTpl: React.FC<Props> = ({
  sales,
  publishedProducts,
  reservedProducts,
  disabledProducts,
  orders,
  onClickToDisabled,
  onClickToEnd,
  onClickToDisplay,
}) => {
  const history = useHistory()
  const { creator } = useContext(CreatorProfileContext)

  const goToCreatorPage = (): void => {
    history.push(`/creator/${creator.id}/user`)
  }

  const goToOrders = (): void => {
    history.push(`/creator/orders`)
  }

  const goToPublishedProductList = (): void => {
    history.push(`/creator/products/published`)
  }

  const goToReserveProductList = (): void => {
    history.push(`/creator/products/reserved`)
  }

  const goToDisabledProductList = (): void => {
    history.push(`/creator/products/disabled`)
  }

  const goToRegisterProduct = (): void => {
    history.push(`/creator/product/register`)
  }

  return (
    <Wrapper>
      <SubtitleWithButton
        className="title pc"
        subtitle="クリエイター情報詳細"
        buttonText="クリエイターページを確認"
        buttonType="primary"
        onClick={goToCreatorPage}
      />
      <PageSubtitle subtitle="クリエイター情報詳細" className="title sp" />
      <div>
        <div className="orders">
          <ContentTitleWithLink className="table-title" title="注文状況" linkText="一覧を見る" onClick={goToOrders} />
          <OrderTable
            orderType="undelivered"
            orders={orders}
            noDataText={`商品が購入されるとこちらに表示されます。${'\n'}納品を済ませることで完了となります。`}
          />
          <DeliveryAttention />
        </div>

        <CreatorSaleThisMonth className="creator-sale-this-month" sales={sales} />

        <div className="products">
          <ContentTitleWithLink className="head-line" title="公開中の商品" linkText="一覧を見る" onClick={goToPublishedProductList} />
          {publishedProducts.length > 0 ? (
            <ProductListForManagement
              productListType="published"
              products={publishedProducts}
              oneLineItemsNum={4}
              onClickToDisabled={onClickToDisabled}
              onClickToEnd={onClickToEnd}
            />
          ) : (
            <div className="no-products">販売中の商品はありません</div>
          )}
        </div>
        <div className="products">
          <ContentTitleWithLink className="head-line" title="公開日時指定商品" linkText="一覧を見る" onClick={goToReserveProductList} />
          {reservedProducts.length > 0 ? (
            <ProductListForManagement
              productListType="reserved"
              products={reservedProducts}
              onClickToDisabled={onClickToDisabled}
              oneLineItemsNum={4}
            />
          ) : (
            <div className="no-products">公開日時指定中の商品はありません</div>
          )}
        </div>
        <div className="products">
          <ContentTitleWithLink className="head-line" title="非表示の商品" linkText="一覧を見る" onClick={goToDisabledProductList} />
          {disabledProducts.length > 0 ? (
            <ProductListForManagement
              productListType="disabled"
              products={disabledProducts}
              onClickToDisplay={onClickToDisplay}
              oneLineItemsNum={4}
            />
          ) : (
            <div className="no-products">非表示の商品はありません</div>
          )}
        </div>
        <Button className="go-to-registration-product" buttonType="red" onClick={goToRegisterProduct}>
          新しい商品を販売する
        </Button>
      </div>
    </Wrapper>
  )
}
