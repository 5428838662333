import React from 'react'
import styled from 'styled-components'
import { mediaPc } from '../../lib/MediaQuery'

type Props = {
  className?: string
}

const StyledLiveTalkBrowseAttention = styled.p`
  width: 100%;
  font-size: 2.6vw;
  color: ${(props): string => props.theme.textColors.black};
  letter-spacing: 0.05em;
  span {
    display: block;
    font-weight: bold;
  }

  ${mediaPc`
    font-size: 10px;
  `}
`

export const LiveTalkBrowseAttention: React.FC<Props> = ({ className = '' }) => {
  return (
    <StyledLiveTalkBrowseAttention className={className}>
      ※ 古い端末や推奨ブラウザ以外など、環境によってはご利用ただけない場合がございます。
      <br />
      お使いのブラウザが最新版でない場合も、正常に動作しない場合もございますので、最新版にアップデートをお願いいたします。
      <br />
      <br />
      <span>推奨ブラウザについて</span>
      iOSご利用の方はsafari(最新版)を使用することを推奨します。その他のブラウザの場合、正しく表示されない可能性があります。
    </StyledLiveTalkBrowseAttention>
  )
}
