import React from 'react'
import { CopyLink } from '../../atoms'

type Props = {
  className?: string
  alertText?: string
  children: React.ReactNode
  onClick?: () => void
  copyTarget: string
}

export const CopyLinkWithAlert: React.FC<Props> = ({ className = '', children, alertText, onClick, copyTarget }) => {
  const copyToClipboard = (): void => {
    const textField = document.createElement('textarea')
    textField.innerText = copyTarget
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    alert(alertText)

    if (!!onClick) {
      onClick()
    }
  }

  return (
    <CopyLink className={className} onClick={copyToClipboard}>
      {children}
    </CopyLink>
  )
}
