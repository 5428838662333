import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { TextInput, Button } from '../../atoms'
import { ErrorMessagesBox } from '../../molecules'
import { externalLink } from '../../../config'
import { userType } from '../../../lib/UserType'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  email: string
  password: string
  onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void
  login: () => void
  emailError: string | null
  passwordError: string | null
}

const Wrapper = styled.div`
  .title {
    margin-bottom: 2.7vw;

    h2 {
      width: 100%;
      font-size: 3.2vw;
      font-weight: bold;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
      letter-spacing: 0.05em;
    }
  }
  .text-input {
    margin-bottom: 4.8vw;
  }
  .login {
    margin-bottom: 2.7vw;
  }
  .link {
    padding: 0 0 2vw;
    text-align: right;

    a {
      font-size: 3.2vw;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.primary};
      display: inline-block;
    }
  }
  ${mediaPc`
    .title {
      margin-bottom: 20px;

      h2 {
        font-size: 16px;
      }
    }
    .text-input {
      width: 320px;
      height: 40px;
      margin-bottom: 24px;
      padding: 0 17px;
      font-size: 14px;
      line-height: 1;
    }
    .login {
      margin-bottom: 20px;
      line-height: 40px;
    }
    .link {
      padding: 0 0 8px;
      a {
        font-size: 12px;
      }
    }
  `}
`

export const LoginForm: React.FC<Props> = ({ email, password, onChangeEmail, onChangePassword, login, emailError, passwordError }) => {
  const errors: string[] = []
  if (emailError) {
    errors.push(emailError)
  }
  if (passwordError) {
    errors.push(passwordError)
  }

  const contentsTitle = (): string => {
    const type = userType()
    switch (type) {
      case 'user':
        return 'ユーザーログイン'
      case 'creator':
        return 'クリエイターログイン'
      default:
        return 'ログイン'
    }
  }
  const isDisable = (): boolean => {
    if (!email || !password || errors.length > 0) {
      return true
    }
    return false
  }

  return (
    <Wrapper>
      <div className="title">
        <h2>{contentsTitle()}</h2>
      </div>
      <TextInput
        className="text-input"
        placeholder="メールアドレスを入力してください"
        type="email"
        value={email}
        error={!!emailError}
        onChange={onChangeEmail}
      />
      <TextInput
        className="text-input"
        placeholder="パスワードを入力してください"
        type="password"
        value={password}
        error={!!passwordError}
        onChange={onChangePassword}
      />
      {errors.length > 0 && <ErrorMessagesBox className="error-messages" errors={errors} />}
      <Button className="login" onClick={login} buttonType="primary" disabled={isDisable()}>
        ログイン
      </Button>
      {userType() === 'user' && (
        <div className="link">
          <a href={externalLink.creatorLogin}>クリエイター（写真や動画等を販売したい方）はこちら</a>
        </div>
      )}
      <div className="link">
        <a href="./password/reset">パスワードを忘れた方はこちら</a>
      </div>
    </Wrapper>
  )
}
