import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, DateInput, Selectbox, FileInput, Label, Link, Icon } from '../../atoms'
import { SelectboxWithLabel, TextInputWithLabel, RadioButtonWithLabel } from '../../molecules'

import { ProductType } from '../../../types/graphql'
import { ProductRegisterErrors, RegisterProductInput } from '../../../types/myTypes'

import { mediaPc } from '../../../lib/MediaQuery'
import {
  priceList,
  liveTalkPriceList,
  movieDurationList,
  releasedAtTimeList,
  hourList,
  minuteList,
  liveTalkMinutesList,
} from '../../../lib/SelectOptions'
import { validateProductName, validateImage, validateReleasedAt } from '../../../lib/Validate'
import { userType } from '../../../lib/UserType'
import { config } from '../../../config'

import moment from 'moment'

import selectThumbnail from '../../../assets/images/icon/select_thumbnail.png'
import selectPhotoWithAdvice from '../../../assets/images/icon/select_photo_with_advice.png'
import externalLink from '../../../assets/images/icon/external_link.png'

type Props = {
  product: RegisterProductInput
  onChangeState: (name: string, value: string | number | null) => void
  onClickShowProductThumbnailEditSelectingModal?: () => void
  onClickShowPreview?: () => void
  onClickProductRegister?: () => void
  uploadProductImage: (result: string, file: File) => void
}

const Wrapper = styled.div`
  background: ${(props): string => props.theme.backgroundColors.gray};

  .input-item {
    margin-bottom: 6.4vw;
    position: relative;
  }

  label {
    font-size: 2.67vw;
  }

  select:not(:focus) {
    border: 1px solid transparent;
  }

  .livetalk-lp-link-wrapper {
    padding-top: 1.33vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .link {
      margin-right: 0.266vw;
      color: ${(props): string => props.theme.textColors.primary};
      font-size: 2.66vw;
      font-weight: bold;
      line-height: 1.2;
      text-decoration-line: underline;
    }

    .icon {
      width: 2.66vw;
      height: 2.66vw;
    }
  }

  .file-select {
    width: 64vw;
    text-align: center;
    padding: 2.4vw 0 10.6vw;
    margin: 0 auto;
    border: 0;
    img {
      border-radius: 4px;
      height: auto;
      margin: auto;
    }
  }

  .movie-seconds-item {
    padding: 2.4vw 0 6.6vw;
  }

  .talk-start-at-title {
    margin-bottom: 2.1vw;
  }
  .talk-start-at-wrapper {
    margin-bottom: 6.4vw;

    .date-input {
      margin-bottom: 4.3vw;
    }

    .start-at-time {
      display: flex;
      align-items: flex-end;

      .start-at-time-select {
        width: 19.7vw;
        margin-right: 2.1vw;
        padding: 0 3.2vw;
        background-position: 80%;
      }

      span {
        display: block;
        margin-right: 4.3vw;
      }
    }
  }

  .talk-minutes {
    margin-bottom: 6.4vw;
  }

  .attention {
    padding: 3.2vw;
    font-weight: bold;
    font-size: 3.2vw;
    line-height: 1.75;
    letter-spacing: 0.05em;
    color: ${(props): string => props.theme.textColors.red};
    background: ${(props): string => props.theme.backgroundColors.bodyBg};
    border: 1px solid ${(props): string => props.theme.borderColors.red};
    border-radius: 4px;
    li {
      padding-left: 1em;
      text-indent: -1em;
      font-weight: bold;
    }
  }

  .release-date-item {
    padding: 2.4vw 0 4.4vw;
    .date-input {
      margin-bottom: 4.5vw;
    }
  }
  .product-preview-button {
    margin: 2.4vw auto 4vw;
  }
  .error-message {
    color: ${(props): string => props.theme.textColors.red};
    position: absolute;
    left: 0;
    bottom: 0;
    animation-duration: 0.3s;
    animation-name: viewOpacity;
    font-size: 2.4vw;
    &.thumbnail-image-error {
      right: 0;
      text-align: center;
    }
  }
  ${mediaPc`
    padding: 22px 22px 43px;
    .input-item {
      margin-bottom:30px;

      label {
        font-size:12px;
      }
      select {
        height 46px;
      }
    }

    .livetalk-lp-link-wrapper {
      padding-top: 0;
      justify-content: flex-start;
      position: absolute;
      bottom: 6px;
      right: 218px;

      .link{
        margin-right: 1px;
        font-size: 10px;
      }

      .icon {
        width: 10px;
        height: 10px;
      }
    }

    .file-select {
      width:200px;
      height:360px;
      margin:0 auto;
      padding: 0 0 32px;
      display: block;
      img {
        height: 100%;
      }
    }

    .movie-seconds-item {
      padding: 16px 0 20px;
    }

    .talk-start-at-title {
      margin-bottom: 5px;
    }
    .talk-start-at-wrapper {
      margin-bottom: 30px;
      display: flex;

      .date-input {
        width: 300px;
        height: 46px;
        margin: 0 30px 0 0;
      }

      .start-at-time {
        display: flex;
        align-items: flex-end;

        .start-at-time-select {
          width: 74px;
          margin-right: 10px;
          padding: 0 10px;
        }

        span {
          display: block;
          margin-right: 15px;
        }
      }
    }

    .talk-minutes {
      margin-bottom: 22px;
    }

    .attention {
      padding: 12px 15px;
      font-size: 12px;
    }

    .release-date-item {
      display:flex;
      position: relative;
      margin: 0 auto;
      padding: 16px 0 20px;
      .date-input {
        margin: 0 16px 0 0;
        height 46px;
      }
      .time-input {
        margin: 0 0 0 16px;
        height 46px;
      }
      .error-message {
        bottom:0px;
      }
    }
    .product-register-button {
      width:320px;
      height:46px;
      margin:0 auto;
      display: block;
    }
    .product-preview-button {
      width:320px;
      height:46px;
      margin:0 auto 14px;
      display: block;
    }
    .thumbnail-edit-button {
      width:320px;
      height:46px;
      margin:0 auto 14px;
      display: block;
    }
    .error-message {
      bottom:10px;
      font-size: 12px;
    }
  `}
`
export const ProductRegisterForm: React.FC<Props> = ({
  product,
  onChangeState,
  uploadProductImage,
  onClickShowProductThumbnailEditSelectingModal,
  onClickShowPreview,
  onClickProductRegister,
}) => {
  const [errors, setErrors] = useState<ProductRegisterErrors>({
    name: null,
    thumbnail_image: null,
    released_at: null,
  })
  const [releasedAtDay, setReleasedAtDay] = useState<string>(moment().format('YYYY-MM-DD'))
  const [releasedAtTime, setReleasedAtTime] = useState<string>('')
  const [liveTalkStartAtDate, setLiveTalkStartAtDate] = useState<string>(moment().format('YYYY-MM-DD'))
  const [liveTalkStartAtHour, setLiveTalkStartAtHour] = useState<string>('00')
  const [liveTalkStartAtMinute, setLiveTalkStartAtMinute] = useState<string>('00')
  const [releasedAtStatus, setReleasedAtStatus] = useState<'set' | 'not-set'>('not-set')

  const currentDay = moment().format('YYYY-MM-DD')
  const oneWeekLater = moment().add(7, 'days').format('YYYY-MM-DD')

  // selectBoxのオプション定義
  const movieDurationOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  movieDurationList.forEach((movieDuration) => {
    movieDurationOptions.push({ value: movieDuration.value, label: movieDuration.label })
  })

  const priceOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  priceList.forEach((price) => {
    priceOptions.push({ value: price.value, label: price.label })
  })

  const liveTalkPriceOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  liveTalkPriceList.forEach((price) => {
    liveTalkPriceOptions.push({ value: price.value, label: price.label })
  })

  const releasedAtTimeOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  releasedAtTimeList.forEach((releasedAtTime) => {
    releasedAtTimeOptions.push({ value: releasedAtTime.value, label: releasedAtTime.label })
  })

  const liveTalkMinutesListOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  liveTalkMinutesList.forEach((liveTalkMinutes) => {
    liveTalkMinutesListOptions.push({ value: liveTalkMinutes.value, label: liveTalkMinutes.label })
  })

  const isAbleToRegister = (): boolean => {
    return (
      product.name !== '' &&
      product.price !== '' &&
      product.thumbnail_image !== '' &&
      !(product.product_type === ProductType.Movie && product.movie_seconds === '') &&
      !(product.product_type === ProductType.LiveTalk && product.talk_minutes === '') &&
      !(releasedAtStatus === 'set' && releasedAtTime === '') &&
      liveTalkStartAtDate !== '' &&
      !errors.name &&
      !errors.thumbnail_image &&
      !errors.released_at
    )
  }

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value
    const name = event.target.name
    onChangeState(name, value)
  }

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const name = event.target.name
    const errorText: string | null = validateProductName(value)
    setErrors({ ...errors, name: null })
    if (errorText) {
      setErrors({ ...errors, name: errorText })
    }
    onChangeState(name, value)
  }

  const onChangeProductImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null && event.target.files[0] !== undefined) {
      const file = event.target.files[0]
      const errorText: string | null = validateImage(file)
      setErrors({ ...errors, thumbnail_image: null })
      if (errorText) {
        setErrors({ ...errors, thumbnail_image: errorText })
      } else {
        const objectUrl = URL.createObjectURL(file)
        uploadProductImage(objectUrl, file)
      }
    }
  }

  const onChangeReleasedAtDay = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    // 現時刻より前じゃ無いかチェック
    const formatReleasedAt = moment(`${value} ${releasedAtTime}`).format('YYYY-MM-DD HH:mm')
    const errorText: string | null = validateReleasedAt(formatReleasedAt)
    setErrors({ ...errors, released_at: null })
    if (errorText) {
      setErrors({ ...errors, released_at: errorText })
    }
    setReleasedAtDay(event.target.value)
    onChangeState('released_at', formatReleasedAt)
  }

  const onChangeReleasedAtTime = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value
    // 現時刻より前じゃ無いかチェック
    const formatReleasedAt = moment(`${releasedAtDay} ${value}`).format('YYYY-MM-DD HH:mm')
    const errorText: string | null = validateReleasedAt(formatReleasedAt)
    setErrors({ ...errors, released_at: null })
    if (errorText) {
      setErrors({ ...errors, released_at: errorText })
    }
    setReleasedAtTime(event.target.value)
    onChangeState('released_at', formatReleasedAt)
  }

  const onChangeLiveTalkStartAtDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const formatStartAt = moment(`${value} ${liveTalkStartAtHour}:${liveTalkStartAtMinute}`).format('YYYY-MM-DD HH:mm')
    setLiveTalkStartAtDate(event.target.value)
    onChangeState('start_at', formatStartAt)
  }

  const onChangeLiveTalkStartAtHour = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value
    const formatStartAt = moment(`${liveTalkStartAtDate} ${value}:${liveTalkStartAtMinute}`).format('YYYY-MM-DD HH:mm')
    setLiveTalkStartAtHour(event.target.value)
    onChangeState('start_at', formatStartAt)
  }

  const onChangeLiveTalkStartAtMinute = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value
    const formatStartAt = moment(`${liveTalkStartAtDate} ${liveTalkStartAtHour}:${value}`).format('YYYY-MM-DD HH:mm')
    setLiveTalkStartAtMinute(event.target.value)
    onChangeState('start_at', formatStartAt)
  }

  return (
    <Wrapper>
      <div className="input-item">
        <TextInputWithLabel
          label="商品タイトル(40文字まで)"
          name="name"
          value={product.name}
          placeholder="タイトルを入力してください"
          onChange={onChangeName}
          error={errors.name !== null}
        />
        {errors.name !== null && <p className="error-message">{errors.name}</p>}
      </div>

      <div className="input-item">
        <SelectboxWithLabel
          className="price"
          label="販売価格を設定"
          name="price"
          options={product.product_type === ProductType.LiveTalk ? liveTalkPriceOptions : priceOptions}
          value={product.price}
          onChange={onChangeValue}
        />
      </div>

      <div className="input-item">
        <RadioButtonWithLabel
          label="販売の形式を選択してください"
          checkedValue={product.product_type}
          buttons={[
            {
              id: 'photo',
              name: 'product_type',
              value: ProductType.Photo,
              label: '写真',
              onChange: () => {
                onChangeState('product_type', ProductType.Photo)
              },
            },
            {
              id: 'movie',
              name: 'product_type',
              value: ProductType.Movie,
              label: '動画',
              onChange: () => {
                onChangeState('product_type', ProductType.Movie)
              },
            },
            {
              id: 'live_talk',
              name: 'product_type',
              value: ProductType.LiveTalk,
              label: 'ライブトーク',
              onChange: () => {
                onChangeState('product_type', ProductType.LiveTalk)
              },
            },
          ]}
        />
        <div className="livetalk-lp-link-wrapper">
          {userType() === 'creator' ? (
            <Link url="/creator/lp/live-talk" text="ライブトークとは" target="_blank" rel="noopener noreferrer" className="link" />
          ) : (
            <a href={`${config.creatorFrontEndpoint}/lp/live-talk`} target="_blank" rel="noopener noreferrer" className="link">
              ライブトークとは
            </a>
          )}
          <Icon src={externalLink} className="icon" />
        </div>
      </div>

      {product.product_type === ProductType.Movie && (
        <div className="input-item">
          <SelectboxWithLabel
            className="movie-seconds-item"
            label="販売する動画の長さを選択してください"
            name="movie_seconds"
            options={movieDurationOptions}
            value={product.movie_seconds}
            onChange={onChangeValue}
          />
        </div>
      )}
      {product.product_type === 'LIVE_TALK' && (
        <>
          <div className="input-item">
            <Label className="talk-start-at-title">トーク開始時間を設定</Label>
            <div className="talk-start-at-wrapper">
              <DateInput
                className="date-input"
                name="start_at_date"
                value={liveTalkStartAtDate}
                min={currentDay}
                onChange={onChangeLiveTalkStartAtDate}
              />
              <div className="start-at-time">
                <Selectbox
                  className="start-at-time-select"
                  name="start_at_hour"
                  options={hourList}
                  value={liveTalkStartAtHour}
                  onChange={onChangeLiveTalkStartAtHour}
                />
                <span>時</span>
                <Selectbox
                  className="start-at-time-select"
                  name="start_at_minute"
                  options={minuteList}
                  value={liveTalkStartAtMinute}
                  onChange={onChangeLiveTalkStartAtMinute}
                />
                <span>分</span>
              </div>
            </div>
            <p className="attention">開始時間の15分前に、自動的に販売終了となりますので、時間に余裕を持ってご販売ください。</p>
          </div>
          <div className="input-item">
            <SelectboxWithLabel
              className="talk-minutes"
              label="トーク時間を選択"
              name="talk_minutes"
              options={liveTalkMinutesListOptions}
              value={product.talk_minutes}
              onChange={onChangeValue}
            />
            <div className="attention">
              <ul>
                <li>・1日に複数のライブトークを販売する場合は、必ずライブトークの間隔を5分以上空けて設定をしてください。</li>
                <li>
                  ・トーク開始時間と、実際にトーク開始できるまでの間に若干のタイムラグが生じますので、トーク時間は上記で設定した時間＋10秒間となります。
                </li>
                <li>・トーク終了時間になりましたら、自動的に終了となります。</li>
              </ul>
            </div>
          </div>
        </>
      )}
      <div className={errors.thumbnail_image !== null ? 'input-item error' : 'input-item '}>
        <FileInput className="file-select" name="icon_url" onChange={onChangeProductImage} accept="image/*">
          {product.product_type === ProductType.Photo ? (
            <img src={product.thumbnail_image ? product.thumbnail_image : selectPhotoWithAdvice} alt="P4U" />
          ) : (
            <img src={product.thumbnail_image ? product.thumbnail_image : selectThumbnail} alt="P4U" />
          )}
        </FileInput>
        {errors.thumbnail_image !== null && <p className="error-message thumbnail-image-error ">{errors.thumbnail_image}</p>}
        {product.thumbnail_image && (
          <Button className="thumbnail-edit-button" buttonType="white" onClick={onClickShowProductThumbnailEditSelectingModal}>
            ぼかし加工する
          </Button>
        )}
      </div>
      <div className="input-item">
        <RadioButtonWithLabel
          label="販売開始時間を設定する"
          checkedValue={releasedAtStatus}
          buttons={[
            {
              id: 'not-set',
              name: 'is-set-released-at',
              value: 'not-set',
              label: '設定しない',
              onChange: () => {
                setReleasedAtStatus('not-set')
                onChangeState('released_at', null)
              },
            },
            {
              id: 'set',
              name: 'is-set-released-at',
              value: 'set',
              label: '設定する',
              onChange: () => {
                setReleasedAtStatus('set')
              },
            },
          ]}
        />
        {releasedAtStatus === 'set' && (
          <>
            <div className={errors.released_at !== null ? 'input-item release-date-item error' : 'input-item release-date-item'}>
              <DateInput
                className="date-input"
                name="released-day"
                value={releasedAtDay}
                min={currentDay}
                max={oneWeekLater}
                onChange={onChangeReleasedAtDay}
              />
              <Selectbox
                className="released-time"
                name="released-time"
                value={releasedAtTime}
                options={releasedAtTimeOptions}
                onChange={onChangeReleasedAtTime}
              />
              {errors.released_at !== null && <p className="error-message">{errors.released_at}</p>}
            </div>
          </>
        )}
      </div>
      {isAbleToRegister() && (
        <Button className="product-preview-button" buttonType="gray" onClick={onClickShowPreview}>
          販売画面をプレビュー
        </Button>
      )}
      <Button disabled={!isAbleToRegister()} className="product-register-button" buttonType="primary" onClick={onClickProductRegister}>
        この内容で登録する
      </Button>
    </Wrapper>
  )
}
