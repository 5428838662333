import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useValidateBusinessUserTokenLazyQuery } from '../types/graphql'

export const useValidateBusinessUserToken = (): void => {
  const history = useHistory()
  const { pathname } = useLocation()

  const [validateBusinessUserTokenLazyQuery] = useValidateBusinessUserTokenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.ValidateBusinessUserToken === true) return
    },
    onError: () => {
      history.push({ pathname: '/business/login', state: { redirect: true, redirectFrom: pathname } })
    },
  })

  useEffect(() => {
    validateBusinessUserTokenLazyQuery()
  }, [validateBusinessUserTokenLazyQuery])
}
