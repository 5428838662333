import React, { useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useOrientationType, OrientationType } from '../../lib/SetWindow'
import { LocalVideoTrack, LocalAudioTrack, RemoteVideoTrack, RemoteAudioTrack } from 'twilio-video'

type Props = {
  className?: string
  track: LocalVideoTrack | LocalAudioTrack | RemoteVideoTrack | RemoteAudioTrack | null
  isLocalVideo?: boolean
}

type StyleProps = {
  isLocalVideo: boolean
  orientationType: OrientationType
}

const StyledLiveTalk = styled.div<StyleProps>`
  max-width: calc(100vh * 9 / 16);
  height: 100%;
  background-color: ${(props): string => props.theme.backgroundColors.darkGray};
  video {
    border-radius: 0px;
    width: calc(100vh * 9 / 16);
    height: 100%;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
  }
  ${(props) =>
    props.isLocalVideo &&
    props.orientationType === 'portrait' &&
    css`
      overflow: hidden;
      border-radius: 4px;
      width: 100px;
      height: 175.62px;
      video {
        height: 100%;
        width: 100%;
      }
    `}
`
export const LiveTalk: React.FC<Props> = ({ className = '', track, isLocalVideo = false }) => {
  const liveTalkRef = useRef<HTMLDivElement>(null)
  const videos = document.getElementsByTagName('video')

  const orientationType = useOrientationType()

  useEffect(() => {
    if (track !== null) {
      const child = track.attach()
      liveTalkRef.current?.appendChild(child)
    }
  }, [track])

  useEffect(() => {
    if (videos.length) {
      Array.from(videos).forEach((video) => {
        video.setAttribute('oncontextmenu', 'return false;')
      })
    }
  }, [videos])

  return <StyledLiveTalk className={className} ref={liveTalkRef} isLocalVideo={isLocalVideo} orientationType={orientationType} />
}
