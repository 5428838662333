import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  label?: string
  children?: React.ReactNode
}

const StyledProfileItem = styled.div`
  dt {
    font-size: 2.67vw;
    color: ${(props): string => props.theme.textColors.gray};
    letter-spacing: 0.05em;
    line-height: 1.8em;
  }
  dd {
    margin-bottom: 5.3vw;
    font-size: 3.733vw;
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 2.4em;
    word-wrap: break-word;
    a {
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    }
  }

  ${mediaPc`
    dt {
      font-size: 10px;
    }

    dd {
      margin-bottom: 30px;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.gray};
    }
  `}
`

export const ProfileItem: React.FC<Props> = ({ className = '', label = '', children }) => {
  return (
    <StyledProfileItem className={className}>
      <dt>{label}</dt>
      <dd>{children}</dd>
    </StyledProfileItem>
  )
}
