import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { PasswordResetForm } from '../../molecules'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  email?: string
  emailError: string | null
  onClick: () => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props): string => props.theme.backgroundColors.gray};
  padding: 7.7vw 7.2vw 51vw;
  ${mediaPc`
  width: 100%;
    background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 72px 0px 190px;
  `}
`

export const PasswordReset: React.FC<Props> = ({ email, emailError, onClick, onChange }) => {
  return (
    <Wrapper>
      <PasswordResetForm email={email} emailError={emailError} onClick={onClick} onChange={onChange} />
    </Wrapper>
  )
}
