import React from 'react'
import styled from 'styled-components'
import { SnsIcon } from '../../atoms'
import { Creator } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  creator: Creator
  className?: string
}

const SnsUl = styled.ul`
  margin: 2.93vw auto 7.73vw;
  padding: 0 2.6vw;
  width: auto;
  display: flex;
  justify-content: space-between;
  li {
    text-align: center;
    img {
      width: 9.6vw;
      height: auto;
      margin: 0 auto 1vw;
    }
    p {
      font-weight: 500;
      font-size: 2.6vw;
      line-height: 3.2vw;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.gray};
      &.unregistered {
        padding-top: 2.13vw;
        font-weight: bold;
        font-size: 2.93vw;
        line-height: 3.46vw;
        color: ${(props): string => props.theme.textColors.black};
      }
    }
  }
  ${mediaPc`
    width: 100%;
    padding: 0;
    margin: 0 auto;
    li {
      width: 36px;
      margin:0;
      img {
        width: 100%;
        margin:0;
      }
      p {
        display: none;
      }
    }
  `}
`

export const SnsList: React.FC<Props> = ({ className = '', creator }) => {
  return (
    <SnsUl className={className}>
      <li>
        {creator.twitter_username && (
          <a href={`https://twitter.com/${creator.twitter_username}`} target="_blank" rel="noopener noreferrer">
            <SnsIcon sns="twitter" />
            <p>twitter</p>
          </a>
        )}
        {!creator.twitter_username && (
          <>
            <SnsIcon sns="twitter" type="disabled" />
            <p>twitter</p>
            <p className="unregistered">未登録</p>
          </>
        )}
      </li>
      <li>
        {creator.instagram_username && (
          <a href={`https://www.instagram.com/${creator.instagram_username}`} target="_blank" rel="noopener noreferrer">
            <SnsIcon sns="instagram" />
            <p>instagram</p>
          </a>
        )}
        {!creator.instagram_username && (
          <>
            <SnsIcon sns="instagram" type="disabled" />
            <p>instagram</p>
            <p className="unregistered">未登録</p>
          </>
        )}
      </li>
      <li>
        {creator.youtube_channel_url && (
          <a href={`${creator.youtube_channel_url}`} target="_blank" rel="noopener noreferrer">
            <SnsIcon sns="youtube" />
            <p>Youtube</p>
          </a>
        )}
        {!creator.youtube_channel_url && (
          <>
            <SnsIcon sns="youtube" type="disabled" />
            <p>Youtube</p>
            <p className="unregistered">未登録</p>
          </>
        )}
      </li>
      <li>
        {creator.kindle_author_url && (
          <a href={`${creator.kindle_author_url}`} target="_blank" rel="noopener noreferrer">
            <SnsIcon sns="kindle" />
            <p>Kindle</p>
          </a>
        )}
        {!creator.kindle_author_url && (
          <>
            <SnsIcon sns="kindle" type="disabled" />
            <p>Kindle</p>
            <p className="unregistered">未登録</p>
          </>
        )}
      </li>
    </SnsUl>
  )
}
