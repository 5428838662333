import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const TayoriChat: React.FC = () => {
  const { pathname, search } = useLocation()

  useEffect(() => {
    const tayoriSvg = document.getElementById(`tayori-svg`)
    if (tayoriSvg !== null) {
      tayoriSvg.style.display = `none`
    }
    const tayoriChat = document.getElementById(`tayori-chat`)
    if (tayoriChat !== null) {
      tayoriChat.style.display = `none`
    }

    if (pathname === '/') {
      if (tayoriSvg !== null) {
        tayoriSvg.style.display = ``
      }
      if (tayoriChat !== null) {
        tayoriChat.style.display = ``
      }

      // tayori-chat-tagの有無でscriptの追加
      const scriptChatTag = document.getElementById('tayori-chat-tag')
      if (scriptChatTag === null) {
        const scriptTag = document.createElement('script')
        scriptTag.text = `(function(t,a,y,o,r,i){r=a.createElement(y),i=a.getElementsByTagName(y)[0];r.type='text/javascript';r.async=1;r.src='//pintoforyou.tayori.com/chat/c59f8220ec450b4e16f7631838f1ce481c89593f/tag.js';r.id='tayori-chat-tag';i.parentNode.insertBefore(r,i)})(window,document,'script');`
        document.body.appendChild(scriptTag)
      }
    }
  }, [pathname, search])

  return null
}
