import React, { useState, useCallback, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import {
  UserHome,
  UserRegistration,
  UserRegistrationComplete,
  UserMypageTop,
  UserMypageEdit,
  UserLogin,
  UserPasswordReset,
  UserMypageEditComplete,
  UserPasswordSendComplete,
  UserPasswordRenewComplete,
  UserPasswordRenew,
  UserProductPurchase,
  UserFavoriteCreators,
  UserFavoriteNewProducts,
  UserMypageProductDetail,
  UserLiveTalk,
  UserLiveTalkLp,
} from '../pages/User'
import {
  TermsOfSale,
  TermsOfUse,
  Privacy,
  NotFound,
  CreatorTop,
  ProductDetail,
  CreatorRanking,
  CreatorSearch,
  CreatorSearchResult,
  ProductsOnSale,
} from '../pages/Common'
import { ScrollToTop } from '../ScrollToTop'
import { UserProfileContext, UserTokenContext, initialUserProfile, initialUserToken } from '../context'
import { User } from '../types/graphql'
import { TayoriChat } from '../lib/TayoriChat'
import { userType } from '../lib/UserType'
import { Footer, Header } from '../components/molecules'
import { config } from '../config'

interface Props {
  storeHeaderToken(token: string): void
}

export const UserRoutes: React.FC<Props> = ({ storeHeaderToken }) => {
  const [userToken, setUserToken] = useState<string>(initialUserToken.userToken)
  const [user, setUser] = useState<User>(initialUserProfile.user)
  const [favoriteCreatorsNumber, setFavoriteCreatorsNumber] = useState<number>(initialUserProfile.favoriteCreatorsNumber)
  const updateUserToken = useCallback((token) => {
    setUserToken(token)
  }, [])
  const updateUser = useCallback((user) => {
    setUser(user)
  }, [])
  const updateFavoriteCreatorsNumber = useCallback((number) => {
    setFavoriteCreatorsNumber(number)
  }, [])

  const [cookie] = useCookies(['token'])
  const headerToken = userToken !== '' ? userToken : cookie.userToken
  useEffect(() => storeHeaderToken(headerToken), [headerToken, storeHeaderToken])

  if (userToken === '') {
    // useContextを優先したいのでブラウザ更新等でtokenが消えた場合はcookieの値をセットしておく
    setUserToken(cookie.userToken)
  }
  const isUserLoggedIn = !!(userType() === 'user' && (userToken || cookie.userToken))

  return (
    <UserTokenContext.Provider value={{ userToken, updateUserToken }}>
      <UserProfileContext.Provider
        value={{
          user,
          favoriteCreatorsNumber,
          updateUser,
          updateFavoriteCreatorsNumber,
        }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Header isLoggedIn={isUserLoggedIn} />
          <main>
            <Switch>
              <Route exact path="/" render={() => <UserHome isLoggedIn={isUserLoggedIn} />} />
              <Route exact path="/terms-of-sale" component={TermsOfSale} />
              <Route exact path="/terms-of-use" component={TermsOfUse} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/registration" component={UserRegistration} />
              <Route exact path="/registration/complete" component={UserRegistrationComplete} />
              <Route exact path="/mypage" component={UserMypageTop} />
              {/* 納品完了通知メールからの遷移用 */}
              <Route exact path="/mypage/product/:id" component={UserMypageTop} />
              <Route exact path="/mypage/product/live-talk/:id" component={UserMypageProductDetail} />
              <Route exact path="/mypage/edit" component={UserMypageEdit} />
              <Route exact path="/mypage/edit/complete" component={UserMypageEditComplete} />
              <Route exact path="/login" component={UserLogin} />
              <Route exact path="/password/reset" component={UserPasswordReset} />
              <Route exact path="/password/send/complete" component={UserPasswordSendComplete} />
              <Route exact path="/password/renew/complete" component={UserPasswordRenewComplete} />
              <Route exact path="/password/renew" component={UserPasswordRenew} />
              <Route exact path="/favorite/creators" component={UserFavoriteCreators} />
              <Route exact path="/favorite/products/new" component={UserFavoriteNewProducts} />
              <Route
                exact
                path="/creators/ranking"
                render={(routeProps) => <CreatorRanking isUserLoggedIn={isUserLoggedIn} {...routeProps} />}
              />
              <Route exact path="/products-on-sale" component={ProductsOnSale} />
              <Route exact path="/creators/search" component={CreatorSearch} />
              <Route
                exact
                path="/creators/search/result"
                render={(routeProps) => <CreatorSearchResult isUserLoggedIn={isUserLoggedIn} {...routeProps} />}
              />
              <Route exact path="/creators/:id" render={(routeProps) => <CreatorTop isUserLoggedIn={isUserLoggedIn} {...routeProps} />} />
              <Route exact path="/product/:id" render={(routeProps) => <ProductDetail isUserLoggedIn={isUserLoggedIn} {...routeProps} />} />
              <Route
                path="/purchase/success"
                exact
                render={() => (
                  <Redirect
                    to={{
                      pathname: '/mypage',
                      state: { from: 'purchaseSuccess' },
                    }}
                  />
                )}
              />
              {/* /purchase/:idは/purchase/successよりも下に配置 */}
              <Route exact path="/purchase/:id" component={UserProductPurchase} />
              <Route
                path="/purchase/:id/failure"
                exact
                render={({ match }) => (
                  <Redirect
                    to={{
                      pathname: `/purchase/${match.params.id}`,
                      state: { from: 'purchaseFailure' },
                    }}
                  />
                )}
              />
              <Route
                path="/update_email/verify"
                exact
                render={({ location }) => (
                  <Redirect
                    to={{
                      pathname: '/mypage',
                      search: location.search,
                      state: { from: 'updateEmail' },
                    }}
                  />
                )}
              />
              <Route
                path="/registered/email/verify"
                exact
                render={({ location }) => (
                  <Redirect
                    to={{
                      pathname: '/',
                      search: location.search,
                      state: { from: 'registerComplete' },
                    }}
                  />
                )}
              />
              <Route exact path="/live-talk/:id" component={UserLiveTalk} />
              <Route exact path="/lp/live-talk" component={UserLiveTalkLp} />
              {/* 旧サイトのリダイレクト */}
              <Route
                path={[
                  '/creator/delivery_product',
                  '/creator/login',
                  '/creator/logout',
                  '/creator/mypage',
                  '/creator/mypage/edit',
                  '/creator/orders',
                  '/creator/password/creators/:creatorId/renew',
                  '/creator/password/renew/complete',
                  '/creator/password/reset',
                  '/creator/password/reset/complete',
                  '/creator/privacy',
                  '/creator/product',
                  '/creator/product/delivery/:orderedProductId',
                  '/creator/product/disabled',
                  '/creator/product/register',
                  '/creator/product/reserve',
                  '/creator/product/:productId',
                  '/creator/registration/:creator',
                  '/creator/sales',
                  '/creator/sales/:month',
                  '/creator/search',
                  '/creator/search/result',
                  '/creator/terms-of-sale',
                  '/creator/terms-of-use',
                  '/creator/update_email/verify',
                  '/creator/update_email/:creatorId/retry',
                  '/creator/user',
                  '/creator/:creatorId',
                ]}
                component={() => {
                  window.location.href = config.creatorFrontEndpoint
                  return null
                }}
              />
              <Route
                path={[
                  '/business/affiliation',
                  '/business/affiliation/add',
                  '/business/affiliation/:affiliationId',
                  '/business/affiliation/:affiliationId/creators',
                  '/business/affiliation/:affiliationId/edit',
                  '/business/creator',
                  '/business/creator/registration',
                  '/business/creator/search',
                  '/business/creator/search/result',
                  '/business/creator/:creatorId',
                  '/business/creator/:creatorId/edit',
                  '/business/creator/:creatorId/orders',
                  '/business/creator/:creatorId/product/disabled',
                  '/business/creator/:creatorId/product/register',
                  '/business/creator/:creatorId/product/reserve',
                  '/business/creator/:creatorId/products',
                  '/business/creator/:creatorId/user',
                  '/business/filtered_sales/creators/month/csv',
                  '/business/filtered_sales/month/csv',
                  '/business/login',
                  '/business/logout',
                  '/business/mypage',
                  '/business/mypage/edit',
                  '/business/password/businessUser/:businessUserId/renew',
                  '/business/password/renew/complete',
                  '/business/password/reset',
                  '/business/password/reset/complete',
                  '/business/privacy',
                  '/business/product/delivery/:orderedProductId',
                  '/business/product/:productId',
                  '/business/sales',
                  '/business/sales/affiliation',
                  '/business/sales/affiliation/:month',
                  '/business/sales/affiliations/:month',
                  '/business/sales/creator/:creatorId',
                  '/business/sales/creator/:creatorId/:month',
                  '/business/sales/creators/month/csv',
                  '/business/sales/month/csv',
                  '/business/terms-of-sale',
                  '/business/terms-of-use',
                  '/business/update_email/verify',
                  '/business/update_email/:businessUserId/retry',
                ]}
                component={() => {
                  window.location.href = config.businessFrontEndpoint
                  return null
                }}
              />
              <Route component={NotFound} />
            </Switch>
          </main>
          <Footer />
          <TayoriChat />
        </BrowserRouter>
      </UserProfileContext.Provider>
    </UserTokenContext.Provider>
  )
}
