import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Button } from '../../../atoms'
import { CONTACT_URL, CREATOR_AND_BUSINESS_FAQ_URL } from '../../../../const'
import { mediaPc } from '../../../../lib/MediaQuery'
import topBgPc from '../../../../assets/images/lp/top_bg_pc.png'
import topBgSp from '../../../../assets/images/lp/top_bg_sp.png'
import liveTalkText from '../../../../assets/images/lp/live_talk_text.png'
import topImage from '../../../../assets/images/lp/top_image.png'
import speechBalloon from '../../../../assets/images/lp/speech_balloon.png'
import liveTalkTheme01 from '../../../../assets/images/lp/live_talk_theme01.png'
import liveTalkTheme02 from '../../../../assets/images/lp/live_talk_theme02.png'
import liveTalkTheme03 from '../../../../assets/images/lp/live_talk_theme03.png'

const Wrapper = styled.div`
  .top {
    padding: 18.6vw 0 0;
    background-image: url(${topBgSp});
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 184vw;
    .inner {
      color: ${(props): string => props.theme.textColors.white};
      padding: 0 4.35vw;
      .lead-text {
        width: 84.8vw;
        height: 16.8vw;
        margin: 0 auto;
        padding: 2.4vw 0;
        background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
        color: ${(props): string => props.theme.textColors.primary};
        font-size: 4.2vw;
        font-weight: bold;
        text-align: center;
        border-radius: 15px 15px 15px 0;
      }
      .top-text {
        .text {
          margin: 0 auto 7.2vw;
          font-size: 3.7vw;
          font-weight: bold;
          letter-spacing: 0.1em;
          line-height: 1.5;
          text-align: center;
        }
        img {
          width: 82.4vw;
          margin: 0 auto 9.6vw;
        }
      }
      .top-image {
        width: 100%;
        margin: 0 0 5.6vw;
      }
    }
  }
  .about {
    width: 81vw;
    margin: 0 auto;
    padding: 21vw 0;
    text-align: center;
    .inner {
      display: block;
      max-width: 922px;
      margin: 0 auto;
      .swiper-container {
        margin: 0 0 7.8vw;
        img {
          width: 28.8vw;
          margin: 0 auto 3.9vw;
        }
        .speech-ballon {
          width: max-content;
          padding: 2.6vw 3.64vw;
          background-color: ${(props): string => props.theme.backgroundColors.primary};
          color: ${(props): string => props.theme.textColors.white};
          border-radius: 0px 15px 15px 15px;
          font-weight: bold;
          font-size: 3.12vw;
          letter-spacing: 0.1em;
          margin: 0 auto;
        }
      }
      .text {
        text-align: center;
        .contents-title {
          font-weight: bold;
          position: relative;
          text-align: center;
          font-size: 4.8vw;
          margin: 0 0 13vw;
          &::after {
            content: '';
            width: 177px;
            height: 6px;
            margin: auto;
            position: absolute;
            bottom: -22px;
            left: 0;
            right: 0;
            border-radius: 3px;
            background-color: ${(props): string => props.theme.backgroundColors.primary};
          }
        }
      }
    }
    p {
      font-weight: bold;
      font-size: 3.2vw;
      line-height: 170%;
      letter-spacing: 0.1em;
    }
  }
  .feature {
    padding: 21vw 0 15.6vw;
    background-color: ${(props): string => props.theme.backgroundColors.primary};
    .inner {
      width: 88.9vw;
      margin: 0 auto;
      .title {
        width: 74.1vw;
        height: 24.4vw;
        margin: 0 auto 8.3vw;
        padding: 7vw 0 0;
        font-size: 4.6vw;
        font-weight: bold;
        text-align: center;
        background-image: url(${speechBalloon});
        background-size: cover;
        background-repeat: no-repeat;
      }
      .block {
        background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
        margin: 0 0 10.4vw;
        padding: 7.8vw 8.26vw 7.54vw;
        border-radius: 10px;
        box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, 0.1);
        div {
          color: ${(props): string => props.theme.textColors.primary};
          text-align: center;
          padding: 0 0 2.34vw;
          letter-spacing: 0.1em;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-size: 4.8vw;
            font-weight: bold;
            line-height: 5.6vw;
            text-align: left;
            &.num {
              font-size: 10.2vw;
              letter-spacing: 0;
              font-weight: bold;
              padding: 0 3.38vw 0 0;
            }
          }
        }
        p {
          font-size: 3.12vw;
          text-align: left;
          letter-spacing: 0.15em;
          line-height: 170%;
        }
        &:last-child {
          font-size: 3.8vw;
        }
      }
    }
  }
  .about-product {
    padding: 4.6vw 0;
    background-color: ${(props): string => props.theme.backgroundColors.gray};
    .inner {
      width: 87.1vw;
      margin: 0 auto;
      padding: 7.8vw 5.98vw 9.62vw;
      border-radius: 10px;
      background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
      .title {
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.1em;
        font-size: 5.4vw;
        padding: 0 0 5.9vw;
      }
      .button {
        display: block;
        margin: 6.76vw auto 7.54vw;
        font-weight: bold;
      }
      .lead-text {
        text-align: center;
        font-weight: bold;
        font-size: 3.64vw;
      }
      .detail {
        p {
          font-size: 3.12vw;
          line-height: 170%;
          letter-spacing: 0.1em;
          text-align: left;
          font-weight: bold;
          &:last-child {
            font-weight: normal;
            padding: 5.2vw 0 0;
          }
          a {
            color: ${(props): string => props.theme.textColors.primary};
          }
        }
      }
    }
  }
  ${mediaPc`
    .top {
      padding: 74px 0 0;
      background-image:url(${topBgPc});
      background-size:cover;
      height:565px;
      .inner {
        position: relative;
        width:1040px;
        margin:0 auto;
        .lead-text {
          width: 624px;
          height: 67px;
          padding:0;
          border-radius: 15px 15px 15px 0;
          font-size: 24px;
          line-height: 67px;
          letter-spacing: 0.1em;
        }
        .top-text {
          width: 429px;
          padding: 97px 0 0;
          .text {
            margin: 0 0 23px;
            font-size: 18px;
          }
          img {
            width: 429px;
          }
        }
        .top-image {
          width: 41vw;
          min-width:470px;
          max-width: 590px;
          margin: 0;
          position: absolute;
          left: 520px;
          top: 50px;
        }
      }
    }
    .about {
      width: auto;
      padding: 149px 0 110px;
      .inner {
        display: flex;
        justify-content: space-between;
        max-width: 922px;
        margin: 0 auto;
        .swiper-container {
          width: 300px;
          margin: initial;
          img {
            width: 178px;
            margin: initial;
          }
          .speech-ballon {
            position: absolute;
            padding: 19px 16px;
            font-size: 14px;
            right: 0px;
            bottom: 60px;
          }
        }
        .text {
          width: 588px;
          padding: 52px 0 0;
          .contents-title {
            text-align: left;
            position: relative;
            font-size: 24px;
            margin: 0 0 73px;
            &::after {
              content: '';
              position: absolute;
              width: 183px;
              right: auto;
            }
          }
          text-align: left;
        }
      }
      p {
        font-size: 18px;
      }
    }
    .feature {
      padding: 80px 0 60px;
      .inner {
        .title {
          width: 316px;
          height: 105px;
          margin: 0 auto 39px;
          padding: 24px 0 0;
          font-size: 24px;
        }
        .block {
          width: 750px;
          margin:0 auto 60px;
          padding:31px 0 53px;
          div {
            padding:0 0 15px;
            p {
              font-size: 24px;
              &.num {
                font-size: 42px;
                padding: 0 10px 0 0;
              }
            }
          }
          p {
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
    .about-product {
      padding: 41px 0 91px;
      .inner {
        max-width: 705px;
        padding: 43px 70px 55px;
        .title {
          font-size:24px;
          padding: 0 0 18px;
        }
        .button {
          width: 310px;
          height 50px;
          margin: 30px auto 30px;
        }
        .lead-text {
          font-size: 14px;
        }
        .detail {
          p {
            font-size: 14px;
            &:last-child {
              padding: 23px 0 0;
            }
          }
        }
      }
    }
  `}
`
export const UserLiveTalkLpTpl: React.FC = () => {
  const history = useHistory()
  SwiperCore.use([Autoplay])

  const goToMypage = (): void => {
    history.push(`/mypage`)
  }
  return (
    <Wrapper>
      <div className="top">
        <div className="inner">
          <div className="lead-text pc">2人だけの時間を過ごせるオンラインコンテンツ</div>
          <div className="top-text">
            <p className="text">会えない時でも2人の繋がりを</p>
            <img src={liveTalkText} alt="liveTalk" />
          </div>
          <img className="top-image" src={topImage} alt="liveTalk" />
          <div className="lead-text sp">
            2人だけの時間を過ごせる
            <br />
            オンラインコンテンツ
          </div>
        </div>
      </div>
      <div className="about">
        <div className="inner">
          <Swiper
            className="swiper-container pc"
            autoplay={{
              delay: 5000,
            }}
            effect={'slide'}
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            autoHeight={true}
          >
            <SwiperSlide>
              <img src={liveTalkTheme01} alt="P4U" />
              <div className="speech-ballon">コスプレしてお話します！</div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={liveTalkTheme02} alt="P4U" />
              <div className="speech-ballon">
                ベットに横になったまま
                <br />
                一緒におはようの挨拶しませんか？
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={liveTalkTheme03} alt="P4U" />
              <div className="speech-ballon">好きなポーズ教えてください♪</div>
            </SwiperSlide>
          </Swiper>
          <div className="text">
            <div className="contents-title">ライブトーク商品とは？</div>
            <Swiper
              className="swiper-container sp"
              autoplay={{
                delay: 5000,
              }}
              effect={'slide'}
              slidesPerView={1}
              centeredSlides={true}
              loop={true}
              autoHeight={true}
            >
              <SwiperSlide>
                <img src={liveTalkTheme01} alt="P4U" />
                <div className="speech-ballon">コスプレしてお話します！</div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={liveTalkTheme02} alt="P4U" />
                <div className="speech-ballon">
                  ベットに横になったまま
                  <br />
                  一緒におはようの挨拶しませんか？
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={liveTalkTheme03} alt="P4U" />
                <div className="speech-ballon">好きなポーズ教えてください♪</div>
              </SwiperSlide>
            </Swiper>
            <p>
              クリエイターと1対１でビデオチャットができるサービスです。
              <br className="pc" />
              アプリ等のダウンロードなしにスマホやタブレット、パソコンのブラウザからそのまま通話をお楽しみいただけます。
            </p>
          </div>
        </div>
      </div>
      <div className="feature">
        <div className="inner">
          <div className="title">ライブトークの魅力</div>
          <div className="block">
            <div>
              <p className="num">01</p>
              <p>
                会えなくてもできる
                <br className="sp" />
                コミュニケーション
              </p>
            </div>
            <p>
              直接会うことのできない時もクリエイターと顔を合わせてお話することができます。
              <br className="pc" />
              周りを気にせず二人で顔を見て話すことで信頼関係を深めることができます。
            </p>
          </div>
          <div className="block">
            <div>
              <p className="num">02</p>
              <p>
                あなたの推しと
                <br className="sp" />
                2人きりで
              </p>
            </div>
            <p>
              推しと2人だけでお話しすることができます。
              <br className="pc" />
              他のファンは知らない2人だけの秘密を、あなただけに話してくれるかも?!
            </p>
          </div>
          <div className="block">
            <div>
              <p className="num">03</p>
              <p>
                普段見ることができない、
                <br className="sp" />
                プライベートな一面も
              </p>
            </div>
            <p>
              推しのプライベートな一面を垣間見ることができます。
              <br className="pc" />
              普段と違う服装や、寝起き・料理している様子など、
              <br className="pc" />
              超レアな推しの姿は必見です！
            </p>
          </div>
        </div>
      </div>
      <div className="about-product">
        <div className="inner">
          <p className="title">ライブトーク商品について</p>
          <p className="lead-text">
            写真/動画と同じように
            <br className="sp" />
            クリエイター個別ページから購入できます！
          </p>
          <Button buttonType="secondary" className="button" onClick={goToMypage}>
            マイページに戻る
          </Button>
          <div className="detail">
            <p>
              ・1分~10分のライブトーク商品の購入ができます。
              <br />
              ・時間を指定して販売されておりますので、前後のご予定を考えて、確実にお話できる時間であることを確認してから、購入してください。
              <br />
              ・購入後のキャンセルはできません。またユーザー様都合でライブトークに参加されなかった場合は返金致しませんので、ご留意ください。
              <br />
              ・利用規約に抵触するまたは、クリエイターが不快になるような言動をした場合、アカウント停止させて頂く場合がございます。
              <br />
              ・端末に通信制限がかかっていると、ライブトークの映像や音声がうまくつながらない可能性があります。ライブトーク前に通信制限がかかっていないか確認をお願い致します。
            </p>
            <p>
              その他よくある質問は
              <a href={CREATOR_AND_BUSINESS_FAQ_URL} target="_blank" rel="noopener noreferrer">
                こちら
              </a>
              <br />
              お問い合わせは
              <a href={CONTACT_URL} target="_blank" rel="noopener noreferrer">
                こちら
              </a>
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
