import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../atoms'
import { CreatorRankingTitle, CreatorRankingDurationTab } from '../../molecules'
import { RankedCreators } from '..'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'
import { CreatorRanking } from '../../../types/graphql'

type Props = {
  className?: string
  creatorRanking: CreatorRanking[]
  selectedDuration: 'weekly' | 'monthly'
  isUserLoggedIn?: boolean
  onChangeDuration: (durationType: 'weekly' | 'monthly') => void
  onClickFavorite?: (creatorId: string, isFavorite: boolean) => void
}

const Wrapper = styled.div`
  .creator-ranking-title {
    margin-bottom: 4vw;
  }

  .creator-ranking-tab {
    margin: 0 auto 8vw;
  }

  .more-ranking {
    width: 50vw;
    margin: 0 auto;
    display: block;
    font-size: 3.2vw;
    line-height: 2.8;
  }
  ${mediaPc`
    .creator-ranking-title,
    .creator-ranking-tab {
      margin: 0;
    }

    .creator-ranking-head {
      margin-bottom: 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .more-ranking {
      width: 210px;
      height: 46px;
      font-size: 12px;
      line-height: 46px;
    }
  `}
`

export const HomeCreatorRanking: React.FC<Props> = ({
  className,
  selectedDuration,
  creatorRanking,
  isUserLoggedIn,
  onChangeDuration,
  onClickFavorite,
}) => {
  const history = useHistory()

  const goToCreatorRanking = (): void => {
    const type = userType()
    switch (type) {
      case 'user':
        return history.push(`/creators/ranking?duration=${selectedDuration}`)
      case 'creator':
        return history.push(`/creator/ranking?duration=${selectedDuration}`)
      case 'business':
        return history.push(`/business/creator/ranking?duration=${selectedDuration}`)
      default:
        return
    }
  }

  return (
    <Wrapper className={className}>
      <div className="creator-ranking-head">
        <CreatorRankingTitle className="creator-ranking-title" />
        <CreatorRankingDurationTab
          selectedDuration={selectedDuration}
          onChangeDuration={onChangeDuration}
          className="creator-ranking-tab"
        />
      </div>
      <RankedCreators creatorRanking={creatorRanking} isUserLoggedIn={isUserLoggedIn} onClickFavorite={onClickFavorite} />
      <Button buttonType="white" onClick={goToCreatorRanking} className="more-ranking">
        もっと見る
      </Button>
    </Wrapper>
  )
}
