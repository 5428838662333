import React from 'react'
import styled from 'styled-components'
import { Label, RadioButton } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  label?: string
  checkedValue: string
  buttons: { id: string; name: string; value: string; label: string; onChange: () => void }[]
}

const Wrapper = styled.div`
  .label {
    margin-bottom: 2.1vw;
  }

  ${mediaPc`
    .label {
      margin-bottom: 10px;
    }
  `}
`

export const RadioButtonWithLabel: React.FC<Props> = ({ className = '', label = '', buttons = [], checkedValue }) => {
  return (
    <Wrapper className={className}>
      <Label className="label">{label}</Label>
      <RadioButton checkedValue={checkedValue} buttons={buttons} />
    </Wrapper>
  )
}
