import React from 'react'
import styled from 'styled-components'
import { PageTitle, Icon } from '../../atoms'
import { mediaPc } from '../../../lib/MediaQuery'
import { convertToVw } from '../../../lib/ConvertSize'

type Props = {
  className?: string
  src?: string
  alt?: string
  title?: string
}

const Title = styled.div`
  display: flex;
  align-items: center;

  .terms-title-text {
    margin-left: ${convertToVw('17px')};
    font-size: 4.2vw;
  }

  ${mediaPc`
    justify-content: center;

    .terms-title-text {
      margin-left: 17px;
      line-height: 1.33;
      text-align: center;
      font-size: 24px;
    }
  `}
`

export const TermsTitle: React.FC<Props> = ({ className = '', src = '', alt = '', title = '' }) => {
  return (
    <Title className={className}>
      <Icon src={src} alt={alt} />
      <PageTitle title={title} className="terms-title-text" />
    </Title>
  )
}
