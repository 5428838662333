import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { mediaPc } from '../../../lib/MediaQuery'
import { ProductImage, ProductStockLabel, MovieDuration, Button, Icon } from '../../atoms'

import moment from 'moment'
import liveTalkIcon from '../../../assets/images/icon/live_talk.png'
import externalLink from '../../../assets/images/icon/external_link.png'

import { ProductType, ProductStatus } from '../../../types/graphql'
import { RegisterProductInput } from '../../../types/myTypes'

import { config } from '../../../config'

type Props = {
  className?: string
  product: RegisterProductInput
  closePreviewModal: () => void
}

const StyledProductDetailPreview = styled.div`
  background: ${(props): string => props.theme.backgroundColors.bodyBg};
  padding: 5.5vw 0 5vw;
  .product-image {
    width: 75vw;
    max-width: 100%;
    margin: 0 auto 4.8vw;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
    }

    .movie-seconds-sp {
      position: absolute;
      width: auto;
      height: 7.4vw;
      padding: 0 3.6vw 0 8.6vw;
      bottom: 3vw;
      left: 3vw;
      z-index: 5;
      font-size: 3.2vw;
      line-height: 7.4vw;
      border-radius: 3.7vw;
    }

    .live-talk-icon {
      position: absolute;
      width: 32.3vw;
      z-index: 5;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .product-info {
    width: 75vw;
    max-width: 100%;
    margin: 0 auto;
    .released-date {
      margin-bottom: 3.2vw;
      font-size: 2.67vw;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.placeholder};
    }
    .name {
      margin-bottom: 3.2vw;
      font-size: 4.27vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props): string => props.theme.textColors.black};
    }
    .live-talk-attention {
      margin-bottom: 4.3vw;
      padding: 2.9vw;
      color: ${(props): string => props.theme.textColors.red};
      font-size: 3.2vw;
      font-weight: bold;
      line-height: 1.7;
      background: ${(props): string => props.theme.backgroundColors.bodyBg};
      border: 1px solid ${(props): string => props.theme.borderColors.red};
      border-radius: 4px;
    }
    .livetalk-lp-link-wrapper {
      margin-bottom: 4.2vw;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .link {
        margin-right: 0.266vw;
        color: ${(props): string => props.theme.textColors.primary};
        font-size: 2.66vw;
        font-weight: bold;
        line-height: 1.2;
        text-decoration-line: underline;
      }

      .icon {
        width: 2.66vw;
        height: 2.66vw;
      }
    }
    .schedule-wrapper {
      margin-bottom: 4.27vw;
      display: flex;
      align-items: center;
      > p {
        font-size: 3.733vw;
      }
      .schedule-title {
        margin-right: 3.2vw;
      }
    }
    .live-talk-note {
      margin-bottom: 2.9vw;
      color: ${(props): string => props.theme.textColors.red};
      font-size: 3.2vw;
      font-weight: bold;
      line-height: 1.7;
    }
    .price {
      margin-bottom: 1.5vw;
      font-size: 4.27vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
    }
    .paragraph-spacing {
      margin-bottom: 1em;
    }
    .close-preview-button {
      width: 100%;
      height: auto;
      border-radius: 4px;
      background: #666;
      color: #fff;
      font-size: 3.735vw;
      line-height: 3.3em;
      font-weight: bold;
      margin: 0 auto 2.6vw;
      display: block;
    }
  }

  ${mediaPc`
      display: flex;
      justify-content: space-between;
      min-height: 317px;
      padding:0 0 0px;
      .product-image {
        width: 329px;
        margin: initial;

        .live-talk-icon {
          width: 121px;
        }
      }
      .product-info {
        width: 310px;
        margin:0;
        .name {
            font-weight: bold;
            font-size: 16px;
            padding: 0 0 19px;
            margin: 0;
            color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
        }
        .movie-seconds-pc {
            width: 96px;
            height: 28px;
            background-size: 20px;
            background-position: 4px;
            padding: 0 0 0 31px;
            font-size: 12px;
            line-height: 28px;
        }
        .live-talk-attention {
          margin-bottom: 9px;
          padding: 11px;
          font-size: 12px;
        }
        .livetalk-lp-link-wrapper {
          margin-bottom: 8px;
          .link{
            margin-right: 1px;
            font-size: 10px;
          }
          .icon {
            width: 10px;
            height: 10px;
          }
        }
    
        .schedule-wrapper {
          margin-bottom: 16px;
    
          > p {
            font-size: 14px;
          }
    
          .schedule-title {
            margin-right: 12px;
          }
        }
    
        .live-talk-note {
          margin-bottom: 30px;
          font-size: 12px;
        }
        .price {
            margin:0;
            font-weight: bold;
            padding: 53px 0 10px;
            text-align: right;
            font-size: 24px;
            color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
        }
        .released-date {
            padding:0 0 45px;
            margin: 0;
            text-align: right;
            font-size:12px;
            color:${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.placeholder};
        }

        .close-preview-button {
          width: 320px;
          height: 46px;
          font-size: 14px;
          line-height: 46px;
          margin: 0 auto 10px;
        }
      }
  `}
`

export const ProductDetailPreview: React.FC<Props> = ({ className = '', product, closePreviewModal }) => {
  return (
    <StyledProductDetailPreview className={className}>
      <div className="product-image">
        <ProductImage src={product.thumbnail_image} />
        <ProductStockLabel
          status={ProductStatus.Sale}
          stock={product.product_type === ProductType.LiveTalk ? 1 : 4}
          type={product.product_type}
        />
        {product.product_type === ProductType.Movie && (
          <MovieDuration className="movie-seconds-sp sp" duration={Number(product.movie_seconds)} />
        )}
        {product.product_type === ProductType.LiveTalk && <Icon className="live-talk-icon" src={liveTalkIcon} alt="ライブトーク" />}
      </div>
      <div className="product-info">
        <div className="name">{product.name}</div>
        {product.product_type === ProductType.Movie && (
          <MovieDuration className="movie-seconds-pc pc" duration={Number(product.movie_seconds)} />
        )}
        {product.product_type === ProductType.LiveTalk && (
          <>
            <p className="live-talk-attention">
              オンラインでお話できる商品です。
              <br />
              ご購入の前に必ず日程をご確認ください。
            </p>
            <div className="livetalk-lp-link-wrapper">
              <a href={`${config.userFrontEndpoint}/lp/live-talk`} target="_blank" rel="noopener noreferrer" className="link">
                ライブトークとは
              </a>
              <Icon src={externalLink} className="icon" />
            </div>
            <div className="schedule-wrapper">
              <p className="schedule-title">日付</p>
              <p className="schedule">{moment(product.start_at).format('YYYY/MM/DD')}</p>
            </div>
            <div className="schedule-wrapper">
              <p className="schedule-title">時間</p>
              <p className="schedule">
                {moment(product.start_at).format('HH時mm分')}~
                {moment(product.start_at).add(product.talk_minutes, 'minutes').format('HH時mm分')}　（{product.talk_minutes}分間）
              </p>
            </div>
          </>
        )}
        <div className="price">¥{product.price.toLocaleString()}</div>
        <div className="released-date">
          {product.released_at ? moment(product.released_at).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD')} 公開
        </div>
        <Button className="close-preview-button" buttonType="gray" onClick={closePreviewModal}>
          プレビューを閉じる
        </Button>
        <p className="paragraph-spacing">
          注文から決済までは１商品づつお願い致します。 注文中に他の商品を注文するとエラーが発生し、決済されることがあるのでご注意ください。
        </p>
        <p className="paragraph-spacing">
          先着{product.product_type === ProductType.LiveTalk ? 1 : 4}
          名様の商品のため15分以内に決済が完了しない場合は、購入がキャンセルとなりますのでご注意ください。
        </p>
        <p>及び、別の商品を注文した際に、最初の商品の注文がキャンセルになりますのでご注意ください。</p>
      </div>
    </StyledProductDetailPreview>
  )
}
