import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Link } from '../../atoms'
import { TermsTitle, TermsBlock } from '../../molecules'
import { APP_NAME, CONTACT_URL } from '../../../const'
import { mediaPc } from '../../../lib/MediaQuery'
import privacy from '../../../assets/images/icon/privacy.png'

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props): string => props.theme.backgroundColors.gray};
  padding: 7.7vw 7.2vw;

  ${mediaPc`
  width: 100%;
    background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.bodyBg};
    padding: 72px 20px 190px;
  `}
`

const Inner = styled.div`
  .terms-title {
    margin-bottom: 5.6vw;
  }

  ${mediaPc`
    max-width: 1000px;
    margin: 0 auto;

    .terms-title {
      margin-bottom: 72px;
    }
  `}
`

export const PrivacyTpl: React.FC = () => {
  return (
    <Wrapper>
      <Inner>
        <TermsTitle src={privacy} alt="プライバシーポリシー" title="プライバシーポリシー" className="terms-title" />
        <TermsBlock withoutSubtitle>
          株式会社ROLO (以下当社)は、「{APP_NAME}
          」ウェブサイト上で提供するサービス（以下本サービス）を展開するにあたり、当社サービス利用者の個人情報(氏名・住所・連絡先等)を大切な財産として認識し、以下のとおりプライバシーポリシーを定め、法律等に基づき適切に取り扱うことに対して万全を尽くします。
        </TermsBlock>
        <TermsBlock subtitle="第1条　個人情報の定義">
          当社は、個人情報保護法第2条第1項に則り、個人情報を、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。）として定義します。
        </TermsBlock>
        <TermsBlock subtitle="第2条　個人情報利用目的の特定">
          当社は、下記の目的において、個人情報を取得し、利用致します。
          <br />
          ①本サービスの提供と、本サービスに関するご案内を送付するため
          <br />
          ②本サービスの利用料金を請求するため
          <br />
          ③当社または第三者の名誉、プライバシー、または社会的信用を侵害する行為を防止するため
          <br />
          ④本サービスの利用報酬を支払うため
          <br />
          ⑤本サービスの改善、および新サービスの開発のため
          <br />
          ⑥本サービスの利用規約に違反するご利用を防止するため
          <br />
          ⑦本プライバシーポリシー第16条に規定されるデータ等を作成するため
        </TermsBlock>
        <TermsBlock subtitle="第3条　個人情報利用の制限">
          当社は、あらかじめご本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。合併その他の理由により個人情報を取得した場合にも、あらかじめご本人の同意を得ないで、承継前の利用目的の範囲を超えて取扱うことはありません。ただし、次の場合はこの限りではありません。
          <br />
          ①法令に基づく場合または法令において許容されている場合
          <br />
          ②人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合
          <br />
          ③公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき
          <br />
          ④国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
        </TermsBlock>
        <TermsBlock subtitle="第4条　個人情報の適正な取得">
          当社は、適正に個人情報を取得し、不正の手段により取得することはありません。
        </TermsBlock>
        <TermsBlock subtitle="第5条　個人情報の取得に際する利用目的の通知">
          当社は、個人情報を取得するにあたり、あらかじめその利用目的を通知または公表します。ただし、次の場合はこの限りではありません。
          <br />
          ①利用目的をご本人に通知し、または公表することによりご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
          <br />
          ②利用目的をご本人に通知し、または公表することにより当社の権利または正当な利益を害するおそれがある場合
          <br />
          ③国の機関もしくは地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的をご本人に通知し、または公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき
          <br />
          ④取得の状況からみて利用目的が明らかであると認められる場合
        </TermsBlock>
        <TermsBlock subtitle="第6条　個人情報利用目的の変更">
          当社は、個人情報の利用目的を変更する場合には、変更前の利用目的と関連性を有すると合理的に認められる範囲を超えては行わず、変更された利用目的について、ご本人に通知し、または公表します。
        </TermsBlock>
        <TermsBlock subtitle="第7条　個人情報の管理">
          当社は、お客様の個人情報を、正確、最新のものとするよう適切な処置を講じます。また、お客様の個人情報を適切かつ安全に管理します。
        </TermsBlock>
        <TermsBlock subtitle="第8条　委託先の監督">
          当社は、個人情報の取扱いの全部または一部を委託する場合は、委託先と機密保持を含む契約の締結、または、当社が定める約款に合意を求め、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
        </TermsBlock>
        <TermsBlock subtitle="第9条　第三者提供の制限">
          1. 当社は、次に掲げる場合を除くほか、あらかじめご本人の同意を得ないで、個人情報を第三者に提供しません。
          <br />
          ①法令に基づく場合
          <br />
          ②人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合
          <br />
          ③公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難である場合
          <br />
          ④国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
          <br />
          ⑤ご本人が当社を通じて、当社または第三者の物品やサービスの購入または請求を行い、当該第三者または運送業者等から当該サービス提供に必要な情報の提供を求められた場合
          <br />
          ⑥ご本人を識別することのできない状態での開示・提供する場合
          <br />
          ⑦ご本人の違法行為、当社との契約違反行為等により、当社または善意の第三者の権利・財産が侵害されるおそれがあり、その違法・違反行為の停止や損害の発生・拡大の回避のために情報開示が必要であると、当社が認めた場合
          <br />
          ⑧あらかじめ次の事項のいずれかを告知あるいは公表をしている場合
          <br />
          　a.利用目的に第三者への提供を含むこと
          <br />
          　b.第三者に提供されるデータの項目
          <br />
          　c.第三者への提供の手段または方法
          <br />
          　d.ご本人の求めに応じて個人情報の第三者への提供を停止すること
          <br />
          ⑨当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          <br />
          ⑩合併その他の事由による事業の承継に伴って個人情報が提供される場合
          <br />
          ⑪個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめご本人に通知し、またはご本人が容易に知り得る状態に置いているとき
          <br />
          2.
          当社は、個人情報を特定の者との間で共同して利用する場合、利用目的または管理責任者の氏名または名称が変更される場合は、変更する内容について、あらかじめご本人に通知し、またはご本人が容易に知り得る状態に置きます。
        </TermsBlock>
        <TermsBlock subtitle="第10条　外国にある第三者への提供">
          当社が利用目的の達成に必要な範囲内において、個人情報の取扱いの全部または一部を国外の第三者へ委託する場合には、当社は、当該第三者に対し、個人情報保護に必要な事項を義務付けるとともに、適切な監督を行うものとします。
        </TermsBlock>
        <TermsBlock subtitle="第11条　個人情報の開示">
          当社は、ご本人から、個人情報の開示を求められたときは、ご本人に対し、遅滞なく開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、アクセスログなどの個人情報以外の情報については、原則として開示いたしません。
          <br />
          • ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
          <br />
          • 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
          <br />• 他の法令に違反することとなる場合
        </TermsBlock>
        <TermsBlock subtitle="第12条　個人情報の訂正">
          当社は、ご本人から、個人情報が正確でない等の理由によって、内容の訂正、追加または削除（以下「訂正等」といいます）を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨ご本人に通知します。
        </TermsBlock>
        <TermsBlock subtitle="第13条　個人情報の利用停止">
          当社は、ご本人から、ご本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ご本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、当該代替策を講じることがあります。
        </TermsBlock>
        <TermsBlock subtitle="第14条　理由の説明">
          当社は、ご本人からの要求に対して、以下のいずれかの対応をとる場合、その旨ご本人に通知するとともに、その際、理由を説明するよう努めます。
          <br />
          ①利用目的を通知しない
          <br />
          ②個人情報の全部または一部を開示しない
          <br />
          ③個人情報の利用停止等を行わない
          <br />
          ④個人情報の第三者提供を停止しない
        </TermsBlock>
        <TermsBlock subtitle="第15条　免責">
          第三者による個人情報の取得に関し、以下の場合においては、当社は一切責任を負いません。
          <br />
          ①ご本人が何らかの手段を用いて第三者に個人情報を明らかにした場合
          <br />
          ②ご本人が当社サービス上に入力した情報等により、個人が識別できた場合
        </TermsBlock>
        <TermsBlock subtitle="第16条　統計情報と匿名加工情報の利用と第三者への提供">
          1. 当社は、当社が取得、保存した各種情報を以下の目的ないし方法で、当社内で利用し、または第三者へ提供することがあります。
          <br />
          ①個人が識別できないよう十分な人数分の元情報を統計化処理して統計情報を作成し、当社内で利用、または当社サービスを利用する企業等の第三者に提供するため。
          <br />
          ②特定の個人を識別することができず(それ自体では個人を特定できず、かつ通常想定される外部情報との照合でも個人を特定できない)、かつ加工の元になった個人情報を復元できない形式とした匿名加工情報(以下「匿名加工情報」といいます)を作成し、当社において本プライバシーポリシー第2条の目的で利用するため。
          <br />
          2.
          当社は、以下に掲げる場合には、提供先に対して、匿名加工情報を元に特定の個人を識別する行為を禁止し、提供する情報が匿名加工情報であることを明示したうえで、匿名加工情報を提供することがあります。
          <br />
          ①目的：本サービスのマーケティング分析や広告出稿等のため
          <br />
          提供先：当社が提携する広告代理店等メディア関連企業、マーケティング調査会社等
          <br />
          提供する情報の項目
          ：当社サービス利用者にまつわる、閲覧広告・ページ、性別、職業、年齢、ご利用時間帯、ご利用の方法、ご利用環境等などの情報、IPアドレス、クッキー情報、位置情報、端末の個体識別情報
          <br />
          提供方法：電磁的な方法による送付(メディア媒体による送付を含む)または送信
          <br />
          ②目的：当社サービスを利用する企業からの委託によるマーケティング分析や広告出稿等のため
          <br />
          提供先：当社が提携する広告代理店等メディア関連企業、マーケティング調査会社等
          <br />
          提供する情報の項目
          ：当社サービス利用者にまつわる、閲覧広告・ページ、性別、職業、年齢、ご利用時間帯、ご利用の方法、ご利用環境等などの情報、IPアドレス、クッキー情報、位置情報、端末の個体識別情報
          <br />
          提供方法：電磁的な方法による送付(メディア媒体による送付を含む)または送信
        </TermsBlock>
        <TermsBlock subtitle="第17条　本プライバシーポリシーの改定・変更">
          当社では、お客様の個人情報の保護を図るために、また法令その他の規範の変更に対応するために、本プライバシーポリシーを適宜改定・変更することがあります。改定があった場合は当ホームページにてお知らせいたします。
        </TermsBlock>
        <TermsBlock subtitle="第18条　お問合せ窓口">
          本ポリシーに関するお問合せは、
          <Link url={CONTACT_URL} text="お問い合わせフォーム" target="_blank" />
          までお願いいたします。
        </TermsBlock>
      </Inner>
    </Wrapper>
  )
}
