import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import moment from 'moment'
import { SalesPerOrder, SalesBreakdown, ProductType } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'

type Props = {
  className?: string
  sales: (SalesPerOrder | SalesBreakdown)[]
  noDataText: string
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 7.2vw;
  overflow-x: scroll;
  border: 1px solid ${(props): string => props.theme.borderColors.black};
  .table-inner {
    width: auto;
    display: flex;
    dl {
      border-right: 1px solid ${(props): string => props.theme.borderColors.black};
      &.delivery-date {
        min-width: 20%;
        text-align: center;
        p {
          &.not-delivered {
            color: ${(props): string => props.theme.textColors.red};
          }
        }
      }
      &.purchase-date {
        min-width: 20%;
        text-align: center;
      }
      &.purchaser {
        min-width: 30%;
        dt {
          text-align: left;
        }
        p {
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      &.product-name {
        max-width: 50%;
        min-width: 50%;
        dt {
          text-align: left;
        }
        p {
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      &.creator {
        max-width: 36%;
        min-width: 30%;
        dt {
          text-align: left;
        }
        p {
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      &.product-type {
        min-width: 16%;
      }
      &.product-time {
        min-width: 15%;
      }
      &.order-id {
        min-width: 19%;
      }
      &.price {
        min-width: 20%;
      }
      dt {
        height: auto;
        padding: 0 1.5vw;
        border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
        font-size: 3.2vw;
        font-weight: bold;
        color: ${(props): string => props.theme.textColors.black};
        line-height: 3em;
        text-align: center;
        background: ${(props): string => props.theme.backgroundColors.bodyBg};
      }
      dd {
        height: 12.2vw;
        padding: 0 1.5vw;
        font-size: 3.2vw;
        color: ${(props): string => props.theme.textColors.black};
        line-height: 3em;
        border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
        background: ${(props): string => props.theme.backgroundColors.bodyBg};
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &:last-child {
          border: none;
        }
        p {
          width: 100%;
          line-height: 1.4em;
          text-align: center;
          span {
            font-size: 2.67vw;
            line-height: 0.6;
          }
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
  .no-data {
    width: 100%;
    height: auto;
    padding-left: 4vw;
    font-size: 3.2vw;
    font-weight: 500;
    line-height: 3.9em;
    letter-spacing: 0.05em;
    color: ${(props): string => props.theme.textColors.gray};
  }
  ${mediaPc`
    width: auto;
    margin-bottom: 24px;
    overflow-x: hidden;
    .table-inner {
      dl {
        &.delivery-date,
        &.purchase-date {
          min-width: 60px;
        }
        &.purchaser {
          min-width: 80px;
        }
        &.product-name {
          min-width: 110px;
        }
        &.creator {
          min-width: 105px;
        }
        &.product-type {
          min-width: 50px;
        }
        &.product-time {
          min-width: 50px;
        }
        &.order-id {
          min-width: 74px;
        }
        &.price {
          min-width: 86px;
        }
        dt {
          padding: 0 8px;
          font-size: 12px;
          line-height: 34px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
        }
        dd {
          height: 75px;
          min-height: 65px;
          padding: 0 10px;
          font-size: 12px;
          font-weight: normal;
          p {
            line-height: 1.4em;
            span {
              font-size: 10px;
              line-height: 0.6;
            }
          }
        }
      }

      &.without-creator {
        dl {
          &.delivery-date,
          &.purchase-date {
            min-width: 70px;
          }
          &.purchaser {
            min-width: 90px;
          }
          &.product-name {
            min-width: 155px;
          }
          &.product-type {
            min-width: 60px;
          }
          &.product-time {
            min-width: 60px;
          }
          &.order-id {
            min-width: 84px;
          }
          &.price {
            min-width: 86px;
          }
        }
      }
    }
    .no-data {
      padding-left: 15px;
      font-size: 14px;
    }
  `}
`

export const SalesPerOrderTable: React.FC<Props> = ({ className = '', sales, noDataText }) => {
  const isSalesPerOrder = (sale: SalesPerOrder | SalesBreakdown): sale is SalesPerOrder => {
    return (sale as SalesPerOrder).creator_name !== undefined
  }

  return (
    <Wrapper className={className}>
      {sales.length !== 0 && (
        <div className={userType() === 'business' ? 'table-inner' : 'table-inner without-creator'}>
          <dl className="delivery-date">
            <dt>納品日</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                {sale.delivered_at ? (
                  <p>
                    {moment(sale.delivered_at).format('M/D')}
                    <br />
                    <span>{moment(sale.delivered_at).format('HH:mm')}</span>
                  </p>
                ) : (
                  <p className="not-delivered">{sale.product_type === ProductType.LiveTalk ? 'トーク前' : '未納品'}</p>
                )}
              </dd>
            ))}
          </dl>
          <dl className="purchase-date">
            <dt>購入日</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                <p>
                  {moment(sale.paid_at).format('M/D')}
                  <br />
                  <span>{moment(sale.paid_at).format('HH:mm')}</span>
                </p>
              </dd>
            ))}
          </dl>
          <dl className="purchaser">
            <dt>購入者</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                <p>{sale.user_name}</p>
              </dd>
            ))}
          </dl>
          <dl className="product-name">
            <dt>購入商品</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                <p>{sale.product_name}</p>
              </dd>
            ))}
          </dl>
          {userType() === 'business' && (
            <dl className="creator">
              <dt>クリエイター</dt>
              {sales.map((sale, index) => (
                <dd key={index}>
                  <p>{isSalesPerOrder(sale) ? sale.creator_name : ''}</p>
                </dd>
              ))}
            </dl>
          )}
          <dl className="product-type">
            <dt>形式</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                <p>{sale.product_type_name}</p>
              </dd>
            ))}
          </dl>
          <dl className="product-time">
            <dt>時間</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                {
                  <p>
                    {sale.product_type === ProductType.LiveTalk
                      ? `${sale.talk_minutes}分`
                      : sale.product_type === ProductType.Movie
                      ? `${sale.movie_seconds}秒`
                      : 'ー'}
                  </p>
                }
              </dd>
            ))}
          </dl>
          <dl className="order-id">
            <dt>注文ID</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                <p>{sale.order_id}</p>
              </dd>
            ))}
          </dl>
          <dl className="price">
            <dt>金額</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                <p>{sale.sales.toLocaleString()}</p>
              </dd>
            ))}
          </dl>
        </div>
      )}
      {/* 売上無い場合 styleは削除 */}
      {!sales.length && (
        <>
          <div className="table-inner">
            <dl className="delivery-date">
              <dt>納品日</dt>
            </dl>
            <dl className="purchase-date">
              <dt>購入日</dt>
            </dl>
            <dl className="purchaser">
              <dt>購入者</dt>
            </dl>
            <dl className="product-name">
              <dt>購入商品</dt>
            </dl>
            <dl className="creator">
              <dt>クリエイター</dt>
            </dl>
            <dl className="product-type">
              <dt>形式</dt>
            </dl>
            <dl className="product-time">
              <dt>時間</dt>
            </dl>
            <dl className="order-id">
              <dt>注文ID</dt>
            </dl>
            <dl className="price">
              <dt>金額</dt>
            </dl>
          </div>
          <div className="no-data">
            <p>{noDataText}</p>
          </div>
        </>
      )}
    </Wrapper>
  )
}
