import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorRankingTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  CreatorRanking as CreatorRankingType,
  useCreatorRankingOfMonthlySalesCountLazyQuery,
  useCreatorRankingOfWeeklySalesCountLazyQuery,
  useFavoriteCreatorMutation,
  useUnfavoriteCreatorMutation,
} from '../../../types/graphql'
import { userType } from '../../../lib/UserType'

type Props = { isUserLoggedIn?: boolean }

export const CreatorRanking: React.FC<Props> = (props) => {
  const [creatorRanking, setCreatorRanking] = useState<CreatorRankingType[]>([])
  const [selectedDuration, setSelectedDuration] = useState<'weekly' | 'monthly'>('weekly')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const history = useHistory()
  const location = useLocation()

  const [creatorRankingOfWeeklySalesCountLazyQuery] = useCreatorRankingOfWeeklySalesCountLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setCreatorRanking(data.CreatorRankingOfWeeklySalesCount ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [creatorRankingOfMonthlySalesCountLazyQuery] = useCreatorRankingOfMonthlySalesCountLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setCreatorRanking(data.CreatorRankingOfMonthlySalesCount ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [favoriteCreatorMutation] = useFavoriteCreatorMutation({
    onCompleted: () => {
      setFlashMessage({ type: 'success', message: 'お気に入り登録しました' })

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchCreatorRanking(selectedDuration)
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り登録できませんでした' })
      }
    },
  })
  const [unfavoriteCreatorMutation] = useUnfavoriteCreatorMutation({
    onCompleted: () => {
      setFlashMessage({ type: 'success', message: 'お気に入り解除しました' })

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchCreatorRanking(selectedDuration)
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り解除できませんでした' })
      }
    },
  })

  const fetchCreatorRanking = useCallback(
    (type: 'weekly' | 'monthly') => {
      switch (type) {
        case 'weekly':
          creatorRankingOfWeeklySalesCountLazyQuery({
            variables: {
              input: {
                limit: 20,
              },
            },
          })
          break
        case 'monthly':
          creatorRankingOfMonthlySalesCountLazyQuery({
            variables: {
              input: {
                limit: 20,
              },
            },
          })
          break
        default:
          break
      }
    },
    [creatorRankingOfMonthlySalesCountLazyQuery, creatorRankingOfWeeklySalesCountLazyQuery],
  )

  const onChangeDuration = (durationType: 'weekly' | 'monthly'): void => {
    setSelectedDuration(durationType)
    setLoading(true)

    if (userType() === 'business') {
      history.replace(`/business/creator/ranking?duration=${durationType}`)
    } else if (userType() === 'creator') {
      history.replace(`/creator/ranking?duration=${durationType}`)
    } else if (userType() === 'user') {
      history.replace(`/creators/ranking?duration=${durationType}`)
    }

    fetchCreatorRanking(durationType)
  }

  const onClickFavorite = (creatorId: string, isFavorite: boolean): void => {
    setLoading(true)
    setFlashMessage(null)
    if (isFavorite) {
      unfavoriteCreatorMutation({
        variables: {
          creator_id: creatorId,
        },
      })
    } else {
      favoriteCreatorMutation({
        variables: {
          creator_id: creatorId,
        },
      })
    }
  }

  useEffect(() => {
    const duration = new URLSearchParams(location.search).get('duration')
    if (duration === 'weekly' || duration === 'monthly') {
      setSelectedDuration(duration)
      fetchCreatorRanking(duration)
    } else {
      fetchCreatorRanking('weekly')
    }
  }, [fetchCreatorRanking, location.search])

  return (
    <>
      <Meta title="クリエイターランキング" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorRankingTpl
        creatorRanking={creatorRanking}
        selectedDuration={selectedDuration}
        isUserLoggedIn={props.isUserLoggedIn}
        onChangeDuration={onChangeDuration}
        onClickFavorite={onClickFavorite}
      />
    </>
  )
}
